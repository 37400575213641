import { useEffect, useState } from 'react';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';
import Loading from '~/components/Loading';
import columns from './columns';
import { HTTPErrorCallback } from '~/Helpers/Error';
import api from '~/services/api';
import Table from '~/components/NexAdmin/Table';
import moment from 'moment';
import { Select, Input } from '~/components/NexAdmin/Inputs/index';
import { Form } from '@unform/web';
import * as S from '../styles';
import { Button } from '~/components/NexAdmin/Buttons/index';
import ConfirmButton from '~/components/NexAdmin/Buttons/ConfirmButton';
import { showNotification } from '~/utils/notificationBoxes';

const UserHistoryList = () => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  useEffect(() => {
    getSheets();
    getFileNames();
  }, []);

  const getFileNames = () => {
    setLoading(true);
    api
      .get(`/pap-engine/file_names?type=usuario`)
      .then((response) => {
        setFileNames(response.data);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const getSheets = (data) => {
    setLoading(true);
    data = {
      ...data,
      type: 'usuario',
    };
    const params = new URLSearchParams(data);
    api
      .get(`/pap-engine/history/?${params}`)
      .then((response) => {
        setFiles(response.data);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const cancel = (id) => {
    if (!id) return;
    setLoading(true);
    api
      .patch(`/pap-engine/cancel_schedule/${id}`)
      .then(() => {
        setLoading(false);
        return showNotification(
          'success',
          'Sucesso',
          'Agendamento cancelado com sucesso!',
        );
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="actions"
          title="Histórico de Processamento de Usuários"
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <Form onSubmit={getSheets}>
              <S.FormWrapper>
                <S.FormFilters>
                  <Input label="Data Upload:" name="data_upload" type="date" />
                  <Input
                    label="Data Processamento:"
                    name="inicio_processamento"
                    type="date"
                  />
                  {fileNames.length > 0 && (
                    <Select
                      name="nome_arquivo"
                      options={fileNames}
                      label="Arquivo"
                    />
                  )}
                  <Select
                    name="processado"
                    options={[
                      { label: 'Todos', value: '*' },
                      { label: 'Sim', value: '1' },
                      { label: 'Não', value: '0' },
                    ]}
                    label="Processado"
                  />
                  <Select
                    name="cancelado"
                    options={[
                      { label: 'Todos', value: '*' },
                      { label: 'Sim', value: '1' },
                      { label: 'Não', value: '0' },
                    ]}
                    label="Cancelado"
                  />
                </S.FormFilters>
                <Button type="submit">Consultar</Button>
              </S.FormWrapper>
            </Form>
            <Table
              columns={[
                {
                  name: 'Ações',
                  cell: (row) => (
                    <ConfirmButton
                      icon={'close'}
                      iconColor={'red'}
                      label="CANCELAR AGENDAMENTO?"
                      confirmLabel={'SIM'}
                      cancelLabel="CANCELAR"
                      openOnRight={true}
                      confirmAction={() => cancel(row.id)}
                    />
                  ),
                  width: '150px',
                },
                ...columns,
              ]}
              downloadColumns={columns}
              data={files}
              dataTableProps={{ pagination: true }}
              header={true}
              fileName={`processamento_usuarios_${moment().format(
                'DD-MM-YYYY',
              )}`}
              hasDownload={true}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default UserHistoryList;
