import React from 'react';
import { useTransition } from 'react-spring';

import * as S from './styles';
import Toast from './Toast';

const ToastContainer = ({ messages }) => {
  const messagesWithAnimations = useTransition(
    messages,
    (message) => message.id,
    {
      from: { right: '-120%', opacity: 0 },
      enter: { right: '0%', opacity: 1 },
      leave: { right: '-120%', opacity: 0 },
    },
  );

  return (
    <S.Container>
      {messagesWithAnimations.map(({ item, key }) => (
        <Toast key={key} message={item} />
      ))}
    </S.Container>
  );
};

export default ToastContainer;
