import moment from 'moment';
import getIcon from '~/Helpers/getIcon';
import * as S from './styles';

const ConfirmCancel = ({ onYes, onNo }) => {
  return (
    <S.ConfirmWrapper>
      <S.Confirm>
        <S.CloseButton type="button" onClick={onNo}>
          <img src="/images/close-icon.png" alt="close button" />
        </S.CloseButton>
        <S.RedWrapper>
          {getIcon('danger', '#c50101')}
          Cancelar Campanha
        </S.RedWrapper>
        <S.Row>
          <S.TitleWrapper>
            <h2>Tem certeza que deseja cancelar a campanha?</h2>
          </S.TitleWrapper>
        </S.Row>
        <S.ButtonsContainer>
          <S.NoButton type="button" onClick={onNo}>
            <img src="/images/confirmCampain/arrow.svg" alt="back icon" />
            Voltar
          </S.NoButton>
          <S.YesButton type="button" onClick={onYes}>
            Confirmar
          </S.YesButton>
        </S.ButtonsContainer>
      </S.Confirm>
    </S.ConfirmWrapper>
  );
};

export default ConfirmCancel;
