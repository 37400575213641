import getIcon from '~/Helpers/getIcon';
import * as S from './styles';
import { useEffect, useState, useRef } from 'react';
import api from '~/services/api';
import Loading from '~/components/Loading';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { showNotification } from '~/utils/notificationBoxes';

const TrainningModal = ({ close, quiz, refresh }) => {
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(
    quiz?.questions ? quiz.questions[current] : [],
  );
  const [anwser, setAnwser] = useState([]);
  const anwserRef = useRef(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const getLetter = (number) => {
    return String.fromCharCode(65 + number);
  };

  const handleAnwser = async (anwser_id, question_id) => {
    if (!anwserRef.current) {
      anwserRef.current = {};
    }
    if (!anwserRef.current[question_id]) {
      anwserRef.current[question_id] = { anwser_id_selected: anwser_id };
    } else {
      anwserRef.current[question_id] = { anwser_id_selected: anwser_id };
    }
    setAnwser({ ...anwserRef.current });

    try {
      await api.put(`/quizzes/user/quiz`, {
        quizz_id: quiz.id,
        partial_answers: anwserRef.current,
      });
    } catch (error) {
      return HTTPErrorCallback(error);
    }
  };

  const handleNext = async () => {
    if (current < quiz.questions.length - 1) {
      const c = current + 1;
      setCurrent((prev) => prev + 1);
      setCurrentQuestion(quiz.questions[c]);
    }
    if (current === quiz.questions.length - 1) {
      try {
        setLoading(true);
        const response = await api.post(`/quizzes/user/quiz`, {
          quizz_id: quiz.id,
        });
        setLoading(false);
        showNotification('success', 'Sucesso', response.data.message);
        close();
        refresh();
      } catch (error) {
        setLoading(false);
        return HTTPErrorCallback(error);
      }
    }
    return;
  };

  const handlePrevious = () => {
    if (current > 0) {
      const c = current - 1;
      setCurrent((prev) => prev - 1);
      setCurrentQuestion(quiz.questions[c]);
    }
    return;
  };

  return (
    <>
      {loading && <Loading />}
      <S.ModalBackground>
        <S.Wrapper>
          <S.HeaderWrapper>
            <S.HeaderLeft>
              {getIcon('trainningBook', '#C50101')}
              Treinamento
            </S.HeaderLeft>
            <button onClick={close} style={{ cursor: 'pointer' }}>
              {getIcon('close')}
            </button>
          </S.HeaderWrapper>
          <>
            <S.QuestionWrapper>
              <S.QuestionNumberWrapper>
                <S.QuestionNumberCurrent>{current + 1}</S.QuestionNumberCurrent>
                <S.QuestionNumberTotal>
                  /{quiz.questions ? quiz.questions.length : 0}
                </S.QuestionNumberTotal>
              </S.QuestionNumberWrapper>
              Pergunta
            </S.QuestionWrapper>
            <S.Content>{currentQuestion.question}</S.Content>
            <S.AnswerWrapper>
              <p>Alternativas</p>
              {currentQuestion.anwser.map((item, i) => (
                <S.AnswerContentWrapper
                  key={i}
                  onClick={() => handleAnwser(item.id, currentQuestion.id)}
                  selected={
                    anwser[currentQuestion.id]?.anwser_id_selected === item.id
                  }
                >
                  <S.AnswerContentLetter
                    selected={
                      anwser[currentQuestion.id]?.anwser_id_selected === item.id
                    }
                  >
                    {getLetter(i)}
                  </S.AnswerContentLetter>
                  <S.AnswerContent
                    selected={
                      anwser[currentQuestion.id]?.anwser_id_selected === item.id
                    }
                  >
                    {item.awnser}
                  </S.AnswerContent>
                </S.AnswerContentWrapper>
              ))}
            </S.AnswerWrapper>
          </>
          <S.ButtonWrapper first={current == 0}>
            {current > 0 && (
              <S.Button prev onClick={handlePrevious}>
                Anterior
              </S.Button>
            )}
            <S.Button next onClick={handleNext}>
              {current < quiz.questions.length - 1 ? 'Próximo' : 'Finalizar'}
            </S.Button>
          </S.ButtonWrapper>
        </S.Wrapper>
      </S.ModalBackground>
    </>
  );
};

export default TrainningModal;
