import { useEffect, useState, useRef } from 'react';
import { AiOutlineSwap } from 'react-icons/ai';

import { Form } from '@unform/web';

import Input from '~/components/Input';
import Loading from '~/components/Loading';
import Select from '~/components/Select';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import { appendObjectInFormData } from '~/utils';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { showNotification } from '~/utils/notificationBoxes';
import Confirm from '~/components/ConfirmCampain/index';
import * as S from './styles';
import UsersTable from '../../UsersTable';
import ProductsTable from '../ProductsTable';
import Rules from '../Rules';

const CreateCampaign = ({ currentUser }) => {
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [pdvs, setPdvs] = useState([]);
  const [selectedPdv, setSelectedPdv] = useState(null);
  const [totalPoints, setTotalPoints] = useState(0);
  const [users, setUsers] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newPoints, setNewPoints] = useState('');
  const [initialDate, setInitialDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [getValue, setGetValue] = useState([]);
  const [nomePlanos, setNomePlanos] = useState('');
  const [products, setProducts] = useState(null);
  const user = parseJwt();

  const PDVref = useRef(null);

  useEffect(() => {
    if ([2, 4].includes(user.canal_id)) {
      getPDV();
    }
  }, []);

  const getPDV = () => {
    setLoading(true);
    api
      .get('/gn/default/user/pdv')
      .then((response) => {
        setLoading(false);
        setPdvs(response.data);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  const getUsersByPdv = () => {
    if (user.canal_id == 5) {
      const pdv = PDVref.current?.getData();
      setLoading(true);
      api
        .get(`/gn/default/user/pdv-users?pdv=${pdv.pdvi}`)
        .then((response) => {
          setLoading(false);
          if (Array.isArray(response.data) && !response.data.length) {
            return showNotification(
              'danger',
              'Atenção',
              'Não foi encontrado nenhum usuário para essa loja.',
            );
          }
          setUsers(response.data);
          setFilteredUsers(response.data);
        })
        .catch((error) => {
          setLoading(false);
          HTTPErrorCallback(error);
        });
    } else {
      setLoading(true);
      api
        .get(`/gn/default/user/pdv-users?pdv=${selectedPdv}`)
        .then((response) => {
          setLoading(false);
          if (Array.isArray(response.data) && !response.data.length) {
            return showNotification(
              'danger',
              'Atenção',
              'Não foi encontrado nenhum usuário para essa loja.',
            );
          }
          setUsers(response.data);
          setFilteredUsers(response.data);
        })
        .catch((error) => {
          setLoading(false);
          HTTPErrorCallback(error);
        });
    }
  };

  const handlePdvChange = (e) => {
    const newPdv = e.target.value;
    if (newPdv !== null) {
      setSelectedPdv(e.target.value);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    let url;
    switch (currentUser.canal_id) {
      case 2:
        url = '/gn/varejo/user/campagn';
        break;
      case 4:
        url = '/gn/AA/user/campagn';
        break;
      case 5:
        url = '/gn/recarga/user/campagn';
        break;
      default:
        break;
    }
    api
      .post(url, {
        description: JSON.stringify(getValue),
        user: selectedUser?.usu_id,
        initial_date: initialDate,
        final_date: endDate,
        points: newPoints,
      })
      .then((response) => {
        setLoading(false);
        setConfirmModal(false);
        setUsers(null);
        setSelectedUser(null);
        return showNotification('success', 'Sucesso', response.data.message);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const buildDescription = () => {
    const inner = Object.values(getValue);
    const result = [];
    for (let i = 0; i < inner.length; i++) {
      const product = inner[i];
      const plan = products.find((item) => item.id === product.id);
      for (const prop in product) {
        const point = product[prop];
        if (point.amount > 0) {
          if (prop === 'points') {
            result.push(
              `${plan.product} ${plan.size ? plan.size + 'GB' : ''} - ${
                point.amount
              }x`,
            );
          }
          if (prop === 'ft_point') {
            result.push(
              `${plan.product} ${plan.size ? plan.size + 'GB' : ''} + ${
                plan.ft_name
              } - ${point.amount}x`,
            );
          }
          if (prop === 'st_point') {
            result.push(
              `${plan.product} ${plan.size ? plan.size + 'GB' : ''} + ${
                plan.st_name
              }- ${point.amount}x`,
            );
          }
          if (prop === 'both_points_not_null') {
            result.push(
              `${plan.product} ${plan.size ? plan.size + 'GB' : ''} + ${
                plan.ft_name
              } + ${plan.st_name}- ${point.amount}x`,
            );
          }
        }
      }
    }
    setNomePlanos(result);
    return;
  };

  const filter = (e) => {
    const input = e.target.value.toLowerCase();
    if (input && input !== '') {
      const newList = users.filter((user) => {
        return user.usu_nome.toLowerCase().includes(input);
      });
      setFilteredUsers(newList);
    }
  };

  const orderOptions = [
    { value: 'ASC', label: 'ASC' },
    { value: 'DESC', label: 'DESC' },
  ];

  const alphabeticSort = (order, list) => {
    if (order === 'ASC')
      return list.sort((a, b) => {
        if (a.usu_nome.toLowerCase() < b.usu_nome.toLowerCase()) return -1;
        if (a.usu_nome.toLowerCase() > b.usu_nome.toLowerCase()) return 1;
        return 0;
      });

    return list.sort((a, b) => {
      if (a.usu_nome.toLowerCase() < b.usu_nome.toLowerCase()) return 1;
      if (a.usu_nome.toLowerCase() > b.usu_nome.toLowerCase()) return -1;
      return 0;
    });
  };

  const sortOrder = (e) => {
    const order = e.value;
    setFilteredUsers([...alphabeticSort(order, filteredUsers)]);
  };
  return (
    <>
      {loading && <Loading />}
      {confirmModal && (
        <Confirm
          dataInicio={initialDate}
          dataFim={endDate}
          pontos={newPoints}
          itens={nomePlanos}
          lojaSelecionada={selectedPdv}
          nomeUsuario={
            users?.filter(
              (u) => u.usu_id === parseInt(selectedUser.usu_id, 10),
            )[0]?.usu_nome
          }
          onYes={() => handleSubmit()}
          onNo={() => setConfirmModal(false)}
        />
      )}
      <S.DataHeader>
        <Form ref={PDVref}>
          <S.InputWrapper>
            {parseJwt().canal_id == 5 ? (
              <>
                <span>Digite o IDPDV</span>
                <Input name="pdvi" />
              </>
            ) : (
              <>
                <span>Código da Loja</span>
                <S.Select onChange={handlePdvChange}>
                  {pdvs.map((pdv) => (
                    <option
                      key={pdv.codigo_ponto_de_venda}
                      value={pdv.codigo_ponto_de_venda}
                    >
                      {pdv.codigo_ponto_de_venda}
                    </option>
                  ))}
                </S.Select>
              </>
            )}
          </S.InputWrapper>
        </Form>
        <S.SubmitButton onClick={getUsersByPdv}>Consultar</S.SubmitButton>
      </S.DataHeader>
      {users && (
        <>
          <S.Data>
            <Form className="search">
              <span>Pesquisar por:</span>
              <Input name="search" onChange={filter} />
            </Form>
          </S.Data>
          <S.Data>
            <S.DataHeader>
              <p>Exibindo {users.length} resultados</p>
              <Form onSubmit={() => {}}>
                <AiOutlineSwap size={20} />
                <span>Ordenar por:</span>
                <Select
                  name="order"
                  placeholder="Selecione"
                  onChange={sortOrder}
                  options={orderOptions}
                />
              </Form>
            </S.DataHeader>
          </S.Data>
          <UsersTable
            users={filteredUsers}
            setSelectedUser={setSelectedUser}
            selectedUser={selectedUser}
          />
        </>
      )}
      {selectedUser && (
        <>
          <Rules />
          <ProductsTable
            loading={loading}
            setLoading={setLoading}
            setTotalPoints={setTotalPoints}
            getValue={getValue}
            setGetValue={setGetValue}
            setProducts={setProducts}
            products={products}
            currentUser={currentUser}
            selectedUser={selectedUser}
          />
          <Form
            onSubmit={() => {
              buildDescription();
              setConfirmModal(true);
            }}
          >
            <S.InputsWrapper>
              <S.DatesWrapper>
                <S.InputWrapper>
                  <span>De</span>
                  <S.DateInput
                    placeholder="Mês/Ano"
                    name="initial_date"
                    type="date"
                    required
                    value={initialDate}
                    onChange={(e) => setInitialDate(e.target.value)}
                  />
                </S.InputWrapper>
                <S.InputWrapper>
                  <span>Até</span>
                  <S.DateInput
                    placeholder="Mês/Ano"
                    name="final_date"
                    type="date"
                    required
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </S.InputWrapper>
              </S.DatesWrapper>
            </S.InputsWrapper>
            <S.InputWrapper>
              <span>
                Esse é o total de pontos que você pode distribuir nessa
                campanha: <b>{totalPoints}</b>
              </span>
              <br />
              <h3>
                Insira a quantidade de pontos que deseja distribuir nessa
                campanha:
              </h3>
              <S.PointsInput
                placeholder="0000"
                name="points"
                type="number"
                required
                value={newPoints}
                onChange={(e) => setNewPoints(e.target.value)}
              />
            </S.InputWrapper>
            {selectedUser && (
              <S.ButtonContainer>
                <button type="submit">Criar campanha</button>
              </S.ButtonContainer>
            )}
          </Form>
        </>
      )}
    </>
  );
};

export default CreateCampaign;
