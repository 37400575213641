import styled from 'styled-components/macro';
import media from 'styled-media-query';
import NexBG from '~/assets/Nex/background.jpg';

export const MainGrid = styled.div`
  display: grid;
  grid-template-areas:
    'headerLeft headerRight'
    'sidebar contentArea';
  max-width: 100vw;
  width: 100vw;
  max-height: var(--app-height);
  overflow: hidden;

  height: var(--app-height);
  grid-template-rows: 65px 1fr;
  grid-template-columns: auto 1fr;
  background-image: url(${NexBG});
  background-size: cover;
`;

export const ContentGrid = styled.div`
  grid-area: contentArea;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: 100%;

  display: grid;

  grid-template-rows: auto 1fr;
`;

export const DashboardGrid = styled.div`
  display: grid;
  width: 100%;

  grid-template-columns: 1fr 1fr;

  grid-gap: 30px;
  ${media.greaterThan('huge')` 
  grid-template-columns: 1fr 1fr 1fr;
 
 `}
  padding-bottom: 20px;
`;

export const CalculatorGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  max-width: 500px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    'hour value result'
    'footer footer footer';
  place-items: center;
`;

export const SelectDeviceGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: 'name name name name quantity quantity period period result result button button';
  place-items: auto stretch;
`;

export const SelectServiceGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: 'name name name name value value hours hours result result button button';
  place-items: auto stretch;
`;
export const SelectLogisticGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: 'name name name name quantity quantity period period result result button button';
  place-items: auto stretch;
`;
export const SelectSupportGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: 'name name name name quantity quantity period period result result button button';
  place-items: auto stretch;
`;

export const CreateActionGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: 'name name name name develop_deadline develop_deadline duration_deadline duration_deadline importants importants importants importants';
`;

export const ProjectDataGrid = styled.div`
  display: grid;
  grid-gap: 20px;

  grid-template-columns: repeat(8, 1fr);
  grid-template-areas: 'name name version event_date customer_input customer_input agency_input agency_input';
`;

export const ProjectPersonalDataGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  margin-top: ${(props) => props.mt};

  grid-template-columns: repeat(10, 1fr);
  grid-template-areas: 'user_name user_name user_email user_email user_email init_date partner_name partner_name partner_email partner_email';
`;

export const ProjectCardsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  grid-gap: 10px;
  padding: 30px 0px 10px;
`;

export const PriceGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  grid-template-columns: ${(props) => (props.columns ? props.columns : '1fr')};
`;

export const NotCoveredItemsSelectorGrid = styled.div`
  margin-top: 25px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas: 'name name name name name button';
`;

export const NotCoveredItemsGrid = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr;
`;

export const ActionButtonsGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.columns};
  width: fit-content;
  grid-gap: 5px;
  height: 100%;

  place-items: center;

  padding-top: 2px;
  svg {
    max-width: 18px;
    max-height: 15px;
  }
`;
export const ProfileGrid = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  width: 100%;
  grid-gap: 20px;
  height: auto;

  place-items: top center;
`;
