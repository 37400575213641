import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: grid;
  place-items: center;
`;

export const TextWrapper = styled.div`
  border: 1px solid;
  border-radius: 100px;
  padding: 1rem;
  display: grid;
  grid-template-columns: auto 24px;
  grid-gap: 16px;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  p {
    font-size: ${({ theme }) => theme.adminfontsize.containertitle};
    max-width: 300px;
    overflow: hidden;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;
