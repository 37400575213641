import React from 'react';

const Download = ({ color }) => {
  return (
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.200195 13.8C0.200195 13.5614 0.295016 13.3324 0.463799 13.1637C0.632582 12.9949 0.861501 12.9 1.1002 12.9H11.9002C12.1389 12.9 12.3678 12.9949 12.5366 13.1637C12.7054 13.3324 12.8002 13.5614 12.8002 13.8C12.8002 14.0387 12.7054 14.2677 12.5366 14.4364C12.3678 14.6052 12.1389 14.7 11.9002 14.7H1.1002C0.861501 14.7 0.632582 14.6052 0.463799 14.4364C0.295016 14.2677 0.200195 14.0387 0.200195 13.8ZM3.1639 6.86375C3.33267 6.69502 3.56155 6.60024 3.8002 6.60024C4.03884 6.60024 4.26772 6.69502 4.4365 6.86375L5.6002 8.02745V1.20005C5.6002 0.961354 5.69502 0.732435 5.8638 0.563653C6.03258 0.39487 6.2615 0.300049 6.5002 0.300049C6.73889 0.300049 6.96781 0.39487 7.13659 0.563653C7.30537 0.732435 7.4002 0.961354 7.4002 1.20005V8.02745L8.5639 6.86375C8.64692 6.77779 8.74623 6.70922 8.85603 6.66206C8.96584 6.61489 9.08393 6.59006 9.20343 6.58902C9.32294 6.58798 9.44145 6.61076 9.55205 6.65601C9.66266 6.70126 9.76315 6.76809 9.84765 6.85259C9.93215 6.9371 9.99898 7.03758 10.0442 7.14819C10.0895 7.2588 10.1123 7.37731 10.1112 7.49681C10.1102 7.61631 10.0854 7.73441 10.0382 7.84421C9.99102 7.95402 9.92245 8.05333 9.8365 8.13635L7.1365 10.8363C6.96772 11.0051 6.73884 11.0999 6.5002 11.0999C6.26155 11.0999 6.03267 11.0051 5.8639 10.8363L3.1639 8.13635C2.99517 7.96757 2.90039 7.7387 2.90039 7.50005C2.90039 7.2614 2.99517 7.03252 3.1639 6.86375Z"
        fill={color}
      />
    </svg>
  );
};
export default Download;
