import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  margin-top: 30px;
  padding: 20px 40px 40px;
`;

export const Text = styled.div`
  h2 {
    margin-top: 30px;
    font-size: 1.6rem;
  }
  .underline {
    text-decoration: underline;
  }
`;

export const Questions = styled.ul`
  list-style: none;
  /* background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  margin-top: 30px;
  padding: 90px 40px; */
`;

export const Message = styled.h1``;

export const WishTitle = styled.h3`
  font-size: 1.6rem;
  color: #c50101;
  font-weight: 700;
  text-align: left;
`;
