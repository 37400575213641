import { useState } from 'react';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';
import Loading from '~/components/Loading';
import { InputUpload, ToggleSwitch } from '~/components/NexAdmin/Inputs';
import * as S from './styles';
import { Form } from '@unform/web';
import Button from '~/components/NexAdmin/Buttons/Button';
import getIcon from '~/Helpers/getIcon';
import DownloadStaticFile from '~/components/NexAdmin/DownloadStaticFile';
import { model_columns, model_data, model_columns_download } from './columns';
import { showNotification } from '~/utils/notificationBoxes';
import { HTTPErrorCallback } from '~/Helpers/Error';
import UseAuthentication from '~/hooks/NexAdmin/AuthenticationHook';
import InsertTable from '../../UserRegistration/Components/InsertTable';

const CreateSales = () => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [tableLoad, setTableLoad] = useState(false);
  const { api, parseJwt } = UseAuthentication();
  const [channel] = useState({
    channel: parseJwt.canal_id,
    name: 'PAP Indireto',
    channel_route_path: 'pap-engine',
    columns: model_columns,
    downloadColumns: model_columns_download,
    modelColumns: model_data,
  });

  const validate = () => {
    setErrors([]);
    setData([]);

    if (!file)
      return showNotification(
        'warning',
        'Atenção',
        'Por favor, selecione um arquivo',
      );

    setLoading(true);

    const formData = new FormData();
    formData.append('sheet', file);
    formData.append('type', 'venda');
    api
      .post(`/${channel.channel_route_path}/validate`, formData)
      .then((response) => {
        const { data, errors } = response.data;
        if (Array.isArray(data) && data.length > 0) {
          setTableLoad(true);
          setData(data);
        }
        if (Array.isArray(errors) && errors.length > 0) {
          setTableLoad(true);
          setErrors(errors);
        }
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const save = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('sheet', file);
    formData.append('type', 'venda');

    api
      .post(`/${channel.channel_route_path}/upload`, formData)
      .then(() => {
        setLoading(false);
        showNotification(
          'success',
          'Sucesso',
          'Planilha de Vendas enviada com sucesso! Os dados serão processados no próximo dia.',
        );
        setTableLoad(false);
        setData([]);
        setErrors([]);
        setFile(null);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="actions"
          title={`Inserção e Atualização de Vendas - ${channel.name}`}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <S.Wrapper>
              <h2>Selecione um arquivo para validação</h2>

              <S.InputWrapper>
                <div className="itens">
                  <Form>
                    <InputUpload
                      name="file"
                      onGetFile={(e) => {
                        setData(null);
                        setErrors([]);
                        setFile(e);
                      }}
                      placeholder={
                        <>
                          {getIcon('upload')} Selecione um arquivo para upload
                        </>
                      }
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  </Form>
                  <Button type="button" width="50%" onClick={() => validate()}>
                    Validar
                  </Button>
                </div>
              </S.InputWrapper>

              {tableLoad && (
                <>
                  {errors?.length > 0 && (
                    <InsertTable
                      data={errors}
                      model_columns={[
                        ...channel.columns,
                        {
                          name: 'Erro',
                          selector: (row) => row.error,
                          cell: (row) => (
                            <div title={row.error}>{row.error}</div>
                          ),
                          sortable: true,
                        },
                      ]}
                      model_columns_download={[
                        ...channel.downloadColumns,
                        {
                          name: 'Erro',
                          selector: (row) => row.error,
                          cell: (row) => (
                            <div title={row.error}>{row.error}</div>
                          ),
                          sortable: true,
                        },
                      ]}
                      type="error"
                      isFrom="vendas"
                    />
                  )}
                  {data?.length > 0 && (
                    <>
                      <InsertTable
                        data={data}
                        model_columns={channel.columns}
                        model_columns_download={channel.downloadColumns}
                        isFrom="vendas"
                      />
                      <Button type="button" width="50%" onClick={() => save()}>
                        Confirmar Agendamento
                      </Button>
                    </>
                  )}
                </>
              )}
            </S.Wrapper>

            <DownloadStaticFile
              columns={channel.columns}
              list={channel.modelColumns}
              file_name={`Planilha Modelo de Inserção/Atualização de Vendas - ${channel.name}`}
              button_name={'Download Modelo'}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default CreateSales;
