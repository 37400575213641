import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 20px;
  width: 50rem;
  margin-bottom: ${({ theme }) => theme.spacing.contentH};
`;

export const ButtonsGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
