import { useState, useEffect } from 'react';
import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import api from '~/services/api';
import Loading from '~/components/Loading';
import Table from '~/components/NexAdmin/Table';
import { HTTPErrorCallback } from '~/Helpers/Error';
import column from './columns';
import { toDateBR } from '~/Helpers/Date';
import { Input, Select } from '~/components/NexAdmin/Inputs/index';
import { Button } from '~/components/NexAdmin/Buttons';
import { Form } from '@unform/web';
import * as S from './styles';
import Counter from '~/components/NexAdmin/Counter';
import Action from '../Component/Action';

const List = () => {
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    getCampagns();
  }, []);

  const getCampagns = (data) => {
    setLoading(true);
    const query = new URLSearchParams(data);
    api
      .get(`/campaign-exhibiton/admin/get-campagns?${query}`)
      .then((response) => {
        setCampaigns(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };
  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag icon="statusSketch" title="Carrossel de Mecânicas" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <Form onSubmit={getCampagns}>
              <S.FormWrapper>
                <S.FormFilters>
                  <Input name="initial_date" label="De:" type="date" />
                  <Input name="final_date" label="Até:" type="date" />
                  <Select
                    name="is_active"
                    label="Status"
                    options={[
                      { label: 'Todos', value: '*' },
                      { label: 'Ativa', value: true },
                      { label: 'Inativa', value: false },
                    ]}
                    placeholder="Selecione o status"
                  />
                </S.FormFilters>
                <Button type="submit">Pesquisar</Button>
              </S.FormWrapper>
            </Form>
            {campaigns && <Counter total={campaigns.length} />}
            <Table
              data={campaigns}
              columns={[
                {
                  name: 'Ações',
                  cell: (row) => (
                    <Action
                      row={row}
                      setLoading={setLoading}
                      refresh={getCampagns}
                    />
                  ),
                },
                ...column,
              ]}
              downloadColumns={column}
              dataTableProps={{ pagination: true }}
              header
              dropdownItems={false}
              hasDownload={true}
              fileName={`Relatório de Carroussel de Campanhas - ${toDateBR(
                new Date(),
              )}`}
              refresh={getCampagns}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default List;
