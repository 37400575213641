import * as S from './styles';

const Title = ({ title }) => {
  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>{title}</S.Title>
      </S.Header>
    </S.Wrapper>
  );
};

export default Title;
