import { parseJwt } from '~/services/auth';
import * as S from './styles';

const ExtractDescription = () => {
  const user = parseJwt();
  return (
    <S.ThermWrapper>
      <br />
      Esta tela exibe o extrato de um único usuário com base em seu{' '}
      <strong>CPF</strong>.
      <br />
      <br />
      Caso queria fazer o download do relatório, primeiro execute o passo acima
      e, após isso, clique no botão “Download”.
      <br />
      <br />
      Os dados são exibidos em uma tabela. É descrito a quantidade de vendas que
      foram encontradas em sua busca e se todos os dados estão sendo exibidos.
      <br />
      <br />
      Caso sua pesquisa encontre mais de 100 resultados, é necessário que faça o
      download do relatório para visualizar todos os produtos encontrados.
      <br />
      <br />
      <strong>Descritivo dos filtros:</strong>
      <br />
      <strong>CPF:</strong> Utilize para buscar o extrato de um único usuário
      pelo seu CPF.
      <br />
      <br />
      <strong>Descritivo das colunas da tabela:</strong>
      <br />
      <strong>1. Data:</strong> Data que a venda foi efetuada.
      <br />
      <strong>2. Origem:</strong> Indica o status da venda.
      <br />
      <strong>3. Descrição:</strong> Descrição dos produtos contidos na venda.
      <br />
      <strong>4. ID:</strong> ID da transação.
      <br />
      <strong>5. Pontos:</strong> Pontos obtidos a partir da venda.
      <br />
      <strong>6. Status:</strong> Indica o status do ponto.
      {user.canal_id == 5 && (
        <>
          <br />
          <strong>8. IDPDV:</strong> ID do PDV do usuário.
        </>
      )}
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default ExtractDescription;
