import styled from 'styled-components';

export const DownloadWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding-top: 20px;
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  max-width: 100%;
`;

export const FormItems = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
  max-width: 100%;
`;

export const ButtonWrapper = styled.div`
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
