import { showNotification } from '~/utils/notificationBoxes';

export const verifyFile = (event, arr) => {
  const files = event.target.files;
  for (const file of files) {
    if (!file) return;
    const extension = file.name?.toLowerCase().split('.').pop();
    const allowed = ['jpeg', 'png', 'jfif', 'xlsx', 'pdf', 'jpg'];
    if (!allowed.includes(extension)) {
      delete event.target.files[0].name[file.name];
      return showNotification(
        'warning',
        'Atenção!',
        'Formato de arquivos permitidos: JPEG, JPG, PNG, JFIF, XLSX ou PDF.',
      );
    }
    if (file.size > 15000000) {
      delete event.target.files[0].name[file.name];
      return showNotification(
        'warning',
        'Atenção!',
        'Insira um arquivo menor que 15MB!',
      );
    }
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = (e) => {
      arr((prev) => {
        return [...prev, { [file.name]: e.target.result }];
      });
    };
  }
};
