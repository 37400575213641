import styled from 'styled-components';
import media from 'styled-media-query';

export const ScrollUp = styled.button`
  bottom: ${({ scrolled }) => (scrolled ? '75px' : '-100px')};
  position: fixed;
  transition: ease all 0.6s;
  right: 25px;
  cursor: pointer;
  z-index: 101;
  background-color: transparent;
  display: none;
  background-color: white;
  border-radius: 50%;
  padding: 3px;
  padding-bottom: 0px;
  ${media.greaterThan('medium')`
display:block;
`}
`;

export const Container = styled.div`
  display: grid;
  text-align: justify-content;
  // height: 1500px;
  margin: auto;
  grid-template-rows: auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto auto;
  grid-template-areas:
    'quiz'
    'campaigns'
    'banner'
    'metas'
    'gigaline'
    'ranking'
    'wishlist'
    'landingPages'
    'extract'
    'media'
    'aboutCampaign'
    'tablePoints';
  gap: 30px 0;

  ${media.greaterThan('medium')`
    text-align: left;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto auto;
    /* grid-template-rows: auto auto auto auto auto auto; */
    gap: 30px 20px;
    grid-template-areas:
      "quiz quiz"
      "campaigns campaigns"
      "banner banner"
      "metas metas"
      "gigaline ranking"
      "gigaline wishlist"
      "landingPages landingPages"
      "extract extract"
      "media media"
      "aboutCampaign tablePoints";
  `}

  h1 {
    font-weight: ${({ theme }) => theme.font.bold};
  }
`;
