import styled from 'styled-components';

export const PDVSCard = styled.div`
  margin-top: 2rem;
  table {
    border-collapse: collapse;
    width: 100%;
  }
  td,
  th {
    border: 1px solid #f2f2f2;
    text-align: left;
    padding: 8px;
    gap: 5px;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
`;
