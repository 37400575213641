import { useEffect, useRef, useState } from 'react';
import { AiOutlineSwap, AiFillHeart as Heart } from 'react-icons/ai';
import { MdPlayArrow as Triangle } from 'react-icons/md';

import { Form } from '@unform/web';

import Filter from '~/components/Filter';
import Select from '~/components/Select';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import { findByAttibutes, goTopPage } from '~/utils';

import CalculatorTable from './components/CalculatorTable';
import Wishes from './components/whishes';
import * as S from './styles';

const Calculator = () => {
  const formRef = useRef(null);
  const [checkboxOptions, setCheckboxOptions] = useState([]);
  const [plans, setPlans] = useState([]);
  const [choosedItems, setChoosedItems] = useState([]);
  const [points, setPoints] = useState(0);
  const [wishListClicked, setWishListClicked] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    api.get(`/loja/wishlist/${parseJwt().login}`).then((response) => {
      setProducts(JSON.parse(response.data.data));
    });
  }, []);

  useEffect(() => {
    const channel = localStorage.getItem('canal');
    const checkboxPreOptions = [
      { id: '1', channels: [2], value: 'prepago', label: 'Pré-Pago' },
      {
        id: '2',
        channels: [2, 4],
        value: 'controleboleto',
        label: 'Controle Boleto',
      },
      {
        id: '3',
        channels: [2, 4],
        value: 'controlefacil',
        label: 'Controle Fácil',
      },
      { id: '4', channels: [2, 4], value: 'pospago', label: 'Pós-Pago' },
      { id: '5', channels: [2, 4], value: 'dependente', label: 'Dependente' },
      { id: '6', channels: [2, 4], value: 'bandaLarga', label: 'Banda Larga' },
      { id: '7', channels: [5], value: 'chip', label: 'Chip' },
      { id: '8', channels: [5], value: 'recarga', label: 'Recarga' },
      { id: '9', channels: [2], value: 'tv', label: 'TV' },
      { id: '10', channels: [2], value: 'fixo', label: 'Fixo' },
      { id: '11', channels: [2], value: 'comboMulti', label: 'Combo Multi' },
      { id: '12', channels: [6], value: 'aparelhos', label: 'Aparelhos' },
      { id: '13', channels: [6], value: 'acessorios', label: 'Acessórios' },
      { id: '14', channels: [6], value: 'modem', label: 'Modem' },
      { id: '15', channels: [6], value: 'roteador', label: 'Roteador' },
    ];

    const finalOptions = [];

    checkboxPreOptions.forEach((checkbox) => {
      const result = checkbox.channels.find((value) => {
        return parseInt(value, 16) === parseInt(channel, 16);
      });

      if (result) finalOptions.push({ ...checkbox });
    });

    setCheckboxOptions(finalOptions);
  }, []);

  const orderOptions = [
    { value: 'Mais recentes', label: 'Mais recentes' },
    { value: 'Mais antigos', label: 'Mais antigos' },
  ];

  useEffect(() => {
    goTopPage();
  }, []);

  const handleSubmit = (data) => {
    const values = data.checkbox;
    let prePago = false;
    let controleBoleto = false;
    let controleFacil = false;
    let posPago = false;
    let dependente = false;
    let bandaLarga = false;
    let chip = false;
    let recarga = false;
    let tv = false;
    let fixo = false;
    let comboMulti = false;
    let aparelhos = false;
    let acessorios = false;
    let modem = false;
    let roteador = false;

    values.forEach((value) => {
      switch (value) {
        case 'prepago':
          prePago = true;
          break;
        case 'controleboleto':
          controleBoleto = true;
          break;
        case 'controlefacil':
          controleFacil = true;
          break;
        case 'pospago':
          posPago = true;
          break;
        case 'dependente':
          dependente = true;
          break;
        case 'bandaLarga':
          bandaLarga = true;
          break;
        case 'chip':
          chip = true;
          break;
        case 'recarga':
          recarga = true;
          break;
        case 'tv':
          tv = true;
          break;
        case 'fixo':
          fixo = true;
          break;
        case 'combomulti':
          comboMulti = true;
          break;
        case 'aparelhos':
          aparelhos = true;
          break;
        case 'acessorios':
          acessorios = true;
          break;
        case 'modem':
          modem = true;
          break;
        case 'roteador':
          roteador = true;
          break;
        default:
          break;
      }
    });

    const finalData = {
      usuario: parseJwt().id,
      canal: localStorage.getItem('canal'),
      prePago,
      controleBoleto,
      controleFacil,
      posPago,
      dependente,
      bandaLarga,
      chip,
      recarga,
      tv,
      fixo,
      comboMulti,
      aparelhos,
      acessorios,
      modem,
      roteador,
    };

    api.post('/calculadora/buscar-plano', finalData).then((response) => {
      setPlans(response.data.data);
    });
  };

  const handleWish = () => {
    setWishListClicked(!wishListClicked);
  };

  const itemSelected = (skuId, willAdd) => {
    const foundItem = products[findByAttibutes(products, 'skuId', skuId)];
    if (willAdd && foundItem) {
      setPoints(points + foundItem.sellingPrice);
      choosedItems.push(foundItem);
    } else {
      const foundIndex = choosedItems.indexOf(foundItem);
      setPoints(points - foundItem.sellingPrice);
      choosedItems.splice(foundIndex, 1);
    }
    return true;
  };

  const pointClicked = (value) => {
    if (wishListClicked || value) {
      setPoints(0);
      setChoosedItems([]);

      document.querySelectorAll('.wishlist-checkbox').forEach((element) => {
        element.checked = false;
      });

      return setWishListClicked(false);
    }

    return false;
  };

  return (
    <>
      <S.WishWrapper>
        <S.WishesTitle>
          <p>Favoritos</p>
        </S.WishesTitle>
        <Wishes />
      </S.WishWrapper>
      <S.Wrapper>
        <S.Text>
          Saiba quantos planos e pontos você precisa para resgatar o seu prêmio.
          Acesse o catálogo de produtos, coloque o prêmio na sua lista de
          favoritos e escolha o plano que você comercializa e pronto! O Giga
          Clube faz o cálculo necessário para você resgatar o que tanto quer.
        </S.Text>
        <S.CalculatorWrapper>
          <S.CalculatorTitle>Minha meta de pontos:</S.CalculatorTitle>
          <S.CalculatorInput onClick={() => pointClicked()}>
            <input
              defaultValue="000"
              value={points}
              type="number"
              onChange={(e) => setPoints(e.target.value)}
            />
          </S.CalculatorInput>
          <S.WishListWrapper>
            <S.WishListButton
              wishListClicked={wishListClicked}
              onClick={handleWish}
            >
              <p>Usar produtos da minha lista de favoritos</p>
              <Heart color="white" />
            </S.WishListButton>
            <S.WishList wishListClicked={wishListClicked}>
              <Triangle className="arrow" size="30" color="white" />
              <S.WishListHeader>
                <S.WishCounterWrapper>
                  <span className="counter">{choosedItems.length}</span>
                  <span className="title">Favoritos</span>
                </S.WishCounterWrapper>
                <S.WishCounterWrapper>
                  <S.WishHeaderButton onClick={() => pointClicked(true)}>
                    Limpar
                  </S.WishHeaderButton>
                  <S.WishHeaderButton onClick={handleWish}>
                    Confirma
                  </S.WishHeaderButton>
                </S.WishCounterWrapper>
              </S.WishListHeader>
              <S.WishListContent>
                {products.map((product) => (
                  <S.WishListItem>
                    <S.WishListItemThumb>
                      <img className="logo" src={product.mediumImage} alt="" />
                    </S.WishListItemThumb>
                    <S.WishListItemDescription>
                      <span className="title">{product.productName}</span>
                      <span className="points">
                        {product.sellingPrice ? product.sellingPrice : 0} pontos
                      </span>
                    </S.WishListItemDescription>
                    <S.WishListItemCheckbox>
                      <input
                        type="checkbox"
                        className="wishlist-checkbox"
                        value={product.skuId}
                        onChange={(e) =>
                          itemSelected(e.target.value, e.target.checked)
                        }
                      />
                    </S.WishListItemCheckbox>
                  </S.WishListItem>
                ))}
              </S.WishListContent>
            </S.WishList>
          </S.WishListWrapper>
          <S.FilterWrapper>
            <Filter
              calculator
              checkboxOptions={checkboxOptions}
              handleSubmit={handleSubmit}
              title="Visualizar Apenas"
              linkIsDownload
              button="Consultar"
            />
          </S.FilterWrapper>
          <S.Comment>
            *Os valores exibidos são apenas para referência, e não incluem o
            valor do frete.
          </S.Comment>
        </S.CalculatorWrapper>
        <S.Hint>
          <img src="/images/hint.png" alt="Banner" />
          <p>
            Dica: selecione planos que você tenha mais facilidade para vender,
            lembrando que os que bonificam mais farão você atingir o objetivo
            mais rápido!
          </p>
        </S.Hint>
      </S.Wrapper>
      {plans.length > 0 && (
        <S.Wrapper>
          <S.DataHeader>
            <p> </p>
            <Form ref={formRef} onSubmit={() => {}}>
              <AiOutlineSwap size={20} />
              <span>Ordenar por:</span>
              <Select
                name="order"
                options={orderOptions}
                placeholder="Selecione"
              />
            </Form>
          </S.DataHeader>
          <CalculatorTable
            plans={plans}
            points={points}
            setPlans={setPlans}
            setPoints={setPoints}
          />
          {/* <ResultTable /> */}
        </S.Wrapper>
      )}
    </>
  );
};

export default Calculator;
