import React from 'react';

const TrainningBook = ({ color }) => {
  return (
    <svg
      width="33"
      height="34"
      viewBox="0 0 33 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.125 2.09766L19.25 8.97266V24.0977L26.125 17.9102V2.09766ZM28.875 7.59766V26.1602C27.3625 25.6789 25.7125 25.4727 24.0625 25.4727C21.725 25.4727 18.3563 26.3664 16.5 27.5352V8.97266C14.5062 7.46016 11.6188 6.91016 8.9375 6.91016C6.25625 6.91016 3.36875 7.46016 1.375 8.97266V29.1164C1.375 29.4602 1.71875 29.8039 2.0625 29.8039C2.2 29.8039 2.26875 29.7352 2.40625 29.7352C4.2625 28.8414 6.94375 28.2227 8.9375 28.2227C11.6188 28.2227 14.5062 28.7727 16.5 30.2852C18.3563 29.1164 21.725 28.2227 24.0625 28.2227C26.3312 28.2227 28.6688 28.6352 30.5938 29.6664C30.7313 29.7352 30.8 29.7352 30.9375 29.7352C31.2812 29.7352 31.625 29.3914 31.625 29.0477V8.97266C30.8 8.35391 29.9062 7.94141 28.875 7.59766ZM13.75 26.0364C12.0312 25.5964 10.3125 25.4727 8.9375 25.4727C7.48 25.4727 5.7475 25.7339 4.125 26.1602V10.5264C5.37625 9.97641 7.0675 9.66016 8.9375 9.66016C10.8075 9.66016 12.4987 9.97641 13.75 10.5264V26.0364Z"
        fill={color}
      />
    </svg>
  );
};
export default TrainningBook;
