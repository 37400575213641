import styled from 'styled-components';
import media from 'styled-media-query';

export const WishContainer = styled.div`
  .rec.rec-arrow {
    border-radius: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #c50101;
  }
  .rec-carousel-item-visible {
    .progress {
      animation: anima 1.5s ease-in-out forwards;
    }
  }

  .rec-item-wrapper {
    min-height: 200px;
  }

  .rec-dot_active {
    box-shadow: 0 0 1px 3px #c50101;
    background-color: #c5010160;
  }
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 20px;
  width: 100vw;
  overflow-x: hidden;
  ${media.greaterThan('medium')`
            width: 100%;
        `};
`;

export const WishScrollArea = styled.div`
  overflow-x: auto;
  overflow-y: hidden;

  width: 100vw;
  overflow-x: hidden;
  ${media.greaterThan('medium')`
        padding: 20px;

        width: 100%;
    `};
`;
