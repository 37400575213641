import React from 'react';

const CameraPlus = ({ color }) => {
  return (
    <svg
      width="37"
      height="34"
      viewBox="0 0 37 34"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.75 5.33331V0.583313H7.91667V5.33331H12.6667V8.49998H7.91667V13.25H4.75V8.49998H0V5.33331H4.75ZM9.5 14.8333V10.0833H14.25V5.33331H25.3333L28.1833 8.49998H33.25C34.9917 8.49998 36.4167 9.92498 36.4167 11.6666V30.6666C36.4167 32.4083 34.9917 33.8333 33.25 33.8333H7.91667C6.175 33.8333 4.75 32.4083 4.75 30.6666V14.8333H9.5ZM20.5833 29.0833C27.6292 29.0833 31.1758 20.565 26.1883 15.5616C21.2008 10.5741 12.6667 14.1208 12.6667 21.1666C12.6667 25.5366 16.2133 29.0833 20.5833 29.0833ZM15.5167 21.1666C15.5167 25.6791 20.9792 27.9433 24.1617 24.745C27.36 21.5625 25.0958 16.1 20.5833 16.1C19.2409 16.1042 17.9546 16.6393 17.0053 17.5886C16.056 18.5379 15.5208 19.8242 15.5167 21.1666Z"
        fill={color}
      />
    </svg>
  );
};
export default CameraPlus;
