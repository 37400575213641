import React from 'react';

const User = ({ color }) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4372 0.833496C12.8517 0.833496 14.2082 1.3954 15.2084 2.39559C16.2086 3.39579 16.7705 4.75234 16.7705 6.16683C16.7705 7.58132 16.2086 8.93787 15.2084 9.93806C14.2082 10.9383 12.8517 11.5002 11.4372 11.5002C10.0227 11.5002 8.66613 10.9383 7.66594 9.93806C6.66574 8.93787 6.10384 7.58132 6.10384 6.16683C6.10384 4.75234 6.66574 3.39579 7.66594 2.39559C8.66613 1.3954 10.0227 0.833496 11.4372 0.833496V0.833496ZM11.4372 3.50016C10.7299 3.50016 10.0517 3.78111 9.55156 4.28121C9.05146 4.78131 8.77051 5.45958 8.77051 6.16683C8.77051 6.87407 9.05146 7.55235 9.55156 8.05245C10.0517 8.55254 10.7299 8.83349 11.4372 8.83349C12.1444 8.83349 12.8227 8.55254 13.3228 8.05245C13.8229 7.55235 14.1038 6.87407 14.1038 6.16683C14.1038 5.45958 13.8229 4.78131 13.3228 4.28121C12.8227 3.78111 12.1444 3.50016 11.4372 3.50016V3.50016ZM11.4372 12.8335C14.9972 12.8335 22.1038 14.6068 22.1038 18.1668V22.1668H0.770508V18.1668C0.770508 14.6068 7.87717 12.8335 11.4372 12.8335ZM11.4372 15.3668C7.47717 15.3668 3.30384 17.3135 3.30384 18.1668V19.6335H19.5705V18.1668C19.5705 17.3135 15.3972 15.3668 11.4372 15.3668V15.3668Z"
        fill={color}
      />
    </svg>
  );
};
export default User;
