import { NavLink } from 'react-router-dom';

import styled from 'styled-components';

export const Wrapper = styled(NavLink)`
  background: ${({ theme }) => theme.colors.degrade};
  /* height: 50px; */
  border-radius: 25px;
  border: 0;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  /* width: 100%; */
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  width: ${(props) => (props.full ? '100%' : 'auto')};
  svg {
    width: 2.3rem;
    height: 2.3rem;
    margin-right: 10px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;
