import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.figure`
  ${({ isRank }) =>
    isRank &&
    css`
      background-image: url('/images/bg-avatar-profile.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    `}

  width: 93px;
  height: 85px;
  margin-right: 10px;

  display: flex;
  justify-content: center;

  ${media.greaterThan('medium')`
    width: 128px;
    height: 117px;
  `};
`;

export const ImgWrapper = styled.div`
  border-radius: 50%;
  width: 57px;
  height: 57px;
  margin-top: 9px;
  ${media.greaterThan('medium')`
width: 80px;
height: 80px;
margin-top: 11px;
`}
  overflow: hidden;
  display: flex;
  img {
    object-fit: cover;
    position: relative;

    ${media.greaterThan('medium')`

    `}
  }
`;
