import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  padding: 70px 20px;
  width: 100vw;
  overflow-x: hidden;
  ${media.greaterThan('medium')`
width: 100%;
padding: 70px 50px;
`};
  table {
    table {
      border: none;
    }

    display: table;
    .order-td {
      display: flex;
      flex-direction: row;
      border-radius: 30px;
      width: 100%;

      &.proccessing {
        background: #f2f2f2;
      }
      &.error {
        background: #ffe8e8;
      }
      &.concluded {
        background: rgba(0, 193, 54, 0.1);
      }
    }
    .order-title {
      font-weight: 700;
    }
    td {
      display: table-cell;
      vertical-align: middle;
      margin: 10px 0px;
      padding: 10px 10px;
      max-width: 150px;
      font-size: 1.3rem;
      &.reproved {
        color: #c50101;
      }
      &.aproved {
        color: #00c136;
      }
    }

    /* background-color: #ccc; */
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.grayDarker};

    thead {
      font-weight: 700;
      font-size: 1.6rem;
      border-radius: 30px;
      overflow: hidden;
      td {
        &:first-child {
          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;
        }
        &:last-child {
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
        }
        padding: 10px 20px;
      }
      button {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.red};
        font-weight: 700;
      }
      &.points-thead {
        border: none;
        background-color: #f2f2f2;
        color: ${({ theme }) => theme.colors.red};
      }
    }
    svg {
      fill: ${({ theme }) => theme.colors.red};
    }
  }
`;

export const Message = styled.h1``;

export const Wrapper = styled.section`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  margin-top: -10px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
  width: 100%;
`;

export const DataWrapper = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  ${media.greaterThan('medium')`
  justify-content: flex-start;
  // align-items: center
  flex-direction: row;
  gap:20px;
  white-space: nowrap;
`};
`;

export const DataHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-grow: 3; */
  margin-bottom: 30px;
  flex-direction: column-reverse;
  gap: 30px;
  ${media.greaterThan('medium')`
flex-direction: row;
`};
  form {
    display: flex;
    align-items: center;
    width: 340px;

    svg {
      transform: rotate(-90deg);
      fill: ${({ theme }) => theme.colors.grayDarker};
    }

    span {
      margin-right: 10px;
      font-weight: 700;
      font-size: 1.6rem;
      color: ${({ theme }) => theme.colors.grayDarker};
    }
  }
  p {
    font-size: 1.4rem;
  }
`;

export const SubmitButton = styled.button`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  max-height: 50px;
  width: 150px;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;
