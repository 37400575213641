import { useState } from 'react';
import Loading from '~/components/Loading';
import UpdateTicket from '../Update';
import * as S from './styles';
import ListTicket from '../Componentes/List';

const Tickets = () => {
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [step, setStep] = useState('list');
  const [current, setCurrent] = useState({});

  const switchStep = (step) => {
    switch (step) {
      case 'list':
        return (
          <ListTicket
            setTickets={setTickets}
            setCurrent={setCurrent}
            setStep={setStep}
            tickets={tickets}
            loading={loading}
            setLoading={setLoading}
            current={current}
          />
        );
      case 'update':
        return (
          <UpdateTicket
            ticket={current}
            setStep={setStep}
            setCurrent={setCurrent}
          />
        );
    }
  };

  return (
    <>
      {loading && <Loading />}
      <S.Wrapper>{switchStep(step)}</S.Wrapper>
    </>
  );
};

export default Tickets;
