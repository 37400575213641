import { HTTPErrorCallback } from '~/Helpers/Error';
import api from '~/services/api';
import { useState, useRef, useEffect } from 'react';
import DownloadHook from '~/hooks/NexAdmin/DownloadHook';
import Loading from '~/components/Loading';
import Table from '~/components/NexAdmin/Table';
import Counter from '~/components/NexAdmin/Counter';
import { buildReportName } from '~/utils/NexAdmin/utils';
import { Form } from '@unform/web';
import { quizColumn } from '../columns';
import { Input, Select } from '~/components/NexAdmin/Inputs';
import Download from '~/components/NexAdmin/Download/Download';
import { appendObjectInFormData } from '~/utils';
import { Button } from '~/components/NexAdmin/Buttons';
import * as S from '../User/styles';
import ConfirmButton from '~/components/NexAdmin/Buttons/ConfirmButton';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import Update from '../../Components/UpdateQuiz';
import QuizzesProvider from '~/contexts/QuizzesContext';
import UpdateQuestion from '../../Components/UpdateQuestions';

const Quiz = () => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [quiz, setQuiz] = useState([]);
  const formRef = useRef();
  const { openModal, hideModal } = useModal();

  useEffect(() => handleSubmit(), []);

  const updateQuiz = (row) => {
    openModal({
      title: 'Edição de Treinamento',
      close: true,
      content: (
        <QuizzesProvider>
          <Update hideModal={hideModal} row={row} refresh={handleSubmit} />
        </QuizzesProvider>
      ),
    });
  };

  const updateQuestion = (row) => {
    openModal({
      title: 'Edição de Perguntas/Respostas',
      close: true,
      content: (
        <UpdateQuestion
          hideModal={hideModal}
          row={row}
          refresh={handleSubmit}
        />
      ),
    });
  };

  const handleSubmit = (data) => {
    setLoading(true);
    const form = new FormData();
    appendObjectInFormData(form, {
      ...data,
    });
    const params = new URLSearchParams(form);
    api
      .get(`/quizzes/admin/quizzes?${params}`)
      .then((response) => {
        const { data, total } = response.data;
        setCount(total);
        setQuiz(data);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const handleDownload = () => {
    const data = formRef.current.getData();
    const form = new FormData();
    appendObjectInFormData(form, {
      download: true,
      ...data,
    });
    const params = new URLSearchParams(form);
    return api.get(`/quizzes/admin/quizzes?${params}`, {
      responseType: 'blob',
    });
  };

  const { execute, ref, urlFile, nameFile } = DownloadHook({
    externalCall: handleDownload,
    onError: HTTPErrorCallback,
    getFileName: buildReportName('Treinamento'),
    beforeLoading: () => setLoading(true),
    afterLoading: () => setLoading(false),
  });

  return (
    <>
      {loading && <Loading />}
      <Form ref={formRef} onSubmit={handleSubmit}>
        <S.FormWrapper>
          <Input name="title" label="Nome Treinamento" />
          <Input name="cpf" label="CPF Usuário" />
          <Input name="initial_date" label="De:" type="date" />
          <Input name="final_date" label="Até:" type="date" />
          <Select
            name="status"
            label="Status"
            options={[
              { label: 'Todos', value: '*' },
              { label: 'Finalizado', value: 100 },
              { label: 'Em Andamento', value: 0 },
            ]}
          />
        </S.FormWrapper>
        <S.ButtonWrapper>
          <Button type="submit">Consultar</Button>
        </S.ButtonWrapper>
      </Form>
      <S.ButtonWrapper>
        <a href={urlFile} download={nameFile} ref={ref} />
        <Download buttonName="Download" external={execute} />
      </S.ButtonWrapper>
      <Counter total={count} />
      <Table
        data={quiz}
        columns={[
          {
            name: 'Ações',
            cell: (row) => (
              <>
                <ConfirmButton
                  icon={'trainningBook'}
                  iconColor={'green'}
                  label="EDITAR TREINAMENTO?"
                  confirmLabel={'SIM'}
                  cancelLabel="CANCELAR"
                  openOnRight={true}
                  confirmAction={() => updateQuiz(row)}
                />
                <ConfirmButton
                  icon={'statusSketch'}
                  iconColor={'BLUE'}
                  label="EDITAR PERGUNTAS E RESPOSTAS?"
                  confirmLabel={'SIM'}
                  cancelLabel="CANCELAR"
                  openOnRight={true}
                  confirmAction={() => updateQuestion(row)}
                />
              </>
            ),
          },
          ...quizColumn,
        ]}
        dataTableProps={{ pagination: true }}
        header
        dropdownItems={false}
        hasDownload={false}
      />
    </>
  );
};

export default Quiz;
