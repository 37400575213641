import { useHistory } from 'react-router-dom';

import * as S from './styles';

const Logo = ({ origin, href }) => {
  const history = useHistory();
  return (
    <S.Container
      onClick={() => origin !== 'signin' && history.push('/admin/dashboard')}
    >
      <a href={href}>
        {origin === 'signin' && (
          <img src="/images/logo-signin-new.png" alt="Logo Claro Clube" />
        )}
        {origin === 'header' && (
          <img src="/images/logo-header-new.png" alt="Logo Claro Clube" />
        )}
        {origin === 'footer' && (
          <img src="/images/logo-footer-new.png" alt="Logo Claro Clube" />
        )}
      </a>
    </S.Container>
  );
};

export default Logo;
