import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 50%;
  height: 50px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: -10px;
  z-index: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;

  &.noactive {
    -moz-box-shadow: inset 0 -10px 10px -10px #00000020;
    -webkit-box-shadow: inset 0 -10px 10px -10px #00000020;
    box-shadow: inset 0 -10px 10px -10px #00000020;
    &:hover {
      background-color: #e6e6e6;
      color: ${({ theme }) => theme.colors.grayDarker};
    }
  }
  color: ${({ theme }) => theme.colors.grayDarker};
  font-weight: 700;
  &.active {
    background-color: white;
    color: ${({ theme }) => theme.colors.red};
  }
`;

export const Message = styled.h1``;
