import { useField } from '@unform/core';
import React, { useEffect, useRef } from 'react';
import InputSelect from 'react-select/async';
import { components } from 'react-select';
import { useTheme } from 'styled-components';
import getIcon from '~/Helpers/getIcon';
import * as S from './styles';

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      Não há opções..
    </components.NoOptionsMessage>
  );
};

const Select = ({
  loadOptions,
  alertType,
  alertMessage,
  label,
  large,
  small,
  status,
  placeholder = '',
  gridarea,
  name,
  ...rest
}) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);
  const theme = useTheme();
  const mainColor = theme.oldColors.primary[40];
  const blackColor = theme.admincolors.contrast.black;
  const optionHoverColor = theme.oldColors.primary[20];
  const borderColor = '#adadad';

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.select.state.value) {
            return [];
          }
          return ref.select.state.value.map((option) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const customStyles = {
    option: (base, state) => ({
      ...base,
      color: `${blackColor}`,
      backgroundColor:
        (state.isHover || state.isFocused || state.isActive) &&
        `${optionHoverColor}`,
    }),
    control: (base, state) => ({
      ...base,

      boxShadow:
        state.isFocused || (state.isHover && state.isFocused)
          ? `0 0 0 1px ${mainColor}`
          : 0,
      fontSize: 16,
      margin: 0,
      textAlign: 'left',

      borderRadius: 30,
      borderColor:
        state.isFocused || (state.isHover && state.isFocused)
          ? mainColor
          : `${borderColor}`,
    }),
  };
  const { Option } = components;

  const IconSelectOption = ({ value, label: selectorLabel, ...props }) => (
    <Option value={value} label={selectorLabel} {...props}>
      <S.Selector>
        {getIcon(`status-${value}`)}
        {selectorLabel}
      </S.Selector>
    </Option>
  );

  // options: value, label
  return (
    <S.Wrapper
      large={large}
      small={small}
      alertType={alertType}
      gridarea={gridarea}
    >
      {label && <label htmlFor={fieldName}>{label}</label>}
      <InputSelect
        id={fieldName}
        ref={selectRef}
        cacheOptions
        defaultValue={defaultValue}
        styles={customStyles}
        classNamePrefix="custom-select"
        className="Select"
        maxMenuHeight="100px"
        placeholder={placeholder}
        loadOptions={loadOptions}
        {...rest}
        components={{
          NoOptionsMessage,
          Option: IconSelectOption,
          // SingleValue: IconSelectValue,
        }}
      />
      {alertMessage && (
        <S.Message alertType={alertType}>{alertMessage}</S.Message>
      )}
    </S.Wrapper>
  );
};

export default Select;
