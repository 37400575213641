import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(133, 133, 133, 0.3);
`;

export const Title = styled.h2`
  color: #c50101;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 700;
`;

export const Carousel = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const CarouselTitle = styled.h3`
  color: #c50101;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 700;
`;
