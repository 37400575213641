import React, { useState, useEffect } from 'react';
import Loading from '~/components/Loading';
import { goTopPage } from '~/utils';
import * as S from './styles';
import useLink from '../../utils/useLink';
import tableLinks from './tableLinks';

const Points = () => {
  const [loading, setLoading] = useState(true);
  const tableLink = useLink(tableLinks);

  useEffect(() => {
    let isMounted = true;

    goTopPage();
    if (isMounted) {
      setLoading(!tableLink);
    }

    return () => {
      isMounted = false;
    };
  }, [tableLink]);

  return (
    <>
      {loading && <Loading />}
      <S.Wrapper>
        <S.Container>
          <S.PageTitle>
            <img src="/images/regulation-icon.png" alt="" />
            Tabela de Pontos:
          </S.PageTitle>
          {tableLink && (
            <iframe
              width="100%"
              height="800px"
              src={`${tableLink}#toolbar=0&navpanes=0&scrollbar=0`}
              frameBorder="0"
              title="pdf viewer"
            />
          )}
        </S.Container>
      </S.Wrapper>
    </>
  );
};

export default Points;
