import React from 'react';

const NewGuest = ({ color }) => {
  return (
    <svg
      width="47"
      viewBox="0 0 47 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.7497 21.1663C24.1872 21.1663 13.083 23.9372 13.083 29.4997V33.6663H46.4163V29.4997C46.4163 23.9372 35.3122 21.1663 29.7497 21.1663ZM10.9997 12.833V6.58301H6.83301V12.833H0.583008V16.9997H6.83301V23.2497H10.9997V16.9997H17.2497V12.833H10.9997ZM29.7497 16.9997C31.9598 16.9997 34.0794 16.1217 35.6422 14.5589C37.205 12.9961 38.083 10.8765 38.083 8.66634C38.083 6.4562 37.205 4.33659 35.6422 2.77378C34.0794 1.21098 31.9598 0.333008 29.7497 0.333008C27.5395 0.333008 25.4199 1.21098 23.8571 2.77378C22.2943 4.33659 21.4163 6.4562 21.4163 8.66634C21.4163 10.8765 22.2943 12.9961 23.8571 14.5589C25.4199 16.1217 27.5395 16.9997 29.7497 16.9997Z"
        fill={color}
      />
    </svg>
  );
};
export default NewGuest;
