const columns = [
  {
    name: 'Data',
    selector: 'created_at',
  },
  {
    name: 'Nome',
    selector: 'user_name',
  },
  {
    name: 'CPF',
    selector: 'cpf',
  },
  {
    name: 'Regional',
    selector: 'regional',
  },
  {
    name: 'PDV',
    selector: 'pdv',
  },
  {
    name: 'PDV',
    selector: 'pdv',
  },
  {
    name: 'Status',
    selector: 'status',
  },
  {
    name: 'Descrição do desafio',
    selector: 'description',
  },
  {
    name: 'Período',
    selector: 'period',
  },
  {
    name: 'Pontos',
    selector: 'points',
  },
  {
    name: 'Motivo recusa',
    selector: 'refusal',
  },
];

export default columns;
