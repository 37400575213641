import * as S from '../../styles';
import { useEffect, useState } from 'react';
import { Input } from '~/components/NexAdmin/Inputs';
import { toDateTime } from '~/Helpers/Date';
import getIcon from '~/Helpers/getIcon';
import { Form } from '@unform/web';
import { Button } from '~/components/NexAdmin/Buttons';
import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { showNotification } from '~/utils/notificationBoxes';

const ThreadInfo = ({ thread, setLoading, refresh }) => {
  const [selected, setSelected] = useState(false);
  const [message, setMessage] = useState(thread.message);
  useEffect(() => {
    setMessage(thread.message);
  }, [thread]);

  const update = (data) => {
    setLoading(true);
    if (!data.message)
      return showNotification('warning', 'Adicione uma mensagem válida!');
    api
      .put(`/tickets/thread/update/${thread.id}`, data)
      .then(() => {
        refresh();
        setSelected(false);
        setLoading(false);
        showNotification('success', 'Thread atualizada com sucesso!');
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  const deleteThread = () => {
    setLoading(true);
    api
      .delete(`/delete-threads/${thread.id}`)
      .then((response) => {
        refresh();
        setSelected(false);
        setLoading(false);
        showNotification('success', 'Sucesso!', `${response.data.message}`);
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  return (
    <S.Thread>
      <S.Header>
        <S.Title>{thread.usu_id ? 'Usuário' : 'Admin'}</S.Title>
        <S.Title>{toDateTime(thread.created_at)}</S.Title>
        {!thread.usu_id && (
          <button onClick={() => setSelected(!selected)}>
            {getIcon('statusSketch')}
          </button>
        )}
        <button onClick={() => deleteThread()}>{getIcon('close')}</button>
      </S.Header>
      <Form onSubmit={update}>
        <S.InfoData>
          {selected ? (
            <>
              <Input name="message" defaultValue={message} />
              <S.ButtonWrapperUpdate>
                <Button>Atualizar</Button>
              </S.ButtonWrapperUpdate>
            </>
          ) : (
            <p>{message}</p>
          )}
        </S.InfoData>
      </Form>
    </S.Thread>
  );
};

export default ThreadInfo;
