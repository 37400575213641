import styled from 'styled-components';
import media from 'styled-media-query';

export const Data = styled.div`
  margin-top: 20px;
  ${media.greaterThan('medium')`
.search {
  display: flex;
  align-items: center;

  span {
      white-space: nowrap;
      margin-right: 10px;
      font-weight: 700;
      font-size: 1.6rem;
      color: ${({ theme }) => theme.colors.grayDarker};
  }
}
`};
`;

export const DataHeader = styled.div`
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-grow: 3; */
  margin-top: 20px;
  flex-direction: column-reverse;
  ${media.greaterThan('medium')`

  flex-direction: row;
  `};
  form {
    display: flex;
    align-items: center;
    // z-index: 100;
    width: 340px;

    svg {
      transform: rotate(-90deg);
      fill: ${({ theme }) => theme.colors.grayDarker};
    }

    span {
      margin-right: 10px;
      font-weight: 700;
      font-size: 1.6rem;
      color: ${({ theme }) => theme.colors.grayDarker};
    }
  }
  p {
    font-size: 1.4rem;
  }
`;
