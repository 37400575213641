import SearchBar from '../SearchBar';
import { PriceGrid } from '~/styles/Layouts/NexAdmin/grids';
import Download from '../Download';
import getIcon from '~/Helpers/getIcon';
import Button from '~/components/NexAdmin/Buttons/Button';
import CustomHeader from '../CustomHeader';

const Header = ({
  mb,
  rawData,
  searchedData,
  setSearchedData,
  columns,
  downloadColumns,
  fileName,
  customHeader,
  dropdownItems,
  hasDownload = true,
  refresh = () => {},
}) => {
  return (
    <>
      {customHeader && <CustomHeader>{customHeader}</CustomHeader>}
      <PriceGrid columns="300px 1fr 50px" mb={mb}>
        <SearchBar
          rawData={rawData}
          setSearchResults={setSearchedData}
          columns={columns}
        />
        {hasDownload && (
          <>
            <Download
              rawData={rawData}
              searchedData={searchedData}
              columns={downloadColumns ? downloadColumns : columns}
              fileName={fileName}
              dropdownItems={dropdownItems}
            />
            <Button
              model="secondary"
              small
              width="50px"
              style={{ placeSelf: 'end' }}
              onClick={() => refresh()}
            >
              {getIcon('refresh')}
            </Button>
          </>
        )}
      </PriceGrid>
    </>
  );
};

export default Header;
