/* eslint-disable no-console */
import { AiOutlineHeart, AiOutlineStar } from 'react-icons/ai';
import { CgSmileMouthOpen } from 'react-icons/cg';
import { Link, useRouteMatch } from 'react-router-dom';

import { getCompleteDate } from '~/utils';

import * as S from './styles';

const PreviewPostItem = ({ post }) => {
  const { url } = useRouteMatch();

  const switchPostType = () => {
    if (post.foto_tipo.includes('.mp4') || post.foto_tipo.includes('.avi')) {
      return (
        <S.Banner>
          <S.BannerWrapper>
            <video controls>
              <source src={post.foto} type="video/mp4" />
              <source src={post.foto} type="video/ogg" />
              <track default kind="captions" />
              Seu browser não suporta video!
            </video>
          </S.BannerWrapper>
        </S.Banner>
      );
    }

    if (post.foto_tipo.includes('.mp3')) {
      return (
        <S.Banner>
          <audio controls>
            <track default kind="captions" />
            <source src={post.foto} type="audio/ogg" />
            <source src={post.foto} type="audio/mpeg" />
            Seu browser não suporta Audio!
          </audio>
        </S.Banner>
      );
    }

    if (
      !post.foto_tipo.includes('.mp3') &&
      !post.foto_tipo.includes('.mp4') &&
      !post.foto_tipo.includes('.avi')
    ) {
      return (
        <S.Banner>
          <S.BannerWrapper>
            <img src={post.foto} alt="Banner" />
          </S.BannerWrapper>
        </S.Banner>
      );
    }

    return <></>;
  };

  return (
    <S.Wrapper>
      <h3>Preview: </h3>
      <S.Post>
        <header>
          <figure>
            <img src="/images/avatar-profile-new.jpg" alt="Avatar" />
          </figure>
          <div>
            <h1>Gigiga</h1>
            <p>Postado em {getCompleteDate(post.data_publicacao)}</p>
          </div>
        </header>
        <S.Commentary
          className="comentary"
          dangerouslySetInnerHTML={{ __html: post.comentario }}
        />
        {post.foto ? switchPostType() : <></>}
        <S.FormWrapper>
          <S.FormReActions>
            <button type="button">
              <li>
                <AiOutlineHeart size={25} />0
              </li>
            </button>
            <button type="button">
              <li>
                <CgSmileMouthOpen size={25} />0
              </li>
            </button>
            <button type="button">
              <li>
                <AiOutlineStar size={25} />0
              </li>
            </button>
          </S.FormReActions>
          {/* {post.comentarios && <Comments post={post} comentaries={post.comentarios} />} */}
        </S.FormWrapper>
      </S.Post>
    </S.Wrapper>
  );
};

export default PreviewPostItem;
