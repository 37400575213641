import Countdown from './Countdown/index';
import { useEffect, useState, useRef, useCallback } from 'react';
import UseAuthentication from '~/hooks/NexAdmin/AuthenticationHook';
import Loading from '~/components/Loading';
import fixedImageSrc from './gigiga.png';
import {
  Container,
  TopSection,
  FirstDiv,
  SecondDiv,
  ThirdDiv,
  AbsoluteImage,
  SecondDivP,
  SecondDivHero,
  SecondDivSpan,
} from './styles'; // Importando os estilos

const CountdownPage = () => {
  const { api } = UseAuthentication();
  const [, setComponentToShow] = useState('countdown');
  const [loading, setLoading] = useState(true);
  const endInterval = useRef(null);

  const countdownInterval = useCallback(async () => {
    try {
      const response = await api.get('/channel/countdown');
      endInterval.current = response.data.end_date;
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch countdown data:', error);
      setLoading(false);
    }
  }, [api]);

  useEffect(() => {
    countdownInterval();
  }, [countdownInterval]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <TopSection>
            <FirstDiv>
              <AbsoluteImage src={fixedImageSrc} alt="Imagem Absoluta" />
            </FirstDiv>
            <SecondDiv>
              <SecondDivHero>PREPARE-SE,</SecondDivHero>
              <SecondDivP>UM NOVO CICLO VEM AI!</SecondDivP>
              <Countdown
                setComponentToShow={setComponentToShow}
                finishDate={endInterval.current}
              />
              <SecondDivSpan>
                Uma nova oportunidade de realizar seus sonhos com o Giga Clube.
              </SecondDivSpan>
            </SecondDiv>
          </TopSection>
          <ThirdDiv />
        </Container>
      )}
    </>
  );
};

export default CountdownPage;
