/* eslint-disable no-console */
import { useRef } from 'react';

import { Form } from '@unform/web';

import Checkbox from '~/components/Checkbox';

import * as S from './styles';

const Filter = ({
  checkboxOptions,
  handleSubmit,
  title,
  button,
  calculator,
  linkIsDownload,
}) => {
  const formRef = useRef(null);
  return (
    <S.Wrapper>
      <h1>{`${title}${title ? ':' : ''}`}</h1>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Checkbox
          calculator={checkboxOptions.length >= 5 ? calculator : false}
          name="checkbox"
          options={checkboxOptions}
          linkIsDownload={linkIsDownload}
        />
        <button type="submit">{button}</button>
      </Form>
    </S.Wrapper>
  );
};

export default Filter;
