import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #211915cc;
  z-index: 100;
  display: grid;
  place-items: center;
  padding: 20px;
`;
export const Inner = styled.div`
  background-color: #f4f4f4;
  color: #211915;
  box-shadow: 4px 4px 16px 0px #33333333;
  border-radius: 18px;
  padding: 32px;
  max-height: calc(100vh - 100px);
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 100px);
`;

export const Close = styled.div`
  width: 48px;
  height: 48px;
  border: 1.5px solid #211915;
  border-radius: 100%;
  display: grid;
  place-content: center;
  cursor: pointer;
`;

export const Top = styled.div`
  display: grid;
  grid-template-columns: auto 48px;
  grid-gap: 12px;
`;

export const HeaderTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;

  * {
    text-align: left;
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: #211915;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.05em;
    color: #f19321;
  }
`;

export const Bg = styled.div`
  position: fixed;
  pointer-events: all;
  height: 100vw;
  background-color: #00000090;
  transition: ease background-color 0.3s;
  width: 100vw;

  ${media.lessThan('medium')`
        height: 100%;
    `}
`;
