import getIcon from '~/Helpers/getIcon';
import * as S from './styles';

const Default = ({ title, description, close, content, hideModal }) => {
  return (
    <S.Wrapper>
      <S.Inner>
        <S.Top>
          <S.HeaderTitle>
            <h3>{title}</h3>
            <p>{description}</p>
          </S.HeaderTitle>
          {close && (
            <S.Close onClick={hideModal}>{getIcon('close', '#323133')}</S.Close>
          )}
        </S.Top>
        {content}
      </S.Inner>
    </S.Wrapper>
  );
};

export default Default;
