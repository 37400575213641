import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  width: 100%;
  gap: 20px;
  overflow: auto;

  table {
    display: table;
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.grayDarker};

    .order-td {
      display: flex;
      flex-direction: row;
      border-radius: 30px;

      &.proccessing {
        background: #f2f2f2;
      }
      &.error {
        background: #ffe8e8;
      }
      &.concluded {
        background: rgba(0, 193, 54, 0.1);
      }
    }
    .order-title {
      font-weight: 700;
    }
    td {
      display: table-cell;
      vertical-align: middle;
      margin: 10px 0px;
      padding: 10px 10px;
      max-width: 150px;
      font-size: 1.3rem;
    }

    thead {
      font-weight: 700;
      font-size: 1.6rem;
      border-radius: 30px;
      background-color: #f2f2f2;
      color: ${({ theme }) => theme.colors.red}!important;
      td {
        &:first-child {
          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;
        }
        &:last-child {
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
        }
        padding: 10px 20px;
      }
      button {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.red};
        font-weight: 700;
      }
      &.points-thead {
        border: none;
        background-color: #f2f2f2;
        color: ${({ theme }) => theme.colors.red};
      }
    }
    tbody {
    }
    svg {
      fill: ${({ theme }) => theme.colors.red};
    }
  }
`;

export const Results = styled.div`
  padding: 20px;
  display: flex;
  gap: 50px;
  margin-top: 20px;
  flex-direction: column;
  ${media.greaterThan('medium')`
    flex-direction: row;
  `}

  div {
    color: #484848;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;

    span {
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left;

      &.red {
        color: #c50101;
      }
    }
    .points {
      width: 200px;
      border: 1px solid #484848;
      border-radius: 100px;
      height: 50px;

      &.red {
        border: 1px solid #c50101;
        color: #c50101;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  button {
    width: 200px;
  }

  ${media.greaterThan('medium')`
    flex-direction: row;
  `}
`;

export const ArrowButton = styled.button`
  background-color: transparent !important;
`;

export const TableRow = styled.tr`
  cursor: pointer;

  td:first-child {
    border-left: 1px solid #f2f2f2;
    border-top-left-radius: 25px;
    border-bottom-left-radius: ${(props) => props.selected || 25}px;
  }

  td:last-child {
    border-right: 1px solid #f2f2f2;
    border-top-right-radius: 25px;
    border-bottom-right-radius: ${(props) => props.selected || 25}px;
  }

  td {
    border-top: 1px solid #f2f2f2;
    border-bottom: ${(props) => props.selected || 1}px solid #f2f2f2;
    margin-bottom: 5px;
  }

  .red {
    color: red;
  }

  .green {
    color: green;
  }

  .grey {
    color: grey;
  }

  .yellow {
    color: #eab000;
  }
`;
