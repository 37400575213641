import { useState, useEffect } from 'react';

import api from '~/services/api';
import { parseJwt } from '~/services/auth';

import Logo from '../Logo';
import * as S from './styles';

const SafariNotificationPopup = ({ oppened, setOppened }) => {
  const [reallyOppened, setReallyOppened] = useState(false);

  const pushId = 'web.com.gigaclube.gigaclube';

  const checkRemotePermission = (permissionData) => {
    if (permissionData.permission === 'default') {
      window.safari.pushNotification.requestPermission(
        'https://gigaclube.com/v1',
        pushId,
        {},
        checkRemotePermission,
      );
    } else if (permissionData.permission === 'granted') {
      api
        .post(`/register-push-token/${parseJwt().login}`, {
          subscription: permissionData.deviceToken,
          usu_device: 'Safari',
        })
        .then(() => {});
    }
  };

  const askPermission = () => {
    if ('safari' in window && 'pushNotification' in window.safari) {
      const permissionData = window.safari.pushNotification.permission(pushId);
      checkRemotePermission(permissionData);
    }
  };

  useEffect(() => {
    if ('safari' in window && 'pushNotification' in window.safari) {
      const newPermissionData =
        window.safari.pushNotification.permission(pushId);
      if (newPermissionData.permission === 'default') {
        setReallyOppened(true);
      } else {
        api
          .post(`/register-push-token/${parseJwt().login}`, {
            subscription: newPermissionData.deviceToken,
            usu_device: 'Safari',
          })
          .then(() => {});
      }
    }
  }, []);

  return reallyOppened && oppened ? (
    <S.SafariNotificationBackground>
      <div className="logo-message-wrapper">
        <Logo origin="signin" />
        <p>Aceita receber notificações do GigaClube?</p>
      </div>
      <div className="buttons-wrapper">
        <button
          type="button"
          onClick={() => {
            setOppened(false);
            askPermission();
          }}
        >
          Sim
        </button>
        <button
          type="button"
          onClick={() => {
            setOppened(false);
          }}
        >
          Não
        </button>
      </div>
    </S.SafariNotificationBackground>
  ) : (
    <></>
  );
};

export default SafariNotificationPopup;
