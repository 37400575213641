import * as S from '../styles';
import Carousel from 'react-elastic-carousel';
import Card from '../Card';
import moment from 'moment';

const CarouselSection = ({ campaigns, title }) => {
  return campaigns.length > 0 ? (
    <S.Carousel>
      <S.CarouselTitle>{title}</S.CarouselTitle>

      <Carousel
        itemPadding={[10, 20]}
        breakPoints={[
          { width: 1, itemsToShow: 1 },
          { width: 550, itemsToShow: 3 },
          { width: 1050, itemsToShow: 4 },
        ]}
      >
        {campaigns.map((item, index) => (
          <Card
            key={index}
            id={item.id}
            name={item.title}
            isAvailable={moment(item.end_date).diff(moment(), 'days') >= 0}
            description={item.description}
          ></Card>
        ))}
      </Carousel>
    </S.Carousel>
  ) : null;
};

export default CarouselSection;
