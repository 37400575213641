import { useState, useEffect } from 'react';
import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import api from '~/services/api';
import Loading from '~/components/Loading';
import Table from '~/components/NexAdmin/Table';
import { HTTPErrorCallback } from '~/Helpers/Error';
import column from './columns';
import { toDateBR } from '~/Helpers/Date';
import { Input, Select } from '~/components/NexAdmin/Inputs/index';
import { Button } from '~/components/NexAdmin/Buttons';
import { Form } from '@unform/web';
import * as S from './styles';
import Counter from '~/components/NexAdmin/Counter';
import Action from '../Component/Action';
import Media from './Media';

const Audit = () => {
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState([]);
  const [campaigns, setCampaigns] = useState(null);
  const [refusalReasons, setRefusalReasons] = useState(null);

  const [selectedMedia, setSelectedMedia] = useState(null);

  useEffect(() => {
    getMedia();
    getCampaigns();
  }, []);

  const getMedia = (data) => {
    setLoading(true);
    const query = new URLSearchParams(data);
    api
      .get(`/media-campaign/media/admin/?${query}`)
      .then((response) => {
        setMedia(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  const getCampaigns = () => {
    api
      .get(`/media-campaign/media/admin/get-data`)
      .then((response) => {
        setCampaigns(
          response.data.campaigns.map((e) => {
            return { label: e.title, value: e.id };
          }),
        );

        setRefusalReasons(
          response.data.refusal_reasons.map((e) => {
            return { label: e.text, value: e.id };
          }),
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="statusSketch"
          title={`Auditoria de Foto e Vídeo ${
            selectedMedia ? ' | Foto de ' + selectedMedia.usu_nome : ''
          }`}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            {selectedMedia ? (
              <Media
                media={selectedMedia}
                setSelectedMedia={setSelectedMedia}
                allMedia={media}
                reset={getMedia}
                refusalReasons={refusalReasons}
              />
            ) : (
              <>
                <Form onSubmit={getMedia}>
                  <S.FormWrapper>
                    <S.FormFilters>
                      <Input name="begin_date" label="De:" type="date" />
                      <Input name="end_date" label="Até:" type="date" />
                      <Input name="cpf" label="CPF:" type="text" />
                      {campaigns && (
                        <Select
                          name="campaign"
                          label="Campanha:"
                          options={campaigns}
                        />
                      )}
                    </S.FormFilters>
                    <Button type="submit">Pesquisar</Button>
                  </S.FormWrapper>
                </Form>
                {media && <Counter total={media.length} />}
                <Table
                  data={media}
                  columns={column}
                  downloadColumns={column}
                  dataTableProps={{
                    pagination: true,
                    onRowClicked: (e) => setSelectedMedia(e),
                  }}
                  header
                  dropdownItems={false}
                  hasDownload={true}
                  fileName={`Relatório de Campanhas de Foto e Vídeo - ${toDateBR(
                    new Date(),
                  )}`}
                  refresh={getMedia}
                />
              </>
            )}
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default Audit;
