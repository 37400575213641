import { useEffect, useState } from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';

import Routes from '~/routes';
import GlobalStyle from '~/styles/global.styles';
import AdminGlobalStyle from '~/styles/admin.global.styles';
import commonTheme from '~/styles/theme';
import { ModalProvider } from './contexts/modalContext';
import SafariNotificationPopup from './components/SafariNotificationPopup';
import AppProvider from './hooks';
import api from './services/api';
import { parseJwt } from './services/auth';
import { isSafari, urlBase64ToUint8Array } from './utils';
import GlobalModalStructureContext from './contexts/GlobalModalStructureContext';
import GlobalModalStructure from './components/ModalStructure';
import VersionContext from './contexts/versionContext';
import NotificationProvider from './contexts/NotificationContext';
import AuthenticationProvider from './contexts/AutenticationContext';
import TwoFactorProvider from './contexts/TwoFactorContext';
import PasswordChangeProvider from './contexts/PasswordChangeContext';

const publicVapidKey =
  'BKd-P86mFM4sKTvue0YmHodp3j0a8vhiHeJrRQn_sUBErcEB0PP8wuEmZoVWg0Ly8qwkeTzJkwDppRUnb7LKV5s';

const App = () => {
  const [theme, setTheme] = useState(commonTheme);
  const [globalStyle, setGlobalStyle] = useState('common');
  const [safariPopupOppened, setSafariPopupOppened] = useState(false);

  const startWorker = async () => {
    if (
      !localStorage.getItem('userToken') ||
      localStorage.getItem('adminUserToken')
    )
      return;
    const registration = await navigator.serviceWorker.register('/worker.js', {
      scope: '/',
    });

    let subscription;
    let device;

    if (
      (navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i)) &&
      !navigator.userAgent.match(/iPhone/i)
    ) {
      const jsonCookies = {};
      document.cookie.split(/\s*;\s*/).forEach((pair) => {
        pair = pair.split(/\s*=\s*/);
        jsonCookies[pair[0]] = pair.splice(1).join('=');
      });
      if (jsonCookies)
        if (jsonCookies.pushNotificationToken) {
          subscription = jsonCookies.pushNotificationToken;
          device = 'Android';
        }
    } else if (isSafari()) {
      setSafariPopupOppened(true);
    } else {
      await navigator.serviceWorker.ready;

      const result = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
      });

      subscription = JSON.stringify(result);
      device = 'Chrome';
    }

    await api.post(`/register-push-token/${parseJwt().login}`, {
      subscription,
      usu_device: device,
    });
  };

  useEffect(() => {
    setTheme(commonTheme);
    if ('serviceWorker' in navigator) startWorker();
  }, []);

  useEffect(() => {
    setGlobalStyle(
      window.location.pathname.includes('/admin') ? 'admin' : 'common',
    );
  });
  return (
    <ThemeProvider theme={theme}>
      {globalStyle === 'admin' ? <AdminGlobalStyle /> : <GlobalStyle />}
      <SafariNotificationPopup
        oppened={safariPopupOppened}
        setOppened={setSafariPopupOppened}
      />
      <AuthenticationProvider>
        <Router>
          <AppProvider>
            <ModalProvider>
              <GlobalModalStructureContext>
                <GlobalModalStructure />
                <ReactNotification />
                <VersionContext>
                  <NotificationProvider>
                    <TwoFactorProvider>
                      <PasswordChangeProvider>
                        <Routes />
                      </PasswordChangeProvider>
                    </TwoFactorProvider>
                  </NotificationProvider>
                </VersionContext>
              </GlobalModalStructureContext>
            </ModalProvider>
          </AppProvider>
        </Router>
      </AuthenticationProvider>
    </ThemeProvider>
  );
};

export default App;
