import { useEffect, useRef, useState } from 'react';
import { Button } from '~/components/NexAdmin/Buttons/index';
import Counter from '~/components/NexAdmin/Counter';
import { Select, Input } from '~/components/NexAdmin/Inputs/index';
import Table from '~/components/NexAdmin/Table';
import { Form } from '@unform/web';
import * as S from './styles';
import moment from 'moment/moment';
import api from '~/services/api';
import { appendObjectInFormData } from '~/utils';
import { parseJwt } from '~/services/auth';
import { HTTPErrorCallback } from '~/Helpers/Error';
import Download from '~/screens/NexAdmin/Sales/Components/Download';
import DownloadHook from '~/hooks/NexAdmin/DownloadHook';
import { buildReportName } from '~/utils/NexAdmin/utils';
import { columns_users_varejo, columns_users_recarga } from '../../columns';
import Cards from '~/components/NexAdmin/Cards';
import getIcon from '~/Helpers/getIcon';
import Actions from '../Actions';

const BaseTableGNUser = ({
  setLoading,
  row,
  setStep,
  dinamycState,
  setCurrentIndex,
  currentIndex,
  current_user,
}) => {
  const [count, setCount] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const formRef = useRef();
  useEffect(() => {
    handleSubmit();
  }, []);
  const handleSubmit = (data) => {
    setLoading(true);
    const formData = new FormData();
    appendObjectInFormData(formData, {
      channel_id: current_user.canal_id,
      status: dinamycState,
      gn_cpf: row.cpf,
      ...data,
    });
    const params = new URLSearchParams(formData);
    api
      .get(`/gn/default/admin/transactions?${params}`)
      .then((response) => {
        setCount(response.data.count);
        setTransactions(response.data.transactions);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  const handleDownload = () => {
    const data = formRef.current.getData();
    const form = new FormData();
    appendObjectInFormData(form, {
      channel_id: current_user.canal_id,
      status: dinamycState,
      gn_cpf: row.cpf,
      download: true,
      ...data,
    });
    const params = new URLSearchParams(form);
    return api.get(`/gn/default/admin/transactions?${params}`, {
      responseType: 'blob',
    });
  };

  const beforeLoading = () => setLoading(true);
  const afterLoading = () => setLoading(false);
  const { execute, ref, urlFile, nameFile } = DownloadHook({
    externalCall: handleDownload,
    onError: HTTPErrorCallback,
    getFileName: buildReportName('Campanhas por GN'),
    beforeLoading,
    afterLoading,
  });

  return (
    <>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <S.FormWrapper>
          <Button
            onClick={() => {
              setStep('gn');
              setCurrentIndex(currentIndex);
            }}
          >
            {getIcon('back')}
          </Button>
          <S.FormFilters>
            <Input name="initial_date" label="De:" type="date" />
            <Input name="final_date" label="Até:" type="date" />
            <Input name="cpf" label="CPF:" type="text" />
            <Input name="regional" label="Regional:" type="text" />
          </S.FormFilters>
          <Button type="submit">Consultar</Button>
        </S.FormWrapper>
        <S.DownloadWrapper>
          <S.UserWrapper>
            <Cards
              title="GN"
              dataList={[
                ['Nome', row.name],
                ['CPF', row.cpf],
                ['Regional', row.regional],
                ['PDV', row.pdv],
                ['Pontos', row.points.toString()],
              ]}
            />
          </S.UserWrapper>
          <a href={urlFile} download={nameFile} ref={ref} />
          <Download buttonName="Download" external={execute} />
        </S.DownloadWrapper>
      </Form>
      <Counter total={count} />
      <Table
        data={transactions}
        columns={
          dinamycState == 1 || dinamycState == 2
            ? [
                {
                  name: 'Ações',
                  cell: (row) => (
                    <Actions
                      row={row}
                      setLoading={setLoading}
                      dinamycState={dinamycState}
                      refresh={handleSubmit}
                      current_user={current_user}
                    />
                  ),
                },
                ...(current_user.canal_id === 2
                  ? columns_users_varejo
                  : columns_users_recarga),
              ]
            : current_user.canal_id === 2
            ? columns_users_varejo
            : columns_users_recarga
        }
        pagination={true}
        header
        dropdownItems={false}
        hasDownload={false}
      />
    </>
  );
};

export default BaseTableGNUser;
