import { Form } from '@unform/web';
import styled from 'styled-components';

export const FormWrapper = styled(Form)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 90px;
  grid-gap: 20px;
  max-width: 80%;

  button {
    margin-top: auto;
  }
`;

export const DownloadWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding-top: 20px;
`;
