import { useEffect, useRef, useState } from 'react';
import Loading from '~/components/Loading';
import api from '~/services/api';
import { showNotification } from '~/utils/notificationBoxes';

import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import { Button } from '~/components/NexAdmin/Buttons';
import CreateForm from '../components/CreateForm';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import CreateChallengeDescription from '~/components/NexAdmin/Modal/Descriptions/Challenges/createChallengeDescription';

const CreateChallenges = () => {
  const fileRef = useRef(null);
  const { openModal } = useModal();
  const [state, setState] = useState();
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [canais, setCanais] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getChannels();
  }, []);

  const getChannels = () => {
    setLoading(true);

    api.get(`/filtros-desafios`).then((response) => {
      setLoading(false);
      setCanais(response.data.canais);
    });
  };

  const createChallenge = (data) => {
    if (!selectedChannels || selectedChannels.length === 0) {
      return showNotification(
        'warning',
        'Atenção',
        'Preencha todos os campos! (selecione pelo menos um canal)',
      );
    }

    if (data.dest_dt_fim < data.dest_dt_ini) {
      return showNotification(
        'warning',
        'Atenção',
        'A data final não pode ser menor do que a data inicial',
      );
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('digital_canal_id', selectedChannels);
    formData.append('desafio', state);
    formData.append('produtos_participantes', data.produtos_participantes);
    formData.append('digital_dt_ini', data.digital_dt_ini);
    formData.append('digital_dt_fim', data.digital_dt_fim);
    formData.append('titulo', data.titulo);

    if (fileRef.current.files[0]) {
      formData.append(
        'extension',
        fileRef.current.files[0].name.split('.').pop(),
      );
      formData.append('file', fileRef.current.files[0]);
    }

    api.post('/criar-desafio', formData).then(() => {
      setLoading(false);
      showNotification('success', 'Sucesso!', 'Desafio criado com sucesso!');
      window.location.reload();
    });
    return true;
  };

  const openThermsModal = () => {
    openModal({
      title: 'Criar Desafios',
      close: true,
      content: <CreateChallengeDescription />,
    });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="actions"
          title="Desafios"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <Button type="button" onClick={() => setCurrentView('')}>
              Voltar
            </Button>

            <CreateForm
              canais={canais}
              selectedCanais={selectedChannels}
              setSelectedCanais={setSelectedChannels}
              createBanner={createChallenge}
              fileRef={fileRef}
              setState={setState}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default CreateChallenges;
