const SIVColumnsDesc = ({ channel_id }) => {
  return (
    <>
      <br />
      <strong>1. Código Venda:</strong> Código ID da venda. Valor único em toda
      a tabela.
      <br />
      <strong>2. CPF Promotor:</strong> CPF do vendedor.
      <br />
      <strong>3. Data da Venda:</strong> Data que a venda foi efetuada.
      <br />
      <strong>4. Nome:</strong> Nome do usuário.
      <br />
      <strong>5. Cargo:</strong> Cargo do usuário.
      <br />
      <strong>6. Rede:</strong> Rede do usuário.
      <br />
      <strong>7. Regional:</strong> Regional do usuário.
      <br />
      <strong>8. PDV:</strong> Ponto de Venda do usuário.
      <br />
      <strong>9. Plano:</strong> Produto da venda.
      <br />
      <strong>10. Plano Tipo:</strong> Tipo do produto da venda.
      <br />
      <strong>
        11. {channel_id === 2 ? 'Fidelidade' : 'Fatura Tipo'}
      </strong>{' '}
      Fidelidade ou tipo da fatura.
      <br />
      <strong>12. Pontos Vendas:</strong> Pontos gerado pela venda.
      <br />
      <strong>13. Pontos Bônus:</strong> Pontos bônus gerado pela venda, se
      aplicável.
      <br />
      <strong>14. Venda Status:</strong> Status da venda.
      <br />
      <strong>15. Transação Status:</strong> Status da transação.
      <br />
      <strong>16. Ponto Status:</strong> Status da pontuação.
      <br />
      <strong>17. Data Venda:</strong> Data da venda.
      <br />
      <strong>18. Portabilidade:</strong> Se existe portabilidade ou não.
      <br />
      <strong>19. MSIDN:</strong> Código interno fornecido pelo SIV.
    </>
  );
};

export default SIVColumnsDesc;
