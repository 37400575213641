import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: calc(100%);
  max-width: 210px;

  position: relative;
`;
export const Inner = styled.button`
  /* ESTILO GLOBAL */
  position: relative;

  min-height: 40px;
  height: fit-content;
  width: ${(props) => (props.opened || props.hoverOpened ? '100%' : '40px')};

  font-size: ${({ theme }) => theme.adminfontsize.p};
  font-weight: bold;

  display: grid;
  grid-gap: 6px;
  grid-template-rows: 1fr;
  grid-template-columns: ${(props) =>
    props.opened || props.hoverOpened ? '24px 1fr 24px' : '1fr'};

  place-items: center;

  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); */
  color: ${(props) =>
    props.active
      ? props.theme.admincolors.contrast.black
      : props.theme.oldColors.primary[50]};

  transition: ${({ theme }) => theme.transition};
  cursor: pointer;

  border: none;
  padding: 8px 10px;
  border-radius: 8px;

  /* MODELOS - default, secondary outline, other  */
  &:focus {
    box-shadow: 0px 0px 0 1px ${(props) => props.theme.oldColors.primary[50]},
      0px 0px 0 3px ${(props) => props.theme.admincolors.contrast.black};
    outline: none;
  }
  background-color: ${(props) =>
    props.active ? props.theme.oldColors.primary[50] : '#00000000'};
  border-color: ${(props) => props.theme.oldColors.primary[50]};

  /* ESTILOS EM HOVER */
`;

export const Title = styled.div`
  place-self: center start;
  animation: ease 0.4s fadeInRight;
  will-change: auto;
  //styleName: paragraph-desktop;

  font-size: ${({ theme }) => theme.adminfontsize.sidebartitle};
  font-style: normal;
  font-weight: 700;
  line-height: ${({ theme }) => theme.lineheight.sidebartitle};
  letter-spacing: 0em;
  text-align: left;
`;
export const Arrow = styled.button`
  display: grid;
  place-items: center;
  height: 100%;
  width: 40px;
  position: absolute;
  background-color: transparent;
  border: none;
  cursor: pointer;
  top: 0;
  right: 0;
  z-index: 10;
  transform: ${(props) => (props.expanded ? 'rotate(0deg)' : 'rotate(-90deg)')};
  transition: ${({ theme }) => theme.transition};
`;
export const Icon = styled.div`
  transition: ${({ theme }) => theme.transition};
  will-change: auto;
  ${(props) => (props.opened || props.hoverOpened ? '100%' : '40px')};
  display: grid;
  place-items: center start;
  svg {
    width: 16px;
  }
`;
export const ButtonItems = styled.div`
  will-change: auto;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center start;
`;
export const ItemArrow = styled.div`
  transform: rotate(-90deg);
  position: absolute;
  left: 0;
  padding: 10px;

  transition: ${({ theme }) => theme.transition};
`;
export const ButtonItemWrapper = styled.button`
  animation: ease 0.4s fadeInRight;

  white-space: nowrap;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.oldColors.primary[40]};
  border-bottom: 1px solid ${({ theme }) => theme.oldColors.primary[40]};

  &:last-child {
    border: none;
  }
  .itemArrow {
    margin-left: ${(props) => props.active && '6px'};
  }
  &:hover {
    .itemArrow {
      margin-left: 6px;
    }
  }

  position: relative;
  display: flex;
  align-items: center;

  cursor: pointer;
  padding-left: 40px;

  width: 172px;
  height: 46px;

  //styleName: paragraph-desktop;

  font-size: ${({ theme }) => theme.adminfontsize.sidebarbutton};

  font-weight: ${(props) => props.active && '700'};
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;
