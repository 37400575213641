import styled from 'styled-components';
import media from 'styled-media-query';

export const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  gap: 45px;
  form {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const FormWrapperUpdate = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 45px;
  form {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 45px;
  /*   
  form {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  } */
`;

export const FilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;
export const File = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  padding: 20px 50px;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  a {
    place-self: center start;
    font-size: ${({ theme }) => theme.adminfontsize.p};
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin: 10px;
  justify-content: flex-end;
`;

export const ButtonWrapperUpdate = styled.div`
  display: flex;
  margin: 10px;
  width: 30%;
  align-self: center;
`;

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  label {
    font-size: 1.4rem;
  }
  input {
    width: 45px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  button svg {
    width: 14px;
  }
`;

export const Threads = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  form {
    width: 100%;
  }
  button {
    width: 100%;
  }
`;

export const Title = styled.div`
  place-self: center start;
  font-size: ${({ theme }) => theme.adminfontsize.containertitle};
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.admincolors.contrast.black};
`;

export const Header = styled.div`
  padding: 10px 22px;
  display: grid;
  width: 100%;
  grid-gap: 10px;
  grid-template-columns: auto 1fr auto auto;
  place-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.oldColors.gray[50]};
  margin-bottom: 30px;
`;

export const Thread = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  padding: 20px 50px;
  width: 90%;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  justify-content: center;
`;

export const InfoData = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-self: center;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  font-weight: 500;
  padding: 10px;
  ${media.greaterThan('1440x')`
    max-width: 1200px;
  `}
  overflow-wrap: break-word;
  height: fit-content;
  max-height: 500px;
  overflow-y: auto;
`;
