import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: grid;
  margin: 0 auto;
  place-items: center;
  grid-gap: 20px;

  h2 {
    font-size: ${({ theme }) => theme.adminfontsize.containertitle};
    color: ${({ theme }) => theme.admincolors.contrast.black};
    text-align: center;

    &:last-child {
      margin-top: 20px;
    }
  }
`;

export const InputWrapper = styled.div`
  display: grid;
  width: 500px;
  grid-gap: 20px;

  ${media.lessThan('1000px')`
  width: 100%;
  `}
`;
