import React from 'react';

const Note = ({ color }) => {
  return (
    <svg
      width="40"
      height="50"
      viewBox="0 0 40 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 0C3.67392 0 2.40215 0.526784 1.46447 1.46447C0.526784 2.40215 0 3.67392 0 5V45C0 46.3261 0.526784 47.5979 1.46447 48.5355C2.40215 49.4732 3.67392 50 5 50H35C36.3261 50 37.5978 49.4732 38.5355 48.5355C39.4732 47.5979 40 46.3261 40 45V15L25 0H5ZM5 5H22.5V17.5H35V45H5V5ZM10 25V30H30V25H10ZM10 35V40H22.5V35H10Z"
        fill={color}
      />
    </svg>
  );
};
export default Note;
