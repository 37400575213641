import getIcon from '~/Helpers/getIcon';
import * as S from './styles';
import Button from '~/components/Button';
import moment from 'moment';

const ChampionModal = ({ campaignType, setOpenModal, userMedia }) => {
  return (
    <S.Background>
      <S.Modal>
        <S.Header>
          <S.HeaderTitle>
            {getIcon('media', '#C50101')}
            <span>Detalhes da Foto</span>
          </S.HeaderTitle>

          <div onClick={() => setOpenModal(null)}>{getIcon('close')}</div>
        </S.Header>

        <S.Main>
          <S.PhotoUpload>
            <S.Info>
              <span>Participante</span>
              <span>{userMedia.usu_nome}</span>
            </S.Info>

            <S.Row>
              <S.Info>
                <span>Data Publicação</span>
                <span>
                  {moment(userMedia.created_at).format('DD/MM/YYYY - HH:mm')}
                </span>
              </S.Info>
            </S.Row>
          </S.PhotoUpload>

          <S.Preview>
            <h3>Preview</h3>

            <S.PreviewBlock>
              <S.PreviewPhoto>
                {campaignType === 'image' ? (
                  <img src={userMedia.media_src} alt="Preview" />
                ) : (
                  <video src={userMedia.media_src} controls />
                )}
              </S.PreviewPhoto>
            </S.PreviewBlock>
          </S.Preview>
        </S.Main>

        <S.Buttons>
          <Button
            to="#"
            onClick={() => {
              setOpenModal(null);
            }}
          >
            Voltar
          </Button>
        </S.Buttons>
      </S.Modal>
    </S.Background>
  );
};

export default ChampionModal;
