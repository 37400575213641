import * as S from './styles';

const BonusPointReportDescription = () => {
  return (
    <S.ThermWrapper>
      <br />
      O Relatório de Pontos Bônus permite a consulta de todas as transações que
      contém pontos bônus.
      <br />
      <br />
      Essas transações podem ter duas origens, sendo <strong>Admin</strong> para
      pontos que foram disponibilizados aos usuários manualmente por meio do
      sistema administrativo, e <strong>Mecânica Extra</strong> para pontos que
      foram disponibilizados por meio de vendas de mecânicas extras automáticas.
      <br />
      <br />
      Para as vendas de origem <strong>Admin</strong>, a nomenclatura é definida
      pelo usuário que realiza o input. Já para as vendas de origem de{' '}
      <strong>Mecânica Extra</strong> são definidas automaticamente pelo
      sistema, contendo o nome do produto vendido.
      <br />
      <br />
      Após preencher os campos e pesquisar, você pode fazer uma filtragem dos
      dados por meio do campo <strong>Pesquisar</strong>, presente abaixo dos
      campos da pesquisa. Nesse campo você pode pesquisar pelo CPF, Nome,
      Descrição, Pontos e Origem.
      <br />
    </S.ThermWrapper>
  );
};

export default BonusPointReportDescription;
