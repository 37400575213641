import React from 'react';
import { useHistory } from 'react-router-dom';
import { parseJwt } from '~/services/auth';
import Rows from '~/screens/TablePoints/components/Rows';

import * as S from './styles';

const TablePoints = () => {
  const history = useHistory();
  return (
    <S.Container>
      <S.Header>
        <S.Title>Tabela de Pontos</S.Title>
      </S.Header>
      <S.Paragraph>
        Clique no botão abaixo para consultar a Tabela de Pontos.
      </S.Paragraph>
      <S.MoreButtonWrapper>
        <S.MoreButton onClick={() => history.push('/tabela-de-pontos')}>
          Ver mais
        </S.MoreButton>
      </S.MoreButtonWrapper>
    </S.Container>
  );
};

export default TablePoints;
