import background from '~assets/Nex/background.jpg';
import tokensCommon from './digestedTokens.json';
// =============== LIGHT THEME ===============
const adminTheme = {
  ...tokensCommon,

  // =============== FONT FAMILY ===============

  adminfontfamily: "'Montserrat', sans-serif",

  // =============== FONT SIZES ===============
  adminglobalBackground: background,
  adminfontsize: {
    global: '62.5%',

    h1: '4.2rem',
    h2: '3.6rem',
    h3: '3.2rem',
    h4: '2.8rem',
    h5: '2.4rem',
    h6: '2rem',
    // h6: "1.8rem",
    p: '1.6rem',

    desktop: {
      h1: '40px',
      h2: '34px',
      h3: '30px',
      h4: '26px',
      h5: '20px',
      h6: '18px',
      p: '14px',
    },

    mobile: {
      h1: '34px',
      h2: '28px',
      h3: '24px',
      h4: '20px',
      h5: '18px',
      h6: '16px',
      p: '12px',
    },

    button: '1.4rem',
    confirmbutton: '1.2rem',
    buttondropdown: '1.6rem',

    sidebartitle: '1.4rem',
    sidebarbutton: '1.4rem',
    sidebarcredentials: {
      name: '1.6rem',
      email: '1.2rem',
    },

    containertab: '1.4rem',
    containertitle: '1.6rem',

    contentheaderdetails: {
      left: '1.4rem',
      right: '1.4rem',
    },
    contentheadermap: {
      name: '1.8rem',
      map: '1.2rem',
    },

    filterselect: '1.4rem',

    contentfooter: '1.6rem',

    text: '1.4rem',
    label: '1.3rem',
    input: {
      default: '1.4rem',
      large: '1.8rem',
      small: '1.4rem',
      message: '1.2rem',
      charlimit: '1.2rem',
    },
    support: '1.2rem',
    togglevalue: '2rem',

    projectcard: {
      title: '1.2rem',
      description: '1.2rem',
      detailtitle: '1.2rem',
      detailcontent: '1.2rem',
    },

    searchbar: {
      input: '1.4rem',
    },

    pricetag: {
      totaltitle: '1.4rem',
      totalvaluespan: '1.6rem',
      totalvalue: '3.6rem',
      creationinfo: '1.4rem',
    },

    sitemap: {
      name: '1.8rem',
      map: '1.2rem',
    },
    statustag: {
      creationinfo: '1.4rem',
      title: '1.6rem',
    },

    modals: {
      modalstructure: {
        title: '1.6rem',
      },
      confirm: {
        button: '1.6rem',
        text: '1.4rem',
        title: '2.8rem',
      },
    },

    table: {
      tablecol: '1.2rem',
      tablecell: '1.2rem',
    },
    totalvalue: {
      label: '1.4rem',
      value: '2rem',
    },

    connectionfinished: '2.8rem',

    login: '2rem',
  },

  // =============== BORDER RADIUS ===============

  borderradius: '8px',

  // =============== LINE HEIGHTS ===============

  lineheight: {
    button: '2.1rem',
    buttondropdown: '2rem',
    confirmbutton: '1.5rem',

    sidebartitle: '2rem',

    containertab: '1.7rem',

    label: '2rem',
    table: '2.4rem',
  },

  // =============== SPACING ===============

  spacing: {
    contentH: '2.4rem',
  },

  // =============== TRANSITIONS ===============

  transition: 'ease 0.3s all',

  // =============== COLORS ===============
  containment: {
    bg: 'red',
  },
  adminscrollbar: { bg: '#ADADAD', bar: { color: '#FFD175' } },
  oldColors: {
    danger: '#DC4242',
    info: '#3FBCD1',
    success: '#36B550',
    warning: '#FFC64C',
    white: '#FAFAFA',
    black: '#323133',
    lightgray: '#B3B3B3',
    lightorange: '#F8EFDC',
    blackopacity: '#32313320',
    bgColor: '#C2C2C2',
    primary: {
      default: '#FFD175',
      100: '#553B06',
      90: '#77540D',
      80: '#996E18',
      70: '#BB8A27',
      60: '#DDA638',
      50: '#FFC44C',
      40: '#FFD175',
      30: '#FEDE9C',
      20: '#FFECC5',
      10: '#FFF9ED',
    },
    secondary: {
      100: '#252D45',
      90: '#384362',
      80: '#4D5980',
      70: '#63719D',
      60: '#7B8BBA',
      50: '#95A5D7',
      40: '#B0C1F4',
      30: '#C9D5FA',
      20: '#E2E9FF',
      10: '#F8F9FF',
    },
    cadetblue: {
      100: '#2D3B40',
      90: '#405359',
      80: '#556B73',
      70: '#6B838C',
      60: '#819CA6',
      50: '#99B5BF',
      40: '#B2CFD9',
      30: '#CBE8F2',
      20: '#E0F0F6',
      10: '#F6F9FA',
    },
    skyblue: {
      100: '#22525A',
      90: '#306D77',
      80: '#418894',
      70: '#53A4B1',
      60: '#67C0CF',
      50: '#7DDCEC',
      40: '#8FEFFF',
      30: '#B1F3FF',
      20: '#D2F8FF',
      10: '#F4FDFF',
    },
    gray: {
      100: '#474647',
      90: '#5C5C5C',
      80: '#707070',
      70: '#858585',
      60: '#999999',
      50: '#ADADAD',
      40: '#C2C2C2',
      30: '#D6D6D6',
      20: '#EBEBEB',
      10: '#EBEBEB',
    },
  },
};

export default adminTheme;
