import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import Button from '~/components/NexAdmin/Buttons/Button/index';
import Input from '~/components/NexAdmin/Inputs/Input/index';
import * as S from './styles';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import { useState } from 'react';
import Loading from '~/components/Loading';
import api from '~/services/api';
import { showNotification } from '~/utils/notificationBoxes';
import { UserInfo } from './UserInfo';
import NoDataMessage from '../../../components/NexAdmin/NoDataMessage/index';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import ValidadeCPFDescription from '~/components/NexAdmin/Modal/Descriptions/validateCPFDescription';
import { Select } from '~/components/NexAdmin/Inputs';

const ValidateCpf = () => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  const { openModal } = useModal();

  const handleSubmit = (data) => {
    const { cpf } = data;
    setLoading(true);
    if (cpf.length == 0) {
      setLoading(false);
      showNotification('warning', 'Atenção', 'Insira um CPF');
      return;
    }
    api
      .post(`/dadoscpf`, data)
      .then((response) => {
        const founds = [];
        const { data } = response;
        const {
          SIV_user,
          INOVA_user,
          gc_user,
          aparelhos_user,
          pap_user,
          pap_pc_user,
        } = data;

        if (SIV_user.found) founds.push('SIV');
        if (INOVA_user.found) founds.push('Inova');
        if (gc_user.found) founds.push('Geral');
        if (aparelhos_user.found) founds.push('Aparelhos');
        if (pap_user.found) founds.push('PAP Indireto');
        if (pap_pc_user.found) founds.push('PAP Premium e Condomínios');
        setResult(data);
        setLoading(false);
        showNotification(
          'success',
          'Sucesso!',
          founds.length > 0
            ? `Usuario encontrado nas seguintes bases: ${founds.join(', ')}`
            : 'Usuário não foi encontrado em nenhuma base de dados.',
        );
      })
      .catch(() => {
        showNotification('warning', 'Atenção', 'Erro ao buscar dados do CPF');
        setLoading(false);
      });
  };

  const renderResults = () =>
    Object.keys(result).map((key) => (
      <S.Card>
        <h2>{result[key].display_name}</h2>
        {result[key]?.found ? UserInfo(key, result) : NoDataMessage}
      </S.Card>
    ));

  const openThermsModal = () => {
    openModal({
      title: 'Validar CPF',
      close: true,
      content: <ValidadeCPFDescription />,
    });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="magnifier"
          title="Pesquisar CPF"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <S.FormWrapper onSubmit={handleSubmit}>
              <Input
                name="cpf"
                placeholder="Digite o CPF ou CNPJ"
                label="CPF:"
              />
              <Select
                isMulti
                name="channels"
                label="Canal"
                placeholder="Selecione canais se não for necessário pesquisar em todas as bases"
                options={[
                  { label: 'SIV - Varejo/AA', value: 2 },
                  { label: 'INOVA - Recarga', value: 5 },
                  { label: 'GIGA - Aparelhos', value: 6 },
                  { label: 'GIGA - PAP Indireto', value: 8 },
                  { label: 'GIGA - Premium/Condominio', value: 9 },
                ]}
              />
              <Button>Buscar</Button>
            </S.FormWrapper>
            <S.CardsContainer>{renderResults()}</S.CardsContainer>
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default ValidateCpf;
