import React from 'react';

const Print = ({ color }) => {
  return (
    <svg
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1663 5.83333C22.103 5.83333 23.6663 7.39667 23.6663 9.33333V16.3333H18.9997V21H4.99967V16.3333H0.333008V9.33333C0.333008 7.39667 1.89634 5.83333 3.83301 5.83333H4.99967V0H18.9997V5.83333H20.1663ZM7.33301 2.33333V5.83333H16.6663V2.33333H7.33301ZM16.6663 18.6667V14H7.33301V18.6667H16.6663ZM18.9997 14H21.333V9.33333C21.333 8.69167 20.808 8.16667 20.1663 8.16667H3.83301C3.19134 8.16667 2.66634 8.69167 2.66634 9.33333V14H4.99967V11.6667H18.9997V14ZM20.1663 9.91667C20.1663 10.5583 19.6413 11.0833 18.9997 11.0833C18.358 11.0833 17.833 10.5583 17.833 9.91667C17.833 9.275 18.358 8.75 18.9997 8.75C19.6413 8.75 20.1663 9.275 20.1663 9.91667Z"
        fill={color}
      />
    </svg>
  );
};
export default Print;
