import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.footer`
  grid-area: footer;
  background: linear-gradient(178.18deg, #ff0000 -13.56%, #760000 190%);
  /* padding-top: 28px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  display: none;
  z-index: 100;
  ${media.greaterThan('medium')`
  display: flex;

`}
`;
export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  justify-content: center;
  align-items: flex-start;
  .logo {
    height: 100%;
    padding: 20px;
    align-self: center;
  }
  .footer-column-title {
    margin: 0px 0px 10px;
    text-align: left;
    width: 100%;
    gap: 20px;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
  }
  .footer-column-ul {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    color: white;
  }
`;
export const FooterWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 3fr;
  height: 100%;
  .underline {
    text-decoration: underline;
  }
`;

export const CopyWrite = styled.div`
  background-color: ${({ theme }) => theme.colors.grayDarker};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.spacings.xxsmall};
  text-align: center;
  padding: 5px;
  align-self: baseline;
  width: 100%;
`;

export const FooterColumnLineWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const FooterColumnUlWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;
