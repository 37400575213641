import styled from 'styled-components';
import media from 'styled-media-query';

export const Actions = styled.div`
  display: flex;
  gap: 5px;
  max-width: 50px;
`;

export const Edit = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 16px;
  }
`;
