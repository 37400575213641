import { useState, useEffect, useRef } from 'react';
import { parseJwt } from '../services/auth';

const useLink = (links) => {
  const [link, setLink] = useState(null);
  const isMounted = useRef(true);

  useEffect(() => {
    const fetchLink = () => {
      const jwtData = parseJwt();
      const canalId = jwtData.canal_id || localStorage.getItem('channelId');
      const cargo = jwtData.cargo;
      const usuRede = jwtData.rede || '';
      let fetchedLink = null;

      switch (canalId) {
        case '2':
        case 2:
          fetchedLink = usuRede.includes('Magazine Luiza')
            ? links.varejo.magazine
            : [75, 79, 85].includes(cargo)
            ? links.varejo.bko
            : links.varejo.default;
          break;
        case '4':
        case 4:
          fetchedLink = links.agenteAutorizado.default;
          break;
        case '5':
        case 5:
          fetchedLink = links.recarga.default;
          break;
        case '6':
        case 6:
          fetchedLink = links.aparelhos.default;
          break;
        case '8':
        case 8:
          fetchedLink = [76, 75, 91].includes(cargo)
            ? links.pap.bko
            : links.pap.default;
          break;
        case '9':
        case 9:
          fetchedLink = [93, 94, 101].includes(cargo)
            ? links.pc.premium
            : [98, 99, 102, 104].includes(cargo)
            ? links.pc.condominio
            : [100, 103].includes(cargo)
            ? links.pc.condominioBko
            : [105].includes(cargo)
            ? links.pc.premiumBko
            : null;
          break;
        default:
          break;
      }

      if (isMounted.current) {
        setLink(fetchedLink);
      }
    };

    fetchLink();

    return () => {
      isMounted.current = false;
    };
  }, [links]);

  return link;
};

export default useLink;
