import getIcon from '~/Helpers/getIcon';
import * as S from './styles';
import { useRef, useState } from 'react';
import Button from '~/components/Button';
import { showNotification } from '~/utils/notificationBoxes';
import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { appendObjectInFormData } from '~/utils';
import moment from 'moment';
import Loading from '~/components/Loading';

const Modal = ({
  id,
  campaignName,
  campaignType,
  duration,
  setOpenModal,
  reset,
  userMedia,
}) => {
  const inputRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [terms, setTerms] = useState(false);

  const [media, setMedia] = useState(userMedia);

  const submit = () => {
    if (!terms) {
      return showNotification(
        'danger',
        'Atenção',
        'Você precisa aceitar os termos para continuar',
      );
    }

    if (!image) {
      return showNotification(
        'danger',
        'Atenção',
        'Você precisa enviar uma foto para continuar',
      );
    }

    setLoading(true);

    const formData = new FormData();
    appendObjectInFormData(formData, {
      media_image: image,
      media_campaign_id: id,
      media_id: userMedia?.id,
    });

    api
      .post(
        userMedia ? `/media-campaign/media/update` : `/media-campaign/media/`,
        formData,
      )
      .then((response) => {
        setLoading(false);
        setOpenModal(false);
        showNotification('success', 'Sucesso', 'Arquivo enviado com sucesso!');

        reset();
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  const deleteMedia = () => {
    api
      .delete(`/media-campaign/media/${userMedia.id}`)
      .then((response) => {
        setOpenModal(false);
        showNotification('success', 'Sucesso', 'Arquivo removido com sucesso!');

        reset();
      })
      .catch((error) => {
        return HTTPErrorCallback(error);
      });
  };

  const status = (status) => {
    switch (status) {
      case 'pending':
        return 'Não Avaliado';
      case 'approved':
        return 'Aprovado';
      case 'rejected':
        return 'Rejeitado';
      default:
        return 'Pendente';
    }
  };

  return (
    <S.Background>
      {loading && <Loading />}
      <S.Modal>
        <S.Header>
          <S.HeaderTitle>
            {getIcon('newProject', '#C50101')}
            <span>Enviar Arquivo</span>
          </S.HeaderTitle>

          <div onClick={() => setOpenModal(false)}>{getIcon('close')}</div>
        </S.Header>

        <S.TitleBlock>
          <S.Title>{campaignName}</S.Title>

          <S.Duration>{duration}</S.Duration>
        </S.TitleBlock>

        <S.Main>
          <S.Preview>
            {!media && <h3>Preview</h3>}

            <S.PreviewBlock>
              <S.PreviewPhoto>
                {image || media ? (
                  campaignType === 'image' ? (
                    <img
                      src={media ? media.media_src : URL.createObjectURL(image)}
                      alt="Preview"
                    />
                  ) : (
                    <video
                      src={media ? media.media_src : URL.createObjectURL(image)}
                      controls
                    />
                  )
                ) : (
                  <span>Você ainda não tem um arquivo</span>
                )}
              </S.PreviewPhoto>
            </S.PreviewBlock>
          </S.Preview>

          <S.PhotoUpload>
            {media ? (
              <>
                {media.status === 'pending' && (
                  <S.Alert>
                    {getIcon('info', '#C4C4C4')}Você só poderá trocar este
                    arquivo até antes de ser avaliado, após a avaliação não será
                    possível troca-lo.
                  </S.Alert>
                )}

                <S.Row>
                  <S.Info>
                    <span>Data Publicação</span>
                    <span>
                      {moment(media.created_at).format('DD/MM/YYYY - HH:mm')}
                    </span>
                  </S.Info>

                  <S.Info>
                    <span>Última Atualização</span>
                    <span>
                      {moment(media.updated_at).format('DD/MM/YYYY - HH:mm')}
                    </span>
                  </S.Info>

                  <S.Info>
                    <span>Status</span>
                    <S.PhotoStatus status={media.status}>
                      {status(media.status)}
                    </S.PhotoStatus>
                  </S.Info>
                </S.Row>

                {media.refusal_reason && (
                  <S.Refusal>
                    <h3>Comentários</h3>
                    <span>{media.refusal_reason}</span>
                  </S.Refusal>
                )}

                <S.FileBlock>
                  <S.FileName>
                    {getIcon('cloudUpload', '#484848')}
                    <span>{media.file_name}</span>
                  </S.FileName>

                  <S.Row>
                    <Button
                      style={{
                        border: '1px solid #C50101',
                        background: 'transparent',
                        color: '#C50101',
                      }}
                      onClick={() => deleteMedia()}
                      to="#"
                    >
                      Excluir
                    </Button>
                    <Button
                      to="#"
                      onClick={() => {
                        inputRef.current.click();
                      }}
                    >
                      Trocar
                    </Button>
                  </S.Row>

                  <input
                    type="file"
                    ref={inputRef}
                    accept={
                      campaignType === 'image'
                        ? 'image/jpeg, image/jpg, image/png'
                        : 'video/mp4, video/x-m4v, video/*'
                    }
                    onChange={() => {
                      setImage(inputRef.current.files[0]);
                      setMedia(null);
                    }}
                  />
                </S.FileBlock>
              </>
            ) : (
              <>
                <h3>Arquivo</h3>

                <S.UploadBlock>
                  {getIcon('cloudUpload', '#484848')}
                  {image ? (
                    <>
                      <span>{image.name}</span>
                      <span>{image.size / 1000000} MB</span>
                    </>
                  ) : (
                    <>
                      <span>Faça o upload do arquivo</span>
                      <span>
                        Os formatos recomendados são{' '}
                        {campaignType === 'image'
                          ? '.JPEG, .JPG e .PNG'
                          : '.MP4 e .M4V'}{' '}
                        com o máximo de 30MB.
                      </span>
                    </>
                  )}

                  <Button
                    style={{ width: '200px' }}
                    to="#"
                    onClick={() => {
                      inputRef.current.click();
                    }}
                  >
                    Escolher arquivo
                  </Button>

                  <input
                    type="file"
                    ref={inputRef}
                    accept={
                      campaignType === 'image'
                        ? 'image/jpeg, image/jpg, image/png'
                        : 'video/mp4, video/x-m4v, video/*'
                    }
                    onChange={() => {
                      setImage(inputRef.current.files[0]);
                    }}
                  />
                </S.UploadBlock>

                <S.Checkbox>
                  <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    onChange={() => {
                      setTerms(!terms);
                    }}
                    checked={terms}
                  />
                  <label>
                    Eu revisei e confirmo o upload do meu arquivo para
                    participar da campanha.
                  </label>
                </S.Checkbox>

                {image && (
                  <S.Alert>
                    {getIcon('info', '#C4C4C4')}O prazo para o avaliador
                    analisar seu arquivo é de 48 horas. Após a avaliação não é
                    mais possível trocar o arquivo.
                  </S.Alert>
                )}
              </>
            )}
          </S.PhotoUpload>
        </S.Main>

        {!media && (
          <S.Buttons>
            <Button
              style={{
                border: '1px solid #C50101',
                background: 'transparent',
                color: '#C50101',
              }}
              onClick={() => {
                setOpenModal(false);
              }}
              to="#"
            >
              Descartar
            </Button>
            <Button
              to="#"
              onClick={() => {
                submit();
              }}
              disabled={!image && !terms}
            >
              Enviar
            </Button>
          </S.Buttons>
        )}
      </S.Modal>
    </S.Background>
  );
};

export default Modal;
