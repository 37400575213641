import Button from '~/components/NexAdmin/Buttons/Button';
import InputUpload from '~/components/NexAdmin/Inputs/InputUpload';
import { HTTPErrorCallback } from '~/Helpers/Error';
import getIcon from '~/Helpers/getIcon';
import api from '~/services/api';
import { showNotification } from '~/utils/notificationBoxes';
import * as S from './styles';
import { Form } from '@unform/web';

const Validate = ({
  setLoading,
  setErrors,
  file,
  setFile,
  changeStep,
  setData,
}) => {
  const validate = () => {
    if (!file)
      return showNotification(
        'warning',
        'Atenção',
        'Por favor, selecione um arquivo',
      );

    setLoading(true);

    const formData = new FormData();

    formData.append('sheet', file);

    api
      .post('/create-pap-users/validate', formData)
      .then((response) => {
        const { errors } = response.data;
        if (errors && errors.length) {
          setFile(null);
          setErrors(errors);
          changeStep('error');
        } else {
          setData(response.data);
          changeStep('createUsers');
        }

        setLoading(false);
      })
      .catch((error) => {
        setFile(null);
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <S.Wrapper>
      <h2>Selecione um arquivo para validação</h2>

      <S.InputWrapper>
        <Form>
          <InputUpload
            name="arquivo"
            onGetFile={setFile}
            placeholder={
              <>{getIcon('upload')} Selecione um arquivo para upload</>
            }
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </Form>
        <Button type="button" width="50%" onClick={validate}>
          Validar
        </Button>
        <S.DownloadModel
          target="_blank"
          download
          href="/worksheets/PAP Modelo usuários em massa.xlsx"
        >
          Baixar modelo de Upload
        </S.DownloadModel>
      </S.InputWrapper>
    </S.Wrapper>
  );
};

export default Validate;
