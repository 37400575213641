import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  ${media.greaterThan('medium')`
    flex-direction: row;
    align-items: center;
    gap: 10px;
  `};

  h1 {
    margin-right: 15px;
    color: ${({ theme }) => theme.colors.grayDarker};
    font-size: 1.6rem;
  }

  button {
    background: ${({ theme }) => theme.colors.degrade};
    border-radius: 25px;
    border: 0;
    padding: 10px 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 400;
    font-size: 1.4rem;
    transition: box-shadow 0.2s;
    text-decoration: none;
    cursor: pointer;
  }

  form {
    display: flex;
    justify-content: space-between;
    align-items: center;

    flex: 1;
    flex-direction: column;
    gap: 5px;

    label {
      white-space: nowrap;
    }
    ${media.greaterThan('medium')`
    flex-direction: row;
    gap: inherit;
  `};
  }
`;
