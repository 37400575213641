import React from 'react';

const Drag = ({ color }) => {
  return (
    <svg
      width="13"
      height="39"
      viewBox="0 0 13 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.5H4.22222V4.72222H0V0.5ZM8.44444 0.5H12.6667V4.72222H8.44444V0.5ZM0 8.94444H4.22222V13.1667H0V8.94444ZM8.44444 8.94444H12.6667V13.1667H8.44444V8.94444ZM0 17.3889H4.22222V21.6111H0V17.3889ZM8.44444 17.3889H12.6667V21.6111H8.44444V17.3889ZM0 25.8333H4.22222V30.0556H0V25.8333ZM8.44444 25.8333H12.6667V30.0556H8.44444V25.8333ZM0 34.2778H4.22222V38.5H0V34.2778ZM8.44444 34.2778H12.6667V38.5H8.44444V34.2778Z"
        fill={color}
      />
    </svg>
  );
};
export default Drag;
