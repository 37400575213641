import { useEffect, useState } from 'react';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';
import Loading from '~/components/Loading';
import columns from './columns';
import { HTTPErrorCallback } from '~/Helpers/Error';
import api from '~/services/api';
import Table from '~/components/NexAdmin/Table';
import moment from 'moment';
import { ConfirmButton } from '~/components/NexAdmin/Buttons';
import { showNotification } from '~/utils/notificationBoxes';
import getIcon from '~/Helpers/getIcon';

import * as S from './styles';
import NetworksUpdate from './Update';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import adminRoutes from '~/routes/Components/NexAdmin/routes';
import Button from '~/components/NexAdmin/Buttons/Button';

const Networks = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [networks, setNetworks] = useState([]);

  const [selectedNetwork, setSelectedNetwork] = useState(null);

  useEffect(() => {
    getNetworks();
  }, []);

  const getNetworks = () => {
    setLoading(true);

    api
      .get('/redes/')
      .then((response) => {
        setNetworks(response.data);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const removeNetwork = (id) => {
    setLoading(true);

    api
      .delete(`/redes/${id}`)
      .then((response) => {
        getNetworks();
        setLoading(false);

        showNotification('success', 'Rede removida com sucesso!');
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="access"
          title={selectedNetwork ? 'Editar Rede' : 'Redes'}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            {selectedNetwork ? (
              <NetworksUpdate
                selectedNetwork={selectedNetwork}
                setSelectedNetwork={setSelectedNetwork}
                getNetworks={getNetworks}
              />
            ) : (
              <>
                <Button
                  type="button"
                  icon="plus"
                  onClick={() => history.push(adminRoutes.NetworksCreate.path)}
                >
                  Nova Rede
                </Button>

                <Table
                  columns={[
                    {
                      name: 'Ações',
                      maxWidth: '50px',
                      cell: (row) => (
                        <S.Actions>
                          <ConfirmButton
                            icon={'trash'}
                            iconColor={'red'}
                            label="Remover Rede?"
                            confirmLabel={'SIM'}
                            cancelLabel="CANCELAR"
                            openOnRight={true}
                            confirmAction={() => removeNetwork(row.id)}
                          />
                          <S.Edit
                            onClick={() => {
                              setSelectedNetwork(row);
                            }}
                          >
                            {getIcon('edit')}
                          </S.Edit>
                        </S.Actions>
                      ),
                    },
                    ...columns,
                  ]}
                  downloadColumns={columns}
                  data={networks}
                  dataTableProps={{ pagination: true }}
                  header={true}
                  fileName={`redes_${moment().format('DD-MM-YYYY')}`}
                  hasDownload={true}
                  refresh={getNetworks}
                />
              </>
            )}
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default Networks;
