import styled from 'styled-components';
import media from 'styled-media-query';

// import Button from '../Button';

export const Wrapper = styled.section`
  grid-area: profileRank;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 50px;
  ${media.greaterThan('medium')`
  width: 100%;
`};
`;

export const Container = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  position: relative;
  width: 100vw;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  ${media.greaterThan('medium')`
    flex-direction: row;
    /* justify-content: center; */
    width: 100%;
    align-items: center;
  }
  `};
  /* height: 167px; */

  padding: 26px 20px;

  &:after,
  &:before {
    top: 12px;
    height: 143px;
    background: ${({ theme }) => theme.colors.degrade};
  }

  &:after {
    content: '';
    width: 20px;
    position: absolute;
    left: -20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &:before {
    content: '';
    width: 20px;
    position: absolute;
    right: -20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const UserContainer = styled.div`
  display: flex;
  text-align: left;

  div {
    h1,
    p {
      color: ${({ theme }) => theme.colors.grayDarker};
    }
    h1 {
      font-size: ${({ theme }) => theme.font.sizes.medium};
    }
    p {
      font-size: ${({ theme }) => theme.font.sizes.xxsmall};

      &:first-of-type {
        padding: 12px 0 3px;
      }
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  text-align: center;

  ${media.greaterThan('medium')`
    align-items: flex-end;
  `};

  p {
    font-weight: ${({ theme }) => theme.font.bold};
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};
    color: ${({ theme }) => theme.colors.grayDarker};

    &:nth-child(2n + 1) {
      text-transform: uppercase;
      margin-top: 28px;
    }

    &:last-child {
      font-weight: ${({ theme }) => theme.font.regular};
      margin-bottom: 13px;
    }

    &:nth-child(2) {
      color: red;
      font-size: ${({ theme }) => theme.font.sizes.large};
      font-weight: ${({ theme }) => theme.font.extraBold};
    }

    &:nth-child(3) {
      font-size: 1.2rem;
      margin-top: 13px;
    }
  }
`;

// export const ReadMore = styled(Button)`
//   display: none;

//   ${media.greaterThan('medium')`
//     display: block;
//   `};
// `;

export const LatestAchievements = styled.div`
  position: relative;
  .tooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      font-size: 01rem;
      white-space: nowrap;
    }
    &:before {
      content: '';
      border-style: solid;
      border-width: 10px 15px 10px 0;
      position: absolute;

      border-color: transparent #b50101 transparent transparent;
      z-index: -1;
      top: 30px;
      transform: rotate(270deg) translateY(-50%) scale(0.5);

      left: 50%;
    }
    display: none;
    position: absolute;
    top: -50px;
    z-index: 10;
    background: linear-gradient(178.18deg, #f00 -13.56%, #760000 190%);
    border-radius: 25px;
    color: white;
    padding: 2px 12px;
    left: 50%;
    font-size: 12px;
    transform: translateX(-50%);
    animation: 0.2s forwards fadeIn;
    @keyframes fadeIn {
      0% {
        opacity: 0;
        margin-top: 20px;
      }
      100% {
        opacity: 1;
        margin-top: 0px;
      }
    }
  }
  width: 95%;
  height: 90px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  top: -3px;

  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 3px 17px;

  ${media.greaterThan('medium')`
    height: 64px;
    padding: 0 25px;
    justify-content: space-between;
    flex-direction: row;
  `};

  border: 3px solid ${({ theme }) => theme.colors.red};

  p {
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
    color: ${({ theme }) => theme.colors.red};
    font-weight: ${({ theme }) => theme.font.bold};
    text-align: left;
  }

  .opacity {
    opacity: 0.6;
  }
  .current {
    transform: scale(1.1);
    margin-right: 25px;
    margin-left: 2px;
  }

  ul {
    margin-top: 8px;
    list-style: none;
    display: flex;
    position: relative;

    li {
      position: relative;
      &:hover .tooltip {
        display: flex;
      }
      img {
        transition: 0.2s ease all;
      }
      &:hover img {
        transform: scale(1.2);
      }
      height: 30px;
      width: 30px;
    }

    li:first-child {
      margin-right: 15px;
    }

    li + li {
      margin-right: 15px;
    }

    ${media.greaterThan('medium')`
      li {
        height: 40px;
        width: 40px;
      }

      li:first-child {
        margin-right: 20px;
      }

      li + li {
        margin-right: 20px;
      }
    `};
  }
`;
