import { useState } from 'react';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import ContainerWithTabs from '~/components/NexAdmin/ContainerWithTabs';
import StatusTag from '~/components/NexAdmin/StatusTag';
import Loading from '~/components/Loading';
import BaseBatches from './BaseBatches';
import {
  columns_aprove_mass,
  columns_insert_mass,
  columns_reprove_mass,
} from '../columns';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import BatchesGNDescription from '~/components/NexAdmin/Modal/Descriptions/GN/batches';

const GNBatches = () => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState('validate');
  const { openModal } = useModal();
  const tab = [
    {
      title: 'Inserir Pontos em Massa',
      info: 'Adicione pontos para vários LADs ao mesmo tempo.',
      columns: columns_insert_mass,
      option: 'insert',
      list: [
        { cpf: '11111111111', points: 21 },
        { cpf: '00000000000', points: 10 },
        { cpf: '22222222222', points: 5 },
      ],
      fileName: 'Modelo Pontos em Massa',
    },
    {
      title: 'Aprovar em massa',
      info: 'Aprove vários campanhas LAD ao mesmo tempo. Só é possível aprovar campanhas que possuem status Pendente ou Pré-Aprovado',
      columns: columns_aprove_mass,
      option: 'approve',
      list: [
        { transaction_id: '125' },
        { transaction_id: '360' },
        { transaction_id: '1456' },
      ],
      fileName: 'Modelo Aprovar em Massa',
    },
    {
      title: 'Reprovar em Massa',
      info: 'Reprove vários campanhas LAD ao mesmo tempo. Só é possível reprovar campanhas que possuem status Pendente ou Pré-Aprovado',
      columns: columns_reprove_mass,
      option: 'reprove',
      list: [
        { transaction_id: '125', recused_reason: 'Não elegível' },
        { transaction_id: '360', recused_reason: null },
        {
          transaction_id: '1456',
          recused_reason: 'Verifique ponto focal de sua regional',
        },
      ],
      fileName: 'Modelo Reprovar em Massa',
    },
  ];

  const openThermsModal = (row) => {
    openModal({
      title: 'Input de dados em Massa - LAD',
      close: true,
      content: <BatchesGNDescription isLAD={true} />,
    });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="config"
          title="Gerenciamento de Campanhas"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTabs
            tabs={tab.map((item, i) => ({
              title: item.title,
              component: (
                <BaseBatches
                  info={item.info}
                  columns={item.columns}
                  list={item.list}
                  fileName={item.fileName}
                  setLoading={setLoading}
                  option={item.option}
                  title={item.title}
                  step={step}
                  setStep={setStep}
                  current={i}
                />
              ),
            }))}
          />
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default GNBatches;
