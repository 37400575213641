import { store } from 'react-notifications-component';

export const showNotification = (type, title, message) => {
  /*
    NOTIFICATION TYPES
    -success
    -danger
    -info
    -default
    -warning
  */

  return store.addNotification({
    title,
    message: message || title || '',
    type,
    insert: 'right',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 3000,
      onScreen: true,
      pauseOnHover: true,
    },
  });
};
