import * as S from '../styles';
import { toDateTime } from '~/Helpers/Date';

const Header = ({ ticket }) => {
  return (
    <>
      <S.Ticket header>
        <S.TicketInfo header>
          <S.Title>
            Número do Ticket: <S.Data>{ticket.id}</S.Data>
          </S.Title>
          <br />
          <S.Title>
            Data da Abertura: <S.Data>{toDateTime(ticket.created_at)}</S.Data>
          </S.Title>
          <br />
          <S.Title>
            Assunto: <S.Data>{ticket.open_reason}</S.Data>
          </S.Title>
          <br />
          <S.Title>
            Status: <S.Data>{ticket.status}</S.Data>
          </S.Title>
          <br />
          <S.Title>
            Conteúdo: <S.Data> {ticket.comment ?? 'N/A'}</S.Data>
          </S.Title>
          <br />
          {ticket.status === 'Finalizado' && (
            <S.Title>
              Razão de Finalização:{' '}
              <S.Data> {ticket.finished_reason ?? 'N/A'}</S.Data>
            </S.Title>
          )}
        </S.TicketInfo>
        <S.FileWrapper>
          <S.Title>Arquivos</S.Title>
          {ticket.files.length > 0 ? (
            <>
              {ticket.files.map((item) => {
                return (
                  <S.File>
                    <a href={item.location}>{item.file_name}</a>
                  </S.File>
                );
              })}
            </>
          ) : (
            <S.Data>Ocorrência não possui arquivos.</S.Data>
          )}
        </S.FileWrapper>
      </S.Ticket>
    </>
  );
};

export default Header;
