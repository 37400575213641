import styled from 'styled-components';

export const Container = styled.div`
  grid-area: campaigns;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 48px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgb(133 133 133 / 30%);
  position: relative;
`;

export const Title = styled.p`
  margin-left: 5px;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  color: ${({ theme }) => theme.colors.red};
  font-weight: ${({ theme }) => theme.font.medium};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;
