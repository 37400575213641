import { toDate } from '~/Helpers/Date';

const columns = [
  {
    name: 'Produtos',
    selector: (row) =>
      row.gc_plano
        ? row.gc_plano.map((product) => product.plano_nome).join(', ')
        : '',
    sortable: true,
  },
  {
    name: 'Ação',
    selector: (row) => row.title,
    sortable: true,
  },
  {
    name: 'Regional',
    selector: (row) => row.region,
    sortable: true,
  },
  {
    name: 'Cargos',
    selector: (row) => row.elegible_roles,
  },
  {
    name: 'Apuração',
    selector: (row) => row.apuration,
    sortable: true,
  },
];

const downloadColumns = [
  {
    name: 'Produtos',
    selector: (row) =>
      row.gc_plano
        ? row.gc_plano.map((product) => product.plano_nome).join(', ')
        : '',
    sortable: true,
  },
  {
    name: 'Ação',
    selector: (row) => row.title,
    sortable: true,
  },
  {
    name: 'Regional',
    selector: (row) => row.region,
    sortable: true,
  },
  {
    name: 'Cargos',
    selector: (row) => row.elegible_roles,
  },
  {
    name: 'Apuração',
    selector: (row) => row.apuration,
    sortable: true,
  },
  {
    name: 'PDVS',
    selector: (row) => row.pdvs,
    sortable: true,
  },
  {
    name: 'Período da mecânica - De',
    selector: (row) => toDate(row.start_date),
    sortable: true,
  },
  {
    name: 'Período da mecânica - Até',
    selector: (row) => toDate(row.end_date),
    sortable: true,
  },
  {
    name: 'Período da apuração - De',
    selector: (row) => toDate(row.apuration_start_date),
    sortable: true,
  },
  {
    name: 'Período da apuração - Até',
    selector: (row) => toDate(row.apuration_end_date),
    sortable: true,
  },
  {
    name: 'Mecânica',
    selector: (row) => row.description,
    sortable: true,
  },
  {
    name: 'Regulamento',
    selector: (row) => row.regulations_file_url,
    sortable: true,
  },
  {
    name: 'Imagem',
    selector: (row) => row.cover_image_url,
    sortable: true,
  },
  {
    name: 'Rede',
    selector: (row) => row.network,
    sortable: true,
  },
  {
    name: 'Data de conclusão',
    selector: (row) => toDate(row.done_date),
    sortable: true,
  },
  {
    name: 'Removido',
    selector: (row) => (row.deleted_at ? toDate(row.deleted_at) : 'N/A'),
    sortable: true,
  },
];

const objectWithColumns = { columns, downloadColumns };

export default objectWithColumns;
