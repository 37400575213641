import React from 'react';

const Projects = ({ color }) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6668 8.375H2.33342V2.95833H11.0001H19.6668V8.375ZM19.6668 12.7083H12.0834V10.5417H19.6668V12.7083ZM19.6668 17.0417H12.0834V14.875H19.6668V17.0417ZM9.91675 17.0417H2.33342V10.5417H9.91675V17.0417ZM20.0243 0.25H18.2259H16.4167H14.6076H12.8092H11.0001H9.19092H7.39258H5.58342H3.77425H1.97592C0.97674 0.25 0.166748 1.05999 0.166748 2.05917V17.5833C0.166748 18.158 0.395021 18.7091 0.80135 19.1154C1.20768 19.5217 1.75878 19.75 2.33342 19.75H19.6668C20.2414 19.75 20.7925 19.5217 21.1988 19.1154C21.6051 18.7091 21.8334 18.158 21.8334 17.5833V2.05917C21.8334 1.05999 21.0234 0.25 20.0243 0.25Z"
        fill={color}
      />
    </svg>
  );
};
export default Projects;
