import { useEffect, useState } from 'react';
import { HTTPErrorCallback } from '~/Helpers/Error';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';

import Loading from '~/components/Loading';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import { Form } from '@unform/web';
import Input from '~/components/NexAdmin/Inputs/Input';
import Button from '~/components/NexAdmin/Buttons/Button';
import * as S from './styles';
import { InputMask, Select } from '~/components/NexAdmin/Inputs';
import { showNotification } from '~/utils/notificationBoxes';
import { useHistory } from 'react-router-dom';
import { appendObjectInFormData } from '~/utils';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import CreateDescription from '~/components/NexAdmin/Modal/Descriptions/Tickets/createDescription';
import { origin_column, status_column } from '../columns';
import { verifyFile } from '~/Helpers/FileValidator';

const CreateTickets = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [cpf, setCpf] = useState('');
  const [foundUser, setFoundUser] = useState(null);
  const [allowUserResponse, setAllowUserResponse] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [files, setFiles] = useState([]);
  const [topic, setTopic] = useState([]);
  const { openModal } = useModal();

  useEffect(() => {
    api
      .get('/tickets/topics-admin')
      .then((response) => setTopic(response.data));
  }, []);

  const createTicket = async (data) => {
    setLoading(true);
    if (!foundUser) {
      setLoading(false);
      return showNotification('warning', 'Adicione um usuário válido!');
    }
    if (!data.status || !data.origin || !data.open_reason) {
      setLoading(false);
      return showNotification(
        'warning',
        'Atenção',
        'É obrigatório informar a Razão de abertura, Status e Origem!',
      );
    }
    const formData = new FormData();
    appendObjectInFormData(formData, {
      admin_id: parseJwt().id,
      allow_user_response: allowUserResponse,
      usu_id: foundUser.usu_id,
      ...data,
    });
    for (const file of files) {
      const item = Object.entries(file)[0];
      formData.append('file', new File([item[1]], item[0]));
    }
    api
      .post('/tickets/admin-create', formData)
      .then((r) => {
        setLoading(false);
        showNotification('success', 'Ticket criado com sucesso!');

        setFoundUser(null);
        setCpf('');

        history.push('/admin/tickets/list');
      })
      .catch((err) => {
        setLoading(false);
        return HTTPErrorCallback(err);
      });
  };
  const searchUser = () => {
    setLoading(true);

    api
      .get(`/list-user/user-cpf/${cpf}`)
      .then((response) => {
        setLoading(false);
        setFoundUser(response.data);
      })
      .catch((err) => {
        setFoundUser(null);
        setLoading(false);
        return HTTPErrorCallback(err);
      });
  };

  const parseChannel = (user) => {
    switch (user.canal_id) {
      case 2:
        return 'Varejo';
      case 4:
        return 'Agente Autorizado';
      case 5:
        return 'Distribuição';
      case 6:
        return 'Loja Própria';
      case 8:
        return 'PAP';
      default:
        return 'incluir canal';
    }
  };
  const openThermsModal = () => {
    openModal({
      title: 'Criação de ocorrência',
      close: true,
      content: <CreateDescription />,
    });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="monitor"
          title="Criar Ocorrência"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <S.FormWrapper>
              <Form onSubmit={createTicket} encType="multipart/form-data">
                {topic && (
                  <Select
                    name="open_reason"
                    label="Razão da abertura"
                    placeholder="Selecione uma opção"
                    options={topic}
                    defaultValue={topic[0]}
                    required
                  />
                )}
                <Input
                  name="comment"
                  label="Informações complementares"
                  type="text"
                  required
                />
                <S.SearchUser>
                  <InputMask
                    name="cpf"
                    onChange={(e) => setCpf(e.target.value)}
                    label="Usuário"
                    required
                  />

                  <Button
                    type="button"
                    onClick={() => searchUser()}
                    icon="plus"
                  >
                    Pesquisar Usuário
                  </Button>
                </S.SearchUser>

                {foundUser && (
                  <>
                    <h4>Usuário encontrado!</h4>
                    <p>Nome: {foundUser.usu_nome}</p>
                    <p>CPF: {foundUser.usu_cpf}</p>
                    <p>Telefone: {foundUser.usu_cel}</p>
                    <p>Email: {foundUser.usu_email}</p>
                    <p>Canal: {parseChannel(foundUser)}</p>
                    <hr />
                  </>
                )}

                {!isFinished && (
                  <S.Checkbox>
                    <label>Permitir resposta do usuário</label>
                    <input
                      type="checkbox"
                      name="allow_user_response"
                      onChange={(e) => setAllowUserResponse(e.target.checked)}
                    />
                  </S.Checkbox>
                )}
                <Input
                  name="resolution_deadline"
                  label="Prazo de Resolução"
                  type="date"
                />
                <Select
                  name="origin"
                  label="Origem"
                  options={origin_column}
                  required
                />
                <Select
                  name="status"
                  label="Status"
                  options={status_column}
                  onChange={(e) => setIsFinished(e.value === 'Finalizado')}
                  defaultValue={{ value: 'Pendente', label: 'Pendente' }}
                  required
                />

                {isFinished && (
                  <Input
                    name="finished_reason"
                    label="Razão da finalização"
                    required
                  />
                )}
                <input
                  type="file"
                  onChange={(e) => verifyFile(e, setFiles)}
                  multiple
                />
                <Button type="submit" icon="plus">
                  Criar Ticket
                </Button>
              </Form>
            </S.FormWrapper>
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default CreateTickets;
