import React, { useEffect, useState, useRef } from 'react';
import { toDateTime } from '~/Helpers/Date';
import { Select, Input } from '~/components/NexAdmin/Inputs/index';
import { Button } from '~/components/NexAdmin/Buttons/index';
import Table from '~/components/NexAdmin/Table';
import * as S from './styles';
import { Form } from '@unform/web';
import { parseJwt } from '~/services/auth';
import api from '~/services/api';
import columns from './columns';
import Counter from '~/components/NexAdmin/Counter';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { appendObjectInFormData } from '~/utils';
import Download from '../Sales/Components/Download';
import DownloadHook from '~/hooks/NexAdmin/DownloadHook';
import Loading from '~/components/Loading';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import moment from 'moment';
import { showNotification } from '~/utils/notificationBoxes';
import RevenueDescription from '~/components/NexAdmin/Modal/Descriptions/revenuaDescription';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';

const Revenue = () => {
  const [loading, setLoading] = useState(false);
  const [sells, setSells] = useState([]);
  const [count, setCount] = useState(0);
  const current_user = parseJwt();
  const formRef = useRef();
  const { openModal } = useModal();
  useEffect(() => {
    // getSells();
  }, []);

  const getSells = (data) => {
    setLoading(true);

    if (!data.mes) {
      data.mes = 'all';
    }
    if (!data.ano) {
      data.ano = new Date().getFullYear();
    }
    if (!data.status) {
      data.status = 'all';
    }

    const form = new FormData();
    appendObjectInFormData(form, data);
    const params = new URLSearchParams(form);

    api
      .get(`/receita/${parseJwt().canal_id}?${params}`, {
        ...data,
        usu_nivel: parseJwt().usu_nivel,
        usu_regional: parseJwt().usu_regional,
      })
      .then((response) => {
        setLoading(false);
        setSells(response.data.vendas);
        setCount(response.data.count);
      })
      .catch(() => {
        setLoading(false);
        return showNotification(
          'danger',
          'Atenção',
          'Não foi possível buscas vendas, tente novamente mais tarde.',
        );
      });
  };

  const handleDownload = () => {
    const data = formRef.current.getData();
    const form = new FormData();
    appendObjectInFormData(form, {
      download: true,
      ...data,
    });
    const params = new URLSearchParams(form);

    return api.get(`/receita/${parseJwt().canal_id}?${params}`, {
      responseType: 'blob',
    });
  };

  const buildReportName = (name) => {
    return `Relatório ${name} - Canal ${localStorage.getItem(
      'canalNome',
    )} - ${toDateTime(new Date())}`;
  };

  const beforeLoading = () => setLoading(true);

  const afterLoading = () => setLoading(false);

  const { execute, ref, urlFile, nameFile } = DownloadHook({
    externalCall: handleDownload,
    onError: HTTPErrorCallback,
    getFileName: buildReportName('Receita'),
    beforeLoading,
    afterLoading,
  });

  const openThermsModal = () => {
    openModal({
      title: 'Receita',
      close: true,
      content: <RevenueDescription />,
    });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="filedUser"
          title="Pesquisar Usuários"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <Form ref={formRef} onSubmit={getSells}>
              <S.FormWrapper>
                <S.FormFilters>
                  <Input
                    label="De:"
                    name="dt_ini"
                    type="date"
                    defaultValue={moment(new Date())
                      .subtract(1, 'month')
                      .format('YYYY-MM-DD')}
                  />
                  <Input
                    label="Até:"
                    name="dt_fim"
                    type="date"
                    defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                  />
                  <Select
                    label="Ano:"
                    name="ano"
                    options={[
                      { value: '2020', label: '2020' },
                      { value: '2021', label: '2021' },
                      { value: 'all', label: 'Todos' },
                    ]}
                  />
                  <Select
                    label="Mês:"
                    name="mes"
                    options={[
                      { value: '01', label: 'Janeiro' },
                      { value: '02', label: 'Fevereiro' },
                      { value: '03', label: 'Março' },
                      { value: '04', label: 'Abril' },
                      { value: '05', label: 'Maio' },
                      { value: '06', label: 'Junho' },
                      { value: '07', label: 'Julho' },
                      { value: '08', label: 'Agosto' },
                      { value: '09', label: 'Setembro' },
                      { value: '10', label: 'Outubro' },
                      { value: '11', label: 'Novembro' },
                      { value: '12', label: 'Dezembro' },
                      { value: 'all', label: 'Todos' },
                    ]}
                  />
                  <Input label="CPF:" name="cpf" type="text" />
                  <Input label="Código Venda:" name="id" type="text" />
                  <Select
                    label="Ponto Status:"
                    name="status"
                    options={[
                      { value: 'all', label: 'Todos' },
                      { value: 'Aprovado', label: 'Aprovado' },
                      { value: 'Pendente', label: 'Pendente' },
                      { value: 'Reprovado', label: 'Reprovado' },
                    ]}
                  />
                </S.FormFilters>
                <Button type="submit">Consultar</Button>
              </S.FormWrapper>
              <S.DownloadWrapper>
                <a href={urlFile} download={nameFile} ref={ref} />
                <Download buttonName="Download" external={execute} />
              </S.DownloadWrapper>
            </Form>
            <Counter total={count} />
            {sells.length > 0 && (
              <Table
                data={sells}
                columns={columns}
                loading={loading}
                dataTableProps={{
                  pagination: true,
                }}
                header
                dropdownItems={false}
                hasDownload={false}
              />
            )}
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default Revenue;
