import styled from 'styled-components';

export const Container = styled.div`
  grid-area: banner;
  background-color: #000;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  display: flex;
  position: relative;
  overflow: hidden;

  .rec-slider-container {
    margin: 0px !important;
  }
  .rec-item-wrapper {
    height: 100% !important;
    max-height: 440px;
  }
  .rec-pagination {
    position: absolute;
    bottom: 10px;
  }
  .rec-dot {
    border: 1px solid white !important;
    box-shadow: 0 0 1px 2px rgb(255 255 255 / 10%) !important;
  }
  .rec-dot_active {
    background-color: white !important;
  }
  a {
    width: 100%;
  }
  img {
    object-fit: fill;
    width: 100%;
    height: 100%;
  }
`;
