import styled from 'styled-components';

export const Title = styled.div`
  margin-bottom: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.oldColors.gray[50]};
  width: 100%;
  p {
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    font-size: 1.8rem;
    font-weight: 700;
    font-style: normal;
  }
`;

export const Spaced = styled.div`
  margin-top: 1.5rem; ;
`;

export const Card = styled.div`
  border: 1px solid #f4f4f4;
  box-shadow: 0 0 1.5em #48484880;
  border-radius: 20px;
  padding-top: 20px;
`;

export const InfoTitle = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.adminfontsize.containertitle};
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  padding-top: 0.5rem;
  margin-left: 1.5rem;
`;

export const InfoData = styled.div`
  display: flex;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  font-size: ${({ theme }) => theme.adminfontsize.containertab};
  font-weight: 500;
`;
