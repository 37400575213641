const RecargaColumnsDesc = () => {
  return (
    <>
      <br />
      <strong>1. Código Venda:</strong> Código ID da venda. Valor único em toda
      a tabela.
      <br />
      <strong>2. Código Serviço:</strong> Código interno da INOVA.
      <br />
      <strong>3. Data da Venda:</strong> Data que a venda foi efetuada.
      <br />
      <strong>4. CPF/CNPJ:</strong> CPF/CNPJ do usuário.
      <br />
      <strong>5. Nome Vendedor:</strong> Nome do usuário.
      <br />
      <strong>6. Cargo:</strong> Cargo do usuário.
      <br />
      <strong>7. Regional:</strong> Regional do usuário.
      <br />
      <strong>8. PDV:</strong> Ponto de Venda do usuário..
      <br />
      <strong>9. DDD:</strong> DDD do usuário.
      <br />
      <strong>10. Cidade:</strong> Cidade de residência do usuário.
      <br />
      <strong>11. Cod Cid IBGE:</strong> Código da cidade de residência do
      usuário no IBGE.
      <br />
      <strong>12. Plano:</strong> Plano da venda, se existente.
      <br />
      <strong>13. Plano Tipo:</strong> Tipo do plano da venda, se existente.
      <br />
      <strong>14. Tipo Serviço:</strong> Tipo do serviço, se existente.
      <br />
      <strong>15. Tipo Fidelidade:</strong> Tipo de fidelidade, se existente.
      <br />
      <strong>16. ICCID:</strong> Código interno da INOVA.
      <br />
      <strong>17. Status:</strong> Status da venda fornecido pela INOVA.
      <br />
      <strong>18. Quantidade:</strong> Quantidade de produtos da venda.
      <br />
      <strong>19. Pontos Vendas:</strong> Pontos GigaClube gerado pela venda
      computado com base no status da venda e regra de negócio.
      <br />
      <strong>20. Pontos Bônus:</strong> Pontos bônus GigaClube gerado pela
      venda, gerado com base nas regras de negócio.
    </>
  );
};

export default RecargaColumnsDesc;
