import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { Form } from '@unform/web';
import * as S from '../styles';
import {
  Select,
  Input,
  ToggleSwitch,
} from '~/components/NexAdmin/Inputs/index';
import { Button } from '~/components/NexAdmin/Buttons/index';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parseJwt } from '~/services/auth';
import getIcon from '~/Helpers/getIcon';
import { showNotification } from '~/utils/notificationBoxes';
import { appendObjectInFormData } from '~/utils';
import moment from 'moment/moment';

const BannerForm = ({ row, setLoading, externalCall, step = 'create' }) => {
  const [banners, setBanners] = useState({
    small: {
      file: null,
      image: null,
    },
    medium: {
      file: null,
      image: null,
    },
  });
  const [net, setNet] = useState([]);
  const [regional, setRegional] = useState([]);
  const [role, setRole] = useState([]);
  const [digitalFilter, setDigitalFilter] = useState([]);
  const [active, setActive] = useState(row?.dest_ativo ?? 0);
  const [order, setOrder] = useState();
  const formRef = useRef(null);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    getFilters();
  }, []);
  const getFilters = () => {
    setLoading(true);
    api
      .get(`/banners/filters/${parseJwt().canal_id}`)
      .then((response) => {
        const { net, regional, role, digital } = response.data;
        const default_label = {
          label: 'Todos',
          value: '*',
        };
        const net_filters = net.map((item) => {
          return {
            label: item.usu_rede,
            value: item.usu_rede,
          };
        });
        const regional_filters = regional.map((item) => {
          return {
            label: item.usu_regional,
            value: item.usu_regional,
          };
        });
        const role_filters = role.map((item) => {
          return {
            label: item.cargo_nome,
            value: item.cargo_id,
          };
        });
        const digital_filters = digital.map((item) => {
          return {
            label: item.canal_label,
            value: item.digital_canal_id,
          };
        });
        net_filters.unshift(default_label);
        regional_filters.unshift(default_label);
        role_filters.unshift({
          label: 'Todos',
          value: 0,
        });
        setNet(net_filters);
        setRegional(regional_filters);
        setRole(role_filters);
        setDigitalFilter(digital_filters);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });

    api
      .get(`/banners/order/${parseJwt().canal_id}`)
      .then((response) => {
        const { order } = response.data;
        setOrder(order);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
    setLoading(false);
  };
  const handleSubmit = (data) => {
    const { small, medium } = banners;
    if (step === 'create' && !medium.file) {
      return showNotification(
        'warning',
        'Campos Obrigatórios!',
        'Por favor, selecione a imagem do desktop.',
      );
    }
    setLoading(true);
    const formData = new FormData();
    appendObjectInFormData(formData, {
      small: small.file,
      medium: medium.file,
      channel_id: parseJwt().canal_id,
      status: active,
      ...data,
    });
    externalCall(formData)
      .then(() => {
        showNotification('success', 'Sucesso!', 'Banner criado!');
        setLoading(false);
      })
      .then(() => {
        const rout = location.pathname.split('/');
        history.push(rout.slice(0, rout.length - 1).join('/'));
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  const handleUpdate = (data) => {
    const { small, medium } = banners;
    setLoading(true);
    const formData = new FormData();
    appendObjectInFormData(formData, {
      small: small.file,
      medium: medium.file,
      channel_id: parseJwt().canal_id,
      status: active,
      ...data,
    });
    externalCall(formData)
      .then(() => {
        showNotification('success', 'Sucesso!', 'Banner atualizado!');
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };
  const verifyFile = (event, size) => {
    const file = event.target.files[0];
    if (!file) return;
    if (file.size > 15000000)
      return showNotification(
        'warning',
        'Atenção!',
        'Insira um arquivo menor que 15MB!',
      );
    const reader = new FileReader();
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = (imageEvent) => {
        const image_path = imageEvent.composedPath()[0];
        if (image_path.height > 440) {
          return showNotification(
            'warning',
            'Atenção!',
            'A altura máxima do arquivo deve ser de até 440px',
          );
        }
        setBanners((prev) => {
          return {
            ...prev,
            [size]: { file, image: e.target.result },
          };
        });
      };
    };
    reader.readAsDataURL(file);
  };
  return (
    <>
      <Form
        ref={formRef}
        onSubmit={step === 'create' ? handleSubmit : handleUpdate}
      >
        <S.FormWrapper>
          <S.FormFilters>
            <Input
              name="desc"
              label="Descrição:"
              type="text"
              large
              defaultValue={row?.dest_desc}
            />
            {order && (
              <Input
                name="order"
                label="Ordem:"
                type="number"
                large
                defaultValue={step !== 'create' ? row?.dest_ord : order}
              />
            )}
            <ToggleSwitch
              name="status"
              label="Ativo?"
              defaultChecked={active ?? false}
              value={active}
              onClick={() => (active ? setActive(0) : setActive(1))}
            />
            <Input
              name="initial_date"
              label="Data Início:"
              type="date"
              large
              defaultValue={moment(new Date(row?.dest_dt_ini)).format(
                'YYYY-MM-DD',
              )}
            />
            <Input
              name="final_date"
              label="Data Fim:"
              type="date"
              large
              defaultValue={moment(new Date(row?.dest_dt_fim)).format(
                'YYYY-MM-DD',
              )}
            />
            {regional.length && (
              <Select
                name="regional"
                options={regional}
                label="Regional:"
                large
                defaultValue={
                  step !== 'create'
                    ? regional.find((item) => item.value === row.regional)
                    : null
                }
              />
            )}
            {net.length && (
              <Select
                name="net"
                options={net}
                label="Rede:"
                large
                defaultValue={
                  step !== 'create'
                    ? net.find((item) => item.value === row.rede)
                    : null
                }
              />
            )}
            {role.length && (
              <Select
                name="role"
                options={role}
                label="Cargo:"
                large
                defaultValue={
                  step !== 'create'
                    ? role.find((item) => item.value === row.cargo_id)
                    : null
                }
              />
            )}
            {parseJwt().canal_id === 7 && digitalFilter.length ? (
              <Select
                name="sub_channel"
                options={digitalFilter}
                label="Canal Digital"
                large
                isMulti={step === 'create' ? true : false}
                defaultValue={
                  step !== 'create'
                    ? digitalFilter.find(
                        (item) => item.label === row.canal_label,
                      )
                    : null
                }
              />
            ) : (
              ' '
            )}
          </S.FormFilters>
        </S.FormWrapper>
        <S.FormUpload>
          <S.BannerWrapper
            image={
              banners.medium.image ? banners.medium.image : row?.dest_imagem
            }
          >
            <S.BannerTitle>Desktop</S.BannerTitle>
            <S.UploadButton htmlFor="desktop_banner">
              {getIcon('upload')}
              <S.UploadButtonText>UPLOAD</S.UploadButtonText>
            </S.UploadButton>
            <input
              name="desktop_banner"
              type="file"
              accept="image/*"
              id="desktop_banner"
              onChange={(e) => verifyFile(e, 'medium')}
            />
          </S.BannerWrapper>
          <S.BannerWrapper
            image={
              banners.small.image
                ? banners.small.image
                : row?.dest_imagem_mobile
            }
          >
            <S.BannerTitle>Mobile</S.BannerTitle>
            <S.UploadButton htmlFor="mobile_banner">
              {getIcon('upload')}
              <S.UploadButtonText>UPLOAD</S.UploadButtonText>
            </S.UploadButton>
            <input
              name="mobile_banner"
              type="file"
              accept="image/*"
              id="mobile_banner"
              onChange={(e) => verifyFile(e, 'small')}
            />
          </S.BannerWrapper>
        </S.FormUpload>
        <S.ButtonWrapper>
          <Button type="submit">
            {step === 'create' ? 'Criar' : 'Atualizar'}
          </Button>
        </S.ButtonWrapper>
      </Form>
    </>
  );
};

export default BannerForm;
