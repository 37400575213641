const na = 'N/A';

export const usersColumn = [
  {
    name: 'Nome',
    selector: (row) => row.name ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
  },
  {
    name: 'CPF',
    selector: (row) => row.cpf ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
  },
  {
    name: 'Treinamento',
    selector: (row) => row.quiz_name ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
  },
  {
    name: 'Progresso atual',
    selector: (row) => row.progress ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
  },
  {
    name: 'Status',
    selector: (row) => row.finalized ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
  },
  {
    name: 'Total de perguntas',
    selector: (row) => row.questions_amount ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
  },
  {
    name: 'Respostas corretas',
    selector: (row) => row.correctly_answers_amount ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
  },
  {
    name: 'Aproveitamento em %',
    selector: (row) => row.user_progress ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
  },
  {
    name: 'Iniciado em',
    selector: (row) => row.initiated_at ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
  },
  {
    name: 'Atualizado em',
    selector: (row) => row.updated_at ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
  },
];

export const quizColumn = [
  {
    name: 'Título',
    selector: (row) => row.title ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => <div data-tag="allowRowEvents">{row.title ?? na}</div>,
  },
  {
    name: 'Descrição',
    selector: (row) => row.description ?? na,
    sortable: true,
    grow: 1,
    maxWidth: '1000px',
  },
  {
    name: 'Pontos',
    selector: (row) => row.points ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
  },
  {
    name: 'Porcentagem de acerto necessária',
    selector: (row) => row.points_percentage ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
  },
  {
    name: 'Cargo',
    selector: (row) => row.role ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => <div data-tag="allowRowEvents">{row.role ?? na}</div>,
  },
  {
    name: 'Regional',
    selector: (row) => row.regional ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => <div data-tag="allowRowEvents">{row.regional ?? na}</div>,
  },
  {
    name: 'Rede',
    selector: (row) => row.net ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => <div data-tag="allowRowEvents">{row.net ?? na}</div>,
  },
  {
    name: 'PDV',
    selector: (row) => row.pdv ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => <div data-tag="allowRowEvents">{row.pdv ?? na}</div>,
  },

  {
    name: 'Material',
    selector: (row) => row.file ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.file ? (
          <a href={row.file} target="_blank" rel="noreferrer">
            Ver mais
          </a>
        ) : (
          na
        )}
      </div>
    ),
  },
  {
    name: 'Link Video',
    selector: (row) => row.video_url ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.video_url ? (
          <a href={row.video_url} target="_blank" rel="noreferrer">
            Ver mais
          </a>
        ) : (
          na
        )}
      </div>
    ),
  },
  {
    name: 'Status',
    selector: (row) => (row.active ? 'Ativo' : 'Inativo'),
    sortable: true,
    grow: 1,
    minWidth: '150px',
  },
  {
    name: 'Atualizado em',
    selector: (row) => row.updated_at ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
  },
];
