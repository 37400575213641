import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import { Form } from '@unform/web';
import Button from '~/components/NexAdmin/Buttons/Button';
import * as S from './styles';
import { useEffect, useState } from 'react';
import { appendObjectInFormData } from '~/utils';
import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { showNotification } from '~/utils/notificationBoxes';
import Loading from '~/components/Loading';
import { Input, InputUpload, Select } from '~/components/NexAdmin/Inputs/index';
import { useHistory } from 'react-router-dom';
import route from '~/routes/Components/NexAdmin/routes';

const Create = () => {
  const [loading, setLoading] = useState(false);
  const [regulation, setRegulation] = useState(null);
  const [role, setRole] = useState([]);
  const [products, setProducts] = useState([]);
  const [regional, setRegional] = useState([]);
  const [net, setNet] = useState([]);
  const [pdv, setPdv] = useState([]);
  const [regulationProducts, setRegulationProducts] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api
      .get(`/campaign-exhibiton/admin/get-products`)
      .then((response) => {
        const { product, role, regional, net, pdv, regulation_products } =
          response.data;
        const base = { label: 'TODOS', value: '*' };
        setProducts(product);
        setRegional([base, ...regional]);
        setRole([base, ...role]);
        setNet([base, ...net]);
        setPdv([base, ...pdv]);
        setRegulationProducts([base, ...regulation_products]);
        setLoading(false);
      })
      .catch((error) => {
        return HTTPErrorCallback(error);
      });
  };

  const submit = (data) => {
    setLoading(true);
    const formData = new FormData();
    appendObjectInFormData(formData, {
      regulation_file: regulation,
      ...data,
    });
    api
      .post('/campaign-exhibiton/admin/create', formData)
      .then(() => {
        showNotification(
          'success',
          'Sucesso!',
          'Exibição de campanha criada com sucesso!',
        );
        history.push(route.CampaignsExhibitionList.path);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag icon="add" title="carrossel de Mecânicas" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle title="Criar exibição de campanha">
            <Form onSubmit={submit}>
              <S.Wrapper>
                <S.InputWrapper>
                  <Input label="Nome da Campanha" name="name" required />
                  <Input
                    label="Descrição"
                    name="description"
                    required
                    textArea
                  />
                  <Select
                    isMulti
                    name="roles"
                    options={role}
                    label="Cargos"
                    large
                    placeholder="Selecione os cargos"
                  />
                  <Select
                    isMulti
                    name="products"
                    options={products}
                    label="Produtos:"
                    placeholder="Selecione os produtos"
                    large
                    maxWidth="400px"
                  />
                  <Select
                    isMulti
                    name="regulation_products"
                    options={regulationProducts}
                    label="Produtos Regulamento:"
                    placeholder="Selecione os produtos"
                    large
                    maxWidth="400px"
                  />
                </S.InputWrapper>
                <S.InputWrapper>
                  <Select
                    isMulti
                    name="regional"
                    options={regional}
                    label="Regional:"
                    placeholder="Selecione a regional"
                    large
                    maxWidth="400px"
                  />
                  <Select
                    isMulti
                    name="net"
                    options={net}
                    label="Rede:"
                    placeholder="Selecione a rede"
                    large
                    maxWidth="400px"
                  />
                  {pdv && (
                    <Select
                      isMulti
                      name="pdv"
                      options={pdv}
                      label="PDV:"
                      placeholder="Selecione o pdv"
                      large
                      maxWidth="400px"
                    />
                  )}
                  <h3>Período da Campanha</h3>
                  <Input label="De:" name="initial_date" type="date" required />
                  <Input label="Até:" name="final_date" type="date" required />
                  <InputUpload
                    name="arquivo"
                    mt="10px"
                    onGetFile={setRegulation}
                    placeholder="Selecione um regulamento"
                    accept="application/pdf"
                  />
                </S.InputWrapper>
                <div />
                <Button type="submit" width="30%">
                  Criar
                </Button>
              </S.Wrapper>
            </Form>
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default Create;
