import { createGlobalStyle } from 'styled-components';
import media from 'styled-media-query';

const appHeight = () =>
  document.documentElement.style.setProperty(
    '--app-height',
    `${window.innerHeight}px`,
  );
window.addEventListener('resize', appHeight);
appHeight();

const GlobalStyle = createGlobalStyle`
.version {
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 50%;
  transform: translateX(50%);
  padding: 7px 10px;
  opacity: 0.1;
  pointer-events: none;
  user-select: none;
  font-size: 9px;
  font-style: italic;
  color: white;
}

::selection {
background: ${({ theme }) => theme.oldColors.primary[50]};
color: white;
}
input:placeholder-shown {
  text-overflow: ellipsis;
}
.mobile {
  width: 100%;
  height: auto;
  image-rendering: optimizeQuality;
  max-width: 100%;
  display: inherit;
  
  ${media.greaterThan('medium')`
    display: none;
    `}

}
    html {

        box-sizing: border-box;
        font-size: ${({ theme }) => theme?.adminfontsize?.global};
        font-family: ${({ theme }) => theme?.adminfontfamily};
        background-color: ${(props) => props.theme?.oldColors?.gray[40]};
        color: ${({ theme }) => theme.admincolors?.neutral?.neutral10};
        overflow-y: scroll;
        overflow-y: overlay;
        button {
          cursor: pointer;
        }
        img {
          image-rendering: smooth;
        }
        img, button { 
            -webkit-user-drag: none; 
            -khtml-user-drag: none; 
            -moz-user-drag: none; 
            -o-user-drag: none; 
            user-drag: none; 
            user-select: none;
            background-color: transparent;
            border: none;
            
        }
        *, *::before, *::after {
            box-sizing: inherit;
            padding: 0;
            margin: 0;
            font-family: ${({ theme }) => theme?.adminfontfamily};
        
        }
        * {

        ::-webkit-scrollbar {
            width: 5px;
            height: 5px; 
            
           
            }

            /* Track */
            ::-webkit-scrollbar-track {
                background: ${({ theme }) => theme?.adminscrollbar?.bg};

  
            }

            /* Handle */
            ::-webkit-scrollbar-thumb {
            background: ${({ theme }) => theme?.adminscrollbar?.bar?.color};
            border-radius: 20px;
            }

            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
                background: ${({ theme }) => theme?.adminscrollbar?.bar?.color};
            }
        }
    }

    /* NOTIFICATIONS */

    .notification__item {
      border-left: none;
      border-radius: 12px;
      padding: 0px 14px 14px 7px;
    }
    .notification__title {
      //styleName: H6-desktop;

      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      word-break: break-all

    }

    /* ANIMATIONS */

    @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(1);
      margin-top: 20px;
    }
    to {
      opacity: 1;
      transform: scale(1);
      margin-top: 0px;
    }
  }
    @keyframes fadeInRight {
    from {
      opacity: 0;
      
      transform: translateX(-5px);
    }
    to {
      opacity: 1;

      transform: translateX(0px);
    }
  }

  // text tags
  h1 {
    font-size: ${({ theme }) => theme.adminfontsize?.h1};

    ${media.lessThan('1766px')`
      font-size: ${({ theme }) => theme.adminfontsize?.desktop.h1};
    `}

    ${media.lessThan('medium')`
      font-size: ${({ theme }) => theme.adminfontsize?.mobile.h1};
    `}
  }
  h2 {
    font-size: ${({ theme }) => theme.adminfontsize?.h2};

    ${media.lessThan('1766px')`
      font-size: ${({ theme }) => theme.adminfontsize?.desktop.h2};
    `}

    ${media.lessThan('medium')`
      font-size: ${({ theme }) => theme.adminfontsize?.mobile.h2};
    `}
  }
  h3 {
    font-size: ${({ theme }) => theme.adminfontsize?.h3};

    ${media.lessThan('1766px')`
      font-size: ${({ theme }) => theme.adminfontsize?.desktop.h3};
    `}

    ${media.lessThan('medium')`
      font-size: ${({ theme }) => theme.adminfontsize?.mobile.h3};
    `}
  }
  h4 {
    font-size: ${({ theme }) => theme.adminfontsize?.h4};

    ${media.lessThan('1766px')`
      font-size: ${({ theme }) => theme.adminfontsize?.desktop.h4};
    `}

    ${media.lessThan('medium')`
      font-size: ${({ theme }) => theme.adminfontsize?.mobile.h4};
    `}
  }
  h5 {
    font-size: ${({ theme }) => theme.adminfontsize?.h5};

    ${media.lessThan('1766px')`
      font-size: ${({ theme }) => theme.adminfontsize?.desktop.h5};
    `}

    ${media.lessThan('medium')`
      font-size: ${({ theme }) => theme.adminfontsize?.mobile.h5};
    `}
  }
  h6 {
    font-size: ${({ theme }) => theme.adminfontsize?.h6};

    ${media.lessThan('1766px')`
      font-size: ${({ theme }) => theme.adminfontsize?.desktop.h6};
    `}

    ${media.lessThan('medium')`
      font-size: ${({ theme }) => theme.adminfontsize?.mobile.h6};
    `}
  }
  p {
    font-size: ${({ theme }) => theme.adminfontsize?.p};
    font-weight: ${({ bold }) => bold && 'bold'};

    ${media.lessThan('1766px')`
      font-size: ${({ theme }) => theme.adminfontsize?.desktop.p};
    `}

    ${media.lessThan('medium')`
      font-size: ${({ theme }) => theme.adminfontsize?.mobile.p};
    `}
  }
`;

export default GlobalStyle;
