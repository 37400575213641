import styled from 'styled-components';
import media from 'styled-media-query';

export const FormWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  form {
    width: 100%;
    max-width: 500px;

    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;
