/* eslint-disable no-console */
import { useEffect, useState } from 'react';

import api from '~/services/api';
import { goTopPage } from '~/utils';

import { parseJwt } from '../../services/auth';
import CreateCampaign from './components/CreateCampaign/index';
import HeaderButtons from './components/HeaderButtons/index';
import ShowCampaigns from './components/ShowCampaigns/index';
import * as S from './styles';
import NewCreateCampaign from './components/NewComponents/CreateCampagn';
import NewShowCampagns from './components/NewComponents/ShowCampaigns/index';

const TablePoints = () => {
  const [points, setPoints] = useState(0);
  const [view, setView] = useState('create');

  useEffect(() => {
    goTopPage();
    api.get(`/lad/default/user/balance`).then((response) => {
      setPoints(response.data);
    });
  }, []);

  return (
    <S.Template>
      <S.Wrapper points="true">
        <S.Points>
          <span>Você possui</span>
          <input type="text" disabled value={points} />
          <span>Para distribuir</span>
        </S.Points>
      </S.Wrapper>
      <>
        <S.Wrapper rules>
          <S.Rules>
            <h3>Algumas regras para distribuição:</h3>
            <div className="rules-grid">
              <span className="rules-number">1.</span>
              <p>
                A quantidade de pontos liberado para cada Lider de Setor
                Desabitado (LAD) será calculada com base nos critérios citados
                no item 3.5 do regulamento.
              </p>
            </div>
            <div className="rules-grid">
              <span className="rules-number">2.</span>
              <p>
                O Lider de Setor Desabitado (LAD) deve escolher o PDV no menu
                abaixo e em seguida definir o vendedor que deseja vincular a uma
                campanha. Lembrando que para participar é necessário que tanto o
                Lider de setor quanto o vendedor tenham dado aceite no Giga
                Clube conforme descrito no item 3.5 do regulamento.
              </p>
            </div>
          </S.Rules>
        </S.Wrapper>
        <S.Wrapper filters>
          <HeaderButtons view={view} setView={setView} />
          <S.Inner>
            {view === 'create' ? <NewCreateCampaign /> : <NewShowCampagns />}
          </S.Inner>
        </S.Wrapper>
      </>
    </S.Template>
  );
};

export default TablePoints;
