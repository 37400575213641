import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.admincolors.contrast.white};
  margin-top: ${(props) => (props.mt ? props.mt : '30px')};
`;

export const Tab = styled.button`
  place-self: end;
  font-size: ${({ theme }) => theme.adminfontsize.containertab};
  line-height: ${({ theme }) => theme.lineheight.containertab};
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 0px 11px;
  background-color: ${(props) =>
    props.active ? props.theme.admincolors.contrast.white : 'transparent'};
  color: ${({ theme }) => theme.admincolors.contrast.black};
  border: none;
  padding: 14px 20px 14px 20px;
  transition: ${({ theme }) => theme.transition};
  box-shadow: ${(props) => props.active && '0px 0px 5px rgba(0, 0, 0, 0.2)'};
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.active
        ? props.theme.admincolors.contrast.white
        : props.theme.oldColors.primary[30]};
  }
`;

export const Header = styled.div`
  overflow: hidden;
  padding: 14px 30px 0px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(0, auto));
  background-color: ${({ theme }) => theme.oldColors.primary[40]};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;
export const Content = styled.div`
  padding: 20px 23px;
  overflow: hidden;
`;
