import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const Section = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(133, 133, 133, 0.3);
`;

export const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
    `}
`;

export const Row = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 20px;

  ${media.lessThan('medium')`
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    `}
`;

export const GoBack = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-end;
  align-items: center;
  color: #c50101;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
`;

export const Title = styled.h2`
  color: #c50101;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 700;
`;

export const Duration = styled.h3`
  font-size: 14px;
  font-family: Poppins;
  font-weight: 700;
  color: #484848;
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  color: ${({ isAvailable }) => (isAvailable ? '#00C136' : '#C50101')};
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;

  border-radius: 50%;
  background: ${({ isAvailable }) => (isAvailable ? '#00C136' : '#C50101')};
`;

export const Subtitle = styled.h3`
  color: #c50101;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 700;
`;

export const Text = styled.p`
  color: #484848;
  font-size: 14px;
  font-family: Poppins;
`;

export const Button = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const PhotoRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  img,
  video {
    width: 124px;
    border-radius: 10px;
  }

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;

    img, video {
      width: 100%;
    }
    `}
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;

  color: #484848;
  font-size: 14px;
  font-family: Poppins;

  ${media.lessThan('medium')`
    width: 100%;
    `}
`;

export const PhotoStatus = styled.div`
  color: ${({ status }) =>
    status === 'pending'
      ? '#484848'
      : status === 'approved'
      ? '#00C136'
      : '#C50101'};
  font-size: 14px;
  font-family: Poppins;
`;

export const Alert = styled.div`
  width: 100%;

  display: flex;
  padding: 20px;
  align-items: center;
  gap: 8px;

  border-radius: 10px;
  background: #c4c4c4;

  color: #484848;
  font-size: 14px;
  font-family: Poppins;

  ${media.lessThan('medium')`
    svg {
      width: 60px;
    }`}
`;

export const Champion = styled.div`
  width: 200px;
  height: 200px;

  background: url(${({ src }) => src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  border-radius: 10px;

  transition: all 0.2s ease-in-out;

  position: relative;

  :hover {
    cursor: pointer;
    box-shadow: inset 0 0 30px black;
  }

  :after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.1s ease-in-out;
  }

  :hover:after {
    content: '${({ user }) => user}';
    text-align: center;
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;

    transition: all 0.1s ease-in-out;
  }
`;
