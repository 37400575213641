import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px;
  gap: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(133, 133, 133, 0.3);
  ${media.lessThan('medium')`
        position: relative;
        top: 100px;
        width: calc(100vw - 30px);
        height: calc(100vh - 30px);
  `}
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
  height: fit-content;
  &.opened {
    visibility: visible;
  }

  &.closed {
    overflow: hidden;
    height: 0;
    visibility: hidden;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.colors.red};
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.grayDarker};
  text-align: center;
  font-size: 24px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Content = styled.div`
  width: fit-content;
  color: ${({ theme }) => theme.colors.grayDarker};
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const AnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  width: 100%;
  p {
    color: ${({ theme }) => theme.colors.grayLighter};
    text-align: center;
    font-size: 24px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const AnswerContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: ${({ theme }) => `2px solid ${theme.colors.grayLighter}`};
  cursor: pointer;
  ${({ anwser, correctly }) =>
    anwser
      ? correctly
        ? css`
            background: #00c136;
          `
        : css`
            background: #c50101;
          `
      : ''}
`;

export const AnswerContentLetter = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 90px;
  border: ${({ theme }) => `2px solid ${theme.colors.grayLighter}`};
  background: ${({ theme }) => theme.colors.grayLighter};
  color: ${({ theme }) => theme.colors.grayDarker};
  ${({ anwser, correctly }) =>
    anwser
      ? correctly
        ? css`
            border: 2px solid #ffff;
            background: #ffff;
            color: #00c136;
          `
        : css`
            border: 2px solid #ffff;
            background: #ffff;
            color: #c50101;
          `
      : ''}
`;

export const AnswerContent = styled.div`
  color: ${({ theme, anwser }) =>
    anwser ? theme.colors.white : theme.colors.grayDarker};
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
