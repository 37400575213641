import React from 'react';

const Duplicate = ({ color }) => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66699 4V16H17.667V18H2.66699C0.666992 18 0.666992 16 0.666992 16V4H2.66699ZM19.967 0H6.36699C5.42699 0 4.66699 0.7 4.66699 1.55V12.45C4.66699 13.31 5.42699 14 6.36699 14H19.967C20.907 14 21.667 13.31 21.667 12.45V1.55C21.667 0.7 20.907 0 19.967 0ZM6.66699 2H11.667V8H6.66699V2ZM19.667 12H6.66699V10H19.667V12ZM19.667 8H13.667V6H19.667V8ZM19.667 4H13.667V2H19.667V4Z"
        fill={color}
      />
    </svg>
  );
};
export default Duplicate;
