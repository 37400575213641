import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  height: fit-content;
  min-height: 100px;
  padding: 10px;
`;

export const BellWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Bell = styled.div`
  position: relative;
`;

export const BellBall = styled.div`
  color: ${({ theme }) => theme.colors.red};
  background: ${({ theme }) => theme.colors.degrade};
  position: absolute;
  right: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
`;
export const ContentTitle = styled.span`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.grayDarker};
  text-align: left;
`;

export const ContentInteractionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;
export const ContentInteractionInfo = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.red};
`;
