import React from 'react';

const Send = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7823 0.217495C17.681 0.11675 17.5532 0.0469978 17.4137 0.0164399C17.2742 -0.014118 17.1288 -0.00421136 16.9948 0.0449951L0.494756 6.045C0.352458 6.09897 0.229946 6.19496 0.143495 6.32021C0.0570431 6.44546 0.0107422 6.59405 0.0107422 6.74624C0.0107422 6.89844 0.0570431 7.04703 0.143495 7.17228C0.229946 7.29753 0.352458 7.39352 0.494756 7.44749L6.93726 10.02L11.6923 5.25L12.7498 6.30749L7.97226 11.085L10.5523 17.5275C10.6078 17.667 10.704 17.7867 10.8284 17.8709C10.9528 17.9552 11.0995 18.0001 11.2498 18C11.4013 17.9969 11.5484 17.9479 11.6716 17.8596C11.7947 17.7712 11.8882 17.6476 11.9398 17.505L17.9398 1.005C17.9909 0.872302 18.0032 0.727818 17.9753 0.588388C17.9474 0.448958 17.8805 0.320328 17.7823 0.217495Z"
        fill={color}
      />
    </svg>
  );
};
export default Send;
