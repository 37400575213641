import { useEffect, useState } from 'react';
import { showNotification } from '~/utils/notificationBoxes';
import { TiCamera } from 'react-icons/ti';
import * as S from './styles';

const ProfileImage = ({ src, isEdit, onChangeImage }) => {
  const [image, setImage] = useState(src);

  useEffect(() => {
    setImage(src);
  }, [src]);

  const verifyImage = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.size > 15000000)
      return showNotification(
        'warning',
        'Atenção!',
        'Insira um arquivo menor que 15MB!',
      );

    const reader = new FileReader();

    reader.onload = (e) => {
      setImage(e.target.result);
    };
    onChangeImage(file);
    setImage(reader.readAsDataURL(file));
  };

  return (
    <>
      <S.Wrapper>
        <div>
          <button type="button">
            <TiCamera size={30} />
          </button>
          <input
            type="file"
            onChange={verifyImage}
            accept="image/x-png,image/jpeg"
          />
          {image && <img alt="profile-preview" src={image} />}
        </div>
      </S.Wrapper>
    </>
  );
};

export default ProfileImage;
