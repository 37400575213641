import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.grayDarker};
  height: 100%;
  ${media.greaterThan('medium')`
    padding: 0 0px;
  `};
`;

export const Commentary = styled.div``;

export const Post = styled.article`
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  padding: 30px 0 15px;

  > .comentary {
    font-size: 1.4rem;

    padding: 10px 16px;
    ${media.greaterThan('medium')`
      padding: 0 20px 20px;
    `};
  }
  header {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    ${media.greaterThan('medium')`
      padding: 0 20px 20px;
    `};

    figure {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 17px;

      img {
        width: 60px;
        height: 60px;
      }
    }

    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.grayDarker};

      &:hover {
        color: ${({ theme }) => theme.colors.red};
      }
    }

    h1 {
      font-size: 2rem;
    }

    p {
      font-size: 1.1rem;
    }
  }
`;

export const BannerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const Banner = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.greaterThan('medium')`
    margin-bottom: 28px;
  `};

  /* audio {
    width: 80%;
    height: 80%;
  } */

  video {
    width: auto;
    max-width: 100%;
    height: 100%;
    max-height: 500px;
  }

  img {
    width: auto;
    max-width: 100%;
    height: 100%;
    max-height: 500px;
  }
`;

export const FormWrapper = styled.div``;

export const FormReActions = styled.ul`
  list-style: none;
  display: flex;
  padding: 10px 16px;
  button {
    background-color: transparent;
  }
  ${media.greaterThan('medium')`
    padding: 0 20px 20px;
  `};

  li {
    margin-right: 15px;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.grayDarker};

    &:nth-child(1) {
      svg {
        fill: #e40707;
      }
    }
    &:nth-child(3) {
      svg {
        fill: #fcc400;
      }
    }

    svg {
      margin-right: 4px;
    }

    & + li {
      margin-left: 12px;
    }
  }
`;
