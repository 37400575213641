import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import Table from '~/components/NexAdmin/Table';
import { toDateTime } from '~/Helpers/Date';
import columns from './columns';
import { useEffect, useState } from 'react';
import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import Actions from './Components/Actions';
import Filters from './Components/Filters';
import { appendObjectInFormData } from '~/utils';

const LandingPages = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    getLandingPages();
  }, []);

  const getLandingPages = (data) => {
    setLoading(true);
    const form = new FormData();
    appendObjectInFormData(form, {
      ...data,
    });
    const params = new URLSearchParams(form);
    api
      .get(`/landing-page/admin/?${params}`)
      .then((response) => {
        setItems(response.data);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const deleteItem = (id) => {
    setLoading(true);
    api
      .delete(`/landing-page/${id}`)
      .then((response) => {
        setItems(response.data);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const restoreItem = (id) => {
    setLoading(true);
    api
      .put(`/landing-page/restore/${id}`)
      .then((response) => {
        setItems(response.data);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <>
      <HeaderWrapper>
        <StatusTag icon="duplicate" title="Landing Pages" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <Table
              data={items}
              columns={[
                ...columns.columns,
                {
                  name: 'Ações',
                  selector: (row) => row.deleted_at,
                  cell: (row) => (
                    <Actions
                      row={row}
                      refresh={getLandingPages}
                      setLoading={setLoading}
                    />
                  ),
                },
              ]}
              loading={loading}
              header
              customHeader={
                <Filters
                  getLandingPages={getLandingPages}
                  setLoading={setLoading}
                />
              }
              dataTableProps={{ pagination: true }}
              dropdownItems={false}
              hasDownload={false}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default LandingPages;
