const options = {
  all: [
    { label: 'Todos', value: 'all' },
    { label: 'Aprovado', value: '1' },
    { label: 'Pendente', value: '2' },
    { label: 'Reprovado', value: '3' },
  ],
  recarga: [
    { label: 'Todos', value: 'all' },
    { label: 'Aprovado', value: 'APROVADO' },
    { label: 'Processado', value: 'PROCESSADO' },
  ],
};

export default options;
