import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  font-size: ${({ theme }) => theme.adminfontsize.confirmbutton};
  line-height: ${({ theme }) => theme.lineheight.confirmbutton};
  z-index: ${(props) => props.open && '1'};
  transition: ${({ theme }) => theme.transition};
  background-color: ${(props) =>
    props.open ? props.theme.oldColors.gray[10] : '#ffffff00'};
  &:hover {
    background-color: ${({ theme }) => theme.oldColors.gray[10]};
  }
  padding: 3px;
  padding-bottom: 1px;
  padding-top: 4px;
  padding-right: 3.5px;
  border-radius: 100%;
`;
export const Button = styled.button`
  svg {
    max-width: 16px;
    max-height: 14px;
  }
`;
export const Bg = styled.div``;

export const ConfirmWrapper = styled.div`
  position: absolute;
  top: -5px;
  animation: fadein 0.2s;
  transform: ${(props) =>
    props.isOpen ? 'translateY(calc(0%))' : 'translateY(calc(-100%))'};
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-template-rows: 1fr;
  place-items: center;
  white-space: nowrap;
  right: ${(props) => (props.openOnRight ? '' : props.isOpen ? '32px' : '0px')};
  left: ${(props) =>
    props.openOnRight ? (props.isOpen ? '32px' : '0px') : ''};
  padding: 8px;
  height: 31px;
  grid-gap: 10px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.admincolors.contrast.black};
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    ${(props) =>
      props.openOnRight
        ? css`
            border-right: 8px solid
              ${({ theme }) => theme.admincolors.contrast.black};
          `
        : css`
            border-left: 8px solid
              ${({ theme }) => theme.admincolors.contrast.black};
          `}
    bottom: 7px;
    animation: fadeinarrow 0.1s 0.05s forwards;
    right: ${(props) => (props.openOnRight ? '' : '10px')};
    left: ${(props) => (props.openOnRight ? '-8px' : '')};
  }

  @keyframes fadein {
    from {
      opacity: 0;
      margin-right: -10px;
    }
    to {
      opacity: 1;
      margin-right: 0px;
    }
  }
  @keyframes fadeinarrow {
    from {
      opacity: 0;
      right: 10px;
      bottom: 7px;
    }
    to {
      opacity: 1;
      right: -8px;
      bottom: 7px;
    }
  }
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.admincolors.contrast.white};
  font-weight: 400;
  width: auto;
`;
export const ConfirmButton = styled.button`
  color: ${(props) =>
    props.green
      ? props.theme.oldColors.primary[50]
      : props.theme.admincolors.feedback.danger30};
  font-weight: 700;

  font-size: ${({ theme }) => theme.adminfontsize.confirmbutton};
  line-height: ${({ theme }) => theme.lineheight.confirmbutton};
`;
export const CancelButton = styled.button`
  color: ${({ theme }) => theme.oldColors.gray[60]};
  font-size: ${({ theme }) => theme.adminfontsize.confirmbutton};
  line-height: ${({ theme }) => theme.lineheight.confirmbutton};
`;
