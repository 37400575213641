import { useEffect, useState } from 'react';
import Table from '~/components/NexAdmin/Table';
import { HTTPErrorCallback } from '~/Helpers/Error';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import { columns } from '../columns';
import Button from '~/components/NexAdmin/Buttons/Button';
import Loading from '~/components/Loading';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import { appendObjectInFormData } from '~/utils';
import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import { ConfirmButton } from '~/components/NexAdmin/Buttons';
import { status, status_admin_update, user_response } from '../options';
import { origin_column } from '../columns';
import UpdateForm from './Components/Update/index';
import { showNotification } from '~/utils/notificationBoxes';
import { Select, Input } from '~/components/NexAdmin/Inputs';
import * as S from './styles';
import { Form } from '@unform/web';
import Counter from '~/components/NexAdmin/Counter';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import ListDescriptions from '~/components/NexAdmin/Modal/Descriptions/Tickets/listDescriptions';
import EditDescription from '~/components/NexAdmin/Modal/Descriptions/Tickets/editDescription';

const Tickets = () => {
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [count, setCount] = useState(0);
  const [topic, setTopic] = useState([]);
  const [files, setFiles] = useState([]);
  const { openModal } = useModal();
  const current_user = parseJwt();
  useEffect(() => {
    getTickets();
    getOpenReason();
  }, []);

  const getOpenReason = () => {
    api
      .get('/tickets/topics-admin')
      .then((response) => setTopic(response.data));
  };
  const getTickets = (data) => {
    setLoading(true);
    const formData = new FormData();
    appendObjectInFormData(formData, {
      admin_id: current_user.id,
      ...data,
    });
    const params = new URLSearchParams(formData);
    api
      .get(`/tickets-admin/?${params}`)
      .then((response) => {
        setLoading(false);
        setCount(response.data.total);
        setTickets(response.data.tickets);
      })
      .catch((err) => {
        setLoading(false);
        return HTTPErrorCallback(err);
      });
  };

  const updateTicket = (id, data) => {
    setLoading(true);
    api
      .put(`/tickets/update/${id}`, data)
      .then(() => {
        setLoading(false);
        showNotification('success', 'Ticket atualizado com sucesso!');
        getTickets();
        setFiles([]);
      })
      .catch((err) => {
        setLoading(false);
        setFiles([]);
        return HTTPErrorCallback(err);
      });
  };
  const openThermsModal = () => {
    openModal({
      title: selectedTicket
        ? 'Tratamento de ocorrências'
        : 'Listagem de ocorrências',
      close: true,
      content: selectedTicket ? <EditDescription /> : <ListDescriptions />,
    });
  };
  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="monitor"
          title="Ocorrências"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            {selectedTicket ? (
              <UpdateForm
                ticket={selectedTicket}
                updateTicket={updateTicket}
                files={files}
                setFiles={setFiles}
                setUserSelected={setSelectedTicket}
                loading={loading}
                setLoading={setLoading}
              />
            ) : (
              <>
                <Form onSubmit={getTickets}>
                  <S.FormWrapper>
                    <Input name="id" label="Ticket ID" type="number" />
                    <Input name="initial_date" label="De" type="date" />
                    <Input name="final_date" label="Até" type="date" />
                    <Input
                      name="resolution_deadline"
                      label="Data de Resolução"
                      type="date"
                    />
                    <Input name="cpf" label="CPF/CNPJ" type="number" />
                    <Select name="status" options={status} label="Status:" />
                    <Select
                      name="status_admin_update"
                      label="Status SAC"
                      options={status_admin_update}
                    />
                    <Select
                      name="has_response"
                      label="Com resposta do usuário"
                      options={user_response}
                    />
                    {topic && (
                      <Select
                        name="open_reason"
                        label="Razão de abertura"
                        options={[{ label: 'Todos', value: '*' }, ...topic]}
                      />
                    )}
                    <Select
                      name="origin"
                      label="Origem"
                      options={[
                        { label: 'Todos', value: '*' },
                        ...origin_column,
                      ]}
                    />
                  </S.FormWrapper>
                  <S.ButtonWrapper>
                    <Button type="submit" icon="plus">
                      Pesquisar
                    </Button>
                  </S.ButtonWrapper>
                </Form>
                <Counter total={count} />
                <Table
                  data={tickets}
                  columns={[
                    {
                      name: 'Ações',
                      cell: (row) => (
                        <S.Actions>
                          {row.status === 'Finalizado' && (
                            <ConfirmButton
                              icon={'check'}
                              label="TICKET FINALIZADO"
                              iconColor="gray"
                              openOnRight={true}
                            />
                          )}
                          {row.files?.length > 0 && (
                            <ConfirmButton
                              icon={'bv'}
                              label="TICKET POSSUI ARQUIVOS"
                              openOnRight={true}
                            />
                          )}
                          {row.has_response !== 0 && (
                            <ConfirmButton
                              icon={'user'}
                              iconColor="blue"
                              label="NOVA MENSAGEM DO USUÁRIO"
                              openOnRight={true}
                            />
                          )}
                          <ConfirmButton
                            icon={'statusSketch'}
                            iconColor="green"
                            label="EDITAR"
                            openOnRight={true}
                            onClick={() => {
                              setSelectedTicket(row);
                            }}
                          />
                        </S.Actions>
                      ),
                    },
                    ...columns,
                  ]}
                  loading={loading}
                  dataTableProps={{
                    pagination: true,
                  }}
                  header
                  dropdownItems={false}
                  hasDownload={false}
                />
              </>
            )}
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default Tickets;
