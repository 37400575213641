import { useEffect, useState } from 'react';
import { FaArrowCircleUp as Seta } from 'react-icons/fa';
import { parseJwt } from '~/services/auth';
import { goTopPage } from '~/utils';
import Gigaline from '../Gigaline';
import AboutCampaign from './components/AboutCampaign';
import Banner from './components/Banner';
import DigitalCampaigns from './components/DigitalCampaigns';
import Extract from './components/Extract';
import Indicate from './components/Indicate';
import PopUp from './components/PopUp';
import TablePoints from './components/TablePoints';
import Wishlist from './components/Wishlist';
import * as S from './styles';
import Campaigns from './components/Campaigns';
import Quiz from './components/Quiz';
import MediaCampaigns from './components/MediaCampaigns';

const Dashboard = () => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    goTopPage();
  }, []);

  return (
    <>
      <S.Container>
        <Quiz />
        <Campaigns />
        {parseJwt().canal_id === 7 ? <DigitalCampaigns /> : ' '}
        <Banner />
        <Gigaline id={1} />
        <Indicate />
        {parseJwt().canal_id !== 7 || parseJwt().id === 1025252 ? (
          <Wishlist />
        ) : (
          ' '
        )}
        {parseJwt().canal_id !== 8 ? <Extract /> : ' '}

        <MediaCampaigns />

        <AboutCampaign />

        {parseJwt().canal_id !== 7 && parseJwt().canal_id !== 8 ? (
          <TablePoints />
        ) : (
          ' '
        )}
        <S.ScrollUp scrolled={scrolled} onClick={goTopPage}>
          <Seta className="seta" size="40" border="white" color="#C50101" />
        </S.ScrollUp>
      </S.Container>
    </>
  );
};

export default Dashboard;
