import React from 'react';

const NotificationBell = ({ color = '#C50101' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#F2F2F2" />
      <path
        d="M18 29H22C22 30.1 21.1 31 20 31C18.9 31 18 30.1 18 29ZM29 27V28H11V27L13 25V19C13 15.9 15 13.2 18 12.3V12C18 10.9 18.9 10 20 10C21.1 10 22 10.9 22 12V12.3C25 13.2 27 15.9 27 19V25L29 27ZM25 19C25 16.2 22.8 14 20 14C17.2 14 15 16.2 15 19V26H25V19Z"
        fill={color}
      />
    </svg>
  );
};
export default NotificationBell;
