import styled from 'styled-components';

export const PushFormWrapper = styled.div`
  form {
    display: flex;
    flex-direction: column;
    max-width: 780px;
    align-items: center;
    margin: 0 auto;
    padding-bottom: 20px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  form {
    width: 100%;
    display: grid;
    align-items: end;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.7fr;
    gap: 20px;
  }
`;

export const InformText = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  font-size: ${({ theme }) => theme.adminfontsize.statustag.title};
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  p {
    display: flex;
    justify-content: center;
    white-space: normal;
    text-overflow: inherit;
    overflow-x: hidden;
    width: 100%;
  }
`;

export const ButtonWithLoading = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

export const Loading = styled.div`
  border: 8px solid white;
  border-radius: 50%;
  border-top: 8px solid ${({ theme }) => theme.admincolors.contrast.black};
  width: 24px;
  height: 24px;
  filter: drop-shadow(0 0 4px #00000030);
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  transform: translate(-50%, -50%);
`;
