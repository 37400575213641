import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  border-radius: 36px;
  border: 1px solid ${({ theme }) => theme.colors.grayDarker};
  padding: 0 16px;
  width: 100%;
  color: #666360;
  display: flex;
  align-items: center;
  & + div {
    margin-top: 8px;
  }

  &:parent {
    margin-top: 8px;
    background: #000;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
      svg {
        margin-right: 0;
      }
    `}
  ${(props) =>
    props.isFocused &&
    css`
      color: #121421;
      border-color: #121421;
    `}
  ${(props) =>
    props.isFilled &&
    css`
      color: #121421;
    `}
  input {
    flex: 1;
    background: transparent;
    border: none;
    color: var(--primary);
    line-height: 4.3rem;
    width: 100%;
    padding: 0px 7px;
    &::placeholder {
      color: #666360;
    }
  }

  textarea {
    margin: 0 auto;
    width: 100%;
    height: 300px;
    padding: 5px 12px;
    border-radius: 26px;
    resize: none;
  }

  svg {
    margin-right: 16px;
    width: 1.5em;
    height: 1.5em;
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.grayDarker};
  font-size: 1.4rem;
  margin-bottom: 5px;
`;
