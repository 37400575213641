import moment from 'moment';

const columns = [
  {
    name: 'Id',
    selector: (row) => row.id,
    cell: (row) => <div title={row.id}>{row.id}</div>,
    sortable: true,
  },
  {
    name: 'Nome',
    selector: (row) => row.rede,
    cell: (row) => <div title={row.rede}>{row.rede}</div>,
    sortable: true,
  },
  {
    name: 'Data de Criação',
    selector: (row) => row.created_at,
    cell: (row) => (
      <div title={row.created_at}>
        {moment(row.created_at).format('DD/MM/YYYY HH:mm')}
      </div>
    ),
    sortable: true,
  },
  {
    name: 'Data de Atualização',
    selector: (row) => row.updated_at,
    cell: (row) => (
      <div title={row.updated_at}>
        {row.updated_at === row.created_at
          ? 'N/A'
          : moment(row.updated_at).format('DD/MM/YYYY HH:mm')}
      </div>
    ),
    sortable: true,
  },
  {
    name: 'Ultima Atualização por:',
    selector: (row) => row.usu_nome,
    cell: (row) => <div title={row.usu_nome}>{row.usu_nome || 'N/A'}</div>,
    sortable: true,
  },
];

export default columns;
