import { useHistory } from 'react-router-dom';

import * as S from './styles';

const Indicate = () => {
  const history = useHistory();
  return (
    <S.Container>
      <S.Header>
        <S.Title>Meu perfil</S.Title>
      </S.Header>
      <S.Paragraph>
        <strong>
          Aqui você pode visualizar todas as informações da sua conta!
        </strong>
        <br />
        <br />
        Aproveite para consultar as suas conquistas, a sua pontuação, atualizar
        os seus dados (nome, cargo, senha), e muito mais!
      </S.Paragraph>
      <S.Button onClick={() => history.push('/meu-perfil')} active>
        Ver mais
      </S.Button>
    </S.Container>
  );
};

export default Indicate;
