import React from 'react';

const Bv = ({ color }) => {
  return (
    <svg
      width="45"
      height="46"
      viewBox="0 0 45 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.65 22.1L23.4 1.85C22.5 0.95 21.375 0.5 20.25 0.5H4.5C2.025 0.5 0 2.525 0 5V20.75C0 21.875 0.45 23 1.35 23.9L21.6 44.15C22.5 45.05 23.625 45.5 24.75 45.5C25.875 45.5 27 45.05 27.9 44.15L43.65 28.4C44.55 27.5 45 26.375 45 25.25C45 24.125 44.55 23 43.65 22.1ZM24.75 41L4.5 20.75V5H20.25L40.5 25.25L24.75 41ZM10.125 7.25C11.925 7.25 13.5 8.825 13.5 10.625C13.5 12.425 11.925 14 10.125 14C8.325 14 6.75 12.425 6.75 10.625C6.75 8.825 8.325 7.25 10.125 7.25ZM18.225 16.025L21.375 12.875L33.75 25.25L30.6 28.4L18.225 16.025ZM12.6 21.65L15.75 18.5L24.75 27.5L21.6 30.65L12.6 21.65Z"
        fill={color}
      />
    </svg>
  );
};
export default Bv;
