import { Button } from '~/components/NexAdmin/Buttons';

const Download = ({ buttonName = 'Download', external }) => {
  return (
    <Button
      model="secondary"
      width="200px"
      onClick={() => external()}
      type="button"
    >
      {buttonName}
    </Button>
  );
};

export default Download;
