import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import { useState, useRef } from 'react';
import api from '~/services/api';
import * as S from '../../Sales/styles';
import { parseJwt } from '~/services/auth';
import Loading from '~/components/Loading';
import Table from '~/components/NexAdmin/Table';
import Counter from '~/components/NexAdmin/Counter';
import { Input } from '~/components/NexAdmin/Inputs/index';
import { Button } from '~/components/NexAdmin/Buttons/index';
import { Form } from '@unform/web';
import { appendObjectInFormData } from '~/utils';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { toDateTime } from '~/Helpers/Date';
import Download from '../../Sales/Components/Download';
import DownloadHook from '~/hooks/NexAdmin/DownloadHook';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import { blockedOrdersColumns } from '../columns';
import BlockedOrdersReportDescription from '~/components/NexAdmin/Modal/Descriptions/blockedOrdersReportDescription';

const BlockedOrdersReport = () => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState(0);
  const current_user = parseJwt();
  const formRef = useRef();
  const { openModal } = useModal();

  const handleSubmit = (data) => {
    setLoading(true);
    const form = new FormData();
    appendObjectInFormData(form, {
      channel_id: current_user.canal_id,
      user_level: current_user.usu_nivel,
      user_regional: current_user.usu_regional,
      ...data,
    });
    const params = new URLSearchParams(form);
    api
      .get(`/orders-report/${current_user.canal_id}/?${params}`)
      .then((response) => {
        const { count, orders_found } = response.data;
        setOrders(orders_found);
        setCount(count);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };
  const handleDownload = () => {
    const data = formRef.current.getData();
    const form = new FormData();
    appendObjectInFormData(form, {
      channel_id: current_user.canal_id,
      user_level: current_user.usu_nivel,
      user_regional: current_user.usu_regional,
      download: true,
      ...data,
    });
    const params = new URLSearchParams(form);
    return api
      .get(`/orders-report/${current_user.canal_id}/?${params}`, {
        responseType: 'blob',
      })
      .catch((error) => {
        HTTPErrorCallback(error);
      });
  };

  const buildReportName = (name) => {
    return `Relatório ${name} - Canal ${localStorage.getItem(
      'canalNome',
    )} - ${toDateTime(new Date())}`;
  };

  const beforeLoading = () => setLoading(true);

  const afterLoading = () => setLoading(false);

  const { execute, ref, urlFile, nameFile } = DownloadHook({
    externalCall: handleDownload,
    onError: HTTPErrorCallback,
    getFileName: buildReportName('Pedidos'),
    beforeLoading,
    afterLoading,
  });

  const openThermsModal = () => {
    openModal({
      title: 'Pedidos Bloqueados',
      close: true,
      content: <BlockedOrdersReportDescription />,
    });
  };
  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="statusArchived"
          title="Pedidos"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <S.FormWrapper>
                <S.FormFilters>
                  <Input name="user_cpf" label="CPF:" type="number" />
                  <Input
                    label="Código do Pedido:"
                    name="order_id"
                    type="number"
                  />
                </S.FormFilters>
                <Button type="submit">Consultar</Button>
              </S.FormWrapper>
              <S.DownloadWrapper>
                <a href={urlFile} download={nameFile} ref={ref} />
                <Download buttonName="Download" external={execute} />
              </S.DownloadWrapper>
            </Form>
            <Counter total={count} />
            <Table
              data={orders}
              columns={blockedOrdersColumns}
              pagination={true}
              header
              dropdownItems={false}
              hasDownload={false}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default BlockedOrdersReport;
