import { useState } from 'react';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';
import Loading from '~/components/Loading';
import { InputUpload } from '~/components/NexAdmin/Inputs';
import * as S from './styles';
import { Form } from '@unform/web';
import Button from '~/components/NexAdmin/Buttons/Button';
import getIcon from '~/Helpers/getIcon';
import DownloadStaticFile from '~/components/NexAdmin/DownloadStaticFile';
import {
  model_columns,
  model_data,
  model_columns_download,
  model_columns_download_pc,
  model_columns_pc,
  model_data_pc,
  model_data_sales,
  model_columns_sales,
  model_columns_download_sales,
} from './columns';
import { showNotification } from '~/utils/notificationBoxes';
import { HTTPErrorCallback } from '~/Helpers/Error';
import UseAuthentication from '~/hooks/NexAdmin/AuthenticationHook';
import InsertTable from '../Components/InsertTable';

const CreateUser = () => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [tableLoad, setTableLoad] = useState(false);
  const { api, parseJwt } = UseAuthentication();
  const [channel] = useState({
    channel: parseJwt.canal_id,
    name:
      parseJwt.canal_id === 2
        ? 'Varejo'
        : parseJwt.canal_id === 8
        ? 'PAP Indireto'
        : 'Pap Premium/Condomínio',
    channel_route_path:
      parseJwt.canal_id === 2
        ? 'sales'
        : parseJwt.canal_id === 8
        ? 'pap-engine'
        : 'pap-premium-condominio-engine',
    columns:
      parseJwt.canal_id === 2
        ? model_columns_sales
        : parseJwt.canal_id === 8
        ? model_columns
        : model_columns_pc,
    downloadColumns:
      parseJwt.canal_id === 2
        ? model_columns_download_sales
        : parseJwt.canal_id === 8
        ? model_columns_download
        : model_columns_download_pc,
    modelColumns:
      parseJwt.canal_id === 2
        ? model_data_sales
        : parseJwt.canal_id === 8
        ? model_data
        : model_data_pc,
  });

  const validate = () => {
    setErrors([]);
    setData([]);

    if (!file)
      return showNotification(
        'warning',
        'Atenção',
        'Por favor, selecione um arquivo',
      );

    setLoading(true);

    const formData = new FormData();
    formData.append('sheet', file);
    formData.append('file_type', 'usuario');
    api
      .post(`/${channel.channel_route_path}/validate`, formData)
      .then((response) => {
        const data = response.data.data_to_insert || response.data.data || [];
        const errors = response.data.errors || [];

        if (Array.isArray(data) && data.length > 0) {
          setData(data);
        }
        if (Array.isArray(errors) && errors.length > 0) {
          setErrors(errors);
        }
        setTableLoad(true);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const save = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('sheet', file);
    formData.append('file_type', 'usuario');

    const uploadPath =
      channel.channel === 2
        ? `${channel.channel_route_path}/save`
        : `${channel.channel_route_path}/upload`;

    api
      .post(uploadPath, formData)
      .then(() => {
        setLoading(false);
        showNotification(
          'success',
          'Sucesso',
          'Planilha de Usuários enviada com sucesso! Os dados serão processados no próximo dia.',
        );
        setTableLoad(false);
        setData([]);
        setErrors([]);
        setFile(null);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const process = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('sheet', file);
    formData.append('file_type', 'usuario');

    api
      .post(`/${channel.channel_route_path}/`, formData)
      .then(() => {
        setLoading(false);
        showNotification(
          'success',
          'Sucesso',
          'Processamento realizado com sucesso!',
        );
        setTableLoad(false);
        setData([]);
        setErrors([]);
        setFile(null);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="actions"
          title={`Inserção e Atualização de Cadastro de Usuários - ${channel.name}`}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <S.Wrapper>
              <h2>Selecione um arquivo para validação</h2>

              <S.InputWrapper>
                <div className="itens">
                  <Form>
                    <InputUpload
                      name="file"
                      onGetFile={(e) => {
                        setData([]);
                        setErrors([]);
                        setFile(e);
                      }}
                      placeholder={
                        <>
                          {getIcon('upload')} Selecione um arquivo para upload
                        </>
                      }
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  </Form>
                  <Button type="button" width="50%" onClick={validate}>
                    Validar
                  </Button>
                </div>
              </S.InputWrapper>

              {tableLoad && (
                <>
                  {errors.length > 0 && (
                    <InsertTable
                      data={errors}
                      model_columns={[
                        ...channel.columns,
                        {
                          name: 'Erro',
                          selector: (row) => row.error,
                          cell: (row) => (
                            <div title={row.error}>{row.error}</div>
                          ),
                          sortable: true,
                        },
                      ]}
                      model_columns_download={[
                        ...channel.downloadColumns,
                        {
                          name: 'Erro',
                          selector: (row) => row.error,
                          cell: (row) => (
                            <div title={row.error}>{row.error}</div>
                          ),
                          sortable: true,
                        },
                      ]}
                      type="error"
                    />
                  )}
                  {data.length > 0 && (
                    <>
                      <InsertTable
                        data={data}
                        model_columns={channel.columns}
                        model_columns_download={channel.downloadColumns}
                      />
                      <Button type="button" width="50%" onClick={save}>
                        Confirmar Agendamento
                      </Button>
                      <Button type="button" width="50%" onClick={process}>
                        Processar
                      </Button>
                    </>
                  )}
                </>
              )}
            </S.Wrapper>

            <DownloadStaticFile
              columns={channel.columns}
              list={channel.modelColumns}
              file_name={`Planilha Modelo de Inserção/Atualização de usuários - ${channel.name}`}
              button_name={'Download Modelo'}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default CreateUser;
