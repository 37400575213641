import styled from 'styled-components';

export const Container = styled.div`
  overflow-x: auto;
  max-width: 100vw;
  table {
    table {
      border: none;
      max-width: 100%;
    }

    display: table;
    .order-td {
      display: flex;
      flex-direction: row;
      border-radius: 30px;
      white-space: nowrap;
      justify-content: center;
      gap: 20px;
      width: 100% !important;
      max-width: 100% !important;

      width: 100%;
      &.proccessing {
        background: #f2f2f2;
      }
      &.estornado {
        background: #ffe8e8;
      }
      &.confirmado {
        background: rgba(0, 193, 54, 0.1);
      }
    }
    .order-title {
      font-weight: 700;
    }
    td {
      display: table-cell;
      vertical-align: middle;
      margin: 10px 0px;
      padding: 10px 10px;
      max-width: 150px;
      font-size: 1.3rem;
      &.reproved {
        color: #c50101;
      }
      &.aproved {
        color: #00c136;
      }
      &.pendent {
        color: #eab000;
      }
    }

    /* background-color: #ccc; */
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.grayDarker};

    thead {
      font-weight: 700;
      font-size: 1.6rem;
      border-radius: 30px;
      overflow: hidden;
      td {
        &:first-child {
          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;
        }
        &:last-child {
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
        }
        padding: 10px 20px;
      }
      button {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.red};
        font-weight: 700;
      }
      &.points-thead {
        border: none;
        background-color: #f2f2f2;
        color: ${({ theme }) => theme.colors.red};
      }
    }
    svg {
      fill: ${({ theme }) => theme.colors.red};
    }
  }
`;
