import React from 'react';
import { useHistory } from 'react-router-dom';

import RescueTable from '~/screens/Extract/components/RescueTable';

import * as S from './styles';

const Extract = () => {
  const history = useHistory();
  return (
    <S.Container>
      <S.Header>
        <S.Title>Meus resgates</S.Title>
      </S.Header>
      <RescueTable />
      <S.MoreButtonWrapper>
        <S.MoreButton onClick={() => history.push('/extrato')}>
          Ver mais
        </S.MoreButton>
      </S.MoreButtonWrapper>
    </S.Container>
  );
};

export default Extract;
