import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cep from 'cep-promise';
import Button from '~/components/Button';
import Checkbox from '~/components/Checkbox';
import Input from '~/components/Input';
import Loading from '~/components/Loading';
import api from '~/services/api';
import {
  cellphoneMask,
  numberOnlyMask,
  validateAge,
  getAllStates,
  getStateCities,
  cepMask,
  validateBirthDate,
  cpfcnpjMask,
  appendObjectInFormData,
} from '~/utils';
import { showNotification } from '~/utils/notificationBoxes';
import * as S from './styles';
import { HTTPErrorCallback } from '~/Helpers/Error';
import RegulationModal from './Components/RegulationModal';
import ProfileImage from './Components/ProfileImage';
import { validate } from 'react-email-validator';
import { parseJwt } from '../../services/auth';
import regulationLinks from '../Regulations/regulationLinks';

const Optin = () => {
  const history = useHistory();
  const { usu_id, temp_token, usu_cargo } = history.location;
  const formRef = useRef(null);
  const [formDefaultData, setFormDefaultData] = useState({});
  const [showRegulationModal, setShowRegulationModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cepValue, setCepValue] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [profileImageFile, setProfileImageFile] = useState('');
  const [token] = useState(temp_token);

  useEffect(() => {
    if (!usu_id || !temp_token) return history.goBack();
    getUser();
  }, []);

  useEffect(() => {
    if (cepValue.length >= 8) {
      cep(cepValue)
        .then((data) => {
          setState(data.state);
          setCity(data.city);

          formRef.current.setData({
            logradouro: data.street,
            bairro: data.neighborhood,
            cidade: data.city,
          });
        })
        .catch((error) =>
          showNotification('danger', 'Atenção', 'CEP Inválido!'),
        );
    }
  }, [cepValue]);

  const useRegulationLink = () => {
    const jwtData = parseJwt();
    const canalId = jwtData.canal_id || localStorage.getItem('channelId');
    const cargo = jwtData.cargo;
    let link = null;

    switch (canalId) {
      case '2':
      case 2:
        link = [75, 79, 85].includes(cargo)
          ? regulationLinks.varejo.bko
          : regulationLinks.varejo.default;
        break;
      case '4':
      case 4:
        link = regulationLinks.agenteAutorizado.default;
        break;
      case '5':
      case 5:
        link = regulationLinks.recarga.default;
        break;
      case '6':
      case 6:
        link = regulationLinks.aparelhos.default;
        break;
      case '8':
      case 8:
        link = [76, 75, 91].includes(cargo)
          ? regulationLinks.pap.bko
          : regulationLinks.pap.default;
        break;
      case '9':
      case 9:
        link = [93, 94].includes(cargo)
          ? regulationLinks.pc.premium
          : [95, 98, 99].includes(cargo)
          ? regulationLinks.pc.condominio
          : [100, 102, 103].includes(cargo)
          ? regulationLinks.pc.condominioBko
          : [101, 105].includes(cargo)
          ? regulationLinks.pc.premiumBko
          : null;
        break;
      default:
        break;
    }

    return link;
  };

  const getUser = () => {
    api
      .get(`/optin/${token}`)
      .then((response) => {
        const { channel_id, ...rest } = response.data;

        // Armazenando channel_id no localStorage
        localStorage.setItem('channelId', channel_id);

        // Atualizando o estado com os dados do usuário
        setFormDefaultData({
          ...rest,
          usu_cpf: cpfcnpjMask(rest.usu_cpf),
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
        return history.goBack();
      });
  };

  const handleSubmit = (data) => {
    setLoading(true);

    if (!validate(data.email)) {
      setLoading(false);
      return showNotification(
        'danger',
        'Atenção',
        'Por favor, insira um endereço de e-mail válido.',
      );
    }

    const formData = new FormData();

    appendObjectInFormData(formData, {
      ...data,
      cidade: city,
      estado: state,
      channel_id: formDefaultData.channel_id,
      usu_foto: profileImageFile,
    });
    api
      .post(`/optin/${token}`, formData)
      .then(() => {
        setLoading(false);
        showNotification('success', 'Sucesso!', 'Dados alterados!');

        localStorage.setItem('userToken', token);
        localStorage.setItem('canal', formDefaultData.channel_id);
        window.location.href = '/dashboard';
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  const checkboxOptions = [
    {
      id: 'terms',
      value: 'terms',
      label: 'Declaro que li e aceito o regulamento da Campanha.',
    },
  ];

  const checkboxOptions2 = [
    {
      id: 'terms',
      value: 'terms',
      label: 'Declaro que li e aceito o regulamento da Campanha do Catálogo.',
    },
  ];

  const hoverOnTooltip = () => {
    document.getElementById('tooltip-text').style.visibility = 'visible';
    document.getElementById('tooltip-text').style.opacity = '1';
  };

  const hoverOutTooltip = () => {
    const toolTipText = document.getElementById('tooltip-text');
    toolTipText.style.visibility = 'hidden';
    toolTipText.style.opacity = '0';
  };

  const clickOnTooltip = () => {
    setClickedOnTooltip(!clickedOnTooltip);
    const visibility = clickedOnTooltip ? 'visible' : 'hidden';
    const opacity = clickedOnTooltip ? '1' : '0';
    document.getElementById('tooltip-text').style.visibility = visibility;
    document.getElementById('tooltip-text').style.opacity = opacity;
  };

  const checkAge = (date) => {
    if (!validateAge(date)) {
      formRef.current.setData({ dataNascimento: '' });
      return showNotification(
        'danger',
        'Atenção',
        'É necessário ter mais de 18 anos para continuar.',
      );
    }

    if (!validateBirthDate(date)) {
      formRef.current.setData({ dataNascimento: '' });
      return showNotification(
        'danger',
        'Atenção',
        'O campo de nascimento parece inválido.',
      );
    }

    return true;
  };

  const regulationLink = useRegulationLink();

  return (
    <>
      {loading && <Loading />}
      {showRegulationModal && (
        <RegulationModal
          regulationUrl={regulationLink}
          setShowRegulationModal={setShowRegulationModal}
          showRegulationModal={showRegulationModal}
        />
      )}
      <S.Wrapper>
        <S.Logo>
          <img src="/images/logo-signin.png" alt="" />
        </S.Logo>

        <S.Content>
          <S.Info>
            <p>
              Complete seu cadastro para acessar a campanha. Preencha
              corretamente seus
              <br />
              dados pois este será o endereço de entrega dos seus pedidos.
            </p>
            <p>
              Os dados poderão ser alterados depois via central de atendimento
              da campanha.
            </p>
          </S.Info>
          <S.FormWrapper>
            <S.Form ref={formRef} onSubmit={handleSubmit}>
              <S.CollForm>
                <p>
                  Olá,
                  {formDefaultData.usu_nome}
                </p>
                <ProfileImage
                  disabled={false}
                  src={profileImageFile}
                  onChangeImage={(file) => setProfileImageFile(file)}
                />
                <Input
                  name="usu_cpf"
                  label="CPF/CNPJ"
                  value={formDefaultData.usu_cpf}
                  disabled
                />
                <Input
                  name="dataNascimento"
                  label="Data de nascimento"
                  type="date"
                  required
                  onBlur={(e) => checkAge(e.target.value)}
                />
                <Input
                  name="celular"
                  label="Celular"
                  required
                  onChange={(e) =>
                    formRef.current.setData({
                      celular: cellphoneMask(e.target.value),
                    })
                  }
                />
                <Input name="email" label="E-mail" required type="email" />
              </S.CollForm>
              <S.CollForm>
                <div className="cep">
                  <Input
                    name="cep"
                    label="CEP"
                    required
                    onChange={(e) => {
                      setCepValue(e.target.value);
                      formRef.current.setData({
                        cep: cepMask(e.target.value),
                      });
                      if (state || city) {
                        setCepValue('');
                        setCity('');
                        setState('');
                        formRef.current.setData({
                          logradouro: '',
                          bairro: '',
                          complemento: '',
                          numero: '',
                        });
                      }
                    }}
                    maxLength={8}
                  />
                </div>
                <Input name="logradouro" label="Endereço" required />
                <div className="inline-wrapper">
                  <div className="inline">
                    <Input
                      name="numero"
                      label="Número"
                      required
                      onChange={(e) =>
                        formRef.current.setData({
                          numero: numberOnlyMask(e.target.value),
                        })
                      }
                    />
                    <Input name="complemento" label="Complemento" />
                  </div>
                </div>
                <div className="inline-wrapper">
                  <div className="inline">
                    <select
                      defaultValue=""
                      id="estado"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      required
                    >
                      <option id="estado" value="">
                        Estado
                      </option>
                      {getAllStates().map((stateFromApi) => (
                        <option
                          value={stateFromApi.id}
                          key={stateFromApi.id}
                          disabled
                        >
                          {stateFromApi.id}
                        </option>
                      ))}
                    </select>
                    <select
                      defaultValue=""
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      required
                    >
                      <option value="">Cidade</option>
                      {getStateCities(state).map((citie) => (
                        <option value={citie.name} key={citie.name}>
                          {citie.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <Input name="bairro" label="Bairro" required />

                {formDefaultData.channel_id === 8 ? (
                  <Checkbox
                    name="checkbox"
                    options={checkboxOptions2}
                    link="/regulamentos/TERMO_DE_USO_PREMMIAR.pdf"
                    linkText="Baixar regulamento do Catálogo"
                    regulation
                    required
                  />
                ) : (
                  ''
                )}

                <Checkbox
                  name="checkbox"
                  options={checkboxOptions}
                  link={regulationLink}
                  linkText="Baixar regulamento"
                  regulation
                  required
                />

                <div className="actions">
                  {formDefaultData.channel_id !== 8 && (
                    <Button
                      to="#"
                      onClick={() =>
                        setShowRegulationModal(!showRegulationModal)
                      }
                    >
                      Ler Regulamento
                    </Button>
                  )}
                  <button type="submit">Finalizar cadastro</button>
                </div>
              </S.CollForm>
            </S.Form>
          </S.FormWrapper>
        </S.Content>
      </S.Wrapper>
    </>
  );
};

export default Optin;
