import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Template = styled.section`
  width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  ${media.greaterThan('medium')`
 display: grid;
 grid-template: "points rules"
 "filters filters";
  width: inherit;
  position: relative;
  grid-template-columns: 350px 5fr;
  grid-template-rows: auto auto;
`};
`;
export const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  margin-top: 30px;
  padding: 20px 10px;
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;
  min-height: 200px;

  position: relative;
  ${media.greaterThan('medium')`
  padding: 70px 50px;
  width: inherit;
`};

  ${(props) =>
    props.points &&
    css`
      grid-area: points;
      padding: 0px;
      ${media.greaterThan('medium')`
  padding:0px;
`};
    `}
  ${(props) =>
    props.rules &&
    css`
      grid-area: rules;
      padding: 0px;
      ${media.greaterThan('medium')`
  margin-left: 20px;
  padding:0px;
`};
    `}
${(props) =>
    props.filters &&
    css`
      grid-area: filters;
      padding: 0px !important;
      overflow: visible;
    `}
`;

export const Title = styled.p`
  top: 20px;
  left: 25px;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  color: ${({ theme }) => theme.colors.red};
  font-weight: ${({ theme }) => theme.font.medium};
  position: absolute;
  font-weight: 700;
`;
export const Inner = styled.div`
  padding: 20px;
  ${media.greaterThan('medium')`
  padding: 70px 50px;
  width: inherit;
`};
`;
export const Points = styled.div`
  width: 100%;
  position: relative;
  padding: 20px 20px;
  height: 100%;
  input {
    border-radius: 100px;
    padding: 20px 10px;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.red};
    margin: 10px 0px;
    text-align: center;
    color: ${({ theme }) => theme.colors.red};
  }
  span {
    font-size: ${({ theme }) => theme.font.sizes.small};
  }

  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  color: ${({ theme }) => theme.colors.red};
  font-weight: ${({ theme }) => theme.font.medium};
  position: absolute;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const Rules = styled.div`
  width: 100%;
  position: relative;
  padding: 17px 20px 34px;
  height: 100%;

  span {
    font-size: ${({ theme }) => theme.font.sizes.small};
  }

  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  color: ${({ theme }) => theme.colors.red};
  font-weight: ${({ theme }) => theme.font.medium};

  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  .rules-grid {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 40px auto;
    grid-template-rows: auto auto;
    span {
      font-size: ${({ theme }) => theme.font.sizes.large};
    }
    p {
      //styleName: Paragrafo;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: black;
    }
  }
`;

export const Message = styled.h1``;
