import styled from 'styled-components';

export const Container = styled.div`
  iframe {
    margin-top: 30px;
    body {
      background-color: transparent !important;
    }
  }
`;

export const Wrapper = styled.section`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  padding: 30px 50px;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
`;

export const PageTitle = styled.h2`
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.colors.red};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  gap: 10px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;
export const Button = styled.button`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;

  margin-bottom: 20px;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;
