import * as S from '../styles';
const CreatePopUpDescription = () => {
  return (
    <S.ThermWrapper>
      <br />
      Para criar um novo PopUp preencha as informações do formulário.
      <br />
      <br />O campo <strong>Título</strong> é opcional, e caso não seja enviado,
      a mensagem padrão é <strong>"Atenção, Galera Giga!"</strong>.
      <br />O campo <strong>Conteúdo</strong> é obrigatório e esse campo deve
      ser preenchido com a informação que deseja mostrar ao usuário.
      <br />O campo <strong>Para todos os canais?</strong> deve ser{' '}
      <strong>Sim</strong> quando o PopUp deve aparecer para{' '}
      <strong>todos os canais</strong> e, caso o valor for <strong>Não</strong>,
      o PopUp é exibido apenas no <strong>canal atual</strong>.
      <br />
      <br />
      Após preencher todos os dados, clique no botão <strong>Criar</strong>.
      Assim que o PopUp for criado, o conteúdo já estará visível na{' '}
      <strong>página inicial do usuário</strong>.
      <br />
      <br />
      <strong>IMPORTANTE</strong>: A ordem de exibição de PopUp para o{' '}
      <strong>usuário</strong> é decrescente, ou seja, os novos se sobrepõe aos
      antigos.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default CreatePopUpDescription;
