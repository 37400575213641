import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-bottom: 50px;
  ${media.greaterThan('medium')` 
justify-content: left;
flex-direction: row;
`}

  h1 {
    color: ${({ theme }) => theme.colors.grayDarker};
    font-size: 1.6rem;
    margin-bottom: 5px;
    ${media.greaterThan('medium')` 
    margin-right: 15px;
   
`}
  }
`;
export const Input = styled.div`
  padding: 12px 15px;
  border-radius: 63px;
  border: 1px solid #484848;
  min-width: 200px;
  .custom-datepicker-input {
    width: 200px; /* Adjust the width as needed */
    height: 40px; /* Adjust the height as needed */
    font-size: 16px; /* Adjust the font size as needed */
    /* Add any other desired styles */
  }
`;

export const Message = styled.h1``;
