import QuizzesProvider from '~/contexts/QuizzesContext';
import Pages from './Components/Pages';

const Quizz = () => {
  return (
    <QuizzesProvider>
      <Pages />
    </QuizzesProvider>
  );
};

export default Quizz;
