import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

import Loading from '~/components/Loading';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';
import Table from '~/components/NexAdmin/Table';
import SearchBar from '~/components/SearchBar';
import { toDateTime } from '~/Helpers/Date';
import { HTTPErrorCallback } from '~/Helpers/Error';
import DownloadHook from '~/hooks/NexAdmin/DownloadHook';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import { downloadToIndication } from '~/utils';
import { showNotification } from '~/utils/notificationBoxes';

import { columns, indicatedColumns } from './columns';
import Download from './Components/Download';
import * as S from './styles';

import getIcon from '~/Helpers/getIcon';
import { Button } from '~/components/NexAdmin/Buttons';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import IndicationsDescription from '~/components/NexAdmin/Modal/Descriptions/indicationsDescriptions';

const Indications = () => {
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [indicatedList, setIndicatedList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const { openModal } = useModal();

  let indicationsDownload = false;

  useEffect(() => {
    setLoading(true);
    api
      .get(`/admin/listar-usuarios-indicacao/${parseJwt().canal_id}`)
      .then((response) => {
        setLoading(false);
        setSearchedUsers(response.data);
      })
      .catch(() => {
        setLoading(false);
        showNotification(
          'danger',
          'Atenção',
          'Houve um erro ao consultar usuários.',
        );
      });
  }, []);

  const getUsersByIndicationCode = (usuIndicacao) => {
    usuIndicacao = window.btoa(usuIndicacao);
    setLoading(true);
    api
      .get(`/admin/listar-usuario-indicacao/${usuIndicacao}/`)
      .then((response) => {
        setIndicatedList(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        showNotification(
          'danger',
          'Atenção',
          'Houve um erro ao consultar usuários.',
        );
      });
  };

  const handleDownload = () => {
    return api.get(
      indicationsDownload
        ? `/admin/listar-usuario-indicacao/${window.btoa(
            selectedUser.usu_id,
          )}?download=true`
        : `/admin/listar-usuarios-indicacao/${
            parseJwt().canal_id
          }?download=true`,
      { responseType: 'blob' },
    );
  };

  const buildReportName = () => {
    const name = indicationsDownload
      ? `Indicações de ${selectedUser.usu_nome}`
      : 'Indicações';

    return `Relatório ${name} - Canal ${localStorage.getItem(
      'canalNome',
    )} - ${toDateTime(new Date())}`;
  };

  const beforeLoading = () => setLoading(true);

  const afterLoading = () => {
    setLoading(false);
    indicationsDownload = false;
  };

  const { execute, ref, urlFile, nameFile } = DownloadHook({
    externalCall: handleDownload,
    onError: HTTPErrorCallback,
    getFileName: buildReportName,
    beforeLoading,
    afterLoading,
  });

  const openThermsModal = () => {
    openModal({
      title: 'Indicações',
      close: true,
      content: <IndicationsDescription />,
    });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="newGuest"
          modalFunction={openThermsModal}
          title={
            selectedUser
              ? `Utilizações do código de ${
                  selectedUser.usu_nome
                } | Código: ${window.btoa(selectedUser.usu_id)}`
              : 'Relatório de Indicações'
          }
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            {selectedUser ? (
              <>
                <Button
                  leftIcon
                  icon="backArrow"
                  type="button"
                  onClick={() => setSelectedUser(null)}
                >
                  Voltar
                </Button>

                <S.DownloadWrapper>
                  <a href={urlFile} download={nameFile} ref={ref} />
                  <Download
                    buttonName="Download"
                    external={() => {
                      indicationsDownload = true;
                      execute();
                    }}
                  />
                </S.DownloadWrapper>

                <Table
                  loading={loading}
                  data={indicatedList}
                  columns={indicatedColumns}
                  pagination={true}
                  header
                  dropdownItems={false}
                  hasDownload={false}
                />
              </>
            ) : (
              <>
                <S.DownloadWrapper>
                  <a href={urlFile} download={nameFile} ref={ref} />
                  <Download buttonName="Download" external={execute} />
                </S.DownloadWrapper>

                <Table
                  loading={loading}
                  data={searchedUsers}
                  columns={[
                    {
                      name: 'Ações',
                      cell: (row) => (
                        <button
                          onClick={() => {
                            getUsersByIndicationCode(row.usu_id);
                            setSelectedUser(row);
                          }}
                          title="Utilizações do código"
                        >
                          {getIcon('magnifier')}
                        </button>
                      ),
                    },
                    ...columns,
                  ]}
                  pagination={true}
                  header
                  dropdownItems={false}
                  hasDownload={false}
                />
              </>
            )}
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default Indications;
