import { toDateBR, InBetweenDates, toDateTimeNoSeconds } from '~/Helpers/Date';
const na = 'N/A';

const column = [
  {
    name: 'Arquivo',
    selector: (row) => row.media_src,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <a href={row.media_src} target="_blank" rel="noopener noreferrer">
        Abrir
      </a>
    ),
  },
  {
    name: 'Status',
    selector: (row) => row.status,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.status === 'pending'
          ? 'Pendente'
          : row.status === 'approved'
          ? 'Aprovado'
          : row.status === 'rejected'
          ? 'Rejeitado'
          : na}
      </div>
    ),
  },
  {
    name: 'Usuário',
    selector: (row) => row.usu_nome,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => <div data-tag="allowRowEvents">{row.usu_nome}</div>,
  },
  {
    name: 'CPF',
    selector: (row) => row.usu_cpf,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => <div data-tag="allowRowEvents">{row.usu_cpf}</div>,
  },
  {
    name: 'Campanha',
    selector: (row) => row.campaign_title,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => <div data-tag="allowRowEvents">{row.campaign_title}</div>,
  },
  {
    name: 'Criado em',
    selector: (row) => toDateTimeNoSeconds(row.created_at),
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{toDateTimeNoSeconds(row.created_at)}</div>
    ),
  },
];

export default column;
