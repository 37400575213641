import React from 'react';

const Access = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 4V16H21V4H3ZM3 2H21C21.5304 2 22.0391 2.21071 22.4142 2.58579C22.7893 2.96086 23 3.46957 23 4V16C23 16.53 22.79 17.04 22.41 17.41C22.04 17.79 21.53 18 21 18H14V20H16V22H8V20H10V18H3C2.47 18 1.96 17.79 1.59 17.41C1.21 17.04 1 16.53 1 16V4C1 2.89 1.89 2 3 2ZM10.84 8.93C11.15 8.63 11.57 8.45 12 8.45C12.43 8.46 12.85 8.63 13.16 8.94C13.46 9.24 13.64 9.66 13.64 10.09C13.64 10.53 13.46 10.94 13.16 11.25C12.85 11.56 12.43 11.73 12 11.73C11.57 11.73 11.15 11.55 10.84 11.25C10.54 10.94 10.36 10.53 10.36 10.09C10.36 9.66 10.54 9.24 10.84 8.93ZM10.07 12C10.3199 12.2596 10.6197 12.4662 10.9513 12.6072C11.2829 12.7483 11.6396 12.821 12 12.821C12.3604 12.821 12.7171 12.7483 13.0487 12.6072C13.3803 12.4662 13.6801 12.2596 13.93 12C14.44 11.5 14.73 10.81 14.73 10.09C14.73 9.37 14.44 8.67 13.93 8.16C13.42 7.65 12.72 7.36 12 7.36C11.28 7.36 10.58 7.65 10.07 8.16C9.56 8.67 9.27 9.37 9.27 10.09C9.27 10.81 9.56 11.5 10.07 12ZM6 10.09C6.47387 8.88468 7.29963 7.84994 8.36977 7.12046C9.43991 6.39098 10.7049 6.00055 12 6C14.73 6 17.06 7.7 18 10.09C17.5295 11.2976 16.7046 12.3346 15.6337 13.0646C14.5628 13.7946 13.296 14.1834 12 14.18C9.27 14.18 6.94 12.5 6 10.09Z"
        fill={color}
      />
    </svg>
  );
};
export default Access;
