const columns = [
  {
    name: 'Nome',
    selector: (row) => row.nome,
    sortable: true,
  },
  {
    name: 'CPF',
    selector: (row) => row.cpf,
    sortable: true,
  },
  {
    name: 'Classificação',
    selector: (row) => row.classificacao,
    sortable: true,
  },
  {
    name: 'Data de cadastro',
    selector: (row) => row.data_de_cadastro,
    sortable: true,
  },
  {
    name: 'Regional',
    selector: (row) => row.regional,
    sortable: true,
  },
  {
    name: 'Situação',
    selector: (row) => row.situacao,
    sortable: true,
  },
];

export default columns;
