export const status = [
  { value: 'Pendente', label: 'Pendente' },
  { value: 'Em Andamento', label: 'Em Andamento' },
  { value: 'Finalizado', label: 'Finalizado' },
];

export const status_admin = [
  { value: 'N/A', label: 'Selecione uma opção ' },
  { value: 'Claro', label: 'Claro' },
  { value: 'Fullbar', label: 'Fullbar' },
  { value: 'Premmiar', label: 'Premmiar' },
  { value: 'TradeUp', label: 'TradeUp' },
  { value: 'Innova', label: 'Innova' },
  { value: 'Fora Prazo', label: 'Fora Prazo' },
  { value: 'Finalizado', label: 'Finalizado' },
];
export const status_admin_update = [
  { value: '*', label: 'Todos' },
  { value: 'Claro', label: 'Claro' },
  { value: 'Fullbar', label: 'Fullbar' },
  { value: 'Premmiar', label: 'Premmiar' },
  { value: 'TradeUp', label: 'TradeUp' },
  { value: 'Innova', label: 'Innova' },
  { value: 'Fora Prazo', label: 'Fora Prazo' },
  { value: 'Finalizado', label: 'Finalizado' },
];

export const user_response = [
  { value: '*', label: 'Todos' },
  { value: 1, label: 'Sim' },
  { value: '0', label: 'Não' },
];
