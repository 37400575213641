import React from 'react';

const Notification = ({ color }) => {
  return (
    <svg
      width="24"
      height="27"
      viewBox="0 0 24 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7708 20.1665H5.77083V11.4998C5.77083 8.1665 8.4375 5.49984 11.7708 5.49984C15.1042 5.49984 17.7708 8.1665 17.7708 11.4998V20.1665ZM20.4375 18.8332V11.4998C20.4375 7.4065 17.5842 3.97984 13.7708 3.07317V2.1665C13.7708 1.63607 13.5601 1.12736 13.185 0.75229C12.81 0.377218 12.3013 0.166504 11.7708 0.166504C11.2404 0.166504 10.7317 0.377218 10.3566 0.75229C9.98155 1.12736 9.77083 1.63607 9.77083 2.1665V3.07317C5.94417 3.97984 3.10417 7.4065 3.10417 11.4998V18.8332L0.4375 21.4998V22.8332H23.1042V21.4998L20.4375 18.8332ZM11.7708 26.8332C12.4781 26.8332 13.1564 26.5522 13.6565 26.0521C14.1565 25.552 14.4375 24.8737 14.4375 24.1665H9.10417C9.10417 24.8737 9.38512 25.552 9.88522 26.0521C10.3853 26.5522 11.0636 26.8332 11.7708 26.8332Z"
        fill={color}
      />
    </svg>
  );
};
export default Notification;
