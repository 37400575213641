import * as S from './../styles';

const InsertPointGNDescription = ({ isLAD }) => {
  return (
    <S.ThermWrapper>
      <br />
      Nesta tela é feita a inserção de pontos individualmente para os{' '}
      {isLAD ? 'LAD' : 'GN'}.
      <br />
      Para inserir pontos em massa, utilize a funcionalidade "Inserir Dados em
      Massa".
      <br />
      <br />
      Personalize sua busca pelos botões de filtros na tela e então clique em
      “Consultar”.
      <br />
      <br />
      Caso queria fazer o download do relatório, primeiro execute o passo acima
      e, após isso, clique no botão “Download”.
      <br />
      <br />
      Após os botões de filtro e botões de ação, os dados são exibidos em uma
      tabela. É descrito a quantidade de usuários que foram encontradas em sua
      busca e se todos estão sendo exibidos.
      <br />
      <br />
      Caso sua pesquisa encontre mais de 100 resultados, é necessário que faça o
      download do relatório para visualizar todos os usuários encontrados.
      <br />
      <br />
      Através da coluna Ações, o administrador pode inserir ou retirar pontos de
      um {isLAD ? 'LAD' : 'GN'}. Clique no ícone verde para realizar essa ação.
      <br />
      Para inserir pontos, informe um valor positivo. Para retirar pontos,
      informe um valor negativo.
      <br />
      <br />
      <strong>Descritivo dos filtros:</strong>
      <br />
      <strong>CPF:</strong> CPF do usuário
      <br />
      <strong>Regional:</strong> Regional do usuário. Utilize para buscar todos
      os {isLAD ? 'LAD' : 'GN'} de uma determinada regional
      <br />
      <strong>PDV:</strong> PDV do usuário. Utilize para buscar todos os{' '}
      {isLAD ? 'LAD' : 'GN'} de um determinado ponto de venda
      <br />
      <br />
      <strong>Descritivo das colunas da tabela:</strong>
      <br />
      <strong>1. Data Aceite:</strong> Data do aceite do usuário no ciclo atual
      <br />
      <strong>2. Hora Aceite:</strong> Hora do aceite do usuário no ciclo atual
      <br />
      <strong>3. Data Último Login:</strong> Registro do último login
      <br />
      <strong>4. Hora Último Login:</strong> Registro do último login
      <br />
      <strong>5. ID Usuário:</strong> ID do usuário. Valor único no sistema
      <br />
      <strong>6. CPF:</strong> CPF do usuário
      <br />
      <strong>7. Nome:</strong> Nome do usuário
      <br />
      <strong>8. Cargo:</strong> Cargo do usuário
      <br />
      <strong>9. Regional:</strong> Regional do usuário
      <br />
      <strong>10. PDV:</strong> Ponto de venda do usuário
      <br />
      <strong>11. Rede:</strong> Rede do usuário, se aplicável
      <br />
      <strong>12. Saldo de Pontos:</strong> Saldo de pontos do usuário
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default InsertPointGNDescription;
