import * as S from './styles';
import { useEffect, useState } from 'react';
import CarouselSection from './Carousel';
import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import Loading from '~/components/Loading';

const MediaCampaign = () => {
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState({
    all: [],
    userNotParticipated: [],
    userParticipated: [],
  });

  useEffect(() => {
    getCampaigns();
  }, []);

  const getCampaigns = () => {
    api
      .get(`/media-campaign/`)
      .then((response) => {
        setCampaigns(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <S.Wrapper>
        <S.Title>Campanhas</S.Title>

        {/* <CarouselSection campaigns={campaigns.all} title="Todas" /> */}
        <CarouselSection
          campaigns={campaigns.userNotParticipated}
          title="Participe enquanto há tempo!"
        />
        <CarouselSection
          campaigns={campaigns.userParticipated}
          title="Confira o status da sua participação"
        />
      </S.Wrapper>
    </>
  );
};

export default MediaCampaign;
