import { useEffect, useState, useRef } from 'react';
import Loading from '~/components/Loading';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';
import Table from '~/components/NexAdmin/Table';
import { transformArrayIntoSelectOptions } from '~/utils';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import { showNotification } from '~/utils/notificationBoxes';
import * as S from './styles';
import { Form } from '@unform/web';
import columns from './columns';
import DownloadHook from '~/hooks/NexAdmin/DownloadHook';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { toDateTime } from '~/Helpers/Date';
import Download from './Components/Download';
import Counter from '~/components/NexAdmin/Counter';
import { Select, Input } from '~/components/NexAdmin/Inputs';
import { Button } from '~/components/NexAdmin/Buttons/index';

const ListPush = () => {
  const [searchedHistorics, setSearchedHistorics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [net, setNet] = useState([]);
  const [regional, setRegional] = useState([]);
  const [role, setRole] = useState([]);
  const [count, setCount] = useState(0);
  const formRef = useRef();

  useEffect(() => {
    setLoading(true);
    api.get(`/filtros-push/${parseJwt().canal_id}`).then((response) => {
      setLoading(false);
      setNet([
        { value: 'all', label: 'Todos' },
        ...transformArrayIntoSelectOptions(
          response.data.redes,
          (item) => item.usu_rede,
          (item) => item.usu_rede,
        ),
      ]);

      setRegional([
        { value: 'all', label: 'Todos' },
        ...transformArrayIntoSelectOptions(
          response.data.regionais,
          (item) => item.usu_regional,
          (item) => item.usu_regional,
        ),
      ]);

      setRole([
        { value: 'all', label: 'Todos' },
        ...transformArrayIntoSelectOptions(
          response.data.cargos,
          (item) => item.cargo_nome,
          (item) => item.cargo_id,
        ),
      ]);
    });
  }, []);

  const getNotifications = (data) => {
    const params = new URLSearchParams(data);
    setLoading(true);
    api
      .get(`/historico-push/?${params}`)
      .then((response) => {
        setLoading(false);
        setSearchedHistorics(response.data.notifications);
        setCount(response.data.count);
      })
      .catch(() => {
        setLoading(false);
        return showNotification(
          'danger',
          'Atenção',
          'Houve um erro ao buscar dados.',
        );
      });
  };

  const handleDownload = () => {
    const data = formRef.current.getData();
    const params = new URLSearchParams(data);
    return api.get(`/historico-push/?download=true&${params}`, {
      responseType: 'blob',
    });
  };

  const buildReportName = (name) => {
    return `Relatório ${name} - Canal ${localStorage.getItem(
      'canalNome',
    )} - ${toDateTime(new Date())}`;
  };

  const beforeLoading = () => setLoading(true);

  const afterLoading = () => setLoading(false);

  const { execute, ref, urlFile, nameFile } = DownloadHook({
    externalCall: handleDownload,
    onError: HTTPErrorCallback,
    getFileName: buildReportName('Notificações'),
    beforeLoading,
    afterLoading,
  });

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag icon="actions" title="Relatório de Push" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <Form onSubmit={getNotifications} ref={formRef}>
              <S.FormWrapper>
                <S.FormItems>
                  <Select
                    name="type"
                    label="Tipo Notificação"
                    options={[
                      {
                        label: 'Todas',
                        value: '*',
                      },
                      {
                        label: 'Automáticas',
                        value: 'platform',
                      },
                      {
                        label: 'Manuais',
                        value: 'push',
                      },
                    ]}
                    defaultValue={{
                      label: 'Todas',
                      value: '*',
                    }}
                  />
                  <Select name="net" label="Rede" options={net} />
                  <Select name="regional" label="Regional" options={regional} />
                  <Select name="role" label="Cargo" options={role} />
                  <Input name="initial_date" label="De:" type="date" />
                  <Input name="final_date" label="Até:" type="date" />
                </S.FormItems>
              </S.FormWrapper>
              <S.ButtonWrapper>
                <Button type="submit">Pesquisar</Button>
              </S.ButtonWrapper>
            </Form>
            <S.DownloadWrapper>
              <a href={urlFile} download={nameFile} ref={ref} />
              <Download buttonName="Download" external={execute} />
            </S.DownloadWrapper>
            <Counter total={count} />
            <Table
              loading={loading}
              data={searchedHistorics}
              columns={columns}
              pagination={true}
              header
              dropdownItems={false}
              hasDownload={false}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default ListPush;
