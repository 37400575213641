/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import moment from 'moment';

import Filter from '~/components/Filter';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import * as S from './styles';

const UserPoints = () => {
  const [points, setPoints] = useState([]);

  const buscarReceita = (usuCpf) => {
    api
      .post('/buscarReceita', {
        usu_cpf: usuCpf,
      })
      .then((response) => {
        const { vendas } = response.data;
        setPoints(vendas);
      });
  };

  useEffect(() => {
    buscarReceita(parseJwt().login);
  }, []);

  const checkboxOptions = [{ id: 'todos', value: '-1', label: 'Todos' }];
  console.log(points);
  return (
    <>
      <S.Wrapper>
        <S.Container>
          <S.DataWrapper>
            <Filter
              checkboxOptions={checkboxOptions}
              handleSubmit={() => {}}
              title="Visualizar Apenas"
              button="Consultar"
            />
          </S.DataWrapper>
          <S.TableContainer>
            <table cellSpacing="0" cellPadding="0">
              <thead className="points-thead">
                <td>Descrição</td>
                <td>Receita</td>
                <td>Pontos</td>
                <td>Status</td>
              </thead>
              <tbody>
                {points.map((point) => (
                  <>
                    {point.descricao !== 'Expiração de pontos' && (
                      <tr>
                        <td>
                          {point.DESCRICAO
                            ? point.DESCRICAO
                            : moment(new Date(point.DT_VENDA)).format('MMMM')}
                        </td>
                        <td>{point.RECEITA_CONTRATADA}</td>
                        <td>{point.PTOS_GIGABONUS ?? 0}</td>
                        <td>{point.STATUS}</td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </S.TableContainer>
        </S.Container>
      </S.Wrapper>
    </>
  );
};

export default UserPoints;
