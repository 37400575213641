import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 400px 400px;
  grid-gap: 50px;
  margin: 0 auto;
  justify-items: end;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 50px;
  ${media.lessThan('1100px')`
    grid-template-columns: 1fr;
    grid-template-rows: 2fr;
  `}
`;

export const CoverImage = styled.img`
  margin-top: 10px;
  max-width: 300px;
  max-height: 250px;
  justify-self: center;
`;

export const InputWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 10px;
  justify-items: center;

  h3 {
    margin-top: 10px;
    font-size: ${({ theme }) => theme.adminfontsize.containertitle};
    color: ${({ theme }) => theme.admincolors.contrast.black};
  }

  img {
    width: 100%;
    max-width: 200px;
    object-fit: cover;
  }
`;
