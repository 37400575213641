/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import Carousel from 'react-elastic-carousel';
import { FaPlus as PlusIcon } from 'react-icons/fa';
import { HTTPErrorCallback } from '~/Helpers/Error';
import Loading from '~/components/Loading';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';

import * as S from './styles';
import UseTwoFactor from '~/hooks/UseTwoFactor';

const Wishes = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pontos, setPontos] = useState('');
  const { requestCode, FEATURES, setCodeLoading } = UseTwoFactor();

  useEffect(() => {
    api
      .get(`/buscarsaldo/${parseJwt().id}`)
      .then((response) => {
        setPontos(
          parseFloat(response.data.data.saldo ? response.data.data.saldo : 0),
        );
      })
      .catch((error) => HTTPErrorCallback(error));
    api
      .get(`/loja/wishlist/${parseJwt().login}`)
      .then((response) => {
        const data = response.data.data;
        const parsed = Object.keys(data).length ? JSON.parse(data) : [];
        setProducts(parsed);
      })
      .catch((error) => HTTPErrorCallback(error));
  }, []);

  const ssoStore = () => {
    requestCode(FEATURES['common-premmiar-sso'], (digits, setOpen) => {
      setLoading(true);
      setCodeLoading(true);
      api
        .get(`/loja/sso/${digits || null}`)
        .then((response) => {
          setOpen(false);
          setLoading(false);
          setCodeLoading(false);
          window.open(response.data.data);
        })
        .catch((error) => {
          setLoading(false);
          setCodeLoading(false);
          HTTPErrorCallback(error);
        });
    });
  };
  const handleCatalogClick = () => {
    alert('O catálogo está em manutenção.');
  };

  return (
    <>
      {loading && <Loading />}
      <S.WishScrollArea>
        <S.WishContainer>
          <Carousel
            itemPadding={[10, 20]}
            breakPoints={[
              { width: 1, itemsToShow: 1 },
              { width: 550, itemsToShow: 1 },
            ]}
          >
            {products.map((product) => (
              <S.Wish
                style={
                  pontos > product.sellingPrice
                    ? { strokeDashoffset: 0 }
                    : {
                        strokeDashoffset: Number(
                          (((pontos * 100) / product.sellingPrice - 100) *
                            -1 *
                            326.56) /
                            100,
                        ).toFixed(2),
                      }
                }
              >
                <S.WishTitle>{product.productName}</S.WishTitle>
                <div
                  className="circle-big"
                  style={{ backgroundImage: `url(${product.mediumImage})` }}
                >
                  <svg>
                    <defs>
                      <linearGradient
                        id="linear"
                        x1="0%"
                        y1="100%"
                        x2="0%"
                        y2="-20%"
                      >
                        <stop offset="0%" stopColor="#760000" />
                        <stop offset="100%" stopColor="#FF0000" />
                      </linearGradient>
                    </defs>
                    <circle className="bg" cx="57" cy="57" r="52" />
                    <S.Progress className="progress" cx="57" cy="57" r="52" />
                  </svg>
                </div>

                <div className="wish-points">
                  {product.isAvailable
                    ? `Faltam ${product.sellingPrice - pontos} pts`
                    : 'Produto Indisponível'}
                </div>
                <div className="wish-observation">
                  *Os valores exibidos são apenas para referência, e não incluem
                  o valor do frete.
                </div>
              </S.Wish>
            ))}
            {parseJwt().canal_id !== 7 && (
              <S.MoreWish onClick={() => ssoStore()}>
                <S.Plus>
                  <PlusIcon color="white" size="50" />
                </S.Plus>
                <p>Adicionar um produto</p>
              </S.MoreWish>
            )}
          </Carousel>
        </S.WishContainer>
      </S.WishScrollArea>
    </>
  );
};

export default Wishes;
