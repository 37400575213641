import styled from 'styled-components';

export const InputWrapper = styled.div`
  ${({ mt }) => mt && `margin-top: ${mt};`}
  input {
    display: none;
  }

  label {
    padding: 6px 16px;
    display: grid;
    align-content: center;
    font-size: ${({ theme }) => theme.adminfontsize.button};
    font-weight: bold;
    min-height: 40px;

    line-height: ${({ theme }) => theme.lineheight.button};

    /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); */
    color: ${(props) => props.theme.admincolors.contrast.black};

    transition: ${({ theme }) => theme.transition};
    cursor: pointer;

    border: none;
    border-radius: 30px;
    background-color: ${({ theme }) => theme.oldColors.cadetblue[40]};
    border-color: ${({ theme }) => theme.oldColors.cadetblue[40]};
    width: 100%;

    /* MODELOS - default, secondary outline, other  */
    &:focus {
      box-shadow: 0px 0px 0 2px
        ${(props) => props.theme.admincolors.contrast.black};
      outline: none;
    }
  }
`;
