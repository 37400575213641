import * as S from './styles';

export const UserInfoCard = ({ answers }) => {
  const answersIsNotEmpty = () => {
    if (answers && typeof answers !== 'string') {
      return answers.length > 0;
    }
    return false;
  };

  return (
    <>
      {answersIsNotEmpty() ? (
        <S.UserInfoCard>
          <ul>
            {answers.map((answer) => (
              <li key={answer.id}>
                <b>{answer.text}</b>
                <p>{`"${answer.answer}"`}</p>
              </li>
            ))}
          </ul>
        </S.UserInfoCard>
      ) : (
        'N/A'
      )}
    </>
  );
};

export default UserInfoCard;
