import * as S from '../styles';
import { Input, Select, ToggleSwitch } from '~/components/NexAdmin/Inputs';
import { Form } from '@unform/web';
import { Button } from '~/components/NexAdmin/Buttons';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useEffect, useRef, useState } from 'react';

const Box = ({ question, anwser, position, formRef }) => {
  const [open, setOpen] = useState(false);
  const [defaultCorreclty, setDefaultCorrectly] = useState([]);
  const [options, setOptions] = useState([]);
  const [active, setActive] = useState(question.active == 1);

  useEffect(() => {
    const d = correctlyAnswer();
    const o = answerOptions();
    setDefaultCorrectly(d);
    setOptions(o);
  }, []);

  const letter = (number) => {
    return String.fromCharCode(65 + number);
  };
  const correctlyAnswer = () => {
    for (let i = 0; i < anwser.length; i++) {
      if (anwser[i].correctly === 1) {
        return [{ label: letter(i), value: anwser[i].id }];
      }
    }
  };

  const answerOptions = () => {
    return anwser.map((item, i) => ({ label: letter(i), value: item.id }));
  };

  return (
    <>
      <S.InnerWrapper>
        <Form
          ref={(e) => {
            formRef.current[position] = e;
          }}
        >
          <S.Header>
            <S.Letter>{position + 1}</S.Letter>
            <ToggleSwitch
              name="active"
              label="Pergunta ativa?"
              defaultChecked={question.active == 1}
              onChange={(e) => setActive(e.target.checked)}
              value={active}
            />
          </S.Header>
          <S.QuestionWrapper>
            <Input
              name={`question_id_${question.id}`}
              label="Pergunta"
              textArea
              maxLength="200"
              defaultValue={question.question}
              required
            />
            <S.Option>
              {defaultCorreclty.length && (
                <Select
                  label="Resposta correta"
                  name={`correctly_anwser_${question.id}`}
                  options={options}
                  defaultValue={defaultCorreclty}
                  placeholder="Selecione a resposta correta"
                  required
                />
              )}
              <S.Inner>
                <S.ArrowWrapper onClick={() => setOpen(!open)}>
                  {!open ? (
                    <IoIosArrowDown size={20} />
                  ) : (
                    <IoIosArrowUp size={20} />
                  )}
                </S.ArrowWrapper>
              </S.Inner>
            </S.Option>
          </S.QuestionWrapper>
          <S.AnswersWrapper className={open ? 'opened' : 'closed'}>
            {Array.isArray(anwser) &&
              anwser.map((item, i) => (
                <S.Answer key={i}>
                  <S.Letter>{letter(i)}</S.Letter>
                  <Input
                    key={i}
                    name={`anwser_id_${item.id}`}
                    label="Resposta"
                    textArea
                    maxLength="200"
                    defaultValue={item.awnser}
                  />
                </S.Answer>
              ))}
          </S.AnswersWrapper>
        </Form>
      </S.InnerWrapper>
    </>
  );
};

export default Box;
