import * as S from './styles';
import {
  Input,
  Select,
  InputUpload,
  ToggleSwitch,
} from '~/components/NexAdmin/Inputs';
import { Form } from '@unform/web';
import { Button } from '~/components/NexAdmin/Buttons';
import { useRef, useState } from 'react';
import UseQuizzes from '~/hooks/NexAdmin/QuizzesHook';
import api from '~/services/api';
import Loading from '~/components/Loading';
import { appendObjectInFormData } from '~/utils';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { showNotification } from '~/utils/notificationBoxes';

const Update = ({ row, hideModal, refresh }) => {
  const { channelData } = UseQuizzes();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(row.active == 1);
  const formRef = useRef(null);

  const handleSubmit = () => {
    const data = formRef?.current?.getData();
    setLoading(true);
    const form = new FormData();
    appendObjectInFormData(form, {
      ...data,
      role: JSON.stringify(data.role),
      regional: JSON.stringify(data.regional),
      net: JSON.stringify(data.net),
      pdv: JSON.stringify(data.pdv),
      quiz_id: row.id,
    });
    api
      .put(`/quizzes/admin/quizzes`, form)
      .then((response) => {
        setLoading(false);
        showNotification('success', 'Sucesso', response.data.message);
        hideModal();
        refresh();
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };
  return (
    <>
      {loading && <Loading />}
      <S.Wrapper>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <S.QuizWrapper>
            <S.Box>
              <Input
                name="title"
                label="Título"
                type="text"
                defaultValue={row.title}
              />
              <Input
                name="description"
                label="Descrição"
                type="text"
                defaultValue={row.description}
                textArea
              />
              <ToggleSwitch
                name="active"
                label="Ativo?"
                defaultChecked={row.active == 1}
                onChange={(e) => setActive(e.target.checked)}
                value={active}
              />
              <InputUpload
                mt="10px"
                onGetFile={setFile}
                placeholder="Selecione um material"
                accept="application/pdf"
                name="file"
              />
              <Input
                name="video_url"
                label="URL Vídeo"
                type="text"
                defaultValue={row.video_url}
              />
            </S.Box>
            <S.Box>
              <Input
                name="points"
                label="Pontos"
                type="number"
                defaultValue={row.points}
              />
              <Input
                name="points_percentage"
                label="Percentual de acerto"
                type="number"
                defaultValue={row.points_percentage}
              />
              {channelData.role && (
                <Select
                  isMulti
                  name="role"
                  options={channelData.role}
                  defaultValue={row.role_default}
                  label="Cargos"
                  large
                  placeholder="Selecione os cargos"
                />
              )}
              {channelData.regional && (
                <Select
                  isMulti
                  name="regional"
                  options={channelData.regional}
                  defaultValue={row.regional_default}
                  label="Regional:"
                  placeholder="Selecione a regional"
                  large
                />
              )}
              {channelData.net && (
                <Select
                  isMulti
                  name="net"
                  options={channelData.net}
                  defaultValue={row.net_default}
                  label="Rede:"
                  placeholder="Selecione a rede"
                  large
                />
              )}
              {channelData.pdv && (
                <Select
                  isMulti
                  name="pdv"
                  options={channelData.pdv}
                  defaultValue={row.pdv_default}
                  label="PDV:"
                  placeholder="Digite um pdv"
                  large
                />
              )}
            </S.Box>
            <Button type="submit">Salvar</Button>
          </S.QuizWrapper>
        </Form>
      </S.Wrapper>
    </>
  );
};

export default Update;
