import React from 'react';

const Flag = ({ color }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.599976 4.19998C0.599976 3.2452 0.97926 2.32952 1.65439 1.65439C2.32952 0.97926 3.2452 0.599976 4.19998 0.599976H16.2C16.4228 0.599976 16.6413 0.662033 16.8309 0.779195C17.0204 0.896356 17.1736 1.06399 17.2733 1.26332C17.373 1.46265 17.4151 1.68579 17.3951 1.90774C17.3751 2.1297 17.2937 2.34169 17.16 2.51998L14.1 6.59998L17.16 10.68C17.2937 10.8583 17.3751 11.0703 17.3951 11.2922C17.4151 11.5142 17.373 11.7373 17.2733 11.9366C17.1736 12.136 17.0204 12.3036 16.8309 12.4208C16.6413 12.5379 16.4228 12.6 16.2 12.6H4.19998C3.88172 12.6 3.57649 12.7264 3.35145 12.9514C3.1264 13.1765 2.99998 13.4817 2.99998 13.8V17.4C2.99998 17.7182 2.87355 18.0235 2.6485 18.2485C2.42346 18.4735 2.11824 18.6 1.79998 18.6C1.48172 18.6 1.17649 18.4735 0.951447 18.2485C0.726404 18.0235 0.599976 17.7182 0.599976 17.4V4.19998Z"
        fill={color}
      />
    </svg>
  );
};
export default Flag;
