const columns = [
  {
    name: 'Servico Id',
    selector: (row) => row.sale_id,
    cell: (row) => <div title={row.sale_id}>{row.sale_id}</div>,
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row) => row.status,
    cell: (row) => <div title={row.status}>{row.status}</div>,
    sortable: true,
  },
  {
    name: 'Observação',
    selector: (row) => row.observation,
    cell: (row) => (
      <div title={row.observation}>{row.observation || 'N/A'}</div>
    ),
    sortable: true,
  },
];

export default columns;
