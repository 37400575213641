import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  button {
    background: ${({ theme }) => theme.colors.degrade};
    border-radius: 25px;
    border: 0;
    padding: 10px 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 400;
    font-size: 1.4rem;
    transition: box-shadow 0.2s;
    text-decoration: none;
    cursor: pointer;
  }

  table {
    display: table;
    .order-td {
      display: flex;
      flex-direction: row;
      border-radius: 30px;

      &.proccessing {
        background: #f2f2f2;
      }
      &.error {
        background: #ffe8e8;
      }
      &.concluded {
        background: rgba(0, 193, 54, 0.1);
      }
    }
    .order-title {
      font-weight: 700;
    }
    td {
      display: table-cell;
      vertical-align: middle;
      margin: 10px 0px;
      padding: 10px 10px;
      max-width: 150px;
      font-size: 1.3rem;
      input {
        margin-right: 5px;
        border: 1px solid #484848;
        border-radius: 2px;
        appearance: none;
        width: 17px;
        height: 17px;
        cursor: pointer;
        position: relative;

        &:checked {
          background-color: ${({ theme }) => theme.colors.red};
          border: transparent;
          background: ${({ theme }) => theme.colors.red}
            url('data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==')
            3px 3px no-repeat;
        }
      }
    }

    /* background-color: #ccc; */
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.grayDarker};

    thead {
      font-weight: 700;
      font-size: 1.6rem;
      border-radius: 30px;
      overflow: hidden;
      background-color: #f2f2f2;
      color: ${({ theme }) => theme.colors.red}!important;
      td {
        &:first-child {
          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;
        }
        &:last-child {
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
        }
        padding: 10px 20px;
      }
      button {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.red};
        font-weight: 700;
      }
      &.points-thead {
        border: none;
        background-color: #f2f2f2;
        color: ${({ theme }) => theme.colors.red};
      }
    }
    svg {
      fill: ${({ theme }) => theme.colors.red};
    }
  }
`;

export const Results = styled.div`
  padding: 20px;
  display: flex;
  gap: 50px;
  /* background-color: #C50101; */
  margin-top: 20px;
  flex-direction: column;
  ${media.greaterThan('medium')`
flex-direction: row;
`}

  div {
    color: #484848;
    span {
      //styleName: H5;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left;
      &.red {
        color: #c50101;
      }
    }
    .points {
      width: 200px;
      border: 1px solid #484848;
      &.red {
        border: 1px solid #c50101;
        color: #c50101;
      }
      border-radius: 100px;
      height: 50px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
`;

export const ButtonContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  button {
    width: 200px;
  }

  ${media.greaterThan('medium')`
flex-direction: row;
`}
`;
