import { useEffect } from 'react';
import getIcon from '~/Helpers/getIcon';
import { showNotification } from '~/utils/notificationBoxes';
import * as S from './styles';

const CopyToClipboard = ({ data = '' }) => {
  useEffect(() => {
    saveToClipboard();
  }, []);

  const saveToClipboard = () => {
    navigator.clipboard.writeText(data);
    return showNotification(
      'success',
      'Sucesso',
      'Url de acesso copiada para a área de transferência',
    );
  };
  return (
    <>
      <S.Wrapper>
        <S.TextWrapper onClick={saveToClipboard}>
          <p>{data}</p>
          {getIcon('bv', 'black')}
        </S.TextWrapper>
      </S.Wrapper>
    </>
  );
};

export default CopyToClipboard;
