import * as S from './../styles';

const DefaultGNDescription = ({ isLAD }) => {
  return (
    <S.ThermWrapper>
      <br />
      Esta tela exibe o extrato de todas as campanhas criadas por{' '}
      {isLAD ? 'LAD' : 'GN'} e os Vendedores ao qual campanhas foram destinadas.
      <br />
      <br />
      Os dados estão distribuidos em duas abas distintas. Navegue entre as abas{' '}
      <strong>Campanhas {isLAD ? 'LAD' : 'GN'}</strong> e{' '}
      <strong>Campanhas Vendedores</strong>.
      <br />
      <br />
      Personalize sua busca pelos botões de filtros na tela e então clique em
      “Consultar”.
      <br />
      <br />
      Caso queria fazer o download do relatório, primeiro execute o passo acima
      e, após isso, clique no botão “Download”.
      <br />
      <br />
      Após os botões de filtro e botões de ação, os dados são exibidos em uma
      tabela. É descrito a quantidade de campanhas que foram encontradas em sua
      busca e se todos estão sendo exibidos.
      <br />
      <br />
      Caso sua pesquisa encontre mais de 100 resultados, é necessário que faça o
      download do relatório para visualizar todos os produtos encontrados.
      <br />
      <br />
      <strong>Aba Campanhas {isLAD ? 'LAD' : 'GN'}</strong>
      <br />
      <br />
      <strong>Descritivo dos filtros:</strong>
      <br />
      <strong>CPF {isLAD ? 'LAD' : 'GN'}:</strong> CPF do usuário
      <br />
      <strong>Regional {isLAD ? 'LAD' : 'GN'}:</strong> Regional do usuário.
      Utilize para buscar todos os {isLAD ? 'LAD' : 'GN'} de uma determinada
      regional
      <br />
      <strong>PDV:</strong> PDV do usuário. Utilize para buscar todos os{' '}
      {isLAD ? 'LAD' : 'GN'} de um determinado ponto de venda
      <br />
      <br />
      <strong>Descritivo das colunas da tabela:</strong>
      <br />
      <strong>1. ID Usuário:</strong> ID do usuário {isLAD ? 'LAD' : 'GN'}.
      Valor único em toda a tabela
      <br />
      <strong>2. Nome:</strong> Nome do usuário
      <br />
      <strong>3. CPF:</strong> CPF do usuário
      <br />
      <strong>4. Regional:</strong> Regional do usuário
      <br />
      <strong>5. PDV:</strong> Ponto de venda do usuário
      <br />
      <strong>6. Rede:</strong> Rede do usuário, se aplicável
      <br />
      <strong>7. Pendente:</strong> Total de campanhas criadas pelo{' '}
      {isLAD ? 'LAD' : 'GN'} com o status pendente. Estas campanhas estão
      pendentes de pré-aprovação pelo administrador
      <br />
      <strong>8. Pré-Aprovado:</strong> Total de campanhas criadas pelo{' '}
      {isLAD ? 'LAD' : 'GN'} com o status pré-aprovado. Estas campanhas foram
      pré-aprovadas e ao final de seu tempo de validade, pode ser aprovada ou
      reprovada pelo administrador
      <br />
      <strong>9. Reprovado:</strong> Total de campanhas criadas pelo{' '}
      {isLAD ? 'LAD' : 'GN'} com o status reprovado. Essa coluna simboliza as
      campanhas que anteriormente foram pré-aprovadas e após sua validade, foram
      reprovadas pelo administrador
      <br />
      <strong>10. Aprovado:</strong> Total de campanhas criadas pelo{' '}
      {isLAD ? 'LAD' : 'GN'} com o status reprovado. Essa coluna simboliza as
      campanhas que anteriormente foram pré-aprovadas e após sua validade, foram
      aprovadas pelo administrador
      <br />
      <strong>11. Pontuação Disponível:</strong> Total de pontos disponíveis
      para o {isLAD ? 'LAD' : 'GN'} utilizar na criação de campanhas
      <br />
      <strong>12. Pontuação em Aberto:</strong> Total de pontos em campanhas com
      status Pendente ou Pré-aprovado.
      <br />
      <strong>13. Pontuação Entregue:</strong> Total de pontos entregue em
      campanhas. O total corresponde a campanhas aprovadas.
      <br />
      <br />
      <strong>Aba Campanhas Vendedores</strong>
      <br />
      <br />
      <strong>Descritivo dos filtros:</strong>
      <br />
      <strong>De:</strong> Data de inicio da campanha
      <br />
      <strong>Até:</strong> Data de término da campanha
      <br />
      <strong>CPF Vendedor:</strong> CPF do vendedor
      <br />
      <strong>Regional Vendedor:</strong> Regional do vendedor
      <br />
      <strong>Status:</strong> Status atual da campanha. Escolha entre todos,
      pendente, pré-aprovado, reprovado ou aprovado
      <br />
      <br />
      <strong>Descritivo das colunas da tabela:</strong>
      <br />
      <strong>1. ID:</strong> ID da campanha. Valor único em toda a tabela
      <br />
      <strong>2. Data Início:</strong> Inicio da campanha
      <br />
      <strong>3. Data Fim:</strong> Término da campanha
      <br />
      <strong>4. ID Usuário:</strong> ID do usuário. Valor único em toda a
      tabela
      <br />
      <strong>5. Nome:</strong> Nome do usuário
      <br />
      <strong>6. CPF:</strong> CPF do usuário
      <br />
      <strong>7. Regional:</strong> Regional do usuário
      <br />
      <strong>8. PDV:</strong> Ponto de venda do usuário
      <br />
      <strong>9. Descrição:</strong> Descrição de produtos participantes da
      campanha
      <br />
      <strong>10. Pontos:</strong> Status atual da campanha
      <br />
      <strong>11. {isLAD ? 'LAD' : 'GN'} Nome:</strong> Nome do{' '}
      {isLAD ? 'LAD' : 'GN'} criador da campanha
      <br />
      <strong>12. {isLAD ? 'LAD' : 'GN'} CPF:</strong> CPF do{' '}
      {isLAD ? 'LAD' : 'GN'} criador da campanha
      <br />
      <strong>13. Motivo Recusa:</strong> Motivo da recusa da campanha informado
      pelo administrador, caso seja aplicável
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default DefaultGNDescription;
