import { parseJwt } from '~/services/auth';

export const restrictAccess = ({
  idList,
  checkClaroUser,
  channel,
  level,
  bothChannelAndLevel,
}) => {
  let temp = true;
  if (idList) {
    for (let i = 0; i < idList.length; i++) {
      if (idList[i] === parseJwt().id) {
        temp = false;
        break;
      }
    }
  }
  if (checkClaroUser) {
    temp = !parseJwt().usu_nome?.toLowerCase().includes('claro');
  }
  if (bothChannelAndLevel) {
    temp = !(
      channel.includes(parseJwt().canal_id) &&
      level.some((nivel) => parseJwt().usu_nivel >= nivel)
    );
  }
  if (channel) {
    temp = !channel.includes(parseJwt().canal_id);
  }
  if (level) {
    temp = level.some((nivel) => parseJwt().usu_nivel >= nivel);
  }
  return temp;
};

export const allowAccess = ({ level, claro_user }) => {
  let temp = false;
  if (level) {
    temp = level.some((nivel) => parseJwt().usu_nivel >= nivel);
  }
  if (claro_user) {
    temp = parseJwt().usu_nome?.toLowerCase().includes('claro');
  }
  return temp;
};
