import * as S from './styles';
import { Input, Select } from '~/components/NexAdmin/Inputs';
import { Form } from '@unform/web';
import { useEffect, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import UseQuizzes from '~/hooks/NexAdmin/QuizzesHook';
import { Button } from '~/components/NexAdmin/Buttons';

const Questions = ({ position, onRemove, questionsLength, letter }) => {
  const { questionRef, newQuestion, getRefData } = UseQuizzes();
  const [open, setOpen] = useState(newQuestion[position]?.answers?.length > 0);
  const [answers, setAnswers] = useState(
    newQuestion[position]?.answers?.length > 0
      ? Array.from(newQuestion[position]?.answers, (item) => item)
      : [],
  );
  const [options, setOptions] = useState(
    answers.length > 0
      ? answers.map((item) => ({ label: letter(item.id), value: item.id }))
      : [],
  );
  const deleteAnswer = (obj) => {
    const remove = answers.filter((item) => item.id !== obj?.id);
    const removeOption = options.filter((item) => item.value !== obj.id);
    if (remove.length > 0) {
      setAnswers(remove);
      setOptions(removeOption);
    }
  };
  const newAnswer = () => {
    const newValue = answers[answers.length - 1]
      ? answers[answers.length - 1]?.id + 1
      : 0;
    setAnswers((prev) => [...prev, { id: newValue }]);
    setOptions((prev) => [
      ...prev,
      { label: letter(newValue), value: newValue },
    ]);
    if (answers.length < 1) {
      setOpen(!open);
    }
  };
  return (
    <S.Wrapper>
      <S.Header>
        <S.Letter>{position + 1}</S.Letter>
        {questionsLength > 1 && position === questionsLength - 1 && (
          <Button onClick={() => onRemove(position)}>excluir</Button>
        )}
      </S.Header>
      <Form
        ref={(e) => {
          questionRef.current[position] = e;
        }}
      >
        <S.QuestionWrapper>
          <Input
            name={`question_${position}`}
            label="Pergunta"
            textArea
            maxLength="200"
            defaultValue={getRefData(newQuestion, position, ['question_'])}
            required
          />
          <S.Option>
            <Select
              label="Resposta correta"
              name={`correctly_anwser_${position}`}
              options={options}
              defaultValue={getRefData(newQuestion, position, [
                'correctly_anwser_',
              ])}
              placeholder="Selecione a resposta correta"
              required
            />
            <S.Inner>
              {answers.length > 0 && (
                <S.ArrowWrapper onClick={() => setOpen(!open)}>
                  {!open ? (
                    <IoIosArrowDown size={20} />
                  ) : (
                    <IoIosArrowUp size={20} />
                  )}
                </S.ArrowWrapper>
              )}
            </S.Inner>
          </S.Option>
        </S.QuestionWrapper>
        <S.AnswersWrapper className={open ? 'opened' : 'closed'}>
          {answers.map((item, i) => (
            <S.Answer key={i}>
              <S.Letter>{letter(i)}</S.Letter>
              <Input
                key={i}
                name={`question_${position}_anwser_${item.id}`}
                label="Resposta"
                textArea
                maxLength="200"
                defaultValue={item.content}
              />
              {i !== 0 && i === answers.length - 1 && (
                <Button onClick={() => deleteAnswer(item)}>excluir</Button>
              )}
            </S.Answer>
          ))}
        </S.AnswersWrapper>
      </Form>
      <S.Option>
        <Button
          onClick={() => newAnswer()}
          style={{ width: '20%' }}
          disabled={answers.length >= 4}
        >
          Inserir resposta
        </Button>
      </S.Option>
    </S.Wrapper>
  );
};

export default Questions;
