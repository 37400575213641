import * as S from './styles';

const AdminHome = ({ nexAdmin }) => {
  return (
    <S.MainSection nexAdmin={nexAdmin}>
      <h2>Bem Vindo Administrador</h2>
      <h3>
        Seu canal atual é: <h1>{localStorage.getItem('canalNome')}</h1>
      </h3>
    </S.MainSection>
  );
};

export default AdminHome;
