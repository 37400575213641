import * as S from './styles';
const ChangeBulkEmailDescription = () => {
  return (
    <S.ThermWrapper>
      <br />
      Essa tela permite a atualização em massa de e-mails dos usuários do canal
      Aparelhos.
      <br />
      <br />
      Para isso, clique no botão <strong>Download Modelo</strong> para baixar o
      arquivo padrão de preenchimento.
      <br />
      <br />
      Nele, há duas colunas que devem ser preenchidas, sendo o{' '}
      <strong>CPF</strong> e o <strong>email</strong>. Preencha-os com os dados
      desejados e em seguida clique no botão <strong>Escolher Arquivo</strong>{' '}
      para selecionar o arquivo preenchido.
      <br />
      <br />
      Para visualizar a lista antes do processamento, clique no botão{' '}
      <strong>Pré-visualizar Lista</strong>, e para processar clique em{' '}
      <strong>Processar Lista</strong>.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default ChangeBulkEmailDescription;
