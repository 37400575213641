import React, { useState, useCallback, createContext } from 'react';

export const GlobalModalStructureContext = createContext({
  modalStructureProps: null,
  addGlobalModalStructure: () => {},
  removeGlobalModalStructure: () => {},
});

export default function GlobalModalStructureProvider({ children }) {
  const [modalStructureProps, setModalStructureProps] = useState(null);

  const removeGlobalModalStructure = () => setModalStructureProps(null);

  const addGlobalModalStructure = (
    children,
    closable = true,
    onClose,
    closeOnClickBackground = true,
  ) =>
    setModalStructureProps({
      children,
      closable,
      onClose,
      closeOnClickBackground,
    });

  const contextValue = {
    modalStructureProps: modalStructureProps,
    addGlobalModalStructure: useCallback(
      (children, closable, onClose, closeOnClickBackground) =>
        addGlobalModalStructure(
          children,
          closable,
          onClose,
          closeOnClickBackground,
        ),
      [],
    ),
    removeGlobalModalStructure: useCallback(
      () => removeGlobalModalStructure(),
      [],
    ),
  };

  return (
    <GlobalModalStructureContext.Provider value={contextValue}>
      {children}
    </GlobalModalStructureContext.Provider>
  );
}
