import React from 'react';

const Back = ({ color }) => {
  return (
    <svg
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8334 1V5H4.66337L8.24337 1.41L6.83337 0L0.833374 6L6.83337 12L8.24337 10.58L4.66337 7H19.8334V1H17.8334Z"
        fill={color}
      />
    </svg>
  );
};
export default Back;
