import * as S from './styles';
import UseAuthentication from '~/hooks/NexAdmin/AuthenticationHook';

const Loading = ({ isComponent = true }) => {
  const { parseJwt } = UseAuthentication();
  return (
    <S.Loader isComponent={isComponent} isAdmin={parseJwt?.type === 'admin'}>
      <div />
    </S.Loader>
  );
};

export default Loading;
