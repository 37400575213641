import { useState } from 'react';
import * as S from './styles';

const SearchBar = ({ rawData, setSearchResults, columns }) => {
  const [focus, setFocus] = useState(false);

  const search = (list, query) => {
    let internalColumns = [];

    columns.forEach((column) => {
      internalColumns.push({ name: column.name, selector: column.selector });
    });

    setSearchResults(
      list.filter((value) => filterList(value, internalColumns, query)),
    );
  };

  const filterList = (value, columns, query) => {
    let result = false;
    columns.forEach((column) => {
      if (
        column.selector !== undefined &&
        typeof column.selector(value) === 'string'
      ) {
        if (
          column.selector(value).toLowerCase().includes(query.toLowerCase())
        ) {
          return (result = true);
        }
      }
    });

    return result;
  };

  return (
    <S.Inner>
      <S.InputWrapper>
        <S.Input
          id="main-searchbar"
          placeholder="Pesquisar"
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={(e) => search(rawData, e.target.value)}
        />
        <S.Line focus={focus} className="line" />
      </S.InputWrapper>
    </S.Inner>
  );
};

export default SearchBar;
