import styled from 'styled-components';

export const FormWrapperUpdate = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 45px;
  form {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  place-items: center;
  border: ${({ theme, notification }) =>
    notification ? `2px solid ${theme.admincolors.red}` : ''};
  border-radius: 10px;
  padding-top: 10px;
  width: 100%;
  margin-bottom: 15px;
  min-height: 200px;
`;

export const HeaderTicketData = styled.div`
  grid-template-rows: auto;
  width: 100%;
  height: 100%;
  padding: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-y: overlay;
`;

export const HeaderUserData = styled.div`
  grid-template-rows: auto;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  height: 100%;
  border-right: ${(props) => `2px solid ${props.theme.oldColors.primary[40]}`};
`;
export const HeaderItem = styled.p`
  font-size: ${({ theme }) => theme.adminfontsize.containertitle};
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.admincolors.contrast.black};
  width: 100%;
  padding-top: 20px;
  width: 100%;
  height: fit-content;
  overflow-y: auto;
  max-height: 300px;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 45px;
`;

export const FilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 100%;
  max-height: 400px;
  align-self: flex-start;
  justify-self: center;
  overflow: auto;
  overflow-x: hidden;
`;

export const File = styled.div`
  display: flex;
  flex-direction: column;
  a {
    font-size: ${({ theme }) => theme.adminfontsize.containertitle};
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin: 10px;
  justify-content: flex-end;
`;

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  label {
    font-size: 1.4rem;
  }
  input {
    width: 45px;
  }
`;

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.adminfontsize.containertitle};
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  height: fit-content;
  color: ${({ theme }) => theme.admincolors.contrast.black};
  border-bottom: 1px solid ${({ theme }) => theme.oldColors.gray[50]};
  width: 100%;
  padding: 10px;
  overflow-wrap: break-word;
  overflow-y: hidden;
`;

export const NoData = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.adminfontsize.containertitle};
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.admincolors.contrast.black};
  width: 100%;
  padding-bottom: 10px;
`;
