import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import { useState, useRef, useEffect } from 'react';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import Loading from '~/components/Loading';
import Table from '~/components/NexAdmin/Table';
import Counter from '~/components/NexAdmin/Counter';
import { Input, Select } from '~/components/NexAdmin/Inputs/index';
import { Button } from '~/components/NexAdmin/Buttons/index';
import { Form } from '@unform/web';
import { appendObjectInFormData } from '~/utils';
import { HTTPErrorCallback } from '~/Helpers/Error';
import * as S from './styles';
import DownloadHook from '~/hooks/NexAdmin/DownloadHook';
import Download from '~/components/NexAdmin/Download/Download';
import columns from './columns';
import { buildReportName } from '~/utils/NexAdmin/utils';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import ExtractDescription from '~/components/NexAdmin/Modal/Descriptions/extractDescription';
import { transformArrayIntoSelectOptions } from '~/utils';

const ExtractReport = () => {
  const [loading, setLoading] = useState(false);
  const [extract, setExtract] = useState([]);
  const [count, setCount] = useState(0);
  const [regional, setRegional] = useState([]);
  const [role, setRole] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const current_user = parseJwt();
  const formRef = useRef();
  const { openModal } = useModal();

  useEffect(() => {
    setLoading(true);
    api
      .get('/extract-report/transaction-type')
      .then((response) => {
        setTransaction([
          { value: '*', label: 'Todos' },
          ...transformArrayIntoSelectOptions(
            response.data,
            (item) => item.tt_nome,
            (item) => item.tt_id,
          ),
        ]);
      })
      .catch((error) => {});
    api
      .get(`/campaign-exhibiton/admin/get-products`)
      .then((response) => {
        const { role, regional } = response.data;
        const base = { label: 'TODOS', value: '*' };
        setRegional([base, ...regional]);
        setRole([base, ...role]);
      })
      .catch((error) => {
        return HTTPErrorCallback(error);
      });
    setLoading(false);
  }, []);

  const handleSubmit = (data) => {
    setLoading(true);
    const form = new FormData();
    appendObjectInFormData(form, {
      segmento: data?.Segmento ? data?.Segmento : '*',
      ...data,
    });
    const params = new URLSearchParams(form);
    api
      .get(`/extract-report/?${params}`)
      .then((response) => {
        const { count, data_found } = response.data;
        setExtract(data_found);
        setCount(count);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const handleDownload = () => {
    const data = formRef.current.getData();
    const form = new FormData();
    appendObjectInFormData(form, {
      download: true,
      segmento: data?.Segmento ? data?.Segmento : '*',
      ...data,
    });
    const params = new URLSearchParams(form);
    return api.get(`/extract-report/?${params}`, { responseType: 'blob' });
  };

  const beforeLoading = () => setLoading(true);

  const afterLoading = () => setLoading(false);

  const { execute, ref, urlFile, nameFile } = DownloadHook({
    externalCall: handleDownload,
    onError: HTTPErrorCallback,
    getFileName: buildReportName('Extrato do Participante'),
    beforeLoading,
    afterLoading,
  });

  const switchColumns = () => {
    switch (Number(current_user.canal_id)) {
      case 2:
      case 4:
        return columns['2'];
      case 5:
        return columns['5'];
      case 6:
        return columns['6'];
      case 8:
        return columns['8'];
      case 9:
        return columns['9'];
    }
  };
  const filters = {
    5: [
      {
        label: 'Perfil Detalhe',
        options: [
          { label: 'Todos', value: '*' },
          { label: 'Estrutural Mar Aberto', value: 'mar_aberto' },
          { label: 'Estrutural RED', value: 'red' },
          { label: 'Especialista', value: 'especialista' },
        ],
        name: 'profile_detail',
      },
      {
        label: 'Ponto válido?',
        options: [
          { label: 'Todos', value: '*' },
          { label: 'Sim', value: '1' },
          { label: 'Não', value: '0' },
        ],
        name: 'point_status',
      },
      {
        label: 'Razão Social',
        type: 'text',
        name: 'social_name',
      },
      {
        label: 'Nome Fantasia',
        type: 'text',
        name: 'company_name',
      },
      {
        label: 'PDV',
        type: 'text',
        name: 'store',
      },
    ],
    6: [
      {
        label: 'Ponto válido?',
        options: [
          { label: 'Todos', value: '*' },
          { label: 'Sim', value: '1' },
          { label: 'Não', value: '0' },
        ],
        name: 'point_status',
      },
      {
        label: 'Loja',
        type: 'text',
        name: 'store',
      },
    ],
    2: [
      {
        label: 'Ponto válido?',
        options: [
          { label: 'Todos', value: '*' },
          { label: 'Sim', value: '1' },
          { label: 'Não', value: '0' },
        ],
        name: 'point_status',
      },
      {
        label: 'PDV',
        type: 'text',
        name: 'store',
      },
    ],
    4: [
      {
        label: 'Ponto válido?',
        options: [
          { label: 'Todos', value: '*' },
          { label: 'Sim', value: '1' },
          { label: 'Não', value: '0' },
        ],
        name: 'point_status',
      },
      {
        label: 'PDV',
        type: 'text',
        name: 'store',
      },
    ],
    8: [
      {
        label: 'Ponto válido?',
        options: [
          { label: 'Todos', value: '*' },
          { label: 'Sim', value: '1' },
          { label: 'Não', value: '0' },
        ],
        name: 'point_status',
      },
    ],
    9: [
      {
        label: 'Ponto válido?',
        options: [
          { label: 'Todos', value: '*' },
          { label: 'Sim', value: '1' },
          { label: 'Não', value: '0' },
        ],
        name: 'point_status',
      },
      {
        label: 'Segmento',
        options: [
          { label: 'Todos', value: 'all' },
          { label: 'Premium', value: 'PREMIUM' },
          { label: 'Condomínio', value: 'CONDOMÍNIOS' },
        ],
        name: 'Segmento',
      },
    ],
  };

  const openThermsModal = () => {
    openModal({
      title: 'Extrato',
      close: true,
      content: <ExtractDescription />,
    });
  };
  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="statusSketch"
          title="Extrato"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle title="Extrato do Participante">
            <Form ref={formRef} onSubmit={handleSubmit}>
              <S.FormWrapper>
                <S.FormFilters>
                  {parseJwt().canal_id !== 9 && (
                    <Input name="id" label="ID Venda" type="number" />
                  )}
                  <Input name="cpf" label="CPF/CNPJ" type="number" />
                  <Input name="initial_date" label="De:" type="date" />
                  <Input name="final_date" label="Até:" type="date" />
                  {current_user?.usu_nivel >= 50 ||
                  parseJwt().usu_nome?.toLowerCase().includes('claro') ? (
                    <>
                      <Select
                        name="user_regional"
                        label="Regional"
                        options={regional}
                        isMulti
                      />
                      <Select
                        name="role"
                        label="Cargo"
                        options={role}
                        isMulti
                      />
                    </>
                  ) : (
                    ''
                  )}
                  <Select
                    name="transaction_type"
                    label="Tipo Transação"
                    options={transaction}
                    isMulti
                  />
                  {filters[current_user.canal_id] &&
                    filters[current_user.canal_id].map((item, i) =>
                      item.options ? (
                        <Select
                          name={item.name}
                          label={item.label}
                          options={item.options}
                        />
                      ) : (
                        <Input
                          name={item.name}
                          label={item.label}
                          type={item.type}
                        />
                      ),
                    )}
                </S.FormFilters>
                <Button type="submit">Consultar</Button>
              </S.FormWrapper>
              <S.DownloadWrapper>
                <a href={urlFile} download={nameFile} ref={ref} />
                <Download buttonName="Download" external={execute} />
              </S.DownloadWrapper>
            </Form>
            <Counter total={count} />
            <Table
              data={extract}
              columns={switchColumns()}
              dataTableProps={{ pagination: true }}
              header
              dropdownItems={false}
              hasDownload={false}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default ExtractReport;
