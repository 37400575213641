import { useEffect, useState, useRef } from 'react';
import Loading from '~/components/Loading';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';

import Input from '~/components/NexAdmin/Inputs/Input';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import { showNotification } from '~/utils/notificationBoxes';

import columns from './columns';
import * as S from './styles';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import { Form } from '@unform/web';
import { Button } from '~/components/NexAdmin/Buttons';
import Table from '~/components/NexAdmin/Table';
import { Select } from '~/components/NexAdmin/Inputs';
import { transformArrayIntoSelectOptions } from '~/utils';
import PushDescription from '~/components/NexAdmin/Modal/Descriptions/pushDescription';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import Counter from '~/components/NexAdmin/Counter';
import { toDateTime } from '~/Helpers/Date';

const Push = () => {
  const [step, setStep] = useState('users_found');
  const [selectedCanais, setSelectedCanais] = useState('');
  const [errors, setErrors] = useState([]);
  const [users, setUsers] = useState([]);
  const [redes, setRedes] = useState([]);
  const [regionais, setRegionais] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [canais, setCanais] = useState([]);
  const [notificationSent, setNotificationSent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [totalLoading, setTotalLoading] = useState(0);
  const formRef = useRef();
  const cancelSubmit = useRef(false);
  const controllerRef = useRef(new AbortController());

  const { openModal } = useModal();
  useEffect(() => {
    setLoading(true);
    api.get(`/filtros-push/${parseJwt().canal_id}`).then((response) => {
      setLoading(false);
      setRedes([
        { value: 'all', label: 'Todos' },
        ...transformArrayIntoSelectOptions(
          response.data.redes,
          (item) => item.usu_rede,
          (item) => item.usu_rede,
        ),
      ]);

      setRegionais([
        { value: 'all', label: 'Todos' },
        ...transformArrayIntoSelectOptions(
          response.data.regionais,
          (item) => item.usu_regional,
          (item) => item.usu_regional,
        ),
      ]);

      setCargos([
        { value: 'all', label: 'Todos' },
        ...transformArrayIntoSelectOptions(
          response.data.cargos,
          (item) => item.cargo_nome,
          (item) => item.cargo_id,
        ),
      ]);
      setCanais(response.data.canais);
    });
  }, []);

  useEffect(() => {
    return () => {
      controllerRef.current.abort();
      cancelSubmit.current = true;
    };
  }, []);

  function handleCheck(e) {
    const checkClicado = e.target;
    let checkAtual = [];
    checkAtual = e.target.value;
    if (!checkClicado.checked) {
      const novaArray = selectedCanais.filter((element) => {
        return element !== checkClicado.value;
      });
      setSelectedCanais(novaArray);
    } else {
      setSelectedCanais([...selectedCanais, checkAtual]);
    }
  }

  const getUsers = (data) => {
    setStep('users_found');
    const { rede, regional, cargo, type } = data;
    if (!rede || !regional || !cargo) {
      return showNotification(
        'danger',
        'Atenção',
        'Selecione todos os campos.',
      );
    }

    if (
      (!selectedCanais ||
        selectedCanais.length === 0 ||
        selectedCanais === '') &&
      parseJwt().canal_id === 7
    ) {
      return showNotification(
        'warning',
        'Atenção',
        'Preencha todos os campos! (selecione pelo menos um canal)',
      );
    }

    setLoading(true);

    if (parseJwt().canal_id === 7) {
      api
        .post('/admin/listar-usuarios-push', {
          canal_id: parseJwt().canal_id,
          rede,
          regional,
          cargo,
          selectedCanais,
        })
        .then((response) => {
          setLoading(false);
          setUsers(response.data);
        })
        .catch(() => {
          setLoading(false);
          showNotification(
            'danger',
            'Atenção',
            'Houve um erro ao consultar usuários.',
          );
        });
    } else {
      api
        .post('/notifications/get-users', {
          canal_id: parseJwt().canal_id,
          rede,
          regional,
          cargo,
          type,
        })
        .then((response) => {
          setLoading(false);
          setUsers(response.data);
        })
        .catch((error) => {
          setLoading(false);
          showNotification(
            'danger',
            'Atenção',
            'Houve um erro ao consultar usuários.',
          );
        });
    }

    return true;
  };

  const sendPush = async (data, { reset }) => {
    cancelSubmit.current = false;
    const refData = formRef.current.getData();
    if (!users.length) {
      setLoading(false);
      return showNotification(
        'danger',
        'Atenção!',
        'É necessário enviar notificação para pelo menos 1 usuário!',
      );
    }
    let offSet = 0;
    let total_loading = 0;
    setStep('result');
    setIsSending(true);

    while (total_loading !== users.length) {
      if (cancelSubmit.current) {
        setIsSending(false);
        break;
      }
      try {
        const offsetUsers = users.slice(offSet, offSet + 30);
        const { signal } = controllerRef.current;
        const notifications = await api.post(
          '/send-push',
          {
            pushPayload: data,
            users: offsetUsers,
            refData,
            offSet,
          },
          { signal },
        );
        offSet += 30;
        total_loading += notifications.data?.success?.length;
        setNotificationSent((prev) => [...prev, ...notifications.data.success]);
        setTotalLoading((prev) => prev + notifications.data?.success?.length);
        setErrors((prev) => [...prev, ...notifications.data.errors]);
      } catch (error) {
        reset();
        if (error.message === 'canceled') {
          return showNotification(
            'info',
            'Atenção!',
            'O envio de notificações foi cancelado!',
          );
        }
        return showNotification(
          'danger',
          'Atenção!',
          'Houve um erro ao enviar notificações!',
        );
      }
    }
    setIsSending(false);
    setTotalLoading(0);
    return showNotification(
      'success',
      'Sucesso!',
      'Notificações enviadas com sucesso!',
    );
  };
  const openThermsModal = () => {
    openModal({
      title: 'Push Notification',
      close: true,
      content: <PushDescription />,
    });
  };

  const switchStep = () => {
    switch (step) {
      case 'users_found':
        return (
          <>
            <S.InformText>
              <p>
                Atenção! As notificações serão enviadas para todos os usuários
                da tabela abaixo.
              </p>
            </S.InformText>
            <Counter total={users.length} showAll />
            <Table
              loading={loading}
              data={users}
              columns={columns}
              pagination={true}
              header
              dropdownItems={false}
              hasDownload={false}
            />
          </>
        );
      case 'result':
        return (
          <>
            <S.InformText>
              <p>Total de notificações enviadas: {notificationSent.length}</p>
            </S.InformText>
            <Table
              loading={loading}
              data={notificationSent}
              columns={columns}
              pagination={true}
              header
              dropdownItems={false}
              hasDownload
              fileName={`Relatório Notificações enviadas com sucesso em ${toDateTime(
                new Date(),
              )}`}
            />
            <S.InformText>
              <p>
                Os usuários foram notificados pelo site, entretando, houveram
                alguns erros ao enviar a notificação para o aparelho.
              </p>
              <p>Verifique abaixo e faça o download do relatório:</p>
            </S.InformText>
            <Table
              loading={loading}
              data={errors}
              columns={[
                { name: 'Nome', selector: (row) => row.user?.usu_nome },
                { name: 'CPF', selector: (row) => row.user?.cpf },
                { name: 'Cargo', selector: (row) => row.user?.cargo },
                { name: 'Regional', selector: (row) => row.user?.regional },
                { name: 'Rede', selector: (row) => row.user?.rede },
                { name: 'Device', selector: (row) => row.user?.usu_device },
                {
                  name: 'Motivo',
                  selector: () => 'Token expirado.',
                },
              ]}
              pagination={true}
              header
              dropdownItems={false}
              fileName={`Relatório Notificações não enviadas via push em ${toDateTime(
                new Date(),
              )}`}
            />
          </>
        );
    }
  };

  const calculatePercentage = (users, totalLoading) => {
    const totalLength = users.length;
    const totalSent = totalLoading;
    const percentage = Math.floor((totalSent * 100) / totalLength);
    const total = percentage > 100 ? '100' : percentage.toString();
    return 'ENVIANDO...' + total + '%';
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="add"
          title="Disparar Notificações"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <S.PushFormWrapper>
              <Form onSubmit={sendPush}>
                <Input name="title" required label="Título" />
                <Input name="body" required label="Corpo" textArea />

                <Button mt="20px" type="submit" disabled={isSending}>
                  <S.ButtonWithLoading>
                    {isSending ? (
                      <>
                        <S.Loading />
                        <div>{calculatePercentage(users, totalLoading)}</div>
                      </>
                    ) : (
                      'Enviar Notificações'
                    )}
                  </S.ButtonWithLoading>
                </Button>
                <Button
                  disabled={!isSending}
                  mt="20px"
                  type="button"
                  onClick={() => (cancelSubmit.current = true)}
                >
                  Cancelar envio
                </Button>
              </Form>
            </S.PushFormWrapper>
            {parseJwt().canal_id === 7 ? (
              <>
                <p>Selecione pelo menos um canal:</p>
                <ul>
                  {canais.map((regionalItem) =>
                    regionalItem.canal_label ? (
                      <li>
                        <label htmlFor="first-name">
                          <input
                            type="checkbox"
                            onClick={handleCheck}
                            value={regionalItem.digital_canal_id}
                          />
                          {regionalItem.canal_label}
                        </label>
                      </li>
                    ) : null,
                  )}
                </ul>
              </>
            ) : (
              ' '
            )}
            <S.FormWrapper>
              <Form onSubmit={getUsers} ref={formRef}>
                <Select
                  name="rede"
                  label="Rede"
                  required
                  options={redes}
                  placeholder="Selecione uma rede"
                  wi
                />

                <Select
                  name="regional"
                  label="Regional"
                  required
                  options={regionais}
                  placeholder="Selecione uma regional"
                />

                <Select
                  name="cargo"
                  label="Cargo"
                  required
                  options={cargos}
                  placeholder="Selecione um cargo"
                />
                <Select
                  name="type"
                  label="Tipo de Notificação"
                  required
                  options={[
                    { value: 'all', label: 'Todos' },
                    { value: 'push', label: 'Apenas Push' },
                    { value: 'platform', label: 'Apenas Site' },
                  ]}
                  defaultValue={{ value: 'all', label: 'Todos' }}
                />
                <Button type="submit">Filtrar</Button>
              </Form>
            </S.FormWrapper>
            {switchStep()}
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default Push;
