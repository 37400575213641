import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '~/components/Loading';

import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import { goTopPage } from '~/utils';
import { showNotification } from '~/utils/notificationBoxes';

import PostItem from './components/PostItem';
import PreviewPostItem from './components/PreviewPostItem';
import * as S from './styles';

const Gigaline = ({ id, admin }) => {
  const history = useHistory();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setCurrentPage] = useState(1);

  useEffect(() => {
    goTopPage();

    getPosts();
  }, [page]);

  const getPosts = () => {
    setLoading(true);
    api
      .get(`${admin ? '/admin' : ''}/posts/${parseJwt().canal_id}/${page}`)
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        setPosts(response.data.data.data);
      })
      .catch((error) => {
        setLoading(false);
        return showNotification(
          'danger',
          'Atenção',
          'Erro ao buscar os posts.',
        );
      });
  };

  return (
    <>
      {loading && <Loading />}
      {id && posts.length > 0 ? (
        <S.PreviewWrapper onClick={() => history.push('/gigaline')}>
          <PreviewPostItem post={posts[0]} />
        </S.PreviewWrapper>
      ) : (
        <>
          <S.WrapperOuter>
            <S.Wrapper>
              {posts.map((post) => (
                <div key={post.post_id}>
                  <PostItem post={post} admin={admin} getPosts={getPosts} />
                </div>
              ))}

              <S.Button type="button" onClick={() => setCurrentPage(page + 1)}>
                Carregar mais postagens
              </S.Button>
            </S.Wrapper>
          </S.WrapperOuter>
        </>
      )}
    </>
  );
};

export default Gigaline;
