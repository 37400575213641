import styled from 'styled-components';

export const MainSection = styled.section`
  width: ${({ nexAdmin }) => (nexAdmin ? '100%' : 'calc(100vw - 100px)')};
  display: flex;
  align-items: center;
  flex-direction: column;

  h3 {
    display: flex;
    gap: 10px;
    h1 {
      text-decoration: underline;
    }
  }

  span {
    white-space: nowrap;
    width: fit-content;
  }
`;
