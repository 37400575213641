import styled from 'styled-components';

export const Container = styled.div`
  grid-area: wishlist;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  overflow: hidden;
  padding: 17px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 15px;
  svg {
    cursor: pointer;
  }
`;

export const Message = styled.h1``;

export const Title = styled.p`
  margin-left: 5px;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  color: ${({ theme }) => theme.colors.red};
  font-weight: ${({ theme }) => theme.font.medium};
`;

export const Button = styled.button`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;
  width: 150px;
  margin-bottom: 20px;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;
