import Button from '~/components/NexAdmin/Buttons/Button';
import Table from '~/components/NexAdmin/Table/index.js';
import { showNotification } from '~/utils/notificationBoxes';
import * as S from './styles.js';
import { columns, columns_pap_pc } from './columns';
import { errorsColumns, errorColumnsPapPC } from '../Errors/columns.js';
import api from '~/services/api.js';
import { HTTPErrorCallback } from '~/Helpers/Error.js';
import Download from '~/screens/NexAdmin/BonusPoints/Screens/Create/Components/Download/index';
import { ToggleSwitch } from '~/components/NexAdmin/Inputs/index.js';
import { Form } from '@unform/web';
import { useState } from 'react';
import UseTwoFactor from '~/hooks/UseTwoFactor.js';

const CreateTransactions = ({
  setLoading,
  file,
  data,
  setFile,
  setErrors,
  changeStep,
  errors = [],
  channel,
}) => {
  const { requestCode, FEATURES } = UseTwoFactor();
  const [createNotification, setCreateNotification] = useState(false);
  const create = () => {
    if (!file)
      return showNotification(
        'warning',
        'Atenção',
        'Por favor, selecione um arquivo',
      );

    setLoading(true);
    api
      .post('/bonus-point/create', {
        data,
        notification: createNotification,
      })
      .then(() => {
        setFile(null);
        changeStep('validate');
        setLoading(false);
        return showNotification(
          'success',
          'Sucesso',
          'Pontos bonus criados com sucesso!',
        );
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };
  const fullArray = new Array(data, errors).flat();
  return (
    <>
      <S.Wrapper>
        <h2>
          Clique em enviar para processar o arquivo
          <br />
          <small>Essa ação é irreversível</small>
        </h2>
        <Form>
          <ToggleSwitch
            name="status"
            label="Enviar notificações?"
            defaultChecked={false}
            value={createNotification}
            onClick={() =>
              createNotification
                ? setCreateNotification(false)
                : setCreateNotification(true)
            }
          />
        </Form>
        <S.InputWrapper>
          <Button type="button" width="100%" onClick={create}>
            Enviar
          </Button>
          <Download
            rawData={fullArray}
            columns={channel == 9 ? columns_pap_pc : columns}
            fileName={`Relatório Inclusão - Pontos Bonus - ${new Date()}`}
            buttonName="Download"
            dropdownItems={false}
          />
        </S.InputWrapper>
      </S.Wrapper>
      <S.TableWrapper>
        <h2>Os dados abaixo serão processados ao enviar</h2>
        <Table
          columns={channel == 9 ? columns_pap_pc : columns}
          data={data}
          dataTableProps={{ pagination: true }}
        />
        {errors.length ? (
          <>
            <h2>Os dados abaixo contem erros e não serão processados</h2>
            <Table
              columns={channel == 9 ? errorColumnsPapPC : errorsColumns}
              data={errors}
              dataTableProps={{ pagination: true }}
            />
          </>
        ) : (
          ' '
        )}
      </S.TableWrapper>
    </>
  );
};

export default CreateTransactions;
