import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import Input from '~/components/NexAdmin/Inputs/Input';
import { Form } from '@unform/web';
import Button from '~/components/NexAdmin/Buttons/Button';
import AsyncSelect from '~/components/NexAdmin/Inputs/AsyncSelect';
import { transformArrayIntoSelectOptions } from '~/utils';
import Download from '~/screens/NexAdmin/Sales/Components/Download';
import DownloadHook from '~/hooks/NexAdmin/DownloadHook';
import { toDateTime } from '~/Helpers/Date';
import { appendObjectInFormData } from '~/utils';

import * as S from './styles';

const Filters = ({ getLandingPages, setLoading }) => {
  const getProducts = (inputValue, callback = () => {}) => {
    setLoading(true);
    api
      .get('/landing-page/products?query=' + inputValue)
      .then((response) => {
        setLoading(false);
        callback(
          transformArrayIntoSelectOptions(
            response.data,
            (item) => item.plano_nome,
            (item) => item.plano_id,
          ),
        );
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  const handleDownload = (data) => {
    const form = new FormData();
    appendObjectInFormData(form, {
      download: true,
      ...data,
    });
    const params = new URLSearchParams(form);
    return api.get(`/landing-page/admin/?${params}`, { responseType: 'blob' });
  };

  const buildReportName = (name) => {
    return `Relatório ${name} - Canal ${localStorage.getItem(
      'canalNome',
    )} - ${toDateTime(new Date())}`;
  };

  const beforeLoading = () => setLoading(true);

  const afterLoading = () => setLoading(false);

  const { execute, ref, urlFile, nameFile } = DownloadHook({
    externalCall: handleDownload,
    onError: HTTPErrorCallback,
    getFileName: buildReportName('Landing Pages'),
    beforeLoading,
    afterLoading,
  });

  return (
    <>
      <S.FormWrapper onSubmit={(data) => getLandingPages(data)}>
        <AsyncSelect
          label="Produtos:"
          name="products"
          isMulti
          placeholder="Selecione os produtos"
          loadOptions={getProducts}
        />
        <Input label="De:" name="start_date" type="date" />
        <Input label="Até:" name="end_date" type="date" />
        <Button type="submit">Filtrar</Button>
      </S.FormWrapper>
      <S.DownloadWrapper>
        <a href={urlFile} download={nameFile} ref={ref} />
        <Download buttonName="Download" external={execute} />
      </S.DownloadWrapper>
    </>
  );
};

export default Filters;
