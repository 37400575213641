import * as S from '../styles';

const CreateBannersDescription = () => {
  return (
    <S.ThermWrapper>
      <br />
      Para cadastrar um novo banner no slide dos participantes, preencha os
      campos disponíveis nessa tela. Segue abaixo o descritivo deles.
      <br />
      <br />
      <strong>Descrição:</strong> Título do banner.
      <br />
      <br />
      <strong>Ordem:</strong> Campo preenchido automaticamente com o código do
      banner. Este é sequencial e informa a quantidade de banners cadastrados.
      <br />
      <strong>Ativo:</strong> Status que determina se o banner está ativo ou
      não. O banner será mostrado no portal apenas quando estiver dentro do
      prazo informado nos campos <strong>Data Início</strong> e{' '}
      <strong>Data Fim</strong> e quando este estiver como{' '}
      <strong>Ativo</strong>, caso contrário, não será exibida no slide.
      <br />
      <strong>Data Início:</strong> Data que o banner deve começar a aparecer no
      portal.
      <br />
      <strong>Data Fim:</strong> Data que o banner deve parar de aparecer no
      portal.
      <br />
      <strong>Regional:</strong> Regionais que podem visualizar o banner,
      podendo ser todas ou uma específica.
      <br />
      <strong>Rede:</strong> Redes que podem visualizar o banner, podendo ser
      todas ou uma específica.
      <br />
      <strong>Cargo:</strong> Cargos que podem visualizar o banner, podendo ser
      todos ou um específico.
      <br />
      <strong>Desktop:</strong> Campo para upload da imagem que deve ser exibida
      no banner. A altura máxima do arquivo deve ser de até 440px. Caso este
      campo seja preenchido e o campo <strong>Mobile</strong> não, o sistema irá
      considerar para mobile a imagem deste campo.
      <br />
      <strong>Mobile:</strong> Campo para upload da imagem que deve ser exibida
      no banner. A altura máxima do arquivo deve ser de até 440px. Caso este
      campo não seja preenchido, o sistema irá considerar para mobile a imagem
      do campo <strong>Desktop</strong>.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default CreateBannersDescription;
