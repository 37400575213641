import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const ModalBackground = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100vh;
  width: 100%;
  margin: 0;
  top: 0;
  left: 0;
  z-index: 101;
  background: rgba(255, 255, 255, 0.6);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px;
  gap: 20px;
  border-radius: 10px;
  overflow-y: scroll;
  box-shadow: 0px 0px 20px 0px rgba(133, 133, 133, 0.3);
  ${media.lessThan('medium')`
        position: relative;
        top: 100px;
        width: calc(100vw - 30px);
        height: calc(100vh - 30px);
    `}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.colors.red};
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.grayDarker};
  text-align: center;
  font-size: 24px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const QuestionNumberWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
  text-align: center;
  font-size: 24px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const QuestionNumberTotal = styled.div`
  font-size: 14px;
`;

export const QuestionNumberCurrent = styled.div`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.red};
`;

export const Content = styled.div`
  width: fit-content;
  color: ${({ theme }) => theme.colors.grayDarker};
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const AnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  width: 100%;
  p {
    color: ${({ theme }) => theme.colors.grayLighter};
    text-align: center;
    font-size: 24px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const AnswerContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: ${({ theme, selected }) =>
    selected
      ? `2px solid ${theme.colors.red}`
      : `2px solid ${theme.colors.grayLighter}`};
  cursor: pointer;
`;

export const AnswerContentLetter = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 90px;
  border: ${({ theme, selected }) =>
    selected
      ? `2px solid ${theme.colors.red}`
      : `2px solid ${theme.colors.grayLighter}`};
  background: ${({ theme, selected }) =>
    selected ? theme.colors.red : theme.colors.grayLighter};
  color: ${({ theme, selected }) =>
    selected ? theme.colors.white : theme.colors.grayDarker};
`;

export const AnswerContent = styled.div`
  color: ${({ theme, selected }) =>
    selected ? theme.colors.red : theme.colors.grayDarker};
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  ${({ first }) =>
    first &&
    css`
      justify-content: flex-end;
    `}
`;
export const Button = styled.button`
  background: ${({ theme, prev }) =>
    prev ? theme.colors.white : theme.colors.degrade};
  color: ${({ theme, prev }) => (prev ? theme.colors.red : theme.colors.white)};
  border: ${({ theme, prev }) =>
    prev ? `2px solid ${theme.colors.red}` : 'none'};
  border-radius: 25px;
  width: 20%;
  height: 40px;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;
