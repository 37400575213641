import React from 'react';

import HeaderButton from '../HeaderButton/index';
import * as S from './styles';

const HeaderButtons = ({ view, setView }) => {
  return (
    <S.Container>
      <HeaderButton
        name="Criar campanha"
        type="create"
        view={view}
        setView={setView}
      />
      <HeaderButton
        name="Ver campanhas"
        type="campaigns"
        view={view}
        setView={setView}
      />
    </S.Container>
  );
};

export default HeaderButtons;
