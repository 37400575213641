import moment from 'moment';
import * as S from './styles';

const Confirm = ({
  dataInicio,
  dataFim,
  pontos,
  itens,
  lojaSelecionada,
  nomeUsuario,
  onYes,
  onNo,
}) => {
  return (
    <S.ConfirmWrapper>
      <S.Confirm>
        <S.CloseButton type="button" onClick={onNo}>
          <img src="/images/close-icon.png" alt="close button" />
        </S.CloseButton>
        <S.RedWrapper>
          <img src="/images/confirmCampain/notes.png" alt="notes icon" />
          Validar Campanha
        </S.RedWrapper>
        <S.Row>
          <S.Column>
            <S.TitleWrapper>
              <h2>Código da loja</h2>
            </S.TitleWrapper>
            {lojaSelecionada}
          </S.Column>
          <S.Column>
            <S.TitleWrapper>
              <h2>Nome do Vendedor</h2>
            </S.TitleWrapper>
            {nomeUsuario}
          </S.Column>
        </S.Row>
        <S.TitleWrapper>
          <h2>Planos Selecionados</h2>
        </S.TitleWrapper>
        <S.QuestionWrapper>
          {itens.map((i) => (
            <p>{i}</p>
          ))}
        </S.QuestionWrapper>
        <S.Row>
          <S.Column>
            <S.TitleWrapper>
              <h2>Período da Campanha</h2>
            </S.TitleWrapper>
            {`${moment(dataInicio).format('DD/MM/YYYY')} até ${moment(
              dataFim,
            ).format('DD/MM/YYYY')}`}
          </S.Column>
          <S.Column>
            <S.TitleWrapper>
              <h2>Total de Pontos</h2>
            </S.TitleWrapper>
            <p>{pontos}</p>
          </S.Column>
        </S.Row>
        <S.ButtonsContainer>
          <S.NoButton type="button" onClick={onNo}>
            <img src="/images/confirmCampain/arrow.svg" alt="back icon" />
            voltar
          </S.NoButton>
          <S.YesButton type="button" onClick={onYes}>
            Continuar
          </S.YesButton>
        </S.ButtonsContainer>
      </S.Confirm>
    </S.ConfirmWrapper>
  );
};

export default Confirm;
