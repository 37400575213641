import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: grid;
  width: 230px;
  height: 226px;
  grid-template-rows: 3fr 1fr;
  align-items: center;
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(133, 133, 133, 0.3);
`;

export const Header = styled.div`
  display: flex;
  padding: 20px 20px 10px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  height: 100%;
  flex: 1 0 0;
  align-self: stretch;
  background: var(--branco, #fff);
  box-shadow: 0px 0px 30px 0px rgba(133, 133, 133, 0.2);
`;

export const Tittle = styled.div`
  color: #484848;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Data = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.red};
  width: 100%;
  height: 100%;
`;

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  padding-right: 13px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
`;

export const ProgressBar = styled.div`
  height: 7px;
  width: 100%;
  background: '#f2f2f2';
  border: 1px solid #c4c4c4;
  border-radius: 100px;
`;
export const ProgressBarInner = styled.div`
  background: ${({ theme }) => theme.colors.red};
  border-radius: 100px;
  width: ${({ progress }) => `${progress}%`};
  height: 7px;
`;
export const ProgressText = styled.div`
  color: ${({ theme }) => theme.colors.red};
  font-size: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const ProgressWrapper = styled.div`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 7px;
`;

export const Status = styled.div`
  display: flex;
  flex-direction: row;
  gap: 7px;
  color: ${({ progress, theme }) =>
    progress === 100 ? theme.colors.green : theme.colors.grayMedium};
  font-size: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-items: center;
`;

export const Ball = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 999px;
  background: ${({ progress, theme }) =>
    progress === 100 ? theme.colors.green : theme.colors.grayMedium};
`;
