import React from 'react';

const HideOn = ({ color }) => {
  return (
    <svg
      width="26"
      height="18"
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9998 5.5C12.0716 5.5 11.1813 5.86875 10.525 6.52513C9.86859 7.1815 9.49984 8.07174 9.49984 9C9.49984 9.92826 9.86859 10.8185 10.525 11.4749C11.1813 12.1313 12.0716 12.5 12.9998 12.5C13.9281 12.5 14.8183 12.1313 15.4747 11.4749C16.1311 10.8185 16.4998 9.92826 16.4998 9C16.4998 8.07174 16.1311 7.1815 15.4747 6.52513C14.8183 5.86875 13.9281 5.5 12.9998 5.5ZM12.9998 14.8333C11.4527 14.8333 9.96901 14.2188 8.87505 13.1248C7.78109 12.0308 7.1665 10.5471 7.1665 9C7.1665 7.4529 7.78109 5.96917 8.87505 4.87521C9.96901 3.78125 11.4527 3.16667 12.9998 3.16667C14.5469 3.16667 16.0307 3.78125 17.1246 4.87521C18.2186 5.96917 18.8332 7.4529 18.8332 9C18.8332 10.5471 18.2186 12.0308 17.1246 13.1248C16.0307 14.2188 14.5469 14.8333 12.9998 14.8333V14.8333ZM12.9998 0.25C7.1665 0.25 2.18484 3.87833 0.166504 9C2.18484 14.1217 7.1665 17.75 12.9998 17.75C18.8332 17.75 23.8148 14.1217 25.8332 9C23.8148 3.87833 18.8332 0.25 12.9998 0.25Z"
        fill={color}
      />
    </svg>
  );
};
export default HideOn;
