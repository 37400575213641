import { useState } from 'react';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';
import Loading from '~/components/Loading';
import { InputUpload } from '~/components/NexAdmin/Inputs';
import * as S from './styles';
import { Form } from '@unform/web';
import Button from '~/components/NexAdmin/Buttons/Button';
import getIcon from '~/Helpers/getIcon';
import DownloadStaticFile from '~/components/NexAdmin/DownloadStaticFile';
import columns from './columns';
import { showNotification } from '~/utils/notificationBoxes';
import { HTTPErrorCallback } from '~/Helpers/Error';
import api from '~/services/api';
import Table from '~/components/NexAdmin/Table';
import moment from 'moment';

const model = [
  { CPF: 21938465701, STATUS: 'ATIVO', OBSERVACAO: '' },
  { CPF: 30192746582, STATUS: 'ATIVO', OBSERVACAO: '' },
  { CPF: 50318294673, STATUS: 'ATIVO', OBSERVACAO: '' },
  { CPF: 60391827564, STATUS: 'ATIVO', OBSERVACAO: '' },
  { CPF: 70418293645, STATUS: 'ATIVO', OBSERVACAO: '' },
  { CPF: 80517293826, STATUS: 'INATIVO', OBSERVACAO: '' },
  { CPF: 90617283547, STATUS: 'INATIVO', OBSERVACAO: '' },
];

const file_columns = [
  {
    name: 'CPF',
    selector: 'CPF',
  },
  {
    name: 'STATUS',
    selector: 'STATUS',
  },
  {
    name: 'OBSERVACAO',
    selector: 'OBSERVACAO',
  },
];

const BlockUsers = () => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState([]);

  const validate = () => {
    setErrors([]);
    setData([]);

    if (!file)
      return showNotification(
        'warning',
        'Atenção',
        'Por favor, selecione um arquivo',
      );

    setLoading(true);

    const formData = new FormData();

    formData.append('sheet', file);

    api
      .post('admin/usuario/validar-status-usuario', formData)
      .then((response) => {
        const { data, errors } = response.data;
        if (errors && errors.length) {
          setErrors(errors);
        }
        if (data && data.length) {
          setData(data);
        }
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const process = () => {
    setLoading(true);

    const formData = new FormData();

    formData.append('sheet', file);

    api
      .post('admin/usuario/alterar-status-usuario', formData)
      .then(() => {
        setLoading(false);
        showNotification(
          'success',
          'Sucesso',
          'Bloqueios/Desbloqueios efetuado com sucesso',
        );

        setData([]);
        setErrors([]);
        setFile(null);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag icon="actions" title="Bloqueio de Usuários" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <S.Wrapper>
              <h2>Selecione um arquivo para validação</h2>

              <S.InputWrapper>
                <Form>
                  <InputUpload
                    name="file"
                    onGetFile={(e) => {
                      setData([]);
                      setErrors([]);
                      setFile(e);
                    }}
                    placeholder={
                      <>{getIcon('upload')} Selecione um arquivo para upload</>
                    }
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </Form>
                <Button type="button" width="50%" onClick={validate}>
                  Validar
                </Button>
              </S.InputWrapper>

              {(data.length > 0 || errors.length > 0) && (
                <S.TableWrapper>
                  {errors.length > 0 && (
                    <>
                      <h2>
                        Os dados abaixo contem erros e não serão processados
                      </h2>
                      <Table
                        columns={[
                          ...columns,
                          {
                            name: 'Erro',
                            selector: 'error',
                            cell: (row) => (
                              <div title={row.error}>{row.error}</div>
                            ),
                            sortable: true,
                          },
                        ]}
                        downloadColumns={[
                          ...columns,
                          {
                            name: 'Erro',
                            selector: 'error',
                            cell: (row) => (
                              <div title={row.error}>{row.error}</div>
                            ),
                            sortable: true,
                          },
                        ]}
                        data={errors}
                        dataTableProps={{ pagination: true }}
                        header={true}
                        fileName={`erros_bloqueio_usuarios${moment().format(
                          'DD-MM-YYYY',
                        )}`}
                        hasDownload={true}
                      />
                    </>
                  )}
                  {data.length > 0 && (
                    <>
                      <h2>Os dados abaixo serão processados ao enviar</h2>
                      <Table
                        columns={columns}
                        downloadColumns={columns}
                        data={data}
                        dataTableProps={{ pagination: true }}
                        header={true}
                        fileName={`parcial_bloqueio_usuarios${moment().format(
                          'DD-MM-YYYY',
                        )}`}
                        hasDownload={true}
                      />
                      <Button type="button" width="50%" onClick={process}>
                        Processar
                      </Button>
                    </>
                  )}
                </S.TableWrapper>
              )}
            </S.Wrapper>

            <DownloadStaticFile
              columns={file_columns}
              list={model}
              file_name={'bloqueio_usuarios_modelo'}
              button_name={'Download Modelo'}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default BlockUsers;
