import { useState } from 'react';
import SideBar from '~components/NexAdmin/Sidebar';
import HeaderLeft from '~components/NexAdmin/HeaderLeft/index';
import HeaderRight from '~components/NexAdmin/HeaderRight/index';
import { ContentGrid, MainGrid } from './grids';

const Layout = ({ children }) => {
  const [opened, setOpened] = useState(true);
  const [hoverOpened, setHoverOpened] = useState(false);
  return (
    <>
      <MainGrid>
        <HeaderLeft
          hoverOpened={hoverOpened}
          opened={opened}
          setOpened={setOpened}
        />
        <HeaderRight />
        <SideBar
          hoverOpened={hoverOpened}
          setHoverOpened={setHoverOpened}
          opened={opened}
        />
        <ContentGrid>{children}</ContentGrid>
      </MainGrid>
    </>
  );
};

export default Layout;
