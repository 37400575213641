import styled from 'styled-components';

export const ConfirmWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${({ isComponent }) => (isComponent ? 'absolute' : 'fixed')};
  height: ${({ isComponent }) => (isComponent ? '100%' : '100vh')};
  width: 100%;
  margin: 0;
  top: 0;
  left: 0;
  z-index: ${({ isComponent }) => (isComponent ? '10' : '100')};
  background: rgba(255, 255, 255, 0.6);
`;
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Confirm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: rgb(250 250 250);
  padding: 50px 70px;
  width: 60vw;
  height: fit-content;
  border-radius: 10px;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  position: relative;

  .warning-image {
    width: 64px;
    height: 64px;
    margin-bottom: 20px;
  }
  p {
    font-size: 14px;
    color: #c50101;
  }
  @media (max-width: 720px) {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
  }
`;
export const RedWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #c50101;
  svg {
    width: 20px;
    margin-right: 10px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  background: #f2f2f2;
  padding: 12px 12px;
  border-radius: 50%;
  top: 15px;
  right: 15px;
  cursor: pointer;

  img {
    width: 15px;
    height: 15px;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;

  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
  }
`;

export const QuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  text-align: center;
  p {
    color: white;
    background-color: grey;
    line-height: 2rem;
    margin: 4px;
    margin-bottom: 20px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  max-height: 25%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 4rem;
`;
export const YesButton = styled.button`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
  width: 120px;
`;
export const NoButton = styled.button`
  background: #fff;
  border-radius: 25px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c50101;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
  width: 120px;
  justify-content: start;
  display: flex;
  img {
    margin-right: 1rem;
  }
`;
