import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import ConfirmButton from '~/components/NexAdmin/Buttons/ConfirmButton';

const Actions = ({ row, refresh, setLoading }) => {
  const deleteItem = (id) => {
    setLoading(true);
    api
      .delete(`/landing-page/${id}`)
      .then(() => {
        refresh();
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const restoreItem = (id) => {
    setLoading(true);
    api
      .put(`/landing-page/restore/${id}`)
      .then(() => {
        setLoading(false);
        refresh();
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <ConfirmButton
      icon={row.deleted_at ? 'back' : 'trash'}
      iconColor={row.deleted_at ? 'green' : 'red'}
      label="Tem certeza?"
      confirmLabel={row.deleted_at ? 'RESTAURAR' : 'DELETAR'}
      cancelLabel="CANCELAR"
      confirmAction={() =>
        row.deleted_at ? restoreItem(row.id) : deleteItem(row.id)
      }
    />
  );
};

export default Actions;
