import { useContext } from 'react';
import { GlobalModalStructureContext } from '../contexts/GlobalModalStructureContext';

const UseGlobalModalStructure = () => {
  const {
    modalStructureProps,
    addGlobalModalStructure,
    removeGlobalModalStructure,
  } = useContext(GlobalModalStructureContext);
  return {
    modalStructureProps,
    addGlobalModalStructure,
    removeGlobalModalStructure,
  };
};

export default UseGlobalModalStructure;
