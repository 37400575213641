import styled from 'styled-components';

export const CounterWrapper = styled.div`
  margin-top: 20px;
  span {
    color: ${({ theme }) => theme.admincolors.contrast.black};
    font-size: ${({ theme }) => theme.adminfontsize.text};
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
  }
`;
