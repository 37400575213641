import * as S from '../styles';
const ListPostDescription = () => {
  return (
    <S.ThermWrapper>
      <br />
      Essa tela permite a visualização dos posts realizadas no Giga Line dos
      participantes. Se necessário, é possível realizar a exclusão de um post
      clicando no <strong>Deletar</strong>.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default ListPostDescription;
