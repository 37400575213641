import React from 'react';
import { useHistory } from 'react-router-dom';

import Wishes from '~/screens/Calculator/components/whishes';

import * as S from './styles';

const Wishlist = () => {
  const history = useHistory();
  return (
    <S.Container>
      <S.Header>
        <S.Title>Favoritos</S.Title>
      </S.Header>
      <Wishes />
      <S.Button href="#" onClick={() => history.push('/calculadora')}>
        Ver mais
      </S.Button>
    </S.Container>
  );
};

export default Wishlist;
