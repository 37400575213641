const AparelhosColumnsDesc = () => {
  return (
    <>
      <br />
      <strong>1. Código Venda:</strong> Código ID da venda. Valor único em toda
      a tabela.
      <br />
      <strong>2. Código Loja:</strong> Código da loja.
      <br />
      <strong>3. Data da Venda:</strong> Data que a venda foi efetuada.
      <br />
      <strong>4. CPF:</strong> CPF do usuário.
      <br />
      <strong>5. Nome:</strong> Nome do usuário.
      <br />
      <strong>6. Cargo:</strong> Cargo do usuário.
      <br />
      <strong>7. Regional:</strong> Regional do usuário.
      <br />
      <strong>8. SAP:</strong> Código SAP fornecido pelo cliente.
      <br />
      <strong>9. TIER:</strong> Código TIER fornecido pelo cliente.
      <br />
      <strong>10. Modelo:</strong> Modelo do produto.
      <br />
      <strong>11. Tipo:</strong> Tipo do produto.
      <br />
      <strong>12. Login Rede:</strong> Login da rede. Fornecido pelo cliente.
      <br />
      <strong>13. Pontos Vendas:</strong> Pontos gerados pela venda.
      <br />
      <strong>14. Pontos Bônus:</strong> Pontos bônus gerado pela venda, se
      aplicável.
      <br />
      <strong>15. Venda Status:</strong> Status da venda.
      <br />
      <strong>16. Data Venda:</strong> Data da venda.
    </>
  );
};

export default AparelhosColumnsDesc;
