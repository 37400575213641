import styled from 'styled-components';

export const FormFilters = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 20px;
  max-width: 80%;
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  button {
    margin-top: auto;
  }
`;

export const ButtonsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  grid-gap: 20px;
  max-width: 80%;
`;

export const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 20px;
  padding: 50px 10px;
`;

export const DownloadWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding-top: 20px;
`;
