/* eslint-disable indent */
import { useEffect, useState } from 'react';
import { isMobile, isBrowser } from 'react-device-detect';

import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import { limitCharactersOfString } from '~/utils';
import { HTTPErrorCallback } from '~/Helpers/Error';
import Avatar from '../Avatar';
import Button from '../Button';
import Loading from '../Loading';
import * as S from './styles';
import UseTwoFactor from '~/hooks/UseTwoFactor';

const badges = [
  {
    id: 1,
    name: 'Bárbaro',
    slug: 'barbaro',
    description: 'de 0 a 49 pontos',
    thumb: '/images/badges/1.png',
  },
  {
    id: 2,
    name: 'Arqueiro',
    slug: 'arqueiro',
    description: 'de 55 a 99 pontos',
    thumb: '/images/badges/2.png',
  },
  {
    id: 3,
    name: 'Guerreiro',
    slug: 'guerreiro',
    description: 'de 100 a 299 pontos',
    thumb: '/images/badges/3.png',
  },
  {
    id: 4,
    name: 'Cavaleiro',
    slug: 'cavaleiro',
    description: 'de 300 a 499 pontos',
    thumb: '/images/badges/4.png',
  },
  {
    id: 5,
    name: 'Paladino',
    slug: 'paladino',
    description: 'de 500 a 699 pontos',
    thumb: '/images/badges/5.png',
  },
  {
    id: 6,
    name: 'Mago',
    slug: 'mago',
    description: 'de 700 a 999 pontos',
    thumb: '/images/badges/6.png',
  },
  {
    id: 7,
    name: 'Rei',
    slug: 'rei',
    description: 'a partir de 1.000 pontos',
    thumb: '/images/badges/7.png',
  },
];

const ProfileRank = () => {
  const [loading, setLoading] = useState(false);
  const [foto, setFoto] = useState('');
  const [name, setName] = useState('');
  const [rede, setRede] = useState('');
  const [cargo, setCargo] = useState('');
  const [pontos, setPontos] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [pontosPendentes, setPontosPendentes] = useState('');
  const [currentBadgeIndex, setCurrentBadgeIndex] = useState(0);
  const { requestCode, FEATURES, setCodeLoading } = UseTwoFactor();

  useEffect(() => {
    if (parseJwt().id) {
      api.get(`/buscarsaldo/${parseJwt().id}`).then((response) => {
        setFoto(response.data.data.foto);
        setName(limitCharactersOfString(response.data.data.usu_nome, 25));
        setRede(response.data.data.usu_rede);
        setCargo(response.data.data.cargo_nome);
        setPontos(response.data.data.saldo || 0);
        setPontosPendentes(response.data.data.pendente || 0);
      });

      api.get(`/badge/${parseJwt().id}`).then((response) => {
        badges.forEach((badge, index) => {
          if (response.data.data.badge === badge.slug) {
            setCurrentBadgeIndex(index + 1);
          }
        });
      });
    }
  }, []);

  const ssoStore = () => {
    requestCode(FEATURES['common-premmiar-sso'], (digits, setOpen) => {
      setLoading(true);
      setCodeLoading(true);
      api
        .get(`/loja/sso/${digits || null}`)
        .then((response) => {
          setOpen(false);
          setLoading(false);
          setCodeLoading(false);
          window.open(response.data.data);
        })
        .catch((error) => {
          setLoading(false);
          setCodeLoading(false);
          HTTPErrorCallback(error);
        });
    });
  };

  const formatter = new Intl.NumberFormat(['ban', 'id'], {
    maximumFractionDigits: 2,
  });

  const getCargo = (cargoString) => {
    let result;

    if (localStorage.getItem('canal') === '5' && parseJwt().cargo === 14) {
      result = 'PDC';
    } else {
      result = cargoString;
    }

    return result;
  };

  const getRede = (redeString) => {
    let result;
    if (
      (localStorage.getItem('canal') === '2' && parseJwt().cargo === 5) ||
      [8, 9, '8', '9'].includes(localStorage.getItem('canal'))
    ) {
      result = '';
    } else {
      result = redeString;
    }

    return result;
  };

  return (
    <S.Wrapper>
      {loading && <Loading />}
      <S.Container>
        <S.UserContainer>
          <Avatar rank foto={foto || '/images/blank-profile.png'} />
          <div>
            <h1>{name}</h1>
            {![79, 85].includes(parseJwt().cargo) && <p>{getRede(rede)}</p>}
            <p>{getCargo(cargo)}</p>
          </div>
        </S.UserContainer>
        {parseJwt().cargo !== 20 && parseJwt().cargo !== 73 && (
          <S.InfoContainer>
            {parseJwt().canal_id !== 7 || parseJwt().id === 1025252 ? (
              <>
                <p>Seu total de pontos</p>
                <p>{formatter.format(pontos)} Pontos</p>
              </>
            ) : (
              ' '
            )}
            {parseJwt().canal_id !== 7 &&
              parseJwt().canal_id !== 8 &&
              parseJwt().canal_id !== 2 && (
                <>
                  <p>Pontos em validação</p>
                  <p>{formatter.format(pontosPendentes)} Pts</p>
                </>
              )}
          </S.InfoContainer>
        )}

        {parseJwt().cargo === 20 && (
          <S.InfoContainer>
            <p>Para visualizar seus pontos disponíveis para distribuição</p>
            <p>
              <a href="/bolsao-do-gn">Clique aqui</a>
            </p>
          </S.InfoContainer>
        )}

        {parseJwt().cargo === 73 && (
          <S.InfoContainer>
            <p>Para visualizar seus pontos disponíveis para distribuição</p>
            <p>
              <a href="/bolsao-do-gn">Clique aqui</a>
            </p>
          </S.InfoContainer>
        )}

        {/* ID de algum canal que queura evitar o acesso */}
        {isMobile &&
        (![5, 7].includes(parseJwt().canal_id) || parseJwt().id === 1025252) ? (
          <Button to="#" onClick={() => ssoStore()}>
            Resgatar produtos
          </Button>
        ) : (
          ' '
        )}
      </S.Container>
      {/* <S.LatestAchievements>
        <p>Últimas conquistas:</p>
        <ul>
          {badges.map((badge) => (
            <li key={badge.id}>
              <div className="tooltip">
                {badge.name}

                <span>{badge.description}</span>
              </div>
              <img
                src={badge.thumb}
                alt="badges 0"
                className={
                  badge.id !== currentBadgeIndex ? 'opacity' : 'current'
                }
              />
            </li>
          ))}
        </ul>
        {isBrowser ? (
          <Button to="/meu-perfil#achievements">Entenda suas conquistas</Button>
        ) : null}
      </S.LatestAchievements> */}
    </S.Wrapper>
  );
};

export default ProfileRank;
