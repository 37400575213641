import React from 'react';

import CampaignsList from './CamapignsList';
import * as S from './styles';

const DigitalCampaigns = () => {
  return (
    <S.Container>
      <S.Header>
        <S.Title>Campanhas</S.Title>
      </S.Header>
      <CampaignsList />
    </S.Container>
  );
};

export default DigitalCampaigns;
