import * as S from '../styles';
const CreateChallengeDescription = () => {
  return (
    <S.ThermWrapper>
      <br />
      Para cadastrar um novo desafio aos participantes, preencha os campos
      disponíveis nessa tela. Segue abaixo o descritivo deles.
      <br />
      <br />
      <strong>Título</strong>: Nome do desafio
      <br />
      <strong>Produtos participantes</strong>: Descrição dos produtos que fazem
      parte do programa. Necessário separar os nomes com vírgula. Por exemplo:
      produto, produto, produto.
      <br />
      <strong>Data Início</strong>: Data de início do desafio.
      <br />
      <strong>Data Fim</strong>: Data de término do desafio.
      <br />
      <strong>Canal</strong>: Subcanais de Digital que fazem parte do desafio.
      <br />
      <br />
      Para anexar um regulamento ao desafio, selecione o arquivo desejado abaixo
      do bloco de texto.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default CreateChallengeDescription;
