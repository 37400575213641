import * as S from './styles';
import { useEffect, useState } from 'react';
import CarouselSection from './Carousel';
import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import Loading from '~/components/Loading';

const MediaCampaigns = () => {
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState({
    all: [],
  });

  useEffect(() => {
    getCampaigns();
  }, []);

  const getCampaigns = () => {
    api
      .get(`/media-campaign/`)
      .then((response) => {
        setCampaigns(response.data);
      })
      .catch((error) => {
        return HTTPErrorCallback(error);
      });
  };

  return (
    <>
      {Array.isArray(campaigns?.all) && campaigns.all.length > 0 && (
        <S.Wrapper>
          <S.Title>Campanhas de Mídia</S.Title>

          <CarouselSection campaigns={campaigns.all} title="Todas" />
        </S.Wrapper>
      )}
    </>
  );
};

export default MediaCampaigns;
