import * as S from '../styles';
const CreatePostDescription = () => {
  return (
    <S.ThermWrapper>
      <br />
      Para publicar um novo post no Giga Line para os participantes, preencha o
      campo abaixo com o texto desejado.
      <br />
      <br />
      Se necessário aplicar formatações especiais, pode-se usar as opções
      disponíveis como negrito, itálico e sublinhado, por exemplo.
      <br />
      <br />
      Para anexar uma imagem ao post, selecione o arquivo desejado abaixo do
      bloco de texto. No lado direito da tela temos o <strong>Preview</strong>,
      que permite visualizar como o post será exibido aos participantes.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default CreatePostDescription;
