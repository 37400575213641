import styled from 'styled-components';
import media from 'styled-media-query';

export const RegulationWrapper = styled.section`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  padding: 30px 50px;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
`;

export const RegulationPageTitle = styled.h2`
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.colors.red};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  gap: 10px;
`;

export const RegulationContainer = styled.div`
  iframe {
    margin-top: 30px;
    body {
      background-color: transparent !important;
    }
  }

  .desafio {
    height: 350px;
    overflow: auto;
  }
`;

export const RegulationButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;

export const RegulationButton = styled.button`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;

  margin-bottom: 20px;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh);
  width: calc(100vw);

  background-color: #00000090;
  iframe {
    height: 50vh;
  }

  z-index: 100;
  iframe {
  }
  h2 {
    color: red;
  }
`;

export const CloseBtn = styled.div`
  position: absolute;
  right: 12px;
  top: 40px;
`;

export const Modal = styled.div`
  margin-top: -80px;
  border-radius: 20px;
  max-height: 80vh;
  max-width: 90vw;
  width: 1080px;
  position: relative;
`;

export const Wrapper = styled.div`
  position: relative;
  opacity: ${(props) => (props.inactive ? '0.3' : '1')};
  border: ${(props) =>
    props.complete ? '2px solid #C50101' : '0px solid #C5010100'};
  .complete {
    position: absolute;
    top: -50px;
    right: 0px;
    height: auto;
  }
  background-color: #fff;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 0px 30px rgba(133, 133, 133, 0.2);
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  button {
    width: 100% !important;
  }

  h2 {
    font-size: 1.6rem;
    color: #c50101;
    font-weight: 500;
    text-align: left;
  }
  width: 100vw;

  margin: 20px 0px;

  ${media.greaterThan('medium')`
width: 100%;
`};
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  padding: 0px 18px 30px;
`;
export const Image = styled.div`
  width: 100%;
  height: 140px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background: linear-gradient(178.18deg, #ff0000 -13.56%, #760000 158.3%);
  color: white;

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
`;
export const ProgressBar = styled.div`
  height: 10px;
  width: 100%;
  background: #f2f2f2;
  border: 1px solid #c4c4c4;
  border-radius: 100px;
`;
export const ProgressBarInner = styled.div`
  background: linear-gradient(178.18deg, #ff0000 -13.56%, #760000 158.3%);
  border-radius: 100px;
  width: ${(props) => `${props.progress}%`};
  height: 10px;
`;
export const ProgressText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const ProgressWrapper = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
  width: 100%;
`;
export const UlTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;
export const Text = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
`;

export const DangerouslySetInnerHTML = styled.div`
  width: 100%;
  height: 50px;
`;
