import * as S from './styles';
import { Button } from '~/components/NexAdmin/Buttons';
import { useRef, useState } from 'react';
import Box from './Box';
import Loading from '~/components/Loading';
import { HTTPErrorCallback } from '~/Helpers/Error';
import api from '~/services/api';
import { showNotification } from '~/utils/notificationBoxes';

const Update = ({ row, hideModal, refresh }) => {
  const formRef = useRef([]);
  const [question, setQuestion] = useState(row.questions);
  const [loading, setLoading] = useState(false);

  const deleteQuestion = (index) => {
    if (question.length && index < question.length) {
      const remove = question.filter((_, i) => i !== index);
      setQuestion(remove);
    }
  };
  const handleSubmit = () => {
    setLoading(true);
    const data = formRef.current;
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      console.log(data[i].getData());
      arr.push(data[i].getData());
    }
    api
      .put(`/quizzes/admin/question`, arr)
      .then((response) => {
        setLoading(false);
        showNotification('success', 'Sucesso', response.data.message);
        hideModal();
        refresh();
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && Loading}
      <S.Wrapper>
        <Button onClick={() => handleSubmit()} style={{ width: '20%' }}>
          Salvar
        </Button>
        {Array.isArray(question) &&
          question.map((item, i) => (
            <Box
              question={item}
              position={i}
              key={i}
              anwser={item.anwser}
              formRef={formRef}
              deleteQuestion={deleteQuestion}
            />
          ))}
      </S.Wrapper>
    </>
  );
};

export default Update;
