export const parseJwt = () => {
  const token =
    localStorage.getItem('userToken') || localStorage.getItem('adminUserToken');
  if (!token) return false;
  const base64Url = token?.split('.')[1];
  const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
  const binary = window.atob(base64);
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < binary.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  const decoder = new TextDecoder('utf-8');
  const decoded = decoder.decode(bytes);
  return base64 ? JSON.parse(decoded) : {};
};
