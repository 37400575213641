import * as S from './styles';

const OrdersReportDescription = () => {
  return (
    <S.ThermWrapper>
      <br />
      Esta tela exibe todos os pedidos que foram feitos pelos usuários do canal.
      <br />
      <br />
      Personalize sua busca pelos botões de filtros na tela e então clique em
      “Consultar”.
      <br />
      <br />
      Caso queria fazer o download do relatório, primeiro execute o passo acima
      e, após isso, clique no botão “Download”.
      <br />
      <br />
      Após os botões de filtro e botões de ação, os dados são exibidos em uma
      tabela. É descrito a quantidade de pedidos que foram encontrados em sua
      busca e se todos estão sendo exibidos ou não.
      <br />
      <br />
      Caso sua pesquisa encontre mais de 100 resultados, é necessário que faça o
      download do relatório para visualizar todos os produtos encontrados.
      <br />
      <br />
      <strong>Descritivo dos filtros:</strong>
      <br />
      <strong>De:</strong> Utilize para indicar uma data mínima.
      <br />
      <strong>Até:</strong> Utilize para indicar uma data máxima.
      <br />
      <strong>CPF</strong> Utilize para buscar todos os pedidos de um único
      usuário pelo seu CPF.
      <br />
      <strong>Código do Pedido: </strong> Utilize para buscar um único pedido
      pelo seu ID.
      <br />
      <strong>Status: </strong> Utilize para buscar todos os pedidos, de todos
      os usuários, que se encontram em determinado status, sendo eles: pendente
      de confirmação, confirmado, cancelado e estornado. A opção “Todos” busca
      os pedidos independente de seu status.
      <br />
      <br />
      <strong>Descritivo das colunas da tabela:</strong>
      <br />
      <strong>1. ID do Pedido:</strong> Código ID do pedido. Valor único em toda
      a tabela.
      <br />
      <strong>2. Data do Pedido:</strong> Data que o pedido foi feito pelo
      usuário.
      <br />
      <strong>3. Hora do Pedido:</strong> Hora que o pedido foi feito pelo
      usuário.
      <br />
      <strong>4. Status:</strong> Status atual do pedido.
      <br />
      <strong>5. Fornecedor:</strong> Fornecedor do produto.
      <br />
      <strong>6. Produto:</strong> Informação detalhada do produto feito no
      pedido.
      <br />
      <strong>7. ID Usuário:</strong> Código ID do usuário. Valor único em toda
      a tabela.
      <br />
      <strong>8. Nome:</strong> Nome do usuário.
      <br />
      <strong>9. CPF:</strong> CPF do usuário.
      <br />
      <strong>10. E-mail:</strong> Email do usuário
      <br />
      <strong>11. Endereço:</strong> Endereço do usuário
      <br />
      <strong>12. Número:</strong> Número residencial do usuário
      <br />
      <strong>13. Complemento:</strong> Complemento residencial do usuário, se
      houver.
      <br />
      <strong>14. CEP:</strong> CEP do usuário.
      <br />
      <strong>15. Bairro:</strong> Bairro do usuário.
      <br />
      <strong>16. Cidade:</strong> Cidade residencial do usuário.
      <br />
      <strong>17. Estado:</strong> Estado residencial do usuário.
      <br />
      <strong>18. Regional:</strong> Regional de atuação do usuário.
      <br />
      <strong>19. PDV:</strong> Ponto de Venda do usuário.
      <br />
      <strong>20. Rede:</strong> Rede do PDV do usuário.
      <br />
      <strong>21. Data do Estorno:</strong> Data do estorno do pedido, se
      houver.
      <br />
      <strong>22. Hora do Estorno:</strong> Hora do estorno do pedido, se
      houver.
      <br />
      <strong>23. Valor Produto Ponto:</strong> Valor do produto em pontos do
      GigaClube.
      <br />
      <strong>24. Valor Frente Ponto:</strong> Valor do frete do pedido em
      pontos do GigaClube.
      <br />
      <strong>25. Valor Frente Real:</strong> Valor do frete do pedido em reais.
      <br />
      <strong>26. Valor Cash Ponto:</strong> É o valor extra pago em dinheiro
      pra completar um resgate.
      <br />
      <strong>27. Valor Cash Real:</strong> É o valor extra pago em dinheiro pra
      completar um resgate.
      <br />
      <strong>28. Valor Total Ponto:</strong> Valor total do pedido em pontos do
      GigaClube.
      <br />
      <strong>29. Valor Total Real:</strong> Valor total do pedido em reais.
      <br />
      <strong>30. Valor Estorno Ponto:</strong> Valor do estorno em pontos do
      GigaClube, se houver.
      <br />
      <strong>31. Valor Estorno Real:</strong> Valor do estorno em reais, se
      houver.
      <br />
      <strong>32. Valor Final do Pedido Ponto:</strong> Valor final do
      pedido(produto + frete) em pontos do GigaClube.
      <br />
      <strong>33. Valor Final do Pedido Real:</strong> Valor final do
      pedido(produto + frete) em reais.
      <br />
      <strong>34. Valor Faturado Ponto:</strong> Valor faturado em pontos do
      GigaClube.
      <br />
      <strong>35. Valor Faturado Real:</strong> Valor faturado em reais.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default OrdersReportDescription;
