import React, { useEffect, useRef, useState } from 'react';
import Carousel from 'react-elastic-carousel';

import api from '~/services/api';
import { parseJwt } from '~/services/auth';

import * as S from './styles';

const Banner = () => {
  const [banners, setBanners] = useState([]);
  const carouselRef = useRef(null);

  useEffect(() => {
    api
      .get(`/buscar-banners/${parseJwt().id}`)
      .then((response) => {
        setBanners(response.data.banners);
      })
      .catch(() => {});
  }, []);
  const reachLastIndex = (index) => {
    if (index === banners.length - 1) {
      setTimeout(() => {
        carouselRef.current?.goTo(0);
      }, 5000);
    }
  };
  return (
    <S.Container>
      {banners.length > 0 ? (
        <Carousel
          ref={carouselRef}
          enableAutoPlay
          enableSwipe
          onNextEnd={(e) => reachLastIndex(e.index)}
          autoPlaySpeed={5000}
          showArrows={false}
          enableMouseSwipe={false}
        >
          {banners.map((banner) => (
            <a href="/dashboard">
              <img src={banner.dest_imagem} alt={banner.dest_desc} />
            </a>
          ))}
        </Carousel>
      ) : (
        <></>
      )}
    </S.Container>
  );
};

export default Banner;
