import styled from 'styled-components';

export const Container = styled.div`
  grid-area: aboutCampaign;
  background-color: white;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgb(133 133 133 / 30%);
  position: relative;
  .rows {
    margin-top: 20px;
  }
  & > * {
    box-shadow: none;
  }
  overflow: hidden;
  padding: 17px 15px;
  max-height: 400px;
`;
export const MoreButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 200px;
  padding-top: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -15px;
  transition: box-shadow 0.2s;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 55%,
    rgba(255, 255, 255, 1) 100%
  );
  z-index: 10;
`;
export const MoreButton = styled.button`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const Title = styled.p`
  margin-left: 5px;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  color: ${({ theme }) => theme.colors.red};
  font-weight: ${({ theme }) => theme.font.medium};
  margin-bottom: 20px;
`;

export const About = styled.div`
  margin-top: -20px;
  & > * {
    box-shadow: none;
    padding: 0 20px;
  }
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
