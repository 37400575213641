import styled from 'styled-components';

export const Container = styled.div`
  margin-right: 30px;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Message = styled.h1``;
