import styled from 'styled-components';
import media from 'styled-media-query';

export const ModalWrapper = styled.div`
  z-index: 999;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgb(0, 0, 0, 0.5);
  padding: 16px;

  ${media.lessThan('960px')`
    padding: 5px;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px;
  gap: 24px;
  width: 664px;
  height: fit-content;
  background: #ffffff;
  border-radius: 32px;
  opacity: 1;

  form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    button {
      align-self: flex-end;
    }
  }

  ${media.lessThan('960px')`
    padding: 35px;
    gap: 35px;
    max-height: 90vh;
    max-width: 90vw;
    overflow-x: auto;
      form{
    gap: 30px;

    button {
      width: 100% !important;
    }
  }
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    cursor: pointer;
    width: 24px;
  }
`;

export const Title = styled.h1`
  font-family: Poppins;
  font-weight: 700;
  color: #d60000;
  font-size: 24px;
`;

export const Description = styled.p`
  font-size: 16px;
  color: #415a6c;
`;

export const FormSection = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.i === 0 ? '1fr 2fr 1fr' : '2fr 2fr 1fr 2fr'};
  grid-gap: 16px;

  #number::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ${media.lessThan('960px')`
    grid-template-columns: 1fr;
  `}
`;

export const FormCep = styled.div`
  display: flex;
  align-items: center;
`;
export const ButtonSearchCep = styled.div`
  margin-top: 23px;
  margin-left: -30px;
  cursor: pointer;
`;

export const FormNumber = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .sc-fJExmy {
    margin-top: 20px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: flex-end;
`;

export const Button = styled.button`
  background: linear-gradient(178.18deg, #ff0000 -13.56%, #760000 158.3%);
  border-radius: 25px;
  border: 0;
  padding: 10px 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
`;
