import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Card from '../../Components/Card';
import * as S from './styles';
import Carousel from 'react-elastic-carousel';
import api from '~/services/api';
import Loading from '~/components/Loading';
import { HTTPErrorCallback } from '~/Helpers/Error';

const QuizzesMain = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [quiz, setQuiz] = useState(
    location.state?.quiz ? location.state?.quiz : [],
  );
  const [partial, setPartial] = useState(
    quiz?.filter((item) => item.progress > 0 && item.progress < 100),
  );
  const [notStarted, setNotStarted] = useState(
    quiz?.filter((item) => item.progress == 0),
  );
  const [completed, setCompleted] = useState(
    quiz?.filter((item) => item.progress == 100),
  );
  useEffect(async () => {
    if (!quiz.length) {
      setLoading(true);
      try {
        const response = await api.get('/quizzes/user/quiz');
        const quiz = response.data;
        setQuiz(quiz);
        setPartial(
          quiz?.filter((item) => item.progress > 0 && item.progress < 100),
        );
        setNotStarted(quiz?.filter((item) => item.progress === 0));
        setCompleted(quiz?.filter((item) => item.progress === 100));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        return HTTPErrorCallback(error);
      }
    }
  }, []);
  return (
    <>
      {loading && <Loading />}
      <S.Container>
        <S.Title>Treinamentos</S.Title>
        <S.Content>
          Bem-vindo à seção de treinamentos online do nosso site! Aqui, você
          encontrará uma plataforma dinâmica e interativa, projetada para
          capacitar você a adquirir novas habilidades e alcançar seus objetivos
          profissionais e pessoais. Neste texto descritivo, vamos destacar os
          recursos e benefícios exclusivos que oferecemos para tornar sua
          jornada de aprendizado online uma experiência estimulante e
          gratificante.
        </S.Content>
        {quiz.length > 0 && (
          <>
            <S.Title>Todos</S.Title>
            <S.WishScrollArea>
              <S.WishContainer>
                <Carousel
                  itemPadding={[10, 20]}
                  breakPoints={[
                    { width: 1, itemsToShow: 1 },
                    { width: 550, itemsToShow: 3 },
                    { width: 1050, itemsToShow: 4 },
                  ]}
                >
                  {quiz.map((item) => (
                    <Card quiz={item} />
                  ))}
                </Carousel>
              </S.WishContainer>
            </S.WishScrollArea>
          </>
        )}
        {partial.length > 0 && (
          <>
            <S.Title>Iniciados</S.Title>
            <S.WishScrollArea>
              <S.WishContainer>
                <Carousel
                  itemPadding={[10, 20]}
                  breakPoints={[
                    { width: 1, itemsToShow: 1 },
                    { width: 550, itemsToShow: 3 },
                    { width: 1050, itemsToShow: 4 },
                  ]}
                >
                  {partial.map((item) => (
                    <Card quiz={item} />
                  ))}
                </Carousel>
              </S.WishContainer>
            </S.WishScrollArea>
          </>
        )}
        {notStarted.length > 0 && (
          <>
            <S.Title>Não iniciados</S.Title>
            <S.WishScrollArea>
              <S.WishContainer>
                <Carousel
                  itemPadding={[10, 20]}
                  breakPoints={[
                    { width: 1, itemsToShow: 1 },
                    { width: 550, itemsToShow: 3 },
                    { width: 1050, itemsToShow: 4 },
                  ]}
                >
                  {notStarted.map((item) => (
                    <Card quiz={item} />
                  ))}
                </Carousel>
              </S.WishContainer>
            </S.WishScrollArea>
          </>
        )}
        {completed.length > 0 && (
          <>
            <S.Title>Concluídos</S.Title>
            <S.WishScrollArea>
              <S.WishContainer>
                <Carousel
                  itemPadding={[10, 20]}
                  breakPoints={[
                    { width: 1, itemsToShow: 1 },
                    { width: 550, itemsToShow: 3 },
                    { width: 1050, itemsToShow: 4 },
                  ]}
                >
                  {completed.map((item) => (
                    <Card quiz={item} />
                  ))}
                </Carousel>
              </S.WishContainer>
            </S.WishScrollArea>
          </>
        )}
      </S.Container>
    </>
  );
};

export default QuizzesMain;
