import { useState, React } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';

import * as S from './styles';

const DataInput = ({ title, setValue }) => {
  const [startDate, setStartDate] = useState();

  return (
    <S.Wrapper>
      <h1>{`${title}:`}</h1>
      <S.Input>
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            setValue(new Date(date));
          }}
          locale={ptBR}
          dateFormat="dd/MM/yyyy"
        />
      </S.Input>
    </S.Wrapper>
  );
};

export default DataInput;
