import React, { useState } from 'react';
import Button from '~/components/Button';

import * as S from './styles';

import api from '~/services/api';
import { showNotification } from '~/utils/notificationBoxes';
import ConfirmCancel from '../ConfirmCancel';

export default function TableDetails({
  id,
  description,
  period,
  points,
  motivo,
  status,
  getCampaigns,
  campaign,
  setSelectedCampaign,
}) {
  const [confirmModal, setConfirmModal] = useState(false);

  const cancel = async () => {
    api
      .post(`/gn/varejo/user/campagn/cancel`, {
        id,
      })
      .then((response) => {
        showNotification(
          'success',
          'Sucesso',
          'Campanha cancelada com sucesso',
        );
        setConfirmModal(false);
        getCampaigns();
      })
      .catch(() =>
        showNotification(
          'danger',
          'Atenção',
          'Houve um erro ao cancelar a campanha',
        ),
      );
  };
  return (
    <>
      {confirmModal && (
        <ConfirmCancel
          onYes={() => cancel()}
          onNo={() => setConfirmModal(false)}
        />
      )}
      <S.ExpandableRow colSpan="7">
        <S.BorderTop />
        <S.StyledDiv>
          <strong>Descrição do desafio:</strong>
          {description}
        </S.StyledDiv>
        <S.StyledDiv>
          <strong>Período:</strong>
          {period}
        </S.StyledDiv>
        <S.StyledDiv>
          <strong>Pontos:</strong>
          {points}
        </S.StyledDiv>

        {motivo ? (
          <S.StyledDiv>
            <strong>Motivo recusa:</strong>
            {motivo}
          </S.StyledDiv>
        ) : (
          ' '
        )}
        {status === 'Pendente' && (
          <S.Buttons>
            <Button
              to="#"
              type="button"
              onClick={() => setSelectedCampaign(campaign)}
            >
              Editar
            </Button>
            <Button to="#" type="button" onClick={() => setConfirmModal(true)}>
              Cancelar
            </Button>
          </S.Buttons>
        )}
      </S.ExpandableRow>
    </>
  );
}
