import { useState, useEffect } from 'react';

import * as S from './styles';

const Pagination = ({ totalCount, limit, setCurrentPage, currentPage }) => {
  const [buttons, setButtons] = useState([]);

  useEffect(() => {
    const totalButtonsCount = totalCount / limit;

    const buttonsArray = [];

    for (let i = 0; i < totalButtonsCount; i += 1) {
      buttonsArray.push({ value: i + 1, label: i + 1 });
    }

    setButtons(buttonsArray);
  }, [totalCount, limit]);
  return (
    <S.PageButtonsWrapper>
      {buttons.map((button) => (
        <S.PageButton
          className={`btn btn-info ${
            currentPage === button.value ? 'active' : ''
          }`}
          key={button.value}
          onClick={() => setCurrentPage(button.value)}
          type="button"
        >
          {button.label}
        </S.PageButton>
      ))}
    </S.PageButtonsWrapper>
  );
};

export default Pagination;
