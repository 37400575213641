import styled from 'styled-components';

export const Inputs = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ChannelSelect = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
  margin-top: 20px;

  ul {
    display: flex;
    flex-direction: column;
    max-height: 250px;
    flex-wrap: wrap;
    margin: 0 34px;
  }

  li {
    margin: 5px 0;
    font-size: 1.2rem;
  }
`;

export const Editor = styled.div`
  width: 100%;
  min-height: 500px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const DownloadWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding-top: 20px;
`;
