const na = 'N/A';
const moment = require('moment');

const columns = [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => row.id,
  },
  {
    name: 'CPF VEND NUM',
    selector: 'CPF_VEND_NUM',
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => row.CPF_VEND_NUM,
  },
  {
    name: 'NOME',
    selector: 'NOME',
    sortable: true,
    grow: 1,
    minWidth: '130px',
    cell: (row) => row.NOME,
  },
  {
    name: 'MATURIDADE',
    selector: 'MATURIDADE',
    sortable: true,
    grow: 1,
    minWidth: '130px',
    cell: (row) => row.MATURIDADE,
  },
  {
    name: 'TVs',
    selector: 'TVs',
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (row.TVs ? row.TVs : 'N/A'),
  },
  {
    name: 'BLs',
    selector: 'BLs',
    sortable: true,
    grow: 1,
    minWidth: '250px',
    cell: (row) => (row.BLs ? row.BLs : 'N/A'),
  },
  {
    name: 'Fones',
    selector: 'Fones',
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => row.Fones,
  },
  {
    name: 'Moveis',
    selector: 'Moveis',
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => row.Moveis,
  },
  {
    name: 'PTOS GIGABONUS',
    selector: 'PTOS_GIGABONUS',
    sortable: true,
    grow: 1,
    minWidth: '180px',
    cell: (row) => (row.PTOS_GIGABONUS ? row.PTOS_GIGABONUS : 'N/A'),
  },
  {
    name: 'RECEITA CONTRATADA',
    selector: 'RECEITA_CONTRATADA',
    sortable: true,
    grow: 1,
    minWidth: '180px',
    cell: (row) => (row.RECEITA_CONTRATADA ? row.RECEITA_CONTRATADA : 'N/A'),
  },
  {
    name: 'data venda',
    selector: 'data_venda',
    sortable: true,
    grow: 1,
    minWidth: '180px',
    cell: (row) =>
      row.data_venda
        ? moment(new Date(row.data_venda)).format('DD/MM/YYYY HH:mm:ss')
        : 'N/A',
  },
];

export default columns;
