import React, { useState } from 'react';
import getIcon from '~/Helpers/getIcon';
import * as S from './styles';

const SidebarButton = ({
  subItems,
  disabled,
  opened,
  icon,
  label,
  active,
  onClick,
  hoverOpened,
  activeDropdown,
  ...rest
}) => {
  const [expanded, setExpanded] = useState(false);

  // useEffect(() => {
  //   if (subItems && onClick)
  //     throw Error("onClick is not allowed when SidebarButton have Subitems.");
  // }, [subItems, onClick]);

  return (
    <>
      <S.Wrapper
        onClick={
          subItems
            ? () => {
                if (expanded) {
                  onClick();
                } else {
                  setExpanded(!expanded);
                  onClick();
                }
              }
            : () => onClick && onClick()
        }
      >
        <S.Inner
          active={active}
          opened={opened}
          hoverOpened={hoverOpened}
          {...rest}
          disabled={disabled}
        >
          <S.Icon opened={opened} hoverOpened={hoverOpened}>
            {getIcon(icon, `${active ? '#323133' : '#FFC44C'}`)}
          </S.Icon>
          {(opened || hoverOpened) && (
            <>
              <S.Title>{label}</S.Title>
            </>
          )}
        </S.Inner>
        {(opened || hoverOpened) && subItems && (
          <S.Arrow
            expanded={expanded}
            onClick={(e) => {
              setExpanded(!expanded);
              e.stopPropagation();
            }}
          >
            {getIcon('downArrow', `${active ? '#323133' : '#FFC44C'}`)}
          </S.Arrow>
        )}
      </S.Wrapper>
      {expanded && (opened || hoverOpened) && (
        <S.ButtonItems>
          {subItems.map((subItem) => (
            <S.ButtonItemWrapper
              key={subItem.label}
              onClick={() => subItem.onClick && subItem.onClick()}
              active={subItem.activeDropdown}
            >
              {subItem.label}
              <S.ItemArrow className="itemArrow">
                {getIcon('downArrow', '#FFD175')}
              </S.ItemArrow>
            </S.ButtonItemWrapper>
          ))}
        </S.ButtonItems>
      )}
    </>
  );
};

export default SidebarButton;
