import { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { parseJwt } from '~/services/auth';

import TableDetails from '../tableDetails';
import * as S from './styles';

const CampaignsTable = ({ getCampaigns, campains, setSelectedCampaign }) => {
  const [subInfo, setSubInfo] = useState(null);

  const selected = (row) => {
    if (subInfo === row) {
      setSubInfo(null);
    } else {
      setSubInfo(row);
    }
  };

  const getClassName = (status) => {
    let result;

    if (status === 'Reprovado') result = 'red';
    else if (status === 'Pendente') result = 'grey';
    else if (status === 'Aprovado') result = 'green';
    else if (status === 'Pré-aprovado') result = 'yellow';

    return result;
  };
  return (
    <>
      <S.Container>
        <table rowSpacing="15" cellSpacing="0" cellPadding="0">
          <thead>
            <td>Data</td>
            <td>Nome</td>
            <td>CPF</td>
            <td>Regional</td>
            <td>IDPDV</td>
            <td>Status</td>
            <td> </td>
          </thead>
          <tbody>
            {campains.map((campain) => (
              <>
                <tr>
                  <td />
                </tr>
                <S.TableRow
                  selected={subInfo === campain}
                  onClick={() => selected(campain)}
                >
                  <td>{campain.created_at}</td>
                  <td>{campain.user_name}</td>
                  <td>{campain.cpf}</td>
                  <td>{campain.regional}</td>
                  <td>{campain.pdv}</td>
                  <td className={getClassName(campain.status)}>
                    {campain.status}
                  </td>
                  <td>
                    <S.ArrowButton
                      onClick={() => selected(campain)}
                      type="button"
                    >
                      {!subInfo ? (
                        <IoIosArrowDown size={20} />
                      ) : (
                        <IoIosArrowUp size={20} />
                      )}
                    </S.ArrowButton>
                  </td>
                </S.TableRow>
                {subInfo === campain && (
                  <S.TableRow>
                    <TableDetails
                      id={campain.id}
                      description={campain.description}
                      period={campain.period}
                      points={campain.points}
                      motivo={campain.refusal}
                      status={campain.status}
                      getCampaigns={getCampaigns}
                      campaign={campain}
                      setSelectedCampaign={setSelectedCampaign}
                    />
                  </S.TableRow>
                )}
              </>
            ))}
          </tbody>
        </table>
        <hr />
      </S.Container>
    </>
  );
};

export default CampaignsTable;
