import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Filter from '~/components/Filter';
import Loading from '~/components/Loading';
import Pagination from '~/components/Pagination';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';

import Collapse from './components/Collapse';
import DataInput from './components/DataInput';
import * as S from './styles';

const allCheckboxOptions = [
  { id: 1, value: 'VENDEDOR', label: 'Vendedor' },
  { id: 2, value: 'SUPERVISOR_LOJA', label: 'Supervisor de Loja' },
  { id: 3, value: 'GERENTE_LOJA', label: 'Gerente de Loja' },
  { id: 4, value: 'LIDER_SETOR', label: 'Líder de Setor' },
  { id: 5, value: 'GERENTE_REGIONAL', label: 'Gerente Regional' },
  { id: 6, value: 'OPERADOR_CAIXA', label: 'Operador de Caixa' },
  { id: 7, value: 'SUBGERENTE_LOJA', label: 'Subgerente de Loja' },
  { id: 8, value: 'PDC', label: 'PDC' },
  { id: 9, value: 'RED', label: 'RED' },
  { id: 10, value: 'SUPERVISOR_RED', label: 'Supervisor RED' },
  { id: 11, value: 'PROMOTOR', label: 'Promotor' },
  { id: 12, value: 'SUPERVISOR_PROMOTOR', label: 'Suprevisor Promotor' },
];

const Team = () => {
  const history = useHistory();
  const [loading, setLoading] = useState([]);
  const [hierarchies, setHierarchies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [dataini, setDataini] = useState('');
  const [datafim, setDatafim] = useState('');
  const [checkboxOptions, setCheckboxOptions] = useState([]);

  const callHierarchy = (filters) => {
    setLoading(true);
    api
      .post('/hierarquia', {
        usu_cpf: parseJwt().login,
        canal_id: localStorage.getItem('canal'),
        dataini,
        datafim,
        paginaAtual: currentPage,
        filtros: filters,
      })
      .then((response) => {
        setLoading(false);
        if (response.data.data) {
          setTotalCount(response.data.data.total);
          setHierarchies(response.data.data.listaUsuarios);
        }
      });
  };

  const getOptionsToVarejo = (role) => {
    const resultedOptions = [];
    switch (role) {
      case 'SUPERVISOR_LOJA':
        resultedOptions.push(allCheckboxOptions[0]);
        break;

      case 'GERENTE_LOJA':
        resultedOptions.push(allCheckboxOptions[0]);
        resultedOptions.push(allCheckboxOptions[1]);

        break;

      case 'LIDER_SETOR':
        resultedOptions.push(allCheckboxOptions[0]);
        resultedOptions.push(allCheckboxOptions[1]);
        resultedOptions.push(allCheckboxOptions[2]);
        break;

      case 'GERENTE_REGIONAL':
        resultedOptions.push(allCheckboxOptions[0]);
        resultedOptions.push(allCheckboxOptions[1]);
        resultedOptions.push(allCheckboxOptions[2]);
        resultedOptions.push(allCheckboxOptions[3]);
        break;

      default:
        history.push('/dashboard');
        break;
    }

    return setCheckboxOptions(resultedOptions);
  };

  const getOptionsToAA = (role) => {
    const resultedOptions = [];
    switch (role) {
      case 'SUBGERENTE_LOJA':
        resultedOptions.push(allCheckboxOptions[0]);
        resultedOptions.push(allCheckboxOptions[5]);
        break;

      case 'GERENTE_LOJA':
        resultedOptions.push(allCheckboxOptions[0]);
        resultedOptions.push(allCheckboxOptions[5]);
        resultedOptions.push(allCheckboxOptions[6]);
        break;

      case 'GERENTE_REGIONAL':
        resultedOptions.push(allCheckboxOptions[0]);
        resultedOptions.push(allCheckboxOptions[5]);
        resultedOptions.push(allCheckboxOptions[6]);
        resultedOptions.push(allCheckboxOptions[2]);
        break;

      default:
        history.push('/dashboard');
        break;
    }
    return setCheckboxOptions(resultedOptions);
  };

  const getOptionsToRecarga = (role) => {
    const resultedOptions = [];
    if (parseJwt().cargo === 16 || parseJwt().cargo === 17) {
      switch (role) {
        case 'SUPERVISOR':
          resultedOptions.push(allCheckboxOptions[8]);
          resultedOptions.push(allCheckboxOptions[7]);
          break;
        case 'RED':
          resultedOptions.push(allCheckboxOptions[7]);
          break;

        default:
          history.push('/dashboard');
          break;
      }
    } else {
      switch (role) {
        case 'SUPERVISOR_PROMOTOR':
          resultedOptions.push(allCheckboxOptions[11]);
          break;

        default:
          history.push('/dashboard');
          break;
      }
    }
    return setCheckboxOptions(resultedOptions);
  };

  const defineUserOptions = (role) => {
    switch (localStorage.getItem('canal')) {
      case '2':
        getOptionsToVarejo(role);
        break;

      case '4':
        getOptionsToAA(role);
        break;

      case '5':
        getOptionsToRecarga(role);
        break;

      default:
        history.push('/dashboard');
        break;
    }
  };

  useEffect(() => {
    api.get(`/check-role/${parseJwt().cargo}`).then((response) => {
      defineUserOptions(response.data.data.cargo_label);
    });
  }, []);

  useEffect(() => {
    callHierarchy();

    // eslint-disable-next-line
  }, [currentPage]);

  const handleSubmit = (data) => {
    callHierarchy(data.checkbox);
  };

  return (
    <>
      {loading && <Loading />}
      <S.Wrapper>
        <S.Title>Minha Equipe</S.Title>
        <S.DataWrapper>
          <DataInput title="Selecione o periodo" setValue={setDataini} />
          <DataInput title="até" setValue={setDatafim} />
        </S.DataWrapper>
        <Filter
          checkboxOptions={checkboxOptions}
          handleSubmit={handleSubmit}
          title="Filtrar por"
          button="Filtrar"
        />
        <S.Data>
          <S.DataHeader>
            <p>Exibindo {totalCount} resultados</p>
          </S.DataHeader>
        </S.Data>
        {hierarchies.map((hierarchy) => (
          <Collapse title={hierarchy.nome} hierarchy={hierarchy} />
        ))}
        <Pagination
          totalCount={totalCount}
          limit={10}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </S.Wrapper>
    </>
  );
};

export default Team;
