import styled from 'styled-components/macro';

export const Wrapper = styled.div``;

export const Label = styled.div`
  color: ${({ theme }) => theme.admincolors.contrast.black};

  font-size: ${({ theme }) => theme.adminfontsize.label};
  font-style: normal;
  font-weight: 400;
  line-height: ${({ theme }) => theme.lineheight.label};
  letter-spacing: 0em;
  text-align: left;
  padding-left: 6px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;

  span {
    color: ${({ theme }) => theme.admincolors.feedback.danger30};
  }
`;

export const Inner = styled.div`
  position: relative;
  label {
    position: relative;
    display: inline-block;
    width: 75px;
    height: 40px;
  }
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:focus + span {
      box-shadow: 0px 0px 0 1px ${(props) => props.theme.oldColors.primary[50]},
        0px 0px 0 3px ${(props) => props.theme.oldColors.primary[50]};
      outline: none;
    }

    &:checked + span {
      background: ${(props) => props.theme.oldColors.primary[50]};

      &::before {
        content: 'Sim';
        transform: translateX(42px);
        color: ${(props) => props.theme.oldColors.primary[50]};
      }
    }
  }
  span {
    font-size: 12px;

    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid ${(props) => props.theme.oldColors.gray[50]};
    display: grid;
    place-items: center;
    background: ${(props) => props.theme.admincolors.contrast.white};

    transition: 0.4s;
    border-radius: 30px;
    &::before {
      position: absolute;
      color: ${(props) => props.theme.admincolors.contrast.white};
      content: 'Não';
      height: 27px;
      display: grid;
      place-items: center;
      width: 27px;
      left: 2px;
      bottom: 5px;
      background-color: ${(props) => props.theme.oldColors.gray[100]};

      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);

      -webkit-transition: 0.6s;
      transition: 0.6s;
      border-radius: 50%;
    }
  }
`;

export const State = styled.div`
  position: relative;
`;
