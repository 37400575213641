import React from 'react';

const Posts = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12C5 13.11 4.11 14 3 14C2.60444 14 2.21776 13.8827 1.88886 13.6629C1.55996 13.4432 1.30362 13.1308 1.15224 12.7654C1.00087 12.3999 0.96126 11.9978 1.03843 11.6098C1.1156 11.2219 1.30608 10.8655 1.58579 10.5858C1.86549 10.3061 2.22186 10.1156 2.60982 10.0384C2.99778 9.96126 3.39992 10.0009 3.76537 10.1522C4.13082 10.3036 4.44318 10.56 4.66294 10.8889C4.8827 11.2178 5 11.6044 5 12M4 2V8H2V2H4ZM2 22V16H4V22H2ZM24 6V18C24 19.11 23.11 20 22 20H10C9.46957 20 8.96086 19.7893 8.58579 19.4142C8.21072 19.0391 8 18.5304 8 18V14L6 12L8 10V6C8 5.46957 8.21072 4.96086 8.58579 4.58579C8.96086 4.21071 9.46957 4 10 4H22C23.11 4 24 4.89 24 6ZM22 6H10V10.83L8.83 12L10 13.17V18H22V6ZM12 9H20V11H12V9ZM12 13H18V15H12V13Z"
        fill={color}
      />
    </svg>
  );
};
export default Posts;
