import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import DownloadStaticFile from '~/components/NexAdmin/DownloadStaticFile';
import { useState } from 'react';
import Loading from '~/components/Loading';
import Validate from './Components/Validate';
import CreateTransactions from './Components/Upload';
import { columns, list, columns_pap_pc, list_pap_pc } from './columns';
import { parseJwt } from '~/services/auth';

const STEPS = ['validate', 'createTransactions', 'error', 'concluded'];

const CreateBonusPoints = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [channel] = useState(parseJwt().canal_id);

  const changeStep = (newStep) => {
    const newStepIndex = STEPS.indexOf(newStep);

    if (newStepIndex < 0) return;

    setCurrentStep(newStepIndex);
  };

  const getStepComponent = (currentStep) => {
    const step = STEPS[currentStep];

    switch (step) {
      case 'validate':
        return (
          <>
            <Validate
              file={file}
              setFile={setFile}
              setErrors={setErrors}
              setLoading={setLoading}
              changeStep={changeStep}
              setData={setData}
              channel={channel}
            />
            <DownloadStaticFile
              columns={channel == 9 ? columns_pap_pc : columns}
              list={channel == 9 ? list_pap_pc : list}
              file_name={'bonus_modelo'}
              button_name={'Download Modelo'}
            />
          </>
        );
      case 'createTransactions':
        return (
          <CreateTransactions
            errors={errors}
            file={file}
            setFile={setFile}
            setErrors={setErrors}
            setLoading={setLoading}
            changeStep={changeStep}
            data={data}
            channel={channel}
          />
        );
      default:
        return <h3>Algo está errado, tente novamente mais tarde</h3>;
    }
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="add"
          title={channel == 9 ? 'Pontos vendas' : 'Pontos bônus'}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle
            title={
              channel == 9
                ? 'Inserir pontos vendas em massa'
                : 'Inserir pontos bônus em massa'
            }
          >
            {getStepComponent(currentStep)}
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default CreateBonusPoints;
