import React from 'react';

const Edit = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.55947 0C1.50914 0 0.666992 0.84215 0.666992 1.89247V15.1398C0.666992 15.6417 0.866377 16.1231 1.22128 16.478C1.57619 16.8329 2.05755 17.0323 2.55947 17.0323H15.8068C16.3087 17.0323 16.79 16.8329 17.145 16.478C17.4999 16.1231 17.6992 15.6417 17.6992 15.1398V8.51613H15.8068V15.1398H2.55947V1.89247H9.18312V0H2.55947ZM14.6524 0.946236C14.4821 0.947674 14.3191 1.01558 14.1982 1.13548L13.0438 2.28043L15.4094 4.64602L16.5638 3.50107C16.8098 3.25505 16.8098 2.83871 16.5638 2.60215L15.0971 1.13548C14.9741 1.01247 14.8132 0.946236 14.6524 0.946236ZM12.3719 2.95226L5.39817 9.93548V12.3011H7.76377L14.7375 5.31785L12.3719 2.95226Z"
        fill={color}
      />
    </svg>
  );
};
export default Edit;
