import React from 'react';

const Access = ({ color }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 12V15C3.5 16.657 6.634 18 10.5 18C14.366 18 17.5 16.657 17.5 15V12C17.5 13.657 14.366 15 10.5 15C6.634 15 3.5 13.657 3.5 12Z"
        fill={color}
      />
      <path
        d="M3.5 7V10C3.5 11.657 6.634 13 10.5 13C14.366 13 17.5 11.657 17.5 10V7C17.5 8.657 14.366 10 10.5 10C6.634 10 3.5 8.657 3.5 7Z"
        fill={color}
      />
      <path
        d="M17.5 5C17.5 6.657 14.366 8 10.5 8C6.634 8 3.5 6.657 3.5 5C3.5 3.343 6.634 2 10.5 2C14.366 2 17.5 3.343 17.5 5Z"
        fill={color}
      />
    </svg>
  );
};
export default Access;
