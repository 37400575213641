import moment from 'moment';

export const toTimeNoSeconds = (value) => {
  return moment(value).format('HH:mm');
};

export const toDate = (value) => {
  return moment(value).format('DD/MM/YYYY');
};

export const toDateTime = (value) => {
  return moment(value).format('DD/MM/YYYY HH:mm:ss');
};

export const toDateTimeNoSeconds = (value) => {
  return moment(value).format('DD/MM/YYYY HH:mm');
};

export const toTimeNoMinuteNoSecondsWithH = (value) => {
  return moment(value).format('HH') + 'h';
};

export const longMonthBR = (value) => {
  var month = moment(value).format('MMMM');

  if (month === 'January') return 'Janeiro';
  if (month === 'February') return 'Fevereiro';
  if (month === 'March') return 'Março';
  if (month === 'April') return 'Abril';
  if (month === 'May') return 'Maio';
  if (month === 'June') return 'Junho';
  if (month === 'July') return 'Julho';
  if (month === 'August') return 'Agosto';
  if (month === 'September') return 'Setembro';
  if (month === 'October') return 'Outubro';
  if (month === 'November') return 'Novembro';
  if (month === 'December') return 'Dezembro';

  return 'NOT RECOGNIZED MONTH';
};

export const toDateBR = (value) => {
  return moment(value).format('DD/MM/YYYY');
};

export const toDateUS = (value) => {
  return moment(value).format('YYYY-MM-DD');
};

export const onlyDayNumber = (value) => {
  return moment(value).format('DD');
};

export const getTime = (value) => {
  var d = new Date(value);
  var h = addZero(d.getHours());
  var m = addZero(d.getMinutes());
  var s = addZero(d.getSeconds());

  return `${h}:${m}:${s}`;
};

export const InBetweenDates = (initial, final) => {
  if (moment(new Date()).isBetween(initial, final)) {
    return 'Ativa';
  }
  return 'Inativa';
};
