import styled from 'styled-components';

export const Container = styled.div`
  grid-area: ranking;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  overflow: hidden;
  padding: 17px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 15px;
  svg {
    cursor: pointer;
  }
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  color: ${({ theme }) => theme.colors.red};
  font-weight: ${({ theme }) => theme.font.medium};
`;
export const Paragraph = styled.p`
  font-size: 1.4rem;

  font-weight: 400;
`;

export const RankList = styled.ul`
  list-style: none;
`;

export const RankItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  overflow: hidden;
  background-color: #fcc21b20;
  // border: 1px solid ${({ theme }) => theme.colors.red};
  border-radius: 30px;
  padding: 0 10px;
  height: 58px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.grayDarker};

  span {
    font-size: 1.2rem;
    /* text-align: left; */
    font-weight: ${({ theme }) => theme.font.extraBold};
  }

  .position-one {
    background-color: rgba(252, 194, 27, 0.2);
    border-color: unset;
  }

  .position-two {
    background-color: rgba(206, 206, 210, 0.2);
    border-color: unset;
  }

  .medal {
    position: relative;
    top: -7px;
  }

  p:first-of-type {
    font-size: 1.2rem;
    text-align: left;
  }

  p:last-of-type {
    font-size: 1.4rem;
    text-align: left;
    font-weight: ${({ theme }) => theme.font.extraBold};
    white-space: nowrap;
  }

  img:last-of-type {
    width: 35px;
    height: 35px;
  }
`;
export const Button = styled.button`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;
  width: 150px;
  margin-bottom: 20px;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;
