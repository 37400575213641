import { useEffect, useState } from 'react';
import * as S from './styles';
import Carousel from 'react-elastic-carousel';
import Card from '~/screens/Quizzes/Components/Card';
import api from '~/services/api';
import Loading from '~/components/Loading';
import { HTTPErrorCallback } from '~/Helpers/Error';
import getIcon from '~/Helpers/getIcon';
import CommonRoutes from '~/routes/Components/Common/routes';
import { Link } from 'react-router-dom';

const Quiz = () => {
  const [loading, setLoading] = useState(false);
  const [quiz, setQuiz] = useState([]);

  useEffect(async () => {
    setLoading(true);
    try {
      const response = await api.get('/quizzes/user/quiz');
      setQuiz(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return HTTPErrorCallback(error);
    }
  }, []);

  return (
    <>
      {loading && <Loading />}
      {quiz.length > 0 && (
        <S.Container>
          <S.Title>Treinamentos</S.Title>
          <S.WishScrollArea>
            <S.WishContainer>
              <Carousel
                itemPadding={[10, 20]}
                breakPoints={[
                  { width: 1, itemsToShow: 1 },
                  { width: 550, itemsToShow: 3 },
                  { width: 1050, itemsToShow: 4 },
                ]}
              >
                {quiz.map((item) => (
                  <Card quiz={item} />
                ))}
              </Carousel>
            </S.WishContainer>
          </S.WishScrollArea>
          <S.ButtonWrapper>
            <div />
            <S.Button>
              <Link
                to={{
                  pathname: CommonRoutes['QuizzesMain'].path,
                  state: { quiz },
                }}
              >
                Ver todos {getIcon('rightArrow', '#C50101')}
              </Link>
            </S.Button>
          </S.ButtonWrapper>
        </S.Container>
      )}
    </>
  );
};

export default Quiz;
