import CountdownPage from '~/components/CountDownFinal/index';

const Countdown = () => {
  return (
    <>
      <CountdownPage />
    </>
  );
};

export default Countdown;
