/* eslint-disable no-console */
import { useState } from 'react';
import {
  AiOutlineHeart,
  AiFillHeart,
  AiOutlineStar,
  AiFillStar,
} from 'react-icons/ai';
import { CgSmileMouthOpen } from 'react-icons/cg';

import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import { getCompleteDate } from '~/utils';
import { showNotification } from '~/utils/notificationBoxes';

import Comments from '../Comments';
import * as S from './styles';

const PostItem = ({ post, admin, getPosts }) => {
  const [executingReaction, setExecutingReaction] = useState(0);
  const getReactions = (first, second, third, reactionType) => {
    let result;
    if (first && first.tipo_reacao === reactionType) {
      result = first.qtd;
    } else if (second && second.tipo_reacao === reactionType) {
      result = second.qtd;
    } else if (third && third.tipo_reacao === reactionType) {
      result = third.qtd;
    }

    return result || 0;
  };

  const [heartReactions, setHeartReactions] = useState(
    getReactions(post.reacoes[0], post.reacoes[1], post.reacoes[2], 1),
  );
  const [smileReactions, setSmileReactions] = useState(
    getReactions(post.reacoes[0], post.reacoes[1], post.reacoes[2], 2),
  );
  const [starReactions, setStarReactions] = useState(
    getReactions(post.reacoes[0], post.reacoes[1], post.reacoes[2], 3),
  );

  const getNewReactions = (postId) => {
    api.get(`/reacoes/${postId}`).then((response) => {
      setHeartReactions(
        getReactions(
          response.data.data[0],
          response.data.data[1],
          response.data.data[2],
          1,
        ),
      );
      setSmileReactions(
        getReactions(
          response.data.data[0],
          response.data.data[1],
          response.data.data[2],
          2,
        ),
      );
      setStarReactions(
        getReactions(
          response.data.data[0],
          response.data.data[1],
          response.data.data[2],
          3,
        ),
      );
    });
  };

  const createOrUpdateReaction = (postToReact, reactionType) => {
    setExecutingReaction(reactionType);
    if (postToReact.minhaReacao?.length > 0) {
      api
        .put(`/reacao/${postToReact.minhaReacao[0].post_reacao_id}`, {
          tipo_reacao: reactionType,
          usu_id: parseJwt().id,
          post_id: postToReact.post_id,
        })
        .then(() => {
          return getNewReactions(postToReact.post_id);
        });
    } else {
      api
        .post('/reacao', {
          tipo_reacao: reactionType,
          usu_id: parseJwt().id,
          post_id: postToReact.post_id,
        })
        .then(() => {
          return getNewReactions(postToReact.post_id);
        });
    }
  };

  const switchPostType = () => {
    if (post.foto?.includes('.mp4') || post.foto?.includes('.avi')) {
      return (
        <S.Banner>
          <S.BannerWrapper>
            <video autoPlay loop muted>
              <source src={post.foto} type="video/mp4" />
              <source src={post.foto} type="video/ogg" />
              <track default kind="captions" />
              Seu browser não suporta video!
            </video>
          </S.BannerWrapper>
        </S.Banner>
      );
    }

    if (post.foto?.includes('.mp3')) {
      return (
        <S.Banner>
          <audio autoPlay loop muted>
            <track default kind="captions" />
            <source src={post.foto} type="audio/ogg" />
            <source src={post.foto} type="audio/mpeg" />
            Seu browser não suporta Audio!
          </audio>
        </S.Banner>
      );
    }

    if (
      post.foto &&
      !post.foto?.includes('.mp3') &&
      !post.foto?.includes('.mp4') &&
      !post.foto?.includes('.avi')
    ) {
      return (
        <S.Banner>
          <img src={post.foto} alt="Banner" />
        </S.Banner>
      );
    }

    return <></>;
  };

  const deletePost = (postId) => {
    const confirm = window.confirm(
      'Tem certeza que deseja deletar o post? Não é possível reverter essa ação.',
    );

    if (!confirm) return false;

    api
      .delete(`/post/${postId}`)
      .then((response) => {
        showNotification('success', 'Sucesso', 'Post deletado com sucesso!');

        getPosts();
      })
      .catch((error) => {
        return showNotification(
          'danger',
          'Sucesso',
          error.response.data.message,
        );
      });

    return true;
  };

  return (
    <S.Wrapper>
      <S.Post>
        <header>
          <figure>
            <img src="/images/avatar-profile-new.jpg" alt="Avatar" />
          </figure>
          <div>
            <h1>Gigiga</h1>
            <p>Postado em {getCompleteDate(post.data_publicacao)}</p>
          </div>
          {admin && (
            <button
              className="admin-delete-button"
              type="button"
              onClick={() => deletePost(post.post_id)}
            >
              Deletar
            </button>
          )}
        </header>
        <S.Commentary
          className="comentary"
          dangerouslySetInnerHTML={{ __html: post.comentario }}
        />
        {switchPostType()}
        <S.FormWrapper>
          <S.FormReActions>
            <button
              onClick={() => createOrUpdateReaction(post, 1)}
              type="button"
              disabled={admin}
            >
              <li>
                <AiOutlineHeart className="heart" size={25} />
                {executingReaction === 1 && (
                  <AiFillHeart className="heart fillReaction" size={25} />
                )}
                {heartReactions}
              </li>
            </button>
            <button
              onClick={() => createOrUpdateReaction(post, 2)}
              type="button"
              disabled={admin}
            >
              <li>
                <CgSmileMouthOpen size={25} />
                {executingReaction === 2 && (
                  <img
                    alt=""
                    src="/images/smile.png"
                    className="star fillReaction"
                  />
                )}
                {smileReactions}
              </li>
            </button>
            <button
              onClick={() => createOrUpdateReaction(post, 3)}
              type="button"
              disabled={admin}
            >
              <li>
                <AiOutlineStar className="star" size={25} />
                {executingReaction === 3 && (
                  <AiFillStar className="star fillReaction" size={25} />
                )}

                {starReactions}
              </li>
            </button>
          </S.FormReActions>
          {/* {post.comentarios && (
            <Comments
              post={post}
              comentaries={post.comentarios}
              admin={admin}
            />
          )} */}
        </S.FormWrapper>
      </S.Post>
    </S.Wrapper>
  );
};

export default PostItem;
