import styled from 'styled-components';
import gigaBG from './gigabg.png';
import gigabgMobile from './gigabgMobile.png';

const redGradient = 'linear-gradient(to right, #92111a, #a0141e);';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
`;

export const TopSection = styled.div`
  display: flex;
  flex: 1;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FirstDiv = styled.div`
  flex: 0 0 40%;
  background-image: url(${gigaBG});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    flex: 1;
    height: 50%;
    background-image: url(${gigabgMobile});
  }
`;

export const SecondDiv = styled.div`
  flex: 1;
  background: ${redGradient};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    flex: 1;
    height: 50%;
  }
`;

export const ThirdDiv = styled.div`
  background-color: white;
  height: 50px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const AbsoluteImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 150px;
  height: 65%;
  width: auto;
  z-index: 1000;

  @media (max-width: 1200px) {
    height: 55%;
    left: 50px;
  }

  @media (max-width: 992px) {
    height: 45%;
    left: 18px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

// Estilização dos elementos h1 e p na SecondDiv
export const SecondDivHero = styled.h1`
  font-size: 72px;
  color: #e53041;
  font-family: 'AMXBold', Times, serif;

  @media (max-width: 768px) {
    font-size: 40px;
  }
`;
export const SecondDivP = styled.h1`
  font-size: 48px;
  color: #e53041;
  margin-bottom: 100px;
  font-family: 'AMXRegular', Times, serif;

  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 60px;
  }
`;

export const SecondDivSpan = styled.p`
  font-size: 32px;
  color: #e0e0e0;
  max-width: 50%;
  margin-top: 137px;
  font-family: 'AMXRegular', Times, serif;
  @media (max-width: 768px) {
    font-size: 24px;
    max-width: 100%;
    margin-top: 50px;
    text-align: center;
  }
`;
