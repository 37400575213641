import { useState } from 'react';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import StatusTag from '~/components/NexAdmin/StatusTag';
import Loading from '~/components/Loading';
import ContainerWithTabs from '~/components/NexAdmin/ContainerWithTabs';
import GeralTable from '../Components/GeralTable';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import DefaultGNDescription from '~/components/NexAdmin/Modal/Descriptions/GN/default';

const GN = () => {
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { openModal } = useModal();

  const tabs = [
    {
      title: 'Campanhas GN',
      component: (
        <GeralTable
          title={'gn'}
          currentIndex={currentIndex}
          setLoading={setLoading}
        />
      ),
    },
    {
      title: 'Campanhas Vendedores',
      component: (
        <GeralTable
          title={'vendedores'}
          currentIndex={currentIndex}
          setLoading={setLoading}
        />
      ),
    },
    {
      title: 'Extrato GN',
      component: (
        <GeralTable
          title={'Extrato GN'}
          currentIndex={currentIndex}
          setLoading={setLoading}
        />
      ),
    },
  ];

  const openThermsModal = () => {
    openModal({
      title: 'Visão Geral - Campanhas GN',
      close: true,
      content: <DefaultGNDescription />,
    });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="config"
          title="Visão Geral"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTabs
            setExternalIndexInfo={setCurrentIndex}
            tabs={tabs}
          />
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default GN;
