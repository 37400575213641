import React from 'react';

const ChartBar = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 11C2.5 10.8674 2.55268 10.7402 2.64645 10.6464C2.74021 10.5527 2.86739 10.5 3 10.5H5C5.13261 10.5 5.25978 10.5527 5.35355 10.6464C5.44732 10.7402 5.5 10.8674 5.5 11V16C5.5 16.1326 5.44732 16.2598 5.35355 16.3536C5.25979 16.4473 5.13261 16.5 5 16.5H3C2.86739 16.5 2.74021 16.4473 2.64645 16.3536C2.55268 16.2598 2.5 16.1326 2.5 16V11ZM8.5 7C8.5 6.86739 8.55268 6.74022 8.64645 6.64645C8.74021 6.55268 8.86739 6.5 9 6.5H11C11.1326 6.5 11.2598 6.55268 11.3536 6.64645C11.4473 6.74022 11.5 6.86739 11.5 7V16C11.5 16.1326 11.4473 16.2598 11.3536 16.3536C11.2598 16.4473 11.1326 16.5 11 16.5H9C8.86739 16.5 8.74021 16.4473 8.64645 16.3536C8.55268 16.2598 8.5 16.1326 8.5 16V7ZM14.5 4C14.5 3.86739 14.5527 3.74021 14.6464 3.64645C14.7402 3.55268 14.8674 3.5 15 3.5H17C17.1326 3.5 17.2598 3.55268 17.3536 3.64645C17.4473 3.74021 17.5 3.86739 17.5 4V16C17.5 16.1326 17.4473 16.2598 17.3536 16.3536C17.2598 16.4473 17.1326 16.5 17 16.5H15C14.8674 16.5 14.7402 16.4473 14.6464 16.3536C14.5527 16.2598 14.5 16.1326 14.5 16V4Z"
        fill={color}
        stroke="#071831"
      />
    </svg>
  );
};
export default ChartBar;
