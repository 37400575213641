import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import * as S from './styles';

const ToggleSwitch = ({ label, name, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <S.Wrapper>
      <S.Label>{label}</S.Label>
      <S.Inner content="Sim">
        <label>
          <input ref={inputRef} id={fieldName} {...rest} type="checkbox" />
          <span></span>
        </label>
      </S.Inner>
    </S.Wrapper>
  );
};

export default ToggleSwitch;
