import { useState, useRef, useEffect } from 'react';
import { isBrowser } from 'react-device-detect';
import { Link } from 'react-router-dom';

import Logo from '~/components/Logo';
import NexAdminRoutes from '~/routes/Components/NexAdmin/routes';
import Stores from '../Stores';
import * as S from './styles';
import getIcon from '~/Helpers/getIcon';
import Notifications from '../Notifications';
import CommonRoute from '~/routes/Components/Common/routes';
import UseNotification from '~/hooks/NexAdmin/NotificationHook';

const Header = ({ open, setOpen }) => {
  const {
    totalUnreadNotifications,
    notifications,
    getNotifications,
    getUnreadNotifications,
  } = UseNotification();
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    getNotifications();
    getUnreadNotifications();
  }, []);
  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem('userToken');
    if (localStorage.getItem('adminUserToken')) {
      return window.close();
    }
    window.location.replace('/');
  };
  return (
    <>
      <Notifications
        showNotification={showNotification}
        setShowNotification={setShowNotification}
        notifications={notifications}
      />
      <S.Container>
        <S.Hamburguer onClick={() => setOpen(!open)}>
          {getIcon('hamburguerCommon')}
        </S.Hamburguer>
        <Logo origin="header" href={CommonRoute.Dashboard.path} />
        <S.IconsWrapper>
          <Link to={CommonRoute.Profile.path}>{getIcon('profile')}</Link>
          <S.NotificationWrapper
            onClick={() => setShowNotification(!showNotification)}
          >
            {getIcon('notificationSign')}
            <S.NotificationAmount>
              {totalUnreadNotifications > 0 && (
                <span>{totalUnreadNotifications}</span>
              )}
            </S.NotificationAmount>
          </S.NotificationWrapper>
        </S.IconsWrapper>
        {isBrowser && (
          <S.LinksWrapper>
            <Stores />
            <S.ItemWrapper>
              {getIcon('myPoints')}
              <Link to={CommonRoute.Extract.path}>Meus Pontos</Link>
            </S.ItemWrapper>
            <S.ItemWrapper>
              {getIcon('profile')}
              <Link to={CommonRoute.Profile.path}>Meu Perfil</Link>
            </S.ItemWrapper>
            <S.ItemWrapper>
              <S.NotificationWrapperBrowser
                onClick={() => setShowNotification(!showNotification)}
              >
                {getIcon('notificationSign')}
                <S.NotificationAmountBrowser>
                  {totalUnreadNotifications > 0 && (
                    <span>{totalUnreadNotifications}</span>
                  )}
                </S.NotificationAmountBrowser>
                Notificações
              </S.NotificationWrapperBrowser>
            </S.ItemWrapper>
            <S.ItemWrapper onClick={(e) => logout(e)}>
              <Link to="/">Sair</Link>
            </S.ItemWrapper>
          </S.LinksWrapper>
        )}
      </S.Container>
    </>
  );
};

export default Header;
