import React from 'react';

const Arrow = ({ color }) => {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.91 0.0800781L6.5 4.67008L11.09 0.0800781L12.5 1.50008L6.5 7.50008L0.5 1.50008L1.91 0.0800781Z"
        fill={color}
      />
    </svg>
  );
};
export default Arrow;
