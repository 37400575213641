import { NavLink } from 'react-router-dom';

import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Back = styled.button`
  background: none;
  img {
    width: 28px;
    height: 28px;
  }
  ${media.greaterThan('medium')`
    display: none;
  `};
`;

export const Container = styled.aside`
  background: ${({ theme }) => theme.colors.sidebar};
  padding: 4.4rem 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: ${({ theme }) => theme.colors.sidebar};
  padding: 10px 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100vh;
  position: fixed;
  display: none;
  top: 143px;
  ${({ open }) =>
    open &&
    css`
      display: block;
      overflow-y: auto;
    `}
  z-index: 90;
  background-color: #ffeeee;
  ${media.greaterThan('medium')`
  padding: 50px 10px;
  grid-area: sidebar;
    display: block;
    width: auto;
    height: auto;
    position: relative;
    bottom: inherit;
    z-index: inherit;
    padding: 4.4rem 1rem;
    top: 0;
  `};

  & > a {
    padding: 10px 20px;
    font-weight: 700;
  }
`;

export const Menu = styled.ul`
  padding: 0 20px;
  list-style: none;
  margin-top: 20px;
  ${media.greaterThan('medium')`
  margin-top: 55px;
  `}

  li + li {
    margin-top: 30px;
  }

  li {
    ul {
      list-style: none;
      li {
        margin: 10px 0 0 30px;
      }
    }
  }

  svg {
    color: ${({ theme }) => theme.colors.red};
    width: 2rem;
    height: 2rem;
    margin-right: 10px;
  }
`;

const activeClassName = 'nav-item-active';
export const Link = styled(NavLink).attrs({ activeClassName })`
  color: ${({ theme }) => theme.colors.grayDarker};
  display: flex;
  align-items: center;
  font-size: 2rem;

  ${media.greaterThan('medium')`
  font-size: 1.4rem;

`};
  text-decoration: none;
  font-weight: 400;
  transition: all 0.2s ease;
  &.preventclick {
    pointer-events: none !important;
    color: #c50101;
    font-weight: 500;
    &.${activeClassName}, &:hover {
      color: ${({ theme }) => theme.colors.red};
      font-weight: 500;
    }
  }

  &.${activeClassName}, &:hover {
    color: ${({ theme }) => theme.colors.red};
    font-weight: 700;
  }

  position: relative;

  span {
    color: #fff;
    background: ${({ theme }) => theme.colors.degrade};
    position: absolute;
    right: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
  }
`;

export const Notification = styled.div`
  color: #fff;
  background: ${({ theme }) => theme.colors.degrade};
  position: absolute;
  right: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
`;

export const LinkAnchor = styled.a`
  color: ${({ theme }) => theme.colors.grayDarker};
  display: flex;
  align-items: center;
  font-size: 2rem;

  ${media.greaterThan('medium')`
  font-size: 1.4rem;

`};
  text-decoration: none;
  font-weight: 400;
  transition: all 0.2s ease;
  &.preventclick {
    pointer-events: none !important;
    color: #c50101;
    font-weight: 500;
    &.${activeClassName}, &:hover {
      color: ${({ theme }) => theme.colors.red};
      font-weight: 500;
    }
  }

  &.${activeClassName}, &:hover {
    color: ${({ theme }) => theme.colors.red};
    font-weight: 700;
  }

  position: relative;

  span {
    color: #fff;
    background: ${({ theme }) => theme.colors.degrade};
    position: absolute;
    right: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
  }
`;

export const Icon = styled.img`
  height: 20px;
  width: 18px;
  margin-right: 10px;
`;
