import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  width: 100vw;
  overflow-x: hidden;
  ${media.greaterThan('medium')`
width: 100%;
`};
`;

export const Text = styled.p`
  padding: 0 20px;
  font-size: 1.3rem;
`;

export const Message = styled.h1``;

export const WishContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 20px;
  width: 100vw;
  overflow-x: hidden;
  ${media.greaterThan('medium')`
width: 100%;
`};
`;

export const WishScrollArea = styled.div`
  padding: 20px;

  overflow-x: auto;
  overflow-y: hidden;
  max-width: 800px;
  width: 100vw;
  overflow-x: hidden;
  ${media.greaterThan('medium')`
width: 100%;
`};
`;
export const Wish = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px 20px;
  background-color: #fff;
  border-radius: 10px;
  min-width: 300px;
  max-width: 300px;
  height: max-content;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  .circle-big {
    position: relative;
    width: 114px;
    height: 114px;
    background-size: contain;
    background-repeat: none;
    background-position: center;
    border-radius: 50%;
    overflow: hidden;
  }

  .wish-points {
    font-size: 1.2rem !important;
    color: #c50101;
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
  }
  .wish-observation {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2rem;
    margin-top: 20px;
    letter-spacing: 0em;
    text-align: center;
  }

  .circle-big svg {
    width: 114px;
    height: 114px;
  }

  .circle-big .bg {
    fill: none;
    strokewidth: 10px;
    stroke: #faa9a9;
    background-color: black;
  }

  .circle-big .progress {
    fill: none;
    strokewidth: 10px;
    stroke: url(#linear);
    stroke-linecap: round;
    stroke-dasharray: 326.56;
    stroke-dashoffset: 60;

    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    animation: anima 1.5s ease-in-out;
  }
  @keyframes anima {
    from {
      stroke-dashoffset: 326.56;
    }
    to {
      stroke-dashoffset: 60;
    }
  }
`;
export const Wrapper = styled.section`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  margin-top: 30px;
  padding: 70px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;

  ${media.greaterThan('medium')`
  width: 100%;
  `};
`;

export const WishWrapper = styled.section`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  margin-top: 30px;
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    font-size: 1.6rem;
    color: #c50101;
    font-weight: 700;
    text-align: left;
  }
  width: 100vw;
  overflow-x: hidden;
  ${media.greaterThan('medium')`
width: 100%;
`};
`;

export const WishTitle = styled.h3`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  margin: 20px 0px;
`;
export const WishesTitle = styled.h2`
  width: 100%;
  text-align: left;
`;
export const CalculatorWrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  // box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  margin-top: 30px;
  // padding: 70px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const CalculatorTitle = styled.h2`
  color: ${({ theme }) => theme.colors.red};
  font-weight: 700;
`;

export const CalculatorInput = styled.div`
  input {
    padding: 20px;
    margin: 20px 0px;
    border: 1px solid ${({ theme }) => theme.colors.red};
    min-width: 300px;
    text-align: center;
    border-radius: 100px;
    font-size: 2rem;
  }
`;
export const Hint = styled.div`
  margin-top: 30px;
  display: flex;
  color: white;
  background: linear-gradient(178.18deg, #ff0000 -13.56%, #760000 158.3%);
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  border-radius: 10px;
  padding: 20px 30px;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${media.greaterThan('medium')`
flex-direction:row;
text-align: left;

`};
  max-width: 1000px;
`;

export const Comment = styled.span`
  margin-top: 20px;
  width: 100%;
  padding: 12px 5px;
  text-align: left;
  border-top: 1px solid #85858580;
  margin-top: 50px;
  font-size: 1.2rem;
  color: #858585;
`;

export const FilterWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export const WishListWrapper = styled.div`
  position: relative;
  margin: 0px 0px 20px;
`;

export const WishListButton = styled.button`
  background: ${({ wishListClicked }) =>
    wishListClicked
      ? '#C50101'
      : 'linear-gradient(178.18deg, #FF0000 -13.56%, #760000 158.3%)'};
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: 9px 15px;
  border-radius: 20px;
  cursor: pointer;
  white-space: nowrap;
  ${media.greaterThan('medium')`


`};
`;

export const WishListHeader = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px;
  border-bottom: 1px solid #f4f4f4;
  padding: 20px 20px;
  z-index: 1;
  font-size: 1.4rem;
  ${media.greaterThan('medium')`


`};
`;
export const WishHeaderButton = styled.button`
  background-color: transparent;
  padding: 0px 4px;
  cursor: pointer;
  &:hover {
    color: #c50101;
  }
  ${media.greaterThan('medium')`

padding: 0px 10px;
`};
`;
export const WishCounterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .counter {
    background-color: #c50101;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
    font-size: 1.2rem;
    font-weight: 700;
  }
`;

export const WishList = styled.div`
  .arrow {
    transform: rotate(-90deg);
    position: absolute;
    left: calc(50% - 15px);
    top: -20px;
    filter: drop-shadow(2px 0px 2px rgb(133 133 133 / 30%));
  }
  z-index: 20;
  position: absolute;
  top: ${({ wishListClicked }) => (wishListClicked ? '50px' : '100px')};
  opacity: ${({ wishListClicked }) => (wishListClicked ? '1' : '0')};
  pointer-events: ${({ wishListClicked }) =>
    wishListClicked ? 'all' : 'none'};
  transition: all ease 0.4s;
  width: 95vw;
  background-color: white;
  max-height: 350px;
  ${media.greaterThan('medium')`

width: 500px;
`};
  scrollbar-width: thin;
  overflow-y: scroll;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgb(133 133 133 / 30%);
`;

export const WishListContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 30px;
`;

export const WishListItem = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr 80px;
  position: relative;
`;
export const WishListItemThumb = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 10px;
  padding-left: 10px;

  img {
    object-fit: contain;

    object-position: 50% 50%;
  }
`;
export const WishListItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 5px;
  .title {
    font-size: 1.2rem;
  }
  .points {
    font-size: 1.3rem;
    font-weight: 600;
    color: #c50101;
  }
`;
export const WishListItemCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    margin-right: 5px;
    border: 1px solid #484848;
    border-radius: 2px;
    appearance: none;
    width: 17px;
    height: 17px;
    cursor: pointer;
    position: relative;

    &:checked {
      background-color: ${({ theme }) => theme.colors.red};
      border: transparent;
      background: ${({ theme }) => theme.colors.red}
        url('data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==')
        3px 3px no-repeat;
    }
  }
`;

export const DataHeader = styled.div`
  margin-top: 30px;
  margin-bottom: 18px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-grow: 3; */
  form {
    display: flex;
    align-items: center;

    width: 340px;

    svg {
      transform: rotate(-90deg);
      fill: ${({ theme }) => theme.colors.grayDarker};
    }

    span {
      margin-right: 10px;
      font-weight: 700;
      font-size: 1.6rem;
      color: ${({ theme }) => theme.colors.grayDarker};
    }
  }
  p {
    font-size: 1.4rem;
  }
`;

export const MyWishListWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100px;
`;
