import { Form } from '@unform/web';
import { useState, useEffect } from 'react';
import { Button } from '~/components/NexAdmin/Buttons';
import { Input } from '~/components/NexAdmin/Inputs';
import * as S from '../../styles';
import ThreadInfo from './ThreadInfo';
import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { showNotification } from '~/utils/notificationBoxes';

const Threads = ({ ticket, loading, setLoading }) => {
  const [thread, setThread] = useState({ threads: ticket.threads });
  const [newThread, setNewThread] = useState(null);
  const [message, setMessage] = useState('');

  const refreshThreads = () => {
    setLoading(true);
    api.get(`/threads/${ticket.id}`).then((response) => {
      setThread({ ...thread, threads: response.data[0] });
      setLoading(false);
    });
  };
  const createThread = (data) => {
    setLoading(true);
    if (!data.message)
      return showNotification('warning', 'Adicione uma mensagem válida!');
    api
      .post(`/tickets/thread/admin-create/${ticket.id}`, { message })
      .then(() => {
        setLoading(false);
        showNotification('success', 'Thread atualizada com sucesso!');
        refreshThreads();
        setMessage('');
        setThread({ ...thread });
      })
      .catch((err) => {
        setLoading(false);
        return HTTPErrorCallback(err);
      });
  };

  return (
    <S.Threads>
      {thread &&
        thread.threads.map((thread, i) => (
          <ThreadInfo
            thread={thread}
            key={i}
            loading={loading}
            setLoading={setLoading}
            refresh={refreshThreads}
          />
        ))}
      {!newThread && (
        <S.ButtonWrapperUpdate>
          <Button type="button" onClick={() => setNewThread(true)}>
            +
          </Button>
        </S.ButtonWrapperUpdate>
      )}
      {newThread && (
        <>
          <Form onSubmit={createThread}>
            <Input
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              label="Nova Mensagem"
              required
            />
            <S.ButtonWrapperUpdate>
              <Button onClick={() => refreshThreads()}>Adicionar</Button>
            </S.ButtonWrapperUpdate>
          </Form>
        </>
      )}
    </S.Threads>
  );
};

export default Threads;
