import styled from 'styled-components';

export const Input = styled.input`
  margin-right: 5px;
  border: 1px solid #484848;
  border-radius: 2px;
  padding: 10px 20px;
  border-radius: 10px;
  appearance: none;
  width: 300px;
  position: relative;
`;
