import * as S from '../styles';
const ListPopUpDescription = () => {
  return (
    <S.ThermWrapper>
      <br />
      Por essa tela é feita a edição do PopUp e extração de relatório. que
      inclui:{' '}
      <strong>
        alterar título, conteúdo e inativação(não mostrar no site)
      </strong>{' '}
      do PopUp.
      <br />
      <br />
      Os filtros disponíveis para busca são:
      <br />
      <br />
      <strong>1.</strong> Título
      <br />
      <strong>2.</strong> Conteúdo
      <br />
      <strong>3.</strong> Status - As opções são: <strong>Ativo</strong>,{' '}
      <strong>Não Ativo</strong> ou <strong>Todos</strong>.
      <br />
      <strong>4.</strong> Canal - As opções são: <strong>Todos</strong> ou os
      respectivos <strong>canais</strong>.
      <br />
      <br />
      Após os filtros, é exibida uma tabela com as informações do PopUp e o
      detalhamento de cada coluna é:.
      <br />
      <br />
      <strong>1.</strong> Ações - Essa coluna possui o ícone de edição do PopUp.
      Caso queria editar alguma informação, clique sobre o ícone e então,
      confirme para abrir o modal de edição. No modal é possível alterar o
      título, conteúdo e status do PopUp. Por esse modal, é feito a ativação ou
      inativação do PopUp no site. Se o status é <strong>Ativo</strong>{' '}
      significa que o PopUp está visível para o usuário. Após finalizar a
      edição, clique em <strong>Atualizar</strong> para salvar as novas
      informações.
      <br />
      <br />
      <strong>2.</strong> Título
      <br />
      <br />
      <strong>3.</strong> Conteúdo
      <br />
      <br />
      <strong>4.</strong> Status - Se <strong>Ativo</strong>, é visível ao
      usuário, se <strong>Inativo</strong> não está visível para o usuário
      <br />
      <br />
      <strong>5.</strong> Criação - Data da criação do PopUp
      <br />
      <br />
      <strong>6.</strong> Atualização - Data da atualização do PopUp, o valor
      inicial é o mesmo da sua data de criação
      <br />
      <br />
      <strong>7.</strong> Canal - Se <strong>Todos</strong>, é visível para
      todos os canais, caso contrario, é visível apenas para o{' '}
      <strong>canal informado</strong>
      <br />
      <br />
      <strong>IMPORTANTE</strong>: A ordem de exibição de PopUp para o{' '}
      <strong>usuário</strong> é decrescente, ou seja, os novos se sobrepõe aos
      antigos.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default ListPopUpDescription;
