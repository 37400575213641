import styled from 'styled-components';

export const Wrapper = styled.div`
  * {
    display: none;
  }
`;

export const Input = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  width: 48px;
  height: 56px;

  background: white;
  border: 1px solid #b0b3c3;
  border-radius: 8px;
  padding: 16px 18px;

  flex: none;
  order: 0;
  flex-grow: 0;

  font-family: Ubuntu;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  color: #415a6c;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type='number'] {
    -moz-appearance: textfield;
  }

  &::placeholder {
    color: #b0b3c3;
  }

  &:focus {
    border: none;
    outline: 1.5px solid red;
  }
`;

export const InputWrapper = styled.div`
  grid-area: ${({ gridArea }) => gridArea};
  margin-left: ${({ margin }) => margin}px;

  display: grid;
  grid-template-columns: repeat(${({ digits }) => digits}, 48px);
  grid-gap: 8px;
`;
