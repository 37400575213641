import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import Loading from '~/components/Loading';
import StatusTag from '~/components/NexAdmin/StatusTag';
import { useState, useRef, useEffect } from 'react';
import { columns_insert_points } from '../columns';
import * as S from '../Components/UserTable/styles';
import { Button } from '~/components/NexAdmin/Buttons/index';
import Counter from '~/components/NexAdmin/Counter';
import { Input } from '~/components/NexAdmin/Inputs/index';
import { Form } from '@unform/web';
import Table from '~/components/NexAdmin/Table';
import { appendObjectInFormData } from '~/utils';
import { toDateTime } from '~/Helpers/Date';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { parseJwt } from '~/services/auth';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import Download from '~/screens/NexAdmin/Sales/Components/Download';
import DownloadHook from '~/hooks/NexAdmin/DownloadHook';
import ConfirmButton from '~/components/NexAdmin/Buttons/ConfirmButton';
import api from '~/services/api';
import InsertPointsGNModal from './modal';
import InsertPointGNDescription from '~/components/NexAdmin/Modal/Descriptions/GN/insertPoints';

const InsertPointsGN = () => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [gnTransactions, setGnTransactions] = useState([]);
  const formRef = useRef();
  const current_user = parseJwt();

  useEffect(() => {
    handleSubmit();
  }, []);

  const { openModal, hideModal } = useModal();

  const openThermsModal = (row) => {
    openModal({
      title: 'Inserção de pontos',
      close: true,
      content: (
        <InsertPointsGNModal
          setLoading={setLoading}
          hideModal={hideModal}
          row={row}
          refresh={handleSubmit}
        />
      ),
    });
  };

  const openThermsModalDescription = () => {
    openModal({
      title: 'Inserção de pontos',
      close: true,
      content: <InsertPointGNDescription />,
    });
  };

  const handleSubmit = async (data) => {
    setLoading(true);
    const params = new URLSearchParams(data);
    api
      .get(`/gn/default/admin/?${params}`)
      .then((response) => {
        setGnTransactions(response.data.data);
        setCount(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  const handleDownload = async () => {
    const data = formRef.current.getData();
    const form = new FormData();
    appendObjectInFormData(form, {
      download: true,
      ...data,
    });
    const params = new URLSearchParams(form);
    return api
      .get(`/gn/default/admin?${params}`, { responseType: 'blob' })
      .catch((error) => HTTPErrorCallback(error));
  };
  const buildReportName = (name) => {
    return `Relatório ${name} - Canal ${localStorage.getItem(
      'canalNome',
    )} - ${toDateTime(new Date())}`;
  };

  const beforeLoading = () => setLoading(true);
  const afterLoading = () => setLoading(false);
  const { execute, ref, urlFile, nameFile } = DownloadHook({
    externalCall: handleDownload,
    onError: HTTPErrorCallback,
    getFileName: buildReportName('GN'),
    beforeLoading,
    afterLoading,
  });

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="config"
          title="Inserção de pontos"
          modalFunction={openThermsModalDescription}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <S.FormWrapper>
                <S.FormFilters>
                  <Input name="cpf" label="CPF:" type="number" />
                  <Input name="regional" label="Regional:" type="text" />
                  <Input name="pdv" label="PDV:" type="text" />
                </S.FormFilters>
                <Button type="submit">Consultar</Button>
              </S.FormWrapper>
              <S.DownloadWrapper>
                <a href={urlFile} download={nameFile} ref={ref} />
                <Download buttonName="Download" external={execute} />
              </S.DownloadWrapper>
            </Form>
            <Counter total={count} />
            <Table
              data={gnTransactions}
              columns={[
                {
                  name: 'Ações',
                  cell: (row) => (
                    <ConfirmButton
                      icon={'add'}
                      iconColor={'green'}
                      label="INSERIR PONTOS?"
                      confirmLabel={'SIM'}
                      cancelLabel="CANCELAR"
                      openOnRight={true}
                      confirmAction={() => openThermsModal(row)}
                    />
                  ),
                },
                ...columns_insert_points,
              ]}
              pagination={true}
              header
              dropdownItems={false}
              hasDownload={false}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default InsertPointsGN;
