import styled from 'styled-components';

export const SearchUser = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: 45px;

  button {
    width: 300px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 45px;
  padding: 30px;

  form {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;
export const Checkbox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  label {
    font-size: 1.4rem;
  }
  input {
    width: 45px;
  }
`;
