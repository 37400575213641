import { useState, useEffect } from 'react';
import api from '~/services/api';
import * as S from './styles';
import { showNotification } from '~/utils/notificationBoxes';

const PopUp = () => {
  const [popUp, setPopUp] = useState([]);

  useEffect(() => {
    getPopUp();
  }, []);

  const getPopUp = () => {
    api
      .get('/popup')
      .then((response) => {
        const popup = response.data;
        setPopUp(popup);
      })
      .catch((error) => {
        // return showNotification('info', '', error);
      });
  };
  return (
    <>
      {popUp.map(
        (item, index) =>
          item.active && (
            <S.Container>
              <button
                className="button"
                type="button"
                onClick={() => {
                  setPopUp((prev) => {
                    const update = [...prev];
                    update[index].active = false;
                    return update;
                  });
                }}
              >
                {' '}
                X{' '}
              </button>
              <p className="title">
                {' '}
                {item.title ? item.title : 'Atenção, Galera Giga!'}
              </p>
              {item.content}
            </S.Container>
          ),
      )}
    </>
  );
};
export default PopUp;
