import styled from 'styled-components';

export const ExpandableRow = styled.th`
  height: 180px;
  padding: 10px 60px;
  border-left: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
`;

export const BorderTop = styled.div`
  height: 1px;
  background: #c2c2c2;
  width: 100%;
  margin-bottom: 20px;
`;

export const StyledDiv = styled.div`
  font-weight: normal;
  justify-content: start;
  margin-bottom: 10px;
  display: flex;
  color: #858585;

  strong {
    margin-right: 5px;
    color: #484848;
  }
`;
