import { toDateTime } from '~/Helpers/Date';

export const buildReportName = (name) => {
  return `Relatório ${name} - Canal ${localStorage.getItem(
    'canalNome',
  )} - ${toDateTime(new Date())}`;
};

export const stringContrinsSpecialCharacter = (string) => {
  //eslint-disable-next-line
  const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  return format.test(string);
};

export const getPasswordSecurityVerifications = (password) => {
  const verifications = {};

  if (password.length >= 8) verifications.hasEnoughLength = true;

  if (/\d/.test(password)) verifications.hasNumber = true;

  if (/[A-Z]/.test(password)) verifications.hasUppercase = true;

  if (stringContrinsSpecialCharacter(password))
    verifications.hasSpecialCharacters = true;

  return verifications;
};
