import Button from '~/components/NexAdmin/Buttons/Button';
import Table from '~/components/NexAdmin/Table/index.js';
import { showNotification } from '~/utils/notificationBoxes';
import * as S from './styles.js';
import columns from './columns';
import api from '~/services/api.js';
import { HTTPErrorCallback } from '~/Helpers/Error.js';

const CreateUsers = ({
  setLoading,
  file,
  data,
  setFile,
  setErrors,
  changeStep,
}) => {
  const create = () => {
    if (!file)
      return showNotification(
        'warning',
        'Atenção',
        'Por favor, selecione um arquivo',
      );

    setLoading(true);

    api
      .post('/create-pap-users/create', data)
      .then(() => {
        setFile(null);
        setErrors(null);
        changeStep('validate');
        setLoading(false);
        return showNotification(
          'success',
          'Sucesso',
          'Usuários criados com sucesso!',
        );
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <>
      <S.Wrapper>
        <h2>
          Clique em enviar para processar o arquivo
          <br />
          <small>Essa ação é irreversível</small>
        </h2>

        <S.InputWrapper>
          <Button type="button" width="100%" onClick={create}>
            Enviar
          </Button>
        </S.InputWrapper>
        <h2>Os dados abaixo serão processados ao enviar</h2>
      </S.Wrapper>
      <Table
        columns={columns}
        data={data}
        dataTableProps={{ pagination: true }}
      />
    </>
  );
};

export default CreateUsers;
