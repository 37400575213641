import { useRef, useState } from 'react';

import { Form } from '@unform/web';

import Input from '~/components/Input';
import api from '~/services/api';
import { showNotification } from '~/utils/notificationBoxes';
import { getPasswordSecurityVerifications } from '~/utils/NexAdmin/utils';
import * as S from './styles';
import { useHistory } from 'react-router-dom';
import { HTTPErrorCallback } from '~/Helpers/Error';
import SafePassword from '../SafePassword';

const CreateAnswers = ({
  questions,
  tempToken,
  usuID,
  channelID,
  optIn,
  setLoading,
  curretPassword,
  usuCargo,
}) => {
  const formRef = useRef(null);
  const history = useHistory();
  const [questionsSelected, setQuestionsSelected] = useState({
    first: null,
    second: null,
    third: null,
  });
  const [checkPassword, setCheckPassword] = useState('');
  const questionNames = ['first', 'second', 'third'];

  const createAnswers = (data) => {
    const { password, confirm_password } = data;
    if (password !== confirm_password)
      return showNotification('danger', 'Atenção', 'As senhas não conferem!');
    const verifications = getPasswordSecurityVerifications(data.password);
    if (
      !verifications.hasEnoughLength ||
      !verifications.hasNumber ||
      !verifications.hasUppercase ||
      !verifications.hasSpecialCharacters
    ) {
      showNotification(
        'danger',
        'Atenção!',
        'Para uma senha segura, certifique-se de ter pelo menos 8 caracteres, incluindo números, letra maiúscula, caracteres especiais como "@" ou "*".',
      );
      return false;
    }

    setLoading(true);

    const answers = [];

    for (const name of questionNames) {
      const answerKey = `${name}_answer`;

      answers.push({
        Id: questionsSelected[name].id,
        Resposta: data[answerKey],
      });
    }
    return api
      .post('/store-secret-answers', {
        temp_token: tempToken,
        usu_id: usuID,
        answers,
        password,
        confirm_password,
        current_password: curretPassword,
      })
      .then(() => {
        setLoading(false);
        showNotification(
          'success',
          'Sucesso',
          'Senha alterada, complete seu cadastro para continuar!',
        );
        if (optIn) {
          history.push({
            pathname: '/optin',
            usu_id: usuID,
            temp_token: tempToken,
            usu_cargo: usuCargo,
          });
        } else {
          localStorage.setItem('userToken', tempToken);
          localStorage.setItem('canal', channelID);
          window.location.href = '/dashboard';
        }
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  const selectQuestion = (name, question_id) => {
    const questionFound = questions.filter(
      (question) =>
        question.id === question_id ||
        parseInt(question.id) === parseInt(question_id),
    )[0];

    if (!questionFound) return;

    setQuestionsSelected({ ...questionsSelected, [name]: questionFound });
  };

  const shoudDisableQuestion = (questionId) => {
    const questionIDsChoosed = [];

    for (const name of questionNames) {
      if (questionsSelected[name])
        questionIDsChoosed.push(questionsSelected[name].id);
    }

    return questionIDsChoosed.includes(questionId);
  };

  const getQuestionName = (index) => {
    switch (index) {
      case 1:
        return 'segunda';
      case 2:
        return 'terceira';

      default:
        return 'primeira';
    }
  };

  return (
    <>
      <S.Wrapper>
        <Form ref={formRef} onSubmit={createAnswers}>
          {questionNames.map((name, i) => (
            <>
              <select
                required
                defaultValue=""
                onChange={(e) => selectQuestion(name, e.target.value)}
              >
                <option value="" disabled>
                  Selecione a {getQuestionName(i)} pergunta
                </option>
                {questions.map((question) => (
                  <option
                    value={question.id}
                    disabled={shoudDisableQuestion(question.id)}
                  >
                    {question.pergunta}
                  </option>
                ))}
              </select>
              <S.InputWrapper>
                <Input
                  type="text"
                  name={`${name}_answer`}
                  className="form-input"
                  label={`Resposta ${getQuestionName(i)} pergunta`}
                  placeholder=""
                  required
                />
              </S.InputWrapper>
            </>
          ))}
          <S.PasswordWrapper>
            <Input
              type="password"
              name="password"
              className="form-input"
              label="Senha"
              placeholder=""
              onChange={(e) => setCheckPassword(e.target.value)}
              required
            />
            <Input
              type="password"
              name="confirm_password"
              className="form-input"
              label="Confirmar Senha"
              placeholder=""
              required
            />
            <SafePassword password={checkPassword} />
          </S.PasswordWrapper>
          <S.Column>
            <S.Button type="submit">Enviar</S.Button>
          </S.Column>
        </Form>
      </S.Wrapper>
    </>
  );
};

export default CreateAnswers;
