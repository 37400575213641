import * as S from './styles';

const Stores = () => {
  return (
    <S.Container>
      <a
        href="https://play.google.com/store/apps/details?id=nex.digital.ClaroAppNex"
        rel="noreferrer"
        target="_blank"
      >
        <img src="/images/google-play-store.png" alt="Google PlayStore" />
      </a>
    </S.Container>
  );
};

export default Stores;
