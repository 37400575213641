import { parseJwt } from '~/services/auth';
import { Route, Redirect } from 'react-router-dom';
import Layout from '~/components/Layout';

const CommonRoute = ({ component: Component, ...rest }) => {
  if (!localStorage.getItem('userToken')) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }

  if (
    localStorage.getItem('canal') === '7' &&
    parseJwt().id !== 1025252 &&
    props.location.pathname === '/calculadora'
  ) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }

  return (
    <Layout>
      <Route {...rest} render={(props) => <Component {...props} />} />
    </Layout>
  );
};

export default CommonRoute;
