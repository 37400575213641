import React from 'react';
import logo from '~assets/Nex/logo-DGTL.svg';
import logoCompact from '~assets/Nex/compact-logo.svg';
import getIcon from '~/Helpers/getIcon';

import * as S from './styles';

const HeaderLeft = ({ opened, setOpened, hoverOpened }) => {
  return (
    <S.Wrapper hoverOpened={hoverOpened} opened={opened}>
      <S.Logo
        hoverOpened={hoverOpened}
        opened={opened}
        src={opened || hoverOpened ? logo : logoCompact}
        alt="Logo Fullbar Digital"
      />
      <S.Hamburguer onClick={() => setOpened(!opened)}>
        {getIcon('hamburguer', '#FFD175')}
      </S.Hamburguer>
    </S.Wrapper>
  );
};

export default HeaderLeft;
