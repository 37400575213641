import * as S from './styles';
const RevenueDescription = () => {
  return (
    <S.ThermWrapper>
      <br />O portal do participante de PAP possui a tela{' '}
      <strong>Receitas</strong>, onde os usuários consultam a evolução de suas
      vendas. Pode-se consultar todos os registros nessa tela.
      <br />
      <br />
      As receitas possuem processo de validação, os status desse processo estão
      disponíveis no campo <strong>Ponto Status</strong>. São disponibilizados
      para resgates apenas os pontos com status como <strong>Aprovado</strong>.
      <br />
      <br />É possível filtrar os dados por meio dos campos disponíveis, sendo
      eles: <strong>De</strong>, <strong>Até</strong>, <strong>Ano</strong>,{' '}
      <strong>Mês</strong>, <strong>CPF</strong>, <strong>Código Venda</strong>{' '}
      e <strong>Ponto Status</strong>.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default RevenueDescription;
