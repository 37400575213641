import styled from 'styled-components/macro';

export const Inner = styled.div`
  border-bottom: 1px thin #999;
  display: grid;
  grid-template-columns: 1fr 50px;
  place-items: center;
`;

export const Input = styled.input`
  padding: 10px 12px;
  width: 100%;
  border: none;
  background-color: transparent;
  transition: 0.6s;
  border-bottom: 2px solid #ffffff00;
  font-size: ${({ theme }) => theme.adminfontsize.searchbar.input};

  outline: none;

  border-bottom: 2px solid ${({ theme }) => theme.oldColors.gray[30]};
`;
export const Line = styled.span`
  position: absolute;
  bottom: 0;
  left: ${(props) => (props.focus ? '0' : '50%')};
  width: ${(props) => (props.focus ? '100%' : 0)};
  height: 2px;
  background-color: ${({ theme }) => theme.oldColors.primary[60]};
  transition: ease-in-out 0.4s;
`;
export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const Close = styled.button`
  svg {
    width: 18px;
  }
`;
