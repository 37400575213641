import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Loading from '~/components/Loading';
import api from '~/services/api';
import { showNotification } from '~/utils/notificationBoxes';

import columns from '../columns';
import * as S from '../styles';
import Table from '~/components/NexAdmin/Table';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import { Button, ConfirmButton } from '~/components/NexAdmin/Buttons';
import getIcon from '~/Helpers/getIcon';
import UpdateForm from '../components/UpdateForm';
import Download from '../components/Download';
import DownloadHook from '~/hooks/NexAdmin/DownloadHook';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { toDateTime } from '~/Helpers/Date';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import ChallengeDescription from '~/components/NexAdmin/Modal/Descriptions/Challenges/challengeDescriptions';

const ListChallenges = () => {
  const fileRef = useRef(null);
  const { openModal } = useModal();
  const [state, setState] = useState();
  const [challenges, setChallenges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedChallenge, setSelectedChallenge] = useState(null);

  useEffect(() => {
    getChallenges();
  }, []);

  const getChallenges = () => {
    setLoading(true);

    api
      .get(`/admin/buscar-desafios`)
      .then((response) => {
        setChallenges(response.data.challenges[0]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        return showNotification(
          'danger',
          'Atenção',
          'Não foi possível encontrar os desafios.',
        );
      });
  };

  const updateChallenge = (data) => {
    setLoading(true);

    if (data.dest_dt_fim < data.dest_dt_ini) {
      return showNotification(
        'warning',
        'Atenção',
        'A data final não pode ser menor do que a data inicial',
      );
    }

    const formData = new FormData();
    formData.append('desafio', data.desafio);
    formData.append('produtos_participantes', data.produtos_participantes);
    formData.append('digital_dt_ini', data.digital_dt_ini);
    formData.append('digital_dt_fim', data.digital_dt_fim);
    formData.append('titulo', data.titulo);

    if (fileRef.current.files[0]) {
      formData.append(
        'extension',
        fileRef.current.files[0].name.split('.').pop(),
      );
      formData.append('file', fileRef.current.files[0]);
    } else {
      formData.append('fileUrl', state.desafio_file);
    }

    api
      .put(`/alterar-desafio/${selectedChallenge.id}`, formData)
      .then(() => {
        setLoading(false);
        showNotification('success', 'Sucesso!', 'Desafio alterado com sucesso');
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
        return showNotification(
          'danger',
          'Atenção',
          'Não foi possivel alterar o desafio, tente novamente mais tarde.',
        );
      });
    return true;
  };

  const deleteChallenge = (id) => {
    setLoading(true);
    api
      .delete(`/delete-desafio/${id}`)
      .then(() => {
        getChallenges();
        showNotification('success', 'Sucesso', 'Desafio deletado com sucesso!');
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        return showNotification(
          'danger',
          'Atenção',
          'Não foi possivel remover o desafio, tente novamente mais tarde.',
        );
      });

    return true;
  };

  const handleDownload = () => {
    return api.get(`/admin/buscar-desafios/?download=true`, {
      responseType: 'blob',
    });
  };

  const buildReportName = (name) => {
    return `Relatório ${name} - Canal ${localStorage.getItem(
      'canalNome',
    )} - ${toDateTime(new Date())}`;
  };

  const beforeLoading = () => setLoading(true);

  const afterLoading = () => setLoading(false);

  const { execute, ref, urlFile, nameFile } = DownloadHook({
    externalCall: handleDownload,
    onError: HTTPErrorCallback,
    getFileName: buildReportName('Desafios'),
    beforeLoading,
    afterLoading,
  });

  const openThermsModal = () => {
    openModal({
      title: 'Desafios',
      close: true,
      content: <ChallengeDescription />,
    });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="actions"
          title="Desafios"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            {selectedChallenge ? (
              <>
                <Button
                  type="button"
                  onClick={() => setSelectedChallenge(null)}
                >
                  Voltar
                </Button>

                <UpdateForm
                  challenge={selectedChallenge}
                  updateChallenge={updateChallenge}
                  fileRef={fileRef}
                />
              </>
            ) : (
              <>
                <S.DownloadWrapper>
                  <a href={urlFile} download={nameFile} ref={ref} />
                  <Download buttonName="Download" external={execute} />
                </S.DownloadWrapper>
                <Table
                  loading={loading}
                  dataTableProps={{
                    pagination: true,
                  }}
                  header
                  dropdownItems={false}
                  hasDownload={false}
                  columns={[
                    {
                      name: 'Ações',
                      cell: (row) => (
                        <>
                          <button
                            onClick={() => {
                              setSelectedChallenge(row);
                            }}
                            title="Editar"
                          >
                            {getIcon('statusSketch')}
                          </button>
                          <ConfirmButton
                            icon={'trash'}
                            iconColor={'red'}
                            title="Deletar"
                            label="DELETAR?"
                            confirmLabel={'SIM'}
                            cancelLabel="CANCELAR"
                            openOnRight={true}
                            confirmAction={() => deleteChallenge(row.id)}
                          />
                        </>
                      ),
                    },
                    ...columns,
                  ]}
                  data={challenges}
                />
              </>
            )}
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default ListChallenges;
