import { useState } from 'react';
import { default as Notification } from '~/components/Notifications/index';

import UseNotification from '~/hooks/NexAdmin/NotificationHook';

const Notifications = () => {
  const [showNotification, setShowNotification] = useState(true);
  const { notifications } = UseNotification();

  return (
    <Notification
      showNotification={showNotification}
      setShowNotification={setShowNotification}
      fullPage={true}
      notifications={notifications}
    />
  );
};

export default Notifications;
