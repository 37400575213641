import styled from 'styled-components';

export const PageButton = styled.button`
  background: transparent !important;
  border-radius: 25px;
  border: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: #484848;
  font-weight: 400;
  font-size: 1.6rem;
  text-decoration: none;
  cursor: pointer;
  &.active {
    color: ${({ theme }) => theme.colors.red};
    font-weight: 700;
  }
`;

export const PageButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 15px 25px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
`;
