import { useEffect, useRef, useState } from 'react';
import { IoCopyOutline as Copy } from 'react-icons/io5';

import cep from 'cep-promise';
import moment from 'moment';

import Input from '~/components/Input';
import UploadImage from '~/components/UploadImage';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import {
  goTopPage,
  validateAge,
  validateBirthDate,
  cepMask,
  cellphoneMask,
} from '~/utils';
import { showNotification } from '~/utils/notificationBoxes';

import * as S from './styles';
import { HTTPErrorCallback } from '~/Helpers/Error';

const badges = [
  {
    id: 1,
    name: 'Bárbaro',
    description: 'de 0 a 49 pontos',
    thumb: '/images/badges/11.png',
  },
  {
    id: 2,
    name: 'Arqueiro',
    description: 'de 55 a 99 pontos',
    thumb: '/images/badges/22.png',
  },
  {
    id: 3,
    name: 'Guerreiro',
    description: 'de 100 a 299 pontos',
    thumb: '/images/badges/33.png',
  },
  {
    id: 4,
    name: 'Cavaleiro',
    description: 'de 300 a 499 pontos',
    thumb: '/images/badges/44.png',
  },
  {
    id: 5,
    name: 'Paladino',
    description: 'de 500 a 699 pontos',
    thumb: '/images/badges/55.png',
  },
  {
    id: 6,
    name: 'Mago',
    description: 'de 700 a 999 pontos',
    thumb: '/images/badges/66.png',
  },
  {
    id: 7,
    name: 'Rei',
    description: 'a partir de 1.000 pontos',
    thumb: '/images/badges/77.png',
  },
];

const Profile = () => {
  const [canUpdateData, setCanUpdateData] = useState(true);
  const formRef = useRef(null);
  const [profileImageFile, setProfileImageFile] = useState('');
  const [cepValue, setCepValue] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [validCep, setValidCep] = useState(false);

  useEffect(() => {
    if (window.location.href.includes('achievements')) {
      window.scrollTo(0, document.body.scrollHeight);
    } else {
      goTopPage();
    }
  }, []);

  useEffect(() => {
    if (cepValue.length >= 8) {
      cep(cepValue)
        .then(() => setValidCep(true))
        .catch(() => {
          setValidCep(false);
          setState('');
          setCity('');

          formRef.current.setData({
            logradouro: '',
            bairro: '',
            cidade: '',
            estado: '',
          });
        });
    }
  }, [cepValue]);

  useEffect(() => {
    api
      .get(`/perfil/buscar/${parseJwt().login}`)
      .then((response) => {
        setCanUpdateData(response.data.data.bloqueiaEdicao === 2);
        setCepValue(response.data?.data?.cep);
        formRef.current.setData(response.data.data);
        formRef.current.setData({
          dataNascimento: moment(response.data.data.dataNascimentoRaw).format(
            'YYYY-MM-DD',
          ),
        });
      })
      .catch((error) => {
        return HTTPErrorCallback(error);
      });
  }, []);

  const verificaCampos = (data, { reset }) => {
    if (data.newPassword !== data.confirmPassword)
      return showNotification(
        'danger',
        'Atenção',
        'Confirmar senha diferente da nova senha',
      );

    const cpf = parseJwt().login;
    api
      .post('/trocar-senha', {
        old_password: data.old_password,
        new_password: data.new_password,
        new_password_confirm: data.new_password_confirm,
      })
      .then(() => {
        reset();
        return showNotification(
          'success',
          'Atenção',
          'Senha alterada com sucesso!',
        );
      })
      .catch((error) => {
        HTTPErrorCallback(error);
      });

    return true;
  };

  const saveData = (data) => {
    if (!data.dataNascimento) {
      showNotification('danger', 'Atenção', 'Insira uma data válida!');
      return true;
    }

    data.bairro = data.bairro.replace(/[ÀÁÂÃÄÅ]/g, 'A');
    data.bairro = data.bairro.replace(/[àáâãäå]/g, 'a');
    data.bairro = data.bairro.replace(/[ÈÉÊË]/g, 'E');
    data.bairro = data.bairro.replace(/[^a-z0-9]/gi, ' ');

    data.complemento = data.complemento.replace(/[ÀÁÂÃÄÅ]/g, 'A');
    data.complemento = data.complemento.replace(/[àáâãäå]/g, 'a');
    data.complemento = data.complemento.replace(/[ÈÉÊË]/g, 'E');
    data.complemento = data.complemento.replace(/[^a-z0-9]/gi, ' ');

    data.logradouro = data.logradouro.replace(/[ÀÁÂÃÄÅ]/g, 'A');
    data.logradouro = data.logradouro.replace(/[àáâãäå]/g, 'a');
    data.logradouro = data.logradouro.replace(/[ÈÉÊË]/g, 'E');
    data.logradouro = data.logradouro.replace(/[^a-z0-9]/gi, ' ');

    if (!validCep) {
      showNotification('danger', 'Atenção', 'CEP inválido!');
      return true;
    }

    api
      .post(`/perfil/salvar/${parseJwt().login}`, {
        ...data,
        profileSrc: profileImageFile,
      })
      .then(() => {
        api.get(`/perfil/buscar/${parseJwt().login}`).then((response) => {
          setCanUpdateData(response.data.data.bloqueiaEdicao === 2);
          formRef.current.setData(response.data.data);
          formRef.current.setData({
            dataNascimento: moment(response.data.data.dataNascimentoRaw).format(
              'YYYY-MM-DD',
            ),
          });
        });
        return showNotification(
          'success',
          'Atenção',
          'Dados alterados com sucesso!',
        );
      })
      .catch((error) => {
        return HTTPErrorCallback(error);
      });

    return true;
  };

  // const copyToClipboard = (str) => {
  //   navigator.clipboard.writeText(str);
  //   return showNotification('success', 'Sucesso', 'O código foi copiado.');
  // };

  // const canShowIndication = () => {
  //   return localStorage.getItem('canal') === '2' && parseJwt().cargo !== 20;
  // };

  const checkAge = (date) => {
    if (!validateAge(date)) {
      formRef.current.setData({ dataNascimento: '' });
      return showNotification(
        'danger',
        'Atenção',
        'É necessário ter mais de 18 anos para continuar.',
      );
    }

    if (!validateBirthDate(date)) {
      formRef.current.setData({ dataNascimento: '' });
      return showNotification(
        'danger',
        'Atenção',
        'O campo de nascimento parece inválido.',
      );
    }

    return true;
  };

  return (
    <>
      <S.Container /*canShowIndication={canShowIndication()}*/>
        <S.EditProfile>
          {!canUpdateData && (
            <S.Disabled>
              <p>
                Para alterações de endereço, entre em contato com nosso SAC.
              </p>
            </S.Disabled>
          )}
          <S.PageTitle>Editar perfil:</S.PageTitle>
          <S.Form ref={formRef} onSubmit={saveData}>
            <S.CollForm>
              <UploadImage
                setValue={(e) => setProfileImageFile(e)}
                disabled={!canUpdateData}
              />
              <Input
                name="celular"
                label="Celular"
                disabled={!canUpdateData}
                required
                onChange={(e) =>
                  formRef.current.setData({
                    celular: cellphoneMask(e.target.value),
                  })
                }
              />
              <Input
                name="email"
                label="E-mail"
                required
                type="email"
                disabled={!canUpdateData}
              />
              <Input
                name="dataNascimento"
                label="Data de nascimento"
                disabled={!canUpdateData}
                type="date"
                onBlur={(e) => checkAge(e.target.value)}
              />

              <Input
                name="cep"
                label="CEP"
                disabled={!canUpdateData}
                onChange={(e) => {
                  setCepValue(e.target.value);
                  if (e.target.value >= 8) {
                    cep(e.target.value)
                      .then((data) => {
                        setState(data.state);
                        setCity(data.city);
                        setValidCep(true);
                        formRef.current.setData({
                          logradouro: data.street,
                          bairro: data.neighborhood,
                          cidade: data.city,
                          estado: data.state,
                        });
                      })
                      .catch(() => {
                        setValidCep(false);
                        setState('');
                        setCity('');
                        formRef.current.setData({
                          logradouro: '',
                          bairro: '',
                          cidade: '',
                          estado: '',
                        });
                      });
                  }
                  formRef.current.setData({ cep: cepMask(e.target.value) });
                  if (state || city) {
                    setCepValue('');
                    setCity('');
                    setState('');
                    formRef.current.setData({
                      logradouro: '',
                      bairro: '',
                      complemento: '',
                      numero: '',
                    });
                  }
                }}
                maxLength={8}
              />
              <Input
                name="logradouro"
                required
                label="Endereço"
                disabled={!canUpdateData && validCep}
              />
              <div className="inline">
                <Input
                  name="numero"
                  required
                  label="Número"
                  disabled={!canUpdateData && validCep}
                />
                <Input
                  name="complemento"
                  label="Complemento"
                  disabled={!canUpdateData && validCep}
                />
              </div>

              <div className="inline">
                <Input
                  name="bairro"
                  label="Bairro"
                  required
                  disabled={!canUpdateData && validCep}
                />
              </div>

              <div className="inline">
                <Input
                  name="estado"
                  required
                  label="Estado"
                  disabled={!canUpdateData && validCep}
                />
                <Input
                  name="cidade"
                  required
                  label="Cidade"
                  disabled={!canUpdateData && validCep}
                />
              </div>
            </S.CollForm>
            <div className="actions">
              <button type="submit" disabled={!canUpdateData}>
                Salvar
              </button>
            </div>
          </S.Form>
        </S.EditProfile>
        {/* <S.Indicate canShowIndication={canShowIndication()}>
          <S.PageTitle>Indique e ganhe:</S.PageTitle>
          <S.PageParagraph>
            <i>Agora você pode ganhar ainda mais pontos no Giga Clube!</i>
            <br />
            <br />É tão fácil quanto vender os melhores planos do mercado: basta
            indicar um participante para o Giga Clube, assim que ele efetuar o
            cadastro e fizer uma venda válida,
            <strong> VOCÊ GANHA 45 PONTOS! </strong>
            <br />
            <br />
            Comece agora, basta seguir os passos abaixo:
            <br />
            <br />
            1. Copie o código de indicação (ele identifica que foi você quem
            indicou);
            <br />
            <br />
            2. Envie para quem você quer indicar ao Giga;
            <br />
            <br />
            3. O seu indicado deverá informar este código no cadastro;
            <br />
            <br />
            <strong>E PRONTO!</strong>
            Assim que ele fizer uma venda válida, você ganha pontos!
            <br />
          </S.PageParagraph>
          <S.CodeWrapper>
            <S.CodeTitle>Seu código é:</S.CodeTitle>
            <S.Code
              id="to-copy"
              onClick={() => copyToClipboard(btoa(parseJwt().id))}
            >
              {btoa(parseJwt().id)}
            </S.Code>
            <S.Copy>
              <Copy
                className="copy"
                size="30px"
                onClick={() => copyToClipboard(btoa(parseJwt().id))}
              />
            </S.Copy>
          </S.CodeWrapper>
        </S.Indicate> */}
        <S.ChangePassword>
          <S.PageTitle>Alterar Senha:</S.PageTitle>
          <S.Form onSubmit={verificaCampos}>
            <Input
              type="password"
              name="old_password"
              label="Senha Atual"
              required
            />
            <Input
              type="password"
              name="new_password"
              label="Nova Senha"
              required
            />
            <Input
              type="password"
              name="new_password_confirm"
              label="Confirmar"
              required
            />
            <div className="actions">
              <button to="#" type="submit">
                Salvar
              </button>
            </div>
          </S.Form>
        </S.ChangePassword>
      </S.Container>
    </>
  );
};

export default Profile;
