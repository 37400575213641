import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  button {
    max-width: 100px;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    width: 100%;
    max-width: 500px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    button {
      max-width: 100%;
    }
  }
`;
