import styled from 'styled-components/macro';

export const CustomHeaderWrapper = styled.div`
  color: ${(props) => props.theme.admincolors.contrast.black};
  margin-bottom: 20px;

  h3 {
    font-size: ${({ theme }) => theme.adminfontsize.statustag.title};
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
  }
`;
