import styled from 'styled-components';
import media from 'styled-media-query';

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.5);
`;

export const Modal = styled.div`
  width: 63%;
  height: 700px;

  background: #ffffff;

  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 12px;

  ${media.lessThan('medium')`
    width: 90%;
    height: 90%;
    overflow: scroll;
    `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  span {
    color: #c50101;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 600;
  }

  div {
    cursor: pointer;
  }
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TitleBlock = styled.div`
  width: 100%;
  padding: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 10px;
  background: #f2f2f2;
`;

export const Title = styled.h2`
  color: #c50101;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 700;
`;

export const Duration = styled.h3`
  font-size: 14px;
  font-family: Poppins;
  font-weight: 700;
  color: #484848;
`;

export const Main = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;

  h3 {
    color: #484848;
    font-size: 14px;
    font-family: Poppins;
  }

  ${media.lessThan('medium')`
    grid-template-columns: 1fr;
  `}
`;

export const Preview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const PreviewBlock = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 12px;
  background: var(--cinza-claro-2, #f2f2f2);
`;

export const PreviewPhoto = styled.div`
  width: 100%;
  height: 295px;

  background: #858585;
  box-shadow: 0px 0px 20px 0px rgba(133, 133, 133, 0.3);

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: #f2f2f2;
    text-align: center;
    font-size: 14px;
    font-family: Poppins;
  }

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const PhotoUpload = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: 100%;
`;

export const UploadBlock = styled.div`
  width: 100%;
  padding: 20px;

  border-radius: 12px;
  border: 1px dashed #858585;
  background: #f2f2f2;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  span {
    color: #484848;
    text-align: center;
    font-size: 12px;
    font-family: Poppins;
  }

  input {
    display: none;
  }
`;

export const Checkbox = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  gap: 5px;

  input {
    width: 19px;
    height: 19px;

    border: 1px solid #858585;
    border-radius: 4px;

    cursor: pointer;
  }

  input:checked {
    background-color: #c50101;
  }

  label {
    color: #484848;
    font-size: 13px;
    font-family: Poppins;
  }
`;

export const Alert = styled.div`
  width: 100%;

  display: flex;
  padding: 20px;
  align-items: center;
  gap: 8px;

  border-radius: 10px;
  background: #c4c4c4;

  color: #484848;
  font-size: 14px;
  font-family: Poppins;

  svg {
    width: 40px;
  }

  ${media.lessThan('medium')`
    svg {
      width: 60px !important;
    }
  `}
`;

export const Buttons = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 20px;

  ${media.lessThan('medium')`
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
  `}
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;

  color: #484848;
  font-size: 14px;
  font-family: Poppins;
`;

export const PhotoStatus = styled.div`
  color: ${({ status }) =>
    status === 'pending'
      ? '#484848'
      : status === 'approved'
      ? '#00C136'
      : '#C50101'};
  font-size: 14px;
  font-family: Poppins;
`;

export const Refusal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  h3 {
    color: #484848;
    font-size: 14px;
    font-family: Poppins;
  }

  span {
    color: #484848;
    font-size: 16px;
    font-family: Poppins;
  }
`;

export const FileBlock = styled.div`
  width: 100%;
  padding: 20px;

  border-radius: 12px;
  background: #f2f2f2;

  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: #484848;
    text-align: center;
    font-size: 12px;
    font-family: Poppins;
  }

  input {
    display: none;
  }

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: 25px;

    a {
      width: 100%;
    }
  `}
`;

export const FileName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;
