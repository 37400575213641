import React, { useEffect, useState } from 'react';
import api from '~/services/api';
import UseAuthentication from '~/hooks/NexAdmin/AuthenticationHook';
import Loading from '~/components/Loading';
import PasswordChangeModal from '~/components/PasswordChangeModal';

export const PasswordChangeContext = React.createContext({});

const PasswordChangeProvider = ({ children }) => {
  const { parseJwt } = UseAuthentication();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!Object.keys(parseJwt).length) return;
    setLoading(true);
    api
      .get(`/checar-senha`)
      .then((response) => {
        setModalOpen(response.data.should_change);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });

    //eslint-disable-next-line
  }, [parseJwt]);
  const contextValue = {};

  return (
    <>
      <PasswordChangeContext.Provider value={contextValue}>
        {loading && <Loading />}
        {modalOpen && <PasswordChangeModal close={() => setModalOpen(false)} />}
        {children}
      </PasswordChangeContext.Provider>
    </>
  );
};

export default PasswordChangeProvider;
