import styled from 'styled-components';
import media from 'styled-media-query';
import { Form } from '@unform/web';

export const FormWrapper = styled(Form)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  max-width: 80%;
  align-items: center;
  justify-content: center;
  button {
    width: 50%;
  }
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 450px 450px 450px;
  grid-gap: 30px;
  padding: 30px;

  ${media.lessThan('1100px')`
  grid-template-columns: 1fr;
  grid-template-rows: 2fr;
`}
`;

export const Card = styled.div`
  border-radius: 25px;
  display: grid;
  grid-template-rows: 50px auto;
  background: white;
  border: 1px solid #f4f4f4;
  padding: 25px;

  h2 {
    text-align: center;
    border-bottom: 1px solid #f2f2f2;
    font-size: ${({ theme }) => theme.adminfontsize.containertitle};
    color: ${({ theme }) => theme.admincolors.contrast.black};
  }
`;
