import Table from '~/components/NexAdmin/Table';
import moment from 'moment';
import * as S from './styles';

const InsertTable = ({
  model_columns,
  model_columns_download,
  data,
  type,
  isFrom = 'usuario',
}) => {
  const title = () => {
    switch (type) {
      case 'error':
        return 'Os dados abaixo contem erros e não serão processados';
      default:
        return `Os dados abaixo serão processados ao enviar. ${
          data.length < 100
            ? 'Exibindo todos os resultados'
            : 'Exibindo os 100 primeiros resultados.'
        }`;
    }
  };

  return (
    <S.TableWrapper>
      <h2>{title()}</h2>
      <Table
        columns={model_columns}
        downloadColumns={model_columns_download}
        data={data}
        dataTableProps={{ pagination: true }}
        header={true}
        fileName={`${type === 'error' ? 'Erro_' : ''}Validação_${
          isFrom === 'usuario' ? 'Usuários' : 'Vendas'
        }_${moment().format('DD-MM-YYYY')}`}
        hasDownload={true}
      />
    </S.TableWrapper>
  );
};

export default InsertTable;
