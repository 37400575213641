import { parseJwt } from '~/services/auth';
import * as S from '../styles';
import RecargaColumnsDesc from './recargaColumnsDesc';
import SIVColumnsDesc from './SIVColumnsDesc';
import AparelhosColumnsDesc from './aparelhosColumnsDesc';

const SalesDescription = () => {
  const user = parseJwt();
  const descriptions = () => {
    switch (Number(user.canal_id)) {
      case 2:
      case 4:
        return <SIVColumnsDesc />;
      case 5:
        return <RecargaColumnsDesc />;
      case 6:
        return <AparelhosColumnsDesc />;
    }
  };

  return (
    <S.ThermWrapper>
      {user.canal_id === 8 ? (
        <>
          <br />
          O canal PAP não possui motor de pontos de vendas, esses são
          disponibilizados como bônus. Nessa tela podemos consultar esses
          pontos.
          <br />
          <br />
          Pode-se filtrar os dados pelos campos <strong>De</strong> e{' '}
          <strong>Até</strong>, para um intervalo de tempo, e também pelos
          campos <strong>CPF</strong> e <strong>Código da Venda</strong>.
          <br />
          <br />
        </>
      ) : (
        <>
          <br />
          Esta tela exibe todas as vendas realizadas no{' '}
          <strong>ciclo atual</strong>
          .
          <br />
          <br />
          Personalize sua busca pelos botões de filtros na tela e então clique
          em “Consultar”.
          <br />
          <br />
          Caso queria fazer o download do relatório, primeiro execute o passo
          acima e, após isso, clique no botão “Download”.
          <br />
          <br />
          Após os botões de filtro e botões de ação, os dados são exibidos em
          uma tabela. É descrito a quantidade de vendas que foram encontradas em
          sua busca e se todos estão sendo exibidos.
          <br />
          <br />
          Caso sua pesquisa encontre mais de 100 resultados, é necessário que
          faça o download do relatório para visualizar todos os produtos
          encontrados.
          <br />
          <br />
          <strong>Descritivo dos filtros:</strong>
          <br />
          <strong>De:</strong> Utilize para indicar uma data mínima.
          <br />
          <strong>Até:</strong> Utilize para indicar uma data máxima.
          <br />
          <strong>CPF:</strong> Utilize para buscar todas as vendas de um único
          usuário pelo seu CPF.
          <br />
          <strong>Código de Venda:</strong> Utilize para buscar uma única venda
          pelo seu ID.
          <br />
          {user.canal_id !== 6 && (
            <>
              <strong>Ponto Status:</strong> Utilize para buscar todas as
              vendas, de todos os usuários, que se encontram em determinado
              status, sendo eles: aprovado ou processado. A opção “Todos” busca
              os pedidos independente de seu status.
              <br />
            </>
          )}
          <br />
          <strong>Descritivo das colunas da tabela:</strong>
          <br />
          {descriptions()}
          <br />
          <br />
        </>
      )}
    </S.ThermWrapper>
  );
};

export default SalesDescription;
