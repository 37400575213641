import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.section`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  margin-top: 30px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.greaterThan('medium')`
  
  padding: 52px 80px;


`};
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 4rem;
  background: linear-gradient(178.18deg, #ff0000 -13.56%, #760000 158.3%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Period = styled.div`
  width: 100%;
  margin: 65px 0;
  display: flex;
  justify-content: space-between;
  padding: 26px 0;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.red};
  border-bottom: 1px solid ${({ theme }) => theme.colors.red};

  p {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.red};
    text-transform: uppercase;
    font-weight: 700;
  }
`;

export const PeriodData = styled.div`
  max-width: 40%;
  p {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.red};
    text-transform: uppercase;
    font-weight: 700;
  }
`;

export const Description = styled.p`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.grayMedium};
  padding: 0 40px;
`;

export const Content = styled.div`
  font-size: 1.6rem;

  h3 {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.grayDarker};
    margin-top: 80px;

    & + h3 {
      margin-top: 15px;
    }
  }

  h5 {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.red};
    margin-bottom: 24px;
  }

  p {
    text-align: left;
    color: ${({ theme }) => theme.colors.grayDarker};

    margin-bottom: 24px;

    span {
      font-weight: 700;
      color: ${({ theme }) => theme.colors.red};
    }
  }
`;
