import ReCAPTCHA from 'react-google-recaptcha';

const ReCaptcha = ({ setReCaptchaToken }) => {
  return (
    <ReCAPTCHA
      sitekey="6LcsbIMpAAAAAI0RkT-7r0mxjRc1Uc7FryCfOsHm"
      onChange={setReCaptchaToken}
      onExpired={() => setReCaptchaToken(null)}
      required
    />
  );
};

export default ReCaptcha;
