const na = 'N/A';
const columns = [
  {
    name: 'Id do Pedido',
    selector: (row) => (row.id_pedido ? row.id_pedido : na),
    sortable: true,
    grow: 1,
    minWidth: '130px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.id_pedido ? row.id_pedido : na}</div>
    ),
  },
  {
    name: 'Data do Pedido',
    selector: (row) => (row.data_pedido ? row.data_pedido : na),
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.data_pedido ? row.data_pedido : na}
      </div>
    ),
  },
  {
    name: 'Hora do Pedido',
    selector: (row) => (row.hora_pedido ? row.hora_pedido : na),
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.hora_pedido ? row.hora_pedido : na}
      </div>
    ),
  },
  {
    name: 'Status',
    selector: (row) => (row.status ? row.status : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.status ? row.status : na}</div>
    ),
  },
  {
    name: 'Fornecedor',
    selector: (row) => (row.fornecedor ? row.fornecedor : na),
    sortable: true,
    grow: 1,
    minWidth: '250px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.fornecedor ? row.fornecedor : na}
      </div>
    ),
  },
  {
    name: 'Produto',
    selector: (row) => (row.produto ? row.produto : na),
    sortable: true,
    grow: 1,
    minWidth: '430px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.produto ? row.produto : na}</div>
    ),
  },
  {
    name: 'Id Usuário',
    selector: (row) => (row.id_usuario ? row.id_usuario : na),
    sortable: true,
    grow: 1,
    minWidth: '130px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.id_usuario ? row.id_usuario : na}
      </div>
    ),
  },
  {
    name: 'Nome',
    selector: (row) => (row.nome ? row.nome : na),
    sortable: true,
    grow: 1,
    minWidth: '300px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.nome ? row.nome : na}</div>
    ),
  },
  {
    name: 'CPF',
    selector: (row) => (row.cpf ? row.cpf : na),
    sortable: true,
    grow: 1,
    minWidth: '140px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.cpf ? row.cpf : na}</div>
    ),
  },
  {
    name: 'Cargo',
    selector: (row) => (row.cargo ? row.cargo : na),
    sortable: true,
    grow: 1,
    minWidth: '140px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.cargo ? row.cargo : na}</div>
    ),
  },
  {
    name: 'E-mail',
    selector: (row) => (row.email ? row.email : na),
    sortable: true,
    grow: 1,
    minWidth: '300px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.email ? row.email : na}</div>
    ),
  },
  {
    name: 'Endereço',
    selector: (row) => (row.endereco ? row.endereco : na),
    sortable: true,
    grow: 1,
    minWidth: '250px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.endereco ? row.endereco : na}</div>
    ),
  },
  {
    name: 'Número',
    selector: (row) => (row.numero ? row.numero : na),
    sortable: true,
    grow: 1,
    minWidth: '100px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.numero ? row.numero : na}</div>
    ),
  },
  {
    name: 'Complemento',
    selector: (row) => (row.complemento ? row.complemento : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.complemento ? row.complemento : na}
      </div>
    ),
  },
  {
    name: 'CEP',
    selector: (row) => (row.cep ? row.cep : na),
    sortable: true,
    grow: 1,
    minWidth: '140px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.cep ? row.cep : na}</div>
    ),
  },
  {
    name: 'Bairro',
    selector: (row) => (row.bairro ? row.bairro : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.bairro ? row.bairro : na}</div>
    ),
  },
  {
    name: 'Cidade',
    selector: (row) => (row.cidade ? row.cidade : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.cidade ? row.cidade : na}</div>
    ),
  },
  {
    name: 'Estado',
    selector: (row) => (row.estado ? row.estado : na),
    sortable: true,
    grow: 1,
    minWidth: '140px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.estado ? row.estado : na}</div>
    ),
  },
  {
    name: 'Regional',
    selector: (row) => (row.regional ? row.regional : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.regional ? row.regional : na}</div>
    ),
  },
  {
    name: 'PDC',
    selector: (row) => (row.pdv ? row.pdv : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.pdv ? row.pdv : na}</div>
    ),
  },
  {
    name: 'Distribuidor',
    selector: (row) => (row.rede ? row.rede : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.rede ? row.rede : na}</div>
    ),
  },
  {
    name: 'Data do Estorno',
    selector: (row) => (row.data_estorno ? row.data_estorno : na),
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.data_estorno ? row.data_estorno : na}
      </div>
    ),
  },
  {
    name: 'Hora do Estorno',
    selector: (row) => (row.hora_estorno ? row.hora_estorno : na),
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.hora_estorno ? row.hora_estorno : na}
      </div>
    ),
  },
  {
    name: 'Valor Produto Ponto',
    selector: (row) => (row.valor_produto_ponto ? row.valor_produto_ponto : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.valor_produto_ponto ? row.valor_produto_ponto : na}
      </div>
    ),
  },
  {
    name: 'Valor Frete Ponto',
    selector: (row) => (row.valor_frete_ponto ? row.valor_frete_ponto : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.valor_frete_ponto ? row.valor_frete_ponto : na}
      </div>
    ),
  },
  {
    name: 'Valor Frete Real',
    selector: (row) => (row.valor_frete_real ? row.valor_frete_real : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.valor_frete_real ? row.valor_frete_real : na}
      </div>
    ),
  },
  {
    name: 'Valor Cash Ponto',
    selector: (row) => (row.valor_cash_ponto ? row.valor_cash_ponto : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.valor_cash_ponto ? row.valor_cash_ponto : na}
      </div>
    ),
  },
  {
    name: 'Valor Cash Real',
    selector: (row) => (row.valor_cash_real ? row.valor_cash_real : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.valor_cash_real ? row.valor_cash_real : na}
      </div>
    ),
  },
  {
    name: 'Valor Total Ponto',
    selector: (row) => (row.valor_total_ponto ? row.valor_total_ponto : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.valor_total_ponto ? row.valor_total_ponto : na}
      </div>
    ),
  },
  {
    name: 'Valor Total Reais',
    selector: (row) => (row.valor_total_reais ? row.valor_total_reais : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.valor_total_reais ? row.valor_total_reais : na}
      </div>
    ),
  },
  {
    name: 'Valor Estorno Ponto',
    selector: (row) => (row.valor_estorno_ponto ? row.valor_estorno_ponto : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.valor_estorno_ponto ? row.valor_estorno_ponto : na}
      </div>
    ),
  },
  {
    name: 'Valor Estorno Real',
    selector: (row) => (row.valor_estorno_real ? row.valor_estorno_real : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.valor_estorno_real ? row.valor_estorno_real : na}
      </div>
    ),
  },
  {
    name: 'Valor Final Do Pedido Ponto',
    selector: (row) =>
      row.valor_final_pedido_ponto ? row.valor_final_pedido_ponto : na,
    sortable: true,
    grow: 1,
    minWidth: '230px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.valor_final_pedido_ponto ? row.valor_final_pedido_ponto : na}
      </div>
    ),
  },
  {
    name: 'Valor Final Do Pedido Real',
    selector: (row) =>
      row.valor_final_pedido_real ? row.valor_final_pedido_real : na,
    sortable: true,
    grow: 1,
    minWidth: '230px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.valor_final_pedido_real ? row.valor_final_pedido_real : na}
      </div>
    ),
  },
  {
    name: 'Valor Faturado Ponto',
    selector: (row) =>
      row.valor_faturado_ponto ? row.valor_faturado_ponto : na,
    sortable: true,
    grow: 1,
    minWidth: '230px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.valor_faturado_ponto ? row.valor_faturado_ponto : na}
      </div>
    ),
  },
  {
    name: 'Valor Faturado Real',
    selector: (row) => (row.valor_faturado_real ? row.valor_faturado_real : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.valor_faturado_real ? row.valor_faturado_real : na}
      </div>
    ),
  },
  {
    name: 'Perfil Detalhe',
    selector: (row) => (row.perfil_detalhe ? row.perfil_detalhe : na),
    sortable: true,
    grow: 1,
    minWidth: '230px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.perfil_detalhe ? row.perfil_detalhe : na}
      </div>
    ),
  },
];

export default columns;
