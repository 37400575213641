import { useEffect, useState } from 'react';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';
import Loading from '~/components/Loading';
import columns from './columns';
import { HTTPErrorCallback } from '~/Helpers/Error';
import api from '~/services/api';
import Table from '~/components/NexAdmin/Table';
import moment from 'moment';

const BlockUsersList = () => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    getSheets();
  }, []);

  const getSheets = () => {
    setLoading(true);

    api
      .get('/admin/usuario/historico-status')
      .then((response) => {
        const updatedData = response.data.listStatus.map((item) => ({
          ...item,
          newStatus: item.newStatus === 'ATUALIZADO' ? 'ATIVO' : item.newStatus,
        }));
        setFiles(updatedData);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag icon="actions" title="Histórico de Bloqueios" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <Table
              columns={columns}
              downloadColumns={columns}
              data={files}
              dataTableProps={{ pagination: true }}
              header={true}
              fileName={`bloqueios_${moment().format('DD-MM-YYYY')}`}
              hasDownload={true}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default BlockUsersList;
