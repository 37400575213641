import { Form as Unform } from '@unform/web';
import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.section`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);

  width: 90vw;

  margin: 17vh auto;
  display: flex;
  position: relative;
  flex-direction: column;

  &:before {
    content: '';
    background-color: red;
    width: 80vw;
    height: 30px;
    position: absolute;
    top: -30px;
    left: 5vw;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:after {
    content: '';
    background-color: red;
    width: 80vw;
    height: 30px;
    position: absolute;
    bottom: -30px;
    left: 5vw;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const CloseBtn = styled.div`
  position: absolute;
  right: 12px;
  top: 40px;
`;

export const Modal = styled.div`
  margin-top: -80px;
  border-radius: 20px;
  max-height: 80vh;
  max-width: 90vw;
  width: 1080px;
  position: relative;
  ${media.greaterThan('medium')`
max-height: 80vh;
width: 1080px;

  `}
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh);
  width: calc(100vw);

  background-color: #00000090;
  iframe {
    height: 50vh;
  }

  z-index: 20;
  iframe {
  }
  h2 {
    color: red;
  }
`;

export const Logo = styled.figure`
  text-align: center;
  position: relative;
  top: -100px;
`;

export const Content = styled.div`
  padding: 0 20px;
  position: relative;
  top: -100px;
`;

export const Info = styled.div`
  text-align: center;
  font-size: 1.4rem;
`;

export const Form = styled(Unform)`
  display: flex;
  padding: 0 20px;
  margin-top: 50px;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  ${media.greaterThan('medium')`
  padding: 0 50px;
flex-direction: row;
width: 1000px;
gap:80px;
`}
`;

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const CollForm = styled.div`
  flex: 1;
  .cep {
    flex-direction: column;
    ${media.greaterThan('medium')`
    width: 60%!important;

  `}
  }

  .input,
  select {
    margin-bottom: 20px;
  }

  select {
    border-radius: 36px;
    border: 1px solid ${({ theme }) => theme.colors.grayDarker};
    padding: 0 16px;
    width: 100%;
    color: #666360;
    display: flex;
    align-items: center;
    height: 50px;
    & + div {
      margin-top: 8px;
    }

    &:parent {
      margin-top: 8px;
      background: #000;
    }
  }

  .actions {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    flex-direction: column;
    gap: 20px;
    ${media.greaterThan('medium')`
  flex-direction: row;
  `}
  }

  .inline {
    display: flex;
    justify-content: space-between;

    flex-direction: column;
    ${media.greaterThan('medium')`
    gap:20px;
  flex-direction: row;
  `}
  }

  button {
    background: ${({ theme }) => theme.colors.grayDarker};

    border-radius: 25px;
    border: 0;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 400;
    font-size: 1.4rem;
    transition: background 0.4s ease-in;
    cursor: pointer;
    &:hover {
      background: ${({ theme }) => theme.colors.degrade};
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
`;

export const Question = styled.div`
  position: relative;
  display: flex;
  .tooltiptext {
    visibility: hidden;
    display: block;
    width: 400px;
    max-width: 84vw;
    padding: 10px 15px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 10;
    bottom: 125%;
    left: ${({ isMobile }) => (isMobile ? '-64vw' : '50%')};
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 99999999;
    font-size: ${({ isMobile }) => (isMobile ? '14px' : '16px')};
    ${({ isMobile }) => (isMobile ? 'transform: translate(20%, 0);' : '')};
  }

  .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    ${({ isMobile }) => (isMobile ? 'right: 20%;' : 'left: 15%;')};
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
`;

export const QuestionIcon = styled.div`
  position: absolute;
  right: 29px;
  top: calc(50% + 5px);
  z-index: 10;
  transform: translateY(-50%);
`;

export const RegulationContainer = styled.div`
  iframe {
    margin-top: 30px;
    body {
      background-color: transparent !important;
    }
  }
`;

export const RegulationWrapper = styled.section`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  padding: 30px 50px;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
`;

export const RegulationPageTitle = styled.h2`
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.colors.red};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  gap: 10px;
`;

export const RegulationButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;
export const RegulationButton = styled.button`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;

  margin-bottom: 20px;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;
