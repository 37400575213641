import styled from 'styled-components/macro';

export const NoDataMessage = styled.div`
  padding: 20px 10px;
  display: grid;
  grid-template-rows: 1fr;
  place-items: center;

  img {
    width: 60px;
  }
`;

export const Wrapper = styled.div`
  margin-top: ${(props) => props.mt};
  .rdt_Table {
    margin-bottom: 1rem;
    text-align: center;
    background-color: transparent;
  }
  .rdt_TableRow {
    background-color: transparent;
    border-radius: 8px;

    border: 1.5px solid ${({ theme }) => theme.oldColors.gray[60]};

    margin: 5px 0px;
    &:hover {
      background-color: ${({ theme }) => theme.oldColors.gray[10]};
    }
  }
  .rdt_TableCol {
    display: flex;
    justify-content: center;
    color: ${({ theme }) => theme.admincolors.contrast.white};
    transition: ${({ theme }) => theme.transition};

    &:hover {
      color: ${({ theme }) => theme.oldColors.gray[30]};
      * {
        color: ${({ theme }) => theme.oldColors.primary[20]};
      }
    }
    text-transform: uppercase;

    text-align: center;

    font-style: normal;
    font-weight: bold;
    font-size: ${({ theme }) => theme.adminfontsize.table.tablecol};
    line-height: 24px;
  }
  .rdt_TableCol_Sortable {
  }
  .rdt_TableCell {
    color: ${({ theme }) => theme.admincolors.contrast.black};
    justify-content: center;
    //styleName: Text-bold-wide;

    font-size: ${({ theme }) => theme.adminfontsize.table.tablecell};
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;

    * {
      text-align: center;
    }
  }
  .rdt_TableHeader {
    background-color: transparent;
  }
  .rdt_TableFooter {
  }
  .rdt_TableHead {
  }
  .rdt_TableHeadRow {
    margin: 5px 0px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.oldColors.gray[60]};
  }
  .rdt_TableBody {
  }
  .rdt_ExpanderRow {
  }
`;
