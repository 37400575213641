import React from 'react';

const Agenda = ({ color }) => {
  return (
    <svg
      width="25"
      height="27"
      viewBox="0 0 25 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8333 24.3335H3.16667V9.66683H21.8333V24.3335ZM17.8333 0.333496V3.00016H7.16667V0.333496H4.5V3.00016H3.16667C1.68667 3.00016 0.5 4.18683 0.5 5.66683V24.3335C0.5 25.0407 0.780951 25.719 1.28105 26.2191C1.78115 26.7192 2.45942 27.0002 3.16667 27.0002H21.8333C22.5406 27.0002 23.2189 26.7192 23.719 26.2191C24.219 25.719 24.5 25.0407 24.5 24.3335V5.66683C24.5 4.95959 24.219 4.28131 23.719 3.78121C23.2189 3.28111 22.5406 3.00016 21.8333 3.00016H20.5V0.333496H17.8333ZM19.1667 15.0002H12.5V21.6668H19.1667V15.0002Z"
        fill={color}
      />
    </svg>
  );
};
export default Agenda;
