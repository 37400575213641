import React from 'react';

import XLSX from 'xlsx';

import { get } from '~/utils';

import * as S from './styles';

const DownloadXLSX = ({
  loading,
  fileName,
  list,
  columns,
  downloadFromOnClick,
  onClick,
  buttonName = 'Download XLSX',
}) => {
  const download = (listToDownload, columnsToDownload) => {
    const dataToDownload = [];

    listToDownload.forEach((item) => {
      const finalObject = {};

      columnsToDownload.forEach((column) => {
        finalObject[column.name] = get(item, column.selector);
      });
      return dataToDownload.push(finalObject);
    });

    const workSheet = XLSX.utils.json_to_sheet(dataToDownload);

    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet');

    XLSX.writeFile(workBook, `${fileName}.xlsx`);
  };

  const onClickDownloadButton = () => {
    if (!downloadFromOnClick) return download(list, columns);

    return onClick();
  };
  return (
    <S.Button
      disabled={loading}
      type="button"
      className=""
      onClick={() => onClickDownloadButton()}
    >
      {loading ? <S.Loader /> : buttonName}
    </S.Button>
  );
};

export default DownloadXLSX;
