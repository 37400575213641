import * as S from './styles';
const Counter = ({ total, showAll }) => {
  const checkBiggerNum = (total) => {
    if (total > 100) {
      if (showAll) {
        return `Encontrado ${total} resultados, exibindo todos.`;
      } else {
        return `Encontrado ${parseNumberPtBR(
          total,
        )} resultados, exibindo os 100 primeiros.`;
      }
    } else if (total > 1) {
      return `Encontrado ${total} resultados, exibindo todos.`;
    } else if (total === 1) {
      return `Encontrado ${total} resultado.`;
    } else {
      return `Não foi encontrado nenhum registro.`;
    }
  };

  const parseNumberPtBR = (number) => {
    const numberString = number.toString();
    const [integerPart, decimalPart] = numberString.split('.');
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const formattedNumber = decimalPart
      ? `${formattedInteger},${decimalPart}`
      : formattedInteger;
    return formattedNumber;
  };

  return (
    <S.CounterWrapper>
      <span>{checkBiggerNum(total)}</span>
    </S.CounterWrapper>
  );
};

export default Counter;
