import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  text-align: center;

  > div {
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin: unset;

    button {
      background-color: rgba(0, 0, 0, 0.3);
      padding: 8px 20px;
      border-radius: 8px;
      width: 130px;
      height: 130px;
      border-radius: 50%;
      font-size: 20px;
      font-weight: bold;
      z-index: 10;
      position: relative;
      cursor: pointer;
    }

    input[type='file'] {
      cursor: pointer;
      position: absolute;
      z-index: 10;
      width: 130px;
      height: 130px;
      left: 0;
      top: 0;
      opacity: 0;
    }

    svg {
      fill: #fff;
      /* stroke: #fff; */
    }

    img {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`;
