import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
`;
export const Selector = styled.button`
  display: grid;
  background: transparent;
  grid-template-columns: 1fr auto auto;
  place-items: center;
  grid-gap: 5px;
  cursor: pointer;
  border: none;

  //styleName: Text-wide;

  font-size: ${({ theme }) => theme.adminfontsize.filterselect};
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.admincolors.contrast.black};
`;
export const CurrentItem = styled.div`
  font-weight: 700;
`;
export const Arrow = styled.div`
  padding: 10px 2px 13px;
`;
export const Dropdown = styled.div`
  position: absolute;
  right: 0;
  background-color: ${({ theme }) => theme.admincolors.contrast.white};

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.oldColors.gray[40]};
  overflow: hidden;
`;
export const DropdownItem = styled.div`
  padding: 10px 20px;
  background-color: ${(props) =>
    props.active && props.theme.oldColors.primary[40]}!important;

  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.oldColors.primary[30]};
  }
`;
