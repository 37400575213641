import Loading from '~/components/Loading';
import { Form } from '@unform/web';
import Input from '~/components/Input';
import * as S from './styles';
import { useState } from 'react';
import SafePassword from '../SafePassword';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { showNotification } from '~/utils/notificationBoxes';
import { cpfcnpjMask } from '~/utils';

const ResetPasswordEmail = ({
  loading,
  setCurrentFormStep,
  api,
  setLoading,
  changePasswordToken,
  history,
  setCPF,
  cpf,
}) => {
  const [checkPassword, setCheckPassword] = useState('');
  const changePassword = (data) => {
    setLoading(true);
    api
      .put(`/reset-password/`, {
        ...data,
        token: changePasswordToken,
      })
      .then((response) => {
        setCurrentFormStep('login');
        setLoading(false);
        showNotification('success', 'Sucesso', response.data?.message ?? '');
        return history.push('/');
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <Form onSubmit={changePassword}>
        <Input
          name="cpf"
          label="Confirme CPF/CNPJ"
          placeholder="CPF/CNPJ"
          value={cpf}
          onChange={(e) => {
            setCPF(cpfcnpjMask(e.target.value));
          }}
        />
        <Input
          type="password"
          name="password"
          label="Nova senha"
          placeholder="DIGITE SUA NOVA SENHA"
          onChange={(e) => setCheckPassword(e.target.value)}
          required
        />
        <Input
          type="password"
          name="confirm_password"
          label="Confirmar nova senha"
          required
          placeholder="CONFIRME SUA NOVA SENHA"
        />
        <S.Column>
          <SafePassword password={checkPassword} />
          <S.Button type="submit">Salvar</S.Button>
        </S.Column>
      </Form>
    </>
  );
};

export default ResetPasswordEmail;
