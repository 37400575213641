import styled from 'styled-components';

export const Container = styled.div`
  grid-area: extract;
  background-color: white;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgb(133 133 133 / 30%);
  position: relative;
  padding: 17px 15px;
`;
export const Title = styled.p`
  margin-left: 5px;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  color: ${({ theme }) => theme.colors.red};
  font-weight: ${({ theme }) => theme.font.medium};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const Message = styled.h1``;

export const MoreButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;
`;
export const MoreButton = styled.button`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;
