import moment from 'moment';

const columns = [
  {
    name: 'Nome',
    selector: (row) => row.usu_nome,
    cell: (row) => <div title={row.usu_nome}>{row.usu_nome}</div>,
    sortable: true,
  },
  {
    name: 'CPF',
    selector: (row) => row.usu_cpf,
    cell: (row) => <div title={row.usu_cpf}>{row.usu_cpf}</div>,
    sortable: true,
  },
  {
    name: 'Novo Status',
    selector: (row) => row.newStatus,
    cell: (row) => <div title={row.newStatus}>{row.newStatus}</div>,
    sortable: true,
  },
  {
    name: 'Usuário Responsável',
    selector: (row) => row.authorId,
    cell: (row) => <div title={row.authorId}>{row.authorId}</div>,
    sortable: true,
  },
  {
    name: 'observacao',
    selector: (row) => row.observacao,
    cell: (row) => <div title={row.observacao}>{row.observacao}</div>,
    sortable: true,
  },
  {
    name: 'Data de Criação',
    selector: (row) => row.created_at,
    cell: (row) => (
      <div title={moment(row.created_at, 'DD/MM/YYYY HH:mm:ss').format('LLL')}>
        {moment(row.created_at, 'DD/MM/YYYY HH:mm:ss').format('LLL')}
      </div>
    ),
    sortable: true,
  },
];

export default columns;
