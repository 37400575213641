import moment from 'moment';

export const columns = [
  {
    name: 'Id',
    selector: (row) => row.dest_id,
    sortable: true,
    maxWidth: '40px',
  },
  {
    name: 'Descrição',
    selector: (row) => row.dest_desc,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.dest_desc ?? 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Início',
    selector: (row) => row.dest_dt_ini,
    sortable: true,
    maxWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>
          {row.dest_dt_ini
            ? moment(new Date(row.dest_dt_ini)).format('DD/MM/YYYY HH:MM:SS')
            : 'N/A'}
        </span>
      </div>
    ),
  },
  {
    name: 'Fim',
    selector: (row) => row.dest_dt_fim,
    sortable: true,
    maxWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>
          {row.dest_dt_fim
            ? moment(new Date(row.dest_dt_fim)).format('DD/MM/YYYY  HH:MM:SS')
            : 'N/A'}
        </span>
      </div>
    ),
  },
  {
    name: 'Ativo',
    selector: (row) => row.dest_ativo,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.dest_ativo === 1 ? 'Sim' : 'Não'}</span>
      </div>
    ),
    maxWidth: '60px',
  },
  {
    name: 'Ordem',
    selector: (row) => row.dest_ord,
    sortable: true,
    maxWidth: '60px',
  },
  {
    name: 'Regional',
    selector: (row) => row.regional,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.regional === '*' ? 'Todas' : row.regional}</span>
      </div>
    ),
  },
  {
    name: 'Rede',
    selector: (row) => row.rede,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.rede === '*' ? 'Todas' : row.rede}</span>
      </div>
    ),
  },
  {
    name: 'Cargo',
    selector: (row) => row.cargo_id,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.cargo_label ? row.cargo_label : 'Todos'}</span>
      </div>
    ),
  },
  {
    name: 'Data de inclusão',
    selector: (row) => row.dt_atualizacao,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>
          {row.dt_atualizacao
            ? moment(new Date(row.dt_atualizacao)).format('DD/MM/YYYY HH:MM:SS')
            : 'N/A'}
        </span>
      </div>
    ),
  },
];

export const columnsDigital = [
  {
    name: 'Id',
    selector: (row) => row.dest_id,
    sortable: true,
    maxWidth: '40px',
  },
  {
    name: 'Descrição',
    selector: (row) => row.dest_desc,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.dest_desc ?? 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Início',
    selector: (row) => row.dest_dt_ini,
    sortable: true,
    maxWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>
          {row.dest_dt_ini
            ? moment(new Date(row.dest_dt_ini)).format('DD/MM/YYYY HH:MM:SS')
            : 'N/A'}
        </span>
      </div>
    ),
  },
  {
    name: 'Fim',
    selector: (row) => row.dest_dt_fim,
    sortable: true,
    maxWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>
          {row.dest_dt_fim
            ? moment(new Date(row.dest_dt_fim)).format('DD/MM/YYYY HH:MM:SS')
            : 'N/A'}
        </span>
      </div>
    ),
  },
  {
    name: 'Ativo',
    selector: (row) => row.dest_ativo,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.dest_ativo === 1 ? 'Sim' : 'Não'}</span>
      </div>
    ),
    maxWidth: '60px',
  },
  {
    name: 'Ordem',
    selector: (row) => row.dest_ord,
    sortable: true,
    maxWidth: '60px',
  },
  {
    name: 'Regional',
    selector: (row) => row.regional,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.regional === '*' ? 'Todas' : row.regional}</span>
      </div>
    ),
  },
  {
    name: 'Rede',
    selector: (row) => row.rede,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.rede === '*' ? 'Todas' : row.rede}</span>
      </div>
    ),
  },
  {
    name: 'Cargo',
    selector: (row) => row.cargo_id,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.cargo_label ? row.cargo_label : 'Todos'}</span>
      </div>
    ),
  },
  {
    name: 'Data de inclusão',
    selector: (row) => row.dt_atualizacao,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>
          {row.dt_atualizacao
            ? moment(new Date(row.dt_atualizacao)).format('DD/MM/YYYY HH:MM:SS')
            : 'N/A'}
        </span>
      </div>
    ),
  },
  {
    name: 'Canal',
    selector: (row) => row.canal_label,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.canal_label ? row.canal_label : 'N/A'}</span>
      </div>
    ),
  },
];
