// import { useState } from 'react';
import { useState } from 'react';

import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import { findByAttibutes } from '~/utils';
import { HTTPErrorCallback } from '~/Helpers/Error';

import * as S from './styles';
import UseTwoFactor from '~/hooks/UseTwoFactor';

const CalculatorTable = ({ plans, points, setPlans, setPoints }) => {
  const [loading, setLoading] = useState(false);
  const [choosedPlans, setChoosedPlans] = useState([]);
  const [resultFromApi, setResultFromApi] = useState({});
  const { requestCode, FEATURES, setCodeLoading } = UseTwoFactor();

  const changeList = (value, willAdd) => {
    const foundPlan = plans[findByAttibutes(plans, 'id', value)];

    if (willAdd) {
      setChoosedPlans((oldArray) => [...oldArray, foundPlan]);
    } else {
      const planToRemove = findByAttibutes(choosedPlans, 'id', value);
      const resultedPlans = choosedPlans;

      const final = resultedPlans.splice(planToRemove, 1);

      setChoosedPlans([...final]);
    }
  };

  const calculate = () => {
    const finalList = [];

    choosedPlans.forEach((choosedPlan) => {
      finalList.push({
        descricao: choosedPlan.plano_nome,
        pontuacao: choosedPlan.valor,
      });
    });

    api.get(`/buscarsaldo/${parseJwt().id}`).then((response) => {
      api
        .post('/calculadora/calcular-pontos', {
          meta: points,
          saldo: parseFloat(
            response.data.data.saldo ? response.data.data.saldo : 0,
          ),
          itens: finalList,
        })
        .then((secondResponse) => {
          setResultFromApi(secondResponse.data);
        });
    });
  };

  const ssoStore = () => {
    requestCode(FEATURES['common-premmiar-sso'], (digits, setOpen) => {
      setLoading(true);
      setCodeLoading(true);
      api
        .get(`/loja/sso/${digits || null}`)
        .then((response) => {
          setOpen(false);
          setLoading(false);
          setCodeLoading(false);
          window.open(response.data.data);
        })
        .catch((error) => {
          setLoading(false);
          setCodeLoading(false);
          HTTPErrorCallback(error);
        });
    });
  };

  return (
    <>
      <S.Container>
        <table cellSpacing="0" cellPadding="0">
          <thead>
            <td> </td>
            <td>Tipo</td>
            <td>Plano</td>
            <td>Mínimo de Pontos</td>
          </thead>
          <tbody>
            {plans.map((plan) => (
              <tr>
                <td>
                  <input
                    type="checkbox"
                    value={plan.id}
                    onChange={(e) =>
                      changeList(e.target.value, e.target.checked)
                    }
                  />
                </td>
                <td>{plan.pt_nome}</td>
                <td>{plan.plano_nome}</td>
                <td>{plan.valor}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr />
        <button type="button" onClick={() => calculate()}>
          Ver Resultado
        </button>
      </S.Container>

      {resultFromApi.message && (
        <S.Container>
          <S.Results>
            <div className="objective">
              <span>Objetivo:</span>
              <div className="points">{resultFromApi.data.meta}</div>
            </div>
            <div className="calculated red">
              <span className="red">Calculados:</span>
              <div className="points red">
                {resultFromApi.data.valorCalculado}
              </div>
            </div>
            <div className="remaining red">
              <span className="red">Faltam:</span>
              <div className="points red">{resultFromApi.data.faltaMeta}</div>
            </div>
          </S.Results>
          <table cellSpacing="0" cellPadding="0">
            <thead>
              <td>Plano Selecionado</td>
              <td>Pontos</td>
              <td>Total de unidades</td>
            </thead>
            <tbody>
              {resultFromApi.data.itens.map((item) => (
                <tr>
                  <td>{item.descricao}</td>
                  <td>{item.pontuacao}</td>
                  <td>{item.quantidade}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr />
          <S.ButtonContainer>
            <button
              type="button"
              onClick={() => {
                setResultFromApi({});
                setChoosedPlans([]);
                setPlans([]);
                setPoints(0);
              }}
            >
              Simular novamente
            </button>
            {parseJwt().canal_id !== 7 && (
              <button type="button" onClick={() => ssoStore()}>
                {loading ? 'Carregando...' : 'Resgatar'}
              </button>
            )}
          </S.ButtonContainer>
        </S.Container>
      )}
    </>
  );
};

export default CalculatorTable;
