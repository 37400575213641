import Title from '../../Components/Title';
import Questions from '../../Components/Questions';
import UseQuizzes from '~/hooks/NexAdmin/QuizzesHook';
import * as S from './styles';
import { useState } from 'react';
import { Button } from '~/components/NexAdmin/Buttons';

const Question = () => {
  const { newQuestion, getLetter } = UseQuizzes();
  const [question, setQuestion] = useState(
    newQuestion.length > 0 ? Array.from(newQuestion, (item) => item) : [0],
  );

  const newQuestionInner = () => {
    setQuestion((prev) => [...prev, question.length]);
  };
  const deleteQuestion = (index) => {
    if (question.length && index < question.length) {
      const remove = question.filter((_, i) => i !== index);
      setQuestion(remove);
    } else {
    }
  };

  return (
    <S.Wrapper>
      <Title title="Configuração de perguntas e respostas" />
      <S.ButtonWrapper>
        <Button onClick={() => newQuestionInner()}>Criar nova pergunta</Button>
      </S.ButtonWrapper>
      {question.map((item, i) => (
        <Questions
          key={i}
          position={item.id || i}
          letter={getLetter}
          onRemove={deleteQuestion}
          questionsLength={question.length}
        />
      ))}
    </S.Wrapper>
  );
};

export default Question;
