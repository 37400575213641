import { useState } from 'react';
import Loading from '~/components/Loading';
import { Input } from '~/components/NexAdmin/Inputs/index';
import { Button } from '~/components/NexAdmin/Buttons';
import { Form } from '@unform/web';
import * as S from './styles';
import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { showNotification } from '~/utils/notificationBoxes';
import Select from '~/components/Select';

const Media = ({
  media,
  setSelectedMedia,
  allMedia,
  reset,
  refusalReasons,
}) => {
  const [loading, setLoading] = useState(false);

  const [selectedReason, setSelectedReason] = useState(null);

  const submit = (status) => {
    setLoading(true);

    api
      .post(`/media-campaign/media/admin/audit`, {
        status,
        media_id: media.id,
        media_refusal_reason_id: selectedReason,
      })
      .then((response) => {
        setLoading(false);

        showNotification('success', 'Sucesso!', 'Mídia auditada com sucesso!');

        setSelectedReason(null);
        next();
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  const next = () => {
    reset();

    const pending = allMedia.filter(
      (e) => e.status === 'pending' && e.id !== media.id,
    );
    const next_media = pending[0];

    if (!next_media) {
      setSelectedMedia(null);
      return;
    }

    setSelectedMedia(next_media);
  };

  return (
    <S.Wrapper>
      {loading && <Loading />}
      <Form onSubmit={submit}>
        <Button
          width="100px"
          type="button"
          onClick={() => setSelectedMedia(null)}
        >
          Voltar
        </Button>
        <S.FormWrapper>
          <Input
            name="usu_nome"
            label="Usuário"
            defaultValue={media.usu_nome}
            disabled
          />

          {media.campaign_type === 'image' ? (
            <img src={media.media_src} alt={media.usu_nome} />
          ) : (
            <video controls>
              <source src={media.media_src} />
            </video>
          )}

          {refusalReasons && (
            <Select
              name="refusal_reason"
              label="Motivo de Reprovação:"
              options={refusalReasons}
              onChange={(e) => setSelectedReason(e.value)}
              defaultValue={refusalReasons.find(
                (item) => item.value === media.refusal_reason_id,
              )}
            />
          )}

          <S.Buttons>
            <Button
              disabled={!selectedReason}
              type="button"
              onClick={() => submit('rejected')}
            >
              Reprovar
            </Button>
            <Button
              disabled={selectedReason}
              type="button"
              onClick={() => next()}
            >
              Pular
            </Button>
            <Button
              disabled={selectedReason}
              type="button"
              onClick={() => submit('approved')}
            >
              Aprovar
            </Button>
          </S.Buttons>
          <Button
            disabled={selectedReason}
            type="button"
            onClick={() => submit('champion')}
          >
            Campeão
          </Button>
        </S.FormWrapper>
      </Form>
    </S.Wrapper>
  );
};

export default Media;
