export const columns = [
  {
    name: 'Nome',
    selector: (row) => row.usu_nome,
    sortable: true,
    grow: 1,
    minWidth: '300px',
    cell: (row) => <div data-tag="allowRowEvents">{row.usu_nome}</div>,
  },
  {
    name: 'CPF',
    selector: (row) => row.cpf,
    sortable: true,
    grow: 1,
    minWidth: '160px',
    cell: (row) => <div data-tag="allowRowEvents">{row.cpf}</div>,
  },
  {
    name: 'Código',
    selector: (row) => window.btoa(row.usu_id),
    sortable: true,
    grow: 1,
    minWidth: '160px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{window.btoa(row.usu_id)}</div>
    ),
  },
  {
    name: 'Cargo',
    selector: (row) => row.cargo,
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => <div data-tag="allowRowEvents">{row.cargo}</div>,
  },
  {
    name: 'Regional',
    selector: (row) => row.regional,
    sortable: true,
    grow: 1,
    cell: (row) => <div data-tag="allowRowEvents">{row.regional}</div>,
  },
  {
    name: 'PDV',
    selector: (row) => row.pdv,
    sortable: true,
    grow: 1,
    cell: (row) => <div data-tag="allowRowEvents">{row.pdv}</div>,
  },
  {
    name: 'Rede',
    selector: (row) => row.rede,
    sortable: true,
    grow: 1,
    cell: (row) => <div data-tag="allowRowEvents">{row.rede}</div>,
  },
];

export const indicatedColumns = [
  {
    name: 'Nome',
    selector: 'usu_nome',
    sortable: true,
    grow: 1,
    minWidth: '300px',
    cell: (row) => <div data-tag="allowRowEvents">{row.usu_nome}</div>,
  },
  {
    name: 'CPF',
    selector: 'cpf',
    sortable: true,
    grow: 1,
    minWidth: '160px',
    cell: (row) => <div data-tag="allowRowEvents">{row.cpf}</div>,
  },
  {
    name: 'Cargo',
    selector: 'cargo',

    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => <div data-tag="allowRowEvents">{row.cargo}</div>,
  },
  {
    name: 'Regional',
    selector: 'regional',
    sortable: true,
    grow: 1,
    cell: (row) => <div data-tag="allowRowEvents">{row.regional}</div>,
  },
  {
    name: 'PDV',
    selector: 'pdv',
    sortable: true,
    grow: 1,
    cell: (row) => <div data-tag="allowRowEvents">{row.pdv}</div>,
  },
  {
    name: 'Rede',
    selector: 'rede',
    sortable: true,
    grow: 1,
    cell: (row) => <div data-tag="allowRowEvents">{row.rede}</div>,
  },
];
