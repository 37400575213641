import React from 'react';
import Whats from '~components/Global/Icons/Whats';
import Chat from '~components/Global/Icons/Chat';
import Time from '~components/Global/Icons/Time';
import NoCam from '~components/Global/Icons/NoCam';
import Upload from '~components/Global/Icons/Upload';
import NewGuest from '~components/Global/Icons/NewGuest';
import Camera from '~components/Global/Icons/Camera';
import Star from '~components/Global/Icons/Star';
import FilledUser from '~components/Global/Icons/FilledUser';
import FilledHome from '~components/Global/Icons/FilledHome';
import BackArrow from '~components/Global/Icons/BackArrow';
import Url from '~components/Global/Icons/Url';
import Access from '~components/Global/Icons/Access';
import Add from '~components/Global/Icons/Add';
import Bv from '~components/Global/Icons/Bv';
import Note from '~components/Global/Icons/Note';
import CloseFullScreen from '~components/Global/Icons/CloseFullScreen';
import OpenFullScreen from '~components/Global/Icons/OpenFullScreen';
import Close from '~components/Global/Icons/Close';
import Actions from '~components/Global/Icons/Actions';
import Edit from '~components/Global/Icons/Edit';
import Duplicate from '~components/Global/Icons/Duplicate';
import Trash from '~components/Global/Icons/Trash';
import StatusSketch from '~components/Global/Icons/StatusSketch';
import StatusArchived from '~components/Global/Icons/StatusArchived';
import NewProject from '~components/Global/Icons/NewProject';
import Back from '~components/Global/Icons/Back';
import Save from '~components/Global/Icons/Save';
import Print from '~components/Global/Icons/Print';
import Price from '~components/Global/Icons/Price';
import Info from '~components/Global/Icons/Info';
import Success from '~components/Global/Icons/Success';
import Danger from '~components/Global/Icons/Danger';
import Warning from '~components/Global/Icons/Warning';
import StatusApproved from '~components/Global/Icons/StatusApproved';
import Config from '~components/Global/Icons/Config';
import Projects from '~components/Global/Icons/Projects';
import LoginArrow from '~components/Global/Icons/LoginArrow';
import Logout from '~components/Global/Icons/Logout';
import Notification from '~components/Global/Icons/Notification';
import User from '~components/Global/Icons/User';
import Magnifier from '~components/Global/Icons/Magnifier';
import Home from '~components/Global/Icons/Home';
import Hamburguer from '~components/Global/Icons/Hamburguer';
import Arrow from '~components/Global/Icons/Arrow';
import Dashboard from '~components/Global/Icons/Dashboard';
import HideOn from '~components/Global/Icons/HideOn';
import HideOff from '~components/Global/Icons/HideOff';
import Calculator from '~components/Global/Icons/Calculator';
import Drag from '~components/Global/Icons/Drag';
import Refresh from '~components/Global/Icons/Refresh';
import Send from '~components/Global/Icons/Send';
import Emoji from '~components/Global/Icons/Emoji';
import VerticalDots from '~components/Global/Icons/VerticalDots';
import Agenda from '~components/Global/Icons/Agenda';
import Download from '~components/Global/Icons/Download';
import Disconnect from '~components/Global/Icons/Disconnect';
import NoData from '~components/Global/Icons/NoData';
import Interrogation from '~components/Global/Icons/Interrogation';
import Check from '~components/Global/Icons/Check';
import Survey from '~components/Global/Icons/Survey';
import CameraPlus from '~components/Global/Icons/CameraPlus';
import Flag from '~components/Global/Icons/Flag';
import Database from '~components/Global/Icons/Database';
import ChartBar from '~components/Global/Icons/ChartBar';
import Book from '~components/Global/Icons/Book';
import Monitor from '~components/Global/Icons/Monitor';
import GoalsResults from '~components/Global/Icons/GoalsResults';
import PostMod from '~/components/Global/Icons/PostMod';
import Posts from '~/components/Global/Icons/Posts';
import NotificationSign from '~/components/Global/Icons/NotificationSign';
import Profile from '~/components/Global/Icons/Profile';
import NotificationBell from '~/components/Global/Icons/NotificationBell';
import MyPoints from '~/components/Global/Icons/MyPoints';
import HamburguerCommon from '~/components/Global/Icons/HamburguerCommon';
import Accelerator from '~/components/Global/Icons/Accelerator';
import XXX from '~/components/Global/Icons/XXX';
import PadLock from '~/components/Global/Icons/PadLock';
import SmallXXX from '~/components/Global/Icons/SmallXXX';
import Media from '~/components/Global/Icons/Media';
import CloudUpload from '~/components/Global/Icons/CloudUpload';
import TrainningBook from '~/components/Global/Icons/TrainingBook';
import Information from '~/components/Global/Icons/Information';
import PlusSign from '~/components/Global/Icons/PlusSign';
import MinusSign from '~/components/Global/Icons/MinusSign';

const getIcon = (iconName, color, all) => {
  const fill =
    color ||
    (window.location.pathname.includes('admin') ? '#323133' : '#4B5E7A');

  const obj = {
    whats: <Whats color={fill} />,
    chat: <Chat color={fill} />,
    time: <Time color={fill} />,
    noCam: <NoCam color={fill} />,
    upload: <Upload color={fill} />,
    cloudUpload: <CloudUpload color={fill} />,
    newGuest: <NewGuest color={fill} />,
    camera: <Camera color={fill} />,
    star: <Star color={fill} />,
    filedUser: <FilledUser color={fill} />,
    filedHome: <FilledHome color={fill} />,
    backArrow: <BackArrow color={fill} />,
    url: <Url color={fill} />,
    access: <Access color={fill} />,
    add: <Add color={fill} />,
    bv: <Bv color={fill} />,
    note: <Note color={fill} />,
    closeFullscreen: <CloseFullScreen color={fill} />,
    openFullscreen: <OpenFullScreen color={fill} />,
    close: <Close color={fill} />,
    actions: <Actions color={fill} />,
    edit: <Edit color={fill} />,
    duplicate: <Duplicate color={fill} />,
    trash: <Trash color={fill} />,
    statusSketch: <StatusSketch color={fill} />,
    statusArchived: <StatusArchived color={fill} />,
    newProject: <NewProject color={fill} />,
    back: <Back color={fill} />,
    save: <Save color={fill} />,
    print: <Print color={fill} />,
    price: <Price color={fill} />,
    info: <Info color={fill} />,
    success: <Success color={fill} />,
    danger: <Danger color={fill} />,
    warning: <Warning color={fill} />,
    statusApproved: <StatusApproved color={fill} />,
    config: <Config color={fill} />,
    projects: <Projects color={fill} />,
    rightArrow: <LoginArrow color={fill} />,
    logout: <Logout color={fill} />,
    notification: <Notification color={fill} />,
    user: <User color={fill} />,
    magnifier: <Magnifier color={fill} />,
    home: <Home color={fill} />,
    hamburguer: <Hamburguer color={fill} />,
    downArrow: <Arrow color={fill} />,
    dashboard: <Dashboard color={fill} />,
    hideOn: <HideOn color={fill} />,
    hideOff: <HideOff color={fill} />,
    calculator: <Calculator color={fill} />,
    drag: <Drag color={fill} />,
    refresh: <Refresh color={fill} />,
    send: <Send color={fill} />,
    emoji: <Emoji color={fill} />,
    verticalDots: <VerticalDots color={fill} />,
    agenda: <Agenda color={fill} />,
    download: <Download color={fill} />,
    disconnect: <Disconnect color={fill} />,
    noData: <NoData color={fill} />,
    interrogation: <Interrogation color={fill} />,
    check: <Check color={fill} />,
    survey: <Survey color={fill} />,
    cameraPlus: <CameraPlus color={fill} />,
    flag: <Flag color={fill} />,
    database: <Database color={fill} />,
    chartBar: <ChartBar color={fill} />,
    book: <Book color={fill} />,
    monitor: <Monitor color={fill} />,
    goalsResults: <GoalsResults color={fill} />,
    postMod: <PostMod color={fill} />,
    posts: <Posts color={fill} />,
    notificationSign: <NotificationSign />,
    profile: <Profile />,
    notificationBell: <NotificationBell />,
    myPoints: <MyPoints />,
    hamburguerCommon: <HamburguerCommon />,
    accelerator: <Accelerator color={fill} />,
    xxx: <XXX color={fill} />,
    padlock: <PadLock color={fill} />,
    smallXXX: <SmallXXX color={fill} />,
    media: <Media color={fill} />,
    trainningBook: <TrainningBook color={fill} />,
    information: <Information color={fill} />,
    plusSign: <PlusSign color={fill} />,
    minusSign: <MinusSign color={fill} />,
  };

  return all ? obj : obj[iconName];
};

export default getIcon;
