import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgb(133 133 133 / 30%);
  position: relative;
  background-color: linear-gradient(0deg, #fff 0%, #fff 100%),
    lightgray -353.133px 0px / 161.202% 113.66% no-repeat;
  ${media.lessThan('medium')`
        flex-direction: column;
        align-items: flex-start;
    `}
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  ${media.lessThan('medium')`
        flex-direction: column;
        align-items: flex-start;
    `}
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.red};
  text-align: center;
  font-size: 24px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  padding-right: 13px;
  color: ${({ theme }) => theme.colors.red};
  text-align: center;
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;

  svg {
    transform: rotate(-180deg);
  }
`;

export const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
export const ProgressBar = styled.div`
  height: 7px;
  width: 100%;
  background: '#f2f2f2';
  border: 1px solid #c4c4c4;
  border-radius: 100px;
`;
export const ProgressBarInner = styled.div`
  background: ${({ theme }) => theme.colors.red};
  border-radius: 100px;
  width: ${({ progress }) => `${progress}%`};
  height: 7px;
`;
export const ProgressText = styled.div`
  color: ${({ theme }) => theme.colors.red};
  font-size: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const ProgressWrapper = styled.div`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 7px;
`;

export const Status = styled.div`
  display: flex;
  flex-direction: row;
  gap: 7px;
  color: ${({ progress, theme }) =>
    progress === 100 ? theme.colors.green : theme.colors.grayMedium};
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-items: center;
`;

export const Ball = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 999px;
  background: ${({ progress, theme }) =>
    progress === 100 ? theme.colors.green : theme.colors.grayMedium};
`;

export const TrainingInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
  align-items: flex-start;
  ${media.lessThan('medium')`
        margin-bottom: 50px;
        flex-direction: column;
        align-items: center;
        width: 100%;
    `}
`;

export const Content = styled.div`
  width: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgb(133 133 133 / 30%);
  position: relative;
  color: ${({ theme }) => theme.colors.grayMedium};
  background-color: linear-gradient(0deg, #fff 0%, #fff 100%),
    lightgray -353.133px 0px / 161.202% 113.66% no-repeat;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgb(133 133 133 / 30%);
  position: relative;
  background-color: linear-gradient(0deg, #fff 0%, #fff 100%),
    lightgray -353.133px 0px / 161.202% 113.66% no-repeat;
  ${media.lessThan('medium')`
        justify-content: center;
    `}
`;

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${media.lessThan('medium')`
        flex-direction: column;
        align-items: center;
        gap: 10px;
    `}
`;

export const BoxLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: ${({ theme }) => theme.colors.grayDarker};
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  ${media.lessThan('medium')`
        width: 100%;
    `}
`;

export const BoxRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  width: 50%;
  .b {
    width: 70%;
    height: 40px;
  }
  ${media.lessThan('medium')`
        width: 100%;
    `}
`;

export const ProgressBox = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 10px;
  background: ${({ background }) => background};
  color: ${({ type }) => type};
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const ConfirmWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 7px;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.colors.grayDarker};
`;

export const ConfirmButton = styled.button`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  height: 40px;
  border: 0;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: box-shadow 0.2s;
  text-decoration: none;
  width: ${(props) => (props.full ? '100%' : 'auto')};
  cursor: pointer;
  svg {
    width: 2.3rem;
    height: 2.3rem;
    margin-right: 10px;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;
