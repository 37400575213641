import styled from 'styled-components';

export const Wrapper = styled.li`
  /* background-color: ${({ theme }) => theme.colors.grayLight}; */
  & + li {
    margin-top: 10px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grayLight};
  padding: 3px 28px;
  border-radius: 25px;
  cursor: pointer;
  position: relative;
  /* z-index: 10; */
`;

export const Title = styled.h1`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.red};
  text-align: center;
`;

export const ArrowButton = styled.button`
  background-color: transparent !important;
`;
export const ArrowButtonTitle = styled.button`
  background-color: transparent !important;
  position: absolute;
  right: 7px;
  cursor: pointer;
`;

export const Description = styled.div`
  /* transition: height 0.3s; */
  border-left: 1px solid ${({ theme }) => theme.colors.grayLight};
  border-right: 1px solid ${({ theme }) => theme.colors.grayLight};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayLight};
  position: relative;
  top: -15px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  overflow: hidden;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);

  &.opened {
    padding: 28px 0px 18px;
    visibility: visible;
    overflow-x: auto;
    visibility: visible;
    overflow-x: auto;
  }

  &.closed {
    overflow: hidden;
    height: 0;
    visibility: hidden;
  }

  table {
    /* background-color: #ccc; */
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.grayDarker};

    thead {
      font-weight: 700;
      font-size: 1.6rem;
      button {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.red};
        font-weight: 700;
        padding: 5px;
        cursor: pointer;
      }
    }
    svg {
      fill: ${({ theme }) => theme.colors.red};
    }
  }
`;

export const SubInfo = styled.div`
  background-color: ${({ theme }) => theme.colors.grayLight};
  overflow: hidden;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 25px;
  margin-bottom: 20px;

  &.opened {
    padding: 28px 0px 28px;
    visibility: visible;
    overflow-x: auto;
  }

  &.closed {
    overflow: hidden;
    height: 0;
    visibility: hidden;
  }

  table {
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.grayDarker};

    thead {
      font-weight: 700;
      font-size: 1.6rem;
      border-bottom: 1px solid ${({ theme }) => theme.colors.red};
      &.more-thead {
        border-bottom: 1px solid #000000;
      }
    }
  }
`;
