import Button from '~/components/NexAdmin/Buttons/Button';
import { Form } from '@unform/web';
import Input from '~/components/NexAdmin/Inputs/Input';
import * as S from './styles';

const Filters = ({ children }) => {
  return <S.CustomHeaderWrapper>{children}</S.CustomHeaderWrapper>;
};

export default Filters;
