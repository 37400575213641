import Loading from '~/components/Loading';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';
import Gigaline from '~/screens/Gigaline';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import * as S from './styles';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import ListPostDescription from '~/components/NexAdmin/Modal/Descriptions/Posts/listPostDescription';

const ListPosts = () => {
  const { openModal } = useModal();
  const openThermsModal = () => {
    openModal({
      title: 'Moderar Post',
      close: true,
      content: <ListPostDescription />,
    });
  };

  return (
    <>
      <HeaderWrapper>
        <StatusTag
          icon="postMod"
          title="Moderar Posts"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle title="Moderar Posts">
            <S.PostsWrapper>
              <Gigaline admin />
            </S.PostsWrapper>
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default ListPosts;
