import * as S from './styles';
const IndicationsDescription = () => {
  return (
    <S.ThermWrapper>
      <br />
      Um indicação ocorre quando um participante que já fez optin no programa
      compartilha o seu código de indicação com um participante que ainda não
      fez optin. Quando este realiza o optin, pode informar o código recebido e
      finalizar seu cadastro.
      <br />
      <br />
      Após esse procedimento, o participante que realizou a indicação, ou seja,
      o que compartilhou o seu código, recebe 45 pontos bônus.
      <br />
      <br />
      Em seu extrato, a nomenclatura dessa pontuação será o primeiro nome do
      segundo participante, ou seja, o indicado.
      <br />
      <br />
      Os participantes que realizaram as indicações são os listados nessa tela,
      pode-se visualizar os seus indicados clicando no ícone de lupa, presente
      na coluna <strong>Ações</strong>.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default IndicationsDescription;
