import * as S from './styles';
import { showNotification } from '~/utils/notificationBoxes';
import { useState } from 'react';
import { HTTPErrorCallback } from '~/Helpers/Error';

const PasswordOption = ({
  cellphone,
  email,
  api,
  setLoading,
  close,
  cpf,
  setCurrentFormStep,
  setType,
  questions,
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const handleCheckboxChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const getToken = () => {
    if (selectedOption === 'perguntas_respostas') {
      setType('perguntas_respostas');
      return close();
    } else {
      setLoading(true);
      api
        .post('/send', { option: selectedOption, usu_cpf: cpf })
        .then((r) => {
          setLoading(false);
          showNotification('success', 'Sucesso', r.data.message);
          setCurrentFormStep('login');
          return close();
        })
        .catch((e) => {
          setLoading(false);
          setCurrentFormStep('login');
          return HTTPErrorCallback(e);
        });
    }
  };

  return (
    <S.Wrapper>
      <S.Description>
        Escolha uma opção para receber seu token:
        <S.Checkbox>
          {cellphone && (
            <label>
              <input
                type="checkbox"
                name="sms"
                value="sms"
                checked={selectedOption === 'sms'}
                onChange={handleCheckboxChange}
              />{' '}
              {cellphone}
            </label>
          )}
          {email && (
            <label>
              <input
                type="checkbox"
                name="email"
                value="email"
                checked={selectedOption === 'email'}
                onChange={handleCheckboxChange}
              />{' '}
              {email}
            </label>
          )}
          {Array.isArray(questions) && (
            <label>
              <input
                type="checkbox"
                name="email"
                value="perguntas_respostas"
                checked={selectedOption === 'perguntas_respostas'}
                onChange={handleCheckboxChange}
              />{' '}
              Perguntas e Respostas
            </label>
          )}
        </S.Checkbox>
      </S.Description>
      <S.Buttons>
        <S.DeleteBalance
          onClick={() => {
            setCurrentFormStep('login');
            close();
            return;
          }}
        >
          Cancelar
        </S.DeleteBalance>
        <S.Button onClick={() => getToken()}>Enviar</S.Button>
      </S.Buttons>
    </S.Wrapper>
  );
};

export default PasswordOption;
