import { Form as unForm } from '@unform/web';
import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  grid-area: gigaline;
  ${media.greaterThan('medium')`
    padding: 0 80px;
    text-align: left;
    max-width: 1080px;

  `};
`;

export const WrapperOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const PreviewWrapper = styled.div`
  cursor: pointer;
  pointer-events: all;
  z-index: 2;
  grid-area: gigaline;

  ${media.greaterThan('medium')`
    padding: 0 0px;
    min-height: 100%;
    text-align: left;
  `};
`;

export const Post = styled.article`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  margin-top: 30px;
  padding: 30px 0 15px;
  text-align: left;
  header {
    display: flex;
    text-align: left;
    /* background-color: #ccc; */
    align-items: center;

    h1 {
      font-size: 2rem;
      text-align: left;
    }

    p {
      font-size: 1.1rem;
      text-align: left;
    }
  }
`;

export const Banner = styled.figure`
  display: flex;
  /* width: 50%; */
  /* ${media.greaterThan('medium')`
    padding: 0 80px;
  `}; */

  img {
    width: 100%;
    height: 100%;
  }
`;

export const FormWrapper = styled.div``;

export const Button = styled.button`
  margin: 0 auto;
  display: block;
  color: #ff0000;
  background: transparent;
  text-decoration: underline;
  margin-top: 50px;
`;

export const FormReActions = styled.ul`
  list-style: none;
  display: flex;
  padding: 10px 16px;
  ${media.greaterThan('medium')`
    padding: 19px 47px;
  `};

  li {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.grayDarker};

    &:nth-child(1) {
      svg {
        fill: #e40707;
      }
    }
    &:nth-child(3) {
      svg {
        fill: #fcc400;
      }
    }

    svg {
      margin-right: 4px;
    }

    & + li {
      margin-left: 12px;
    }
  }
`;

export const Form = styled(unForm)`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  ${media.greaterThan('medium')`
    padding: 19px 47px;
  `};

  button {
    background-color: transparent;
    cursor: pointer;

    svg {
      fill: ${({ theme }) => theme.colors.grayDarker};
    }
  }

  div {
    border-radius: 10px;
    margin-right: 10px;
  }
`;
