const regulationLinks = {
  varejo: {
    bko:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/VAREJO/Regulamento_Varejo-BKO_2024.pdf',
    magazine:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/VAREJO/Regulamento_Varejo_2024_Magalu.pdf',
    default:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/VAREJO/Regulamento_Varejo_2024.pdf',
  },
  agenteAutorizado: {
    default:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/AGENTE_AUTORIZADO/Regulamento_AA_2024.pdf',
  },
  recarga: {
    default:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/DISTRIBUICAO/Regulamento_Recarga_2024.pdf',
  },
  aparelhos: {
    default:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/APARELHOS/Regulamento_Aparelhos_2024.pdf',
  },
  pap: {
    default:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/PAP/Regulamento_PAP_2024.pdf',
    bko:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/PAP/Regulamento_PAP-BKO_2024.pdf',
  },
  pc: {
    premiumBko:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/PAP_PREMIUM/Regulamento_Premium-BKO_2024.pdf',
    condominioBko:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/PAP_CONDOMINIO/Regulamento_Condominio_BKO_2024.pdf',
    premium:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/PAP_PREMIUM/Regulamento_Premium_2024.pdf',
    condominio:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/PAP_CONDOMINIO/Regulamento_Condominio_2024.pdf',
  },
};

export default regulationLinks;
