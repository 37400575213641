/* eslint-disable no-console */
import { useState } from 'react';
import { FaWindowClose as Close } from 'react-icons/fa';

import Button from '~/components/Button';

import * as S from './styles';

const CampaignItem = ({
  complete,
  inactive,
  dataFim,
  produtos,
  desafio,
  saibaMais,
  titulo,
  timeline,
}) => {
  const [showRegulationModal, setShowRegulationModal] = useState(false);

  return (
    <>
      {showRegulationModal && (
        <S.ModalWrapper>
          <S.Modal>
            <S.RegulationWrapper>
              <S.RegulationContainer>
                <S.RegulationPageTitle>
                  <img src="/images/regulation-icon.png" alt="" />
                  Desafio:
                </S.RegulationPageTitle>

                <S.DangerouslySetInnerHTML
                  className="desafio"
                  dangerouslySetInnerHTML={{ __html: desafio }}
                />
                {/* <div width='100%' height='50px'>{desafio}</div> */}

                <S.RegulationButtonWrapper>
                  <S.RegulationButton onClick={() => window.open(saibaMais)}>
                    Baixar desafio
                  </S.RegulationButton>
                </S.RegulationButtonWrapper>
              </S.RegulationContainer>
            </S.RegulationWrapper>
            <S.CloseBtn>
              <Close
                onClick={() => setShowRegulationModal(false)}
                color="red"
                size="20"
              />
            </S.CloseBtn>
          </S.Modal>
        </S.ModalWrapper>
      )}

      <S.Wrapper complete={complete} inactive={inactive}>
        {complete && (
          <img
            className="complete"
            src="/images/giga-checked-stroke.svg"
            alt=""
          />
        )}
        <S.Image>{titulo}</S.Image>
        <S.ProgressWrapper>
          <S.ProgressBar>
            <S.ProgressBarInner progress={timeline} />
          </S.ProgressBar>
          <S.ProgressText>
            válida até:
            {dataFim}
          </S.ProgressText>
        </S.ProgressWrapper>
        <S.DescriptionWrapper>
          <S.UlTitle>Produto Participante: </S.UlTitle>
          <S.Text>{produtos}</S.Text>
          {/* <Button onClick={() => setShowRegulationModal(!showRegulationModal)} full to="/">Saiba Mais</Button> */}
          <Button
            onClick={() => setShowRegulationModal(!showRegulationModal)}
            className="button"
            to="#"
          >
            Saiba Mais
          </Button>
        </S.DescriptionWrapper>
      </S.Wrapper>
    </>
  );
};

export default CampaignItem;
