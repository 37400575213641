import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  margin-top: 30px;
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
