import { useEffect, useState } from 'react';
import * as S from './styles';
import getIcon from '~/Helpers/getIcon';
import api from '~/services/api';
import Loading from '~/components/Loading';
import { HTTPErrorCallback } from '~/Helpers/Error';

const Revision = ({ quizId }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [userAnwser, setUserAnwser] = useState([]);

  useEffect(() => {
    getUserAnwser();
  }, []);

  const getUserAnwser = async () => {
    try {
      const response = await api.get(
        `/quizzes/user/quiz/anwsers?quizz_id=${quizId}`,
      );
      const anwser = response.data;
      setUserAnwser(anwser);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return HTTPErrorCallback(error);
    }
  };
  const getLetter = (number) => {
    return String.fromCharCode(65 + number);
  };

  return (
    <>
      {loading && <Loading />}
      <S.Wrapper>
        <S.HeaderWrapper>
          <S.HeaderLeft>Revisão das perguntas</S.HeaderLeft>
          <button onClick={() => setOpen(!open)}>
            {open
              ? getIcon('minusSign', '#C50101')
              : getIcon('plusSign', '#C50101')}
          </button>
        </S.HeaderWrapper>
        <S.InfoWrapper className={open ? 'opened' : 'closed'}>
          {userAnwser.map((item, i) => (
            <>
              <S.QuestionWrapper>{`Pergunta ${i + 1}`}</S.QuestionWrapper>
              <S.Content>{item.question}</S.Content>
              <S.AnswerWrapper>
                <p>Alternativas</p>
                {item.anwser.map((item, i) => (
                  <S.AnswerContentWrapper
                    key={i}
                    anwser={item.id === item.userAnswer}
                    correctly={item.userHasAccepted}
                  >
                    <S.AnswerContentLetter
                      anwser={item.id === item.userAnswer}
                      correctly={item.userHasAccepted}
                    >
                      {getLetter(i)}
                    </S.AnswerContentLetter>
                    <S.AnswerContent
                      anwser={item.id === item.userAnswer}
                      correctly={item.userHasAccepted}
                    >
                      {item.awnser}
                    </S.AnswerContent>
                  </S.AnswerContentWrapper>
                ))}
              </S.AnswerWrapper>
            </>
          ))}
        </S.InfoWrapper>
      </S.Wrapper>
    </>
  );
};

export default Revision;
