import { Form } from '@unform/web';
import { Input } from '~/components/NexAdmin/Inputs';
import { Button } from '~/components/NexAdmin/Buttons/index';
import { appendObjectInFormData } from '~/utils';
import * as S from './styles';
import api from '~/services/api';
import { showNotification } from '~/utils/notificationBoxes';
import { HTTPErrorCallback } from '~/Helpers/Error';

const GNModal = ({ setLoading, hideModal, row, refresh }) => {
  const repprovedTransaction = (data) => {
    setLoading(true);
    const formData = new FormData();
    appendObjectInFormData(formData, {
      new_status: 4,
      ...data,
    });
    api
      .put(`/gn/default/admin/transactions/${row.id}`, formData)
      .then(() => {
        setLoading(false);
        hideModal();
        showNotification('success', 'Sucesso!', `Campanha reprovada!`);
        refresh();
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  return (
    <Form onSubmit={repprovedTransaction}>
      <S.Wrapper>
        <Input
          name="recused_reason"
          label="Essa informação é opcional"
          type="text"
        />
        <S.ButtonsGrid>
          <Button type="submit">Reprovar</Button>
        </S.ButtonsGrid>
      </S.Wrapper>
    </Form>
  );
};

export default GNModal;
