import { Form } from '@unform/web';
import { Button } from '~/components/NexAdmin/Buttons/index';
import * as S from './styles';
import api from '~/services/api';
import { showNotification } from '~/utils/notificationBoxes';
import { HTTPErrorCallback } from '~/Helpers/Error';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { Input, InputUpload, Select } from '~/components/NexAdmin/Inputs/index';
import { appendObjectInFormData } from '~/utils';

const CampaignExhibitionModal = ({ setLoading, hideModal, row, refresh }) => {
  const [defaultRole, setDefaultRole] = useState(null);
  const [defaultProduct, setDefaultProduct] = useState(null);
  const [defaultRegional, setDefaultRegional] = useState(null);
  const [defaultNet, setDefaultNet] = useState(null);
  const [defaultRegulationProducts, setDefaultRegulationProducts] =
    useState(null);
  const [regulation, setRegulation] = useState(null);
  const [regulationProducts, setRegulationProducts] = useState(null);
  const [role, setRole] = useState([]);
  const [products, setProducts] = useState([]);
  const [regional, setRegional] = useState([]);
  const [net, setNet] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  const buildDefaultValues = (str, arr) => {
    console.log(str, arr);
    const str_arr = str !== 'TODOS' && str?.split(',');
    if (str_arr) {
      for (let i = 0; i < str_arr.length; i++) {
        if (str_arr[i].length) {
          if (str_arr[i] === '*') {
            return [{ label: 'TODOS', value: '*' }];
          } else {
            str_arr[i] = str_arr[i].trim().toUpperCase();
          }
        }
      }
      return arr.filter((obj) => str_arr.includes(obj?.label?.toUpperCase()));
    }
    return [{ label: 'TODOS', value: '*' }];
  };
  const getData = async () => {
    try {
      const response = await api.get(`/campaign-exhibiton/admin/get-products`);
      const { net, product, regional, role, regulation_products } =
        response.data;
      const default_role = buildDefaultValues(row.roles, role);
      setDefaultRole(default_role);
      const default_product = buildDefaultValues(row.products, product);
      setDefaultProduct(default_product);
      const default_regional = buildDefaultValues(row.regional, regional);
      setDefaultRegional(default_regional);
      const default_net = buildDefaultValues(row.net, net);
      setDefaultNet(default_net);
      const default_regulation_products = buildDefaultValues(
        row.regulation_products,
        regulation_products,
      );
      setDefaultRegulationProducts(default_regulation_products);
      const base = { label: 'TODOS', value: '*' };
      setProducts(product);
      setRegional([base, ...regional]);
      setRole([base, ...role]);
      setNet([base, ...net]);
      setRegulationProducts([base, ...regulation_products]);
      setLoading(false);
    } catch (error) {
      return HTTPErrorCallback(error);
    }
  };
  const update = (data) => {
    setLoading(true);
    const formData = new FormData();
    appendObjectInFormData(formData, {
      regulation_file: regulation,
      ...data,
    });
    api
      .put(`/campaign-exhibiton/admin/${row.id}`, formData)
      .then(() => {
        showNotification(
          'success',
          'Sucesso!',
          'Campanha atualizada com sucesso!',
        );
        hideModal();
        refresh();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };
  return (
    <Form onSubmit={update}>
      <S.Wrapper>
        <S.InputWrapper>
          <Input
            label="Nome da Campanha"
            name="name"
            required
            defaultValue={row.name}
          />
          <Input
            label="Descrição"
            name="description"
            required
            textArea
            defaultValue={row.description}
          />
          {defaultRole && (
            <Select
              isMulti
              name="roles"
              options={role}
              label="Cargos"
              large
              placeholder="Selecione os cargos"
              defaultValue={defaultRole}
            />
          )}
          {defaultProduct && (
            <Select
              isMulti
              name="products"
              options={products}
              label="Produtos:"
              placeholder="Selecione os produtos"
              large
              maxWidth="400px"
              defaultValue={defaultProduct}
            />
          )}
          {defaultRegulationProducts && (
            <Select
              isMulti
              name="regulation_products"
              options={regulationProducts}
              label="Produtos Regulamento:"
              placeholder="Selecione os produtos"
              large
              maxWidth="400px"
              defaultValue={defaultRegulationProducts}
            />
          )}
        </S.InputWrapper>
        <S.InputWrapper>
          {defaultRegional && (
            <Select
              isMulti
              name="regional"
              options={regional}
              label="Regional:"
              placeholder="Selecione a regional"
              large
              maxWidth="400px"
              defaultValue={defaultRegional}
            />
          )}
          {defaultNet && (
            <Select
              isMulti
              name="net"
              options={net}
              label="Rede:"
              placeholder="Selecione a rede"
              large
              maxWidth="400px"
              defaultValue={defaultNet}
            />
          )}
          <Input
            label="Data Inicial:"
            name="initial_date"
            type="date"
            required
            defaultValue={moment(row.initial_date).format('YYYY-MM-DD')}
          />
          <Input
            label="Data Final:"
            name="final_date"
            type="date"
            required
            defaultValue={moment(row.final_date).format('YYYY-MM-DD')}
          />
          <InputUpload
            name="arquivo"
            mt="10px"
            onGetFile={setRegulation}
            placeholder="Selecione um regulamento"
            accept="application/pdf"
          />
        </S.InputWrapper>
        <div />
        <Button type="submit" width="30%">
          Atualizar
        </Button>
      </S.Wrapper>
    </Form>
  );
};

export default CampaignExhibitionModal;
