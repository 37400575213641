export const columns = [
  {
    name: 'CPF',
    selector: 'cpf',
  },
  {
    name: 'Descrição',
    selector: 'desc',
  },
  {
    name: 'Pontos',
    selector: 'val',
  },
];

export const list = [
  {
    cpf: '12345678910',
    desc: 'Pontos de Teste',
    val: '10',
  },
  {
    cpf: '23456789012',
    desc: 'Pontos de Teste',
    val: '20',
  },
];

export const columns_pap_pc = [
  {
    name: 'ID usuário',
    selector: 'user_id',
  },
  {
    name: 'Descrição',
    selector: 'desc',
  },
  {
    name: 'Pontos',
    selector: 'points',
  },
];

export const list_pap_pc = [
  {
    user_id: '123345',
    desc: 'Pontos de Teste',
    points: '10',
  },
  {
    user_id: '222443',
    desc: 'Pontos de Teste',
    points: '20',
  },
];
