import React from 'react';

const Media = ({ color }) => {
  return (
    <svg
      width="33"
      height="34"
      viewBox="0 0 33 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="mdi:panorama-variant-outline">
        <path
          id="Vector"
          d="M28.875 6.22168C28.05 6.22168 23.925 8.28418 16.5 8.28418C9.2125 8.28418 4.8125 6.22168 4.125 6.22168C3.4375 6.22168 2.75 6.77168 2.75 7.59668V26.8467C2.75 27.6717 3.4375 28.2217 4.125 28.2217C4.95 28.2217 8.9375 26.1592 16.5 26.1592C23.925 26.1592 28.05 28.2217 28.875 28.2217C29.5625 28.2217 30.25 27.6717 30.25 26.8467V7.59668C30.25 6.77168 29.5625 6.22168 28.875 6.22168ZM27.5 24.9217C24.75 24.0967 21.175 23.4092 16.5 23.4092C11.825 23.4092 8.25 24.0967 5.5 24.9217V9.52168C9.075 10.4842 12.7875 11.0342 16.5 11.0342C21.175 11.0342 24.75 10.3467 27.5 9.52168V24.9217ZM12.65 15.8467L7.5625 21.8967C10.3125 21.4842 13.3375 21.3467 16.5 21.3467C19.6625 21.3467 22.6875 21.4842 25.4375 21.8967L19.25 14.4717L15.4 19.1467L12.65 15.8467Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
export default Media;
