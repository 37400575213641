import XLSX from 'xlsx';
import Button from '~/components/NexAdmin/Buttons/Button';

const DownloadStaticFile = ({
  columns,
  list,
  file_name,
  button_name = 'Download',
}) => {
  const download = (list, columns) => {
    const dataToDownload = [];

    list.forEach((item) => {
      let finalObject = {};
      columns.forEach((column) => {
        finalObject[column.name] = finalObject[column.name] =
          item[column.selector];
      });
      return dataToDownload.push(finalObject);
    });

    const workSheet = XLSX.utils.json_to_sheet(dataToDownload);

    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet');

    XLSX.writeFile(workBook, `${file_name}.xlsx`);
  };

  return (
    <Button
      width="200px"
      style={{ placeSelf: 'end' }}
      onClick={() => download(list, columns)}
    >
      {button_name}
    </Button>
  );
};

export default DownloadStaticFile;
