export const columns = [
  {
    name: 'CPF',
    selector: (row) => row.cpf,
    sortable: true,
  },
  {
    name: 'Nome',
    selector: (row) => row.usu_nome,
    cell: (row) => <div title={row.usu_nome}>{row.usu_nome}</div>,
    sortable: true,
  },
  {
    name: 'Descrição',
    selector: (row) => row.t_desc,
    cell: (row) => <div title={row.t_desc}>{row.t_desc}</div>,
    sortable: true,
  },
  {
    name: 'Valor',
    selector: (row) => row.t_valor,
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row) => (row.status ? row.status : 'Pontuado'),
    sortable: true,
  },
];

export const columns_pap_pc = [
  {
    name: 'ID Usuário',
    selector: (row) => row.user_id,
    sortable: true,
  },
  {
    name: 'Nome',
    selector: (row) => row.usu_nome,
    cell: (row) => <div title={row.usu_nome}>{row.usu_nome}</div>,
    sortable: true,
  },
  {
    name: 'Descrição',
    selector: (row) => row.t_desc,
    cell: (row) => <div title={row.t_desc}>{row.t_desc}</div>,
    sortable: true,
  },
  {
    name: 'Valor',
    selector: (row) => row.t_valor,
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row) => (row.status ? row.status : 'Pontuado'),
    sortable: true,
  },
];
