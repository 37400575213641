import { Form } from '@unform/web';
import { Button } from '~/components/NexAdmin/Buttons/index';
import * as S from './styles';
import api from '~/services/api';
import { showNotification } from '~/utils/notificationBoxes';
import { HTTPErrorCallback } from '~/Helpers/Error';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { Input, InputUpload, Select } from '~/components/NexAdmin/Inputs/index';
import { appendObjectInFormData } from '~/utils';

const MediaCampaignModal = ({ setLoading, hideModal, row, refresh }) => {
  const [defaultRole, setDefaultRole] = useState(null);
  const [defaultRegional, setDefaultRegional] = useState(null);
  const [image, setImage] = useState(null);

  const [role, setRole] = useState([]);
  const [regional, setRegional] = useState([]);

  const types = [
    { label: 'Foto', value: 'image' },
    { label: 'Vídeo', value: 'video' },
  ];

  useEffect(() => {
    getData();
  }, []);

  const buildDefaultValues = (str, arr) => {
    const str_arr = str?.split(',');
    if (str_arr) {
      for (let i = 0; i < str_arr.length; i++) {
        if (str_arr[i].length) {
          if (str_arr[i] === '*') {
            return [{ label: 'TODOS', value: '*' }];
          } else {
            str_arr[i] = str_arr[i].toString();
          }
        }
      }
      return arr.filter((obj) => str_arr.includes(obj?.value?.toString()));
    }
    return [{ label: 'TODOS', value: '*' }];
  };

  const getData = async () => {
    try {
      const response = await api.get(`/media-campaign/admin/get-data`);
      const { role, regional } = response.data;
      const default_role = buildDefaultValues(row.roles, role);
      const default_regional = buildDefaultValues(row.regional, regional);

      const base = { label: 'TODOS', value: '*' };

      setRole([base, ...role]);
      setRegional([base, ...regional]);

      setDefaultRole(default_role);
      setDefaultRegional(default_regional);

      setLoading(false);
    } catch (error) {
      return HTTPErrorCallback(error);
    }
  };
  const update = (data) => {
    setLoading(true);

    delete data.image;

    const formData = new FormData();
    appendObjectInFormData(formData, {
      cover_image: image,
      ...data,
    });
    api
      .put(`/media-campaign/admin/${row.id}`, formData)
      .then(() => {
        showNotification(
          'success',
          'Sucesso!',
          'Campanha atualizada com sucesso!',
        );
        hideModal();
        refresh();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  return (
    <Form onSubmit={update}>
      <S.Wrapper>
        <S.InputWrapper>
          <Input
            label="Título da Campanha"
            name="title"
            required
            defaultValue={row.title}
          />
          <Input
            label="Descrição"
            name="description"
            required
            textArea
            defaultValue={row.description}
          />
          {defaultRole && (
            <Select
              isMulti
              name="roles"
              options={role}
              label="Cargos"
              large
              placeholder="Selecione os cargos"
              defaultValue={defaultRole}
            />
          )}
          {defaultRegional && (
            <Select
              isMulti
              name="regional"
              options={regional}
              label="Regionais"
              large
              placeholder="Selecione as regionais"
              defaultValue={defaultRegional}
            />
          )}
        </S.InputWrapper>
        <S.InputWrapper>
          <Input
            label="Data Inicial:"
            name="begin_date"
            type="date"
            required
            defaultValue={moment(row.begin_date).format('YYYY-MM-DD')}
          />
          <Input
            label="Data Final:"
            name="end_date"
            type="date"
            required
            defaultValue={moment(row.end_date).format('YYYY-MM-DD')}
          />

          <Select
            name="type"
            options={types}
            label="Tipo de Mídia"
            large
            placeholder="Selecione o tipo de mídia"
            defaultValue={types.find((obj) => obj.value === row.type)}
          />

          {image ? (
            <img src={URL.createObjectURL(image)} alt="Imagem da Campanha" />
          ) : (
            row.cover_src && (
              <img src={row.cover_src} alt="Imagem da Campanha" />
            )
          )}

          <InputUpload
            name="image"
            mt="10px"
            onGetFile={setImage}
            placeholder="Selecione uma capa"
            accept="image/*"
          />
        </S.InputWrapper>
        <div />
        <Button type="submit" width="30%">
          Atualizar
        </Button>
      </S.Wrapper>
    </Form>
  );
};

export default MediaCampaignModal;
