import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  grid-area: sidebar;
  position: relative;
  height: 100%;
  background-color: ${({ theme }) => theme.admincolors.contrast.black};
  width: ${(props) => (props.opened || props.hoverOpened ? '230px' : '70px')};
  border-top-right-radius: 9px;
  transition: ${({ theme }) => theme.transition};
  z-index: 1;
  will-change: auto;
  * {
    will-change: auto;
  }
`;

export const Navbar = styled.nav`
  padding: 20px;
  padding-left: ${(props) =>
    props.opened || props.hoverOpened ? '16px' : '14px'};
  display: grid;
  grid-gap: 16px;
  max-height: calc(var(--app-height) - 240px);
  overflow: hidden;
  overflow-y: overlay;
  ::-webkit-scrollbar {
    width: 2px;
  }
`;
export const Header = styled.div`
  position: relative;
  width: 100%;
  transition: ${({ theme }) => theme.transition};

  display: grid;
  padding: ${(props) =>
    props.opened || props.hoverOpened ? '30px 26.5px' : '30px 10px'};
  z-index: 10;

  grid-template-columns: ${(props) =>
    props.opened || props.hoverOpened ? '50px 1fr' : '1fr'};
  grid-gap: 10px;
  place-items: ${(props) =>
    props.opened || props.hoverOpened ? 'center start' : 'center'};
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    transition: ${({ theme }) => theme.transition};
    width: ${(props) =>
      props.opened || props.hoverOpened
        ? 'calc(100% - 50px)'
        : 'calc(100% - 20px)'};
    height: 1px;
    background-color: ${({ theme }) => theme.oldColors.primary[50]};
  }

  figure {
    border: 2px solid ${({ theme }) => theme.oldColors.primary[40]};
    border-radius: 100px;

    img {
      transform: translate(2px, 2px);
      width: 50px;
    }
  }
`;

export const Credentials = styled.div`
  animation: ease 0.4s fadeInRight;
  will-change: auto;
  white-space: nowrap;
  display: ${(props) => (props.opened || props.hoverOpened ? 'grid' : 'none')};

  color: ${({ theme }) => theme.oldColors.primary[40]};
`;
export const Name = styled.div`
  //styleName: H6-desktop;

  font-size: ${({ theme }) => theme.adminfontsize.sidebarcredentials.email};
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  min-width: 0;
`;
export const Email = styled.div`
  //styleName: Support-desktop;

  font-size: ${({ theme }) => theme.adminfontsize.sidebarcredentials.email};
  font-style: normal;
  max-width: 100%;

  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  min-width: 0;
`;
