// import { useEffect, useState, useRef } from 'react';
import { useEffect, useState } from 'react';
import { AiOutlineSwap } from 'react-icons/ai';

import { Form } from '@unform/web';

import Input from '~/components/Input';
import Select from '~/components/Select';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import { showNotification } from '~/utils/notificationBoxes';

import Loading from '~/components/Loading';
import DownloadXLSX from '~/components/Download';
import CampaignsTable from '../CampaignsTable';
import * as S from './styles';
import columns from './columns';

const orderOptions = [
  { value: 'ASC', label: 'Inicio mais antigo' },
  { value: 'DESC', label: 'Inicio mais recente' },
];

const ShowCampaigns = ({ limit }) => {
  const [loading, setLoading] = useState(true);
  const [campaings, setCampaings] = useState([]);
  const [filtredCampains, setFiltredCampains] = useState([]);
  useEffect(() => {
    getCampaigns();
  }, []);

  const formatCampaings = (list = []) => {
    const result = [];

    list.forEach((item) => {
      const data = new Date(item.created_at).toLocaleDateString('pt-BR');
      const status = item.status;
      const period = `${item.initial_date} à ${item.final_date}`;

      result.push({ ...item, data, status, period });
    });

    return result;
  };

  const getCampaigns = (data = {}) => {
    setLoading(true);
    const params = new URLSearchParams(data);
    api
      .get(`/lad/AA/user/campagn?${params}`)
      .then((response) => {
        const campagns = response.data;
        const formattedCampaings = formatCampaings(campagns);
        setCampaings(formattedCampaings);
        setFiltredCampains(formattedCampaings);
        setLoading(false);
      })
      .catch((error) => {
        showNotification('danger', 'Atenção', error.response?.data?.message);
        return setLoading(false);
      });
  };

  const filter = (e) => {
    const input = e.target.value.toLowerCase();
    const newList = campaings.filter(
      (campain) =>
        campain.user_name.toLowerCase().includes(input) ||
        campain.cpf.toLowerCase().includes(input),
    );
    setFiltredCampains(newList);
  };

  const dateSort = (order, list) => {
    if (order === 'ASC')
      return list.sort((a, b) => {
        if (Date.parse(a.data) < Date.parse(b.data)) return -1;
        if (Date.parse(a.data) > Date.parse(b.data)) return 1;
        return 0;
      });
    return list.sort((a, b) => {
      if (Date.parse(a.data) < Date.parse(b.data)) return 1;
      if (Date.parse(a.data) > Date.parse(b.data)) return -1;
      return 0;
    });
  };

  const sortOrder = (e) => {
    setFiltredCampains([...dateSort(e, filtredCampains)]);
  };

  return (
    <>
      {loading && <Loading />}
      {!limit && (
        <>
          <S.Data>
            <Form className="filters" onSubmit={getCampaigns}>
              <S.DateWrapper>
                <S.InputWrapper>
                  <p>De:</p>
                  <Input name="initial_date" placeholder="De" type="date" />
                </S.InputWrapper>

                <S.InputWrapper>
                  <p>Até:</p>
                  <Input name="final_date" placeholder="Até" type="date" />
                </S.InputWrapper>
                <S.SubmitButton type="submit">Consultar</S.SubmitButton>
              </S.DateWrapper>
              <S.DateWrapper end>
                <DownloadXLSX
                  fileName="Campanhas"
                  list={filtredCampains}
                  columns={columns}
                />
              </S.DateWrapper>
            </Form>
            <Form className="search">
              <S.InputWrapper>
                <span>Pesquisar por:</span>
                <Input name="search" label="" onChange={filter} />
              </S.InputWrapper>
            </Form>
          </S.Data>
          <S.Data>
            <S.DataHeader>
              <p>Exibindo {campaings.length} resultados</p>
              <Form>
                <AiOutlineSwap size={20} />
                <span>Ordenar por:</span>
                <Select
                  onChange={(e) => sortOrder(e.value)}
                  name="order"
                  options={orderOptions}
                  placeholder="Selecione"
                />
              </Form>
            </S.DataHeader>
          </S.Data>
        </>
      )}
      <CampaignsTable campains={filtredCampains} />
    </>
  );
};

export default ShowCampaigns;
