import * as S from '../styles';
import { useEffect } from 'react';
import { toDateTime } from '~/Helpers/Date';
import Input from '~/components/Input';
import Select from '~/components/Select';
import { Form } from '@unform/web';
import { HTTPErrorCallback } from '~/Helpers/Error';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';

const ListTicket = ({
  tickets,
  setCurrent,
  setStep,
  setTickets,
  setLoading,
}) => {
  useEffect(() => {
    getTickets();
  }, []);

  const subject_option = [
    { label: 'Todos', value: '*' },
    { label: 'Alteração cadastral', value: '2' },
    { label: 'Pontos em validação', value: '3' },
    { label: 'Contestação de vendas', value: '4' },
    { label: 'Acesso Site', value: '5' },
    { label: 'Acesso Catálogo Prêmios', value: '6' },
    { label: 'Resgates de prêmios', value: '7' },
    { label: 'Reset de Senha', value: '8' },
    { label: 'Estorno de pontos', value: '9' },
    { label: 'Outros', value: '1' },
  ];
  const status_option = [
    { label: 'Todos', value: '*' },
    { label: 'Pendente', value: 'Pendente' },
    { label: 'Em andamento', value: 'Em andamento' },
    { label: 'Finalizado', value: 'Finalizado' },
  ];

  const order_option = [
    { label: 'Mais Recente', value: 'DESC' },
    { label: 'Mais Antigo', value: 'ASC' },
  ];

  const getTickets = (data) => {
    setLoading(true);
    const params = new URLSearchParams(data);
    api
      .get(`/tickets/user/${parseJwt().id}/?${params}`)
      .then((response) => {
        setLoading(false);
        setTickets(response.data.tickets);
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  const handleStep = (item) => {
    setStep('update');
    setCurrent(item);
  };

  const confirmVisualization = (id) => {
    setLoading(true);
    api
      .patch('/tickets/confirm-visualization', { id })
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const content = () => {
    return tickets.map((item) => (
      <S.Ticket notification={item.has_admin_response}>
        <S.TicketInfo>
          <S.Title>
            Número do Ticket: <S.Data>{item.id}</S.Data>
          </S.Title>
          <br />
          <S.Title>
            Data da Abertura: <S.Data>{toDateTime(item.created_at)}</S.Data>
          </S.Title>
          <br />
          <S.Title>
            Assunto: <S.Data>{item.open_reason}</S.Data>
          </S.Title>
          <br />
          <S.Title>
            Conteúdo: <S.Data>{item.comment}</S.Data>
          </S.Title>
          <br />
          <S.Title>
            Status: <S.Data>{item.status}</S.Data>
          </S.Title>
        </S.TicketInfo>
        <S.ButtonWrapper>
          <S.Button
            onClick={() => {
              handleStep(item);
              confirmVisualization(item.id);
            }}
          >
            Analisar
          </S.Button>
        </S.ButtonWrapper>
      </S.Ticket>
    ));
  };
  return (
    <>
      <Form onSubmit={getTickets}>
        <S.FormWrapper>
          <S.FormInput>
            <Input name="id" label="Nº Ticket" />
            <Select
              name="order"
              label="Ordernar por"
              options={order_option}
              defaultValue={order_option[0]}
            />
            <Select
              name="status"
              label="Status"
              options={status_option}
              defaultValue={status_option[0]}
            />
            <Select
              name="open_reason"
              label="Assunto"
              options={subject_option}
              defaultValue={subject_option[0]}
            />
          </S.FormInput>
          <S.FormButton>
            <S.Button>Pesquisar</S.Button>
          </S.FormButton>
        </S.FormWrapper>
      </Form>
      {tickets.length > 0 ? (
        <>{content()}</>
      ) : (
        <S.Title>Não há ocorrência em aberto</S.Title>
      )}
    </>
  );
};

export default ListTicket;
