import styled from 'styled-components/macro';
import { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: ${(props) => props.maxWidth};
  min-width: ${(props) => props.minWidth};
  border: none;
  background-color: transparent;
  grid-area: ${(props) => props.gridarea};
  label {
    color: ${({ theme }) => theme.admincolors.contrast.black};
    font-size: ${({ theme }) => theme.adminfontsize.label};
    line-height: ${({ theme }) => theme.lineheight.label};
  }
  .css-2b097c-container {
    width: 100%;
    margin: 0;
  }

  .styled__control-is-focused {
    box-shadow: 0 0 0 1px
      ${({ theme }) => theme.oldColors.primary[40]}!important;
  }
  .css-1rhbuit-multiValue {
    background-color: ${({ theme }) => theme.oldColors.primary[40]};
  }

  .css-xb97g8 {
    background-color: ${({ theme }) => theme.oldColors.primary[40]};
    position: relative;

    &:hover {
      background-color: ${({ theme }) => theme.oldColors.primary[50]};
      svg {
        fill: ${({ theme }) => theme.admincolors.contrast.black};
      }
    }
  }
  .css-1tobflw-control:hover,
  .css-fym7d8-control:hover {
    border-color: ${({ theme }) => theme.oldColors.primary[40]} !important;
  }
  .css-g1d714-ValueContainer {
    overflow-y: auto;
    max-height: 60px;
  }
  .custom-select__value-container--is-multi {
    overflow-y: auto;
    max-height: 60px;
    margin-left: 20px;
    ${(props) =>
      props.isDisabled &&
      css`
        overflow-y: hidden;
        height: fit-content;
        max-height: fit-content;
      `}
  }
  input {
    color: ${({ theme }) => theme.admincolors.contrast.black};
    background-color: ${(props) => props.theme.admincolors.contrast.white};
    border: 1px solid #adadad;
    outline: none;
    padding: 8px 16px;
    border-radius: 30px;

    font-size: ${({ theme }) => theme.adminfontsize.input};
    line-height: 24px;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    ${(props) => {
      if (props.large)
        return `
          padding: 10px 18px;
          font-size: ${props.theme.adminfontsize.input.large};
          line-height: 28px;
        `;

      if (props.small)
        return `
          padding: 6px 14px;
          font-size: ${props.theme.adminfontsize.input.small};
          line-height: 20px;
        `;

      return '';
    }}

    ${(props) => {
      switch (props.alertType) {
        case 'warning':
          return `
        border-color: ${props.theme.oldColors.warning};
        
        `;

        case 'danger':
          return `         
          border-color: ${props.theme.admincolors.feedback.danger30};
          `;

        case 'success':
          return `
            border-color: ${props.theme.oldColors.success};
                `;
        case 'info':
          return `
            border-color: ${props.theme.oldColors.info};
                `;

        default:
          return `


                `;
      }
    }}
  }
`;
export const Hide = styled.button`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
export const Inner = styled.div`
  position: relative;
`;
export const Message = styled.div`
  font-size: ${({ theme }) => theme.adminfontsize.input.message};
  line-height: 15px;
  margin: 6px 8px;
  ${(props) => {
    switch (props.alertType) {
      case 'warning':
        return `
        color: ${props.theme.oldColors.warning};
        
        `;

      case 'danger':
        return `         
          color: ${props.theme.admincolors.feedback.danger30};
          `;

      case 'success':
        return `
            color: ${props.theme.oldColors.success};
                `;
      case 'info':
        return `
            color: ${props.theme.oldColors.info};
                `;

      default:
        return `


                `;
    }
  }}
`;
export const CharLimit = styled.div`
  position: absolute;
  right: 5px;
  bottom: -6px;
  padding: 2px 6px;
  background-color: ${({ theme }) => theme.oldColors.cadetblue[50]};
  color: ${({ theme }) => theme.oldColors.cadetblue[80]};
  border-radius: 4px;
  font-weight: 600;
  font-size: ${({ theme }) => theme.adminfontsize.input.charlimit};
  line-height: 15px;
  transform: translateY(100%);
`;

export const Selector = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  place-items: center start;

  svg {
    width: 19px;
  }
`;
