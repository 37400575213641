import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: grid;
  padding: 22px 22px;
  grid-gap: 20px;
  grid-template-columns: ${({ icon }) =>
    icon
      ? `minmax(20px, 30px) minmax(0, 2px) minmax(150px, auto) minmax(150px, auto)`
      : 'auto'};
  border-radius: 9px;

  box-shadow: 0px 0px 10px rgba(65, 64, 66, 0.25);

  ${(props) => {
    switch (props.type) {
      case 'approved':
        return `
        background-color: ${props.theme.oldColors.primary[40]};
        
        `;

      case 'sended':
        return `         
          background-color: ${props.theme.oldColors.cadetblue[50]};
          `;

      case 'archived':
        return `
            background-color: ${props.theme.oldColors.secondary[60]};
                `;
      case 'sketch':
        return `
            background-color: ${props.theme.oldColors.gray[50]};
                `;

      default:
        return `
        background-color: ${props.theme.oldColors.gray[20]};


                `;
    }
  }}
`;

export const InfoWrapper = styled.button`
  place-self: end;
  justify-content: center;
  align-items: center;
`;

export const Line = styled.div`
  ${(props) => {
    switch (props.type) {
      case 'approved':
        return `
        background-color: ${props.theme.oldColors.primary[30]};
        
        `;

      case 'sended':
        return `         
          background-color: ${props.theme.oldColors.cadetblue[40]};
          `;

      case 'archived':
        return `
            background-color: ${props.theme.oldColors.secondary[50]};
                `;
      case 'sketch':
        return `
            background-color: ${props.theme.oldColors.gray[40]};
                `;

      default:
        return `
        background-color: ${props.theme.admincolors.contrast.white};


                `;
    }
  }}
`;
export const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.admincolors.contrast.black};
`;
export const Infos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
`;
export const CreationInfo = styled.div`
  margin-right: 20px;

  //styleName: Text-desktop;

  font-size: ${({ theme }) => theme.adminfontsize.statustag.creationinfo};
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;

  overflow: hidden;

  /* cursor: url("/icons/copy.png"), auto; */
  span {
    display: inline;
    font-weight: 700;
    min-width: 0;
    white-space: nowrap;
    text-overflow: ellipsis;

    overflow: hidden;
  }
  &:hover {
    span {
      text-overflow: inherit;
      white-space: normal;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  //styleName: paragraph-bold-desktop;

  font-size: ${({ theme }) => theme.adminfontsize.statustag.title};
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  /* cursor: url("/icons/copy.png"), auto; */
  width: 100%;
  p {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    width: 100%;

    /* -ms-text-overflow:ellipsis; */
    /* text-overflow:ellipsis; */
  }
  &:hover {
    p {
      text-overflow: inherit;
      white-space: normal;
    }
  }
`;
export const Icon = styled.div`
  display: grid;
  place-items: center;
  svg {
    width: 30px;
  }
`;
