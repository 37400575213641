import styled from 'styled-components';

export const CreationForm = styled.div`
  margin-top: 30px;
`;
export const CreationFormTitle = styled.h3`
  text-align: center;
  font-size: ${({ theme }) => theme.font.sizes.small};
  color: ${({ theme }) => theme.colors.red};
  font-weight: ${({ theme }) => theme.font.medium};

  font-weight: 700;
`;
export const CreationRules = styled.div`
  border: 1px solid #c50101;
  width: 100%;
  border-radius: 10px;
  margin: 20px 0px;
  padding: 15px 20px;
`;
export const CreationRulesTitle = styled.h3`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.colors.red};
  margin-bottom: 10px;
`;
export const CreationRulesText = styled.p`
  //styleName: Paragrafo;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #858585;
`;
