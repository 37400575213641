import Button from '~/components/NexAdmin/Buttons/Button';
import XLSX from 'xlsx';

const download = (list, columns, fileName) => {
  const dataToDownload = [];

  list.forEach((item) => {
    let finalObject = {};

    columns.forEach((column) => {
      finalObject[column.name] = column.selector(item);
    });
    return dataToDownload.push(finalObject);
  });

  const workSheet = XLSX.utils.json_to_sheet(dataToDownload);

  const workBook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet');

  XLSX.writeFile(workBook, `${fileName}.xlsx`);
};

const Download = ({
  rawData,
  searchedData,
  columns,
  fileName,
  dropdownItems = [
    { label: 'Todos', onClick: () => download(rawData, columns, fileName) },
    {
      label: 'Filtrados',
      onClick: () => download(searchedData, columns, fileName),
    },
  ],
  buttonName = 'Download',
  placeSelf = 'end',
}) => {
  return (
    <Button
      model="secondary"
      width="200px"
      style={{ placeSelf: placeSelf }}
      onClick={() => download(rawData, columns, fileName)}
      dropdownItems={dropdownItems}
    >
      {buttonName}
    </Button>
  );
};

export default Download;
