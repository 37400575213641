import { useState, useEffect } from 'react';
import { Form } from '@unform/web';
import Input from '~/components/NexAdmin/Inputs/Input';
import Button from '~/components/NexAdmin/Buttons/Button';
import * as S from './styles';
import { Select } from '~/components/NexAdmin/Inputs';
import Threads from '../Threads/Threads';
import moment from 'moment';
import { appendObjectInFormData } from '~/utils';
import api from '~/services/api';
import { status, status_admin } from '../../../options';
import { verifyFile } from '~/Helpers/FileValidator';
import { showNotification } from '~/utils/notificationBoxes';

const UpdateForm = ({
  ticket,
  updateTicket,
  setUserSelected,
  loading,
  setLoading,
  files,
  setFiles,
}) => {
  const [allowUserResponse, setAllowUserResponse] = useState(
    ticket.allow_user_response,
  );
  const [topic, setTopic] = useState([]);

  useEffect(() => {
    api
      .get('/tickets/topics-admin')
      .then((response) => setTopic(response.data));
  }, []);

  const [isFinished, setIsFinished] = useState(ticket.status === 'Finalizado');

  const submit = (data) => {
    setLoading(true);

    if (data.status_admin === 'N/A') {
      showNotification('warning', 'Atenção', 'Selecione status SAC correto');
      setLoading(false);
      return;
    }

    const formData = new FormData();
    appendObjectInFormData(formData, {
      allow_user_response: Boolean(allowUserResponse),
      ...data,
    });

    for (const file of files) {
      const item = Object.entries(file)[0];
      formData.append('file', new File([item[1]], item[0]));
    }

    updateTicket(ticket.id, formData);
    setLoading(false);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const [day, month, year] = dateString.split(' ')[0].split('/');
    const formattedDate = moment
      .utc(`${year}-${month}-${day}`)
      .format('YYYY-MM-DD');
    return formattedDate;
  };

  return (
    <>
      <Button
        onClick={() => {
          setUserSelected(null);
          setFiles([]);
        }}
      >
        Voltar
      </Button>
      <br />
      <br />
      <S.Header>
        <S.HeaderUserData>
          <S.Title>Dados do usuário</S.Title>
          <S.HeaderItem>Nº Ticket: {ticket.id}</S.HeaderItem>
          <S.HeaderItem>Usuário: {ticket.usu_nome}</S.HeaderItem>
          <S.HeaderItem>ID Usuário: {ticket.usu_id}</S.HeaderItem>
          <S.HeaderItem>CPF: {ticket.usu_cpf}</S.HeaderItem>
          <S.HeaderItem>
            Data Criação Ocorrência: {ticket.created_at}
          </S.HeaderItem>
        </S.HeaderUserData>
        <S.HeaderTicketData>
          <S.Title>Mensagem do usuário</S.Title>
          <S.HeaderItem bigtext>{ticket.comment}</S.HeaderItem>
        </S.HeaderTicketData>
      </S.Header>
      <S.FormWrapperUpdate>
        <S.Wrapper>
          <Form onSubmit={submit}>
            {topic.length > 0 && (
              <Select
                name="open_reason"
                label="Razão da abertura"
                placeholder="Selecione uma opção"
                options={topic}
                defaultValue={topic.find(
                  (item) => item.label === ticket.open_reason,
                )}
                required
              />
            )}
            <Input
              defaultValue={formatDate(ticket?.resolution_deadline)}
              name="resolution_deadline"
              label="Prazo de Resolução"
              large
              type="date"
            />
            {!isFinished && (
              <S.Checkbox>
                <label>Permitir resposta do usuário</label>
                <input
                  type="checkbox"
                  name="allow_user_response"
                  checked={allowUserResponse}
                  onChange={(e) => setAllowUserResponse(e.target.checked)}
                />
              </S.Checkbox>
            )}
            <Select
              name="status_admin"
              label="Status SAC"
              defaultValue={status_admin.find(
                (option) => option.value === ticket.status_admin,
              )}
              options={status_admin}
              onChange={(e) => setIsFinished(e.value === 'Finalizado')}
            />
            <Select
              name="status"
              label="Status"
              defaultValue={status.find(
                (option) => option.value === ticket.status,
              )}
              options={status}
              onChange={(e) => setIsFinished(e.value === 'Finalizado')}
            />

            <Input
              defaultValue={ticket.finished_reason}
              name="finished_reason"
              label="Razão da finalização"
              required
            />

            <S.ButtonWrapper>
              <input
                type="file"
                onChange={(e) => verifyFile(e, setFiles)}
                multiple
              />
              <Button type="submit" icon="plus">
                Atualizar Ticket
              </Button>
            </S.ButtonWrapper>
          </Form>
          <S.FilesWrapper>
            <S.Title>Arquivos</S.Title>
            {ticket.files.length > 0 ? (
              <>
                {ticket.files.map((item) => (
                  <S.File key={item.file_name}>
                    <a href={item.location}>{item.file_name}</a>
                  </S.File>
                ))}
              </>
            ) : (
              <S.NoData>Ocorrência não possui arquivos.</S.NoData>
            )}
          </S.FilesWrapper>
        </S.Wrapper>
        <Threads ticket={ticket} loading={loading} setLoading={setLoading} />
      </S.FormWrapperUpdate>
    </>
  );
};

export default UpdateForm;
