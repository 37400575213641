import styled from 'styled-components';
import media from 'styled-media-query';
import { css } from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 60px;
  border-top: 1px solid ${({ theme }) => theme.oldColors.gray[50]};
  ${({ first }) =>
    first &&
    css`
      justify-content: flex-end;
    `}
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  ${({ first }) =>
    first &&
    css`
      display: none;
    `}
`;

export const IconWrapper = styled.div`
  transform: ${({ rotate }) => (rotate ? 'rotate(180deg)' : 'none')};
`;
