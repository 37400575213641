import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw - 200px);
  height: calc(100vh - 400px);
  margin-top: 30px;
  padding: 10px;
`;

export const QuizWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  width: 100%;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
