import * as S from './styles';

const BlockedOrdersReportDescription = () => {
  return (
    <S.ThermWrapper>
      <br />
      Esta tela exibe todos os pedidos que foram feitos pelos usuários do canal
      e bloqueados pela Premiar.
      <br />
      <br />
      Personalize sua busca pelos botões de filtros na tela e então clique em
      “Consultar”.
      <br />
      <br />
      Caso queria fazer o download do relatório, primeiro execute o passo acima
      e, após isso, clique no botão “Download”.
      <br />
      <br />
      Após os botões de filtro e botões de ação, os dados são exibidos em uma
      tabela. É descrito a quantidade de pedidos que foram encontrados em sua
      busca e se todos estão sendo exibidos ou não.
      <br />
      <br />
      Caso sua pesquisa encontre mais de 100 resultados, é necessário que faça o
      download do relatório para visualizar todos os produtos encontrados.
      <br />
      <br />
      <strong>Descritivo dos filtros:</strong>
      <br />
      <strong>CPF</strong> Utilize para buscar todos os pedidos de um único
      usuário pelo seu CPF.
      <br />
      <strong>Código do Pedido: </strong> Utilize para buscar um único pedido
      pelo seu ID.
      <br />
      <br />
      <strong>Descritivo das colunas da tabela:</strong>
      <br />
      <strong>1. Nome:</strong> Nome do usuário.
      <br />
      <strong>2. CPF:</strong> CPF do usuário.
      <br />
      <strong>3. ID do Pedido:</strong> Código ID do pedido. Valor único em toda
      a tabela.
      <br />
      <strong>4. JSON:</strong> Resposta enviada pela Premiar.
      <br />
      <strong>5. ID Usuário:</strong> Código ID do usuário. Valor único em toda
      a tabela.
      <br />
      <strong>6. Valor Produto Ponto:</strong> Valor do produto em pontos do
      GigaClube.
      <br />
      <strong>7. Data Pedido:</strong> Data que o pedido foi feito pelo usuário.
      <br />
      <strong>8. Data Atualização Pedido:</strong> Data em que o pedido foi
      atualizado.
      <br />
      <strong>9. Data Estorno:</strong> Data do estorno do pedido, se houver.
      <br />
      <strong>10. Data Confirmação:</strong> Data de confirmação de recebimento
      do pedido.
      <br />
      <strong>11. Codição Autorização:</strong> Código de autorização emitido
      pela Premiar.
      <br />
      <strong>12. Valor Total Ponto:</strong> Valor total do pedido em pontos do
      GigaClube.
      <br />
      <strong>13. Valor Total Real:</strong> Valor total do pedido em reais.
      <br />
      <strong>14. Valor Frente Real:</strong> Valor do frete do pedido em reais.
      <br />
      <strong>15. Valor Cash Real:</strong> É o valor extra pago em dinheiro pra
      completar um resgate.
      <br />
      <strong>16. Supplier:</strong> Fornecedor do produto.
      <br />
      <strong>17. Produto:</strong> Informação detalhada do produto feito no
      pedido.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default BlockedOrdersReportDescription;
