import React from 'react';

import HeaderButton from '../HeaderButton/index';
import * as S from './styles';

const HeaderButtons = ({ view, setView }) => {
  return (
    <S.Container>
      <HeaderButton
        name="Meus Resgates"
        type="resgates"
        view={view}
        setView={setView}
      />
      <HeaderButton
        name="Meus Pontos"
        type="pontos"
        view={view}
        setView={setView}
      />
    </S.Container>
  );
};

export default HeaderButtons;
