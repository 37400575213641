import React, { useRef, useEffect, useState, useCallback } from 'react';

import { useField } from '@unform/core';

import * as S from './styles';

const Input = ({ icon: Icon, name, label, textArea, ...rest }) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleInpuFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInpuBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <S.Wrapper>
        {label && <S.Label htmlFor={name}>{`${label}:`}</S.Label>}
        <S.Container
          isErrored={!!error}
          isFocused={isFocused}
          isFilled={isFilled}
          className="input"
        >
          {Icon && <Icon />}

          {textArea ? (
            <textarea
              maxLength="5000"
              onFocus={handleInpuFocus}
              onBlur={handleInpuBlur}
              ref={inputRef}
              defaultValue={defaultValue}
              id={name}
              {...rest}
            />
          ) : (
            <input
              onFocus={handleInpuFocus}
              onBlur={handleInpuBlur}
              ref={inputRef}
              defaultValue={defaultValue}
              id={name}
              {...rest}
            />
          )}
        </S.Container>
      </S.Wrapper>
    </>
  );
};

export default Input;
