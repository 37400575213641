import React from 'react';

const Profile = ({ color = '#C50101' }) => {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2644 16.8216C17.7941 15.6989 17.1115 14.6792 16.2548 13.8191C15.4006 12.9566 14.3888 12.2689 13.2752 11.794C13.2653 11.7889 13.2553 11.7864 13.2453 11.7814C14.7987 10.6508 15.8085 8.80905 15.8085 6.73116C15.8085 3.28894 13.0409 0.5 9.625 0.5C6.20913 0.5 3.44153 3.28894 3.44153 6.73116C3.44153 8.80905 4.45133 10.6508 6.00468 11.7839C5.9947 11.7889 5.98473 11.7915 5.97476 11.7965C4.85774 12.2714 3.85542 12.9523 2.99522 13.8216C2.13929 14.6823 1.45685 15.7019 0.985596 16.8241C0.522639 17.9227 0.272956 19.1006 0.250062 20.294C0.249397 20.3208 0.254064 20.3475 0.26379 20.3724C0.273516 20.3974 0.288103 20.4202 0.306692 20.4394C0.325281 20.4586 0.347495 20.4738 0.372027 20.4842C0.396558 20.4946 0.42291 20.5 0.449529 20.5H1.94553C2.05524 20.5 2.1425 20.4121 2.145 20.304C2.19486 18.3643 2.9678 16.5477 4.33414 15.1709C5.74786 13.7462 7.62535 12.9623 9.625 12.9623C11.6247 12.9623 13.5021 13.7462 14.9159 15.1709C16.2822 16.5477 17.0551 18.3643 17.105 20.304C17.1075 20.4146 17.1948 20.5 17.3045 20.5H18.8005C18.8271 20.5 18.8534 20.4946 18.878 20.4842C18.9025 20.4738 18.9247 20.4586 18.9433 20.4394C18.9619 20.4202 18.9765 20.3974 18.9862 20.3724C18.9959 20.3475 19.0006 20.3208 18.9999 20.294C18.975 19.093 18.7282 17.9246 18.2644 16.8216V16.8216ZM9.625 11.0528C8.48056 11.0528 7.40344 10.603 6.59311 9.78643C5.78277 8.96985 5.33646 7.88442 5.33646 6.73116C5.33646 5.57789 5.78277 4.49246 6.59311 3.67588C7.40344 2.8593 8.48056 2.40955 9.625 2.40955C10.7694 2.40955 11.8466 2.8593 12.6569 3.67588C13.4672 4.49246 13.9135 5.57789 13.9135 6.73116C13.9135 7.88442 13.4672 8.96985 12.6569 9.78643C11.8466 10.603 10.7694 11.0528 9.625 11.0528Z"
        fill={color}
      />
    </svg>
  );
};
export default Profile;
