/* eslint-disable no-console */
// import {useState} from 'react';

// import HeaderButtons from './components/HeaderButtons/index'
// import Rescue from './components/Rescue';
import UserPoints from './components/UserPoints';

const Receita = () => {
  // const [view, setView] = useState("resgates")

  return (
    <>
      <UserPoints />
    </>
  );
};

export default Receita;
