import { NavLink } from 'react-router-dom';

import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.main`
  display: grid;
  max-width: 100vw;
  min-height: var(--app-height);
  width: 100vw;
  grid-template-rows: 72px auto;
  grid-template-areas:
    'header'
    'content';
  height: var(--app-height);
  padding-bottom: 170px;
  ${media.greaterThan('medium')`
    display: grid;
    padding-bottom: 0px;
    grid-template-columns: 250px 1fr;
    grid-template-rows: 100px 1fr 181px;
    grid-template-areas:
      'header header'
      'sidebar content'
      'footer footer';
  `};
`;

export const Content = styled.section`
  grid-area: content;
  justify-items: center;
  background-image: url('/images/bg-pattern.png');
  background-repeat: repeat-y;
  padding: 0 36px;
  padding-top: 80px;
  max-width: 100%;
  ${media.greaterThan('medium')`
    padding: 37px 92px;
  `};
`;
