import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 20px auto auto auto;
  grid-template-areas:
    'backButton'
    'header'
    'messages'
    'form';
`;

export const Button = styled.button`
  grid-area: backButton;
  display: grid;
  align-self: flex-start;
  border: 2px solid;
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 35px;
  margin-top: 10px;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
`;

export const Header = styled.div`
  grid-area: header;
  width: 100%;
  align-self: center;
  margin-top: 20px;
`;

export const Message = styled.div`
  grid-area: messages;
  width: 100%;
  align-self: center;
  margin-top: 20px;
`;

export const FormWrapper = styled.div`
  grid-area: form;
  width: 100%;
  align-self: center;
  margin-top: 20px;
`;

export const MessageWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  place-items: center;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  margin-bottom: 15px;

  ${media.lessThan('769px')`
    width: 100%;
    display: block;
    margin: 10px;
  `}
`;

export const MessageInfo = styled.div`
  padding: 20px;
  width: 100%;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  border-right: ${(props) => (props.header ? '2px solid' : '')};
  border-color: ${({ theme, header }) => (header ? `${theme.colors.red}` : '')};

  ${media.lessThan('769px')`
    width: 100%;
    display: block;
    border-top: "";
    border-color: "";
  `}
`;

export const MessageData = styled.p`
  text-align: left;
  padding-left: 10px;
  color: ${({ theme }) => theme.colors.grayDarker};
  ${media.lessThan('769px')`
    width: 100%;
    display: block;
    margin: 10px;
  `}
`;
