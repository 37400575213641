import styled from 'styled-components';

export const Table = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export const Text = styled.div`
  text-align: center;
  margin-top: 10px;
  width: 100%;
`;

export const TableHeaderStart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  width: 100%;
  color: red !important;
`;

export const CreateBannerSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    padding: 20px 0;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
`;
