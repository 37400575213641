import DataTable from 'react-data-table-component';
import * as S from './styles';
import NoDataMessage from '../NoDataMessage';
import { useEffect, useState } from 'react';
import Header from './Components/Header';

const Table = ({
  data,
  mt = '20px',
  columns,
  downloadColumns,
  pagination,
  loading,
  dataTableProps,
  header,
  customHeader,
  fileName,
  dropdownItems,
  refresh,
  hasDownload,
}) => {
  const customStyles = {};
  const [searchedData, setSearchedData] = useState(data);

  useEffect(() => {
    setSearchedData(data);
  }, [data]);

  return (
    <S.Wrapper mt={mt}>
      {header && (
        <Header
          customHeader={customHeader}
          mb="20px"
          rawData={data}
          searchedData={searchedData}
          setSearchedData={setSearchedData}
          downloadColumns={downloadColumns}
          columns={columns}
          fileName={fileName}
          refresh={refresh}
          dropdownItems={dropdownItems}
          hasDownload={hasDownload}
        />
      )}
      <DataTable
        data={searchedData}
        noDataComponent={NoDataMessage}
        columns={columns}
        pagination={pagination}
        progressPending={loading}
        customStyles={customStyles}
        responsive
        noHeader
        {...dataTableProps}
      />
    </S.Wrapper>
  );
};

export default Table;
