import { useEffect, useState, useRef } from 'react';
import Table from '~/components/NexAdmin/Table';
import { HTTPErrorCallback } from '~/Helpers/Error';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import { columns, origin_column } from '../columns';
import Button from '~/components/NexAdmin/Buttons/Button';
import Loading from '~/components/Loading';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import { appendObjectInFormData } from '~/utils';
import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import { status, status_admin, user_response } from '../options';
import { Select, Input } from '~/components/NexAdmin/Inputs';
import * as S from './styles';
import { Form } from '@unform/web';
import Download from '~/components/NexAdmin/Download/Download';
import DownloadHook from '~/hooks/NexAdmin/DownloadHook';
import { buildReportName } from '~/utils/NexAdmin/utils';
import Counter from '~/components/NexAdmin/Counter';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import ReportDescription from '~/components/NexAdmin/Modal/Descriptions/Tickets/reportDescription';

const TicketReport = () => {
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [topic, setTopic] = useState([]);
  const [count, setCount] = useState(0);
  const formRef = useRef();
  const current_user = parseJwt();
  const { openModal } = useModal();
  useEffect(() => {
    getTickets();
    api
      .get('/tickets/topics-admin')
      .then((response) => setTopic(response.data));
  }, []);

  const getTickets = async (data) => {
    setLoading(true);
    const formData = new FormData();
    appendObjectInFormData(formData, {
      admin_id: current_user.id,
      ...data,
    });
    const params = new URLSearchParams(formData);
    api
      .get(`/tickets-admim/report/?${params}`)
      .then((response) => {
        setLoading(false);
        setCount(response.data.total);
        setTickets(response.data.tickets);
      })
      .catch((err) => {
        setLoading(false);
        return HTTPErrorCallback(err);
      });
  };

  const handleDownload = async () => {
    const data = formRef.current.getData();
    const formData = new FormData();
    appendObjectInFormData(formData, {
      admin_id: current_user.id,
      download: true,
      ...data,
    });
    const params = new URLSearchParams(formData);
    return api.get(`/tickets-admim/report/?${params}`, {
      responseType: 'blob',
    });
  };

  const beforeLoading = () => setLoading(true);

  const afterLoading = () => setLoading(false);

  const { execute, ref, urlFile, nameFile } = DownloadHook({
    externalCall: handleDownload,
    onError: HTTPErrorCallback,
    getFileName: buildReportName('Ocorrências'),
    beforeLoading,
    afterLoading,
  });

  const openThermsModal = () => {
    openModal({
      title: 'Relatório de ocorrências',
      close: true,
      content: <ReportDescription />,
    });
  };

  const channels = [
    { label: 'Todos', value: '*' },
    { label: 'Varejo', value: '2' },
    { label: 'Agente Autorizado', value: '4' },
    { label: 'Recarga', value: '5' },
    { label: 'Aparelhos', value: '6' },
    { label: 'PAP', value: '8' },
    { label: 'PAP PC', value: '9' },
  ];

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="monitor"
          title="Relatório de Ocorrências"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <Form ref={formRef} onSubmit={getTickets}>
              <S.FormWrapper>
                <Input name="id" label="Ticket ID:" type="number" />
                <Select
                  name="status"
                  options={status}
                  label="Status:"
                  defaultValue={{ label: 'Todos', value: '*' }}
                />
                <Select
                  name="status_admin"
                  label="Status SAC"
                  options={status_admin}
                  defaultValue={{ label: 'Todos', value: '*' }}
                />
                {topic && (
                  <Select
                    name="open_reason"
                    label="Razão da abertura"
                    placeholder="Selecione uma opção"
                    options={[{ label: 'Todos', value: '*' }, ...topic]}
                    defaultValue={{ label: 'Todos', value: '*' }}
                    required
                  />
                )}
                <Input name="initial_date" label="De:" type="date" />
                <Input name="final_date" label="Até:" type="date" />
                <Input
                  name="resolution_deadline"
                  label="Data de Resolução:"
                  type="date"
                />
                <Input name="cpf" label="CPF:" type="number" />
                <Select
                  name="origin"
                  label="Origem"
                  options={[{ label: 'Todos', value: '*' }, ...origin_column]}
                  defaultValue={{ label: 'Todos', value: '*' }}
                />
                <Select
                  name="channel"
                  label="Canal"
                  options={channels}
                  defaultValue={channels[0]}
                />
                <Select
                  name="has_response"
                  label="Com resposta do usuário"
                  options={user_response}
                />
              </S.FormWrapper>
              <S.ButtonWrapper>
                <S.DownloadWrapper>
                  <a href={urlFile} download={nameFile} ref={ref} />
                  <Download buttonName="Download" external={execute} />
                </S.DownloadWrapper>
                <Button type="submit" icon="plus">
                  Pesquisar
                </Button>
              </S.ButtonWrapper>
            </Form>
            <Counter total={count} />
            <Table
              data={tickets}
              columns={columns}
              loading={loading}
              dataTableProps={{
                pagination: true,
              }}
              header
              dropdownItems={false}
              hasDownload={false}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default TicketReport;
