import styled from 'styled-components';

export const Forgot = styled.div`
  width: 100%;
  text-decoration: underline;
  text-align: center;
  font-size: 12px;
  padding-top: 5px;
  cursor: pointer;
`;
export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row !important;
  gap: 20px;
`;

export const Button = styled.button`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  min-width: 120px;
  justify-content: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;
