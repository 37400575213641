import React from 'react';

import * as S from './styles';

export default function TableDetails({ description, period, points, motivo }) {
  return (
    <S.ExpandableRow colSpan="7">
      <S.BorderTop />
      <S.StyledDiv>
        <strong>Descrição do desafio:</strong>
        {description}
      </S.StyledDiv>
      <S.StyledDiv>
        <strong>Período:</strong>
        {period}
      </S.StyledDiv>
      <S.StyledDiv>
        <strong>Pontos:</strong>
        {points}
      </S.StyledDiv>

      {motivo ? (
        <S.StyledDiv>
          <strong>Motivo recusa:</strong>
          {motivo}
        </S.StyledDiv>
      ) : (
        ' '
      )}
    </S.ExpandableRow>
  );
}
