import { Form as unForm } from '@unform/web';
import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.grayDarker};
  padding: 10px 16px;

  /* ${media.greaterThan('medium')`
  `}; */
`;

export const Comments = styled.ul`
  padding: 10px 4px;
  list-style: none;
  width: 100%;
  /* ${media.greaterThan('medium')`
  `}; */
  figure {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 17px;

    img {
      width: 60px;
      height: 60px;
    }
  }

  li {
    display: flex;
    align-items: flex-start;
    font-size: 1.2rem;
    background-color: #e6e6e630;
    border-radius: 10px;
    padding: 10px;

    color: ${({ theme }) => theme.colors.grayDarker};

    & + li {
      margin-top: 5px;
    }

    div {
      flex: 1;
      p {
        word-break: break-word;
      }
    }

    button {
      background: ${({ theme }) => theme.colors.degrade};
      border-radius: 25px;
      border: 0;
      padding: 5px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 400;
      font-size: 1.4rem;
      transition: box-shadow 0.2s;
      text-decoration: none;
      cursor: pointer;
      margin-right: 5px;
    }
  }
`;

export const Button = styled.button`
  margin: 0 auto;
  display: block;
  color: #ff0000;
  background: white;
  text-decoration: underline;
  cursor: pointer;

  font-weight: 700;
  padding: 10px 20px;
`;

export const Form = styled(unForm)`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  width: 100%;
  ${media.greaterThan('medium')`
    padding: 0 20px 20px;
  `};
  input {
    width: 100%;
  }
  button {
    background-color: transparent;
    cursor: pointer;

    svg {
      fill: ${({ theme }) => theme.colors.grayDarker};
    }
  }

  div {
    border-radius: 10px;
    margin-right: 10px;
  }
`;
