import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button from '~/components/Button';
import Loading from '~/components/Loading';
import { HTTPErrorCallback } from '~/Helpers/Error';
import getIcon from '~/Helpers/getIcon';
import api from '~/services/api';
import * as S from './styles';
import Carousel from 'react-elastic-carousel';
import Modal from '../Modal';
import ChampionModal from '../ChampionModal';

const Campaign = () => {
  const { id } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [campaign, setCampaign] = useState(null);
  const [userMedia, setUserMedia] = useState(null);
  const [champions, setChampions] = useState([]);

  const [isAvailable, setIsAvailable] = useState({});

  const [openModal, setOpenModal] = useState(false);

  const [selectedChampion, setSelectedChampion] = useState(false);

  useEffect(() => {
    getCampaignDetails();
  }, []);

  const getCampaignDetails = () => {
    api
      .get(`/media-campaign/get-details/${id}`)
      .then((response) => {
        setCampaign(response.data.campaign);
        setChampions(response.data.champions);
        setUserMedia(response.data.user_media);

        const isAvailable =
          moment(response.data.campaign.end_date).diff(moment(), 'days') >= 0;

        setIsAvailable(isAvailable);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  const campaignName = (campaign) => {
    return `Campanha de ${campaign?.type === 'image' ? 'Imagem' : 'Vídeo'}
    ${' '}
    ${campaign?.title}`;
  };

  const duration = (campaign) => {
    return `${moment(campaign?.begin_date).format('DD/MM/YYYY')} até ${' '}
    ${moment(campaign?.end_date).format('DD/MM/YYYY')}`;
  };

  const status = (status) => {
    switch (status) {
      case 'pending':
        return 'Não Avaliado';
      case 'approved':
        return 'Aprovado';
      case 'rejected':
        return 'Rejeitado';
      default:
        return 'Pendente';
    }
  };

  return (
    <>
      {loading && <Loading />}
      {openModal && (
        <Modal
          id={campaign.id}
          campaignName={campaignName(campaign)}
          campaignType={campaign.type}
          duration={duration(campaign)}
          setOpenModal={setOpenModal}
          reset={getCampaignDetails}
          userMedia={userMedia}
        />
      )}
      {selectedChampion && (
        <ChampionModal
          campaignType={campaign.type}
          setOpenModal={setSelectedChampion}
          userMedia={selectedChampion}
        />
      )}
      {campaign && (
        <S.Wrapper>
          <S.Section>
            <S.RowWrapper>
              <S.Row>
                <S.GoBack
                  onClick={() => {
                    history.push('/campanha-de-midia');
                  }}
                  className="button"
                  to="#"
                >
                  {getIcon('backArrow', '#C50101')} Voltar
                </S.GoBack>

                <S.Title>{campaignName(campaign)}</S.Title>
              </S.Row>

              <S.Row>
                <S.Duration>{duration(campaign)}</S.Duration>
              </S.Row>

              <S.Row>
                <S.Status isAvailable={isAvailable}>
                  <S.Dot isAvailable={isAvailable} />
                  {isAvailable ? 'Em Andamento' : 'Encerrado'}
                </S.Status>
              </S.Row>
            </S.RowWrapper>
          </S.Section>

          {champions.length > 0 && (
            <S.Section>
              <S.Subtitle>Vencedores</S.Subtitle>

              <Carousel
                itemPadding={[10, 20]}
                breakPoints={[
                  { width: 1, itemsToShow: 1 },
                  { width: 550, itemsToShow: 3 },
                  { width: 1050, itemsToShow: 4 },
                ]}
              >
                {champions.map((item, index) => (
                  <S.Champion
                    src={item.media_src}
                    key={index}
                    user={item.usu_nome}
                    onClick={() => setSelectedChampion(item)}
                  />
                ))}
              </Carousel>
            </S.Section>
          )}

          {userMedia && (
            <S.Section>
              <S.Subtitle>Seu Arquivo Participante</S.Subtitle>

              <S.PhotoRow>
                <S.Row>
                  {campaign.type === 'image' ? (
                    <img src={userMedia.media_src} alt="Arquivo Participante" />
                  ) : (
                    <video src={userMedia.media_src} />
                  )}

                  <S.Info>
                    <span>Data Publicação</span>
                    <span>
                      {moment(userMedia.created_at).format('DD/MM/YYYY')}
                    </span>
                  </S.Info>

                  <S.Info>
                    <span>Status</span>
                    <S.PhotoStatus status={userMedia.status}>
                      {status(userMedia.status)}
                    </S.PhotoStatus>
                  </S.Info>
                </S.Row>

                {['pending', 'rejected'].includes(userMedia.status) && (
                  <S.Row>
                    <Button
                      style={{
                        border: '1px solid #C50101',
                        background: 'transparent',
                        color: '#C50101',
                        width: '100%',
                      }}
                      onClick={() => setOpenModal(true)}
                      to="#"
                    >
                      Excluir
                    </Button>
                    <Button
                      style={{
                        width: '100%',
                      }}
                      to="#"
                      onClick={() => setOpenModal(true)}
                    >
                      Trocar
                    </Button>
                  </S.Row>
                )}
              </S.PhotoRow>

              <S.Alert>
                {getIcon('info', '#C4C4C4')}O prazo para o avaliador analisar
                sua foto é de 48 horas. Após a avaliação não é mais possível
                trocar a imagem.
              </S.Alert>
            </S.Section>
          )}

          <S.Section>
            <S.Subtitle>Entenda as regras da campanha</S.Subtitle>

            <S.Text>{campaign.description}</S.Text>

            {!userMedia && (
              <S.Button>
                <Button
                  style={{
                    width: '225px',
                  }}
                  onClick={() => setOpenModal(true)}
                  to="#"
                >
                  Participar da campanha
                </Button>
              </S.Button>
            )}
          </S.Section>
        </S.Wrapper>
      )}
    </>
  );
};

export default Campaign;
