import * as S from './styles';
import List from './List';
import { useState } from 'react';

const Campaigns = () => {
  const [hasCampagns, setHasCampagns] = useState(true);

  return (
    hasCampagns && (
      <>
        <S.Container>
          <S.Title>Campanhas: </S.Title>
          <List setHasCampagns={setHasCampagns} />
        </S.Container>
      </>
    )
  );
};
export default Campaigns;
