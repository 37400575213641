import * as S from '../styles';
import getIcon from '~/Helpers/getIcon';

const EditDescription = () => {
  return (
    <S.ThermWrapper>
      <br />
      Essa tela permite a edição de uma ocorrência. A seção{' '}
      <strong>Dados do Usuário</strong> possui os dados abaixo.
      <br />
      <strong>Ticket ID:</strong> Código da ocorrência.
      <br />
      <strong>Usuário:</strong> Nome do participante da ocorrência.
      <br />
      <strong>ID Usuário:</strong> Código do participante da ocorrência.
      <br />
      <strong>CPF:</strong> CPF do participante da ocorrência.
      <br />
      <strong>Data Criação Ocorrência:</strong> Data em que a ocorrência foi
      aberta.
      <br />
      <br />A seção <strong>Mensagem do usuário</strong> exibe o texto
      preenchido pelo participante para a abertura da ocorrência.
      <br />
      Já a seção <strong>Arquivos</strong> exibe os documentos que foram
      anexados pelo participante ou pelo usuário admin. É exibida uma lista,
      clique no nome do arquivo para realizar o download.
      <br />
      <br />
      <strong>
        Os campos abaixo se referem as informações cadastradas na criação da
        ocorrência, sendo esta criação feita pelo usuário ou pelo SAC. Aqui, os
        campos são abertos para total alteração que o SAC necessite fazer.
      </strong>
      <br />
      <br />
      <strong>Razão da abertura:</strong> Informa o motivo da abertura da
      ocorrência.
      <br />
      <strong>Prazo Resolução:</strong> Prazo de resolução estipulado para
      resolução da ocorrência. Este campo deve ser SEMPRE configurado pelo SAC.
      Todas as ocorrências iniciam sem um prazo de resolução.
      <br />
      <strong>Permitir resposta do usuário: </strong> Acione este campo sempre
      que desejar liberar a resposta do usuário. O usuário só poderá escrever
      uma nova mensagem se essa opção estiver selecionada.
      <br />
      <strong>Status SAC:</strong> Este status não é visível para o usuário.
      Campo de status interno que deve ser SEMPRE configurado pelo SAC. Todas as
      ocorrências iniciam sem um status de SAC configurado.
      <br />
      <strong>Status:</strong> Este status é visível para o usuário. Indica o
      status atual da ocorrência.
      <br />
      <strong>Razão finalização:</strong> Nos casos em que o SAC altere o status
      da ocorrência para finalizado, este input ficará disponível para informar
      a razão de finalização da ocorrência. Esta informação é visível para o
      usuário. podendo ser <strong>Pendente</strong>,{' '}
      <strong>Em Andamento</strong> e <strong>Escolher arquivos:</strong>{' '}
      Utilize para incluir novos arquivos na ocorrência.
      <br />
      Após o preenchimento dos campos, clique em Atualizar Ticket para que a
      ocorrência seja atualizada.
      <br />
      <br />
      Quando não há nenhuma mensagem, clique no botão <strong>+ </strong>
      para enviar uma nova mensagem para o usuário. O campo{' '}
      <strong>Nova Mensagem</strong> será exibido quando já existir mensagem na
      ocorrência e tem a mesma função de enviar mensagens para o usuário.
      <br />
      Os comentários são ordenados por data e podem ser excluídos e editados.
      Para editar um comentário, clique no ícone {getIcon('statusSketch')},
      presente do lado direito do comentário, realize a edição do texto e depois
      clique em Atualizar. Para excluir, clique no ícone {getIcon('close')}, ao
      lado do ícone de agenda.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default EditDescription;
