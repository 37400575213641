import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './styles.css';

const mapNumber = (number, inMin, inMax, outMin, outMax) =>
  ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;

const SVGSquare = ({ percent, side }) => {
  const strokeWidth = 4;
  const adjustedSide = side - strokeWidth;
  const dashArray = adjustedSide * 4;
  const dashOffset = dashArray - (dashArray * percent) / 100;

  return (
    <svg className="countdown-svg" width={side} height={side}>
      <rect
        width={adjustedSide}
        height={adjustedSide}
        x={strokeWidth / 2}
        y={strokeWidth / 2}
        fill="none"
        stroke="#fff"
        strokeWidth={strokeWidth}
        strokeDasharray={dashArray}
        strokeDashoffset={dashOffset}
        style={{ filter: 'blur(2px)' }}
      />
      <rect
        width={adjustedSide}
        height={adjustedSide}
        x={strokeWidth / 2}
        y={strokeWidth / 2}
        fill="none"
        stroke="#fff"
        strokeWidth={strokeWidth}
        strokeDasharray={dashArray}
        strokeDashoffset={0}
        opacity={0.3}
      />
    </svg>
  );
};

// Componente principal de Countdown
const Countdown = ({ finishDate }) => {
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const updateCountdown = () => {
      const then = moment(finishDate);
      const now = moment();
      const countdown = moment.duration(then.diff(now));

      if (countdown.asSeconds() <= 0) {
        setTime({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return;
      }

      setTime({
        days: countdown.days(),
        hours: countdown.hours(),
        minutes: countdown.minutes(),
        seconds: countdown.seconds(),
      });
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, [finishDate]);

  const daysPercent = mapNumber(time.days, 30, 0, 0, 100);
  const hoursPercent = mapNumber(time.hours, 24, 0, 0, 100);
  const minutesPercent = mapNumber(time.minutes, 60, 0, 0, 100);
  const secondsPercent = mapNumber(time.seconds, 60, 0, 0, 100);

  const getSideLength = () => {
    if (window.innerWidth <= 480) return 80;
    if (window.innerWidth <= 768) return 100;
    if (window.innerWidth <= 1700) return 150;
    return 216;
  };

  const sideLength = getSideLength();

  return (
    <div>
      <div className="countdown-wrapper">
        <div
          className="countdown-item"
          style={{ width: sideLength, height: sideLength }}
        >
          <SVGSquare percent={daysPercent} side={sideLength} />
          {time.days}
          <span>dias</span>
        </div>
        <div
          className="countdown-item"
          style={{ width: sideLength, height: sideLength }}
        >
          <SVGSquare percent={hoursPercent} side={sideLength} />
          {time.hours}
          <span>horas</span>
        </div>
        <div
          className="countdown-item"
          style={{ width: sideLength, height: sideLength }}
        >
          <SVGSquare percent={minutesPercent} side={sideLength} />
          {time.minutes}
          <span>minutos</span>
        </div>
        <div
          className="countdown-item"
          style={{ width: sideLength, height: sideLength }}
        >
          <SVGSquare percent={secondsPercent} side={sideLength} />
          {time.seconds}
          <span>segundos</span>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
