import * as S from './styles';
import Carousel from 'react-elastic-carousel';
import Card from '../Card';
import api from '~/services/api';
import { useEffect, useState } from 'react';
import Loading from '~/components/Loading';
import { HTTPErrorCallback } from '~/Helpers/Error';
import Campaigns from '..';

const List = ({ setHasCampagns }) => {
  const [campagns, setCampagns] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCampagns();
  }, []);

  const getCampagns = () => {
    setLoading(true);
    api
      .get('/campaign-exhibiton/get-campagns')
      .then((response) => {
        setLoading(false);
        setCampagns(response.data);

        if (response.data.length === 0) setHasCampagns(false);
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  return (
    <>
      {loading && <Loading />}
      {campagns.length > 0 && (
        <S.WishScrollArea>
          <S.WishContainer>
            <Carousel
              itemPadding={[10, 20]}
              breakPoints={[
                { width: 1, itemsToShow: 1 },
                { width: 550, itemsToShow: 3 },
                { width: 1050, itemsToShow: 4 },
              ]}
            >
              {campagns &&
                campagns.map((item, index) => (
                  <Card
                    key={index}
                    name={item.name}
                    products={item.parsed_product}
                    regulation_products={item.parsed_regulation_product}
                    regulation={item.regulation_file}
                    period={item.period}
                    description={item.description}
                  ></Card>
                ))}
            </Carousel>
          </S.WishContainer>
        </S.WishScrollArea>
      )}
    </>
  );
};

export default List;
