import React from 'react';

const Trash = ({ color }) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66699 0V1H0.666992V3H1.66699V16C1.66699 16.5304 1.87771 17.0391 2.25278 17.4142C2.62785 17.7893 3.13656 18 3.66699 18H13.667C14.1974 18 14.7061 17.7893 15.0812 17.4142C15.4563 17.0391 15.667 16.5304 15.667 16V3H16.667V1H11.667V0H5.66699ZM3.66699 3H13.667V16H3.66699V3ZM5.66699 5V14H7.66699V5H5.66699ZM9.66699 5V14H11.667V5H9.66699Z"
        fill={color}
      />
    </svg>
  );
};
export default Trash;
