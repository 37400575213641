import styled from 'styled-components';

export const ColorFulSpan = styled.span`
  color: ${(props) => (props.valid ? 'green' : '#d60000')};
`;
export const SafePassword = styled.ol`
  margin-top: 20px;
  list-style-position: inside;
  display: table;

  //styleName: Text-desk;

  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

  li {
    display: table-row;
    color: ${({ theme }) => 'black'}!important;
    &::before {
      display: table-cell; /* aha! */
      text-align: right;
    }
  }
`;
