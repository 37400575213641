import { useState } from 'react';
import Loading from '~/components/Loading';
import { Form } from '@unform/web';
import Input from '~/components/NexAdmin/Inputs/Input';
import Button from '~/components/NexAdmin/Buttons/Button';
import * as S from './styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { showNotification } from '~/utils/notificationBoxes';

const NetworksUpdate = ({
  selectedNetwork,
  setSelectedNetwork,
  getNetworks,
}) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const updateRede = (data) => {
    setLoading(true);
    api
      .put('/redes/update/' + selectedNetwork.id, data)
      .then((r) => {
        getNetworks();
        setLoading(false);
        showNotification('success', 'Rede atualizada com sucesso!');

        setSelectedNetwork(null);
      })
      .catch((err) => {
        setLoading(false);
        return HTTPErrorCallback(err);
      });
  };

  return (
    <S.Wrapper>
      {loading && <Loading />}
      <Button type="button" onClick={() => setSelectedNetwork(null)}>
        Voltar
      </Button>

      <S.FormWrapper>
        <Form onSubmit={updateRede}>
          <Input
            name="rede"
            label="Nome da Rede"
            type="text"
            required
            defaultValue={selectedNetwork.rede}
          />

          <Button type="submit" icon="plus">
            Atualizar Rede
          </Button>
        </Form>
      </S.FormWrapper>
    </S.Wrapper>
  );
};

export default NetworksUpdate;
