import styled from 'styled-components';
import media from 'styled-media-query';

export const Ticket = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  place-items: center;
  border: ${({ theme, notification }) =>
    notification ? `2px solid ${theme.colors.red}` : ''};
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  margin-bottom: 15px;

  ${media.lessThan('769px')`
    width: 100%;
    display: flex;
  flex-direction: column;
  column-gap: 20px;
  `}

  span {
    color: #fff;
    background: ${({ theme }) => theme.colors.degrade};
    position: absolute;
    right: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
  }
`;

export const TicketInfo = styled.div`
  padding: 20px;
  width: 100%;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  border-right: ${(props) => (props.header ? '2px solid' : '')};
  border-color: ${(props) => (props.header ? '#c50101' : '')};

  ${media.lessThan('769px')`
    width: 100%;
    border-right: '';
  `}
`;

export const BottomLine = styled.div`
  border-bottom: 2px solid;
  border-color: #c50101;
`;

export const Title = styled.p`
  display: flex;
  font-size: 1.4rem;
`;

export const Data = styled.p`
  text-align: left;
  padding-left: 10px;
  color: ${({ theme }) => theme.colors.grayDarker};
`;

export const Button = styled.button`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 35px;
  display: flex;
  align-items: center;
  justify-content: end;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  ${media.lessThan('769px')`
    display: block;
    margin: 10px;
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const FileWrapper = styled.div`
  display: grid;
  grid-template-rows: 40px auto;
  gap: 20px;
  width: 100%;
  max-height: 300px;
  height: 100%;
  align-self: flex-start;
  justify-self: center;
  overflow: hidden;
  overflow-y: scroll;
  overflow-y: overlay;
  padding: 20px;
  ${media.lessThan('769px')`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  `}
`;

export const File = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  a {
    color: blue;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
  }

  ${media.lessThan('769px')`
    margin: 10px;
    a {
    color: blue;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: start;
  }
  `}
`;

export const FormWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  align-self: flex-start;
  margin: 20px;
  ${media.lessThan('769px')`
    width: 100%;
    grid-template-columns: 1fr;
  `}
`;

export const FormButton = styled.div`
  display: grid;
  justify-content: center;
`;

export const FormInput = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 25px;
  align-items: center;

  ${media.lessThan('769px')`
    width: 100%;
    grid-template-columns: 1fr;
    align-items: center;
  `}
`;
