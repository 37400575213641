import { useEffect, useState } from 'react';

import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import { sortArray, sortArrayDESC } from '~/utils';

import * as S from './styles';

const RescueTable = ({ order, dataIni, dataFim, submit }) => {
  const [historics, setHistorics] = useState([]);
  const [orderedHistorics, setOrderedHistorics] = useState([]);

  useEffect(() => {
    if (order === 'ASC') {
      const newHistorics = historics.sort((a, b) =>
        sortArrayDESC(a, b, 'dataFormatada'),
      );
      setOrderedHistorics([...newHistorics]);
    } else if (order === 'DESC') {
      const newHistorics = historics.sort((a, b) =>
        sortArray(a, b, 'dataFormatada'),
      );
      setOrderedHistorics([...newHistorics]);
    } else {
      setOrderedHistorics([...historics]);
    }
  }, [order, historics]);

  useEffect(() => {
    api
      .post('/historico-resgate', {
        usuario: parseJwt().id,
        pedido: true,
        estorno: true,
        dataini: dataIni,
        datafim: dataFim,
      })
      .then((response) => {
        setHistorics(response.data.data);
      });
  }, [submit]);

  return (
    <S.Container>
      <table cellSpacing="0" cellPadding="0">
        <thead>
          <td> </td>
          <td>Descrição</td>
          <td>Status</td>
          <td>Total</td>
        </thead>
        <tbody>
          {orderedHistorics.map((historic) => (
            <tr key={historic.descricao}>
              <td
                className={`order-td ${historic.pedidoStatus?.toLowerCase()}`}
              >
                <div className="order-title">
                  PEDIDO # {historic.codigoPedido}
                </div>
                <div className="order-purchase">
                  Compra: {historic.dataFormatada}
                </div>
              </td>
              <td>{historic.descricao}</td>
              <td>{historic.pedidoStatus}</td>
              <td>{historic.pontos} Pts</td>
            </tr>
          ))}
        </tbody>
      </table>
    </S.Container>
  );
};

export default RescueTable;
