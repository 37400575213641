import { createGlobalStyle, css } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
    list-style: none;

    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px grey;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: linear-gradient(
    178.18deg
    ,#FF0000 -13.56%,#760000 190%);
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #d60000;
    }
  }
  @-webkit-keyframes rotation {
    from {
      -webkit-transform: rotate(270deg);
    }
    to{
      -webkit-transform: rotate(630deg);
      }
    }

  ${({ theme }) => css`
    html {
      font-family: ${theme.font.family};
      font-weight: ${theme.font.regular};
      font-size: 62.5%;
      scroll-behavior: smooth;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
    .rec-carousel-item {
      display: flex !important;
    }

    body {
      height: 100vh;
      max-width: 100vw;
      overflow-x: hidden;
      font-size: ${theme.font.sizes.xsmall};
      background: ${theme.colors.grayLight};
    }
  `}
`;

export default GlobalStyle;
