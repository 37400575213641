import styled from 'styled-components';
import media from 'styled-media-query';

export const CloseBtn = styled.div`
  position: absolute;
  right: 12px;
  top: 40px;
`;

export const Modal = styled.div`
  margin-top: -80px;
  border-radius: 20px;
  max-height: 80vh;
  max-width: 90vw;
  width: 1080px;
  position: relative;
  ${media.greaterThan('medium')`
max-height: 80vh;
width: 1080px;

  `}
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh);
  width: calc(100vw);

  background-color: #00000090;
  iframe {
    height: 50vh;
  }

  z-index: 20;
  iframe {
  }
  h2 {
    color: red;
  }
`;

export const RegulationContainer = styled.div`
  iframe {
    margin-top: 30px;
    body {
      background-color: transparent !important;
    }
  }
`;

export const RegulationWrapper = styled.section`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  padding: 30px 50px;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
`;

export const RegulationPageTitle = styled.h2`
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.colors.red};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  gap: 10px;
`;

export const RegulationButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;
export const RegulationButton = styled.button`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;

  margin-bottom: 20px;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;
