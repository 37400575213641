import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  grid-area: headerRight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px ${({ theme }) => theme.spacing.contentH};

  height: 100%;
  width: 100%;
  svg {
    height: 20px;
  }
`;

export const SearchButton = styled.button`
  transition: ${(props) => props.theme.transition};
  &:hover {
    transform: scale(1);
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
`;
