import React from 'react';

const HamburguerCommon = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <svg
        width="31"
        height="4"
        viewBox="0 0 31 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2H29"
          stroke="#C50101"
          strokeWidth="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <svg
        width="31"
        height="4"
        viewBox="0 0 31 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2H29"
          stroke="#C50101"
          strokeWidth="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <svg
        width="31"
        height="4"
        viewBox="0 0 31 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2H29"
          stroke="#C50101"
          strokeWidth="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};
export default HamburguerCommon;
