import React from 'react';

const MinusSign = ({ color = '#484848' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 14H4V10H20" fill={color} />
    </svg>
  );
};
export default MinusSign;
