import { HTTPErrorCallback } from '~/Helpers/Error';
import api from '~/services/api';
import { useState, useRef, useEffect } from 'react';
import DownloadHook from '~/hooks/NexAdmin/DownloadHook';
import Loading from '~/components/Loading';
import Table from '~/components/NexAdmin/Table';
import Counter from '~/components/NexAdmin/Counter';
import { parseJwt } from '~/services/auth';
import { buildReportName } from '~/utils/NexAdmin/utils';
import { Form } from '@unform/web';
import { usersColumn } from '../columns';
import { Input, Select } from '~/components/NexAdmin/Inputs';
import Download from '~/components/NexAdmin/Download/Download';
import { appendObjectInFormData } from '~/utils';
import { Button } from '~/components/NexAdmin/Buttons';
import * as S from './styles';

const User = () => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [users, setUsers] = useState([]);
  const formRef = useRef();

  useEffect(() => handleSubmit(), []);

  const handleSubmit = (data) => {
    setLoading(true);
    const form = new FormData();
    appendObjectInFormData(form, {
      ...data,
    });
    const params = new URLSearchParams(form);
    api
      .get(`/quizzes/admin/quizzes-user?${params}`)
      .then((response) => {
        const { data, total } = response.data;
        setCount(total);
        setUsers(data);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const handleDownload = () => {
    const data = formRef.current.getData();
    const form = new FormData();
    appendObjectInFormData(form, {
      download: true,
      ...data,
    });
    const params = new URLSearchParams(form);
    return api.get(`/quizzes/admin/quizzes-user?${params}`, {
      responseType: 'blob',
    });
  };

  const beforeLoading = () => setLoading(true);

  const afterLoading = () => setLoading(false);

  const { execute, ref, urlFile, nameFile } = DownloadHook({
    externalCall: handleDownload,
    onError: HTTPErrorCallback,
    getFileName: buildReportName('Treinamento por Usuários'),
    beforeLoading,
    afterLoading,
  });

  return (
    <>
      {loading && <Loading />}
      <Form ref={formRef} onSubmit={handleSubmit}>
        <S.FormWrapper>
          <Input name="title" label="Nome Treinamento" />
          <Input name="cpf" label="CPF Usuário" />
          <Input name="initial_date" label="De:" type="date" />
          <Input name="final_date" label="Até:" type="date" />
          <Select
            name="status"
            label="Status"
            options={[
              { label: 'Todos', value: '*' },
              { label: 'Finalizado', value: 100 },
              { label: 'Em Andamento', value: 0 },
            ]}
          />
        </S.FormWrapper>
        <S.ButtonWrapper>
          <Button type="submit">Consultar</Button>
        </S.ButtonWrapper>
      </Form>
      <S.ButtonWrapper>
        <a href={urlFile} download={nameFile} ref={ref} />
        <Download buttonName="Download" external={execute} />
      </S.ButtonWrapper>
      <Counter total={count} />
      <Table
        data={users}
        columns={usersColumn}
        dataTableProps={{ pagination: true }}
        header
        dropdownItems={false}
        hasDownload={false}
      />
    </>
  );
};

export default User;
