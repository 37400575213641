import * as S from './styles';
import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import getIcon from '~/Helpers/getIcon';
import Button from '~/components/NexAdmin/Buttons/Button';
import UseQuizzes from '~/hooks/NexAdmin/QuizzesHook';
import { useEffect, useState } from 'react';
import Quizz from '../../Create/Quiz';
import Question from '../../Create/Question';
import Revision from '../../Create/Revision';

const Pages = () => {
  const [page, setPage] = useState(1);
  const { previousPage, nextPage, step, switchStep } = UseQuizzes();
  const func = switchStep();

  useEffect(() => {
    switch (step) {
      case 1:
        return setPage(<Quizz />);
      case 2:
        return setPage(<Question />);
      case 3:
        return setPage(<Revision />);
      default:
        return setPage(<div>default</div>);
    }
  }, [step]);

  return (
    <>
      <HeaderWrapper>
        <StatusTag icon="add" title="Módulo de Treinamento" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            {page}
            <S.ButtonWrapper first={step === 1}>
              <S.Button first={step === 1}>
                <Button onClick={previousPage}>
                  <S.IconWrapper rotate>{getIcon('rightArrow')}</S.IconWrapper>
                  Voltar
                </Button>
              </S.Button>
              <S.Button>
                <Button
                  onClick={() => {
                    nextPage();
                    func();
                  }}
                >
                  {step === 3 ? 'Criar' : 'Próximo'}
                  <S.IconWrapper>{getIcon('rightArrow')}</S.IconWrapper>
                </Button>
              </S.Button>
            </S.ButtonWrapper>
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default Pages;
