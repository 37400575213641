import { useEffect, useRef, useState } from 'react';
import { Button } from '~/components/NexAdmin/Buttons/index';
import Counter from '~/components/NexAdmin/Counter';
import { Input, Select } from '~/components/NexAdmin/Inputs/index';
import Table from '~/components/NexAdmin/Table';
import { Form } from '@unform/web';
import * as S from '../UserTable/styles';
import api from '~/services/api';
import { appendObjectInFormData } from '~/utils';
import { parseJwt } from '~/services/auth';
import { HTTPErrorCallback } from '~/Helpers/Error';
import Download from '~/screens/NexAdmin/Sales/Components/Download';
import DownloadHook from '~/hooks/NexAdmin/DownloadHook';
import {
  columns_users_geral,
  columns_lad_geral,
  column_gn_extract,
  columns_users_geral_AA,
} from '../../columns';
import { buildReportName } from '~/utils/NexAdmin/utils';

const GeralTable = ({ setLoading, currentIndex }) => {
  const [count, setCount] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [lad, setlad] = useState([]);
  const [extract, setExtract] = useState([]);
  const current_user = parseJwt();
  const formRef = useRef();

  useEffect(() => {
    switch (currentIndex) {
      case 1:
        getTransacionsData();
        break;
      case 0:
        getladData();
        break;
      case 2:
        GetLadExtract();
    }
  }, [currentIndex]);

  const getTransacionsData = (data) => {
    setLoading(true);
    const formData = new FormData();
    appendObjectInFormData(formData, {
      channel_id: current_user.canal_id,
      ...data,
    });
    const params = new URLSearchParams(formData);
    api
      .get(`/lad/default/admin/transactions?${params}`)
      .then((response) => {
        setTransactions(response.data.transactions);
        setCount(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };
  const handleDownloadTransaction = () => {
    const data = formRef.current.getData();
    const form = new FormData();
    appendObjectInFormData(form, {
      channel_id: current_user.canal_id,
      download: true,
      ...data,
    });
    const params = new URLSearchParams(form);
    return api.get(`/lad/default/admin/transactions?${params}`, {
      responseType: 'blob',
    });
  };

  const getladData = (data) => {
    setLoading(true);
    const formData = new FormData();
    appendObjectInFormData(formData, {
      channel_id: current_user.canal_id,
      ...data,
    });
    const params = new URLSearchParams(formData);
    api
      .get(`/lad/default/admin/?${params}`)
      .then((response) => {
        setlad(response.data.data);
        setCount(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  const handleDownloadlad = () => {
    const data = formRef.current.getData();
    const form = new FormData();
    appendObjectInFormData(form, {
      channel_id: current_user.canal_id,
      download: true,
      ...data,
    });
    const params = new URLSearchParams(form);
    return api.get(`/lad/default/admin/?${params}`, { responseType: 'blob' });
  };

  const GetLadExtract = (data) => {
    setLoading(true);
    const formData = new FormData();
    appendObjectInFormData(formData, {
      channel_id: current_user.canal_id,
      ...data,
    });
    const params = new URLSearchParams(formData);
    api
      .get(`/lad/default/admin/extract/?${params}`)
      .then((response) => {
        setExtract(response.data.extract);
        setCount(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  const HandleLadExtract = () => {
    const data = formRef.current.getData();
    const form = new FormData();
    appendObjectInFormData(form, {
      channel_id: current_user.canal_id,
      download: true,
      ...data,
    });
    const params = new URLSearchParams(form);
    return api.get(`/lad/default/admin/extract/?${params}`, {
      responseType: 'blob',
    });
  };

  const beforeLoading = () => setLoading(true);
  const afterLoading = () => setLoading(false);
  const { execute, ref, urlFile, nameFile } = DownloadHook({
    externalCall: () => switchTab().downloadCall(),
    onError: HTTPErrorCallback,
    getFileName: () => switchTab().fileName,
    beforeLoading,
    afterLoading,
  });

  const switchTab = () => {
    switch (currentIndex) {
      case 0:
        return {
          externalCall: getladData,
          downloadCall: handleDownloadlad,
          data: lad,
          columns: columns_lad_geral,
          fileName: buildReportName('Transações por LAD'),
        };
      case 1:
        return {
          externalCall: getTransacionsData,
          downloadCall: handleDownloadTransaction,
          data: transactions,
          columns: columns_users_geral_AA,
          fileName: buildReportName('Transações por Vendedor'),
        };
      case 2:
        return {
          externalCall: GetLadExtract,
          downloadCall: HandleLadExtract,
          data: extract,
          columns: column_gn_extract,
          fileName: buildReportName('Extrato LAD'),
        };
    }
  };

  const switchInputs = () => {
    switch (currentIndex) {
      case 0:
        return (
          <>
            <Input name="cpf" label="CPF LAD: " type="text" />
            <Input name="regional" label="Regional LAD: " type="text" />
            <Input name="pdv" label="PDV:" type="text" />
            <Select
              name="campagn_amount"
              label="Com campanhas criadas: "
              defaultValue={{ label: 'Todos', value: 'all' }}
              options={[
                { label: 'Todos', value: 'all' },
                { label: 'Sim', value: '1' },
                { label: 'Não', value: '0' },
              ]}
            />
          </>
        );
      case 1:
        return (
          <>
            <Input name="initial_date" label="De:" type="date" />
            <Input name="final_date" label="Até:" type="date" />
            <Input name="cpf" label="CPF Vendedor: " type="text" />
            <Input name="regional" label="Regional Vendedor: " type="text" />
            <Select
              name="status"
              label="Status: "
              options={[
                { label: 'Todos', value: 'all' },
                { label: 'Pendente', value: 1 },
                { label: 'Pré-Aprovado', value: 2 },
                { label: 'Aprovado', value: 3 },
                { label: 'Reprovado', value: 4 },
              ]}
            />
          </>
        );
      case 2:
        return (
          <>
            <Input name="cpf" label="CPF:" type="number" />
            <Input name="regional" label="Regional:" type="text" />
            <Input name="pdv" label="PDV:" type="text" />
            <Select
              name="type"
              label="Tipo Transação"
              options={[
                { label: 'Todos', value: 'all' },
                { label: 'Inserção Ponto', value: 1 },
                { label: 'Estorno Ponto', value: 2 },
                { label: 'Criação Campanha', value: 4 },
              ]}
            />
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Form ref={formRef} onSubmit={switchTab().externalCall}>
        <S.FormWrapper>
          <S.FormFilters>{switchInputs()}</S.FormFilters>
          <Button type="submit">Consultar</Button>
        </S.FormWrapper>
        <S.DownloadWrapper>
          <a href={urlFile} download={nameFile} ref={ref} />
          <Download buttonName="Download" external={execute} />
        </S.DownloadWrapper>
      </Form>
      <Counter total={count} />
      <Table
        data={switchTab().data}
        columns={switchTab().columns}
        pagination={true}
        header
        dropdownItems={false}
        hasDownload={false}
      />
    </>
  );
};

export default GeralTable;
