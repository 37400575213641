import React from 'react';

const Url = ({ color }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 12H2V2H5V0H2C1 0 0 1 0 2V12C0 13 1 14 2 14H12C13 14 14 13 14 12V9H12V12ZM9 0V2H10.6L4.8 7.8L6.2 9.2L12 3.4V5H14V0H9Z"
        fill={color}
      />
    </svg>
  );
};
export default Url;
