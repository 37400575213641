import { Form } from '@unform/web';
import { Input } from '~/components/NexAdmin/Inputs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import * as S from '../../styles';
import { Button } from '~/components/NexAdmin/Buttons';

const CreateForm = ({
  createBanner,
  canais,
  selectedCanais,
  setSelectedCanais,
  setState,
  fileRef,
}) => {
  return (
    <>
      <Form onSubmit={createBanner}>
        <S.Inputs>
          <Input
            name="titulo"
            required
            type="text"
            label="Titulo"
            width="400px"
          />
          <Input
            name="produtos_participantes"
            required
            type="text"
            label="Produtos participantes"
            width="400px"
          />
          <Input
            name="digital_dt_ini"
            required
            type="date"
            label="Data Início"
            width="400px"
          />
          <Input
            name="digital_dt_fim"
            required
            type="date"
            label="Data Fim"
            width="400px"
          />
        </S.Inputs>

        <S.ChannelSelect>
          <p>Selecione pelo menos um canal: </p>
          <ul>
            {canais.map((regionalItem) =>
              regionalItem.canal_label ? (
                <li>
                  <label htmlFor="first-name">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedCanais([
                            ...selectedCanais,
                            regionalItem.digital_canal_id,
                          ]);
                        } else {
                          setSelectedCanais(
                            selectedCanais.filter(
                              (item) => item !== regionalItem.digital_canal_id,
                            ),
                          );
                        }
                      }}
                      value={regionalItem.digital_canal_id}
                    />
                    {regionalItem.canal_label}
                  </label>
                </li>
              ) : null,
            )}
          </ul>
        </S.ChannelSelect>

        <S.Editor>
          <Editor
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editor"
            onContentStateChange={(e) => setState(draftToHtml(e))}
          />
        </S.Editor>

        <S.Buttons>
          <input type="file" required accept="application/pdf" ref={fileRef} />
          <Button width="200px" type="submit">
            Criar
          </Button>
        </S.Buttons>
      </Form>
    </>
  );
};

export default CreateForm;
