import styled from 'styled-components';

export const TableContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 50px;
  background-color: white;

  .powerbi-container {
    height: 100%;
    width: 100%;
  }
`;
