import { useState, useRef, useEffect } from 'react';
import { showNotification } from '~/utils/notificationBoxes';
import * as S from './styles';
import { useField } from '@unform/core';

const InputUpload = ({
  maxSize = 15000000,
  onGetFile,
  choosenFilePrefix = 'Arquivo escolhido: ',
  placeholder = 'Clique aqui para selecionar um arquivo',
  accept,
  mt,
  name,
}) => {
  const [file, setFile] = useState(null);
  const inputRef = useRef(null);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref) {
        ref.value = '';
        setPreview(null);
      },
      setValue(_, value) {
        setPreview(value);
      },
    });
  }, [fieldName, registerField]);

  const id =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);

  const onChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.size > maxSize)
      return showNotification(
        'warning',
        'Atenção!',
        'Insira um arquivo menor!',
      );

    setFile(file);
    onGetFile(file);
  };
  return (
    <S.InputWrapper mt={mt}>
      <input
        name="sheet"
        type="file"
        accept={accept}
        id={id}
        onChange={onChange}
        ref={inputRef}
      />
      <label htmlFor={id}>
        {file ? `${choosenFilePrefix}${file.name}` : placeholder}
      </label>
    </S.InputWrapper>
  );
};

export default InputUpload;
