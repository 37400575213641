import React, { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import * as S from './styles';

const Question = ({ pergunta, resposta }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <S.Wrapper>
        <S.Header onClick={() => setOpen(!open)}>
          <S.Title>{pergunta}</S.Title>
          {!open ? <IoIosArrowDown size={20} /> : <IoIosArrowUp size={20} />}
        </S.Header>
        <S.Description className={open ? 'opened' : 'closed'}>
          <p>{resposta}</p>
        </S.Description>
      </S.Wrapper>
    </>
  );
};

export default Question;
