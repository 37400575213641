import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import api from '~/services/api';
import { parseJwt } from '~/services/auth';

import * as S from './styles';

const Footer = () => {
  const [rodape, setRodape] = useState([]);
  const canal = localStorage.getItem('canal');
  const number = process.env.REACT_APP_NUMBER;

  useEffect(() => {
    api.get(`/fale-conosco/${canal}`).then((response) => {
      setRodape(response.data.data[0]);
    });
  }, []);

  return (
    <S.Container>
      <S.FooterWrapper>
        <S.FooterColumn>
          <img className="logo" src="/images/logo-footer-new.png" alt="" />
        </S.FooterColumn>
        <S.FooterColumn>
          <div className="footer-column-title">Central de Atendimento:</div>
          <div className="footer-column-ul">
            <S.FooterColumnLineWrapper>
              <img src="/images/icon-phone.png" alt="Telefone" />
              <div className="footer-column-item">
                <a
                  className="underline"
                  target="_blank"
                  without="true"
                  rel="noreferrer"
                  href={`https://api.whatsapp.com/send?phone=${rodape.telefone}`}
                >
                  {number}
                </a>
              </div>
            </S.FooterColumnLineWrapper>
          </div>
        </S.FooterColumn>
        <S.FooterColumn>
          <div className="footer-column-title">Mapa do site:</div>
          <S.FooterColumnUlWrapper>
            <div className="footer-column-ul">
              <div className="footer-column-item">
                <Link to="/dashboard">Página Inicial</Link>
              </div>
              <div className="footer-column-item">
                <Link to="/meu-perfil">Meu perfil</Link>
              </div>
            </div>
            <div className="footer-column-ul">
              {parseJwt().canal_id !== 7 && parseJwt().canal_id !== 9 ? (
                <div className="footer-column-item">
                  <Link to="/tabela-de-pontos">Tabela de pontos</Link>
                </div>
              ) : (
                ' '
              )}

              <div className="footer-column-item">
                <Link to="/gigaline">Gigaline</Link>
              </div>
            </div>
            <div className="footer-column-ul">
              {parseJwt().canal_id !== 7 && parseJwt().cargo !== 20 ? (
                <div className="footer-column-item">
                  <Link to="/extrato">Extrato</Link>
                </div>
              ) : (
                ' '
              )}

              <div className="footer-column-item">
                <Link to="/faq">FAQ</Link>
              </div>
            </div>
          </S.FooterColumnUlWrapper>
        </S.FooterColumn>
      </S.FooterWrapper>
      <S.CopyWrite>
        Copyright© Campanha Giga Clube. Todos os direitos reservados.
      </S.CopyWrite>
    </S.Container>
  );
};

export default Footer;
