import styled from 'styled-components';

export const ThermWrapper = styled.div`
  max-height: 410px;
  color: #211915;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-align: justify;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
  display: inline-block;
  border-top: 1px solid;
  margin-top: 5px;

  ul {
    padding-left: 15px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style-type: none;
  }

  svg {
    max-width: 16px;
    max-height: 14px;
  }
`;
