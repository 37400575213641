import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  position: relative;
  opacity: ${(props) => (props.inactive ? '0.3' : '1')};
  background-color: #fff;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  background: #ffffff;
  height: fit-content;
  min-height: 220px;
  max-width: 300px;
  button {
    width: 100% !important;
  }

  h2 {
    font-size: 1.6rem;
    color: #c50101;
    font-weight: 500;
    text-align: left;
  }
  width: 100vw;

  margin: 20px 0px;

  ${media.greaterThan('medium')`
        width: 100%;
    `};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  gap: 8px;
  width: 100%;
  height: 125px;
  padding: 20px;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.grayDarker};
  box-shadow: 0px 0px 30px rgba(133, 133, 133, 0.2);
  border-radius: 10px 10px 0px 0px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  color: ${({ isAvailable }) => (isAvailable ? '#00C136' : '#C50101')};
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;

  border-radius: 50%;
  background: ${({ isAvailable }) => (isAvailable ? '#00C136' : '#C50101')};
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 5px;
  background: linear-gradient(178.18deg, #ff0000 -13.56%, #760000 158.3%);
  ${({ dummy }) =>
    dummy &&
    css`
      background: ${({ theme }) => theme.colors.grayLighter};
    `}
  box-shadow: 0px 0px 30px rgba(133, 133, 133, 0.2);
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  height: 100%;
  padding: 20px;
  align-items: flex-start;
`;

export const ModalButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-45%, -60%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  z-index: 100;
  h2 {
    color: red;
  }
  ${media.lessThan('medium')`
    transform: translate(-50%, -80%);
  `}
`;

export const CloseBtn = styled.div`
  position: absolute;
  right: 12px;
  top: 40px;
`;

export const Modal = styled.div`
  margin-top: -80px;
  border-radius: 20px;
  max-height: 80vh;
  max-width: 90vw;
  width: 1080px;
  position: relative;
`;

export const DangerouslySetInnerHTML = styled.div`
  width: 100%;
  height: 50px;
`;
