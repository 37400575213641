import { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import * as S from './styles';

const Collapse = ({ title, hierarchy }) => {
  const [open, setOpen] = useState(false);
  const [subInfo, setSubInfo] = useState(false);
  return (
    <S.Wrapper>
      <S.Header onClick={() => setOpen(!open)}>
        <S.Title>{title}</S.Title>
      </S.Header>
      <S.Description className={open ? 'opened' : 'opened'}>
        <table>
          <thead>
            <td>Pontos</td>
            <td>Qtd. Vendas</td>
            <td>PDV</td>
            <td>Regional</td>
            <td>
              <button type="button" onClick={() => setSubInfo(!subInfo)}>
                Ver mais
              </button>
            </td>
          </thead>
          <tbody>
            <tr>
              <td>{hierarchy.totalPontos}</td>
              <td>{hierarchy.vendasAgrupadas.length}</td>
              <td>{hierarchy.pdv}</td>
              <td>{hierarchy.rede}</td>
              <td>
                <S.ArrowButton
                  onClick={() => setSubInfo(!subInfo)}
                  type="button"
                >
                  {!subInfo ? (
                    <IoIosArrowDown size={20} />
                  ) : (
                    <IoIosArrowUp size={20} />
                  )}
                </S.ArrowButton>
              </td>
            </tr>
          </tbody>
        </table>
      </S.Description>
      <S.SubInfo className={subInfo ? 'opened' : 'closed'}>
        <table>
          <thead className="more-thead">
            <td>Descrição</td>
            <td>Status</td>
            <td>Qtd.</td>
            <td>Pontos</td>
          </thead>
          <tbody>
            {hierarchy.vendasAgrupadas.map((venda) => (
              <tr>
                <td>{venda.plano}</td>
                <td>{venda.status}</td>
                <td>{venda.qtd}</td>
                <td className="special-cell">{parseFloat(venda.valor)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </S.SubInfo>
    </S.Wrapper>
  );
};

export default Collapse;
