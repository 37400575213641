import Button from '~/components/NexAdmin/Buttons/Button';
import XLSX from 'xlsx';

const Download = ({
  rawData,
  searchedData,
  columns,
  fileName,
  dropdownItems = [
    { label: 'Todos', onClick: () => download(rawData, columns) },
    { label: 'Filtrados', onClick: () => download(searchedData, columns) },
  ],
  buttonName = 'Download',
}) => {
  const download = (list, columns) => {
    const dataToDownload = [];

    list.forEach((item) => {
      let finalObject = {};

      columns.forEach((column) => {
        finalObject[column.name] = column.selector(item);
      });
      return dataToDownload.push(finalObject);
    });

    const workSheet = XLSX.utils.json_to_sheet(dataToDownload);

    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet');

    XLSX.writeFile(workBook, `${fileName}.xlsx`);
  };

  return (
    <Button
      model="secondary"
      width="200px"
      onClick={() => download(rawData, columns)}
      dropdownItems={dropdownItems}
    >
      {buttonName}
    </Button>
  );
};

export default Download;
