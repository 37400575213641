import { useEffect, useState } from 'react';
import PopUp from '~/screens/Dashboard/components/PopUp';
import api from '~/services/api';
import Button from '~/components/Button';
import { HTTPErrorCallback } from '~/Helpers/Error';

const VersionContext = ({ children }) => {
  const [diffVersion, setDiffVersion] = useState(false);

  useEffect(() => {
    const checkVersion = async () => {
      try {
        let localVersion = await fetch('/version.txt').then((response) =>
          response.text(),
        );

        if (localVersion.includes('<!DOCTYPE html>')) {
          localVersion = null;
        }

        const serverVersion = await api.get('/version/').then((response) => {
          return response.data.version;
        });

        if (localVersion && serverVersion !== localVersion) {
          setDiffVersion(true);
        }
      } catch (error) {
        console.error('Failed to fetch version', error);
        HTTPErrorCallback(error);
      }
    };

    checkVersion();
  }, []);

  const clearCache = () => {
    if (window.caches) {
      window.caches.keys().then((names) => {
        for (let name of names) {
          window.caches.delete(name);
        }
      });
    }

    setDiffVersion(false);
    window.location.reload(true);
  };

  return (
    <>
      {diffVersion && (
        <PopUp
          canClose={false}
          conteudo={[
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '25px',
              }}
            >
              <p>
                Parece que a sua versão do Giga está desatualizada. Para
                atualizar e continuar navegando, clique no “OK”. Caso esse aviso
                apareça novamente, considere limpar o cache do seu navegador.
              </p>
              <Button
                style={{
                  width: '100px',
                }}
                onClick={() => clearCache()}
                to="/"
              >
                OK
              </Button>
            </div>,
          ]}
        />
      )}
      {children}
    </>
  );
};

export default VersionContext;
