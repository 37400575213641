import React from 'react';

const Disconnect = ({ color }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.47997 1.87866L9.48018 1.87845C11.0625 0.274469 13.6232 0.274095 15.208 1.87845C16.7943 3.48643 16.7943 6.09075 15.2099 7.69871L13.5268 9.40284L13.1542 9.02554L14.8464 7.31216C16.1582 5.984 16.3034 3.73814 14.8467 2.26296C13.3878 0.783279 11.1564 0.931613 9.84177 2.26266L8.15827 3.9672L7.78773 3.59204L9.47997 1.87866ZM2.42572 9.01892L4.10923 7.31438L4.48187 7.69167L2.78963 9.40505C1.47786 10.7332 1.33268 12.9791 2.78934 14.4543C4.2483 15.9339 6.47966 15.7856 7.79427 14.4546L7.79464 14.4542L9.47604 12.7483L9.84621 13.123L8.15398 14.8364L8.15377 14.8366C6.57165 16.4404 4.01059 16.4392 2.42551 14.8362C0.83785 13.2306 0.837553 10.6269 2.42572 9.01892ZM3.40107 2.502L14.6034 13.8443L14.235 14.2173L3.03264 2.87503L3.40107 2.502Z"
        fill={color}
        stroke={color}
      />
    </svg>
  );
};
export default Disconnect;
