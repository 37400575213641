import { useEffect, useState } from 'react';

import * as S from './styles';

const InputController = ({ setGetValue, type, name, value = 0 }) => {
  const [total, setTotal] = useState(value);

  useEffect(() => {
    setGetValue((prev) => {
      const update = { ...prev };
      if (update[name] && update[name][type]) {
        update[name][type].amount = total;
      }
      return update;
    });
  }, [total, name, type]);

  function decreaseValue() {
    let campo = parseInt(total, 10);
    if (campo > 0) {
      setTotal((campo -= 1));
    }
  }
  function increaseValue() {
    let campo = parseInt(total, 10);
    if (campo < 999) {
      setTotal((campo += 1));
    }
  }

  return (
    <>
      <S.Wrapper>
        <S.Input
          min="0"
          max="999"
          onChange={(e) =>
            setTotal(e.target.value <= 999 ? e.target.value : total)
          }
          value={total}
          type="number"
        />
        <S.Controls>
          <S.Arrow type="button" onClick={() => increaseValue()} />
          <S.Arrow type="button" onClick={() => decreaseValue()} down />
        </S.Controls>
      </S.Wrapper>
    </>
  );
};

export default InputController;
