import styled from 'styled-components';

export const Wrapper = styled.section`
  padding: 20px;
  overflow-y: auto;
  ol {
    list-style: none;
  }

  li {
    margin-top: 0.8rem;
    font-family: ${({ theme }) => theme.adminfontfamily};
    font-size: ${({ theme }) => theme.adminfontsize.text};
    color: ${({ theme }) => theme.admincolors.contrast.black};
  }
`;
