import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import { Form } from '@unform/web';
import Button from '~/components/NexAdmin/Buttons/Button';
import * as S from './styles';
import { useEffect, useRef, useState } from 'react';
import { appendObjectInFormData } from '~/utils';
import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { showNotification } from '~/utils/notificationBoxes';
import Loading from '~/components/Loading';
import { Input, InputUpload, Select } from '~/components/NexAdmin/Inputs/index';
import { useHistory } from 'react-router-dom';
import route from '~/routes/Components/NexAdmin/routes';

const Create = () => {
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState([]);
  const [regional, setRegional] = useState([]);

  const [image, setImage] = useState(null);

  const history = useHistory();
  const inputRef = useRef();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    api
      .get(`/media-campaign/admin/get-data`)
      .then((response) => {
        const { role, regional } = response.data;
        const base = { label: 'TODOS', value: '*' };
        setRole([base, ...role]);
        setRegional([base, ...regional]);
        setLoading(false);
      })
      .catch((error) => {
        return HTTPErrorCallback(error);
      });
  };

  const submit = (data) => {
    setLoading(true);

    delete data.image;

    const formData = new FormData();
    appendObjectInFormData(formData, {
      cover_image: image,
      ...data,
    });
    api
      .post('/media-campaign/admin/', formData)
      .then(() => {
        showNotification(
          'success',
          'Sucesso!',
          'Campanha de foto e vídeo criada com sucesso!',
        );
        history.push(route.MediaCampaignsList.path);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag icon="add" title="Campanhas de Foto e Vídeo" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle title="Criar Campanhas de Foto e Vídeo">
            <Form onSubmit={submit}>
              <S.Wrapper>
                <S.InputWrapper>
                  <Input label="Título da Campanha" name="title" required />
                  <Input
                    label="Descrição"
                    name="description"
                    required
                    textArea
                  />
                  <div style={{ width: '100%' }}>
                    <Select
                      isMulti
                      name="roles"
                      options={role}
                      label="Cargos"
                      large
                      placeholder="Selecione os cargos"
                    />
                  </div>

                  <div style={{ width: '100%' }}>
                    <Select
                      isMulti
                      name="regional"
                      options={regional}
                      label="Regionais"
                      large
                      placeholder="Selecione as regionais"
                      maxWidth="100%"
                    />
                  </div>
                </S.InputWrapper>
                <S.InputWrapper>
                  <h3>Período da Campanha</h3>
                  <Input label="De:" name="begin_date" type="date" required />
                  <Input label="Até:" name="end_date" type="date" required />

                  <div style={{ width: '100%' }}>
                    <Select
                      name="type"
                      options={[
                        { label: 'Foto', value: 'image' },
                        { label: 'Vídeo', value: 'video' },
                      ]}
                      label="Tipo de Mídia"
                      large
                      placeholder="Selecione o tipo de mídia"
                    />
                  </div>

                  {image && (
                    <img
                      src={URL.createObjectURL(image)}
                      alt="Imagem da Campanha"
                    />
                  )}

                  <InputUpload
                    name="image"
                    mt="10px"
                    onGetFile={setImage}
                    placeholder="Selecione uma capa"
                    accept="image/*"
                  />
                </S.InputWrapper>
                <div />
                <Button type="submit" width="30%">
                  Criar
                </Button>
              </S.Wrapper>
            </Form>
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default Create;
