import * as S from './styles';

const Rules = ({ update = false }) => {
  return (
    <S.CreationForm>
      <S.CreationFormTitle>
        {update ? 'Atualização' : 'Criação'} de Campanha
      </S.CreationFormTitle>
      <S.CreationRules>
        <S.CreationRulesTitle>Regras</S.CreationRulesTitle>
        <S.CreationRulesText>
          <p>
            {' '}
            <b>1ª</b> Selecione a quantidade e os planos no qual o vendedor
            vinculado o campanha irá precisar vender para conseguir alcançar a
            meta estipulada.{' '}
          </p>
          <p>
            {' '}
            <b>2ª</b> O valor dos pontos a serem distribuídos devem seguir o
            limite máximo de pontos aplicados, lembrando que não é possível
            distribuir mais pontos do que o saldo disponível.{' '}
          </p>
          <p>
            {' '}
            <b>3ª</b> Definir período desejado da campanha e quantidade de
            pontos a serem distribuídos para o vendedor caso o mesmo cumpra os
            requisitos descritos na campanha.{' '}
          </p>
          <p>
            {' '}
            <b>4ª</b> A campanha passará por validação da Claro e após término
            do período, se a campanha for cumprida os pontos serão distribuídos
            automaticamente. Lembrando que o status das campanhas podem ser
            visualizado na aba Ver Campanhas na página do Bolsão do GN.
          </p>
          <p>
            {' '}
            <b>5ª</b> Qualquer duvida entre em contato com a central via
            WhatsApp, pelo número (11) 2776-6205.
          </p>
        </S.CreationRulesText>
      </S.CreationRules>
    </S.CreationForm>
  );
};

export default Rules;
