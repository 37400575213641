import * as S from './styles';

const ValidadeCPFDescription = () => {
  return (
    <S.ThermWrapper>
      <br />O Validar permite a verificação dos cadastros de participantes do
      Giga. Para isso, deve-se informar o CPF desejado e pesquisar. O CPF irá
      aparecer no bloco do canal a qual faz parte, isso significa que se um CPF
      for pesquisado e aparecer no bloco Aparelhos, significa que ele é um
      participante do canal Aparelhos. Porém, todos os participantes estão no
      bloco <strong>Geral</strong>.
      <br />
      <br />O bloco <strong>Geral</strong> contém os dados do participante que
      já estão na base do Giga e suas respectivas respostas, quando já
      cadastradas, independente do canal. Mesmo estando cadastrado em outros
      canais, o participante só é migrado para a base geral após realizar o
      primeiro acesso.
      <br />
      <br />
      Os blocos <strong>Aparelhos, Digital e PAP</strong> contém os dados dos
      participantes que foram cadastrados nos canais Aparelhos, Digital e PAP,
      respectivamente. A base desses canais é interna do Giga Clube.
      <br />
      <br />O bloco <strong>SIV</strong> representa os cadastros dos
      participantes dos canais Agente Autorizado e Varejo. É possível
      diferenciar qual o canal pelo campo <strong>Canal</strong> e se
      encontra-se ativo ou não pelo campo <strong>Ativo</strong>. Caso o usuário
      pesquisado pertença ao canal Agente Autorizado e Varejo não apareça neste
      bloco, significa que ainda não foi cadastrado no SIV, no qual é necessário
      seguir com a solicitação de cadastro. Somente após o cadastro no SIV o
      participante aparece no <strong>Validar</strong>.
      <br />
      <br />O bloco <strong>INOVA</strong> contém o registro dos participantes
      do canal Recarga. As mesmas condições do SIV se aplicam nesse bloco, porém
      com os dados do canal Recarga.
      <br />
      <br />
      <strong>Observações importantes:</strong>
      <br />
      <br />
      1. Caso alguma informação esteja diferente desatualizada no bloco geral, é
      preciso verificar quando ocorreu o último acesso do participante, visto
      que os dados são atualizados após o login.
      <br />
      <br />
      2. Caso um CPF esteja presente em dois canais, significa que está
      duplicado. Nesses casos, é preciso verificar com a Claro qual o canal
      correto do participante, para desativação no canal incorreto.
      <br />
      <br />
      3. As respostas das perguntas dos participantes dos canais Agente
      Autorizado e Varejo são salvas no próprio SIV, isso significa que elas não
      irão aparecer no bloco <strong>Geral</strong>. Caso apareça, podem ser
      desconsideradas pois pertencem a canais a qual o participante não faz mais
      parte, ou seja, foi migrado.
      <br />
      <br />
      4. Os dados que aparecem nos blocos INOVA e SIV são uma visualização dos
      dados que estão cadastrados na base desses sistemas. Os dados que estão
      cadastrados na base do Giga ficam no bloco Geral, que são atualizados nos
      logins dos participantes.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default ValidadeCPFDescription;
