import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import { useState, useRef } from 'react';
import api from '~/services/api';
import * as S from '../Sales/styles';
import { parseJwt } from '~/services/auth';
import moment from 'moment/moment';
import Loading from '~/components/Loading';
import Table from '~/components/NexAdmin/Table';
import Counter from '~/components/NexAdmin/Counter';
import { Select, Input } from '~/components/NexAdmin/Inputs/index';
import { Button } from '~/components/NexAdmin/Buttons/index';
import { Form } from '@unform/web';
import { appendObjectInFormData } from '~/utils';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { toDateTime } from '~/Helpers/Date';
import Download from '../Sales/Components/Download';
import DownloadHook from '~/hooks/NexAdmin/DownloadHook';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import {
  digitalColumns,
  defaultColumns,
  recargaColumns,
  recargaLowLevelColumns,
  pcColumns,
} from './columns';
import OrdersReportDescription from '~/components/NexAdmin/Modal/Descriptions/ordersReportDescription';

const OrdersReport = () => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState(0);
  const [initialDate, setInitialDate] = useState(
    moment(new Date()).subtract(1, 'month').format('YYYY-MM-DD'),
  );
  const [finalDate, setFinalDate] = useState(
    moment(new Date()).format('YYYY-MM-DD'),
  );
  const current_user = parseJwt();
  const formRef = useRef();
  const { openModal } = useModal();

  const handleSubmit = (data) => {
    setLoading(true);
    const form = new FormData();
    appendObjectInFormData(form, {
      channel_id: current_user.canal_id,
      user_level: current_user.usu_nivel,
      user_regional: current_user.usu_regional,
      user_id: parseJwt().id,
      ...data,
    });
    const params = new URLSearchParams(form);
    api
      .get(`/orders-report/?${params}`)
      .then((response) => {
        const { count, orders_found } = response.data;
        setOrders(orders_found);
        setCount(count);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };
  const handleDownload = () => {
    const data = formRef.current.getData();
    const form = new FormData();
    appendObjectInFormData(form, {
      channel_id: current_user.canal_id,
      user_level: current_user.usu_nivel,
      user_regional: current_user.usu_regional,
      user_id: parseJwt().id,
      download: true,
      ...data,
    });
    const params = new URLSearchParams(form);
    return api
      .get(`/orders-report/?${params}`, { responseType: 'blob' })
      .catch((error) => {
        HTTPErrorCallback(error);
      });
  };

  const buildReportName = (name) => {
    return `Relatório ${name} - Canal ${localStorage.getItem(
      'canalNome',
    )} - ${toDateTime(new Date())}`;
  };

  const beforeLoading = () => setLoading(true);

  const afterLoading = () => setLoading(false);

  const { execute, ref, urlFile, nameFile } = DownloadHook({
    externalCall: handleDownload,
    onError: HTTPErrorCallback,
    getFileName: buildReportName('Pedidos'),
    beforeLoading,
    afterLoading,
  });

  const switchColumns = (channel_id) => {
    switch (Number(channel_id)) {
      case 5:
        return current_user.usu_nivel < 3
          ? recargaLowLevelColumns
          : recargaColumns;
      case 7:
        return digitalColumns;
      case 9:
        return pcColumns;
      default:
        return defaultColumns;
    }
  };

  const openThermsModal = () => {
    openModal({
      title: 'Pedidos',
      close: true,
      content: <OrdersReportDescription />,
    });
  };
  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="statusArchived"
          title="Pedidos"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <S.FormWrapper>
                <S.FormFilters>
                  <Input
                    name="initial_date"
                    label="De:"
                    type="date"
                    defaultValue={initialDate}
                  />
                  <Input
                    name="final_date"
                    label="Até:"
                    type="date"
                    defaultValue={finalDate}
                  />
                  <Input name="user_cpf" label="CPF:" type="number" />
                  <Input
                    label="Código do Pedido:"
                    name="order_id"
                    type="number"
                  />
                  <Select
                    name="status"
                    options={[
                      { label: 'Todos', value: 'all' },
                      { label: 'Pendente Confirmação', value: '0' },
                      { label: 'Confirmado', value: '1' },
                      { label: 'Cancelado', value: '-1' },
                      { label: 'Estornado', value: '2' },
                    ]}
                    label="Status:"
                  />
                  {current_user.canal_id === 9 && (
                    <Select
                      name="segmento"
                      options={[
                        { label: 'Todos', value: 'all' },
                        { label: 'Premium', value: 'PREMIUM' },
                        { label: 'Condomínio', value: 'CONDOMÍNIOS' },
                      ]}
                      label="Segmento"
                      defaultValue={{ label: 'Todos', value: 'all' }}
                    />
                  )}
                </S.FormFilters>
                <Button type="submit">Consultar</Button>
              </S.FormWrapper>
              <S.DownloadWrapper>
                <a href={urlFile} download={nameFile} ref={ref} />
                <Download buttonName="Download" external={execute} />
              </S.DownloadWrapper>
            </Form>
            <Counter total={count} />
            <Table
              data={orders}
              columns={switchColumns(current_user.canal_id)}
              pagination={true}
              header
              dropdownItems={false}
              hasDownload={false}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default OrdersReport;
