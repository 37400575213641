import moment from 'moment';
import XLSX from 'xlsx';
import 'moment/locale/pt-br';
import { parseJwt } from '~/services/auth';
import indexes from '../state_cities';

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const getCompleteDate = (date) => {
  moment().locale('pt-br');

  return moment(new Date(date)).format('DD MMMM YYYY');
};

const getDateToDownloadRelatory = () => {
  const result = moment().format('DD/MM/YYYY hh:mm-ss');
  return `${result.replace(':', 'h ').replace('-', 'm ')}s`;
};

const sortArray = (a, b, key) => {
  if (a[key] > b[key]) {
    return -1;
  }
  if (a[key] < b[key]) {
    return 1;
  }
  return 0;
};

const sortArrayDESC = (a, b, key) => {
  if (a[key] < b[key]) {
    return -1;
  }
  if (a[key] > b[key]) {
    return 1;
  }
  return 0;
};

const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const cpfcnpjMask = (value) => {
  let result;

  if (value.length < 14 || (value.length <= 14 && value.includes('.'))) {
    result = value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  } else {
    result = value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }

  return result;
};

const cellphoneMask = (value) => {
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');

  return value;
};

const cepMask = (value) => {
  value = value.replace(/\D/g, '');
  return value;
};

const numberOnlyMask = (value) => {
  value = value.replace(/\D/g, '');

  return value;
};

const goTopPage = () => {
  window.scrollTo(0, 0);
};

const dataURLtoFile = (dataurl, filename) => {
  if (!dataurl) return false;

  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n !== 0) {
    n -= 1;
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

const findByAttibutes = (arr, attribute, value) => {
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i][attribute] === value) return i;
  }
  return -1;
};

const limitCharactersOfString = (string, limit) => {
  if (string) {
    let result = string;

    if (string.length > limit) {
      const resultSliced = result.slice(0, limit);
      const resultWithDots = `${resultSliced}...`;
      result = resultWithDots;
    }

    return result;
  }
  return string;
};

const cleanString = (str) => {
  str = str.toString();
  return str.replace(/\./g, '').replace(/\-/g, '').replace(/\//g, '');
};

const validateAge = (dateString) => {
  if (dateString === '') return false;

  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  let da = today.getDate() - birthDate.getDate();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age -= 1;

  if (m < 0) m += 12;

  if (da < 0) da += 30;

  if (age < 18) return false;

  return true;
};

const validateBirthDate = (dateString) => {
  if (dateString === '') return false;

  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  let da = today.getDate() - birthDate.getDate();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age -= 1;

  if (m < 0) m += 12;

  if (da < 0) da += 30;

  if (age > 100) return false;

  return true;
};

const getAllStates = () => {
  const allData = indexes;

  const result = [];

  allData.forEach((state) => {
    result.push({ id: state.sigla });
  });

  return result;
};

const getStateCities = (state) => {
  const allData = indexes;
  const foundByState = findByAttibutes(allData, 'sigla', state);

  const cities = [];

  allData[foundByState]?.cidades.forEach((city) => {
    cities.push({ name: city });
  });

  return cities;
};

const get = (object, key) => {
  const keys = key.split('.');
  for (let i = 0; i < keys.length; i += 1) {
    if (!Object.hasOwnProperty.call(object, keys[i])) {
      return null;
    }
    object = object[keys[i]];
  }
  return object;
};

const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/\_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};

const applyDelay = (lastClick, setLastClick, delay) => {
  if (lastClick >= Date.now() - delay) return false;

  setLastClick(Date.now());

  return true;
};

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  let result = false;
  if (ua.indexOf('safari') !== -1) {
    if (ua.indexOf('chrome') > -1) {
      result = false;
    } else {
      result = true;
    }
  }

  return result;
};

const downloadToIndication = (listToDownload, columnsToDownload, fileName) => {
  const dataToDownload = [];

  listToDownload.forEach((item) => {
    const finalObject = {};

    columnsToDownload.forEach((column) => {
      if (column.selector === 'usu_id_indicado') {
        finalObject[column.name] = btoa(get(item, column.selector));
      } else {
        finalObject[column.name] = get(item, column.selector);
      }
    });
    return dataToDownload.push(finalObject);
  });

  const workSheet = XLSX.utils.json_to_sheet(dataToDownload);

  const workBook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet');

  XLSX.writeFile(workBook, `${fileName}.xlsx`);
};

const download = (listToDownload, columnsToDownload, fileName) => {
  const dataToDownload = [];

  listToDownload.forEach((item) => {
    const finalObject = {};

    columnsToDownload.forEach((column) => {
      finalObject[column.name] = get(item, column.selector);
    });
    return dataToDownload.push(finalObject);
  });

  const workSheet = XLSX.utils.json_to_sheet(dataToDownload);

  const workBook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet');

  XLSX.writeFile(workBook, `${fileName}.xlsx`);
};

const appendObjectInFormData = (formData, object) => {
  for (const key of Object.keys(object)) {
    formData.append(key, object[key]);
  }

  return formData;
};

const insertElementInArray = (array, element, index) => {
  const newArray = [...array];
  newArray.splice(index, 0, element);
  return newArray;
};

const transformArrayIntoSelectOptions = (
  array,
  labelSelector,
  valueSelector,
) => {
  const options = [];
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    const option = {
      label: labelSelector(item),
      value: valueSelector(item),
    };
    options.push(option);
  }
  return options;
};

const adminIsFromClaro = () => {
  return parseJwt().usu_nome?.toLowerCase().includes('claro');
};

export {
  groupBy,
  sortArray,
  goTopPage,
  cpfcnpjMask,
  validateAge,
  cleanString,
  sortArrayDESC,
  cellphoneMask,
  dataURLtoFile,
  numberOnlyMask,
  getCompleteDate,
  findByAttibutes,
  limitCharactersOfString,
  getAllStates,
  getStateCities,
  cepMask,
  validateBirthDate,
  toBase64,
  get,
  urlBase64ToUint8Array,
  applyDelay,
  isSafari,
  downloadToIndication,
  getDateToDownloadRelatory,
  download,
  appendObjectInFormData,
  insertElementInArray,
  transformArrayIntoSelectOptions,
  adminIsFromClaro,
};
