import * as S from './styles';
import moment from 'moment';

const ConfirmDeleteBalance = ({ cancel, balance = 0, confirm }) => {
  const formatter = new Intl.NumberFormat(['ban', 'id'], {
    maximumFractionDigits: 2,
  });
  return (
    <S.Wrapper>
      <S.Description>
        <b>Atenção!</b>
        <br />
        Ao clicar em "Desistir do meu saldo" você desistirá de todos os seus
        pontos! Voce ainda possuí <b>{formatter.format(balance)}</b>{' '}
        {balance === 0 || balance > 1 ? 'pontos' : 'ponto'}. Tem certeza que
        deseja desistir do seu saldo?
      </S.Description>
      <S.Buttons>
        <S.Button onClick={cancel}>Cancelar</S.Button>
        <S.DeleteBalance onClick={confirm}>
          Desistir do meu saldo
        </S.DeleteBalance>
      </S.Buttons>
    </S.Wrapper>
  );
};

export default ConfirmDeleteBalance;
