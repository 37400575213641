import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import { Form } from '@unform/web';
import Input from '~/components/NexAdmin/Inputs/Input';
import Select from '~/components/NexAdmin/Inputs/Select';
import Button from '~/components/NexAdmin/Buttons/Button';
import * as S from './styles';
import InputUpload from '~/components/NexAdmin/Inputs/InputUpload';
import { useEffect, useState } from 'react';
import {
  appendObjectInFormData,
  transformArrayIntoSelectOptions,
} from '~/utils';
import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { showNotification } from '~/utils/notificationBoxes';
import Loading from '~/components/Loading';
import AsyncSelect from '~/components/NexAdmin/Inputs/AsyncSelect';

const CreateLandingPage = () => {
  const [loading, setLoading] = useState(true);
  const [regulation, setRegulation] = useState(null);
  const [cover, setCover] = useState(null);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = (inputValue, callback = () => {}) => {
    setLoading(true);
    api
      .get('/landing-page/products?query=' + inputValue)
      .then((response) => {
        setLoading(false);
        callback(
          transformArrayIntoSelectOptions(
            response.data,
            (item) => item.plano_nome,
            (item) => item.plano_id,
          ),
        );
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  const setCoverImage = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => setCover({ image: e.target.result, file });
    reader.readAsDataURL(file);
  };

  const getFormError = (data) => {
    if (data.apuration_start_date && !data.apuration_end_date)
      return 'É necessário preencher a data de término da apuração!';

    if (data.apuration_end_date && !data.apuration_start_date)
      return 'É necessário preencher a data de início da apuração!';

    return false;
  };

  const submit = (data, { reset }) => {
    const error = getFormError(data);

    if (error) return showNotification('danger', 'Atenção', error);

    setLoading(true);
    const formData = new FormData();

    appendObjectInFormData(formData, {
      regulation,
      cover: cover?.file,
      ...data,
      products: JSON.stringify(data.products),
    });

    api
      .post('/landing-page', formData)
      .then(() => {
        showNotification(
          'success',
          'Sucesso!',
          'Landing page criada com sucesso!',
        );
        reset();
        setCover(null);
        setRegulation(null);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag icon="add" title="Landing Pages" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle title="Criar Mecânica">
            <Form onSubmit={submit}>
              <S.Wrapper>
                <S.InputWrapper>
                  <Input
                    label="Ação"
                    name="title"
                    placeholder="Ganhe 3x vendendo Banda Larga"
                    required
                  />
                  <AsyncSelect
                    label="Produtos:"
                    name="products"
                    isMulti
                    placeholder="Selecione os produtos"
                    loadOptions={getProducts}
                  />
                  <Input
                    label="Regional"
                    name="region"
                    placeholder="SUL"
                    required
                  />
                  <Input label="Rede" name="network" placeholder="Rede" />
                  <Input
                    label="PDVS:"
                    name="pdvs"
                    placeholder="PDVS"
                    required
                  />

                  <Select
                    label="Cargos elegíveis"
                    name="elegible_roles"
                    defaultValue={{
                      label: 'Vendedores e Hierarquia de acordo com a %',
                      value: 'Vendedores e Hierarquia de acordo com a %',
                    }}
                    options={[
                      {
                        label: 'Vendedores e Hierarquia de acordo com a %',
                        value: 'Vendedores e Hierarquia de acordo com a %',
                      },
                      {
                        label: 'Vendedores nos PDVS desabitados',
                        value: 'Vendedores nos PDVS desabitados',
                      },
                    ]}
                  />

                  {cover?.image && <S.CoverImage src={cover?.image} />}
                  <InputUpload
                    mt="10px"
                    onGetFile={(file) => setCoverImage(file)}
                    placeholder="Selecione uma imagem para a mecânica"
                    accept="image/png, image/gif, image/jpeg"
                    name="arquivo"
                  />
                  <InputUpload
                    name="arquivo"
                    mt="10px"
                    onGetFile={setRegulation}
                    placeholder="Selecione um regulamento"
                    accept="application/pdf"
                  />
                </S.InputWrapper>

                <S.InputWrapper>
                  <h3>Período da mecânica</h3>
                  <Input label="De:" name="start_date" type="date" required />
                  <Input label="Até:" name="end_date" type="date" required />

                  <h3>Período de apuração</h3>
                  <Input label="De:" name="apuration_start_date" type="date" />
                  <Input label="Até:" name="apuration_end_date" type="date" />

                  <Input
                    label="Conclusão:"
                    name="done_date"
                    type="date"
                    required
                  />

                  <Input
                    label="Mecânica"
                    name="description"
                    placeholder="Vendendo Banda Larga instalado do período em questão recebe 3x mais pontos."
                    required
                  />
                  <Select
                    label="Apuração"
                    name="apuration_responsable"
                    defaultValue={{ label: 'Rock', value: 'Rock' }}
                    options={[
                      { label: 'Rock', value: 'Rock' },
                      { label: 'Claro', value: 'Claro' },
                      { label: 'Fullbar', value: 'Nex Digital' },
                    ]}
                  />
                </S.InputWrapper>
                <div />
                <Button type="submit" width="30%">
                  Criar
                </Button>
              </S.Wrapper>
            </Form>
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default CreateLandingPage;
