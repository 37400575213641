import { useEffect, useState } from 'react';

import Loading from '~/components/Loading';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';

import { Input } from '~/components/NexAdmin/Inputs';
import api from '~/services/api';
import * as S from './styles';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import { Form } from '@unform/web';
import { Button } from '~/components/NexAdmin/Buttons';
import { HTTPErrorCallback } from '~/Helpers/Error.js';
import Table from '~/components/NexAdmin/Table';
import { Select } from '~/components/NexAdmin/Inputs';
import column from './columns';
import { toDateBR } from '~/Helpers/Date';
import Action from '../Component/Action';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import ListPopUpDescription from '~/components/NexAdmin/Modal/Descriptions/PopUp/listPopUpDescription';

const ListPopUp = () => {
  const [loading, setLoading] = useState(false);
  const [popUp, setPopUp] = useState([]);
  const [channel, setChannel] = useState([]);
  const { openModal } = useModal();

  useEffect(() => {
    getAll();
    getChannel();
  }, []);

  const getChannel = () => {
    api
      .get(`/popup/admin/channels`)
      .then((response) => {
        setLoading(false);
        const data = response.data;
        const arr = [{ label: 'Todos', value: '*' }];
        for (let i = 0; i < data.length; i++) {
          if (
            data[i]?.canal_id === 1 ||
            data[i]?.canal_id === 3 ||
            data[i]?.canal_id === 7
          ) {
            continue;
          }
          arr.push({ label: data[i].canal_nome, value: data[i].canal_id });
        }
        setChannel(arr);
      })
      .catch((err) => {
        setLoading(false);
        return HTTPErrorCallback(err);
      });
  };

  const getAll = (data) => {
    setLoading(true);
    const query = new URLSearchParams(data);
    api
      .get(`/popup/admin?${query}`)
      .then((response) => {
        setLoading(false);
        setPopUp(response.data);
      })
      .catch((err) => {
        setLoading(false);
        return HTTPErrorCallback(err);
      });
  };

  const openThermsModal = () => {
    openModal({
      title: 'Edição e Listagem de PopUp',
      close: true,
      content: <ListPopUpDescription />,
    });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="add"
          title="Criar Pop-Up"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <Form onSubmit={getAll}>
              <S.FormWrapper>
                <Input name="title" label="Título" />
                <Input name="content" label="Conteúdo" />
                <Select
                  label="Status"
                  name="active"
                  options={[
                    { label: 'Todos', value: '*' },
                    { label: 'Ativo', value: '1' },
                    { label: 'Inativo', value: '0' },
                  ]}
                  defaultValue={{ label: 'Todos', value: '*' }}
                />
                {channel.length && (
                  <Select
                    label="Canal"
                    name="channel"
                    options={channel}
                    defaultValue={channel[0]}
                  />
                )}
                <Button>Pesquisar</Button>
              </S.FormWrapper>
            </Form>
            <Table
              data={popUp}
              columns={[
                {
                  name: 'Ações',
                  cell: (row) => (
                    <Action
                      row={row}
                      setLoading={setLoading}
                      refresh={getAll}
                    />
                  ),
                },
                ...column,
              ]}
              downloadColumns={column}
              dataTableProps={{ pagination: true }}
              header
              dropdownItems={false}
              hasDownload={true}
              fileName={`Relatório de PopUp - ${toDateBR(new Date())}`}
              refresh={getAll}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default ListPopUp;
