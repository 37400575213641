import Default from '~/components/NexAdmin/Modal/Default/index';
import { createContext, useState } from 'react';

export const ModalContext = createContext({
  openModal: ({ title, description, close, content, onClose }) => {},
  hideModal: () => {},
});

const defaultModalProps = {
  title: '',
  description: '',
  close: false,
  onClose: () => {},
};

export const ModalProvider = ({ children }) => {
  const [modalProps, setModalProps] = useState(defaultModalProps);
  const [open, setOpen] = useState(false);

  const openModal = ({ title, description, close, content, onClose }) => {
    setModalProps({ title, description, close, content, onClose });
    setOpen(true);
  };

  const hideModal = () => {
    if (modalProps.onClose) modalProps.onClose();
    setModalProps(defaultModalProps);
    setOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{
        openModal,
        hideModal,
      }}
    >
      {open && <Default {...modalProps} hideModal={hideModal} />}
      {children}
    </ModalContext.Provider>
  );
};
