import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  min-height: var(--app-height);
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  color: black;

  div {
    font-size: 1.3em;
    padding: 10px;
    flex-direction: column;
    display: grid;
    place-items: center;
    grid-gap: 5px;
    cursor: pointer;
    span {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  svg {
    height: 30px;
    width: 30px;
  }
`;
