import styled from 'styled-components';

export const MainSection = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  background: white;

  .wrapperClassName {
    width: 100%;
  }
  .toolbarClassName {
    justify-content: center;
  }
  .editor {
    padding: 10px 20px;
    height: 700px;
    width: 100%;
    background: #ffffff;
    margin: 0 auto;
    border: 1px solid #f4f4f4;
  }
`;

export const BottomRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;

export const Button = styled.button`
  background: ${({ theme }) => theme.admincolors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  width: 100px;
`;
