import React from 'react';
import { useHistory } from 'react-router-dom';

import AboutCampaignComponent from '../../../AboutCampaign';
import * as S from './styles';

const AboutCampaign = () => {
  const history = useHistory();

  return (
    <S.Container>
      <S.Header>
        <S.Title>Sobre a campanha</S.Title>
      </S.Header>
      <S.About>
        <AboutCampaignComponent />
      </S.About>
      <S.MoreButtonWrapper>
        <S.MoreButton onClick={() => history.push('/sobre-a-campanha')}>
          Ver mais
        </S.MoreButton>
      </S.MoreButtonWrapper>
    </S.Container>
  );
};

export default AboutCampaign;
