import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  place-items: center;
  margin-right: 10px;
`;
export const Label = styled.div`
  margin-right: 5px;
  color: ${({ theme }) => theme.oldColors.primary[40]};
`;
export const Inner = styled.div`
  position: relative;
  zoom: 0.6;
  label {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 31px;
  }
  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + span {
      background: ${(props) => props.theme.oldColors.primary[50]};
      &::before {
        content: '';
        transform: translateX(19px);
        color: ${(props) => props.theme.oldColors.primary[50]};
      }
    }
  }
  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: grid;
    place-items: center;
    background: ${(props) => props.theme.admincolors.contrast.white};

    transition: 0.4s;
    border-radius: 30px;
    &::before {
      font-size: ${({ theme }) => theme.adminfontsize.togglevalue};
      line-height: 16px;
      position: absolute;
      color: ${(props) => props.theme.admincolors.contrast.white};
      content: '';
      height: 26px;
      display: grid;
      place-items: center;
      width: 26px;
      left: 2px;
      top: 3px;
      background-color: ${(props) => props.theme.oldColors.gray[100]};

      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);

      -webkit-transition: 0.6s;
      transition: 0.6s;
      border-radius: 50%;
    }
  }
`;

export const State = styled.div`
  position: relative;
`;
