import { useState } from 'react';

import Loading from '~/components/Loading';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';

import { Input, ToggleSwitch } from '~/components/NexAdmin/Inputs';
import api from '~/services/api';
import { useHistory } from 'react-router-dom';
import { showNotification } from '~/utils/notificationBoxes';
import * as S from './styles';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import { Form } from '@unform/web';
import { Button } from '~/components/NexAdmin/Buttons';
import { HTTPErrorCallback } from '~/Helpers/Error.js';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import CreatePopUpDescription from '~/components/NexAdmin/Modal/Descriptions/PopUp/createPopUpDescription';

const CreatePopUp = () => {
  const [loading, setLoading] = useState(false);
  const [allChannel, setAllChannel] = useState(false);
  const history = useHistory();
  const { openModal } = useModal();

  const create = (data) => {
    setLoading(true);
    api
      .post('/popup/admin', {
        all_channel: allChannel,
        ...data,
      })
      .then(() => {
        setLoading(false);
        showNotification('success', 'PopUp criado com sucesso!');
        history.push('/admin/popup/list');
      })
      .catch((err) => {
        setLoading(false);
        return HTTPErrorCallback(err);
      });
  };

  const openThermsModal = () => {
    openModal({
      title: 'Criação de PopUp',
      close: true,
      content: <CreatePopUpDescription />,
    });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="add"
          title="Criar Pop-Up"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <Form onSubmit={create}>
              <S.Wrapper>
                <S.FormWrapper>
                  <Input name="title" label="Título" />
                  <Input
                    textArea
                    name="content"
                    label="Conteúdo"
                    maxLength="500"
                  />
                  <ToggleSwitch
                    name="all_channel"
                    label="Para todos os canais?"
                    defaultChecked={false}
                    value={allChannel}
                    onClick={() => setAllChannel(!allChannel)}
                  />
                  <Button>Criar</Button>
                </S.FormWrapper>
              </S.Wrapper>
            </Form>
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default CreatePopUp;
