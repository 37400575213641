import getIcon from '~/Helpers/getIcon';
import { showNotification } from '~/utils/notificationBoxes';
import { Wrapper } from './styles';

const Icons = () => {
  return (
    <Wrapper>
      {Object.entries(getIcon(null, 'black', true))
        .sort()
        .map((item) => {
          const value = item[1];
          const key = item[0];
          return (
            <div
              onClick={() => {
                navigator.clipboard.writeText(key);
                showNotification(
                  'success',
                  '',
                  `${key} Copiado para o clipboard!`,
                  () => {},
                  1500,
                );
              }}
            >
              {value} <span>{key}</span>
            </div>
          );
        })}
    </Wrapper>
  );
};

export default Icons;
