import * as S from './styles';
import getIcon from '~/Helpers/getIcon';
import { useState } from 'react';
import { FaWindowClose as Close } from 'react-icons/fa';
import Media from '~/components/Global/Icons/Media';
import { useHistory } from 'react-router-dom';

const Card = ({ id, name, dummy, isAvailable }) => {
  const history = useHistory();

  return (
    <>
      <S.Wrapper>
        <S.Header>
          {dummy ? getIcon('padlock') : getIcon('media')}
          {dummy ? getIcon('xxx', '#484848') : name}

          <S.Status isAvailable={isAvailable}>
            {dummy ? (
              getIcon('xxx', '#484848')
            ) : (
              <>
                <S.Dot isAvailable={isAvailable} />
                {isAvailable ? 'Em Andamento' : 'Encerrado'}
              </>
            )}
          </S.Status>
        </S.Header>
        <S.Data dummy={dummy}>
          {!dummy && (
            <S.ModalButton
              onClick={() => {
                history.push('/campanha-de-midia/' + id);
              }}
              className="button"
              to="#"
            >
              Ver mais {getIcon('rightArrow', 'white')}
            </S.ModalButton>
          )}
        </S.Data>
      </S.Wrapper>
    </>
  );
};

export default Card;
