import * as S from '../styles';
import getIcon from '~/Helpers/getIcon';

const CreateDescription = () => {
  return (
    <S.ThermWrapper>
      <br />
      Essa tela permite a criação de uma ocorrência em nome de um participante.
      Para isso, é necessário preencher os campos, segue um descritivo de cada
      um deles.
      <br />
      <br />
      <strong>Razão da abertura: </strong> Informa o motivo da abertura da
      ocorrência, podendo ser <strong>Alteração cadastral</strong>,{' '}
      <strong>Pontos em validação</strong>,{' '}
      <strong>Contestação de vendas</strong>, <strong>Acesso site</strong>,{' '}
      <strong>Acesso Catálogo Prêmios</strong>,{' '}
      <strong>Resgate de prêmios</strong>, <strong>Reset de senha</strong>,{' '}
      <strong>Estorno de pontos</strong> e <strong>Outros</strong>.
      <br />
      <br />
      <strong>Informações complementares: </strong> Descrição do problema e
      situação que motivou a abertura da ocorrência.
      <br />
      <strong>Usuário:</strong> Campo para informar o número do CPF ou CNPJ do
      participante da ocorrência. Este campo é obrigatório, só é possível criar
      uma ocorrência se este campo for preenchido. Após o preenchimento, clique
      em <strong>Pesquisar usuário</strong>. Em seguida os dados do participante
      serão exibidos para confirmação.
      <br />
      <strong>Permitir resposta do usuário:</strong> Campo de check box, que
      quando selecionado, permite que o participante insira uma resposta na
      ocorrência.
      <br />
      <strong>Prazo de Resolução:</strong> Data informada pelo usuário admin
      para finalização da ocorrência. Este é preeenchido obrigatoriamente na
      primeira edição da ocorrência, ainda quando o status da solicitação é{' '}
      <strong>Pendente</strong>.
      <br />
      <strong>Status: </strong> Campo informativo da situação da ocorrência,
      podendo ser Pendente, Em Andamento e Finalizado. Por padrão, após sua
      abertura, uma ocorrência tem seu status definido como{' '}
      <strong>Pendente</strong>.
      <br />
      <strong>Escolher arquivos:</strong> Utilize para incluir novos arquivos na
      ocorrência.
      <br />
      <br />
      Após o preenchimento dos campos, clique em <strong>Criar Ticket</strong>.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default CreateDescription;
