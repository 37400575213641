import { AuthenticationContext } from '~/contexts/AutenticationContext';
import { useContext } from 'react';

const UseAuthentication = () => {
  const { parseJwt, api } = useContext(AuthenticationContext);

  return {
    parseJwt,
    api,
  };
};

export default UseAuthentication;
