import styled from 'styled-components';
import { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  gap: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.oldColors.gray[50]};
  ${(props) =>
    props.noBorder &&
    css`
      border: none;
    `}
`;

export const QuestionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.4fr;
  margin-bottom: 20px;
  gap: 50px;
  width: 90%;
`;

export const Option = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
`;

export const ArrowWrapper = styled.div`
  cursor: pointer;
`;

export const AnswersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 90%;

  &.opened {
    padding: 28px 46px 13px;
    visibility: visible;
  }

  &.closed {
    overflow: hidden;
    height: 0;
    visibility: hidden;
  }
`;

export const Answer = styled.div`
  display: grid;
  grid-template-columns: 0.1fr 1fr 0.1fr;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

export const Letter = styled.div`
  display: flex;
  margin-left: 10px;
  border-radius: 10px;
  border: 1px solid;
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.oldColors.blackopacity};
  color: ${({ theme }) => theme.admincolors.contrast.black};
  font-size: ${({ theme }) => theme.adminfontsize.containertitle};
`;

export const AwnserInput = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;
