import * as S from './styles';
import getIcon from '~/Helpers/getIcon';
import { useState } from 'react';
import { FaWindowClose as Close } from 'react-icons/fa';

const Card = ({
  name,
  products,
  description,
  period,
  dummy,
  regulation,
  regulation_products,
}) => {
  const [showRegulationModal, setShowRegulationModal] = useState(false);
  return (
    <>
      {showRegulationModal && (
        <S.ModalWrapper>
          <S.Modal>
            <S.RegulationWrapper>
              <S.RegulationPageTitle>
                <img src="/images/regulation-icon.png" alt="" />
                Campanha:
              </S.RegulationPageTitle>
              <S.ModalInfo>
                <S.ModalInfoItem>
                  <div className="title">1. Nome: </div>{' '}
                  <div className="content">{name}</div>
                </S.ModalInfoItem>
                <S.ModalInfoItem>
                  <div className="title">2. Descrição:</div>{' '}
                  <div className="content"> {description}</div>
                </S.ModalInfoItem>
                <S.ModalInfoItem>
                  <div className="title">3. Período:</div>{' '}
                  <div className="content">{period}</div>
                </S.ModalInfoItem>
                {regulation_products && (
                  <S.ModalInfoItem>
                    <div className="title">4. Produtos participantes:</div>
                    {regulation_products.map((item, index) => (
                      <div key={index}>
                        <div className="content">{item}</div>
                      </div>
                    ))}
                  </S.ModalInfoItem>
                )}
                {products && (
                  <S.ModalInfoItem>
                    <div className="title">
                      {regulation_products
                        ? '5. Produtos detalhes:'
                        : '4. Produtos participantes:'}
                    </div>
                    {products.map((item, index) => (
                      <div key={index}>
                        <div className="content">{item}</div>
                      </div>
                    ))}
                  </S.ModalInfoItem>
                )}
                {regulation && (
                  <S.RegulationButtonWrapper>
                    <S.RegulationButton onClick={() => window.open(regulation)}>
                      Baixar regulamento
                    </S.RegulationButton>
                  </S.RegulationButtonWrapper>
                )}
              </S.ModalInfo>
            </S.RegulationWrapper>
            <S.CloseBtn>
              <Close
                onClick={() => setShowRegulationModal(false)}
                color="red"
                size="20"
              />
            </S.CloseBtn>
          </S.Modal>
        </S.ModalWrapper>
      )}
      <S.Wrapper>
        <S.Header>
          {dummy ? getIcon('padlock') : getIcon('accelerator')}
          {dummy ? getIcon('xxx', '#484848') : name}
        </S.Header>
        <S.Data dummy={dummy}>
          <S.Products dummy={dummy}>
            {dummy ? getIcon('smallXXX', '#FFFFFF') : 'Produto Foco'}
            <S.Product>
              {!dummy &&
                regulation_products &&
                regulation_products.map((item, index) => (
                  <S.ProductName key={index}>{item}</S.ProductName>
                ))}
              {dummy && (
                <S.ProductName>{getIcon('smallXXX', '#FFFFFF')}</S.ProductName>
              )}
            </S.Product>
          </S.Products>
          <S.Period>
            <S.PeriodItem>
              {dummy
                ? getIcon('smallXXX', '#FFFFFF')
                : 'Período de Participação'}
            </S.PeriodItem>
            <S.PeriodItem>
              {dummy ? getIcon('smallXXX', '#FFFFFF') : period}
            </S.PeriodItem>
          </S.Period>
          {!dummy && (
            <S.ModalButton
              onClick={() => setShowRegulationModal(!showRegulationModal)}
              className="button"
              to="#"
            >
              Ver mais {getIcon('rightArrow', 'white')}
            </S.ModalButton>
          )}
        </S.Data>
      </S.Wrapper>
    </>
  );
};

export default Card;
