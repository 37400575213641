import * as S from '../styles';

const ReportDescription = () => {
  return (
    <S.ThermWrapper>
      <br />
      Essa tela permite consultar ocorrências e emitir relatórios. Pode-se
      utilizar os campos abaixo para filtrar os resultados, segue um descritivo
      de cada um deles.
      <br />
      <br />
      <strong>Descritivo dos filtros:</strong>
      <br />
      <br />
      <strong>Ticket ID:</strong> Código da ocorrência.
      <br />
      <strong>Status:</strong> Campo informativo da situação da ocorrência,
      podendo ser <strong>Pendente</strong>, <strong>Em Andamento</strong> e{' '}
      <strong>Finalizado</strong>. Por padrão, após sua abertura, uma ocorrência
      tem seu status definido como Pendente.
      <br />
      <strong>Status SAC:</strong> Campo informativo de quem está tratando a
      ocorrência, podendo ser <strong>Claro</strong>, <strong>Nex</strong>,{' '}
      <strong>Premmiar</strong>, <strong>TradeUp</strong>,{' '}
      <strong>Inova</strong>, <strong>Fora Prazo</strong>. Este campo não
      aparece para os participantes, sendo visível apenas pelo usuários
      administrativos.
      <br />
      <strong>Razão da abertura: </strong> Informa o motivo da abertura da
      ocorrência, podendo ser <strong>Alteração cadastral</strong>,{' '}
      <strong>Pontos em validação</strong>,{' '}
      <strong>Contestação de vendas</strong>, <strong>Acesso site</strong>,{' '}
      <strong>Acesso Catálogo Prêmios</strong>,{' '}
      <strong>Resgate de prêmios</strong>, <strong>Reset de senha</strong>,{' '}
      <strong>Estorno de pontos</strong> e <strong>Outros</strong>.
      <br />
      <strong>De:</strong> Campo de data, que quando informado, filtra apenas
      ocorrências abertas a partir desta data.
      <br />
      <strong>Até:</strong> Campo de data, que quando informado, trás apenas
      ocorrências abertas até esta data.
      <br />
      <strong>Data da Resolução:</strong> Data em que a ocorrência teve seu
      status atualizado para Finalizado.
      <br />
      <strong>CPF:</strong> CPF do participante da ocorrência.
      <br />
      <br />
      Após preencher os campos, clique em <strong>Pesquisar</strong> para
      filtrar os resultados. Entre os listados, pode-se realizar uma nova
      filtragem pelo campo
      <strong>Pesquisar</strong>, digitando o texto desejado.
      <br />
      <strong>Descritivo das colunas da tabela:</strong>
      <br />
      <br />
      <strong>1. Ticket ID:</strong> Código da ocorrência.
      <br />
      <strong>2. Canal:</strong> Informa o canal em que a ocorrência foi aberta.
      <br />
      <strong>3. Origem da ocorrência:</strong> Informa qual canal de
      comunicação foi utilizado pelo participante para informar a ocorrência. Há
      as opções <strong>WhatsApp</strong>, <strong>Portal</strong> e{' '}
      <strong>E-mail</strong>.
      <br />
      <strong>6. Nome:</strong> Nome do participante da ocorrência.
      <br />
      <strong>7. CPF/CNPJ:</strong> Número do CPF ou CNPJ do participante da
      ocorrência.
      <br />
      <strong>8. Cargo:</strong> Cargo do participante da ocorrência.
      <br />
      <strong>9. Status:</strong> Campo informativo da situação da ocorrência,
      podendo ser <strong>Pendente</strong>, <strong>Em Andamento</strong> e{' '}
      <strong>Finalizado</strong>. Por padrão, após sua abertura, uma ocorrência
      tem seu status definido como Pendente.
      <br />
      <strong>10. Status SAC:</strong>Campo informativo de quem está tratando a
      ocorrência, podendo ser <strong>Claro</strong>, <strong>Nex</strong>,{' '}
      <strong>Premmiar</strong>, <strong>TradeUp</strong>,{' '}
      <strong>Inova</strong>, <strong>Fora Prazo</strong>. Este campo não
      aparece para os participantes, sendo visível apenas pelo usuários
      administrativos.
      <br />
      <strong>11. Categoria:</strong> Informa o motivo da abertura da
      ocorrência, podendo ser <strong>Alteração cadastral</strong>,{' '}
      <strong>Pontos em validação</strong>,{' '}
      <strong>Contestação de vendas</strong>, <strong>Acesso site</strong>,{' '}
      <strong>Acesso Catálogo Prêmios</strong>,{' '}
      <strong>Resgate de prêmios</strong>, <strong>Reset de senha</strong>,{' '}
      <strong>Estorno de pontos</strong> e <strong>Outros</strong>.
      <br />
      <strong>12. Prazo Resolução:</strong> Data informada pelo usuário admin
      para finalização da ocorrência. Este é preeenchido obrigatoriamente na
      primeira edição da ocorrência.
      <br />
      <strong>13. Data Abertura Ticket:</strong> Data em que a ocorrência foi
      aberta.
      <br />
      <strong>14. Resumo da Ocorrência:</strong> Texto opcional preenchido pelo
      usuário admin ao finalizar a ocorrência.
      <br />
      <br />
      Para emitir o relatório, clique no botão <strong>Download</strong>,
      presente do lado esquerdo do botão Pesquisar.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default ReportDescription;
