import React from 'react';

const PadLock = ({ color = '#484848' }) => {
  return (
    <svg
      width="27"
      height="29"
      viewBox="0 0 27 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.8433 3.5C12.5265 3.5 11.2678 4.01615 10.3429 4.92848C9.41872 5.84012 8.90332 7.07218 8.90332 8.35247V13.4344C8.90332 14.2629 8.23175 14.9344 7.40332 14.9344C6.57489 14.9344 5.90332 14.2629 5.90332 13.4344V8.35247C5.90332 6.26319 6.7449 4.2637 8.23615 2.79271C9.7267 1.3224 11.744 0.5 13.8433 0.5C15.9425 0.5 17.9598 1.3224 19.4504 2.79271C20.9416 4.2637 21.7832 6.26319 21.7832 8.35247V13.4344C21.7832 14.2629 21.1116 14.9344 20.2832 14.9344C19.4548 14.9344 18.7832 14.2629 18.7832 13.4344V8.35247C18.7832 7.07218 18.2678 5.84012 17.3436 4.92848C16.4187 4.01615 15.16 3.5 13.8433 3.5Z"
        fill="#C4C4C4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.82597 14.9344C4.21233 14.9344 3.75 15.4197 3.75 15.9754V24.8688C3.75 25.4245 4.21233 25.9098 4.82597 25.9098H22.8578C23.4714 25.9098 23.9338 25.4245 23.9338 24.8688V15.9754C23.9338 15.4197 23.4714 14.9344 22.8578 14.9344H4.82597ZM0.75 15.9754C0.75 13.7243 2.59428 11.9344 4.82597 11.9344H22.8578C25.0895 11.9344 26.9338 13.7243 26.9338 15.9754V24.8688C26.9338 27.1199 25.0895 28.9098 22.8578 28.9098H4.82597C2.59428 28.9098 0.75 27.1199 0.75 24.8688V15.9754Z"
        fill="#C4C4C4"
      />
    </svg>
  );
};
export default PadLock;
