const na = 'N/A';
const columns = [
  {
    name: 'Nome',
    selector: (row) => (row.usu_nome ? row.usu_nome : na),
    sortable: true,
    grow: 1,
    minWidth: '130px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.usu_nome ? row.usu_nome : na}</div>
    ),
  },
  {
    name: 'CPF',
    selector: (row) => (row.usu_cpf ? row.usu_cpf : na),
    sortable: true,
    grow: 1,
    minWidth: '130px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.usu_cpf ? row.usu_cpf : na}</div>
    ),
  },
  {
    name: 'ID do pedido',
    selector: (row) => (row.orderId ? row.orderId : na),
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.orderId ? row.orderId : na}</div>
    ),
  },
  {
    name: 'JSON',
    selector: (row) => (row.json ? row.json : na),
    sortable: true,
    grow: 1,
    minWidth: '750px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.json ? row.json : na}</div>
    ),
  },
  {
    name: 'ID Usuário',
    selector: (row) => (row.usu_id ? row.usu_id : na),
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.usu_id ? row.usu_id : na}</div>
    ),
  },
  {
    name: 'Ponto Total',
    selector: (row) => (row.totalPoints ? row.totalPoints : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.totalPoints ? row.totalPoints : na}
      </div>
    ),
  },
  {
    name: 'Data Pedido',
    selector: (row) => (row.dt_start ? row.dt_start : na),
    sortable: true,
    grow: 1,
    minWidth: '250px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.dt_start ? row.dt_start : na}</div>
    ),
  },
  {
    name: 'Data Atualização',
    selector: (row) => (row.dt_update ? row.dt_update : na),
    sortable: true,
    grow: 1,
    minWidth: '250px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.dt_update ? row.dt_update : na}</div>
    ),
  },
  {
    name: 'Data Estorno',
    selector: (row) => (row.dt_reversal ? row.dt_reversal : na),
    sortable: true,
    grow: 1,
    minWidth: '250px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.dt_reversal ? row.dt_reversal : na}
      </div>
    ),
  },
  {
    name: 'Data Confirmação',
    selector: (row) => (row.dt_confirm ? row.dt_confirm : na),
    sortable: true,
    grow: 1,
    minWidth: '250px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.dt_confirm ? row.dt_confirm : na}
      </div>
    ),
  },
  {
    name: 'Código Autorização',
    selector: (row) => (row.authorizationCode ? row.authorizationCode : na),
    sortable: true,
    grow: 1,
    minWidth: '140px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.authorizationCode ? row.authorizationCode : na}
      </div>
    ),
  },
  {
    name: 'Valor Total',
    selector: (row) => (row.Valor_Total ? row.Valor_Total : na),
    sortable: true,
    grow: 1,
    minWidth: '140px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.Valor_Total ? row.Valor_Total : na}
      </div>
    ),
  },
  {
    name: 'Valor Reais',
    selector: (row) => (row.Valor_Reais ? row.Valor_Reais : na),
    sortable: true,
    grow: 1,
    minWidth: '250px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.Valor_Reais ? row.Valor_Reais : na}
      </div>
    ),
  },
  {
    name: 'Valor Produto',
    selector: (row) => (row.Valor_Produto ? row.Valor_Produto : na),
    sortable: true,
    grow: 1,
    minWidth: '100px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.Valor_Produto ? row.Valor_Produto : na}
      </div>
    ),
  },
  {
    name: 'Valor Frete',
    selector: (row) => (row.Valor_Frete ? row.Valor_Frete : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.Valor_Frete ? row.Valor_Frete : na}
      </div>
    ),
  },
  {
    name: 'Valor Cash',
    selector: (row) => (row.Valor_Cash ? row.Valor_Cash : na),
    sortable: true,
    grow: 1,
    minWidth: '140px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.Valor_Cash ? row.Valor_Cash : na}
      </div>
    ),
  },
  {
    name: 'Supplier',
    selector: (row) => (row.Supplier ? row.Supplier : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.Supplier ? row.Supplier : na}</div>
    ),
  },
  {
    name: 'Produto',
    selector: (row) => (row.Produto ? row.Produto : na),
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.Produto ? row.Produto : na}</div>
    ),
  },
];

export default columns;
