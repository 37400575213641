import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  place-items: start;
  max-width: ${(props) => props.maxWidth};
  width: ${(props) => (props.width ? props.width : '100%')};
  border: none;
  pointer-events: ${(props) => props.disabled && 'none'};

  grid-area: ${(props) => props.gridarea};

  label {
    color: ${({ theme }) => theme.admincolors.contrast.black};
    //styleName: paragraph-desktop;

    font-size: ${({ theme }) => theme.adminfontsize.label};
    font-style: normal;
    font-weight: 400;
    line-height: ${({ theme }) => theme.lineheight.label};
    letter-spacing: 0em;
    text-align: left;
    padding-left: 6px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;

    span {
      color: ${({ theme }) => theme.admincolors.feedback.danger30};
    }
  }
  input {
    &::placeholder {
      color: ${({ theme }) => theme.oldColors.gray[40]};
    }
    color: ${({ theme }) => theme.admincolors.contrast.black};

    border: 1px solid #adadad;
    transition: ${({ theme }) => theme.transition};
    background-color: ${(props) =>
      props.disabled && props.theme.oldColors.gray[30]};
    padding: 8px 12px;
    border-radius: 30px;
    font-size: ${({ theme }) => theme.adminfontsize.input.default};
    line-height: 24px;
    width: 100%;
    height: 40px;

    outline-color: ${({ theme }) => theme.oldColors.primary[40]};

    ${(props) => {
      if (props.large)
        return `
          padding: 10px 18px;
          font-size: ${props.theme.adminfontsize.input.large};
          line-height: 28px;
        `;

      if (props.small)
        return `
          padding: 6px 14px;
          font-size: ${props.theme.adminfontsize.input.small};
          line-height: 20px;
        `;

      if (props.cardToggle)
        return `
          padding: 6px 2px;
          max-width: 50px;
          line-height: 20px;
          text-align: center;
        `;

      return '';
    }}

    ${(props) => {
      switch (props.alertType) {
        case 'warning':
          return `
        border-color: ${props.theme.oldColors.warning};
        
        `;

        case 'danger':
          return `         
          border-color: ${props.theme.admincolors.feedback.danger30};
          `;

        case 'success':
          return `
            border-color: ${props.theme.oldColors.success};
                `;
        case 'info':
          return `
            border-color: ${props.theme.oldColors.info};
                `;

        default:
          return `


                `;
      }
    }}
  }

  textarea {
    &::placeholder {
      color: ${({ theme }) => theme.oldColors.gray[40]};
    }
    color: ${({ theme }) => theme.admincolors.contrast.black};
    border: 1px solid #adadad;

    transition: ${({ theme }) => theme.transition};
    background-color: ${(props) =>
      props.disabled && props.theme.oldColors.gray[30]};
    padding: 8px 10px;
    border-radius: 10px;
    font-size: ${({ theme }) => theme.adminfontsize.input.default};
    line-height: 24px;
    width: 100%;
    height: 150px;
    overflow: auto;
    outline-color: ${({ theme }) => theme.oldColors.primary[40]};

    ${(props) => {
      if (props.large)
        return `
          padding: 10px 18px;
          font-size: ${props.theme.adminfontsize.input.large};
          line-height: 28px;
        `;

      if (props.small)
        return `
          padding: 6px 14px;
          font-size: ${props.theme.adminfontsize.input.small};
          line-height: 20px;
        `;

      if (props.cardToggle)
        return `
          padding: 6px 2px;
          max-width: 50px;
          line-height: 20px;
          text-align: center;
        `;

      return '';
    }}

    ${(props) => {
      switch (props.alertType) {
        case 'warning':
          return `
        border-color: ${props.theme.oldColors.warning};
        
        `;

        case 'danger':
          return `         
          border-color: ${props.theme.admincolors.feedback.danger30};
          `;

        case 'success':
          return `
            border-color: ${props.theme.oldColors.success};
                `;
        case 'info':
          return `
            border-color: ${props.theme.oldColors.info};
                `;

        default:
          return `


                `;
      }
    }}
  }
`;
export const Hide = styled.button`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
export const Input = styled.div`
  position: relative;
`;
export const Inner = styled.div`
  position: relative;
  display: ${(props) => props.add && 'grid'};
  grid-template-columns: ${(props) => props.add && 'auto auto'};
  grid-gap: 10px;
  input {
    padding-right: ${(props) => props.serverside && '40px'};
    &::placeholder {
      text-overflow: ellipsis;
    }
  }
  button {
    aspect-ratio: ${(props) => props.add && '1'};
    div {
      padding: ${(props) => props.add && '0'};
    }
  }

  width: 100%;
  ${(props) => {
    if (!props.showIncrement)
      return `
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
      `;

    return '';
  }}
`;
export const Message = styled.div`
  font-size: ${({ theme }) => theme.adminfontsize.message};
  line-height: 15px;
  margin: 6px 8px;
  ${(props) => {
    switch (props.alertType) {
      case 'warning':
        return `
        color: ${props.theme.oldColors.warning};
        
        `;

      case 'danger':
        return `         
          color: ${props.theme.admincolors.feedback.danger30};
          `;

      case 'success':
        return `
            color: ${props.theme.oldColors.success};
                `;
      case 'info':
        return `
            color: ${props.theme.oldColors.info};
                `;

      default:
        return `


                `;
    }
  }}
`;
export const CharLimit = styled.div`
  position: absolute;
  right: 5px;
  bottom: -6px;
  padding: 2px 6px;
  background-color: ${({ theme }) => theme.oldColors.cadetblue[50]};
  color: ${({ theme }) => theme.oldColors.cadetblue[80]};
  border-radius: 4px;
  font-weight: 600;
  font-size: ${({ theme }) => theme.adminfontsize.input.charlimit};
  line-height: 15px;
  transform: translateY(100%);
`;
export const ServerSideDropdown = styled.div`
  position: absolute;
  bottom: -10px;
  transform: translateY(calc(100% - 5px));
  background-color: white;
  display: grid;

  border-radius: 8px;

  width: 100%;
  overflow: hidden;

  max-height: 120px;
  overflow-y: auto;

  border: 1px solid ${({ theme }) => theme.oldColors.gray[50]};

  z-index: 1;
`;
export const ServerSideDropdownIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 7px;
`;
export const ServerSideDropdownItem = styled.button`
  padding: 8px 16px;

  width: 100%;
  text-align: left;
  transition: ${(props) => props.theme.transition};
  line-height: auto;
  &:focus {
    outline: 0;
  }

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.oldColors.primary[30]};
  }
`;
