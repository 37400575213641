import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.aside`
  background-color: ${({ theme }) => theme.colors.white};
  display: none;
  width: 100%;
  height: 100%;
  top: 143px;
  position: fixed;
  z-index: 90;
  transition: opacity 0.2s ease-in-out;
  overflow: auto;
  margin-bottom: 100px;
  ${({ showNotification }) =>
    showNotification &&
    css`
      display: block;
    `}

  ${({ fullPage }) =>
    fullPage &&
    css`
      display: flex;
      position: static;
      width: 100%;
      height: fit-content;
      z-index: 0;
      margin-bottom: 100px;
    `}

  ${media.greaterThan('medium')`
    z-index: 90;  
    width: 360px;
    height: fit-content;
    min-height: 300px;
    max-height: 500px;
    right: 145px;
    top: 120px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
    border-radius: 10px;
    ${({ fullPage }) =>
      fullPage &&
      css`
        display: flex;
        position: static;
        width: 100%;
        max-height: fit-content;
        z-index: 0;
      `}
`};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  gap: 20px;
  ${({ fullPage }) =>
    fullPage &&
    css`
      width: 100%;
    `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-family: Poppins;
  color: ${({ theme }) => theme.colors.red};
  letter-spacing: 0em;
`;

export const Title = styled.h2`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
`;

export const Button = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
`;

export const Loading = styled.div`
  position: absolute;
  border: 8px solid white;
  border-radius: 50%;
  border-top: 8px solid red;
  width: 24px;
  height: 24px;
  filter: drop-shadow(0 0 4px #00000030);
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  left: 24px;
  top: 0;
  transform: translate(-50%, -50%);
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const StateButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${({ theme, read }) =>
    read ? theme?.colors.red : theme?.colors.white};
  width: fit-content;
  min-width: 100px;
  height: 40px;
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;
  border: ${({ theme, read }) =>
    read ? `none` : `2px solid ${theme?.colors.red}`};
  color: ${({ theme, read }) =>
    read ? theme?.colors.white : theme?.colors.red};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ContentButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.colors.red};
`;

export const NotificationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
