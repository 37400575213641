import styled from 'styled-components';
import media from 'styled-media-query';

export const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  justify-items: center;
  padding-top: 4rem;
  width: 100%;
  border-bottom: 1px solid;
  overflow-x: scroll;

  h2 {
    font-size: ${({ theme }) => theme.adminfontsize.containertitle};
    color: ${({ theme }) => theme.admincolors.contrast.black};
    text-align: center;

    &:last-child {
      margin-top: 20px;
    }
  }

  ${media.lessThan('1000px')`
    width: 100%;
  `}
  button {
    ${media.lessThan('1000px')`
      width: 100%;
    `}
  }
`;
