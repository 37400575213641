import { useState } from 'react';
import Input from '~/components/Input';
import { appendObjectInFormData } from '~/utils';
import { Form } from '@unform/web';
import * as S from './styles';
import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { showNotification } from '~/utils/notificationBoxes';
import Header from '../Componentes/Header';
import { toDateTime } from '~/Helpers/Date';
import { verifyFile } from '~/Helpers/FileValidator';

const UpdateTicket = ({ id, ticket, setStep, setCurrent }) => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const handleSubmit = (data) => {
    setLoading(true);
    if (!allowUserResponse()) {
      return;
    }
    const formData = new FormData();
    appendObjectInFormData(formData, {
      usu_id: ticket.usu_id,
      message,
      ticket_id: ticket.id,
      ...data,
    });
    for (const file of files) {
      const item = Object.entries(file)[0];
      formData.append('file', new File([item[1]], item[0]));
    }
    api
      .post('/tickets/thread/create', formData)
      .then(() => {
        setLoading(false);
        showNotification('success', 'Mensagem enviada com sucesso!');
        setStep('list');
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  const handleStep = () => {
    setStep('list');
    setCurrent(null);
  };

  const allowUserResponse = () => {
    return ticket.allow_user_response === 1;
  };

  const threads = () => {
    return ticket.threads.map((item) => {
      return (
        <S.MessageWrapper>
          <S.MessageInfo>
            {item.admin_id ? 'SAC' : 'Você'} - {toDateTime(item.created_at)}
          </S.MessageInfo>
          <S.MessageData>{item.message}</S.MessageData>
        </S.MessageWrapper>
      );
    });
  };
  const cancelTicket = () => {
    setLoading(true);
    api
      .patch('/tickets/soft-delete', { id: ticket.id, user_id: ticket.usu_id })
      .then((response) => {
        setLoading(false);
        showNotification('success', `${response.data.message}`);
        setStep('list');
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  const confirmEndOfTicket = () => {
    setLoading(true);
    api
      .patch('/tickets/confirm-end', { id: ticket.id })
      .then((response) => {
        setLoading(false);
        showNotification('success', `${response.data.message}`);
        setStep('list');
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  return (
    <>
      <S.Button onClick={() => handleStep()}>Voltar</S.Button>
      <S.Header>
        <Header ticket={ticket} />
      </S.Header>
      <S.Message>{ticket.threads && threads()}</S.Message>
      <S.FormWrapper>
        <Form onSubmit={handleSubmit}>
          <Input
            name="message"
            defaultValue={
              !allowUserResponse()
                ? ticket.status === 'Finalizado'
                  ? 'Ocorrência finalizada'
                  : 'Aguarde nova mensadem do SAC'
                : ''
            }
            disabled={!allowUserResponse()}
            onChange={(e) => setMessage(e.target.value)}
          />
          {allowUserResponse() && (
            <>
              <br />
              <input
                type="file"
                onChange={(e) => verifyFile(e, setFiles)}
                multiple
              />
              <S.Button type="submit">Enviar</S.Button>
            </>
          )}
        </Form>
      </S.FormWrapper>
    </>
  );
};

export default UpdateTicket;
