import { toDateBR, InBetweenDates, toDateTimeNoSeconds } from '~/Helpers/Date';
const na = 'N/A';

const column = [
  {
    name: 'Nome',
    selector: (row) => row.name ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => <div data-tag="allowRowEvents">{row.name ?? na}</div>,
  },
  {
    name: 'Descrição',
    selector: (row) => row.description ?? na,
    sortable: true,
    minWidth: '250px',
    cell: (row) => <div data-tag="allowRowEvents">{row.description ?? na}</div>,
  },
  {
    name: 'Início',
    selector: (row) => (row.initial_date ? toDateBR(row.initial_date) : na),
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{toDateBR(row.initial_date) ?? na}</div>
    ),
  },
  {
    name: 'Fim',
    selector: (row) => (row.final_date ? toDateBR(row.final_date) : na),
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{toDateBR(row.final_date) ?? na}</div>
    ),
  },
  {
    name: 'Status',
    selector: (row) => InBetweenDates(row.initial_date, row.final_date),
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {InBetweenDates(row.initial_date, row.final_date)}
      </div>
    ),
  },
  {
    name: 'Regulamento',
    selector: (row) => row.regulation_file ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.regulation_file ? (
          <a href={row.regulation_file} target="_blank" rel="noreferrer">
            Ver mais
          </a>
        ) : (
          na
        )}
      </div>
    ),
  },
  {
    name: 'Produtos',
    selector: (row) => row.products ?? na,
    sortable: true,
    grow: 1,
    minWidth: '250px',
    cell: (row) => <div data-tag="allowRowEvents">{row.products ?? na}</div>,
  },
  {
    name: 'Produtos Regulamento',
    selector: (row) => row.regulation_products ?? na,
    sortable: true,
    grow: 1,
    minWidth: '250px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.regulation_products ?? na}</div>
    ),
  },
  {
    name: 'Cargos',
    selector: (row) => row.roles ?? na,
    sortable: true,
    grow: 1,
    minWidth: '250px',
    cell: (row) => <div data-tag="allowRowEvents">{row.roles ?? na}</div>,
  },
  {
    name: 'Regional',
    selector: (row) => row.regional ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => <div data-tag="allowRowEvents">{row.regional ?? na}</div>,
  },
  {
    name: 'Rede',
    selector: (row) => row.net ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => <div data-tag="allowRowEvents">{row.net ?? na}</div>,
  },
  {
    name: 'PDV',
    selector: (row) => row.pdv ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => <div data-tag="allowRowEvents">{row.pdv ?? na}</div>,
  },
  {
    name: 'Última atualização em',
    selector: (row) => toDateTimeNoSeconds(row.updated_at),
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{toDateTimeNoSeconds(row.updated_at)}</div>
    ),
  },
];

export default column;
