import React from 'react';

import * as S from './styles';

const HeaderButton = ({ name, type, view, setView }) => {
  return (
    <S.Wrapper
      onClick={() => {
        setView(type);
      }}
      className={type === view ? 'active' : 'noactive'}
    >
      <p>{name}</p>
    </S.Wrapper>
  );
};

export default HeaderButton;
