import * as S from './styles';
import moment from 'moment';

const SSOBody = ({
  url,
  limit_date,
  channel_name,
  balance = 0,
  deleteBalance,
}) => {
  const days = (date_1, date_2) => {
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
  };

  const formatter = new Intl.NumberFormat(['ban', 'id'], {
    maximumFractionDigits: 2,
  });

  return (
    <S.Wrapper>
      <S.Description>
        <b>Atenção participante,</b>
        <br />O seu cadastro foi migrado para o canal {channel_name}. Faça o
        resgate dos seus pontos acumulados em até{' '}
        {days(new Date(limit_date), new Date())} dias e continue aproveitando as
        vantagens do Giga. Após esse período eles irão expirar e uma nova fase
        de pontuação se inicia. NÃO PERCA TEMPO!
        <br />
        Seu saldo atual é <b>{formatter.format(balance)}</b>{' '}
        {balance === 0 || balance > 1 ? 'pontos' : 'ponto'}!
      </S.Description>
      <S.Buttons>
        <S.DeleteBalance onClick={deleteBalance}>
          Desistir do meu saldo
        </S.DeleteBalance>
        <S.Button href={url} target="_blank">
          Ok
        </S.Button>
      </S.Buttons>
    </S.Wrapper>
  );
};

export default SSOBody;
