const na = 'N/A';

const columns = [
  {
    name: 'Nome',
    selector: (row) => row.usu_nome ?? na,
    sortable: true,
    grow: 1,
    minWidth: '300px',
    cell: (row) => <div data-tag="allowRowEvents">{row.usu_nome ?? na}</div>,
  },
  {
    name: 'CPF',
    selector: (row) => row.cpf ?? na,
    sortable: true,
    grow: 1,
    minWidth: '160px',
    cell: (row) => <div data-tag="allowRowEvents">{row.cpf ?? na}</div>,
  },
  {
    name: 'Cargo',
    selector: (row) => row.cargo ?? na,
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => <div data-tag="allowRowEvents">{row.cargo ?? na}</div>,
  },
  {
    name: 'Regional',
    selector: (row) => row.regional ?? na,
    sortable: true,
    grow: 1,
    cell: (row) => <div data-tag="allowRowEvents">{row.regional ?? na}</div>,
  },
  {
    name: 'PDV',
    selector: (row) => row.pdv ?? na,
    sortable: true,
    grow: 1,
    cell: (row) => <div data-tag="allowRowEvents">{row.pdv ?? na}</div>,
  },
  {
    name: 'Rede',
    selector: (row) => row.rede ?? na,
    sortable: true,
    grow: 1,
    cell: (row) => <div data-tag="allowRowEvents">{row.rede ?? na}</div>,
  },
];

export default columns;
