import React, { useEffect, useState, useRef } from 'react';
import { toDateTime } from '~/Helpers/Date';
import { Select, Input } from '~/components/NexAdmin/Inputs/index';
import { Button } from '~/components/NexAdmin/Buttons/index';
import Table from '~/components/NexAdmin/Table';
import * as S from '../../styles';
import { Form } from '@unform/web';
import { states_list, regional_list } from '../../StatesList/index';
import { parseJwt } from '~/services/auth';
import api from '~/services/api';
import columns from './columns';
import Counter from '~/components/NexAdmin/Counter';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { appendObjectInFormData } from '~/utils';
import Download from '../../../Sales/Components/Download';
import DownloadHook from '~/hooks/NexAdmin/DownloadHook';

const UserList = ({ loading, setLoading, setStep, setRow }) => {
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [regional, setRegional] = useState([]);
  const current_user = parseJwt();
  const formRef = useRef();

  useEffect(() => {
    handleSubmit();
    getRegional();
  }, []);

  const handleSubmit = (data) => {
    setLoading(true);
    const form = new FormData();
    const status = data?.status ? data.status : '*';

    appendObjectInFormData(form, {
      channel_id: current_user.canal_id,
      user_level: current_user.usu_nivel,
      user_id: parseJwt().id,
      segmento: data?.Segmento ? data?.Segmento : '*',
      optin: data?.optin ? data?.optin : '*',

      ...data,
    });

    const params = new URLSearchParams(form);

    api
      .get(`/list-user/?${params}`)
      .then((response) => {
        const { count, users_found } = response.data;
        setUsers(users_found);
        setCount(count);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const getRegional = () => {
    setLoading(true);
    api
      .get(`/channel/admin/channel-data`)
      .then((response) => {
        const { regional } = response.data;
        regional.shift();
        setRegional([{ label: 'Todos', value: 'all' }, ...regional]);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const handleDownload = () => {
    const data = formRef.current.getData();
    const form = new FormData();
    appendObjectInFormData(form, {
      channel_id: current_user.canal_id,
      user_level: current_user.usu_nivel,
      user_id: parseJwt().id,
      download: true,
      segmento: data?.Segmento ? data?.Segmento : '*',
      ...data,
    });
    const params = new URLSearchParams(form);
    return api.get(`/list-user/?${params}`, { responseType: 'blob' });
  };

  const buildReportName = (name) => {
    return `Relatório ${name} - Canal ${localStorage.getItem(
      'canalNome',
    )} - ${toDateTime(new Date())}`;
  };

  const beforeLoading = () => setLoading(true);

  const afterLoading = () => setLoading(false);

  const { execute, ref, urlFile, nameFile } = DownloadHook({
    externalCall: handleDownload,
    onError: HTTPErrorCallback,
    getFileName: buildReportName('Cadastro'),
    beforeLoading,
    afterLoading,
  });

  const switchColumn = (channel_id) => {
    switch (channel_id) {
      case 5:
        return columns['recarga'];
      case 6:
        return columns['aparelhos'];
      case 7:
        return columns['digital'];
      case 8:
        return columns['pap'];
      case 9:
        return columns['pap_pc'];
      default:
        return columns['all'];
    }
  };

  const actions = (row) => {
    setRow(row);
    setStep('data');
  };

  return (
    <>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <S.FormWrapper>
          <S.FormFilters>
            <Input
              label="Opt-in a partir de:"
              name="initial_date"
              type="date"
            />
            <Input label="Opt-in até:" name="final_date" type="date" />
            <Input
              label="Pontos/vendas a partir de:"
              name="sum_initial_date"
              type="date"
            />
            <Input
              label="Pontos/vendas a até:"
              name="sum_final_date"
              type="date"
            />
            <Select name="regional" options={regional} label="Regional" />
            {![2, 8, 9].includes(parseJwt().canal_id) && (
              <Select name="state" options={states_list} label="Estados" />
            )}
            <Input label="CPF" name="cpf" type="text" />
            <Select
              name="optin"
              options={[
                { label: 'Todos', value: '*' },
                { label: 'Sim', value: 'optin' },
                { label: 'Não', value: 'no-optin' },
              ]}
              label="Com aceite?"
              defaultValue={{ label: 'Todos', value: '*' }}
            />
            {/* <Select
              name="status"
              options={[
                { label: 'Todos', value: '*' },
                { label: 'Ativo', value: '1,2' }, // Corrigido aqui
                { label: 'Inativo', value: '3' },
              ]}
              label="Status de Bloqueio"
              defaultValue={{ label: 'Todos', value: '*' }}
            /> */}
            {current_user.canal_id === 9 && (
              <Select
                name="Segmento"
                options={[
                  { label: 'Todos', value: 'all' },
                  { label: 'Premium', value: 'PREMIUM' },
                  { label: 'Condomínio', value: 'CONDOMÍNIOS' },
                ]}
                label="Segmento"
                defaultValue={{ label: 'Todos', value: 'all' }}
              />
            )}
          </S.FormFilters>
          <Button type="submit">Consultar</Button>
        </S.FormWrapper>
        <S.DownloadWrapper>
          <a href={urlFile} download={nameFile} ref={ref} />
          <Download buttonName="Download" external={execute} />
        </S.DownloadWrapper>
      </Form>
      <Counter total={count} />
      <Table
        data={users}
        columns={[...switchColumn(current_user.canal_id)]}
        loading={loading}
        dataTableProps={{
          pagination: true,
          onRowClicked: (e) => actions(e),
        }}
        header
        dropdownItems={false}
        hasDownload={false}
      />
    </>
  );
};

export default UserList;
