import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  /* width: 100%;
  display: flex; */

  .react-select-container {
    border-radius: 50px;
  }

  .react-select__control--is-focused {
    border-color: red;
  }

  h1 {
    font-weight: normal;
    font-size: 1.6rem;
  }

  & + div {
    margin-left: 15px;
  }
`;

export const Message = styled.label`
  color: ${({ theme }) => theme.colors.grayDarker};
  font-size: 1.4rem;
  margin-bottom: 5px;
`;
