import React, { useState, useEffect, useRef } from 'react';
import getIcon from '~/Helpers/getIcon';
import * as S from './styles';

const Button = ({
  children,
  model,
  disabled,
  dropdownItems,
  leftIcon,
  rightIcon,
  gridarea,
  icon,
  iconColor,
  mt,
  small,
  width,
  title,
  ...rest
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef();

  const handleClick = (e) => {
    if (ref.current.contains(e.target)) return;

    setShowDropdown(false);
  };

  useEffect(() => {
    if (dropdownItems) document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [dropdownItems]);

  return (
    <>
      <S.Wrapper
        ref={ref}
        {...rest}
        small={small}
        mt={mt}
        dropdown={dropdownItems}
        disabled={disabled}
        model={model}
        gridarea={gridarea}
        width={width}
        title={title}
      >
        <S.Inner small={small} rightIcon={rightIcon} leftIcon={leftIcon}>
          {leftIcon && <S.Icon>{getIcon(icon, iconColor)}</S.Icon>}

          {children}
          {rightIcon && <S.Icon>{getIcon(icon, iconColor)}</S.Icon>}
        </S.Inner>

        {dropdownItems && (
          <>
            <S.Dropdown
              model={model}
              active={showDropdown}
              onClick={(e) => {
                e.stopPropagation();
                setShowDropdown(!showDropdown);
              }}
            >
              {getIcon('downArrow', 'black')}
            </S.Dropdown>

            {showDropdown && (
              <S.DropdownUl>
                {dropdownItems.map((item) => (
                  <S.DropdownItem
                    key={item.label}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDropdown(!showDropdown);
                      if (item.onClick) item.onClick();
                    }}
                  >
                    {item.label}
                  </S.DropdownItem>
                ))}
              </S.DropdownUl>
            )}
          </>
        )}
      </S.Wrapper>
    </>
  );
};

export default Button;
