import { useEffect, useRef, useState } from 'react';
import { TiCamera } from 'react-icons/ti';

import * as S from './styles';

const UploadImage = ({ disabled, setValue }) => {
  const [previewProfilePhoto, setPreviewProfilePhoto] = useState('');
  const inputFileRef = useRef(null);
  const showPreview = () => {
    if (inputFileRef.current.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => setPreviewProfilePhoto(e.target.result);
      setPreviewProfilePhoto(
        reader.readAsDataURL(inputFileRef.current.files[0]),
      );
    }
  };

  useEffect(() => {
    setValue(previewProfilePhoto);
  }, [previewProfilePhoto]);
  return (
    <S.Wrapper>
      <div>
        <button type="button">
          <TiCamera size={30} />
        </button>
        <input
          disabled={disabled}
          type="file"
          ref={inputFileRef}
          onChange={() => showPreview()}
          accept="image/x-png,image/jpeg"
        />
        {previewProfilePhoto && (
          <img alt="profile-preview" src={previewProfilePhoto} />
        )}
      </div>
    </S.Wrapper>
  );
};

export default UploadImage;
