const columns = [
  {
    name: 'Nome da Rede',
    selector: (row) => row.rede,
    cell: (row) => <div title={row.rede}>{row.rede}</div>,
    sortable: true,
  },
  {
    name: 'Ação',
    selector: (row) => row.action,
    cell: (row) => <div title={row.action}>{row.action}</div>,
    sortable: true,
  },
];

export default columns;
