import React from 'react';

const Accelerator = ({ color = '#484848' }) => {
  return (
    <svg
      width="28"
      height="23"
      viewBox="0 0 28 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5224 6.78379L23.8311 9.32754C24.6467 10.9543 25.046 12.7579 24.9932 14.5769C24.9405 16.3959 24.4372 18.1734 23.5286 19.75H4.47112C3.29023 17.7014 2.8009 15.3268 3.07569 12.9783C3.35048 10.6297 4.37475 8.43218 5.99665 6.71151C7.61856 4.99083 9.75176 3.8386 12.08 3.42563C14.4083 3.01265 16.8076 3.36092 18.9224 4.41879L21.4661 2.72754C18.8763 1.06684 15.8043 0.322676 12.7416 0.614082C9.67887 0.905488 6.80236 2.21563 4.57217 4.33494C2.34199 6.45424 0.886993 9.26027 0.43992 12.3042C-0.00715387 15.3481 0.579528 18.454 2.10612 21.125C2.34607 21.5407 2.69062 21.8863 3.10551 22.1275C3.52041 22.3687 3.99121 22.4971 4.47112 22.5H23.5149C23.9994 22.502 24.4759 22.3758 24.8961 22.1344C25.3162 21.8929 25.6651 21.5447 25.9074 21.125C27.1743 18.9304 27.8101 16.4284 27.7445 13.8952C27.679 11.362 26.9148 8.89623 25.5361 6.77005L25.5224 6.78379ZM12.0611 16.1888C12.3165 16.4445 12.6198 16.6473 12.9537 16.7857C13.2875 16.9241 13.6454 16.9953 14.0067 16.9953C14.3681 16.9953 14.726 16.9241 15.0598 16.7857C15.3937 16.6473 15.697 16.4445 15.9524 16.1888L23.7349 4.51504L12.0611 12.2975C11.8054 12.5529 11.6026 12.8562 11.4642 13.1901C11.3258 13.5239 11.2546 13.8818 11.2546 14.2432C11.2546 14.6046 11.3258 14.9624 11.4642 15.2963C11.6026 15.6301 11.8054 15.9334 12.0611 16.1888V16.1888Z"
        fill={color}
      />
    </svg>
  );
};
export default Accelerator;
