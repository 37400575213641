import styled from 'styled-components/macro';

export const Wrapper = styled.button`
  /* ESTILO GLOBAL */
  position: relative;
  margin-top: ${(props) => props.mt};

  width: ${(props) => props.width};

  font-size: ${({ theme }) => theme.adminfontsize.button};
  grid-area: ${(props) => props.gridarea};
  font-weight: bold;
  height: 40px;
  max-height: 40px;

  line-height: ${({ theme }) => theme.lineheight.button};
  text-transform: uppercase;

  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); */
  color: ${(props) => props.theme.admincolors.contrast.black};

  transition: ${({ theme }) => theme.transition};
  cursor: pointer;

  border: none;
  border-radius: 30px;

  /* MODELOS - default, secondary outline, other  */
  &:focus {
    box-shadow: 0px 0px 0 2px
      ${(props) => props.theme.admincolors.contrast.black};
    outline: none;
  }

  ${({ disabled }) => disabled && 'opacity: 0.3'};

  ${(props) => {
    switch (props.model) {
      case 'outline':
        return `
        color: ${props.theme.oldColors.primary[40]};
        background-color: #ffffff00;
        &::before {
            top: 0;
            left: 0;
            border-radius: 30px;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            color: ${props.theme.oldColors.primary[40]};
            border-color: ${props.theme.oldColors.primary[40]};
            border: 3px solid;
        }
                `;

      case 'secondary':
        return `
          border-color: ${props.theme.oldColors.cadetblue[40]};
          background-color: ${props.theme.oldColors.cadetblue[40]};

                `;
      case 'other':
        return `
                `;

      default:
        return `
        background-color: ${props.theme.oldColors.primary[40]};
        border-color: ${props.theme.oldColors.primary[40]};

                `;
    }
  }}

  /* ESTILOS EM HOVER */

  &:hover, &:focus, &:focus-within {
    z-index: 1;
    ${(props) => {
      switch (props.model) {
        case 'outline':
          return `
          color: ${props.theme.admincolors.contrast.black};
          background-color: ${props.theme.oldColors.primary[50]};
          border-color: ${props.theme.oldColors.primary[50]};
          &::before {
         
            border-color: ${props.theme.oldColors.primary[50]};
       
        }
                `;

        case 'secondary':
          return `
            background-color: ${props.theme.oldColors.cadetblue[50]};
            border-color: ${props.theme.oldColors.cadetblue[50]};
                `;
        case 'other':
          return `
                `;

        default:
          return `
            background-color: ${props.theme.oldColors.primary[50]};
            border-color: ${props.theme.oldColors.primary[50]};
          `;
      }
    }}
  }

  /* DISABLED */
  ${(props) => {
    if (props.disabled)
      return `
        opacity: 0.5;
        pointer-events:none;
    `;

    return '';
  }}

  /* LAYOUTS */
  ${(props) => {
    if (props.dropdown)
      return `
        display: grid;
        grid-template-columns: auto 44px;    
        place-items: center; 
      `;

    return '';
  }}
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  padding: ${({ small }) => (small ? '6px 14px' : '6px 16px')};
  svg {
    width: 18px;
  }
  ${(props) => {
    if (props.leftIcon)
      return `
        display: grid;
        grid-template-columns: 28px auto;    
        grid-gap: 10px;
        place-items: center; 
      `;

    if (props.rightIcon)
      return `
        display: grid;
        grid-template-columns: auto 28px;    
        grid-gap: 10px;
        place-items: center; 
      `;

    return '';
  }}
`;
export const Dropdown = styled.button`
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
  border: none;
  background-color: transparent;
  border-left: 2px solid
    ${({ theme }) => theme.admincolors.contrast.blackopacity};
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: ${({ theme }) => theme.transition};
  animation: FadeInAnimation 1s linear 2s infinite alternate;

  /* ACTIVE */

  ${(props) => {
    if (props.active)
      return `
        background-color: ${props.theme.oldColors.primary[60]};
      `;
    return '';
  }}

  /* HOVER */
  &:hover, &:focus {
    ${(props) => {
      switch (props.model) {
        case 'outline':
          return `
        color: ${props.theme.oldColors.primary[40]};
        background-color: #ffffff00;
        &::before {
            top: 0;
            left: 0;
            border-radius: 8px;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            color: ${props.theme.oldColors.primary[40]};
            border-color: ${props.theme.oldColors.primary[40]};
            border: 3px solid;
        }
                `;

        case 'secondary':
          return `
          border-color: ${props.theme.oldColors.cadetblue[40]};
          background-color: ${props.theme.oldColors.cadetblue[40]};
                `;
        case 'other':
          return `
                `;

        default:
          return `
        background-color: ${props.theme.oldColors.primary[40]};
        border-color: ${props.theme.oldColors.primary[40]};

                `;
      }
    }}
  }
`;
export const DropdownUl = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 70px;
  position: absolute;
  right: 0;
  z-index: 1;
  bottom: -5px;
  transform: translateY(100%);
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.oldColors.lightgray};
`;

export const DropdownItem = styled.button`
  padding: 13px 20px;
  border: none;
  background-color: white;

  cursor: pointer;

  transition: ${({ theme }) => theme.transition};
  font-size: ${({ theme }) => theme.adminfontsize.buttondropdown};
  font-style: normal;
  font-weight: 400;
  line-height: ${({ theme }) => theme.lineheight.buttondropdown};
  letter-spacing: 0em;
  text-align: center;
  width: 100%;
  text-align: left;

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.oldColors.lightorange};
  }
`;
export const Icon = styled.div`
  display: grid;
  place-items: center;
  height: 28px;
  width: 100%;
  border: none;

  background-color: transparent;
`;
