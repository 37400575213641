import styled from 'styled-components';

export const Wrapper = styled.li`
  /* background-color: ${({ theme }) => theme.colors.grayLight}; */
  & + li {
    margin-top: 10px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grayLight};
  padding: 6px 28px;
  border-radius: 25px;
  cursor: pointer;
  position: relative;
  z-index: 10;
`;

export const Title = styled.h1`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.red};
`;

export const Description = styled.div`
  /* transition: height 0.3s; */
  border-left: 1px solid ${({ theme }) => theme.colors.grayLight};
  border-right: 1px solid ${({ theme }) => theme.colors.grayLight};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayLight};
  position: relative;
  top: -15px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  overflow: hidden;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);

  &.opened {
    padding: 28px 46px 13px;
    visibility: visible;
  }

  &.closed {
    overflow: hidden;
    height: 0;
    visibility: hidden;
  }

  p {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.grayDarker};
  }
`;
