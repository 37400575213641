import React from 'react';
import { useState, useEffect } from 'react';
import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { showNotification } from '~/utils/notificationBoxes';

export const NotificationContext = React.createContext({
  getNotifications: () => {},
  readNotification: ({}) => {},
  totalNotifications: 0,
  totalUnreadNotifications: 0,
  loading: false,
  notifications: [],
  nextPage: () => {},
  notificationAmount: {},
  getUnreadNotifications: () => {},
});

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [notificationAmount, setNotificationAmount] = useState({
    Extract: 0,
    Tickets: 0,
    Dashboard: 0,
  });
  useEffect(() => {
    getNotificationAmount();
  }, [notifications]);

  const nextPage = () => {
    getNotifications();
  };
  const getUnreadNotifications = () => {
    setLoading(true);
    api
      .get(`/notifications/unread`)
      .then((response) => {
        const data = response.data;
        setUnreadNotifications(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };
  const getNotifications = () => {
    setLoading(true);
    api
      .get(`/notifications?page=${page}`)
      .then((response) => {
        const data = response.data;
        setPage((curr) => curr + 1);
        if (data.length) {
          setNotifications([...notifications, ...data]);
        } else if (page > 1) {
          setPage((curr) => curr - 1);
          // showNotification('info', 'Atenção', 'Não há mais notificações');
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  const getNotificationAmount = () => {
    const newNotificationAmount = {
      Extract: 0,
      Tickets: 0,
      Dashboard: 0,
    };
    for (let i = 0; i < notifications.length; i++) {
      const notification = notifications[i];
      const type = notification.type;
      if (newNotificationAmount[type] === 0 && notification.was_read === 0) {
        newNotificationAmount[type] += 1;
      }
    }
    setNotificationAmount(newNotificationAmount);
  };
  const readNotification = ({ notification_id, identifier }) => {
    if (!notification_id) return;
    setLoading(true);
    api
      .patch(
        `/notifications/read?identifier=${identifier}&notification_id=${notification_id}`,
      )
      .then((response) => {
        const newNotifications = [];
        for (const notification of notifications) {
          if (notification.id === notification_id) {
            newNotifications.push({ ...notification, was_read: true });
            continue;
          }
          newNotifications.push(notification);
        }

        setNotifications(newNotifications);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };
  const contextValue = {
    getNotifications,
    readNotification,
    loading,
    notifications,
    totalNotifications: notifications.length,
    totalUnreadNotifications: unreadNotifications,
    nextPage,
    notificationAmount,
    getUnreadNotifications,
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
