import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import ConfirmButton from '~/components/NexAdmin/Buttons/ConfirmButton';

const Actions = ({ row, refresh, setLoading, setStep, setRow }) => {
  const deleteBanner = (id) => {
    setLoading(true);
    api
      .delete(`/banners/delete/${id}`)
      .then(() => {
        setLoading(false);
        refresh();
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  return (
    <>
      <ConfirmButton
        icon={'trash'}
        iconColor={'red'}
        label="DELETAR?"
        confirmLabel={'SIM'}
        cancelLabel="CANCELAR"
        openOnRight={true}
        confirmAction={() => deleteBanner(row.dest_id)}
      />
      <ConfirmButton
        icon={'config'}
        iconColor={'green'}
        label="EDITAR?"
        confirmLabel={'SIM'}
        cancelLabel="CANCELAR"
        openOnRight={true}
        confirmAction={() => {
          setRow(row);
          setStep('update');
        }}
      />
    </>
  );
};

export default Actions;
