import { Form } from '@unform/web';

import { DigitSeparated } from './Components/DigitSeparatedInput';

import * as S from './styles';
import getIcon from '~/Helpers/getIcon';

const VerificationCode = ({
  submit = () => {},
  loading = false,
  title = 'Confirme o código',
  description = 'Enviamos um e-mail com um código de confirmação. Digite o código recebido para continuar',
  close = () => {},
  cancelText = 'Cancelar',
  submitText = 'Continuar',
}) => {
  return (
    <S.ModalWrapper>
      <S.Wrapper>
        <S.Header>
          <S.Title>{title}</S.Title>
          <div onClick={close}>{getIcon('close')}</div>
        </S.Header>
        <S.Description>{description}</S.Description>

        <Form onSubmit={submit}>
          <DigitSeparated
            type="digit-separated"
            required
            name="code"
            label="Código de verificação"
            placeholder="9"
            mask="9"
            digits={4}
          />

          <S.ButtonsWrapper>
            <S.Button type="button" onClick={close} outline disabled={loading}>
              {cancelText}
            </S.Button>
            <S.Button type="submit" disabled={loading}>
              {submitText}
            </S.Button>
          </S.ButtonsWrapper>
        </Form>
      </S.Wrapper>
    </S.ModalWrapper>
  );
};

export default VerificationCode;
