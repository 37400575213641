import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import { useState } from 'react';
import BannerForm from '../Components/BannerForm';
import Loading from '~/components/Loading';
import api from '~/services/api';
import CreateBannersDescription from '~/components/NexAdmin/Modal/Descriptions/Banners/createBannerDescription';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';

const CreateBanner = () => {
  const [loading, setLoading] = useState(false);
  const { openModal } = useModal();
  const externalCall = (data) => {
    return api.post('/banners/create', data);
  };
  const openThermsModal = () => {
    openModal({
      title: 'Criar Banner',
      close: true,
      content: <CreateBannersDescription />,
    });
  };
  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="upload"
          title="Criar Banner"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <BannerForm setLoading={setLoading} externalCall={externalCall} />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default CreateBanner;
