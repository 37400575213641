import { QuizzesContext } from '~/contexts/QuizzesContext';
import { useContext } from 'react';

const UseQuizzes = () => {
  const {
    GetChannelData,
    channelData,
    newQuiz,
    questionRef,
    quizRef,
    nextPage,
    previousPage,
    step,
    switchStep,
    searchDefaultValues,
    newQuestion,
    getRefData,
    getLetter,
  } = useContext(QuizzesContext);

  return {
    GetChannelData,
    channelData,
    newQuiz,
    questionRef,
    quizRef,
    nextPage,
    previousPage,
    step,
    switchStep,
    searchDefaultValues,
    newQuestion,
    getRefData,
    getLetter,
  };
};

export default UseQuizzes;
