const columns = [
  {
    name: 'CPF',
    selector: (row) => row.CPF,
    cell: (row) => <div title={row.CPF}>{row.CPF}</div>,
    sortable: true,
  },
  {
    name: 'STATUS',
    selector: (row) => row.STATUS,
    cell: (row) => <div title={row.STATUS}>{row.STATUS}</div>,
    sortable: true,
  },
  {
    name: 'OBSERVACAO',
    selector: (row) => row.OBSERVACAO,
    cell: (row) => <div title={row.OBSERVACAO}>{row.OBSERVACAO || 'N/A'}</div>,
    sortable: true,
  },
];

export default columns;
