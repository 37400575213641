import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f2f2f2;
  position: fixed;
  z-index: 99999;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 5px 60px 25px 60px;
  border-radius: 10px;
  box-shadow: 10px 10px 40px black;

  p {
    font-size: 18px;
    text-align: center;
  }

  .title {
    font-weight: 600;
    padding-bottom: 20px;
    padding-top: 30px;
  }

  .button {
    float: right;
    font-size: 22px;
    margin-top: 10px;
  }
`;
