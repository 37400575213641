import moment from 'moment';

const columns = [
  { name: 'Id', selector: (row) => row.id, maxWidth: '40px' },
  {
    name: 'Produtos participantes',
    selector: (row) => row.produtos_participantes,
  },
  { name: 'Titulo', selector: (row) => row.titulo, maxWidth: '60px' },
  {
    name: 'Início',
    selector: (row) => row.digital_dt_ini,
    maxWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>
          {row.digital_dt_ini
            ? moment(new Date(row.digital_dt_ini)).format('DD/MM/YYYY')
            : 'N/A'}
        </span>
      </div>
    ),
  },
  {
    name: 'Fim',
    selector: (row) => row.digital_dt_fim,
    maxWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>
          {row.digital_dt_fim
            ? moment(new Date(row.digital_dt_fim)).format('DD/MM/YYYY')
            : 'N/A'}
        </span>
      </div>
    ),
  },

  { name: 'Digital canal', selector: (row) => row.canal, maxWidth: '300px' },
];

export default columns;
