import * as S from './styles';
import { FaWindowClose as Close } from 'react-icons/fa';

const RegulationModal = ({
  regulationUrl,
  setShowRegulationModal,
  showRegulationModal,
}) => {
  return (
    <S.ModalWrapper>
      <S.Modal>
        <S.RegulationWrapper>
          <S.RegulationContainer>
            <S.RegulationPageTitle>
              <img src="/images/regulation-icon.png" alt="" />
              Regulamento:
            </S.RegulationPageTitle>

            <iframe
              width="100%"
              height="800px"
              src={`${regulationUrl}#toolbar=0&navpanes=0&scrollbar=0`}
              frameBorder="0"
              title="PDF viewer"
            />

            <S.RegulationButtonWrapper>
              <S.RegulationButton onClick={() => window.open(regulationUrl)}>
                Baixar regulamento
              </S.RegulationButton>
            </S.RegulationButtonWrapper>
          </S.RegulationContainer>
        </S.RegulationWrapper>
        <S.CloseBtn>
          <Close
            onClick={() => setShowRegulationModal(!showRegulationModal)}
            color="red"
            size="20"
          />
        </S.CloseBtn>
      </S.Modal>
    </S.ModalWrapper>
  );
};

export default RegulationModal;
