import React, { useState, useEffect } from 'react';
import Loading from '~/components/Loading';
import { goTopPage } from '~/utils';
import * as S from './styles';
import useLink from '../../utils/useLink';
import regulationLinks from './regulationLinks';

const Regulations = () => {
  const [loading, setLoading] = useState(true);
  const regulationLink = useLink(regulationLinks);

  useEffect(() => {
    let isMounted = true;

    goTopPage();
    if (isMounted) {
      setLoading(!regulationLink);
    }

    return () => {
      isMounted = false;
    };
  }, [regulationLink]);

  const downloadRegulation = () => {
    if (regulationLink) {
      window.open(regulationLink);
    }
  };

  return (
    <>
      {loading && <Loading />}
      <S.Wrapper>
        <S.Container>
          <S.PageTitle>
            <img src="/images/regulation-icon.png" alt="" />
            Regulamento:
          </S.PageTitle>
          {regulationLink && (
            <>
              <iframe
                width="100%"
                height="800px"
                src={`${regulationLink}#toolbar=0&navpanes=0&scrollbar=0`}
                frameBorder="0"
                title="pdf viewer"
              />
              <S.ButtonWrapper>
                <S.Button onClick={downloadRegulation}>
                  Baixar regulamento
                </S.Button>
              </S.ButtonWrapper>
            </>
          )}
        </S.Container>
      </S.Wrapper>
    </>
  );
};

export default Regulations;
