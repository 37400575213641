import Button from '~/components/NexAdmin/Buttons/Button';
import * as S from './styles.js';

const Errors = ({ errors, tryAgain }) => {
  if (!errors || !errors.length) return <></>;

  return (
    <S.Wrapper>
      <h2>
        Os erros abaixo foram encontrados no arquivo, corrija-os e tente
        novamente
      </h2>
      <S.ErrorsWrapper>
        <ul>
          {errors.map((error, i) => (
            <label>
              <input type="checkbox" />
              <span>{error}</span>
            </label>
          ))}
        </ul>
      </S.ErrorsWrapper>
      <Button type="button" width="50%" onClick={tryAgain}>
        Tentar novamente
      </Button>
    </S.Wrapper>
  );
};

export default Errors;
