import adminTheme from './Themes/Admin/theme';

export default {
  grid: {
    container: '130rem',
    gutter: '3.2rem',
  },
  border: {
    radius: '5rem',
  },
  font: {
    family:
      "Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    ff_roboto:
      "Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
    extraBold: 800,
    sizes: {
      xxsmall: '1.4rem',
      xsmall: '1.6rem',
      small: '2rem',
      medium: '2.4rem',
      large: '2.8rem',
      xlarge: '3.2rem',
      xxlarge: '4rem',
    },
  },
  colors: {
    grayDarker: '#484848',
    grayMedium: '#858585',
    grayLighter: '#C4C4C4',
    grayLight: '#F2F2F2',
    red: '#C50101',
    green: '#00C136',
    degrade: 'linear-gradient(178.18deg, #FF0000 -13.56%, #760000 158.3%);',
    white: '#ffff',
    sidebar: '#FFEEEE',
    footer: '#ED2129',
  },
  spacings: {
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '6.0rem',
    xlarge: '6.4rem',
    xxlarge: '12.8rem',
  },
  ...adminTheme,
};
