import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 30px;
  z-index: 0;
`;

export const Message = styled.h1``;
