import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 50px;

  h1 {
    margin-right: 15px;
    color: ${({ theme }) => theme.colors.grayDarker};
    font-size: 1.6rem;
  }
`;
export const Input = styled.div`
  padding: 12px 15px;
  border-radius: 63px;
  border: 1px solid #484848;
  min-width: 200px;
`;

export const Message = styled.h1``;
