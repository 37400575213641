import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AdminSignIn from '~/screens/AdminSignIn';
import CountdownPage from '~/screens/Countdown';
import Optin from '~/screens/Optin';
import SignIn from '~/screens/SignIn';
import NexAdminRoute from './Components/NexAdmin';
import AdminRoutes from './Components/NexAdmin/routes';
import CommonRoute from './Components/Common';
import CommonRoutes from './Components/Common/routes';
import Icons from '~/components/Icons';
import moment from 'moment';

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/admin" exact component={AdminSignIn} />
      <Route path="/optin" exact component={Optin} />
      <Route path="/countdown" exact component={CountdownPage} />
      {Object.entries(AdminRoutes).map(([, value], index) => (
        <NexAdminRoute {...value} key={index} />
      ))}
      {Object.entries(CommonRoutes).map(([, value], index) => (
        <CommonRoute {...value} key={index} />
      ))}
      <Route path="/icons" exact component={Icons} />
      {window.location.pathname.includes('admin') ? (
        <Redirect to="/admin" />
      ) : (
        <Redirect to="/" />
      )}
    </Switch>
  );
};

export default Routes;
