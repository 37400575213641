import * as S from './styles';

export const PDVSCard = ({ PDVS }) => {
  return (
    <S.PDVSCard>
      <table>
        <tr>
          <th>Código</th>
          <th>DDD</th>
          <th>Rede</th>
          <th>Regional</th>
        </tr>
        {PDVS.map((pdv) => (
          <tr key={pdv.codigo_ponto_de_venda}>
            <td>{pdv.codigo_ponto_de_venda}</td>
            <td>{pdv.ddd}</td>
            <td>{pdv.rede}</td>
            <td>{pdv.regional}</td>
          </tr>
        ))}
      </table>
    </S.PDVSCard>
  );
};
