import React from 'react';

const Star = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4754 3.08147L10.4755 3.08163L11.5455 6.37363L11.5456 6.37408C11.6437 6.67481 11.8343 6.93685 12.0902 7.12273C12.3462 7.30862 12.6543 7.40884 12.9706 7.40908H12.971H16.433C16.9162 7.40908 17.1197 8.02875 16.7268 8.31481C16.7268 8.31482 16.7268 8.31483 16.7267 8.31484L13.9274 10.3484C13.9273 10.3484 13.9272 10.3485 13.9271 10.3485C13.6708 10.5346 13.48 10.797 13.3819 11.0981C13.2838 11.3994 13.2836 11.724 13.3814 12.0254L13.3815 12.0256L14.4515 15.3176L14.4516 15.3179C14.6011 15.777 14.0749 16.1625 13.6809 15.8765C13.6808 15.8765 13.6808 15.8765 13.6808 15.8765L10.8809 13.8425L10.8808 13.8425C10.6246 13.6565 10.3161 13.5563 9.99951 13.5563C9.68292 13.5563 9.37445 13.6565 9.11826 13.8425L9.11814 13.8425L6.31814 15.8765L6.31798 15.8767C5.92678 16.1611 5.39889 15.779 5.54852 15.3176C5.54854 15.3176 5.54855 15.3175 5.54857 15.3175L6.61852 12.0256L6.61861 12.0254C6.71637 11.724 6.71619 11.3994 6.61811 11.0981C6.52005 10.797 6.32918 10.5346 6.07284 10.3485C6.07276 10.3485 6.07268 10.3484 6.07261 10.3484L3.27427 8.31584C3.27422 8.3158 3.27416 8.31576 3.27411 8.31572C2.88192 8.03 3.08521 7.41008 3.568 7.41008H7.02887C7.02892 7.41008 7.02896 7.41008 7.029 7.41008C7.34553 7.41013 7.65396 7.31006 7.91016 7.12418C8.16639 6.93827 8.35724 6.67605 8.45537 6.37508L8.45552 6.37463L9.52552 3.08263L9.53713 3.04692C9.70827 2.62075 10.3302 2.63398 10.4754 3.08147Z"
        fill={color}
        stroke="#071831"
      />
    </svg>
  );
};
export default Star;
