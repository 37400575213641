const NA = 'N/A';

const getColorForStatus = (status) => {
  const result = { color: 'red' };
  if (status === 2) result.color = '#eab000';
  else if (status === 1) result.color = '#444444';
  else if (status === 3) result.color = 'green';
  return result;
};

const getTextForStatus = (status) => {
  let result;

  if (status === 1) result = 'Pendente';
  else if (status === 2) result = 'Pré-Aprovado';
  else if (status === 3) result = 'Aprovado';
  else if (status === 4) result = 'Reprovado';

  return result;
};

export const status_options = {
  pending: 1,
  preapproved: 0,
  approved: 2,
  repproved: 3,
};

export const status_options_AA = {
  pending: 1,
  preapproved: 2,
  approved: 3,
  repproved: 4,
};

export const base_columns_gn = [
  {
    name: 'Id GN',
    selector: 'user_id',
    sortable: true,
    grow: 1,
    minWidth: '120px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.user_id}</span>
      </div>
    ),
  },
  {
    name: 'Nome',
    selector: 'name',
    sortable: true,
    grow: 1,
    minWidth: '330px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.name ? row.name : 'N/A'}</div>
    ),
  },
  {
    name: 'CPF',
    selector: 'cpf',
    sortable: true,
    grow: 1,
    minWidth: '160px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.cpf ? row.cpf : 'N/A'}</div>
    ),
  },
  {
    name: 'Regional',
    selector: 'regional',
    sortable: true,
    grow: 1,
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.regional ? row.regional : 'N/A'}</div>
    ),
  },
  {
    name: 'PDV',
    selector: 'pdv',
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.pdv ? row.pdv : 'N/A'}</div>
    ),
  },
  {
    name: 'Rede',
    selector: 'net',
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.net ? row.net : 'N/A'}</div>
    ),
  },
];

export const pending_campagn = {
  name: 'Campanhas pendentes',
  selector: 'pending',
  sortable: true,
  grow: 1,
  minWidth: '150px',
  cell: (row) => (
    <div data-tag="allowRowEvents">{row.pending ? row.pending : '0'}</div>
  ),
};

export const approved_campagn = {
  name: 'Campanhas Aprovadas',
  selector: 'approved',
  sortable: true,
  grow: 1,
  minWidth: '150px',
  cell: (row) => (
    <div data-tag="allowRowEvents">{row.approved ? row.approved : '0'}</div>
  ),
};

export const repproved_campagn = {
  name: 'Campanhas Reprovadas',
  selector: 'repproved',
  sortable: true,
  grow: 1,
  minWidth: '150px',
  cell: (row) => (
    <div data-tag="allowRowEvents">{row.repproved ? row.repproved : '0'}</div>
  ),
};

export const preapproved_campagn = {
  name: 'Campanhas Pré-Aprovadas',
  selector: 'pre_approved',
  sortable: true,
  grow: 1,
  minWidth: '150px',
  cell: (row) => (
    <div data-tag="allowRowEvents">
      {row.pre_approved ? row.pre_approved : '0'}
    </div>
  ),
};

export const columns_users_varejo = [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.id ? row.id : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Data Inicio',
    selector: 'initial_date',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.initial_date ? row.initial_date : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Data Fim',
    selector: 'final_date',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.final_date ? row.final_date : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Id Indicado',
    selector: 'user_id',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.user_id ? row.user_id : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Nome Indicado',
    selector: 'user_name',
    sortable: true,
    grow: 1,
    minWidth: '400px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.user_name ? row.user_name : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'CPF Indicado',
    selector: 'cpf',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.cpf ? row.cpf : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Id Usuário GN',
    selector: 'gn_id',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.gn_id ? row.gn_id : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Pré Pago Meta',
    selector: 'Pré Pago',
    sortable: true,
    minWidth: '350px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row['Pré Pago'] ? row['Pré Pago'] : 'N/A'}
      </div>
    ),
  },
  {
    name: 'Pré Pago Quantidade',
    selector: 'Pré Pago-amount',
    sortable: true,
    minWidth: '350px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row['Pré Pago-amount'] ? row['Pré Pago-amount'] : 'N/A'}
      </div>
    ),
  },
  {
    name: 'Controle Boleto Meta',
    selector: 'Controle Boleto',
    sortable: true,
    minWidth: '350px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row['Controle Boleto'] ? row['Controle Boleto'] : 'N/A'}
      </div>
    ),
  },
  {
    name: 'Controle Boleto Quantidade',
    selector: 'Controle Boleto-amount',
    sortable: true,
    minWidth: '350px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row['Controle Boleto-amount'] ? row['Controle Boleto-amount'] : 'N/A'}
      </div>
    ),
  },
  {
    name: 'Banda Larga Meta',
    selector: 'Banda Larga',
    sortable: true,
    minWidth: '350px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row['Banda Larga'] ? row['Banda Larga'] : 'N/A'}
      </div>
    ),
  },
  {
    name: 'Banda Larga Quantidade',
    selector: 'Banda Larga-amount',
    sortable: true,
    minWidth: '350px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row['Banda Larga-amount'] ? row['Banda Larga-amount'] : 'N/A'}
      </div>
    ),
  },
  {
    name: 'Controle Fácil',
    selector: 'Controle Fácil',
    sortable: true,
    minWidth: '350px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row['Controle Fácil'] ? row['Controle Fácil'] : 'N/A'}
      </div>
    ),
  },
  {
    name: 'Controle Fácil Quantidade',
    selector: 'Controle Fácil-amount',
    sortable: true,
    minWidth: '350px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row['Controle Fácil-amount'] ? row['Controle Fácil-amount'] : 'N/A'}
      </div>
    ),
  },
  {
    name: 'Pós Pago Meta',
    selector: 'Pós Pago',
    sortable: true,
    minWidth: '350px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row['Pós Pago'] ? row['Pós Pago'] : 'N/A'}
      </div>
    ),
  },
  {
    name: 'Pós Pago Quantidade',
    selector: 'Pós Pago-amount',
    sortable: true,
    minWidth: '350px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row['Pós Pago-amount'] ? row['Pós Pago-amount'] : 'N/A'}
      </div>
    ),
  },
  {
    name: 'Tv Meta',
    selector: 'Tv',
    sortable: true,
    minWidth: '350px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.Tv ? row.Tv : 'N/A'}</div>
    ),
  },
  {
    name: 'Tv Quantidade',
    selector: 'Tv-amount',
    sortable: true,
    minWidth: '350px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row['Tv-amount'] ? row['Tv-amount'] : 'N/A'}
      </div>
    ),
  },
  {
    name: 'Fone Meta',
    selector: 'Fixo',
    sortable: true,
    minWidth: '350px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.Fixo ? row.Fixo : 'N/A'}</div>
    ),
  },
  {
    name: 'Fone Quantidade',
    selector: 'Fixo-amount',
    sortable: true,
    minWidth: '350px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row['Fixo-amount'] ? row['Fixo-amount'] : 'N/A'}
      </div>
    ),
  },
  {
    name: 'Pontos',
    selector: 'points',
    sortable: true,
    minWidth: '160px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.points ? row.points : 'N/A'}</div>
    ),
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents" style={getColorForStatus(row.status)}>
        {getTextForStatus(row.status)}
      </div>
    ),
  },
  {
    name: 'Motivo',
    selector: 'recused_reason',
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.recused_reason ? row.recused_reason : 'N/A'}
      </div>
    ),
  },
];

export const columns_users_recarga = [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.id ? row.id : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Data Inicio',
    selector: 'initial_date',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.initial_date ? row.initial_date : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Data Fim',
    selector: 'final_date',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.final_date ? row.final_date : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Id Indicado',
    selector: 'user_id',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.user_id ? row.user_id : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Nome Indicado',
    selector: 'user_name',
    sortable: true,
    grow: 1,
    minWidth: '400px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.user_name ? row.user_name : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'CPF Indicado',
    selector: 'cpf',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.cpf ? row.cpf : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Regional',
    selector: 'cpf',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.regional ? row.regional : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'PDV',
    selector: 'cpf',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.pdv ? row.pdv : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Chip Flex Meta',
    selector: 'cpf',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row['Chip Flex-amount'] ? row['Chip Flex-amount'] : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Chip Combo Meta',
    selector: 'cpf',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>
          {row['Chip Combo-amount'] ? row['Chip Combo-amount'] : 'N/A'}
        </span>
      </div>
    ),
  },
  {
    name: 'Claro TV Pre SD Ativação Meta',
    selector: 'cpf',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>
          {row['Claro TV Pre SD - Ativação-amount']
            ? row['Claro TV Pre SD - Ativação-amount']
            : 'N/A'}
        </span>
      </div>
    ),
  },
  {
    name: 'Claro TV Pre SD Rastreamento Meta',
    selector: 'cpf',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>
          {row['Claro TV Pre SD - Rastreamento-amount']
            ? row['Claro TV Pre SD - Rastreamento-amount']
            : 'N/A'}
        </span>
      </div>
    ),
  },
  {
    name: 'Claro TV Pre HD Ativação Meta',
    selector: 'cpf',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>
          {row['Claro TV Pre HD - Ativação-amount']
            ? row['Claro TV Pre HD - Ativação-amount']
            : 'N/A'}
        </span>
      </div>
    ),
  },
  {
    name: 'Claro TV Pre HD Rastreamento Meta',
    selector: 'cpf',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>
          {row['Claro TV Pre HD - Rastreamento-amount']
            ? row['Claro TV Pre HD - Rastreamento-amount']
            : 'N/A'}
        </span>
      </div>
    ),
  },
  {
    name: 'PDV',
    selector: 'cpf',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.pdv ? row.pdv : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Pontos',
    selector: 'points',
    sortable: true,
    minWidth: '160px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.points ? row.points : 'N/A'}</div>
    ),
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents" style={getColorForStatus(row.status)}>
        {getTextForStatus(row.status)}
      </div>
    ),
  },
  {
    name: 'Motivo',
    selector: 'recused_reason',
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.recused_reason ? row.recused_reason : 'N/A'}
      </div>
    ),
  },
];

export const columns_users_AA = [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.id ? row.id : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Data Inicio',
    selector: 'initial_date',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.initial_date ? row.initial_date : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Data Fim',
    selector: 'final_date',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.final_date ? row.final_date : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Id Indicado',
    selector: 'user_id',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.user_id ? row.user_id : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Nome Indicado',
    selector: 'user_name',
    sortable: true,
    grow: 1,
    minWidth: '400px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.user_name ? row.user_name : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'CPF Indicado',
    selector: 'cpf',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.cpf ? row.cpf : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Id Usuário GN',
    selector: 'gn_id',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.gn_id ? row.gn_id : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Descrição',
    selector: 'description',
    sortable: true,
    minWidth: '350px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.description ? row.description : 'N/A'}
      </div>
    ),
  },
  {
    name: 'Pontos',
    selector: 'points',
    sortable: true,
    minWidth: '160px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.points ? row.points : 'N/A'}</div>
    ),
  },
  {
    name: 'Tipo',
    selector: 'transaction_type',
    sortable: true,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.transaction_type ? row.transaction_type : 'N/A'}
      </div>
    ),
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents" style={getColorForStatus(row.status)}>
        {getTextForStatus(row.status)}
      </div>
    ),
  },
  {
    name: 'Motivo',
    selector: 'recused_reason',
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.recused_reason ? row.recused_reason : 'N/A'}
      </div>
    ),
  },
];

export const columns_gn_geral = [
  {
    name: 'Id Usuário',
    selector: (row) => row.user_id,
    sortable: true,
    grow: 1,
    minWidth: '120px',
    cell: (row) => row.user_id,
  },
  {
    name: 'Nome',
    selector: (row) => row.name,
    sortable: true,
    grow: 1,
    minWidth: '330px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.name ? row.name : 'N/A'}</div>
    ),
  },
  {
    name: 'CPF',
    selector: 'cpf',
    sortable: true,
    grow: 1,
    minWidth: '160px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.cpf ? row.cpf : 'N/A'}</div>
    ),
  },
  {
    name: 'Regional',
    selector: (row) => row.regional,
    sortable: true,
    grow: 1,
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.regional ? row.regional : 'N/A'}</div>
    ),
  },
  {
    name: 'PDV',
    selector: (row) => row.pdv,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.pdv ? row.pdv : 'N/A'}</div>
    ),
  },
  {
    name: 'Rede',
    selector: (row) => row.net,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.net ? row.net : 'N/A'}</div>
    ),
  },
  {
    name: 'Campanhas Pendentes',
    selector: (row) => row.pending,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.pending ? row.pending : '0'}</div>
    ),
  },
  {
    name: 'Pontos Pendentes',
    selector: (row) => row.pending_points,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.pending_points ? row.pending_points : '0'}
      </div>
    ),
  },
  {
    name: 'Campanhas Pré-Aprovadas',
    selector: (row) => row.pre_approved,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.pre_approved ? row.pre_approved : '0'}
      </div>
    ),
  },
  {
    name: 'Pontos Pré-Aprovados',
    selector: (row) => row.pre_approved_points,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.pre_approved_points ? row.pre_approved_points : '0'}
      </div>
    ),
  },
  {
    name: 'Campanhas Aprovadas',
    selector: (row) => row.approved,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.approved ? row.approved : '0'}</div>
    ),
  },
  {
    name: 'Pontos Aprovados',
    selector: (row) => row.approved_points,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.approved_points ? row.approved_points : '0'}
      </div>
    ),
  },
  {
    name: 'Campanhas Reprovadas',
    selector: (row) => row.repproved,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.repproved ? row.repproved : '0'}</div>
    ),
  },
  {
    name: 'Pontos Reprovados',
    selector: (row) => row.repproved_points,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.repproved_points ? row.repproved_points : '0'}
      </div>
    ),
  },
  {
    name: 'Pontuação disponível',
    selector: (row) => row.points,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.points ? row.points : '0'}</div>
    ),
  },
];

export const columns_users_geral_AA = [
  {
    name: 'id',
    selector: (row) => row.id,
    sortable: true,
    grow: 1,
    cell: (row) => row.id,
  },
  {
    name: 'Data de inicio',
    selector: (row) => row.initial_date,
    sortable: true,
    grow: 1,
    minWidth: '180px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.initial_date ? row.initial_date : 'N/A'}
      </div>
    ),
  },

  {
    name: 'Data de fim',
    selector: (row) => row.final_date,
    sortable: true,
    grow: 1,
    minWidth: '180px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.final_date ? row.final_date : 'N/A'}
      </div>
    ),
  },

  {
    name: 'Id Usuário',
    selector: (row) => row.user_id,
    sortable: true,
    grow: 1,
    minWidth: '120px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.user_id}</span>
      </div>
    ),
  },

  {
    name: 'Nome',
    selector: (row) => row.user_name,
    sortable: true,
    grow: 1,
    minWidth: '180px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.user_name ? row.user_name : 'N/A'}
      </div>
    ),
  },

  {
    name: 'CPF',
    selector: (row) => row.cpf,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.cpf ? row.cpf : 'N/A'}</span>
      </div>
    ),
  },

  {
    name: 'Regional',
    selector: (row) => row.regional,
    sortable: true,
    grow: 1,
    minWidth: '100px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.regional ? row.regional : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'pdv',
    selector: (row) => row.pdv,
    sortable: true,
    grow: 1,
    minWidth: '100px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.pdv ? row.pdv : 'N/A'}</span>
      </div>
    ),
  },

  {
    name: 'Descrição',
    selector: (row) => row.description,
    sortable: true,
    grow: 1,
    minWidth: '250px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.description ? row.description : 'N/A'}
      </div>
    ),
  },

  {
    name: 'pontos',
    selector: (row) => row.points,
    sortable: true,
    grow: 1,
    minWidth: '100px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.points ? row.points : 'N/A'}</div>
    ),
  },
  {
    name: 'status',
    selector: (row) => row.status,
    sortable: true,
    grow: 1,
    minWidth: '190px',
    cell: (row) => (
      <div data-tag="allowRowEvents" style={getColorForStatus(row.status)}>
        {getTextForStatus(row.status)}
      </div>
    ),
  },
  {
    name: 'GN Nome',
    selector: (row) => row.gn_name,
    sortable: true,
    grow: 1,
    minWidth: '250px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.gn_name ? row.gn_name : 'N/A'}</div>
    ),
  },

  {
    name: 'GN CPF',
    selector: (row) => row.gn_cpf,
    sortable: true,
    grow: 1,
    minWidth: '250px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.gn_cpf ? row.gn_cpf : 'N/A'}</div>
    ),
  },

  {
    name: 'Motivo recusa',
    selector: (row) => row.recused_reason,
    sortable: true,
    grow: 1,
    minWidth: '250px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.recused_reason ? row.recused_reason : 'N/A'}
      </div>
    ),
  },
];

export const columns_users_geral = [
  {
    name: 'ID',
    selector: (row) => row['id'] ?? NA,
    sortable: true,
  },
  {
    name: 'Data Inicio',
    selector: (row) => row['initial_date'] ?? NA,
    sortable: true,
  },
  {
    name: 'Data Fim',
    selector: (row) => row['final_date'] ?? NA,
    sortable: true,
  },
  {
    name: 'Id Indicado',
    selector: (row) => row['user_id'] ?? NA,
    sortable: true,
  },
  {
    name: 'Nome Indicado',
    selector: (row) => row['user_name'] ?? NA,
    sortable: true,
  },
  {
    name: 'CPF Indicado',
    selector: (row) => row['cpf'] ?? NA,
    sortable: true,
  },
  {
    name: 'Regional',
    selector: (row) => row.regional,
    sortable: true,
    grow: 1,
    minWidth: '100px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.regional ? row.regional : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'PDV',
    selector: (row) => row.pdv,
    sortable: true,
    grow: 1,
    minWidth: '100px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.pdv ? row.pdv : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Pontos',
    selector: (row) => row.points,
    sortable: true,
    grow: 1,
    minWidth: '100px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.points ? row.points : 'N/A'}</div>
    ),
  },
  {
    name: 'GN Nome',
    selector: (row) => row.gn_name,
    sortable: true,
    grow: 1,
    minWidth: '250px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.gn_name ? row.gn_name : 'N/A'}</div>
    ),
  },

  {
    name: 'GN CPF',
    selector: (row) => row.gn_cpf,
    sortable: true,
    grow: 1,
    minWidth: '250px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.gn_cpf ? row.gn_cpf : 'N/A'}</div>
    ),
  },
  {
    name: 'Id Usuário GN',
    selector: (row) => row['gn_id'] ?? NA,
    sortable: true,
  },
  {
    name: 'Pré Pago Meta',
    selector: (row) => row['Pré Pago'] ?? NA,
    sortable: true,
    cell: (row) => <div data-tag="allowRowEvents">{row['Pré Pago'] ?? NA}</div>,
  },
  {
    name: 'Pré Pago Quantidade',
    selector: (row) => row['Pré Pago-amount'] ?? NA,
    sortable: true,
  },
  {
    name: 'Controle Boleto Meta',
    selector: (row) => row['Controle Boleto'] ?? NA,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">{row['Controle Boleto'] ?? NA}</div>
    ),
  },
  {
    name: 'Controle Boleto Quantidade',
    selector: (row) => row['Controle Boleto-amount'] ?? NA,
    sortable: true,
  },
  {
    name: 'Banda Larga Meta',
    selector: (row) => row['Banda Larga'] ?? NA,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">{row['Banda Larga'] ?? NA}</div>
    ),
  },
  {
    name: 'Banda Larga Quantidade',
    selector: (row) => row['Banda Larga-amount'] ?? NA,
    sortable: true,
  },
  {
    name: 'Controle Fácil Meta',
    selector: (row) => row['Controle Fácil'] ?? NA,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">{row['Controle Fácil'] ?? NA}</div>
    ),
  },
  {
    name: 'Controle Fácil Quantidade',
    selector: (row) => row['Controle Fácil-amount'] ?? NA,
    sortable: true,
  },
  {
    name: 'Pós Pago Meta',
    selector: (row) => row['Pós Pago'] ?? NA,
    sortable: true,
    cell: (row) => <div data-tag="allowRowEvents">{row['Pós Pago'] ?? NA}</div>,
  },
  {
    name: 'Pós Pago Quantidade',
    selector: (row) => row['Pós Pago-amount'] ?? NA,
    sortable: true,
  },
  {
    name: 'Tv Meta',
    selector: (row) => row['Tv'] ?? NA,
    sortable: true,
    cell: (row) => <div data-tag="allowRowEvents">{row['Tv'] ?? NA}</div>,
  },
  {
    name: 'Tv Quantidade',
    selector: (row) => row['Tv-amount'] ?? NA,
    sortable: true,
  },
  {
    name: 'Fone Meta',
    selector: (row) => row['Fixo'] ?? NA,
    sortable: true,
    cell: (row) => <div data-tag="allowRowEvents">{row['Fixo'] ?? NA}</div>,
  },
  {
    name: 'Fone Quantidade',
    selector: (row) => row['Fixo-amount'] ?? NA,
    sortable: true,
  },
  {
    name: 'Pontos',
    selector: (row) => row['points'] ?? NA,
    sortable: true,

    name: 'Status',
    selector: (row) => row['ponto_status'] ?? NA,
    sortable: true,
  },
  {
    name: 'Motivo',
    selector: (row) => row['recused_reason'] ?? NA,
    sortable: true,
  },
];

export const columns_insert_points = [
  {
    name: 'Data do Aceite',
    selector: (row) => row.accept_date,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.accept_date ? row.accept_date : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Hora do Aceite',
    selector: (row) => row.accept_time,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.accept_time ? row.accept_time : 'N/A'}
      </div>
    ),
  },
  {
    name: 'Data do Último Login',
    selector: (row) => row.last_login,
    sortable: true,
    grow: 1,
    minWidth: '180px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.last_login ? row.last_login : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Hora do Último Login',
    selector: (row) => row.last_login_time,
    sortable: true,
    grow: 1,
    minWidth: '180px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.last_login_time ? row.last_login_time : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Id Usuário',
    selector: (row) => row.user_id,
    sortable: true,
    grow: 1,
    minWidth: '120px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.user_id}</span>
      </div>
    ),
  },
  {
    name: 'CPF',
    selector: (row) => row.cpf,
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.cpf ? row.cpf : 'N/A'}</div>
    ),
  },
  {
    name: 'Nome',
    selector: (row) => row.name,
    sortable: true,
    grow: 1,
    minWidth: '330px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.name ? row.name : 'N/A'}</div>
    ),
  },
  {
    name: 'Cargo',
    selector: (row) => row.role,
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.role ? row.role : 'N/A'}</div>
    ),
  },
  {
    name: 'Regional',
    selector: (row) => row.regional,
    sortable: true,
    grow: 1,
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.regional ? row.regional : 'N/A'}</div>
    ),
  },
  {
    name: 'PDV',
    selector: (row) => row.pdv,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.pdv ? row.pdv : 'N/A'}</div>
    ),
  },
  {
    name: 'Rede',
    selector: (row) => row.net,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{row.net ? row.net : 'N/A'}</div>
    ),
  },
  {
    name: 'Saldo de Pontos',
    selector: (row) => row.points,
    sortable: true,
    grow: 1,
    minWidth: '180px',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.points ? row.points : '0'}</span>
      </div>
    ),
  },
];

export const columns_reprove_mass = [
  { name: 'ID', selector: 'transaction_id' },
  { name: 'Recusa', selector: 'recused_reason' },
];

export const columns_aprove_mass = [{ name: 'ID', selector: 'transaction_id' }];

export const columns_insert_mass = [
  { name: 'CPF', selector: 'cpf' },
  { name: 'Pontos', selector: 'points' },
];

export const columns_insert_mass_report = [
  {
    name: 'CPF',
    selector: (row) => row.CPF,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.CPF ? row.CPF : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Pontos',
    selector: (row) => row.Pontos,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.Pontos ? row.Pontos : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Mensagem',
    selector: (row) => row.Mensagem,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.Mensagem ? row.Mensagem : 'N/A'}</span>
      </div>
    ),
  },
];

export const columns_approve_mass_report = [
  {
    name: 'ID',
    selector: (row) => row.ID,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.ID ? row.ID : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Mensagem',
    selector: (row) => row.Mensagem,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.Mensagem ? row.Mensagem : 'N/A'}</span>
      </div>
    ),
  },
];

export const columns_repprove_mass_report = [
  {
    name: 'ID',
    selector: (row) => row.ID,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.ID ? row.ID : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Recusa',
    selector: (row) => row.Recusa,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.Recusa ? row.Recusa : 'N/A'}</span>
      </div>
    ),
  },
  {
    name: 'Mensagem',
    selector: (row) => row.Mensagem,
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <span>{row.Mensagem ? row.Mensagem : 'N/A'}</span>
      </div>
    ),
  },
];

export const column_gn_extract = [
  {
    name: 'Nome',
    selector: (row) => row.name ?? NA,
    sortable: true,
    grow: 1,
    minWidth: '150px',
  },
  {
    name: 'CPF',
    selector: (row) => row.cpf ?? NA,
    sortable: true,
    grow: 1,
    minWidth: '150px',
  },
  {
    name: 'Descrição',
    selector: (row) => row.description ?? NA,
    sortable: true,
    grow: 1,
    minWidth: '150px',
  },
  {
    name: 'Pontos',
    selector: (row) => row.points ?? NA,
    sortable: true,
    grow: 1,
    minWidth: '150px',
  },
  {
    name: 'Data',
    selector: (row) => row.created_at ?? NA,
    sortable: true,
    grow: 1,
    minWidth: '150px',
  },
];
