import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  margin-top: 30px;
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SubmitButton = styled.button`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  margin-top: 10px;
`;

export const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
`;
