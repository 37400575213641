import Title from '../../Components/Title';
import UseQuizzes from '~/hooks/NexAdmin/QuizzesHook';
import * as S from './styles';
import * as QuizStyle from '../Quiz/styles';
import * as QuestionStyle from '../../Components/Questions/styles';
import { Form } from '@unform/web';
import {
  Input,
  Select,
  InputUpload,
  ToggleSwitch,
} from '~/components/NexAdmin/Inputs';
import { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const Revision = () => {
  const { newQuiz, newQuestion, searchDefaultValues, getRefData, getLetter } =
    UseQuizzes();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Title title="Revisão do treinamento" />
      <Form>
        <S.Wrapper>
          <QuizStyle.Wrapper>
            <QuizStyle.InputWrapper>
              <Input
                label="Nome do Treinamento"
                name="title"
                defaultValue={newQuiz.title}
                required
                disabled
              />
              <Input
                label="Pontos"
                name="points"
                defaultValue={newQuiz.points}
                disabled
              />
              {newQuiz.points && (
                <Input
                  label="Percentual de acerto para receber a pontuação"
                  name="points_percentage"
                  defaultValue={newQuiz.points_percentage}
                  disabled
                />
              )}
              <ToggleSwitch
                name="active"
                label="Ativo?"
                defaultChecked={newQuiz?.active}
                disabled
              />
              <InputUpload
                mt="10px"
                // onGetFile={setFile}
                placeholder="Selecione um material"
                accept="application/pdf"
                name="file"
                disabled
              />
            </QuizStyle.InputWrapper>
            <QuizStyle.InputWrapper>
              <Select
                isMulti
                name="role_id"
                defaultValue={searchDefaultValues('role', 'role_id')}
                label="Cargos"
                placeholder="Selecione os cargos"
                isDisabled
              />
              <Select
                isMulti
                name="regional"
                // options={channelData.regional}
                defaultValue={searchDefaultValues('regional', 'regional')}
                label="Regional:"
                placeholder="Selecione a regional"
                large
                maxWidth="400px"
                isDisabled
              />
              <Select
                isMulti
                name="net"
                // options={channelData.net}
                defaultValue={searchDefaultValues('net', 'net')}
                label="Rede:"
                placeholder="Selecione a rede"
                large
                maxWidth="400px"
                isDisabled
              />
              <Select
                isMulti
                name="pdv"
                // options={channelData.pdv}
                defaultValue={searchDefaultValues('pdv', 'pdv')}
                label="PDV:"
                placeholder="Digite um pdv"
                large
                maxWidth="400px"
                isDisabled
              />
            </QuizStyle.InputWrapper>
          </QuizStyle.Wrapper>

          {newQuestion &&
            newQuestion.map((item, i) => (
              <QuestionStyle.Wrapper noBorder>
                <QuestionStyle.Header>
                  <QuestionStyle.Letter>{item.id + 1}</QuestionStyle.Letter>
                </QuestionStyle.Header>
                <QuestionStyle.QuestionWrapper>
                  <Input
                    name={`question_${item.id}`}
                    label="Pergunta"
                    textArea
                    maxLength="200"
                    defaultValue={getRefData(newQuestion, item.id, [
                      'question_',
                    ])}
                    disabled
                  />
                  <QuestionStyle.Option>
                    <Select
                      label="Resposta correta"
                      name={`correctly_anwser_${item.id}`}
                      defaultValue={getRefData(newQuestion, item.id, [
                        'correctly_anwser_',
                      ])}
                      placeholder="Selecione a resposta correta"
                      isDisabled
                    />
                    <QuestionStyle.Inner>
                      <QuestionStyle.ArrowWrapper
                        onClick={() => setOpen(!open)}
                      >
                        {!open ? (
                          <IoIosArrowDown size={20} />
                        ) : (
                          <IoIosArrowUp size={20} />
                        )}
                      </QuestionStyle.ArrowWrapper>
                    </QuestionStyle.Inner>
                  </QuestionStyle.Option>
                </QuestionStyle.QuestionWrapper>
                <QuestionStyle.AnswersWrapper
                  className={open ? 'opened' : 'closed'}
                >
                  {item.answers &&
                    item.answers.map((anwser, i) => (
                      <QuestionStyle.Answer key={i}>
                        <QuestionStyle.Letter>
                          {getLetter(i)}
                        </QuestionStyle.Letter>
                        <Input
                          key={i}
                          name={`question_${item.id}_anwser_${anwser.id}`}
                          label="Resposta"
                          textArea
                          maxLength="200"
                          defaultValue={anwser.content}
                          disabled
                        />
                      </QuestionStyle.Answer>
                    ))}
                </QuestionStyle.AnswersWrapper>
              </QuestionStyle.Wrapper>
            ))}
        </S.Wrapper>
      </Form>
    </>
  );
};

export default Revision;
