import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap !important;
  gap: 10px;
  margin: 20px 0px 40px;
  flex-wrap: ${({ calculator }) => (calculator ? 'wrap' : 'nowrap')};
  ${media.greaterThan('medium')`
  flex-wrap:nowrap;
  margin: 10px 0px;
    flex-direction: row;
    flex-wrap: ${({ calculator }) => (calculator ? 'wrap' : 'nowrap')};
  `};
  label {
    flex: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: 1.4rem;
    cursor: pointer;
    ${media.greaterThan('medium')`
      width: auto;
    `};
    & + label {
      margin-left: 0px;
      text-align: left;

      ${media.greaterThan('medium')`
        margin-left: ${({ calculator }) => (calculator ? '0' : '15px')};
      `};
    }

    input {
      margin-right: 5px;
      border: 1px solid #484848;
      border-radius: 2px;
      appearance: none;
      width: ${({ regulation }) => (regulation ? '27px' : '17px')};
      height: 17px;
      cursor: pointer;
      position: relative;

      &:checked {
        background-color: ${({ theme }) => theme.colors.red};
        border: transparent;
        background: ${({ theme }) => theme.colors.red}
          url('data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==')
          3px 3px no-repeat;
      }
    }
  }
`;

export const Link = styled.a`
  text-decoration: none;
  font-size: 1.4rem;
  flex: 1;
  white-space: nowrap;
  margin-left: 3px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.red};
`;
