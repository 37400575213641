import styled from 'styled-components/macro';
import media from 'styled-media-query';

export const Wrapper = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: ${({ open }) => (open ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

export const Inner = styled.div`
  position: relative;

  background-color: #f6f6f6;

  max-width: calc(100vw - 100px);
  min-width: 380px;
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: auto;

  height: auto;

  word-break: break-all;
  border-radius: 16px;
  z-index: 1;

  ${media.lessThan('380px')`
  min-width: 100%;
  `}
`;

export const Head = styled.div`
  padding: 10px 30px;
  display: grid;
  grid-template-rows: 70px;
  place-items: center;
  grid-gap: 10px;
`;
export const Icon = styled.div``;
export const Body = styled.div`
  padding: 20px 30px;
`;
export const Title = styled.div`
  width: 100%;
  color: ${(props) => props.color};
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
`;
export const Background = styled.div`
  color: white;
  position: fixed;
  height: 100vh;
  background: #00000090;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
