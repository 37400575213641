import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { showNotification } from '~/utils/notificationBoxes';
import Loading from '~/components/Loading';
import { appendObjectInFormData } from '~/utils';
import UseTwoFactor from '~/hooks/UseTwoFactor';

export const QuizzesContext = React.createContext({
  GetChannelData: () => {},
  channelData: {},
  newQuiz: {},
  quizRef: {},
  questionRef: {},
  switchStep: () => {},
  step: 1,
  nextPage: () => {},
  previousPage: () => {},
  searchDefaultValues: () => {},
  newQuestion: [],
  getRefData: () => {},
  getLetter: () => {},
});

const QuizzesProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [channelData, setChannelData] = useState({
    role: [],
    regional: [],
    net: [],
    pdv: [],
  });
  const [newQuiz, setNewQuizz] = useState({
    title: null,
    points: null,
    active: true,
    file: null,
    role_id: null,
    regional: null,
    net: null,
    pdv: null,
    points_percentage: null,
    description: null,
  });
  const [newQuestion, setNewQuestion] = useState([]);
  const [step, setStep] = useState(1);
  const quizRef = useRef(null);
  const questionRef = useRef([]);
  const { requestCode, FEATURES } = UseTwoFactor();
  useEffect(() => {
    GetChannelData();
  }, []);
  const GetChannelData = async () => {
    setLoading(true);
    try {
      const data = await api.get(`/channel/admin/channel-data`);
      const result = data.data;
      setChannelData((prev) => ({
        ...prev,
        role: result.role,
        net: result.net,
        pdv: result.pdv,
        regional: result.regional,
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return HTTPErrorCallback(error);
    }
  };
  const parseQuiz = () => {
    const data = quizRef.current?.getData();
    if (!data.title.length || !data.description.length) {
      return showNotification(
        'danger',
        'Atenção',
        'Informe o nome do treinamento e a descrição!',
      );
    }
    setNewQuizz((prev) => ({
      ...prev,
      title: data.title,
      points: data.points,
      active: Boolean(data.active === 'true'),
      file: data.file,
      role_id: data.role_id,
      regional: data.regional,
      net: data.net,
      pdv: data.pdv,
      points_percentage: data.points_percentage,
      description: data.description,
    }));
  };

  const parseQuestion = () => {
    const data = questionRef.current;
    const arr = [];
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        const result = data[i]?.getData();
        const parsedData = {};
        if (result) {
          Object.keys(result).forEach((key) => {
            if (result[key].length > 0) {
              if (key.startsWith('question_') && key.includes('_anwser_')) {
                if (!parsedData.answers) {
                  parsedData.answers = [];
                }
                const split = key.split('_');
                const answerIndex = split[split.length - 1];
                const answer = result[key];
                parsedData.answers.push({
                  id: parseInt(answerIndex),
                  content: answer,
                });
              } else if (key.startsWith('question_')) {
                const question = result[key];
                const questionIndex = key.split('_')[1];
                const correctAnswerKey = `correctly_anwser_${questionIndex}`;
                const correctAnswer = result[correctAnswerKey];
                parsedData[key] = question;
                parsedData[`correctly_anwser_${questionIndex}`] = {
                  label: getLetter(correctAnswer),
                  value: correctAnswer,
                };
                parsedData.id = parseInt(questionIndex);
              }
            }
          });
          if (parsedData.hasOwnProperty('id')) {
            arr.push(parsedData);
          }
        }
      }
    }
    setNewQuestion(arr);
  };
  const getLetter = (number) => {
    return String.fromCharCode(65 + number);
  };
  const finalize = async () => {
    setLoading(true);
    try {
      if (!newQuestion.length) {
        setLoading(false);
        return showNotification(
          'danger',
          'Atenção',
          'É obrigatório a criação de pelo menos uma pergunta!',
        );
      }
      for (let i = 0; i < newQuestion.length; i++) {
        const current = newQuestion[i];
        if (!current.answers?.length) {
          setLoading(false);
          return showNotification(
            'danger',
            'Atenção',
            'É obrigatório a criação de pelo menos uma resposta por pergunta!',
          );
        }
        const correctlyAnwser = Object.keys(current).find((prop) =>
          prop.includes('correctly_anwser'),
        );
        if (typeof current[correctlyAnwser]?.value !== 'number') {
          setLoading(false);
          return showNotification(
            'danger',
            'Atenção',
            'É obrigatório selecionar a resposta correta de cada pergunta!',
          );
        }
      }
      const formData = new FormData();
      appendObjectInFormData(formData, {
        ...newQuiz,
        role_id: JSON.stringify(newQuiz.role_id),
        regional: JSON.stringify(newQuiz.regional),
        net: JSON.stringify(newQuiz.net),
        pdv: JSON.stringify(newQuiz.pdv),
      });
      const quiz = await api.post('/quizzes/admin/quiz', formData);
      const quizId = quiz.data[0].id;
      if (Array.isArray(newQuestion)) {
        for (const current of newQuestion) {
          const currentAnswers = current.answers;
          const question = Object.keys(current).find((prop) =>
            prop.includes('question'),
          );
          const correctlyAnwser = Object.keys(current).find((prop) =>
            prop.includes('correctly_anwser'),
          );
          const questionCreated = await api.post('/quizzes/admin/question', {
            quiz_id: quizId,
            question: current[question],
          });
          const questionId = questionCreated.data[0];
          if (Array.isArray(currentAnswers)) {
            for (const currentItem of currentAnswers) {
              await api.post('/quizzes/admin/anwser', {
                question_id: questionId,
                awnser: currentItem.content,
                correctly: Boolean(
                  currentItem.id === current[correctlyAnwser].value,
                ),
              });
            }
          }
        }
      }
      setLoading(false);
      setNewQuestion([]);
      setNewQuizz({
        title: null,
        points: null,
        active: true,
        file: null,
        role_id: null,
        regional: null,
        net: null,
        pdv: null,
        points_percentage: null,
      });
      setStep(1);
      return showNotification('success', 'Sucesso!', 'Treinamento criado!');
    } catch (error) {
      setLoading(false);
      return HTTPErrorCallback(error);
    }
  };
  const switchStep = () => {
    switch (step) {
      case 1:
        return parseQuiz;
      case 2:
        return parseQuestion;
      case 3:
        return finalize;
    }
  };
  const nextPage = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };
  const previousPage = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };
  const searchDefaultValues = (option, prop) => {
    const data = channelData[option]?.filter((item) =>
      newQuiz[prop]?.includes(item.value),
    );
    return data.length ? data : [{ label: 'TODOS', value: null }];
  };
  const getRefData = (state, position, prop) => {
    return state[position] ? state[position][prop[0] + position] : null;
  };
  const contextValue = {
    GetChannelData,
    channelData,
    quizRef,
    questionRef,
    newQuiz,
    step,
    nextPage,
    previousPage,
    switchStep,
    searchDefaultValues,
    newQuestion,
    getRefData,
    getLetter,
  };
  return (
    <QuizzesContext.Provider value={contextValue}>
      {loading && <Loading />}
      {children}
    </QuizzesContext.Provider>
  );
};

export default QuizzesProvider;
