import React, { useState, useEffect } from 'react';

import api from '~/services/api';
import { goTopPage } from '~/utils';

import * as S from './styles';

const AboutCampaign = () => {
  const [periodo, setPeriodo] = useState('');
  const [conteudo, setConteudo] = useState('');

  const canal = localStorage.getItem('canal');

  useEffect(() => {
    goTopPage();
  }, []);

  useEffect(() => {
    api.get(`/sobre-campanha/${canal}`).then((response) => {
      setPeriodo(response.data.data[0].periodo);
      setConteudo(response.data.data[0].conteudo);
    });
  }, []);
  return (
    <>
      <S.Container>
        <S.Title>Bem-vindo ao Giga Clube! </S.Title>
        <S.Description>
          Aqui, as suas vendas se transformam em prêmios e experiências que
          realizam os seus sonhos. Saiba tudo o que você precisa abaixo.
        </S.Description>
        <S.Period>
          <p>Período</p>
          <S.PeriodData>
            <p>{periodo}</p>
          </S.PeriodData>
        </S.Period>
        <S.Content dangerouslySetInnerHTML={{ __html: conteudo }} />
      </S.Container>
    </>
  );
};

export default AboutCampaign;
