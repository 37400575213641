import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  position: relative;
  opacity: ${(props) => (props.inactive ? '0.3' : '1')};
  background-color: #fff;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  background: #ffffff;
  height: fit-content;
  min-height: 270px;
  max-width: 300px;
  button {
    width: 100% !important;
  }

  h2 {
    font-size: 1.6rem;
    color: #c50101;
    font-weight: 500;
    text-align: left;
  }
  width: 100vw;

  margin: 20px 0px;

  ${media.greaterThan('medium')`
        width: 100%;
    `};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  gap: 15px;
  width: 100%;
  height: 125px;
  padding: 20px 20px 10px;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.grayDarker};
  box-shadow: 0px 0px 30px rgba(133, 133, 133, 0.2);
  border-radius: 10px 10px 0px 0px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 5px;
  background: linear-gradient(178.18deg, #ff0000 -13.56%, #760000 158.3%);
  ${({ dummy }) =>
    dummy &&
    css`
      background: ${({ theme }) => theme.colors.grayLighter};
    `}
  box-shadow: 0px 0px 30px rgba(133, 133, 133, 0.2);
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  height: 205px;
  padding: 10px 20px 20px;
  align-items: flex-start;
`;

export const Products = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
  height: 60%;
  overflow: auto;
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    background: ${({ theme }) => theme.colors.grayLight};
    border-radius: 10px;
  }
  color: ${({ theme }) => theme.colors.grayLight};
  ${({ dummy }) =>
    dummy &&
    css`
      gap: 10px;
    `}
`;
export const Product = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.colors.white};
`;

export const ProductName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 10px;
  height: fit-content;
  margin: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Period = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PeriodItem = styled.div`
  color: ${({ theme }) => theme.colors.grayLight};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`;

export const ModalButton = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 10px;
  right: 15px;
  gap: 5px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
`;

export const RegulationWrapper = styled.section`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  padding: 30px 50px;
  z-index: 1;
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
`;

export const RegulationPageTitle = styled.h2`
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.colors.red};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  gap: 10px;
`;

export const RegulationButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;

export const RegulationButton = styled.button`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;

  margin-bottom: 20px;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-45%, -60%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  z-index: 100;
  h2 {
    color: red;
  }
  ${media.lessThan('medium')`
    transform: translate(-50%, -80%);
  `}
`;

export const CloseBtn = styled.div`
  position: absolute;
  right: 12px;
  top: 40px;
`;

export const Modal = styled.div`
  margin-top: -80px;
  border-radius: 20px;
  max-height: 80vh;
  max-width: 90vw;
  width: 1080px;
  position: relative;
`;

export const DangerouslySetInnerHTML = styled.div`
  width: 100%;
  height: 50px;
`;

export const ModalInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 30px;
  padding-right: 30px;
  overflow: auto;
  justify-content: flex-start;
`;

export const ModalInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.01em;
  text-align: justified;
  color: #484848;

  .title {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.01em;
    text-align: justified;
  }
  .content {
    padding-left: 40px;
  }
`;
