const columns = [
  {
    name: 'Data Criação',
    selector: (row) => row.created_at,
    sortable: true,
  },
  {
    name: 'CPF',
    selector: (row) => row.cpf,
    sortable: true,
  },
  {
    name: 'Nome',
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: 'Nomenclatura',
    selector: (row) => row.nomenclature,
  },
  {
    name: 'Pontos Bônus',
    selector: (row) => row.bonus_point,
    sortable: true,
  },
  {
    name: 'Origem',
    selector: (row) => row.origin,
    sortable: true,
  },
  {
    name: 'Segmento',
    selector: (row) => row.segmento,
    sortable: true,
  },
];

export default columns;
