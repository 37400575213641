import styled from 'styled-components';

export const SafariNotificationBackground = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: ${({ isComponent }) => (isComponent ? 'absolute' : 'fixed')};
  height: 220px;
  width: 35%;
  margin: 0;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: ${({ isComponent }) => (isComponent ? '10' : '100')};
  background: rgba(255, 255, 255);
  border: 1px solid #f4f4f4;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgb(133 133 133 / 30%);
  padding: 15px 0px;

  .logo-message-wrapper {
    display: flex;
    height: 70%;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    img {
      width: 100px;
      margin-right: 10px;
    }
    p {
      text-align: left;
    }
  }

  .buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;

    button {
      padding: 10px 20px;
      background: linear-gradient(178.18deg, #ff0000 -13.56%, #760000 158.3%);
      border-radius: 5px;
      color: white;
    }
  }
`;
