import { useState } from 'react';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';
import Loading from '~/components/Loading';
import { InputUpload, ToggleSwitch } from '~/components/NexAdmin/Inputs';
import * as S from './styles';
import { Form } from '@unform/web';
import Button from '~/components/NexAdmin/Buttons/Button';
import getIcon from '~/Helpers/getIcon';
import DownloadStaticFile from '~/components/NexAdmin/DownloadStaticFile';
import columns from './columns';
import { showNotification } from '~/utils/notificationBoxes';
import { HTTPErrorCallback } from '~/Helpers/Error';
import api from '~/services/api';
import Table from '~/components/NexAdmin/Table';
import moment from 'moment';

const model = [
  { rede: 'REDE TESTE 1', action: 'CRIAR' },
  { rede: 'REDE TESTE 2', action: 'DELETAR' },
];

const file_columns = [
  {
    name: 'Nome da Rede',
    selector: 'rede',
  },
  {
    name: 'Ação',
    selector: 'action',
  },
];

const NetworkMassUpload = () => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const [errors, setErrors] = useState([]);

  const validate = () => {
    setErrors([]);
    setData(null);

    if (!file)
      return showNotification(
        'warning',
        'Atenção',
        'Por favor, selecione um arquivo',
      );

    setLoading(true);

    const formData = new FormData();

    formData.append('sheet', file);

    api
      .post('/redes/validate', formData)
      .then((response) => {
        const { data_to_insert, errors } = response.data;
        if (data_to_insert && data_to_insert.length) {
          setData(data_to_insert);
        }
        if (errors && errors.length) {
          setErrors(errors);
        }
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const save = () => {
    setLoading(true);

    const formData = new FormData();

    formData.append('sheet', file);

    api
      .post('/redes/save', formData)
      .then((response) => {
        setLoading(false);
        showNotification('success', 'Sucesso', 'Redes salvas com sucesso!');

        setData(null);
        setErrors([]);
        setFile(null);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag icon="actions" title="Upload em Massa de Redes" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <S.Wrapper>
              <h2>
                Selecione um arquivo para incluir ou deletar redes em massa
              </h2>

              <S.InputWrapper>
                <Form>
                  <InputUpload
                    name="file"
                    onGetFile={(e) => {
                      setData(null);
                      setErrors([]);

                      setFile(e);
                    }}
                    placeholder={
                      <>{getIcon('upload')} Selecione um arquivo para upload</>
                    }
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </Form>
                <Button type="button" width="50%" onClick={validate}>
                  Validar
                </Button>
              </S.InputWrapper>

              {data && (
                <S.TableWrapper>
                  {errors.length ? (
                    <>
                      <h2>
                        Os dados abaixo contem erros e não serão processados
                      </h2>
                      <Table
                        columns={[
                          ...columns,
                          {
                            name: 'Erro',
                            selector: (row) => row.error,
                            cell: (row) => (
                              <div title={row.error}>{row.error}</div>
                            ),
                            sortable: true,
                          },
                        ]}
                        downloadColumns={[
                          ...columns,
                          {
                            name: 'Erro',
                            selector: (row) => row.error,
                            cell: (row) => (
                              <div title={row.error}>{row.error}</div>
                            ),
                            sortable: true,
                          },
                        ]}
                        data={errors}
                        dataTableProps={{ pagination: true }}
                        header={true}
                        fileName={`erros_redes_${moment().format(
                          'DD-MM-YYYY',
                        )}`}
                        hasDownload={true}
                      />
                    </>
                  ) : (
                    <>
                      <h2>Os dados abaixo serão processados ao enviar</h2>
                      <Table
                        columns={columns}
                        downloadColumns={columns}
                        data={data}
                        dataTableProps={{ pagination: true }}
                        header={true}
                        fileName={`parcial_redes_${moment().format(
                          'DD-MM-YYYY',
                        )}`}
                        hasDownload={true}
                      />
                      <Button type="button" width="50%" onClick={save}>
                        Confirmar
                      </Button>
                    </>
                  )}
                </S.TableWrapper>
              )}
            </S.Wrapper>

            <DownloadStaticFile
              columns={file_columns}
              list={model}
              file_name={'upload_redes_modelo'}
              button_name={'Download Modelo'}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default NetworkMassUpload;
