import React from 'react';

const LoginArrow = ({ color }) => {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.516251 10.0075L4.52375 6L0.516251 1.98375L1.75 0.75L7 6L1.75 11.25L0.516251 10.0075Z"
        fill={color}
      />
    </svg>
  );
};
export default LoginArrow;
