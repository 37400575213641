import * as S from './../styles';

const BatchesGNDescription = ({ isLAD }) => {
  return (
    <S.ThermWrapper>
      <br />
      Nesta tela é feita a inserção de dados em massa para os{' '}
      {isLAD ? 'LAD' : 'GN'}.
      <br />
      É possível, em lotes, inserir pontos, aprovar campanhas ou reprovar
      campanhas.
      <br />
      <br />
      As funcionalidades estão separadas entre as abas{' '}
      <strong>Inserir Pontos em Massa</strong>,{' '}
      <strong>Aprovar em massa</strong> e <strong>Reprovar em massa</strong>.
      <br />
      <br />
      Clique no botão "Download Modelo" para baixar um exemplo de como deve ser
      feita a inserção de dados pela planilha. Atente-se que cada funcionalidade
      possui um modelo de planilha diferente.
      <br />
      <br />
      Após configurar sua planilha, utilize a caixa de diálogo para fazer o
      upload de sua planilha personalizada, e então, clique em "Enviar".
      <br />
      <br />
      Após seu arquivo ser processado, você é redirecionado para a tela de
      relatórios.
      <br />
      Nesta tela, informações sobre os dados inputados serão exibidas.
      <br />
      <br />
      As informações sobre os dados são divididas entre 2 tabelas:
      <br />
      - A primeira tabela informa dados que foram inputados com sucesso.
      <br />
      - A segunda tabela informa dados que contém erros e não foram processados.
      <br />
      <br />
      Faça o download do relatório através do botão "Download".
      <br />
      Atente-se que só é possível fazer o download da planilha de relatório
      neste momento, portanto, certifique-se de baixar o relatório.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default BatchesGNDescription;
