import * as S from './styles';
import { Link, useLocation } from 'react-router-dom';
import getIcon from '~/Helpers/getIcon';
import { useState, useEffect, useRef } from 'react';
import Loading from '~/components/Loading';
import CommonRoutes from '~/routes/Components/Common/routes';
import Button from '~/components/Button';
import Checkbox from '~/components/Checkbox';
import { Form } from '@unform/web';
import { isMobile } from 'react-device-detect';
import TrainningModal from '../../Components/TrainningModal';
import { showNotification } from '~/utils/notificationBoxes';
import { HTTPErrorCallback } from '~/Helpers/Error';
import api from '~/services/api';
import Revision from '../../Components/Revision';
import Certificate from '../../Components/Certificate';
import { PDFDownloadLink } from '@react-pdf/renderer';

const QuizzesTraining = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [quizId] = useState(location.state ? location.state.quiz_id : null);
  const [quiz, setQuiz] = useState([]);
  const [term, setTerm] = useState(false);
  const [openTrainingModal, setOpenTrainingModal] = useState(false);

  useEffect(() => {
    getData();
  }, [location.state]);
  const getData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/quizzes/user/quiz?quiz_id=${quizId}`);
      setQuiz(response.data[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return HTTPErrorCallback(error);
    }
  };

  const switchStatus = () => {
    switch (quiz.progress) {
      case 0:
        return {
          status_component: (
            <S.Status progress={quiz.progress}>
              <S.Ball progress={quiz.progress} />
              Não iniciado
            </S.Status>
          ),
          box_component: (
            <>
              <S.ProgressBox type="#484848" background="#C4C4C4">
                {getIcon('information', '#484848')}
                Você ainda não iniciou este treinamento
              </S.ProgressBox>
              {quiz.video_url && (
                <Button
                  to="#"
                  className="b"
                  onClick={() => window.open(quiz.video_url, '_blank')}
                >
                  Assitir vídeo
                </Button>
              )}
              {quiz.file && (
                <Button
                  to="#"
                  className="b"
                  onClick={() => window.open(quiz.file, '_blank')}
                >
                  Baixar material
                </Button>
              )}
            </>
          ),
          button_name: 'Começar treinamento',
          button_action: () => {
            if (!term && !isMobile) {
              return showNotification(
                'info',
                'Atenção',
                'É obrigatório aceitar o termo para continuar!',
              );
            }
            setOpenTrainingModal(true);
          },
          accept_term: !isMobile && (
            <S.ConfirmWrapper>
              <Form>
                <Checkbox
                  name="accept"
                  options={[
                    {
                      id: 'terms',
                      value: 'terms',
                      label:
                        'Eu assisti ao vídeo por completo, baixei e li o material necessário para continuar.',
                    },
                  ]}
                  onClick={() => setTerm(!term)}
                />
              </Form>
            </S.ConfirmWrapper>
          ),
        };
      case 100:
        return {
          status_component: (
            <S.Status progress={quiz.progress}>
              <S.Ball progress={quiz.progress} />
              Concluido
            </S.Status>
          ),
          box_component: quiz.completed_points && (
            <S.ProgressBox type="#00C136" background="rgba(0, 193, 54, 0.20)">
              {getIcon('information', '#00C136')}
              {`Parabéns, você fez ${quiz.points ?? 0} pontos no treinamento.`}
            </S.ProgressBox>
          ),
          button_name: (
            <PDFDownloadLink
              style={{ width: '100%' }}
              document={
                <Certificate
                  user_name={quiz.user_name}
                  date={quiz.last_update}
                  title={quiz.title}
                />
              }
              fileName={`Certificado de Conclusão - ${quiz.title} - ${quiz.user_name}.pdf`}
            >
              Baixar Certificado
            </PDFDownloadLink>
          ),
          button_action: () => {},
          revision: <Revision questions={quiz.questions} quizId={quizId} />,
        };
      default:
        return {
          status_component: (
            <S.ProgressWrapper>
              <S.ProgressBar>
                <S.ProgressBarInner progress={quiz.progress} />
              </S.ProgressBar>
              <S.ProgressText>{`${quiz.progress}% concluido`}</S.ProgressText>
            </S.ProgressWrapper>
          ),
          box_component: (
            <S.ProgressBox type="#C50101" background="rgba(197, 1, 1, 0.20)">
              {getIcon('information', '#C50101')}
              {`Faltam ${100 - quiz.progress}% para concluir o treinamento`}
            </S.ProgressBox>
          ),
          button_name: 'Continuar treinamento',
          button_action: () => setOpenTrainingModal(true),
        };
    }
  };

  const switchType = switchStatus();

  return (
    <>
      {loading && <Loading />}
      {openTrainingModal && (
        <TrainningModal
          quiz={quiz}
          close={() => setOpenTrainingModal(false)}
          refresh={() => getData()}
        />
      )}
      <S.Wrapper>
        <S.Header>
          <S.LeftWrapper>
            <Link
              to={{
                pathname: CommonRoutes['QuizzesMain'].path,
              }}
            >
              <S.Button>{getIcon('rightArrow', '#C50101')} Voltar</S.Button>
            </Link>
            <S.Title>{quiz.title}</S.Title>
          </S.LeftWrapper>
          <S.RightWrapper>{switchType.status_component}</S.RightWrapper>
        </S.Header>
        <S.TrainingInfo>
          <S.Content>{quiz.description}</S.Content>
          <S.Box>
            <S.BoxWrapper>
              <S.BoxLeft>
                {getIcon('trainningBook', '#484848')}
                {quiz.title}
              </S.BoxLeft>
              <S.BoxRight>{switchType.box_component}</S.BoxRight>
            </S.BoxWrapper>
            {switchType.accept_term}
            <S.ConfirmButton className="b" onClick={switchType.button_action}>
              {switchType.button_name}
            </S.ConfirmButton>
          </S.Box>
        </S.TrainingInfo>
        {switchType?.revision ? switchType.revision : ''}
      </S.Wrapper>
    </>
  );
};

export default QuizzesTraining;
