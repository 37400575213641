import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Data = styled.div`
  margin-top: 20px;
  ${media.greaterThan('medium')`

  .filters {
    display: flex;
  }

  .search {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;

  div:first-child {
    width: 100%;
  }
    
  span {
      white-space: nowrap;
      margin-right: 10px;
      font-weight: 700;
      font-size: 1.6rem;
      color: ${({ theme }) => theme.colors.grayDarker};
  }
}
`};
`;

export const DataHeader = styled.div`
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-grow: 3; */
  margin-bottom: 40px;
  flex-direction: column-reverse;
  ${media.greaterThan('medium')`

  flex-direction: row;
  `};
  form {
    display: flex;
    align-items: center;
    // z-index: 100;
    width: 340px;

    svg {
      transform: rotate(-90deg);
      fill: ${({ theme }) => theme.colors.grayDarker};
    }

    span {
      margin-right: 10px;
      font-weight: 700;
      font-size: 1.6rem;
      color: ${({ theme }) => theme.colors.grayDarker};
    }
  }
  p {
    font-size: 1.4rem;
  }
`;

export const SubmitButton = styled.button`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  max-height: 50px;
  width: 150px;
  ${({ mt }) =>
    mt &&
    css`
      margin-top: 20px;
    `}

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const InputWrapper = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  place-items: flex-start;
  justify-content: center;

  p {
    margin-right: 10px;
    font-weight: 700;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.grayDarker};
  }
`;

export const DateWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: flex-start;
  align-items: end;
  width: 50%;
  ${media.lessThan('medium')`
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    place-items: center;
    align-items: center;
    width: 100%;
  `}

  ${({ end }) =>
    end &&
    `
  grid-template-columns: 1fr;
  place-items: end;
  `}
`;
