import * as S from './styles';

const Avatar = ({ rank, foto }) => {
  return (
    <S.Wrapper isRank={rank}>
      <S.ImgWrapper>
        <img src={foto} alt="Avatar" />
      </S.ImgWrapper>
    </S.Wrapper>
  );
};

export default Avatar;
