import React, { useState, useEffect } from 'react';
import 'dotenv/config';
import { HTTPErrorCallback } from '~/Helpers/Error';
import axios from 'axios';

export const AuthenticationContext = React.createContext({
  parseJwt: {},
  api: {},
});

const AuthenticationProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [parseJwt, setParseJwt] = useState({});

  useEffect(() => {
    startVerifications();
  }, []);

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,

    headers: {
      Authorization: `Bearer ${
        localStorage.getItem('userToken') ||
        localStorage.getItem('adminUserToken')
      }`,
      IsMirror: localStorage.getItem('adminUserToken') ? 'true' : 'false',
    },
  });

  const startVerifications = () => {
    const token =
      localStorage.getItem('userToken') ||
      localStorage.getItem('adminUserToken');
    if (!token) {
      return finishLoading();
    }

    if (localStorage.getItem('userToken')) {
      api
        .get(`/verify-token`)
        .then((response) => {
          setParseJwt(response.data);
          finishLoading();
        })
        .catch((e) => {
          localStorage.removeItem('userToken');
          finishLoading();
          return HTTPErrorCallback(e);
        });
    } else if (localStorage.getItem('adminUserToken')) {
      api
        .get(`/admin-verify-token`)
        .then((response) => {
          setParseJwt(response.data);
          return finishLoading();
        })
        .catch((e) => {
          localStorage.removeItem('adminUserToken');
          finishLoading();
          return HTTPErrorCallback(e);
        });
    }
  };

  const finishLoading = () => {
    setLoading(false);
  };

  const contextValue = {
    parseJwt,
    api,
  };
  return (
    <>
      <AuthenticationContext.Provider value={contextValue}>
        {!loading && children}
      </AuthenticationContext.Provider>
    </>
  );
};

export default AuthenticationProvider;
