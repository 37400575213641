import * as S from '../styles';

const BannersDescription = () => {
  return (
    <S.ThermWrapper>
      <br />
      No portal do participante há um slide que contém banners informativos de
      promoções, mecânicas extras, novos ciclos e mais. Essa tela permite a
      moderação desses banners e todos os cadastrados são listados aqui.
      <br />
      <br />
      Caso deseje excluir um banner, clique no ícone de lixeira presente na
      coluna <strong>Ações</strong>.
      <br />
      <br />
      Se deseja editar, clique no ícone de engrenagem, também em{' '}
      <strong>Ações</strong>.
      <br />
      <br />
      Para pesquisar, preencha o campo <strong>Pesquisar</strong>. Esta pesquisa
      filtra apenas o campo <strong>Descrição</strong>.
      <br />
      <br />
      É possível emitir um relatório com todas as informações por meio do botão
      Download.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default BannersDescription;
