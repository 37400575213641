import moment from 'moment';

const columns = [
  {
    name: 'ID',
    selector: (row) => row.id,
    cell: (row) => <div title={row.id}>{row.id}</div>,
    sortable: true,
  },
  {
    name: 'ARQUIVO',
    selector: (row) => row.nome_arquivo,
    cell: (row) => <div title={row.nome_arquivo}>{row.nome_arquivo}</div>,
    sortable: true,
  },
  {
    name: 'URL',
    selector: (row) => row.caminho,
    cell: (row) => (
      <a
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        href={row.caminho}
      >
        {row.caminho || 'N/A'}
      </a>
    ),
    sortable: true,
  },
  {
    name: 'Data de Upload',
    selector: (row) => row.data_upload,
    cell: (row) => (
      <div title={row.data_upload}>
        {moment(row.data_upload).format('YYYY-MM-DD HH:mm')}
      </div>
    ),
    sortable: true,
  },
  {
    name: 'Processado',
    selector: (row) => (row.status ? 'Sim' : 'Não'),
    cell: (row) => <div title={row.status}>{row.status ? 'Sim' : 'Não'}</div>,
    sortable: true,
  },
  {
    name: 'Inicio processamento',
    selector: (row) => row.inicio_processamento,
    cell: (row) => (
      <div title={row.inicio_processamento}>{row.inicio_processamento}</div>
    ),
    sortable: true,
  },
  {
    name: 'FIM processamento',
    selector: (row) => row.data_processamento,
    cell: (row) => (
      <div title={row.data_processamento}>{row.data_processamento}</div>
    ),
    sortable: true,
  },
  {
    name: 'Cancelado',
    selector: (row) => (row.cancelado ? 'Sim' : 'Não'),
    cell: (row) => (
      <div title={row.cancelado}>{row.cancelado ? 'Sim' : 'Não'}</div>
    ),
    sortable: true,
  },
];

export default columns;
