import * as S from '../styles';
import getIcon from '~/Helpers/getIcon';

const ListDescriptions = () => {
  return (
    <S.ThermWrapper>
      <br />
      Essa tela permite consultar e editar as ocorrências. Pode-se utilizar os
      campos abaixo para filtrar os resultados, segue um descritivo de cada um
      deles.
      <br />
      <br />
      <strong>Descritivo dos filtros:</strong>
      <br />
      <br />
      <strong>Ticket ID:</strong> Código da ocorrência.
      <br />
      <strong>De:</strong> Campo de data, que quando informado, filtra apenas
      ocorrências abertas a partir desta data.
      <br />
      <strong>Até:</strong> Campo de data, que quando informado, trás apenas
      ocorrências abertas até esta data.
      <br />
      <strong>Data da Resolução:</strong> Data de resolução do ticket que foi
      inputado pelo SAC. Este campo deve ser sempre preenchido pelo SAC.
      <br />
      <strong>CPF:</strong> CPF do participante da ocorrência.
      <br />
      <strong>Status:</strong> Campo informativo da situação da ocorrência,
      podendo ser <strong>Pendente</strong>, <strong>Em Andamento</strong> e{' '}
      <strong>Finalizado</strong>. Por padrão, após sua abertura, uma ocorrência
      tem seu status definido como Pendente.
      <br />
      <strong>Status SAC:</strong> Campo informativo de quem está tratando a
      ocorrência, podendo ser <strong>Claro</strong>, <strong>Nex</strong>,{' '}
      <strong>Premmiar</strong>, <strong>TradeUp</strong>,{' '}
      <strong>Inova</strong>, <strong>Fora Prazo</strong>. Este campo não
      aparece para os participantes, sendo visível apenas pelo usuários
      administrativos.
      <br />
      <strong>Com resposta do usuário: </strong> Este campo informa se a
      ocorrência possui ou não uma resposta do participante. Há a opção{' '}
      <strong>Sim</strong> e <strong>Não</strong> disponíveis.
      <br />
      <br />
      Após preencher os campos, clique em <strong>Pesquisar</strong> para
      filtrar os resultados. Entre os listados, pode-se realizar uma nova
      filtragem pelo campo <strong>Pesquisar</strong>, digitando o texto
      desejado.
      <br />
      <br />
      <strong>Descritivo das colunas da tabela:</strong>
      <br />
      <br />
      <strong>1. Ações:</strong> Há quatro opções disponíveis.
      <ul>
        <li>
          {getIcon('check', 'gray')} - Indica que a ocorrência foi finalizada
        </li>
        <li>
          {getIcon('bv', 'black')} - Indica que há pelo menos um documento
          anexado
        </li>
        <li>
          {getIcon('user', 'blue')} - Indica que há uma nova mensagem do usuário
        </li>
        <li>
          {getIcon('statusSketch', 'green')} - Utilizado para fazer a tratativa
          da ocorrência
        </li>
      </ul>
      <br />
      <strong>2. Ticket ID:</strong> Código da ocorrência.
      <br />
      <strong>3. Canal:</strong> Informa o canal em que a ocorrência foi aberta.
      <br />
      <strong>4. Origem da ocorrência:</strong> Informa qual canal de
      comunicação foi utilizado pelo participante para informar a ocorrência. Há
      as opções <strong>WhatsApp</strong>, <strong>Portal</strong> e{' '}
      <strong>E-mail</strong>.
      <br />
      <strong>5. Nome:</strong> Nome do participante da ocorrência.
      <br />
      <strong>6. CPF/CNPJ:</strong> Número do CPF ou CNPJ do participante da
      ocorrência.
      <br />
      <strong>7. Cargo:</strong> Cargo do participante da ocorrência.
      <br />
      <strong>8. Status:</strong> Campo informativo da situação da ocorrência,
      podendo ser <strong>Pendente</strong>, <strong>Em Andamento</strong> e{' '}
      <strong>Finalizado</strong>. Por padrão, após sua abertura, uma ocorrência
      tem seu status definido como Pendente.
      <br />
      <strong>9. Status SAC:</strong>Campo informativo de quem está tratando a
      ocorrência, podendo ser <strong>Claro</strong>, <strong>Nex</strong>,{' '}
      <strong>Premmiar</strong>, <strong>TradeUp</strong>,{' '}
      <strong>Inova</strong>, <strong>Fora Prazo</strong>. Este campo não
      aparece para os participantes, sendo visível apenas pelo usuários
      administrativos.
      <br />
      <strong>10. Categoria:</strong> Informa o motivo da abertura da
      ocorrência, podendo ser <strong>Alteração cadastral</strong>,{' '}
      <strong>Pontos em validação</strong>,{' '}
      <strong>Contestação de vendas</strong>, <strong>Acesso site</strong>,{' '}
      <strong>Acesso Catálogo Prêmios</strong>,{' '}
      <strong>Resgate de prêmios</strong>, <strong>Reset de senha</strong>,{' '}
      <strong>Estorno de pontos</strong> e <strong>Outros</strong>.
      <br />
      <strong>11. Prazo Resolução:</strong> Data informada pelo usuário admin
      para finalização da ocorrência. Este é preeenchido obrigatoriamente na
      primeira edição da ocorrência.
      <br />
      <strong>12. Data Abertura Ticket:</strong> Data em que a ocorrência foi
      aberta.
      <br />
      <strong>13. Resumo da Ocorrência:</strong> Texto opcional preenchido pelo
      usuário admin ao finalizar a ocorrência.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default ListDescriptions;
