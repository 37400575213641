import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: grid;
  margin: 0 auto;
  place-items: center;
  grid-gap: 20px;

  h2 {
    font-size: ${({ theme }) => theme.adminfontsize.containertitle};
    color: ${({ theme }) => theme.admincolors.contrast.black};
  }
`;

export const InputWrapper = styled.div`
  display: grid;
  width: 500px;
  grid-gap: 20px;

  ${media.lessThan('1000px')`
  width: 100%;
  `}

  button {
    margin: 0 auto;
  }

  label {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 10px;
  }
`;

export const DownloadModel = styled.a`
  width: 100%;
  text-align: center;
  padding: 10px 0;
  text-decoration: underline;
  font-size: 18px;
  cursor: pointer;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 40px;

  button {
    width: 80%;
  }
`;
