import Button from '~/components/NexAdmin/Buttons/Button';
import InputUpload from '~/components/NexAdmin/Inputs/InputUpload';
import { HTTPErrorCallback } from '~/Helpers/Error';
import getIcon from '~/Helpers/getIcon';
import api from '~/services/api';
import { showNotification } from '~/utils/notificationBoxes';
import * as S from './styles';
import { Form } from '@unform/web';
import { ToggleSwitch } from '~/components/NexAdmin/Inputs';
import { useState } from 'react';

const Validate = ({
  setLoading,
  setErrors,
  file,
  setFile,
  changeStep,
  setData,
  channel,
}) => {
  const [optin, setOptin] = useState(false);
  const [inactive, setInactive] = useState(false);

  const validate = () => {
    if (!file)
      return showNotification(
        'warning',
        'Atenção',
        'Por favor, selecione um arquivo',
      );

    setLoading(true);

    const formData = new FormData();

    formData.append('sheet', file);
    formData.append('opt_in', optin);
    formData.append('inactive', inactive);

    api
      .post('/bonus-point/validate', formData)
      .then((response) => {
        const { data_to_insert, errors } = response.data;
        if (data_to_insert && data_to_insert.length) {
          setData(data_to_insert);
        }
        if (errors && errors.length) {
          setErrors(errors);
        }
        setFile(file);
        changeStep('createTransactions');
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <S.Wrapper>
      <h2>Selecione um arquivo para validação</h2>

      <S.InputWrapper>
        <Form>
          <InputUpload
            name="file"
            onGetFile={setFile}
            placeholder={
              <>{getIcon('upload')} Selecione um arquivo para upload</>
            }
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          <S.ToggleWrapper>
            <p>Incluir participantes sem opt-in?</p>
            <ToggleSwitch
              name="status"
              value={optin}
              onClick={() => setOptin(!optin)}
            />
          </S.ToggleWrapper>
          {channel && channel == 9 && (
            <S.ToggleWrapper>
              <p>Incluir participantes inativos?</p>
              <ToggleSwitch
                name="status"
                value={inactive}
                onClick={() => setInactive(!inactive)}
              />
            </S.ToggleWrapper>
          )}
        </Form>
        <Button type="button" width="50%" onClick={validate}>
          Validar
        </Button>
      </S.InputWrapper>
    </S.Wrapper>
  );
};

export default Validate;
