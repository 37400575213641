import { useState } from 'react';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';
import Loading from '~/components/Loading';
import { Form } from '@unform/web';
import Input from '~/components/NexAdmin/Inputs/Input';
import Button from '~/components/NexAdmin/Buttons/Button';
import * as S from './styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { showNotification } from '~/utils/notificationBoxes';

const NetworksCreate = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const createRede = (data) => {
    setLoading(true);
    api
      .post('/redes/create', data)
      .then((r) => {
        setLoading(false);
        showNotification('success', 'Rede criada com sucesso!');

        history.push('/admin/networks');
      })
      .catch((err) => {
        setLoading(false);
        return HTTPErrorCallback(err);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag icon="access" title="Criar Rede" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <S.FormWrapper>
              <Form onSubmit={createRede}>
                <Input name="rede" label="Nome da Rede" type="text" required />

                <Button type="submit" icon="plus">
                  Criar Rede
                </Button>
              </Form>
            </S.FormWrapper>
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default NetworksCreate;
