import React from 'react';

import * as S from './styles';

const HeaderRight = () => {
  return (
    <>
      <S.Wrapper>
        <S.Right />
      </S.Wrapper>
    </>
  );
};

export default HeaderRight;
