/* eslint-disable no-console */
import { useEffect, useState } from 'react';

import api from '~/services/api';
import { goTopPage } from '~/utils';

import { parseJwt } from '../../services/auth';
import CreateCampaign from './components/CreateCampaign';
import HeaderButtons from './components/HeaderButtons/index';
import ShowCampaigns from './components/ShowCampaigns';
import NewCreateCampaign from './components/NewComponents/CreateCampagn';
import NewShowCampagns from './components/NewComponents/ShowCampaigns/index';

import * as S from './styles';
import { showNotification } from '~/utils/notificationBoxes';

const TablePoints = () => {
  const [points, setPoints] = useState(0);
  const [view, setView] = useState('create');
  const [currentUser] = useState(parseJwt());
  useEffect(() => {
    goTopPage();
    api
      .get(`/gn/default/user/balance`)
      .then((response) => {
        setPoints(response.data);
      })
      .catch(() =>
        showNotification(
          'danger',
          'Atenção',
          'Houve um erro ao consultar pontuação do Gerente de Negócios',
        ),
      );
  }, []);

  const switchChannel = () => {
    switch (parseJwt().canal_id) {
      case 2:
      case 4:
        return (
          <>
            <S.Wrapper rules>
              <S.Rules>
                <h3>Algumas regras para distribuição:</h3>
                <div className="rules-grid">
                  <span className="rules-number">1.</span>
                  <p>
                    A quantidade de pontos liberada para o Gerente de Negócios
                    será calculada com base nos critérios citados no item 3.4 do
                    regulamento.
                  </p>
                </div>
                <div className="rules-grid">
                  <span className="rules-number">2.</span>
                  <p>
                    {' '}
                    O Gerente de Negócios deve escolher o PDV no menu abaixo e
                    em seguida definir o vendedor que deseja vincular a uma
                    campanha. Lembrando que para participar é necessário que
                    tanto o Gerente de Negócios quanto o Vendedor tenham dado
                    aceite no Gigaclube conforme descrito no item 3.5 do
                    regulamento.
                  </p>
                </div>
              </S.Rules>
            </S.Wrapper>
            <S.Wrapper filters>
              <HeaderButtons view={view} setView={setView} />
              <S.Inner>
                {view === 'create' ? (
                  <NewCreateCampaign currentUser={currentUser} />
                ) : (
                  <NewShowCampagns currentUser={currentUser} />
                )}
              </S.Inner>
            </S.Wrapper>
          </>
        );
      case 5:
        return (
          <>
            <S.Wrapper rules>
              <S.Rules>
                <h3>Algumas regras para distribuição:</h3>
                <div className="rules-grid">
                  <span className="rules-number">1.</span>
                  <p>
                    A quantidade de pontos liberada para o Gerente de Negócios
                    será calculada mediante estratégia do Canal de Distribuição.
                  </p>
                </div>
                <div className="rules-grid">
                  <span className="rules-number">2.</span>
                  <p>
                    O Gerente de Negócios deve inserir os dados do PDC que irá
                    participar da campanha. Lembrando que para participar é
                    necessário que tanto o Gerente de Negócios quanto o PDC
                    tenham dado aceite no Giga Clube.{' '}
                  </p>
                </div>
                <div className="rules-grid">
                  <span className="rules-number">3.</span>
                  <p>
                    <b>
                      <a
                        target="_blank"
                        href="/regulamentos/Regulamento_Giga_GN.pdf"
                      >
                        Clique aqui
                      </a>
                    </b>{' '}
                    para consultar o regulamento do GigaGN.
                  </p>
                </div>
              </S.Rules>
            </S.Wrapper>
            <S.Wrapper filters>
              <HeaderButtons view={view} setView={setView} />
              <S.Inner>
                {view === 'create' ? (
                  <NewCreateCampaign currentUser={currentUser} />
                ) : (
                  <NewShowCampagns />
                )}
              </S.Inner>
            </S.Wrapper>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <S.Template>
      <S.Wrapper points="true">
        <S.Points>
          <span>Você possui</span>
          <input type="text" disabled value={points} />
          <span>Para distribuir</span>
        </S.Points>
      </S.Wrapper>
      {switchChannel()}
    </S.Template>
  );
};

export default TablePoints;
