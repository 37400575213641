/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import Carousel from 'react-elastic-carousel';

import moment from 'moment';

import api from '~/services/api';
import { parseJwt } from '~/services/auth';

import CampaignItem from '../CampaignItem';
import * as S from './styles';

const CampaignsList = () => {
  const [desafios, setDesafios] = useState();

  useEffect(() => {
    api.get(`/buscar-desafio/${parseJwt().login}`).then((response) => {
      setDesafios(response.data.desafios[0]);
    });
  }, []);

  return (
    <>
      <S.WishScrollArea>
        <S.WishContainer>
          {desafios && (
            <Carousel
              itemPadding={[10, 20]}
              breakPoints={[
                { width: 1, itemsToShow: 1 },
                { width: 550, itemsToShow: 3 },
                { width: 1050, itemsToShow: 4 },
              ]}
            >
              {desafios.map((desafio) => (
                <>
                  {/* Se mes atual estiver no mes do desafio (mexe na barra) */}
                  {moment().format('MM') ===
                  moment(desafio.digital_dt_fim).format('MM') ? (
                    <CampaignItem
                      timeline={
                        (100 * moment().format('DD')) /
                          moment(desafio.digital_dt_fim).format('DD') >
                        100
                          ? 100
                          : (100 * moment().format('DD')) /
                            moment(desafio.digital_dt_fim).format('DD')
                      }
                      titulo={desafio.titulo}
                      dataFim={` ${moment(desafio.digital_dt_fim).format(
                        'DD/MM/YYYY',
                      )}`}
                      produtos={desafio.produtos_participantes}
                      saibaMais={desafio.desafio_file}
                      desafio={desafio.desafio}
                    />
                  ) : (
                    ' '
                  )}

                  {/* Se mes atual menor que o mes do desafio (barra em 0 sempre) */}
                  {moment().format('MM') <
                  moment(desafio.digital_dt_fim).format('MM') ? (
                    <CampaignItem
                      timeline={0}
                      titulo={desafio.titulo}
                      dataFim={` ${moment(desafio.digital_dt_fim).format(
                        'DD/MM/YYYY',
                      )}`}
                      produtos={desafio.produtos_participantes}
                      saibaMais={desafio.desafio_file}
                      desafio={desafio.desafio}
                    />
                  ) : (
                    ' '
                  )}

                  {/* Se mes atual maior que mesm do desafio (barra em 100 sempre) */}
                  {moment().format('MM') >
                  moment(desafio.digital_dt_fim).format('MM') ? (
                    <CampaignItem
                      timeline={100}
                      inactive
                      titulo={desafio.titulo}
                      dataFim={` ${moment(desafio.digital_dt_fim).format(
                        'DD/MM/YYYY',
                      )}`}
                      produtos={desafio.produtos_participantes}
                      saibaMais={desafio.desafio_file}
                      desafio={desafio.desafio}
                    />
                  ) : (
                    ' '
                  )}
                </>
              ))}
            </Carousel>
          )}
        </S.WishContainer>
      </S.WishScrollArea>
    </>
  );
};

export default CampaignsList;
