import { getPasswordSecurityVerifications } from '~/utils/NexAdmin/utils';
import { useState } from 'react';
import { useEffect } from 'react';
import * as S from './styles';

const SafePassword = ({ password }) => {
  const [verifications, setVerifications] = useState({});
  useEffect(() => {
    setVerifications(getPasswordSecurityVerifications(password));
  }, [password]);

  return (
    <S.SafePassword>
      <li>
        Para uma senha segura, certifique-se de ter{' '}
        <S.ColorFulSpan valid={verifications.hasEnoughLength}>
          pelo menos 8 caracteres
        </S.ColorFulSpan>
        ,{' '}
        <S.ColorFulSpan valid={verifications.hasNumber}>
          incluindo números
        </S.ColorFulSpan>
        ,{' '}
        <S.ColorFulSpan valid={verifications.hasUppercase}>
          letra maiúscula
        </S.ColorFulSpan>
        ,{' '}
        <S.ColorFulSpan valid={verifications.hasSpecialCharacters}>
          caracteres especiais como "@" ou "*"
        </S.ColorFulSpan>
        .
      </li>
    </S.SafePassword>
  );
};

export default SafePassword;
