import moment from 'moment';
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';

// Font.register({
//   family: 'Roboto',
//   src: 'https://fonts.googleapis.com/css2?family=Roboto&display=swap',
// })

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
  },
  section: {
    flexGrow: 1,
    position: 'relative',
  },
  pageBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  name: {
    position: 'absolute',
    top: '39%',
    left: '45%',
    color: '#C50101',
  },
  first: {
    position: 'absolute',
    top: '30%',
    left: '50%',
  },
  second: {
    position: 'absolute',
    top: '50%',
    left: '42%',
  },
  third: {
    position: 'absolute',
    top: '55%',
    left: '47%',
  },
  fourth: {
    position: 'absolute',
    top: '60%',
    left: '43%',
  },
});
const Certificate = ({ user_name = '', date = '', title = '' }) => {
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.section}>
          <Image
            source={{
              uri: '/images/certificado.jpeg',
              method: 'GET',
              headers: {},
              body: '',
            }}
            style={styles.pageBackground}
            cache={false}
          />
          <Text style={styles.first}>O Giga Clube certifica que</Text>
          <Text style={styles.name}>{user_name.toUpperCase()}</Text>
          <Text style={styles.second}>concluiu o treinamento {title}</Text>
          <Text style={styles.third}>
            {' '}
            no dia {moment(date).format('DD/MM/YYYY')} e está apto(a)
          </Text>
          <Text style={styles.fourth}>
            a gigar cada vez mais alto e mais longe.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default Certificate;
