import { useEffect, useState } from 'react';

import { Form } from '@unform/web';

import Input from '~/components/Input';
import Loading from '~/components/Loading';
import api from '~/services/api';
import { showNotification } from '~/utils/notificationBoxes';

import * as S from './styles';
import { HTTPErrorCallback } from '~/Helpers/Error';
import UseGlobalModalStructure from '~/hooks/UseGlobalModalStructure';
import PasswordOption from './Components/PasswordOption';

const ForgotPassword = ({ cpf, setCurrentFormStep }) => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);
  const { addGlobalModalStructure, removeGlobalModalStructure } =
    UseGlobalModalStructure();

  useEffect(() => {
    setLoading(true);
    api
      .post(`/forgot-password`, {
        usu_cpf: cpf,
      })
      .then((response) => {
        const { questions, type, cellphone, email } = response.data;
        setLoading(false);
        setQuestions(questions);
        if (type && type === 'common') {
          return ResetPasswordModal(cellphone, email, questions);
        } else {
          setType('perguntas_respostas');
        }
      })
      .catch((error) => {
        setLoading(false);
        setCurrentFormStep('login');
        return HTTPErrorCallback(error);
      });
  }, []);

  const forgotPassword = (data) => {
    if (data.password !== data.confirm_password)
      return showNotification('danger', 'Atenção', 'As senhas não conferem.');

    const question_id = Object.keys(data);

    const answers = [];
    const firstAnswer = {};
    const secondAnswer = {};
    const thirdAnswer = {};

    firstAnswer[question_id[0]] = data[question_id[0]];
    secondAnswer[question_id[1]] = data[question_id[1]];
    thirdAnswer[question_id[2]] = data[question_id[2]];

    answers.push({
      id: Object.keys(firstAnswer)[0],
      resposta: Object.values(firstAnswer)[0],
    });
    answers.push({
      id: Object.keys(secondAnswer)[0],
      resposta: Object.values(secondAnswer)[0],
    });
    answers.push({
      id: Object.keys(thirdAnswer)[0],
      resposta: Object.values(thirdAnswer)[0],
    });

    setLoading(true);
    api
      .post('/perguntas', {
        ...data,
        usu_cpf: cpf,
        answers,
      })
      .then(() => {
        setLoading(false);
        setCurrentFormStep('login');
        return showNotification(
          'success',
          'Sucesso',
          'Senha alterada com sucesso!',
        );
      })
      .catch((error) => {
        setLoading(false);
        setCurrentFormStep('login');
        return HTTPErrorCallback(error);
      });
  };

  const ResetPasswordModal = (cellphone, email, questions) => {
    addGlobalModalStructure(
      <PasswordOption
        cellphone={cellphone}
        email={email}
        api={api}
        setLoading={setLoading}
        close={removeGlobalModalStructure}
        cpf={cpf}
        setCurrentFormStep={setCurrentFormStep}
        setType={setType}
        questions={questions}
      />,
      false,
      () => {},
      false,
    );
  };

  return (
    <>
      {loading && <Loading />}
      <Form className="forgot" onSubmit={forgotPassword}>
        {type &&
          questions.map((question) => (
            <Input
              type="text"
              name={`${question.id}`}
              className="forgot-form-input"
              label={question.pergunta}
              placeholder="DIGITE SUA RESPOSTA"
              required
            />
          ))}
        {type && (
          <>
            <Input
              type="password"
              name="password"
              label="Nova senha"
              placeholder="DIGITE SUA NOVA SENHA"
              required
            />
            <Input
              type="password"
              name="confirm_password"
              className="forgot-form-input"
              label="Confirmar nova senha"
              required
              placeholder="CONFIRME SUA NOVA SENHA"
            />
          </>
        )}
        <S.Column>
          <S.Forgot onClick={() => setCurrentFormStep('login')}>
            Voltar
          </S.Forgot>
          <S.Button type="submit">Próximo</S.Button>
        </S.Column>
      </Form>
    </>
  );
};

export default ForgotPassword;
