import { NotificationContext } from '~/contexts/NotificationContext';
import { useContext } from 'react';

const UseNotification = () => {
  const {
    getNotifications,
    readNotification,
    notifications,
    totalNotifications,
    totalUnreadNotifications,
    nextPage,
    notificationAmount,
    getUnreadNotifications,
    loading,
  } = useContext(NotificationContext);

  return {
    getNotifications,
    readNotification,
    notifications,
    totalNotifications,
    totalUnreadNotifications,
    nextPage,
    notificationAmount,
    getUnreadNotifications,
    loading,
  };
};

export default UseNotification;
