// import { useState } from 'react';
import { parseJwt } from '~/services/auth';

import * as S from './styles';

const UsersTable = ({ users, selectedUser, setSelectedUser }) => {
  // const [loading, setLoading] = useState(false)
  const handleSelectedUser = (e) => {
    const idx = e.target.value;
    setSelectedUser(users[idx]);
  };
  return (
    <>
      <S.Container>
        <table cellSpacing="0" cellPadding="0">
          <thead>
            <td> </td>

            {parseJwt().canal_id === 5 ? <td>Nome Fantasia</td> : <td>Nome</td>}
            {parseJwt().canal_id === 5 ? <td>CPF/CNPJ</td> : <td>CPF</td>}
            <td>Cargo</td>
            <td>Regional</td>
            <td>IDPDV</td>
            <td>Distribuidor</td>
          </thead>
          <tbody>
            {users.map((user, idx) => (
              <tr>
                <td>
                  <input
                    type="radio"
                    name="user"
                    value={idx}
                    onChange={handleSelectedUser}
                    checked={user.usu_id === selectedUser?.usu_id}
                  />
                </td>
                <td>{user.usu_nome}</td>
                <td>{user.usu_cpf}</td>
                <td>{user.cargo_nome}</td>
                <td>{user.usu_regional}</td>
                <td>{user.usu_pdv}</td>
                <td>{user.usu_rede}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr />
      </S.Container>
    </>
  );
};

export default UsersTable;
