import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  grid-gap: 20px;
  padding-bottom: 30px;
  width: 700px;
  button {
    margin-top: auto;
    width: 40%;
  }

  img,
  video {
    width: 100%;
    max-width: 500px;
  }
`;

export const DownloadWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding-top: 20px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  gap: 20px;
`;

export const StarsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100px;
  margin-top: 20px;
`;

export const Star = styled.div`
  cursor: pointer;

  svg {
    cursor: pointer;
    width: 20px;
  }
`;
