import { useEffect, useRef, useState } from 'react';
import { Button } from '~/components/NexAdmin/Buttons/index';
import Counter from '~/components/NexAdmin/Counter';
import { Select, Input } from '~/components/NexAdmin/Inputs/index';
import Table from '~/components/NexAdmin/Table';
import { Form } from '@unform/web';
import * as S from '~/screens/NexAdmin/Sales/styles';
import api from '~/services/api';
import { appendObjectInFormData } from '~/utils';
import { parseJwt } from '~/services/auth';
import { HTTPErrorCallback } from '~/Helpers/Error';

const BaseTableGN = ({
  setLoading,
  current_state,
  title,
  columns,
  setRow,
  setStep,
  setDinamicyState,
  current_user,
}) => {
  const [count, setCount] = useState(0);
  const [gnTransactions, setGnTransactions] = useState([]);
  const formRef = useRef();

  useEffect(() => {
    handleSubmit();
  }, [title]);

  const handleSubmit = (data) => {
    setLoading(true);
    const formData = new FormData();
    appendObjectInFormData(formData, {
      status: current_state,
      ...data,
    });
    const params = new URLSearchParams(formData);
    api
      .get(`/gn/default/admin/?${params}`)
      .then((response) => {
        setGnTransactions(response.data.data);
        setCount(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  const switchStep = (row) => {
    setRow(row);
    setStep('user-gn');
    setDinamicyState(current_state);
  };

  return (
    <>
      <>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.FormWrapper>
            <S.FormFilters>
              <Input name="cpf" label="CPF:" type="number" />
              <Input name="regional" label="Regional:" type="text" />
              <Input name="pdv" label="PDV:" type="text" />
              <Input name="initial_date" label="De:" type="date" />
              <Input name="final_date" label="Até:" type="date" />
            </S.FormFilters>
            <Button type="submit">Consultar</Button>
          </S.FormWrapper>
        </Form>
        <Counter total={count} />
        <Table
          data={gnTransactions}
          columns={columns}
          pagination={true}
          dataTableProps={{ onRowClicked: (row) => switchStep(row) }}
          header
          dropdownItems={false}
          hasDownload={false}
        />
      </>
    </>
  );
};

export default BaseTableGN;
