import { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';

import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import DownloadXLSX from './Components/DownloadXLSX';
import Loading from '~/components/Loading';
import api from '~/services/api';
import { showNotification } from '~/utils/notificationBoxes';

import columns from './columns';
import * as S from './styles';
import { Button } from '~/components/NexAdmin/Buttons';
import Table from '~/components/NexAdmin/Table';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import ChangeBulkEmailDescription from '~/components/NexAdmin/Modal/Descriptions/changeBulkEmail';

const ChangeBulkEmail = () => {
  const fileRef = useRef(null);
  const [modelo, setModelo] = useState([]);
  const [searchedScores, setSearchedScores] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState([]);
  const { openModal } = useModal();
  useEffect(() => {
    const dados = [
      { cpf: '11111111111', email: 'teste1@teste.com' },
      { cpf: '00000000000', email: 'teste2@teste.com' },
      { cpf: '22222222222', email: 'teste3@teste.com' },
    ];
    setModelo(dados);
  }, []);

  const createPontos = () => {
    if (fileRef.current?.files.length < 1) {
      return showNotification('warning', 'Atenção', 'Escolha um arquivo.');
    }

    setLoading(true);
    const formData = new FormData();

    if (fileRef.current.files[0]) {
      formData.append(
        'extension',
        fileRef.current.files[0].name.split('.').pop(),
      );
      formData.append('XLSX', fileRef.current.files[0]);
    }

    api
      .post('/trocar-email-massa', formData)
      .then((r) => {
        setLoading(false);
        setContent(r.data.content);
        showNotification(
          'success',
          'Sucesso!',
          'Verifique na tabela abaixo os dados processados!',
        );
      })
      .catch((error) => {
        setLoading(false);
        showNotification(
          'danger',
          'Atenção',
          error?.response?.data?.message ||
            'Não foi possível processar lista, verifique o arquivo e tente novamente.',
        );
      });

    return true;
  };

  const previewPontos = () => {
    if (fileRef.current?.files.length < 1) {
      return showNotification('warning', 'Atenção', 'Escolha um arquivo.');
    }

    setLoading(true);
    const formData = new FormData();

    if (fileRef.current.files[0]) {
      formData.append(
        'extension',
        fileRef.current.files[0].name.split('.').pop(),
      );
      formData.append('XLSX', fileRef.current.files[0]);
    }

    api
      .post('/visualizar-troca-email', formData)
      .then((response) => {
        setLoading(false);
        setSearchedScores(response.data?.lista);
      })
      .catch((e) => {
        setLoading(false);
        showNotification(
          'danger',
          'Atenção',
          'Não foi possível criar a visualização da lista, verifique o arquivo e tente novamente.',
        );
      });

    return true;
  };

  const openThermsModal = () => {
    openModal({
      title: 'Trocar E-mail em Massa',
      close: true,
      content: <ChangeBulkEmailDescription />,
    });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="send"
          title="Trocar E-mail em Massa"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <S.CreateBannerSection>
              <S.Text>
                <S.Text>
                  <p>Baixe o modelo do arquivo excel aqui: </p>
                  <br />
                </S.Text>

                <DownloadXLSX
                  fileName="modelo_trocar_email_massa"
                  list={modelo}
                  columns={columns}
                />

                <S.Text>
                  <p>
                    Para visualizar a lista, clique em <b>Pré Visualizar</b>{' '}
                    antes de <b>Processar lista</b>.
                  </p>
                </S.Text>
                <p>Verifique se não há espaços no e-mail e remova-os.</p>
                <p>
                  <b>Sempre </b>siga o padrão do modelo.
                </p>
              </S.Text>

              <Form onSubmit={previewPontos}>
                <input
                  width="300px"
                  type="file"
                  accept="XLSX"
                  ref={fileRef}
                  onChange={() => setDisableButton(false)}
                />
                <Button type="submit" width="300px" disabled={disableButton}>
                  Pré visualizar lista
                </Button>
              </Form>

              <Form onSubmit={createPontos}>
                <Button type="submit" width="300px" disabled={disableButton}>
                  Processar lista
                </Button>
              </Form>

              {searchedScores.length > 0 && (
                <>
                  <S.Table>
                    <p>
                      <b>Pré visualização da lista</b>
                    </p>
                    <Table striped columns={columns} data={searchedScores} />
                  </S.Table>
                </>
              )}
              {content.length > 0 && (
                <S.Table>
                  <p>
                    <b>Resultado do processamento</b>
                  </p>
                  <Table
                    header
                    columns={[
                      { name: 'cpf', selector: (row) => row.cpf ?? 'N/A' },
                      { name: 'email', selector: (row) => row.email ?? 'N/A' },
                      { name: 'erro', selector: (row) => row.erro ?? 'N/A' },
                    ]}
                    downloadColumns={[
                      { name: 'cpf', selector: (row) => row.cpf ?? 'N/A' },
                      { name: 'email', selector: (row) => row.email ?? 'N/A' },
                      { name: 'erro', selector: (row) => row.erro ?? 'N/A' },
                    ]}
                    data={content}
                    fileName="Atualização de E-mail em massa"
                  />
                </S.Table>
              )}
            </S.CreateBannerSection>
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default ChangeBulkEmail;
