import { showNotification } from '~/utils/notificationBoxes';

const logout = () => {
  window.location.reload();
};

const getErrorMessage = (error) => {
  switch (error.status) {
    case 404:
      return 'Algo deu errado no servidor. Tente novamente mais tarde.';
    case 504:
      return 'Oops! O tempo para carregar a página expirou. Por favor, verifique sua conexão e tente novamente.';
    case 500:
      return 'Algo deu errado no servidor. Tente novamente mais tarde.';
    case 502:
      return 'Algo deu errado no servidor. Tente novamente mais tarde.';
    default:
      if (error.status === 0 && error.statusText === 'error' && !error.type) {
        return 'Oops! Houve um problema de conexão. Verifique sua conexão à internet.';
      }
      return 'Oops! Algo deu errado. Tente novamente mais tarde.';
  }
};

export const HTTPErrorCallback = (errorResponse, onRemove) => {
  const error = errorResponse.response;
  const onRemoveNotification = () => {
    if (onRemove) onRemove();
    if (error.data.disconnect_user) logout();
  };
  if (error?.data && error.status !== 502) {
    showNotification(
      'danger',
      'Ops!',
      typeof error.data === 'string'
        ? error.data
        : error.data.error || error.data.message || getErrorMessage(error),
      () => onRemoveNotification(),
    );
  } else {
    showNotification('danger', 'Ops!', getErrorMessage(error), () =>
      onRemoveNotification(),
    );
  }
};
