import Dashboard from '~/screens/NexAdmin/Dashboard';
import ListLandingPages from '~/screens/NexAdmin/LandingPages/Screens/List';
import CreateLandingPage from '~/screens/NexAdmin/LandingPages/Screens/Create';
import CreateBonusPoints from '~/screens/NexAdmin/BonusPoints/Screens/Create';
import ValidateCpf from '~/screens/NexAdmin/ValidateCpf';
import UserRegistration from '~/screens/NexAdmin/UserRegistration';
import PAPCreateUsers from '~/screens/NexAdmin/PAPCreateUsers';
import BonusPointReport from '~/screens/NexAdmin/BonusPointReport';
import Sales from '~/screens/NexAdmin/Sales';
import OrdersReport from '~/screens/NexAdmin/OrdersReport';
import BlockedOrdersReport from '~/screens/NexAdmin/OrdersReport/BlockedOrdersReport';
import ExtractReport from '~/screens/NexAdmin/ExtractReport/ExtractReport';
import Banners from '~/screens/NexAdmin/Banners/index';
import CreateBanner from '~/screens/NexAdmin/Banners/Create';
import ListPosts from '~/screens/NexAdmin/Posts/List';
import CreatePost from '~/screens/NexAdmin/Posts/Create';
import Revenue from '~/screens/NexAdmin/Revenue';
import Indications from '~/screens/NexAdmin/Indications';
import Push from '~/screens/NexAdmin/Notifications/Create';
import ListPush from '~/screens/NexAdmin/Notifications/List';
import {
  GNCampagns,
  GNGeral,
  GNInsertPoints,
  GNBatches,
} from '~/screens/NexAdmin/GN/index';
import RockDash from '~/screens/NexAdmin/RockDash';
import ChangeBulkEmail from '~/screens/NexAdmin/ChangeBulkEmail';
import ListChallenges from '~/screens/NexAdmin/Challenges/List';
import CreateChallenges from '~/screens/NexAdmin/Challenges/Create';
import Tickets from '~/screens/NexAdmin/Tickets/List';
import CreateTickets from '~/screens/NexAdmin/Tickets/Create';
import TicketReport from '~/screens/NexAdmin/Tickets/Report';
import {
  LADBatches,
  LADCampagns,
  LADGeral,
  LADInsertPoints,
} from '~/screens/NexAdmin/LAD/index';
import CreatePopUp from '~/screens/NexAdmin/PopUp/Create';
import ListPopUp from '~/screens/NexAdmin/PopUp/List';
import CampaignsExhibition from '~/screens/NexAdmin/CampaignsExhibition/Create/index';
import CampaignsExhibitionList from '~/screens/NexAdmin/CampaignsExhibition/List/index';
import MediaCampaigns from '~/screens/NexAdmin/MediaCampaign/Create/index';
import MediaCampaignsList from '~/screens/NexAdmin/MediaCampaign/List/index';
import Audit from '~/screens/NexAdmin/MediaCampaign/Audit';
import CreateQuizzes from '~/screens/NexAdmin/Quizzes';
import ListQuizzes from '~/screens/NexAdmin/Quizzes/Report';
import SalesValidator from '~/screens/NexAdmin/SalesValidator';
import SalesValidatorList from '~/screens/NexAdmin/SalesValidator/List';
import CreateUser from '~/screens/NexAdmin/UserRegistration/Create';
import MigrationReport from '~/screens/NexAdmin/MigrationReport';
import Networks from '~/screens/NexAdmin/Networks';
import NetworksCreate from '~/screens/NexAdmin/Networks/Create';
import UserHistoryList from '~/screens/NexAdmin/UserRegistration/List';
import SalesHistoryList from '~/screens/NexAdmin/Sales/List';
import CreateSales from '~/screens/NexAdmin/Sales/Create';
import NetworkMassUpload from '~/screens/NexAdmin/Networks/MassUpload';
import BlockUsers from '~/screens/NexAdmin/BlockUsers';
import BlockUsersList from '~/screens/NexAdmin/BlockUsers/List';

const routes = {
  Home: {
    path: '/admin/dashboard',
    exact: true,
    component: Dashboard,
  },
  LandingPages: {
    path: '/admin/langin_pages',
    exact: true,
    component: ListLandingPages,
  },
  PAPCreateUsers: {
    path: '/admin/pap_insert_users',
    exact: true,
    component: PAPCreateUsers,
  },
  CreateLandingPages: {
    path: '/admin/langin_pages/create',
    exact: true,
    component: CreateLandingPage,
  },
  CreateBonusPoints: {
    path: '/admin/bonus_points/create',
    exact: true,
    component: CreateBonusPoints,
  },
  ValidateCpf: {
    path: '/admin/validate_cpf',
    exact: true,
    component: ValidateCpf,
  },
  UserRegistration: {
    path: '/admin/user_registration',
    exact: true,
    component: UserRegistration,
  },
  UserRegistrationCreate: {
    path: '/admin/user_registration/create',
    exact: true,
    component: CreateUser,
  },
  UserRegistrationList: {
    path: '/admin/user_registration/list',
    exact: true,
    component: UserHistoryList,
  },
  BonusPointReport: {
    path: '/admin/bonus_point_report',
    exact: true,
    component: BonusPointReport,
  },
  Sales: {
    path: '/admin/sales',
    exact: true,
    component: Sales,
  },
  CreateSale: {
    path: '/admin/sales/create',
    exact: true,
    component: CreateSales,
  },
  SalesHistory: {
    path: '/admin/sales/list',
    exact: true,
    component: SalesHistoryList,
  },
  CreatePosts: {
    path: '/admin/posts/create',
    exact: true,
    component: CreatePost,
  },
  ListPosts: {
    path: '/admin/posts',
    exact: true,
    component: ListPosts,
  },
  OrdersReport: {
    path: '/admin/orders_report',
    exact: true,
    component: OrdersReport,
  },
  BlockedOrdersReport: {
    path: '/admin/orders_report/blocked_orders',
    exact: true,
    component: BlockedOrdersReport,
  },
  ExtractReport: {
    path: '/admin/extract_report',
    exact: true,
    component: ExtractReport,
  },
  Banners: {
    path: '/admin/banners',
    exact: true,
    component: Banners,
  },
  CreateBanners: {
    path: '/admin/banners/create',
    exact: true,
    component: CreateBanner,
  },
  Revenue: {
    path: '/admin/revenue',
    exact: true,
    component: Revenue,
  },
  Indications: {
    path: '/admin/indications',
    exact: true,
    component: Indications,
  },
  Push: {
    path: '/admin/notifications/create',
    exact: true,
    component: Push,
  },
  ListPush: {
    path: '/admin/notifications/list',
    exact: true,
    component: ListPush,
  },
  GNGeral: {
    path: '/admin/gn/geral',
    exact: true,
    component: GNGeral,
  },
  GNCampagns: {
    path: '/admin/gn/campagns',
    exact: true,
    component: GNCampagns,
  },
  GNPoints: {
    path: '/admin/gn',
    exact: true,
    component: GNInsertPoints,
  },
  GNBatches: {
    path: '/admin/gn/batches',
    exact: true,
    component: GNBatches,
  },
  RockDash: {
    path: '/admin/rockdash',
    exact: true,
    component: RockDash,
  },
  ListChallenges: {
    path: '/admin/challenges',
    exact: true,
    component: ListChallenges,
  },
  CreateChallenge: {
    path: '/admin/challenges/create',
    exact: true,
    component: CreateChallenges,
  },
  ChangeBulkEmail: {
    path: '/admin/change_bulk_email',
    exact: true,
    component: ChangeBulkEmail,
  },
  Indications: {
    path: '/admin/indications',
    exact: true,
    component: Indications,
  },
  ListTickets: {
    path: '/admin/tickets/list',
    exact: true,
    component: Tickets,
  },
  CreateTickets: {
    path: '/admin/tickets/create',
    exact: true,
    component: CreateTickets,
  },
  ReportTickets: {
    path: '/admin/tickets/report',
    exact: true,
    component: TicketReport,
  },
  LADGeral: {
    path: '/admin/lad/geral',
    exact: true,
    component: LADGeral,
  },
  LADCampagns: {
    path: '/admin/lad/campagns',
    exact: true,
    component: LADCampagns,
  },
  LADPoints: {
    path: '/admin/lad',
    exact: true,
    component: LADInsertPoints,
  },
  LADBatches: {
    path: '/admin/lad/batches',
    exact: true,
    component: LADBatches,
  },
  CreatePopUp: {
    path: '/admin/popup/create',
    exact: true,
    component: CreatePopUp,
  },
  ListPopUp: {
    path: '/admin/popup/list',
    exact: true,
    component: ListPopUp,
  },
  CampaignsExhibition: {
    path: '/admin/campaigns-exhibition/create',
    exact: true,
    component: CampaignsExhibition,
  },
  CampaignsExhibitionList: {
    path: '/admin/campaigns-exhibition',
    exact: true,
    component: CampaignsExhibitionList,
  },
  MediaCampaigns: {
    path: '/admin/media-campaigns/create',
    exact: true,
    component: MediaCampaigns,
  },
  MediaCampaignsList: {
    path: '/admin/media-campaigns',
    exact: true,
    component: MediaCampaignsList,
  },
  AuditMedia: {
    path: '/admin/media-campaigns/audit',
    exact: true,
    component: Audit,
  },
  ListQuiz: {
    path: '/admin/quizzes',
    exact: true,
    component: ListQuizzes,
  },
  CreateQuiz: {
    path: '/admin/quizzes/create',
    exact: true,
    component: CreateQuizzes,
  },
  SalesValidator: {
    path: '/admin/validate-sales',
    exact: true,
    component: SalesValidator,
  },
  SalesValidatorList: {
    path: '/admin/validate-sales/list',
    exact: true,
    component: SalesValidatorList,
  },
  MigrationReport: {
    path: '/admin/migration-report',
    exact: true,
    component: MigrationReport,
  },
  Networks: {
    path: '/admin/networks',
    exact: true,
    component: Networks,
  },
  NetworksCreate: {
    path: '/admin/networks/create',
    exact: true,
    component: NetworksCreate,
  },
  NetworksMassUpload: {
    path: '/admin/networks/mass-upload',
    exact: true,
    component: NetworkMassUpload,
  },
  BlockUsers: {
    path: '/admin/block-users',
    exact: true,
    component: BlockUsers,
  },
  BlockUsersList: {
    path: '/admin/block-users-list',
    exact: true,
    component: BlockUsersList,
  },
};

export default routes;
