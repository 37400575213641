import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Description = styled.p`
  max-width: 500px;
  word-wrap: normal;
  word-break: keep-all;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;

  ${media.lessThan('392px')`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  `}
`;

export const Button = styled.a`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  min-width: 120px;
  justify-content: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  height: 36px;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const DeleteBalance = styled.button`
  background: none;
  border-radius: 25px;
  border: 0;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  min-width: 120px;
  justify-content: center;
  color: #760000;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  align-self: flex-end;
  height: 36px;

  ${media.lessThan('392px')`
    align-self: center;
  `}

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;
