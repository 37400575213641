import { Form } from '@unform/web';
import * as S from './styles';
import { useEffect, useState } from 'react';
import SafePassword from '~/screens/SignIn/components/SafePassword';
import { getPasswordSecurityVerifications } from '~/utils/NexAdmin/utils';
import { showNotification } from '~/utils/notificationBoxes';
import Input from '../Input';
import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import UseAuthentication from '~/hooks/NexAdmin/AuthenticationHook';
import { parseJwt } from '~/services/auth';

const PasswordChangeModal = ({ close = () => {} }) => {
  const { parseJwt: parsedJWT } = UseAuthentication();
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const isQuestion = [2, 4].includes(parseJwt().canal_id);

  useEffect(() => {
    if (isQuestion) getQuestions();
  }, []);

  const getQuestions = () => {
    api
      .post(`/forgot-password`, {
        usu_cpf: parsedJWT.login,
      })
      .then((response) => {
        const { questions } = response.data;
        setQuestions(questions);
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  const submit = (data) => {
    const verifications = getPasswordSecurityVerifications(password);

    if (data.new_password !== data.new_password_confirm) {
      return showNotification('danger', 'Atenção', 'As senhas não coincidem!');
    }

    if (
      !verifications.hasEnoughLength ||
      !verifications.hasNumber ||
      !verifications.hasUppercase ||
      !verifications.hasSpecialCharacters
    ) {
      return showNotification(
        'danger',
        'Atenção',
        'Insira uma senha mais segura!',
      );
    }

    setLoading(true);
    api
      .post('/trocar-senha', data)
      .then((response) => {
        setLoading(false);
        showNotification('success', 'Sucesso!', 'Senha alterada com sucesso!');
        close();
      })
      .catch((err) => {
        setLoading(false);
        HTTPErrorCallback(err);
      });
  };

  return (
    <S.ModalWrapper>
      <S.Wrapper>
        <S.Header>
          <S.Title>Redefinir Senha</S.Title>
          <div></div>
        </S.Header>
        <S.Description>
          Para sua segurança pedimos que atualize sua senha para seguir com a
          navegação.
        </S.Description>

        <Form onSubmit={submit}>
          {isQuestion ? (
            questions.length ? (
              questions.map((question) => (
                <Input
                  type="text"
                  name={`${question.id}`}
                  className="forgot-form-input"
                  label={question.pergunta}
                  placeholder="DIGITE SUA RESPOSTA"
                />
              ))
            ) : (
              <p>Carregando...</p>
            )
          ) : (
            <Input
              label="Senha Atual"
              name="old_password"
              placeholder="********"
              type="password"
              required
              size="sm"
            />
          )}
          <Input
            label="Nova Senha"
            name="new_password"
            placeholder="********"
            type="password"
            required
            size="sm"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Input
            label="Confirmar Nova Senha"
            name="new_password_confirm"
            placeholder="********"
            type="password"
            required
            size="sm"
          />
          <SafePassword password={password} />

          <S.ButtonsWrapper>
            <div />
            <S.Button type="submit">Salvar</S.Button>
          </S.ButtonsWrapper>
        </Form>
      </S.Wrapper>
    </S.ModalWrapper>
  );
};

export default PasswordChangeModal;
