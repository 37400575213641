import styled from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: start;
  gap: 20px;
  padding-bottom: 40px;
`;
