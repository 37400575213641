import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const FormFilters = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  max-width: 100%;
`;

export const FormUpload = styled.div`
  display: grid;
  grid-gap: 30px;
  margin-top: 4rem;
  margin-bottom: 2rem;
  justify-content: center;
  align-items: center;
  max-width: 100%;
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 30px;
  button {
    margin-top: auto;
  }
`;

export const ButtonsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  grid-gap: 20px;
  max-width: 80%;
`;

export const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 20px;
  padding: 50px 10px;
`;

export const DownloadWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding-top: 20px;
`;

export const BannerWrapper = styled.div`
  height: 30rem;
  width: 90rem;
  border: 1px solid black;
  border-radius: 8px;
  background: #00020299;
  background-image: ${({ image }) => `url(${image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid;
  padding: 16px 20px;

  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 0.5fr 0.5fr;
  grid-template-areas:
    'title space '
    'size space '
    'button space ';

  grid-row-gap: 5px;

  input {
    display: none;
  }
`;

export const BannerTitle = styled.span`
  display: block;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;

  grid-area: title;
`;

export const UploadButton = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7.5px 12px;

  border-radius: 4px;
  background-color: #ffd175;

  grid-area: button;
`;

export const UploadButtonText = styled.span`
  display: block;
  //styleName: Text-bold-desktop;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #323133;

  margin-left: 7px;
`;
