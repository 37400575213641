import { Form } from '@unform/web';
import { Input } from '~/components/NexAdmin/Inputs';
import { Button } from '~/components/NexAdmin/Buttons/index';
import * as S from './styles';
import api from '~/services/api';
import { showNotification } from '~/utils/notificationBoxes';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { ToggleSwitch } from '~/components/NexAdmin/Inputs';
import { useState } from 'react';

const PopUpModal = ({ setLoading, hideModal, row, refresh }) => {
  const [active, setActive] = useState(row.active === 1 ? true : false);
  const updatePopUp = (data) => {
    setLoading(true);
    api
      .put(`/popup/admin/${row.id}`, {
        active,
        ...data,
      })
      .then((response) => {
        setLoading(false);
        hideModal();
        showNotification('success', 'Sucesso!', `${response.data.message}`);
        refresh();
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  return (
    <Form onSubmit={updatePopUp}>
      <S.Wrapper>
        <Input name="title" label="Título" defaultValue={row.title} />
        <Input
          textArea
          name="content"
          label="Conteúdo"
          maxLength="500"
          defaultValue={row.content}
        />
        <ToggleSwitch
          name="all_channel"
          label="Ativo?"
          defaultChecked={active}
          value={active}
          onClick={() => setActive(!active)}
        />
        <S.ButtonsGrid>
          <Button type="submit">Atualizar</Button>
        </S.ButtonsGrid>
      </S.Wrapper>
    </Form>
  );
};

export default PopUpModal;
