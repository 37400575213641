import { useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

import Loading from '~/components/Loading';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import { showNotification } from '~/utils/notificationBoxes';

import PreviewPostItem from './Components/PreviewPostItem';
import * as S from './styles';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';
import { Button } from '~/components/NexAdmin/Buttons';
import { useHistory } from 'react-router-dom';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import CreatePostDescription from '~/components/NexAdmin/Modal/Descriptions/Posts/createPostDescription';

const CreatePost = () => {
  const history = useHistory();
  const [state, setState] = useState();
  const [loading, setLoading] = useState(false);
  const [fileType, setFileType] = useState('');
  const [previewFile, setPreviewFile] = useState('');
  const fileRef = useRef(null);
  const { openModal } = useModal();

  const createPost = async () => {
    setLoading(true);
    let filesize;
    let fileExtension;
    const formData = new FormData();

    if (fileRef.current.files[0]) {
      filesize = (fileRef.current.files[0].size / 1024 / 1024).toFixed(4);
      fileExtension = fileRef.current.files[0].name.split('.').pop();
      formData.append('file', fileRef.current.files[0]);
    }

    if (filesize > 15)
      return showNotification(
        'danger',
        'Atenção',
        'Arquivo muito grande. O limite é 15 MB.',
      );

    formData.append('comentario', state);
    formData.append('canal_id', parseJwt().canal_id);
    formData.append('extension', fileExtension);

    api
      .post('/post/', formData)
      .then((response) => {
        showNotification('success', 'Sucesso', 'Post criado com sucesso!');
        setLoading(false);
        history.push('/admin/posts');
      })
      .catch((error) => {
        setLoading(false);
        return showNotification(
          'danger',
          'Atenção!',
          error.response.data.data?.message,
        );
      });
    return true;
  };

  const previewPost = async () => {
    let fileExtension;
    if (fileRef.current?.files[0]) {
      fileExtension = fileRef.current.files[0].name.split('.').pop();
      const reader = new FileReader();
      reader.onload = (e) => setPreviewFile(e.target.result);
      setPreviewFile(reader.readAsDataURL(fileRef.current.files[0]));
    }

    setFileType(fileExtension);
    return true;
  };

  const openThermsModal = () => {
    openModal({
      title: 'Criar Post',
      close: true,
      content: <CreatePostDescription />,
    });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="add"
          title="Criar Post"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle title="Criar Post">
            <S.MainSection>
              <div>
                <Editor
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editor"
                  onContentStateChange={(e) => setState(draftToHtml(e))}
                />
              </div>

              <PreviewPostItem
                post={{
                  foto: previewFile,
                  data_publicacao: new Date(),
                  comentario: state,
                  foto_tipo: fileType,
                }}
              />

              <S.BottomRow>
                <input
                  type="file"
                  accept="image/*, audio/*, video/*"
                  ref={fileRef}
                  onChange={() => previewPost()}
                />
                <Button
                  width="170px"
                  type="button"
                  onClick={() => createPost()}
                >
                  Enviar
                </Button>
              </S.BottomRow>
            </S.MainSection>
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default CreatePost;
