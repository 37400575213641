import * as S from './styles';

const DashDescription = () => {
  return (
    <S.ThermWrapper>
      <br />
      Nessa tela pode-se visualizar os gráficos criados no Power BI para analise
      de dados deste canal.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default DashDescription;
