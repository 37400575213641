import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  grid-area: headerLeft;
  display: grid;
  grid-template-columns: ${(props) =>
    props.opened || props.hoverOpened ? '1fr 40px' : '1fr'};
  grid-template-areas: ${(props) =>
    props.opened || props.hoverOpened
      ? "'logoArea hamburguer'"
      : "'hamburguer'"};
  place-items: center;
  height: 100%;
  width: 100%;
`;
export const Logo = styled.img`
  margin-left: 40px;
  height: 30px;
  grid-area: logoArea;
  display: ${(props) => (props.opened || props.hoverOpened ? 'flex' : 'none')};
`;
export const Hamburguer = styled.button`
  grid-area: hamburguer;
  place-self: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  svg {
    height: 12px;
  }
`;
