import { Form as Unform } from '@unform/web';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  display: grid;
  text-align: center;
  height: auto;
  margin: auto;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    'edit'
    'indicate'
    'change'
    'conquerers';

  display: flex;
  flex-direction: column;

  ${({ canShowIndication }) =>
    canShowIndication &&
    css`
      grid-template-areas:
        'edit'
        'change'
        'indicate';
    `}

  gap: 30px 0;

  ${media.greaterThan('medium')`
    text-align: left;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    gap: 10px 20px;
    ${({ canShowIndication }) =>
      canShowIndication
        ? css`
            grid-template-areas:
              'edit indicate'
              'edit change'
              'conquerers conquerers';
          `
        : css`
            grid-template-areas:
              'edit conquerers'
              'edit change';
          `}
  `}

  h1 {
    font-weight: ${({ theme }) => theme.font.bold};
  }
`;

export const Message = styled.h1``;
export const EditProfile = styled.div`
  position: relative;
  grid-area: edit;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  overflow: hidden;
  padding: 17px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;

    margin-bottom: 50px;
  }
`;

export const Disabled = styled.div`
  position: absolute;
  bottom: 0%;
  left: 0;
  width: 100%;
  height: 300px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 55%,
    rgba(255, 255, 255, 1) 100%
  );
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  pointer-events: all;
  padding: 10px 15px;
  padding-bottom: 50px;

  p {
    text-decoration: underline;
    font-size: 14px;
    text-align: center;
    cursor: text;
    max-width: 70%;
  }
`;
export const ChangePassword = styled.div`
  background-color: #fff;
  padding-top: 50px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  overflow: hidden;
  padding: 20px 15px;
  display: flex;

  flex-direction: column;
  justify-content: space-between;

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin-bottom: 50px;
  }
`;
export const Indicate = styled.div`
  grid-area: indicate;

  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  overflow: hidden;
  padding: 17px 15px;
  display: ${({ canShowIndication }) => (canShowIndication ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    margin-bottom: 50px;
  }
`;
export const Conquerer = styled.div`
  grid-area: conquerers;

  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  overflow: hidden;
  padding: 17px 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  margin-top: 0px;
`;

export const Form = styled(Unform)`
  display: flex;
  padding: 0px 50px;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  height: 100%;
  padding-top: 50px;
  button[type='submit'] {
    background: linear-gradient(178.18deg, #ff0000 -13.56%, #760000 158.3%);
    border-radius: 25px;
    border: 0;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 400;
    font-size: 1.4rem;
    transition: box-shadow 0.2s;
    text-decoration: none;
    cursor: pointer;
    margin-top: 20px;
  }
`;

export const CollForm = styled.div`
  flex: 1;
  margin-right: 0px;
  max-width: 100%;

  .input {
    margin-bottom: 20px;
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 20px;
  }

  .inline {
    display: flex;
    justify-content: space-between;

    div {
      flex: 1;
      input {
        width: 50%;
      }
      &:first-child {
        margin-right: 20px;
      }
    }
  }
`;

export const PageTitle = styled.h2`
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.colors.red};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  display: flex;

  gap: 10px;
`;
export const PageParagraph = styled.p`
  width: 100%;

  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 50px;
  color: #858585;
`;

export const ConquererWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  flex-wrap: wrap;
  margin: 5px;
  justify-content: space-between;
  ${media.greaterThan('medium')`
    grid-template-columns: 1fr 1fr;
    margin: ${({ canShowIndication }) => (canShowIndication ? '30px' : '0px')};
    width: ${({ canShowIndication }) => (canShowIndication ? 'auto' : '100%')};
  `}
`;

export const ConquererItem = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 5px;
  margin: 15px;
  ${media.greaterThan('medium')`
    flex-direction: ${({ canShowIndication }) =>
      canShowIndication ? 'row' : 'column'};
  `}

  img {
    width: 40px;
    ${media.greaterThan('medium')`
    width: ${({ canShowIndication }) => (canShowIndication ? '72px' : '55px')};
  `}
  }
`;

export const ConquererItemTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.red};
  font-size: ${({ canShowIndication }) =>
    canShowIndication ? '2rem' : '1.7rem'};
  font-style: normal;
  font-weight: 600;
  padding-left: 15px;
  ${media.greaterThan('medium')`
    padding-left: ${({ canShowIndication }) =>
      canShowIndication ? '15px' : '0'};
  `}
`;

export const ConquererItemPoints = styled.div`
  display: flex;
`;

export const CodeTitle = styled.div`
  font-size: 1.4rem;
`;
export const Code = styled.div`
  padding: 10px 20px;
  border: solid red 2px;
  border-radius: 25px;
  min-width: 200px;
  text-align: center;
  letter-spacing: 2px;
  font-size: 2rem;
  cursor: pointer;
  position: relative;
  padding: 10px 10px;
  &::before {
    content: 'Copiar o código';
    position: absolute;
    font-size: 1rem;

    padding: 0px 10px;
    padding-top: 0px;
    border-radius: 10px;
    white-space: nowrap;
    bottom: 5rem;
    left: 50%;
    transform: translateX(-50%);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    letter-spacing: 1px;
    height: 0px;
    overflow: hidden;
    transition: ease all 0.3s;
    background-color: red;
    color: white;
  }
  &:hover {
    &::before {
      height: 2rem;
      padding-top: 5px;
    }
  }
`;
export const CodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin: 20px 0px;
`;

export const Copy = styled.button`
  background-color: transparent;
  position: relative;
  overflow: visible;
  &::before {
    content: 'Copiar';
    padding: 3px 7px;
    position: absolute;
    top: 0px;
    z-index: 1;
    border-radius: 10px;
    font-size: 1rem;
    color: white;
    opacity: 0;
    background-color: #444;
    transition: ease-in-out 0.3s all;
    left: 50%;
    transform: translate(-50%, 0%);
    pointer-events: none;
  }
  &:hover {
    &::before {
      top: -20px;
      opacity: 1;
    }
  }
  .copy {
    background-color: transparent;
  }
  cursor: pointer;
`;
