import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import Footer from '../Footer';
import Header from '../Header';
import ProfileRank from '../ProfileRank';
import Sidebar from '../Sidebar';
import * as S from './styles';

const Layout = ({ children }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   if (!localStorage.getItem('userToken')) history.push('/');
  // }, []);

  return (
    <S.Container>
      <Header open={open} setOpen={setOpen} />
      <Sidebar open={open} setOpen={setOpen} />
      <S.Content>
        <ProfileRank />
        {children}
      </S.Content>
      <Footer />
    </S.Container>
  );
};

export default Layout;
