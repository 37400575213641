import { useState, useRef, useEffect } from 'react';
import getIcon from '~/Helpers/getIcon';
import * as S from './styles';

const ConfirmButton = ({
  icon,
  iconColor,
  confirmAction,
  label,
  confirmLabel,
  cancelLabel,
  openOnRight,
  green,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef();

  const handleClick = (e) => {
    if (ref.current.contains(e.target)) return;

    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);
  return (
    <>
      <S.Wrapper open={isOpen} ref={ref} onClick={() => setIsOpen(!isOpen)}>
        <S.Button {...rest}>{getIcon(icon, iconColor)}</S.Button>
        <S.Bg />
        {isOpen && (
          <S.ConfirmWrapper isOpen={isOpen} openOnRight={openOnRight}>
            <S.Label>{label}</S.Label>
            <S.ConfirmButton
              green={green}
              onClick={() => {
                confirmAction();
                setIsOpen(false);
              }}
            >
              {confirmLabel}
            </S.ConfirmButton>
            <S.CancelButton onClick={() => setIsOpen(false)}>
              {cancelLabel}
            </S.CancelButton>
          </S.ConfirmWrapper>
        )}
      </S.Wrapper>
    </>
  );
};

export default ConfirmButton;
