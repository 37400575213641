import { useEffect, useState } from 'react';
import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import InputController from './InputController';
import * as S from './styles';

const ProductsTable = ({
  getValue,
  setGetValue,
  setLoading,
  setTotalPoints,
  products,
  setProducts,
  currentUser,
  selectedUser,
  description,
}) => {
  const [AAprop] = useState([
    'both_points_not_null',
    'ft_point',
    'points',
    'st_point',
  ]);
  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    reducer();
  }, [getValue]);
  const getProducts = () => {
    setLoading(true);
    let url;
    switch (currentUser.canal_id) {
      case 2:
        url = '/gn/user/varejo/plans';
        break;
      case 4:
        url = '/gn/user/AA/plans';
        break;
      case 5:
        url = `/gn/user/recarga/plans?cargo_id=${selectedUser?.cargo_id}`;
        break;
      default:
        break;
    }
    api
      .get(url)
      .then((response) => {
        setLoading(false);
        setProducts(response.data);
        return response.data;
      })
      .then((data) => {
        parseProducts(data);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };
  const parseProducts = (data) => {
    switch (currentUser.canal_id) {
      case 2:
      case 4:
        for (let i = 0; i < data.length; i++) {
          const curr = data[i];
          let currentProduct = description
            ? description.find((item) => item.product_id === curr.id)
            : null;

          const pointsAmount = currentProduct?.amount || 0;
          const ftPointsAmount = description
            ? description.find(
                (item) =>
                  item.product_id === curr.id &&
                  item.name.includes(curr.ft_name),
              )?.amount || 0
            : 0;

          const stPointsAmount = description
            ? description.find(
                (item) =>
                  item.product_id === curr.id &&
                  item.name.includes(curr.st_name),
              )?.amount || 0
            : 0;

          setGetValue((prev) => ({
            ...prev,
            [`${curr.product} - ${curr.id}`]: {
              id: curr.id,
              product: `${curr.product} - ${curr.size ?? 'Todos'}`,
              product_point: curr?.points ?? 0,
              points: {
                points_base: curr?.points ?? 0,
                amount: pointsAmount,
              },
              ft_point: {
                points_base: (curr?.points ?? 0) + curr?.ft_point ?? 0,
                amount: ftPointsAmount,
              },
              st_point: {
                points_base: (curr?.points ?? 0) + curr?.st_point ?? 0,
                amount: stPointsAmount,
              },
              both_points_not_null: {
                points_base:
                  (curr?.points ?? 0) +
                  (curr?.ft_point ?? 0) +
                  (curr?.st_point ?? 0),
                amount: 0,
              },
              percentage: curr.percentage,
            },
          }));
        }
        break;
      case 5:
        for (let i = 0; i < data.length; i++) {
          const curr = data[i];
          setGetValue((prev) => ({
            ...prev,
            [`${curr.product} - ${curr.id}`]: {
              id: curr.id,
              product: curr.product,
              product_point: curr?.points ?? 0,
              percentage: curr.percentage,
              points: {
                points_base: curr?.points ?? 0,
                amount: 0,
              },
            },
          }));
        }
        break;
      default:
        return {};
    }
  };

  const switchHeader = () => {
    switch (currentUser.canal_id) {
      case 2:
        return (
          <>
            <td key="1">Produto</td>
            <td key="2">Ativação</td>
            {/* <td key="4">Fidelidade</td> */}
            <td key="5">Port-in / Migra</td>
            {/* <td key="6">Fid. + Port.</td> */}
          </>
        );
      case 4:
        return (
          <>
            <td key="1">Produto</td>
            <td key="2">GB</td>
            <td key="3">Apenas Produto</td>
            <td key="4">Débito Automático</td>
            <td key="5">Portabilidade</td>
            <td key="6">Déb. Aut + Port.</td>
          </>
        );
      default:
        return (
          <>
            <td>Ativação</td>
            <td>Quantidade</td>
          </>
        );
    }
  };

  const switchContent = (product, name) => {
    let currentProduct = description
      ? description.find((item) => item.product_id === product.id)
      : null;
    switch (currentUser.canal_id) {
      case 2:
        return Object.entries(product).map(([key, value], i) => {
          if (key === 'product') {
            return <td key={i}>{value}</td>;
          }
          if (key === 'points') {
            if (value !== null && value > 0) {
              return (
                <td key={i}>
                  <InputController
                    setGetValue={setGetValue}
                    type={key}
                    name={name}
                    value={currentProduct?.amount || 0}
                  />
                </td>
              );
            } else {
              return <td></td>;
            }
          }
          // if (key === 'ft_point') {
          //   //fidelidade
          //   if (value !== null) {
          //     currentProduct = description
          //       ? description.find(
          //           (item) =>
          //             item.product_id === product.id &&
          //             item.name.includes(product.ft_name),
          //         )
          //       : null;
          //     return (
          //       <td key={i}>
          //         <InputController
          //           setGetValue={setGetValue}
          //           type={key}
          //           name={name}
          //           value={currentProduct?.amount || 0}
          //         />
          //       </td>
          //     );
          //   } else {
          //     return <td></td>;
          //   }
          // }
          if (key === 'st_point') {
            //Portabilidade
            if (value !== null) {
              currentProduct = description
                ? description.find(
                    (item) =>
                      item.product_id === product.id &&
                      item.name.includes(product.st_name),
                  )
                : null;
              return (
                <td key={i}>
                  <InputController
                    setGetValue={setGetValue}
                    type={key}
                    name={name}
                    value={currentProduct?.amount || 0}
                  />
                </td>
              );
            } else {
              return <td></td>;
            }
          }
          // if (key === 'both_points_not_null') {
          //   //Fid + Port
          //   if (value === 1) {
          //     return (
          //       <td key={i}>
          //         <InputController
          //           setGetValue={setGetValue}
          //           type={key}
          //           name={name}
          //           value={currentProduct?.amount || 0}
          //         />
          //       </td>
          //     );
          //   } else {
          //     return <td></td>;
          //   }
          // }
        });
      case 4:
        return Object.entries(product).map(([key, value], i) => {
          if (key === 'product') {
            return <td key={i}>{value}</td>;
          }
          if (key === 'size') {
            return <td key={i}>{value ?? 'Todos'}</td>;
          }
          if (key === 'points') {
            return (
              <td key={i}>
                <InputController
                  setGetValue={setGetValue}
                  type={key}
                  name={name}
                  value={currentProduct?.amount || 0}
                />
              </td>
            );
          }
          if (key === 'ft_point') {
            if (value !== null) {
              currentProduct = description
                ? description.find(
                    (item) =>
                      item.product_id === product.id &&
                      item.name.includes(product.ft_name),
                  )
                : null;
              return (
                <td key={i}>
                  <InputController
                    setGetValue={setGetValue}
                    type={key}
                    name={name}
                    value={currentProduct?.amount || 0}
                  />
                </td>
              );
            } else {
              return <td></td>;
            }
          }
          if (key === 'st_point') {
            if (value !== null) {
              currentProduct = description
                ? description.find(
                    (item) =>
                      item.product_id === product.id &&
                      item.name.includes(product.st_name),
                  )
                : null;
              return (
                <td key={i}>
                  <InputController
                    setGetValue={setGetValue}
                    type={key}
                    name={name}
                    value={currentProduct?.amount || 0}
                  />
                </td>
              );
            } else {
              return <td></td>;
            }
          }
          if (key === 'both_points_not_null') {
            if (value === 1) {
              return (
                <td key={i}>
                  <InputController
                    setGetValue={setGetValue}
                    type={key}
                    name={name}
                    value={currentProduct?.amount || 0}
                  />
                </td>
              );
            } else {
              return <td></td>;
            }
          }
        });
      case 5:
        return Object.entries(product).map(([key, value], i) => {
          if (key === 'product') {
            return <td key={i}>{value}</td>;
          }
          if (key === 'points') {
            if (value !== null && value > 0) {
              return (
                <td key={i}>
                  <InputController
                    setGetValue={setGetValue}
                    type={key}
                    name={name}
                    value={currentProduct?.amount || 0}
                  />
                </td>
              );
            } else {
              return <td></td>;
            }
          }
        });
      default:
        return <></>;
    }
  };

  const reducer = () => {
    let total = 0;
    switch (currentUser.canal_id) {
      case 2:
      case 4:
        for (let prop in getValue) {
          const curr = getValue[prop];
          const percentage = curr.percentage;
          for (let inner in curr) {
            if (AAprop.includes(inner)) {
              const partial =
                curr[inner].points_base *
                curr[inner].amount *
                parseFloat(percentage);
              total += partial;
            }
          }
        }
        return setTotalPoints(() => total);
      case 5:
        for (let prop in getValue) {
          const curr = getValue[prop];
          const percentage = curr.percentage;
          for (let inner in curr) {
            if (inner === 'points') {
              const partial =
                curr[inner].points_base *
                curr[inner].amount *
                parseFloat(percentage);
              total += partial;
            }
          }
        }
        return setTotalPoints(() => total);
      default:
        return 0;
    }
  };

  return (
    <>
      <S.Container>
        <table rowSpacing="15" cellSpacing="0" cellPadding="0">
          <thead>{switchHeader()}</thead>
          <tbody>
            {products &&
              products.map((product) => (
                <>
                  <S.TableRow>
                    {switchContent(
                      product,
                      `${product.product} - ${product.id}`,
                    )}
                  </S.TableRow>
                </>
              ))}
          </tbody>
        </table>
      </S.Container>
    </>
  );
};

export default ProductsTable;
