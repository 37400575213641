import { useState } from 'react';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import ContainerWithTabs from '~/components/NexAdmin/ContainerWithTabs';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';
import Loading from '~/components/Loading';
import BaseTableGN from '../Components/BaseTable';
import BaseTableGNUser from '../Components/UserTable';
import {
  status_options,
  base_columns_gn,
  approved_campagn,
  pending_campagn,
  preapproved_campagn,
  repproved_campagn,
  status_options_AA,
} from '../columns';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import CampagnsGNDescription from '~/components/NexAdmin/Modal/Descriptions/GN/campagns';
import { parseJwt } from '~/services/auth';

const GNCampagns = () => {
  const [loading, setLoading] = useState(false);
  const [row, setRow] = useState({});
  const [step, setStep] = useState('gn');
  const [dinamycState, setDinamicyState] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { openModal } = useModal();
  const current_channel = parseJwt().canal_id;
  const current_user = parseJwt();

  const openThermsModal = () => {
    openModal({
      title: 'Gerenciamento de Campanhas',
      close: true,
      content: <CampagnsGNDescription />,
    });
  };
  const tab = [
    {
      title: 'Pendentes',
      status: status_options_AA.pending,
      columns: [...base_columns_gn, pending_campagn],
    },
    {
      title: 'Pré-Aprovados',
      status: status_options_AA.preapproved,
      columns: [...base_columns_gn, preapproved_campagn],
    },
    {
      title: 'Aprovados',
      status: status_options_AA.approved,
      columns: [...base_columns_gn, approved_campagn],
    },
    {
      title: 'Reprovados',
      status: status_options_AA.repproved,
      columns: [...base_columns_gn, repproved_campagn],
    },
  ];
  const switchComp = (step) => {
    switch (step) {
      case 'gn':
        return (
          <ContainerWithTabs
            defaultTabIndex={currentIndex}
            setExternalIndexInfo={setCurrentIndex}
            tabs={tab.map((item, i) => ({
              title: item.title,
              component: (
                <BaseTableGN
                  title={item.title}
                  current_state={item.status}
                  columns={item.columns}
                  setLoading={setLoading}
                  setDinamicyState={setDinamicyState}
                  setRow={setRow}
                  setStep={setStep}
                  current_user={current_user}
                />
              ),
            }))}
          />
        );
      case 'user-gn':
        return (
          <ContainerWithTitle>
            <BaseTableGNUser
              setStep={setStep}
              row={row}
              setLoading={setLoading}
              dinamycState={dinamycState}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              current_user={current_user}
            />
          </ContainerWithTitle>
        );
    }
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="config"
          title="Gerenciamento de Campanhas"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>{switchComp(step)}</ContentInner>
      </ContentWrapper>
    </>
  );
};

export default GNCampagns;
