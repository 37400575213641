import { useRef, useState } from 'react';
import { AiOutlineSwap } from 'react-icons/ai';

import { Form } from '@unform/web';

import Select from '~/components/Select';
import { showNotification } from '~/utils/notificationBoxes';

import DataInput from '../DataInput/index';
import RescueTable from '../RescueTable';
import * as S from './styles';

const Rescue = () => {
  const formRef = useRef(null);
  const [order, setOrder] = useState();
  const [dataIni, setDataIni] = useState();
  const [dataFim, setDataFim] = useState();
  const [submit, setSubmit] = useState(false);

  const orderOptions = [
    { value: 'ASC', label: 'Mais recentes' },
    { value: 'DESC', label: 'Mais antigos' },
  ];

  const handleSubmit = () => {
    if (!dataIni || !dataFim) {
      return showNotification(
        'danger',
        'Atenção',
        'Preencha a data inicial e final.',
      );
    }

    if (dataFim < dataIni) {
      return showNotification(
        'danger',
        'Atenção',
        'A data inicial deve ser menor do que a final',
      );
    }

    return setSubmit(!submit);
  };
  return (
    <>
      <S.Wrapper>
        <S.Container>
          <S.DataWrapper>
            <DataInput title="Selecione o periodo" setValue={setDataIni} />
            <DataInput title="Até" setValue={setDataFim} />
          </S.DataWrapper>

          <S.DataHeader>
            <Form ref={formRef} onSubmit={() => {}}>
              <AiOutlineSwap size={20} />
              <span>Ordenar por:</span>
              <Select
                name="order"
                options={orderOptions}
                placeholder="Selecione"
                onChange={(e) => setOrder(e.value)}
              />
            </Form>
            <S.SubmitButton onClick={() => handleSubmit()}>
              Consultar
            </S.SubmitButton>
          </S.DataHeader>
          <RescueTable
            order={order}
            dataIni={dataIni}
            dataFim={dataFim}
            submit={submit}
          />
        </S.Container>
      </S.Wrapper>
    </>
  );
};

export default Rescue;
