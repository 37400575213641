export const model_data = [
  {
    ID: 10000002,
    G20: 'SIM',
    CANAL: 'PAP INDIRETO',
    CNPJ: 245634563456,
    'RAZÃO SOCIAL': 'TALK PAP LTDA',
    VENDEDOR: 'FABIANO BITENCOURT DA SILVA',
    CPF: 2456534263,
    CLIENTE: '-',
    'CPF/ CNPJ': '-',
    DATA: 45197,
    'FORMA DE PAGAMENTO': 'VIA_POSTAL',
    'EQUIPE VENDA': 'TALK 2',
    'PLANO TV': '-',
    'PLANO INTERNET': '500 MEGA (FIBRA)',
    'PLANO FONE': '-',
    'PLANO CELULAR': '-',
    'PORTABILIDADE FIXO': 'Não',
    'PORTABILIDADE MÓVEL': 'Não',
    STATUS: 'REPROVADO',
    MOTIVO: 'N_INSTALADA',
  },
  {
    ID: 10000466,
    G20: 'SIM',
    CANAL: 'PAP INDIRETO',
    CNPJ: 4567465746,
    'RAZÃO SOCIAL': 'CONEXOES TELECOM LTDA',
    VENDEDOR: 'RAIANE VALERIA OLIVEIRA ARAUJO',
    CPF: 345635463,
    CLIENTE: '-',
    'CPF/ CNPJ': '-',
    DATA: 45239,
    'FORMA DE PAGAMENTO': 'VIA_POSTAL',
    'EQUIPE VENDA': 'EQUIPE WILLIAMS ALVES',
    'PLANO TV': '-',
    'PLANO INTERNET': '-',
    'PLANO FONE': '-',
    'PLANO CELULAR': '-',
    'PORTABILIDADE FIXO': 'Não',
    'PORTABILIDADE MÓVEL': 'Não',
    STATUS: 'APROVADO',
    MOTIVO: '-',
  },
  {
    ID: 10000019,
    G20: 'SIM',
    CANAL: 'PAP INDIRETO',
    CNPJ: 23452345,
    'RAZÃO SOCIAL': 'MS NOGUEIRA EIRELI',
    VENDEDOR: 'APHONSO WESLEY MARTINS COSTA',
    CPF: 367345656,
    CLIENTE: '-',
    'CPF/ CNPJ': '-',
    DATA: 45231,
    'FORMA DE PAGAMENTO': 'VIA_POSTAL',
    'EQUIPE VENDA': 'FERNANDO DIAS SERRA',
    'PLANO TV': '-',
    'PLANO INTERNET': '-',
    'PLANO FONE': '-',
    'PLANO CELULAR': '-',
    'PORTABILIDADE FIXO': 'Não',
    'PORTABILIDADE MÓVEL': 'Não',
    STATUS: 'PENDENTE',
    MOTIVO: '-',
  },
];

export const model_columns = [
  {
    name: 'ID',
    selector: 'ID',
    cell: (row) => <div>{row['ID']}</div>,
  },
  {
    name: 'G20',
    selector: 'G20',
    cell: (row) => <div>{row['G20']}</div>,
  },
  {
    name: 'CANAL',
    selector: 'CANAL',
    cell: (row) => <div>{row['CANAL']}</div>,
  },
  {
    name: 'CNPJ',
    selector: 'CNPJ',
    cell: (row) => <div>{row['CNPJ']}</div>,
  },
  {
    name: 'RAZÃO SOCIAL',
    selector: 'RAZÃO SOCIAL',
    cell: (row) => <div>{row['RAZÃO SOCIAL']}</div>,
  },
  {
    name: 'VENDEDOR',
    selector: 'VENDEDOR',
    cell: (row) => <div>{row['VENDEDOR']}</div>,
  },
  {
    name: 'CPF',
    selector: 'CPF',
    cell: (row) => <div>{row['CPF']}</div>,
  },
  {
    name: 'CLIENTE',
    selector: 'CLIENTE',
    cell: (row) => <div>{row['CLIENTE']}</div>,
  },
  {
    name: 'CPF/ CNPJ',
    selector: 'CPF/ CNPJ',
    cell: (row) => <div>{row['CPF/ CNPJ']}</div>,
  },
  {
    name: 'DATA',
    selector: 'DATA',
    cell: (row) => <div>{row['DATA']}</div>,
  },
  {
    name: 'FORMA DE PAGAMENTO',
    selector: 'FORMA DE PAGAMENTO',
    cell: (row) => <div>{row['FORMA DE PAGAMENTO']}</div>,
  },
  {
    name: 'EQUIPE VENDA',
    selector: 'EQUIPE VENDA',
    cell: (row) => <div>{row['EQUIPE VENDA']}</div>,
  },
  {
    name: 'PLANO TV',
    selector: 'PLANO TV',
    cell: (row) => <div>{row['PLANO TV']}</div>,
  },
  {
    name: 'PLANO INTERNET',
    selector: 'PLANO INTERNET',
    cell: (row) => <div>{row['PLANO INTERNET']}</div>,
  },
  {
    name: 'PLANO FONE',
    selector: 'PLANO FONE',
    cell: (row) => <div>{row['PLANO FONE']}</div>,
  },
  {
    name: 'PLANO CELULAR',
    selector: 'PLANO CELULAR',
    cell: (row) => <div>{row['PLANO CELULAR']}</div>,
  },
  {
    name: 'PORTABILIDADE FIXO',
    selector: 'PORTABILIDADE FIXO',
    cell: (row) => <div>{row['PORTABILIDADE FIXO']}</div>,
  },
  {
    name: 'PORTABILIDADE MÓVEL',
    selector: 'PORTABILIDADE MÓVEL',
    cell: (row) => <div>{row['PORTABILIDADE MÓVEL']}</div>,
  },
  {
    name: 'STATUS',
    selector: 'STATUS',
    cell: (row) => <div>{row['STATUS']}</div>,
  },
  {
    name: 'MOTIVO',
    selector: 'MOTIVO',
    cell: (row) => <div>{row['MOTIVO']}</div>,
  },
];

export const model_columns_download = [
  {
    name: 'ID',
    selector: (row) => row['ID'],
    cell: (row) => <div>{row['ID']}</div>,
  },
  {
    name: 'G20',
    selector: (row) => row['G20'],
    cell: (row) => <div>{row['G20']}</div>,
  },
  {
    name: 'CANAL',
    selector: (row) => row['CANAL'],
    cell: (row) => <div>{row['CANAL']}</div>,
  },
  {
    name: 'CNPJ',
    selector: (row) => row['CNPJ'],
    cell: (row) => <div>{row['CNPJ']}</div>,
  },
  {
    name: 'RAZAO SOCIAL',
    selector: (row) => row['RAZAO SOCIAL'],
    cell: (row) => <div>{row['RAZAO SOCIAL']}</div>,
  },
  {
    name: 'VENDEDOR',
    selector: (row) => row['VENDEDOR'],
    cell: (row) => <div>{row['VENDEDOR']}</div>,
  },
  {
    name: 'CPF',
    selector: (row) => row['CPF'],
    cell: (row) => <div>{row['CPF']}</div>,
  },
  {
    name: 'CLIENTE',
    selector: (row) => row['CLIENTE'],
    cell: (row) => <div>{row['CLIENTE']}</div>,
  },
  {
    name: 'CPF/ CNPJ',
    selector: (row) => row['CPF/ CNPJ'],
    cell: (row) => <div>{row['CPF/ CNPJ']}</div>,
  },
  {
    name: 'DATA',
    selector: (row) => row['DATA'],
    cell: (row) => <div>{row['DATA']}</div>,
  },
  {
    name: 'FORMA DE PAGAMENTO',
    selector: (row) => row['DAFORMA DE PAGAMENTO'],
    cell: (row) => <div>{row['FORMA DE PAGAMENTO']}</div>,
  },
  {
    name: 'EQUIPE VENDA',
    selector: (row) => row['EQUIPE VENDA'],
    cell: (row) => <div>{row['EQUIPE VENDA']}</div>,
  },
  {
    name: 'PLANO TV',
    selector: (row) => row['PLANO TV'],
    cell: (row) => <div>{row['PLANO TV']}</div>,
  },
  {
    name: 'PLANO INTERNET',
    selector: (row) => row['PLANO INTERNET'],
    cell: (row) => <div>{row['PLANO INTERNET']}</div>,
  },
  {
    name: 'PLANO FONE',
    selector: (row) => row['PLANO FONE'],
    cell: (row) => <div>{row['PLANO FONE']}</div>,
  },
  {
    name: 'PLANO CELULAR',
    selector: (row) => row['PLANO CELULAR'],
    cell: (row) => <div>{row['PLANO CELULAR']}</div>,
  },
  {
    name: 'PORTABILIDADE FIXO',
    selector: (row) => row['PORTABILIDADE FIXO'],
    cell: (row) => <div>{row['PORTABILIDADE FIXO']}</div>,
  },
  {
    name: 'PORTABILIDADE MÓVEL',
    selector: (row) => row['PORTABILIDADE MÓVEL'],
    cell: (row) => <div>{row['PORTABILIDADE MÓVEL']}</div>,
  },
  {
    name: 'STATUS',
    selector: (row) => row['STATUS'],
    cell: (row) => <div>{row['STATUS']}</div>,
  },
];
