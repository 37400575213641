// import styled from 'styled-components';

// export const Wrapper = styled.div`
//   display: grid;
//   grid-template-rows: 1fr;
//   grid-gap: 20px;
//   width: 50rem;
//   margin-bottom: ${({ theme }) => theme.spacing.contentH};
// `;

// export const ButtonsGrid = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 750px;
  margin: 0 auto;
  justify-items: end;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  ${media.lessThan('1100px')`
    grid-template-columns: 1fr;
    grid-template-rows: 2fr;
  `}
`;

export const InputWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 10px;
  justify-items: center;

  h3 {
    margin-top: 10px;
    font-size: ${({ theme }) => theme.adminfontsize.containertitle};
    color: ${({ theme }) => theme.admincolors.contrast.black};
  }

  img {
    width: 100%;
    max-width: 200px;
    object-fit: cover;
  }
`;
