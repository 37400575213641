import React, { useState } from 'react';
import * as S from './styles';

const ContainerWithTabs = ({
  mt,
  tabs,
  defaultTabIndex = 0,
  setExternalIndexInfo,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(defaultTabIndex);
  return (
    <S.Wrapper mt={mt}>
      <S.Header>
        {tabs.map((tab, i) => (
          <S.Tab
            key={tab.title}
            active={i === selectedTabIndex}
            onClick={() => {
              setSelectedTabIndex(i);
              tab.onClick && tab.onClick();
            }}
          >
            {tab.title}
          </S.Tab>
        ))}
      </S.Header>
      <S.Content>
        {tabs[selectedTabIndex].component}
        {setExternalIndexInfo ? setExternalIndexInfo(selectedTabIndex) : null}
      </S.Content>
    </S.Wrapper>
  );
};

export default ContainerWithTabs;
