import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgb(133 133 133 / 30%);
  position: relative;
  background-color: linear-gradient(0deg, #fff 0%, #fff 100%),
    lightgray -353.133px 0px / 161.202% 113.66% no-repeat;
  ${media.lessThan('medium')`
    margin-bottom: 50px;
  `}
`;

export const Title = styled.p`
  margin-left: 5px;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  color: ${({ theme }) => theme.colors.red};
  font-weight: ${({ theme }) => theme.font.medium};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const WishContainer = styled.div`
  .rec.rec-arrow {
    border-radius: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #c50101;
  }
  .rec-carousel-item-visible {
    .progress {
      animation: anima 1.5s ease-in-out forwards;
    }
  }

  .rec-item-wrapper {
    min-height: 200px;
  }

  .rec-dot_active {
    box-shadow: 0 0 1px 3px #c50101;
    background-color: #c5010160;
  }
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 20px;
  width: 100vw;
  overflow-x: hidden;
  ${media.greaterThan('medium')`
            width: 100%;
        `};
`;

export const WishScrollArea = styled.div`
  overflow-x: auto;
  overflow-y: hidden;

  width: 100vw;
  overflow-x: hidden;
  ${media.greaterThan('medium')`
        padding: 20px;

        width: 100%;
    `};
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.colors.grayDarker};
  margin-left: 5px;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
