import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import { useState } from 'react';
import Loading from '~/components/Loading';
import Validate from './Components/Validate';
import Errors from './Components/Errors';
import CreateUsers from './Components/Upload';

const STEPS = ['validate', 'createUsers', 'error', 'concluded'];

const PAPCreateUsers = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(0);
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);

  const changeStep = (newStep) => {
    const newStepIndex = STEPS.indexOf(newStep);

    if (newStepIndex < 0) return;

    setCurrentStep(newStepIndex);
  };

  const getStepComponent = (currentStep) => {
    const step = STEPS[currentStep];

    switch (step) {
      case 'validate':
        return (
          <Validate
            file={file}
            setFile={setFile}
            setErrors={setErrors}
            setLoading={setLoading}
            changeStep={changeStep}
            setData={setData}
          />
        );
      case 'error':
        return (
          <Errors
            errors={errors}
            tryAgain={() => {
              setFile(null);
              changeStep('validate');
            }}
          />
        );
      case 'createUsers':
        return (
          <CreateUsers
            file={file}
            setFile={setFile}
            setErrors={setErrors}
            setLoading={setLoading}
            changeStep={changeStep}
            data={data}
          />
        );
      default:
        return <h3>Algo está errado, tente novamente mais tarde</h3>;
    }
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag icon="newGuest" title="Criar usuários" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle title="Criar/Alterar usuários em massa">
            {getStepComponent(currentStep)}
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default PAPCreateUsers;
