import React from 'react';

const Save = ({ color }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.875 0.625H2.375C1.91087 0.625 1.46575 0.809374 1.13756 1.13756C0.809374 1.46575 0.625 1.91087 0.625 2.375V14.625C0.625 15.0891 0.809374 15.5342 1.13756 15.8624C1.46575 16.1906 1.91087 16.375 2.375 16.375H14.625C15.5875 16.375 16.375 15.5875 16.375 14.625V4.125L12.875 0.625ZM14.625 14.625H2.375V2.375H12.1488L14.625 4.85125V14.625ZM8.5 8.5C7.0475 8.5 5.875 9.6725 5.875 11.125C5.875 12.5775 7.0475 13.75 8.5 13.75C9.9525 13.75 11.125 12.5775 11.125 11.125C11.125 9.6725 9.9525 8.5 8.5 8.5ZM3.25 3.25H11.125V6.75H3.25V3.25Z"
        fill={color}
      />
    </svg>
  );
};
export default Save;
