import React, { useState, useEffect } from 'react';

import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import { faq_bko_pap, faq_bko } from './temporary_sub_faq_pap';
import Question from './components/Question';
import * as S from './styles';

const Faq = () => {
  const [perguntasRespostas, setPerguntasRespostas] = useState([]);
  const [contacts, setContacts] = useState({});

  const canal = localStorage.getItem('canal');
  const number = process.env.REACT_APP_NUMBER;

  useEffect(() => {
    api.get(`/faq/${canal}`).then((response) => {
      setPerguntasRespostas(response.data.data);
    });
    api.get(`/fale-conosco/${canal}`).then((response) => {
      setContacts(response.data.data[0]);
    });
  }, []);

  const content = () => {
    switch (parseJwt().canal_id) {
      case 2:
      case 8:
        if ([79, 85].includes(parseJwt().cargo)) {
          return faq_bko.map((item) => (
            <Question
              key={item.id}
              pergunta={item.pergunta}
              resposta={item.resposta}
            />
          ));
        } else if ([75, 76, 91].includes(parseJwt().cargo)) {
          return faq_bko_pap.map((item) => (
            <Question
              key={item.id}
              pergunta={item.pergunta}
              resposta={item.resposta}
            />
          ));
        } else {
          return perguntasRespostas.map((per) => (
            <>
              <Question
                key={per.id}
                resposta={per.resposta}
                pergunta={per.pergunta}
              />
            </>
          ));
        }
      default:
        return perguntasRespostas.map((per) => (
          <>
            <Question
              key={per.id}
              resposta={per.resposta}
              pergunta={per.pergunta}
            />
          </>
        ));
    }
  };

  return (
    <>
      <S.Container>
        <S.Questions>{content()}</S.Questions>
      </S.Container>
      <S.Container>
        <S.WishTitle className="underline">Central de atendimento</S.WishTitle>
        <S.Text>
          <h2>Entre em contato por:</h2>
          <p>
            WhatsApp:{' '}
            <a
              className="underline"
              href={`https://api.whatsapp.com/send?phone=${contacts.telefone}`}
            >
              {number}
            </a>
          </p>
          <h2>Horário de funcionamento:</h2>
          <p>de segunda a sexta-feira, das 9h às 18h, exceto feriados.</p>
        </S.Text>
      </S.Container>
    </>
  );
};

export default Faq;
