import { useState, useRef } from 'react';

const DownloadHook = ({
  externalCall,
  onError,
  getFileName,
  beforeLoading,
  afterLoading,
}) => {
  const ref = useRef(null);
  const [urlFile, setUrlFile] = useState('');
  const [nameFile, setNameFile] = useState('');

  const execute = async () => {
    beforeLoading();
    try {
      const { data } = await externalCall();
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      setUrlFile(url);
      setNameFile(getFileName);
      ref.current?.click();
      URL.revokeObjectURL(url);
      afterLoading();
    } catch (error) {
      afterLoading();
      new Blob([error.response.data], { type: 'application/json' })
        .text()
        .then((error) => onError({ response: { data: JSON.parse(error) } }));
    }
  };
  return { execute, ref, urlFile, nameFile };
};

export default DownloadHook;
