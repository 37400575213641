/* eslint-disable no-console */
import { useState } from 'react';

import HeaderButtons from './components/HeaderButtons/index';
import Rescue from './components/Rescue';
import UserPoints from './components/UserPoints';

const Extract = () => {
  const [view, setView] = useState('resgates');

  return (
    <>
      <HeaderButtons view={view} setView={setView} />
      {view === 'resgates' ? <Rescue /> : <UserPoints />}
    </>
  );
};

export default Extract;
