import React from 'react';

const Warning = ({ color }) => {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.2332 31.5L41.2332 34.5666C38.8332 36.9666 37.3332 39 37.3332 44H30.6665V42.3333C30.6665 38.6333 32.1665 35.3 34.5665 32.9L38.6998 28.7C39.9332 27.5 40.6665 25.8333 40.6665 24C40.6665 22.2319 39.9641 20.5362 38.7139 19.2859C37.4636 18.0357 35.7679 17.3333 33.9998 17.3333C32.2317 17.3333 30.536 18.0357 29.2858 19.2859C28.0356 20.5362 27.3332 22.2319 27.3332 24H20.6665C20.6665 20.4637 22.0713 17.0724 24.5717 14.5719C27.0722 12.0714 30.4636 10.6666 33.9998 10.6666C37.5361 10.6666 40.9274 12.0714 43.4279 14.5719C45.9284 17.0724 47.3332 20.4637 47.3332 24C47.3284 26.8108 46.2143 29.5061 44.2332 31.5V31.5ZM37.3332 57.3333H30.6665V50.6666H37.3332V57.3333ZM33.9998 0.666626C29.6224 0.666626 25.2879 1.52882 21.2437 3.20398C17.1995 4.87913 13.5249 7.33445 10.4296 10.4297C4.1784 16.6809 0.666504 25.1594 0.666504 34C0.666504 42.8405 4.1784 51.319 10.4296 57.5702C13.5249 60.6655 17.1995 63.1208 21.2437 64.7959C25.2879 66.4711 29.6224 67.3333 33.9998 67.3333C42.8404 67.3333 51.3189 63.8214 57.5701 57.5702C63.8213 51.319 67.3332 42.8405 67.3332 34C67.3332 15.5666 52.3332 0.666626 33.9998 0.666626Z"
        fill={color}
      />
    </svg>
  );
};
export default Warning;
