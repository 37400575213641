import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTabs from '~/components/NexAdmin/ContainerWithTabs';
import Quiz from './Quiz';
import User from './User';

const Report = () => {
  return (
    <>
      <HeaderWrapper>
        <StatusTag icon="config" title="Visão Geral" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTabs
            tabs={[
              {
                title: 'Relatório de Treinamento',
                component: <Quiz />,
              },
              {
                title: 'Relatório de Treinamento feito por usuários',
                component: <User />,
              },
            ]}
          />
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default Report;
