import ConfirmButton from '~/components/NexAdmin/Buttons/ConfirmButton';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import CampaignExhibitionModal from '../Modal';

const Action = ({ row, setLoading, refresh }) => {
  const { openModal, hideModal } = useModal();
  const openThermsModal = () => {
    openModal({
      title: 'Edição de Campanha',
      close: true,
      content: (
        <CampaignExhibitionModal
          setLoading={setLoading}
          hideModal={hideModal}
          row={row}
          refresh={refresh}
        />
      ),
    });
  };
  return (
    <ConfirmButton
      icon={'config'}
      iconColor={'green'}
      label="Editar"
      confirmLabel={'SIM'}
      cancelLabel="CANCELAR"
      openOnRight={true}
      confirmAction={() => openThermsModal()}
    />
  );
};

export default Action;
