import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import { useState, useEffect, useRef } from 'react';
import columns from './columns';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import Loading from '~/components/Loading';
import Table from '~/components/NexAdmin/Table';
import Counter from '~/components/NexAdmin/Counter';
import { Select, Input } from '~/components/NexAdmin/Inputs/index';
import { Button } from '~/components/NexAdmin/Buttons/index';
import { Form } from '@unform/web';
import { appendObjectInFormData } from '~/utils';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { toDateTime } from '~/Helpers/Date';
import * as S from './styles';
import Download from '../Sales/Components/Download';
import DownloadHook from '~/hooks/NexAdmin/DownloadHook';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import BonusPointReportDescription from '~/components/NexAdmin/Modal/Descriptions/bonusPointReportDescription';

const origins = [
  { label: 'Todos', value: '' },
  { label: 'Mecânica manual', value: 'admin' },
  { label: 'Mecânica automática', value: 'extra' },
];

const CreateBonusPoints = () => {
  const [loading, setLoading] = useState(false);
  const [points, setPoints] = useState([]);
  const [count, setCount] = useState(0);
  const current_user = parseJwt();
  const formRef = useRef();
  const { openModal } = useModal();

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleSubmit = (data) => {
    setLoading(true);
    const form = new FormData();
    appendObjectInFormData(form, {
      channel_id: current_user.canal_id,
      ...data,
    });
    const params = new URLSearchParams(form);

    api
      .get(`/bonus-point-report/?${params}`)
      .then((response) => {
        const bonusPointsData = response.data?.bonus_points || [];
        const count = response.data?.count || bonusPointsData.length;
        setPoints(bonusPointsData);
        setCount(count);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  const handleDownload = () => {
    const data = formRef.current.getData();
    const form = new FormData();
    appendObjectInFormData(form, {
      channel_id: current_user.canal_id,
      download: true,
      segmento: data?.Segmento ? data?.Segmento : '*',
      ...data,
    });
    const params = new URLSearchParams(form);
    return api.get(`/bonus-point-report/?${params}`, { responseType: 'blob' });
  };

  const buildReportName = (name) => {
    return `Relatório ${name} - Canal ${localStorage.getItem(
      'canalNome',
    )} - ${toDateTime(new Date())}`;
  };

  const beforeLoading = () => setLoading(true);

  const afterLoading = () => setLoading(false);

  const { execute, ref, urlFile, nameFile } = DownloadHook({
    externalCall: handleDownload,
    onError: HTTPErrorCallback,
    getFileName: buildReportName('Ponto Bônus'),
    beforeLoading,
    afterLoading,
  });

  const openThermsModal = () => {
    openModal({
      title: 'Ponto Bônus',
      close: true,
      content: <BonusPointReportDescription />,
    });
  };
  //teste

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="add"
          title={parseJwt().canal_id == 9 ? 'Pontos Vendas' : 'Pontos Bônus'}
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle
            title={
              parseJwt().canal_id == 9
                ? 'Relatório de Pontos Vendas'
                : 'Relatório de Pontos Bônus'
            }
          >
            <Form ref={formRef} onSubmit={handleSubmit}>
              <S.FormWrapper>
                <S.FormFilters>
                  <Input name="initial_date" label="De:" type="date" />
                  <Input name="final_date" label="Até:" type="date" />
                  <Input label="CPF" name="cpf" type="number" />
                  <Input label="Nomenclatura" name="bonus_name" type="text" />
                  <Select label="Origem" name="source" options={origins} />
                  <Input label="Pontos" name="points" type="number" />
                  {current_user.canal_id === 9 && (
                    <Select
                      name="segmento"
                      options={[
                        { label: 'Todos', value: 'all' },
                        { label: 'Premium', value: 'PREMIUM' },
                        { label: 'Condomínio', value: 'CONDOMÍNIOS' },
                      ]}
                      label="Segmento"
                      defaultValue={{ label: 'Todos', value: 'all' }}
                    />
                  )}
                </S.FormFilters>
                <Button type="submit">Consultar</Button>
              </S.FormWrapper>
              <S.DownloadWrapper>
                <a href={urlFile} download={nameFile} ref={ref} />
                <Download buttonName="Download" external={execute} />
              </S.DownloadWrapper>
            </Form>
            <Counter total={count} />
            <Table
              data={points}
              columns={columns}
              loading={loading}
              dataTableProps={{ pagination: true }}
              header
              dropdownItems={false}
              hasDownload={false}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default CreateBonusPoints;
