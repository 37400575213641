import { Form } from '@unform/web';
import { Input } from '~/components/NexAdmin/Inputs';
import { Button } from '~/components/NexAdmin/Buttons/index';
import { appendObjectInFormData } from '~/utils';
import { parseJwt } from '~/services/auth';
import * as S from './styles';
import api from '~/services/api';
import { showNotification } from '~/utils/notificationBoxes';
import { HTTPErrorCallback } from '~/Helpers/Error';

const InsertPointsGNModal = ({ setLoading, hideModal, row, refresh }) => {
  const current_user = parseJwt();
  const insertPoints = (data) => {
    setLoading(true);
    const formData = new FormData();
    appendObjectInFormData(formData, {
      channel_id: current_user.canal_id,
      id: row.user_id,
      ...data,
    });
    api
      .post(`/lad/default/admin/insert-points`, formData)
      .then((response) => {
        setLoading(false);
        refresh();
        hideModal();
        showNotification('success', 'Sucesso!', `${response.data.message}`);
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  return (
    <Form onSubmit={insertPoints}>
      <S.Wrapper>
        <Input name="points" label="Pontos: " type="text" />
        <S.ButtonsGrid>
          <Button type="submit">Adicionar Pontos</Button>
        </S.ButtonsGrid>
      </S.Wrapper>
    </Form>
  );
};

export default InsertPointsGNModal;
