import { useEffect, useRef, useState } from 'react';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';
import Loading from '~/components/Loading';
import { Button } from '~/components/NexAdmin/Buttons/index';
import Counter from '~/components/NexAdmin/Counter';
import { Select, Input } from '~/components/NexAdmin/Inputs/index';
import Table from '~/components/NexAdmin/Table';
import { Form } from '@unform/web';
import * as S from './styles';
import moment from 'moment/moment';
import api from '~/services/api';
import {
  appendObjectInFormData,
  transformArrayIntoSelectOptions,
} from '~/utils';
import { parseJwt } from '~/services/auth';
import columns from './columns';
import options from './options';
import { HTTPErrorCallback } from '~/Helpers/Error';
import Download from './Components/Download';
import DownloadHook from '~/hooks/NexAdmin/DownloadHook';
import { toDateTime } from '~/Helpers/Date';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import SalesDescription from '~/components/NexAdmin/Modal/Descriptions/SalesDescriptions/salesDescription';
import { Link, useLocation } from 'react-router-dom';

const Sales = () => {
  const location = useLocation();
  const current_user = parseJwt();
  const formRef = useRef();
  const { openModal } = useModal();

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [sales, setSales] = useState([]);
  const [files, setFiles] = useState([]);
  const [loadingText] = useState('');
  const [initialDate] = useState(
    moment(new Date()).subtract(1, 'month').format('YYYY-MM-DD'),
  );
  const [finalDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [search] = useState(location.state?.cpf);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileId, setSelectedFileId] = useState(null);
  useEffect(() => {
    if (
      current_user.usu_nivel > 3 &&
      (current_user?.canal_id === 2 || current_user?.canal_id === 4)
    ) {
      getValidationFiles();
    }
  }, [current_user?.canal_id]);

  useEffect(() => {
    if (search) {
      handleSubmit({ cpf: search });
    }
  }, [search]);

  useEffect(() => {
    if (current_user.usu_nivel > 3 && selectedFileId) {
      searchFileSales();
    }
  }, [selectedFileId]);

  const handleSubmit = (data) => {
    setLoading(true);
    const form = new FormData();
    appendObjectInFormData(form, {
      channel_id: current_user.canal_id,
      user_level: current_user.usu_nivel,
      initial_date: initialDate,
      final_date: finalDate,
      regional: current_user.usu_regional,
      ...data,
    });
    const params = new URLSearchParams(form);
    api
      .get(`/sales/?${params}`)
      .then((response) => {
        const { count, sales_found } = response.data;
        setCount(count);
        setSales(sales_found);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };
  const handleDownload = () => {
    const data = formRef.current.getData();
    const form = new FormData();
    appendObjectInFormData(form, {
      channel_id: current_user.canal_id,
      user_level: current_user.usu_nivel,
      initial_date: initialDate,
      final_date: finalDate,
      regional: current_user.usu_regional,
      ...(selectedFileId && { file_id: selectedFileId }),
      download: true,
      ...data,
    });
    const params = new URLSearchParams(form);

    if (selectedFileId) {
      setSelectedFile(null);
      setSelectedFileId(null);
    }

    return api.get(
      selectedFileId ? `/sales/file-sales?${params}` : `/sales/?${params}`,
      { responseType: 'blob' },
    );
  };

  const buildReportName = (name) => {
    return `Relatório ${name} - Canal ${localStorage.getItem(
      'canalNome',
    )} - ${toDateTime(new Date())}`;
  };

  const beforeLoading = () => setLoading(true);

  const afterLoading = () => setLoading(false);

  const { execute, ref, urlFile, nameFile } = DownloadHook({
    externalCall: handleDownload,
    onError: HTTPErrorCallback,
    getFileName: buildReportName(
      parseJwt().canal_id === 8 ? 'Pontuação' : 'Vendas',
    ),
    beforeLoading,
    afterLoading,
  });

  const switchColumn = (channel_id) => {
    switch (Number(channel_id)) {
      case 2:
        return columns[2];
      case 4:
        return columns[4];
      case 5:
        return columns[5];
      case 6:
        return columns[6];
      case 8:
        return columns[8];
      default:
        return [];
    }
  };

  const switchOptions = (channel_id) => {
    switch (Number(channel_id)) {
      case 5:
        return options['recarga'];
      default:
        return options['all'];
    }
  };

  const openThermsModal = () => {
    openModal({
      title: 'Vendas',
      close: true,
      content: <SalesDescription />,
    });
  };

  const getValidationFiles = () => {
    api
      .get('/sales/history')
      .then((response) => {
        setFiles(
          transformArrayIntoSelectOptions(
            response.data,
            (item) =>
              `${item.id} - ${item.original_name} - ${moment(
                item.created_at,
              ).format('DD/MM/YYYY HH:mm')}`,
            (item) => item.id,
          ),
        );
      })
      .catch((error) => {
        HTTPErrorCallback(error);
      });
  };

  const searchFileSales = () => {
    setLoading(true);
    api
      .get('/sales/file-sales?file_id=' + selectedFileId)
      .then((response) => {
        const { count, sales_found } = response.data;
        setCount(count);
        setSales(sales_found);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loading text={loadingText} />}
      <HeaderWrapper>
        <StatusTag
          icon="actions"
          title={
            parseJwt().canal_id === 8
              ? 'Relatório de Pontuação'
              : 'Relatório de Vendas'
          }
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            {search && (
              <>
                <Button>
                  <Link
                    to={{
                      pathname: '/admin/user_registration',
                      state: { backFromSales: search },
                    }}
                    style={{ textDecoration: 'none', color: '#323133' }}
                  >
                    Voltar
                  </Link>
                </Button>
                <br />
                <br />
              </>
            )}
            <Form ref={formRef} onSubmit={handleSubmit}>
              <S.FormWrapper>
                <S.FormFilters>
                  <Input
                    name="initial_date"
                    label="De:"
                    type="date"
                    defaultValue={initialDate}
                  />
                  <Input
                    name="final_date"
                    label="Até:"
                    type="date"
                    defaultValue={finalDate}
                  />
                  <Input name="cpf" label="CPF:" type="number" />
                  <Input
                    label="Código de Venda:"
                    name="sale_id"
                    type="number"
                  />
                  {current_user.canal_id !== 6 &&
                  current_user.canal_id !== 8 ? (
                    <Select
                      name="status"
                      options={[...switchOptions(current_user.canal_id)]}
                      label="Ponto Status:"
                      minWidth="140px"
                    />
                  ) : (
                    ' '
                  )}
                  {current_user.usu_nivel > 3 &&
                  (current_user.canal_id === 2 ||
                    current_user.canal_id === 4) ? (
                    <Select
                      name="file"
                      options={files}
                      value={selectedFile}
                      label="Arquivo de Validação:"
                      onChange={(e) => {
                        setSelectedFileId(e.value);
                        setSelectedFile(e);
                      }}
                      minWidth="210px"
                    />
                  ) : (
                    ' '
                  )}
                </S.FormFilters>
                <Button type="submit">Consultar</Button>
              </S.FormWrapper>
              <S.DownloadWrapper>
                <a href={urlFile} download={nameFile} ref={ref} />
                <Download buttonName="Download" external={execute} />
              </S.DownloadWrapper>
            </Form>
            <Counter total={count} />
            <Table
              loading={loading}
              data={sales}
              columns={[...switchColumn(current_user.canal_id)]}
              pagination={true}
              header
              dropdownItems={false}
              hasDownload={false}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default Sales;
