import styled from 'styled-components';
// import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
export const Controls = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;
export const Arrow = styled.button`
  width: 30px;
  cursor: pointer;
  position: relative;
  height: 18px;
  border-radius: 50px;
  margin: 1px;
  background: linear-gradient(178.18deg, #ff0000 -13.56%, #760000 158.3%);
  &::before {
    content: '';
    width: 5px;
    height: 5px;
    position: absolute;
    border-left: 1px solid white;
    border-top: 1px solid white;

    transform: translateX(-50%)
      translateY(${(props) => (props.down ? '-50%' : '-25%')})
      rotate(${(props) => (props.down ? '-135deg' : '45deg')});
  }
`;
export const Down = styled.button``;

export const Input = styled.input`
  width: 40px;
`;
