import getIcon from '~/Helpers/getIcon';
import * as S from './styles';
import { useState } from 'react';

const Notification = ({ was_read, content, goTo, date }) => {
  return (
    <S.Wrapper>
      <S.BellWrapper>
        {getIcon('notificationBell')}
        <S.Bell>{!was_read && <S.BellBall />}</S.Bell>
      </S.BellWrapper>
      <S.ContentWrapper>
        <S.ContentTitle>{content}</S.ContentTitle>
        <S.ContentInteractionWrapper>
          <S.ContentInteractionInfo>{date}</S.ContentInteractionInfo>
          <S.ContentInteractionInfo onClick={goTo}>
            Ver mais
          </S.ContentInteractionInfo>
        </S.ContentInteractionWrapper>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default Notification;
