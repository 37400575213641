import styled from 'styled-components';
import media from 'styled-media-query';

export const Hamburguer = styled.button`
  padding: 20px;
  padding-left: 0;
  background: none;
  border: none;
  ${media.greaterThan('medium')`
    display: none;
  `}
`;

export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  ${media.greaterThan('medium')`
    display: none;
  `}
`;
export const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  ${media.greaterThan('medium')`
    display: none;
  `}
`;

export const NotificationAmount = styled.div`
  position: absolute;
  top: -10px;
  right: -13px;
  display: flex;
  span {
    color: #fff;
    background: ${({ theme }) => theme.colors.degrade};
    position: absolute;
    right: 0;
    width: 20px;
    height: 20px;
    border: 2px solid white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
  }
  ${media.greaterThan('medium')`
    display: none;
  `}
`;

export const Sidebarra = styled.div`
  position: fixed;
  top: 0px;
  z-index: 20;
`;
export const Container = styled.header`
  grid-area: header;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  z-index: 21;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  transform: translate(0, 0%);
  padding: 20px 30px;
  height: 144px;

  ${media.greaterThan('medium')`
  padding: 0 100px;
  position: fixed;
  transform: translate(0, 0%);
  top: 0;
  height: 110px;
  `}
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.grayDarker};
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
  &:not(:last-child) {
    border-right: 1px solid #000000;
    padding-right: 20px;
  }
`;

export const Border = styled.div`
  border-left: 1px solid #000000;
  padding-left: 30px;
  margin-left: 30px;
  font-size: 1.4rem;
`;

export const NotificationWrapperBrowser = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  gap: 10px;
  ${media.lessThan('medium')`
    display: none;
  `}
`;

export const NotificationAmountBrowser = styled.div`
  position: absolute;
  top: -14px;
  right: -13px;
  display: flex;
  span {
    color: #fff;
    background: ${({ theme }) => theme.colors.degrade};
    position: absolute;
    right: 0;
    width: 24px;
    height: 24px;
    border: 2px solid white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
  }
  ${media.lessThan('medium')`
    display: none;
  `}
`;
