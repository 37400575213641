const moment = require('moment');

export const columns = [
  {
    name: 'Ticket ID',
    selector: (row) => row.id,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => row.id,
  },
  {
    name: 'Canal',
    selector: (row) => row.canal_nome,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => row.canal_nome,
  },
  {
    name: 'Origem da Ocorrência',
    selector: (row) => row.origin ?? 'N/A',
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => row.origin ?? 'N/A',
  },
  {
    name: 'Nome',
    selector: (row) => row.usu_nome,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => row.usu_nome,
  },
  {
    name: 'CPF/CNPJ',
    selector: (row) => row.usu_cpf,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => row.usu_cpf,
  },
  {
    name: 'Cargo',
    selector: (row) => row.cargo_nome,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => row.cargo_nome,
  },
  {
    name: 'Status',
    selector: (row) => row.id,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => row.status,
  },
  {
    name: 'Status SAC',
    selector: (row) => row.id,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (row.status_admin ? row.status_admin : 'N/A'),
  },

  {
    name: 'Categoria',
    selector: (row) => row.id,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => row.open_reason,
  },
  {
    name: 'Prazo Resolução',
    selector: (row) => row.id,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) =>
      row.resolution_deadline === '00/00/0000'
        ? 'N/A'
        : row.resolution_deadline,
  },
  {
    name: 'Data Abertura Ticket',
    selector: (row) => row.created_at,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => row.created_at,
  },
  {
    name: 'Resumo da Ocorrência',
    selector: (row) => row.id,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => row.finished_reason,
  },
];

export const status_column = [
  { value: 'Pendente', label: 'Pendente' },
  { value: 'Em Andamento', label: 'Em Andamento' },
  { value: 'Finalizado', label: 'Finalizado' },
];

export const origin_column = [
  { value: 'Email', label: 'Email' },
  { value: 'Whatsapp', label: 'Whatsapp' },
  { value: 'Site', label: 'Site' },
];
