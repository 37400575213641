import api from '~/services/api';
import { HTTPErrorCallback } from '~/Helpers/Error';
import ConfirmButton from '~/components/NexAdmin/Buttons/ConfirmButton';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import { showNotification } from '~/utils/notificationBoxes';
import GNModal from './modal';

const Actions = ({ row, dinamycState, setLoading, refresh }) => {
  const { openModal, hideModal } = useModal();

  const openThermsModal = () => {
    openModal({
      title: 'Informe o Motivo de Recusa',
      close: true,
      content: (
        <GNModal
          setLoading={setLoading}
          hideModal={hideModal}
          row={row}
          refresh={refresh}
        />
      ),
    });
  };

  const aprovedTransaction = () => {
    setLoading(true);
    api
      .put(`/lad/default/admin/transactions/${row.id}`, {
        new_status: 3,
      })
      .then((response) => {
        setLoading(false);
        showNotification('success', 'Sucesso!', `${response.data.message}`);
        refresh();
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  const preaprovedTransaction = () => {
    setLoading(true);
    api
      .put(`/lad/default/admin/transactions/${row.id}`, {
        new_status: 2,
      })
      .then((response) => {
        setLoading(false);
        showNotification('success', 'Sucesso!', `${response.data.message}`);
        refresh();
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  return (
    <>
      {dinamycState != 3 || dinamycState != 4 ? (
        <ConfirmButton
          icon={'trash'}
          iconColor={'red'}
          label="REPROVAR CAMPANHA?"
          confirmLabel={'SIM'}
          cancelLabel="CANCELAR"
          openOnRight={true}
          confirmAction={() => openThermsModal()}
        />
      ) : (
        ' '
      )}
      {dinamycState == 2 && (
        <ConfirmButton
          icon={'config'}
          iconColor={'green'}
          label="APROVAR CAMPANHA?"
          confirmLabel={'SIM'}
          cancelLabel="CANCELAR"
          openOnRight={true}
          confirmAction={() => aprovedTransaction()}
        />
      )}
      {dinamycState == 1 && (
        <ConfirmButton
          icon={'config'}
          iconColor={'green'}
          label="PRÉ-APROVAR CAMPANHA?"
          confirmLabel={'SIM'}
          cancelLabel="CANCELAR"
          openOnRight={true}
          confirmAction={() => preaprovedTransaction()}
        />
      )}
    </>
  );
};

export default Actions;
