import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: grid;
  margin: 0 auto;
  place-items: center;
  grid-gap: 20px;
  width: 500px;

  ${media.lessThan('1000px')`
  width: 100%;
  `}

  h2 {
    font-size: ${({ theme }) => theme.adminfontsize.containertitle};
    color: ${({ theme }) => theme.admincolors.contrast.black};
    text-align: center;
  }

  button {
    ${media.lessThan('1000px')`
      width: 100%;
    `}
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 40px;

  button {
    width: 80%;
  }
`;

export const ErrorsWrapper = styled.div`
  background-color: ${({ theme }) => theme.admincolors.grayScale.gray30};
  color: ${({ theme }) => theme.admincolors.contrast.black};
  border-radius: 8px;
  max-height: 500px;
  overflow-x: auto;
  padding: 20px;
  width: 100%;

  ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
    list-style: inside;
    font-size: ${({ theme }) => theme.adminfontsize.p};
  }

  label {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 10px;
    cursor: pointer;
  }

  input:checked span {
    text-decoration: line-through;
  }
`;
