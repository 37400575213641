import React from 'react';

const HideOff = ({ color }) => {
  return (
    <svg
      width="26"
      height="18"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.85825 5.5L12.4999 8.13333V8C12.4999 7.33696 12.2365 6.70107 11.7677 6.23223C11.2988 5.76339 10.663 5.5 9.99992 5.5H9.85825ZM6.27492 6.16667L7.56658 7.45833C7.52492 7.63333 7.49992 7.80833 7.49992 8C7.49992 8.66304 7.76331 9.29893 8.23215 9.76777C8.70099 10.2366 9.33688 10.5 9.99992 10.5C10.1833 10.5 10.3666 10.475 10.5416 10.4333L11.8333 11.725C11.2749 12 10.6583 12.1667 9.99992 12.1667C8.89485 12.1667 7.83504 11.7277 7.05364 10.9463C6.27224 10.1649 5.83325 9.10507 5.83325 8C5.83325 7.34167 5.99992 6.725 6.27492 6.16667V6.16667ZM1.66659 1.55833L3.56659 3.45833L3.94159 3.83333C2.56659 4.91667 1.48325 6.33333 0.833252 8C2.27492 11.6583 5.83325 14.25 9.99992 14.25C11.2916 14.25 12.5249 14 13.6499 13.55L14.0083 13.9L16.4416 16.3333L17.4999 15.275L2.72492 0.5L1.66659 1.55833ZM9.99992 3.83333C11.105 3.83333 12.1648 4.27232 12.9462 5.05372C13.7276 5.83512 14.1666 6.89493 14.1666 8C14.1666 8.53333 14.0583 9.05 13.8666 9.51667L16.3083 11.9583C17.5583 10.9167 18.5583 9.55 19.1666 8C17.7249 4.34167 14.1666 1.75 9.99992 1.75C8.83325 1.75 7.71658 1.95833 6.66658 2.33333L8.47492 4.125C8.94992 3.94167 9.45825 3.83333 9.99992 3.83333Z"
        fill={color}
      />
    </svg>
  );
};
export default HideOff;
