import 'dotenv/config';
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  headers: {
    Authorization: `Bearer ${
      localStorage.getItem('userToken') ||
      localStorage.getItem('adminUserToken')
    }`,
    IsMirror: localStorage.getItem('adminUserToken') ? 'true' : 'false',
  },
});

export default api;
