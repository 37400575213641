import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  padding: 20px;
  ${media.greaterThan('medium')`
padding: 0;
`}
`;

export const Data = styled.div`
  margin-top: 20px;
  ${media.greaterThan('medium')`
.search {
  display: flex;
  align-items: center;

  span {
      white-space: nowrap;
      margin-right: 10px;
      font-weight: 700;
      font-size: 1.6rem;
      color: ${({ theme }) => theme.colors.grayDarker};
  }
}
`};
`;

export const DataHeader = styled.div`
  margin-bottom: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-grow: 3; */
  margin-bottom: 40px;
  flex-direction: column;
  ${media.greaterThan('medium')`
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  `};
  form {
    display: flex;
    align-items: center;
    // z-index: 100;
    width: 340px;

    svg {
      transform: rotate(-90deg);
      fill: ${({ theme }) => theme.colors.grayDarker};
    }

    span {
      margin-right: 10px;
      font-weight: 700;
      font-size: 1.6rem;
      color: ${({ theme }) => theme.colors.grayDarker};
    }
  }
  p {
    font-size: 1.4rem;
  }
`;
export const DataWrapper = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

  ${media.greaterThan('medium')`
  justify-content: inherit;

  flex-direction: column;
  &.row {
    flex-direction: row;
  }

`};
`;
export const SubmitButton = styled.button`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 25px;
  border: 0;
  padding: 10px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  max-height: 50px;
  width: 150px;
  ${({ mt }) =>
    mt &&
    css`
      margin-top: 20px;
    `}

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const ButtonContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
  button {
    background: ${({ theme }) => theme.colors.degrade};
    border-radius: 25px;
    border: 0;
    padding: 10px 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 400;
    font-size: 1.4rem;
    transition: box-shadow 0.2s;
    text-decoration: none;
    cursor: pointer;
  }

  ${media.greaterThan('medium')`
flex-direction: row;
`}
`;

export const CreationForm = styled.div`
  margin-top: 30px;
`;
export const CreationFormTitle = styled.h3`
  text-align: center;
  font-size: ${({ theme }) => theme.font.sizes.small};
  color: ${({ theme }) => theme.colors.red};
  font-weight: ${({ theme }) => theme.font.medium};

  font-weight: 700;
`;
export const CreationRules = styled.div`
  border: 1px solid #c50101;
  width: 100%;
  border-radius: 10px;
  margin: 20px 0px;
  padding: 15px 20px;
`;
export const CreationRulesTitle = styled.h3`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.colors.red};
  margin-bottom: 10px;
`;
export const CreationRulesText = styled.p`
  //styleName: Paragrafo;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #858585;
`;
export const DescriptionInput = styled.textarea`
  border: 1px solid #484848;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border-radius: 10px;
  height: 170px;
  min-height: 120px;
  margin: 20px 0px;
  padding: 15px 20px;

  //styleName: Paragrafo;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${media.greaterThan('medium')`
flex-direction: row;
justify-content: center;
`}
`;
export const InputWrapper = styled.div`
  padding: 10px 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 20px 0px;
  ${media.greaterThan('medium')`
 
 width: auto;
 margin: 0px 0px;
`}

  span {
    //styleName: Paragrafo;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #484848;
  }
`;
export const Select = styled.select`
  border: 1px solid #484848;
  box-sizing: border-box;
  border-radius: 63px;
  padding: 11px 17px;
  margin-top: 5px;
  width: 200px;
  max-width: 100%;
`;
export const PointsInput = styled.input`
  border: 1px solid #484848;
  box-sizing: border-box;
  border-radius: 63px;
  padding: 11px 17px;
  margin-top: 5px;
  width: 200px;
  max-width: 100%;
`;

export const DateInput = styled.input`
  border: 1px solid #484848;
  box-sizing: border-box;
  border-radius: 63px;
  padding: 11px 17px;
  width: 200px;
  max-width: 100%;

  margin-top: 5px;
`;

export const DatesWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  ${media.greaterThan('medium')`
flex-direction: row;
* + div {
margin-left: 20px;
}
`}
`;

export const DatesText = styled.div`
  display: block;
  text-align: center;
`;
