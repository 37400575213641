import * as S from '../styles';

const CampagnsGNDescription = ({ isLAD }) => {
  return (
    <S.ThermWrapper>
      <br />
      Esta tela exibe o extrato de todas as campanhas criadas por{' '}
      {isLAD ? 'LAD' : 'GN'} filtrado pelo status da campanha.
      <br />
      Nesta tela é feito o gerenciamento das campanhas.
      <br />
      <br />
      Os dados estão distribuidos em quatro abas distintas. Navegue entre as
      abas <strong>Pendentes</strong>, <strong>Pré-Aprovados</strong>,{' '}
      <strong>Aprovados</strong> e <strong>Reprovados</strong>.
      <br />
      <br />
      Para gerenciar as campanhas, clique sobre a linha da tabela que deseja
      analisar.
      <br />
      Ao efetuar esse passo, você é direcionado para a página de gerenciamento
      de campanhas.
      <br />
      <br />A página de gerenciamento de campanha contém informações sobre o{' '}
      {isLAD ? 'LAD' : 'GN'} e as campanhas criadas.
      <br />
      As colunas da tabela desta página são as mesmas da página "Visão Geral"
      <br />
      <br />
      Personalize sua busca pelos botões de filtros na tela e então clique em
      “Consultar”.
      <br />
      <br />
      Caso queria fazer o download do relatório, primeiro execute o passo acima
      e, após isso, clique no botão “Download”.
      <br />
      <br />
      Após os botões de filtro e botões de ação, os dados são exibidos em uma
      tabela. É descrito a quantidade de campanhas que foram encontradas em sua
      busca e se todos estão sendo exibidos.
      <br />
      <br />
      Através da coluna Ações, o administrador pode configurar o andamento da
      campanha.
      <br />
      <br />
      O ícone vermelho é utilizado para reprovar uma campanha. Já o ícone verde
      é utilizado para pré-aprovar ou aprovar uma campanha.
      <br />
      <br />
      Utilize a caixa de diálogo para confirmar sua ação. Ao reprovar uma
      campanha, é possível informar um motivo de recusa.
      <br />
      <br />
      Caso sua pesquisa encontre mais de 100 resultados, é necessário que faça o
      download do relatório para visualizar todos os produtos encontrados.
      <br />
      <br />
      <strong>Descritivo dos filtros:</strong>
      <br />
      <strong>CPF:</strong> CPF do usuário
      <br />
      <strong>Regional {isLAD ? 'LAD' : 'GN'}:</strong> Regional do usuário.
      Utilize para buscar todas as campanhas de uma determinada regional
      <br />
      <strong>PDV:</strong> PDV do usuário. Utilize para buscar todas as
      campanhas de um determinado ponto de venda
      <br />
      <br />
      <strong>Descritivo das colunas da tabela:</strong>
      <br />
      <strong>1. ID {isLAD ? 'LAD' : 'GN'}:</strong> ID do usuário{' '}
      {isLAD ? 'LAD' : 'GN'}. Valor único em toda a tabela
      <br />
      <strong>2. Nome:</strong> Nome do usuário
      <br />
      <strong>3. CPF:</strong> CPF do usuário
      <br />
      <strong>4. Regional:</strong> Regional do usuário
      <br />
      <strong>5. PDV:</strong> Ponto de venda do usuário
      <br />
      <strong>6. Rede:</strong> Rede do usuário, se aplicável
      <br />
      <strong>
        7. Campanhas Pendentes/ Campanhas Pré-Aprovadas/ Campanhas Aprovadas/
        Campanhas Reprovadas:
      </strong>{' '}
      Quantidade <strong>total</strong> de campanhas de todo o canal em seu
      respectivo status
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default CampagnsGNDescription;
