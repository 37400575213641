import styled from 'styled-components';
import media from 'styled-media-query';
import PostItem from '~/screens/Gigaline/components/PostItem';

export const PostsWrapper = styled.div`
  > .comentary {
    font-size: 1.4rem;

    padding: 10px 16px;

    ${media.greaterThan('medium')`
      padding: 0 20px 20px;
    `};
  }
  header {
    display: flex;
    align-items: center;
    padding: 10px 16px;
  }

  li {
    button {
      background: red;
    }
  }

  .admin-delete-button {
    margin-left: 60%;
    background: red;
    border-radius: 25px;
    border: 0;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 400;
    font-size: 1.4rem;
    transition: box-shadow 0.2s;
    text-decoration: none;
    cursor: pointer;
  }

  figure {
    img {
      width: 60px;
      height: 60px;
    }
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.admincolors.grayDarker};

    &:hover {
      color: ${({ theme }) => theme.admincolors.red};
    }
  }

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1.1rem;
  }
`;
