import { Form } from '@unform/web';
import * as S from './styles';
import { useState } from 'react';
import Loading from '~/components/Loading';
import {
  Input,
  InputUpload,
  Select,
  ToggleSwitch,
} from '~/components/NexAdmin/Inputs/index';
import UseQuizzes from '~/hooks/NexAdmin/QuizzesHook';
import { Button } from '~/components/NexAdmin/Buttons';
import Title from '../../Components/Title';

const Create = () => {
  const [_, setFile] = useState(null);
  const [active, setActive] = useState(true);
  const [points, setPoints] = useState(null);
  const [percentagePoints, setPercentagePoints] = useState(null);
  const { channelData, quizRef, newQuiz, searchDefaultValues } = UseQuizzes();
  return (
    <>
      <Title title="Configuração do Treinamento" />
      <Form ref={quizRef}>
        <S.Wrapper>
          <S.InputWrapper>
            <Input
              label="Nome do Treinamento"
              name="title"
              defaultValue={newQuiz.title}
              required
            />
            <Input
              label="Pontos"
              name="points"
              type="number"
              defaultValue={newQuiz.points}
              onChange={(e) => setPoints(e.target.value)}
              value={points}
            />
            {(points || newQuiz.points) && (
              <Input
                label="Percentual de acerto para receber a pontuação"
                name="points_percentage"
                type="number"
                defaultValue={newQuiz.points_percentage}
                value={percentagePoints || 0}
                onChange={(e) =>
                  setPercentagePoints(
                    e.target.value <= 100 ? e.target.value : percentagePoints,
                  )
                }
              />
            )}
            <Input
              label="Descrição"
              name="description"
              type="text"
              defaultValue={newQuiz.description}
              required
              textArea
            />
            <ToggleSwitch
              name="active"
              label="Ativo?"
              defaultChecked={newQuiz?.active}
              onChange={(e) => setActive(e.target.checked)}
              value={active}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            {channelData.role && (
              <Select
                isMulti
                name="role_id"
                options={channelData.role}
                defaultValue={searchDefaultValues('role', 'role_id')}
                label="Cargos"
                large
                placeholder="Selecione os cargos"
              />
            )}
            {channelData.regional && (
              <Select
                isMulti
                name="regional"
                options={channelData.regional}
                defaultValue={searchDefaultValues('regional', 'regional')}
                label="Regional:"
                placeholder="Selecione a regional"
                large
                maxWidth="400px"
              />
            )}
            {channelData.net && (
              <Select
                isMulti
                name="net"
                options={channelData.net}
                defaultValue={searchDefaultValues('net', 'net')}
                label="Rede:"
                placeholder="Selecione a rede"
                large
                maxWidth="400px"
              />
            )}
            {channelData.pdv && (
              <Select
                isMulti
                name="pdv"
                options={channelData.pdv}
                defaultValue={searchDefaultValues('pdv', 'pdv')}
                label="PDV:"
                placeholder="Digite um pdv"
                large
                maxWidth="400px"
              />
            )}
            <InputUpload
              mt="10px"
              onGetFile={setFile}
              placeholder="Selecione um material"
              accept="application/pdf"
              name="file"
            />
          </S.InputWrapper>
        </S.Wrapper>
      </Form>
    </>
  );
};

export default Create;
