import Button from '~/components/NexAdmin/Buttons/Button';
import InputUpload from '~/components/NexAdmin/Inputs/InputUpload';
import { HTTPErrorCallback } from '~/Helpers/Error';
import getIcon from '~/Helpers/getIcon';
import api from '~/services/api';
import { showNotification } from '~/utils/notificationBoxes';
import { useState, useEffect } from 'react';
import DownloadStaticFile from '~/components/NexAdmin/DownloadStaticFile';
import { appendObjectInFormData } from '~/utils';
import { parseJwt } from '~/services/auth';
import Table from '~/components/NexAdmin/Table/index.js';
import * as S from './styles';
import Download from '~/screens/NexAdmin/BonusPoints/Screens/Create/Components/Download/index';
import {
  columns_insert_mass_report,
  columns_approve_mass_report,
  columns_repprove_mass_report,
} from '../../columns';
import { buildReportName } from '~/utils/NexAdmin/utils';
import { Form } from '@unform/web';

const BaseBatches = ({
  setLoading,
  info,
  columns,
  list,
  fileName,
  option,
  title,
  step,
  setStep,
  current,
}) => {
  const [file, setFile] = useState(null);
  const [insert, setInsert] = useState([]);
  const [approve, setApprove] = useState([]);
  const [repprove, setReprove] = useState([]);
  const [errors, setErrors] = useState([]);
  const current_user = parseJwt();

  useEffect(() => {
    setStep('validate');
    setFile(null);
  }, [current]);

  const handleInsertMass = () => {
    if (!file)
      return showNotification(
        'warning',
        'Atenção',
        'Por favor, selecione um arquivo',
      );
    setLoading(true);
    const formData = new FormData();
    appendObjectInFormData(formData, {
      channel_id: current_user.canal_id,
      sheet: file,
    });
    api
      .post('/gn/default/admin/batch/insert', formData)
      .then((response) => {
        const { data_to_insert, errors } = response.data;
        if (data_to_insert && data_to_insert.length) {
          setInsert(data_to_insert);
        }
        if (errors && errors.length) {
          setErrors(errors);
        }
        setFile(file);
        setLoading(false);
        setStep('report');
      })
      .catch((error) => {
        setFile(null);
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };
  const handleApproveMass = () => {
    if (!file)
      return showNotification(
        'warning',
        'Atenção',
        'Por favor, selecione um arquivo',
      );
    setLoading(true);
    const formData = new FormData();
    appendObjectInFormData(formData, {
      channel_id: current_user.canal_id,
      operation: 'approve',
      sheet: file,
    });
    api
      .post('/gn/default/admin/batch/approve-or-reprove', formData)
      .then((response) => {
        const { data_to_insert, errors } = response.data;
        if (data_to_insert && data_to_insert.length) {
          setApprove(data_to_insert);
        }
        if (errors && errors.length) {
          setErrors(errors);
        }
        setFile(file);
        setLoading(false);
        setStep('report');
      })
      .catch((error) => {
        setFile(null);
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };
  const handleReproveMass = () => {
    if (!file)
      return showNotification(
        'warning',
        'Atenção',
        'Por favor, selecione um arquivo',
      );
    setLoading(true);
    const formData = new FormData();
    appendObjectInFormData(formData, {
      channel_id: current_user.canal_id,
      operation: 'repprove',
      sheet: file,
    });
    api
      .post('/gn/default/admin/batch/approve-or-reprove', formData)
      .then((response) => {
        const { data_to_insert, errors } = response.data;
        if (data_to_insert && data_to_insert.length) {
          setReprove(data_to_insert);
        }
        if (errors && errors.length) {
          setErrors(errors);
        }
        setFile(file);
        setLoading(false);
        setStep('report');
      })
      .catch((error) => {
        setFile(null);
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };
  const switchExternal = (op = null) => {
    switch (option) {
      case 'insert':
        if (op === 'data') {
          return { data: insert, column: columns_insert_mass_report };
        }
        return handleInsertMass();
      case 'approve':
        if (op === 'data') {
          return { data: approve, column: columns_approve_mass_report };
        }
        return handleApproveMass();
      case 'reprove':
        if (op === 'data') {
          return { data: repprove, column: columns_repprove_mass_report };
        }
        return handleReproveMass();
    }
  };
  const current_data = switchExternal('data');
  const switchStep = () => {
    switch (step) {
      case 'validate':
        return (
          <>
            <S.Wrapper>
              {info.split('.').map((item) => (
                <h2>{item}</h2>
              ))}
              <br />
              <h2>Selecione um arquivo para processamento</h2>
              <S.InputWrapper>
                <Form>
                  <InputUpload
                    name="arquivo"
                    onGetFile={setFile}
                    placeholder={
                      <>{getIcon('upload')} Selecione um arquivo para upload</>
                    }
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </Form>
                <Button type="button" width="50%" onClick={switchExternal}>
                  Enviar
                </Button>
              </S.InputWrapper>
            </S.Wrapper>
            <DownloadStaticFile
              columns={columns}
              list={list}
              file_name={fileName}
              button_name={'Download Modelo'}
            />
          </>
        );
      case 'report':
        return (
          <>
            <S.Wrapper>
              <Download
                rawData={new Array(insert, approve, repprove, errors).flat()}
                columns={current_data.column}
                fileName={buildReportName(title)}
                buttonName="Download"
                dropdownItems={false}
              />
            </S.Wrapper>
            <S.TableWrapper>
              {current_data.data.length ? (
                <>
                  <h2>Os dados abaixo foram processados com sucesso.</h2>
                  <Table
                    columns={current_data.column}
                    data={current_data.data}
                    dataTableProps={{ pagination: true }}
                  />
                </>
              ) : (
                ' '
              )}
              {errors.length ? (
                <>
                  <h2>Os dados abaixo contem erros e não foram processados.</h2>
                  <Table
                    columns={current_data.column}
                    data={errors}
                    dataTableProps={{ pagination: true }}
                  />
                </>
              ) : (
                ' '
              )}
            </S.TableWrapper>
          </>
        );
    }
  };
  return <>{switchStep()}</>;
};

export default BaseBatches;
