export const states_list = [
  { label: 'Todos', value: 'all' },
  { label: 'Acre', value: 'ac' },
  { label: 'Alagoas', value: 'al' },
  { label: 'Amapá', value: 'ap' },
  { label: 'Amazonas', value: 'am' },
  { label: 'Bahia', value: 'ba' },
  { label: 'Ceará', value: 'ce' },
  { label: 'Distrito Federal', value: 'df' },
  { label: 'Goiás', value: 'go' },
  { label: 'Maranhão', value: 'ma' },
  { label: 'Mato Grosso do Sul', value: 'ms' },
  { label: 'Mato Grosso', value: 'mt' },
  { label: 'Minas Gerais', value: 'mg' },
  { label: 'Pará', value: 'pa' },
  { label: 'Paraíba', value: 'pb' },
  { label: 'Paraná', value: 'pr' },
  { label: 'Pernambuco', value: 'pe' },
  { label: 'Piauí', value: 'pi' },
  { label: 'Rio de Janeiro', value: 'rj' },
  { label: 'Rio Grande do Norte', value: 'rn' },
  { label: 'Rio Grande do Sul', value: 'rs' },
  { label: 'Rondônia', value: 'ro' },
  { label: 'Roraima', value: 'rr' },
  { label: 'Santa Catarina', value: 'sc' },
  { label: 'São Paulo', value: 'sp' },
  { label: 'Sergipe', value: 'se' },
  { label: 'Tocantins', value: 'to' },
];

export const regional_list = [
  { label: 'Todos', value: 'all' },
  { label: 'Nacional', value: 'Nacional' },
  { label: 'REGIONAL', value: 'REGIONAL' },
  { label: 'SP2', value: 'SP2' },
  { label: 'RJ/ES', value: 'RJ/ES' },
  { label: 'BA/SE', value: 'BA/SE' },
  { label: 'CO', value: 'CO' },
  { label: 'MG Federal', value: 'MG' },
  { label: 'PR/SC', value: 'PR/SC' },
  { label: 'NO', value: 'NO' },
  { label: 'SP1', value: 'SP1' },
  { label: 'SUL', value: 'SUL' },
  { label: 'NE', value: 'NE' },
  { label: 'RS', value: 'RS' },
];
