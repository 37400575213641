import styled from 'styled-components';

export const Loader = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${({ isComponent }) => (isComponent ? 'absolute' : 'fixed')};
  height: ${({ isComponent }) => (isComponent ? '100%' : '100vh')};
  width: 100%;
  margin: 0;
  top: 0;
  left: 0;
  z-index: ${({ isComponent }) => (isComponent ? '10' : '100')};
  background: rgba(255, 255, 255, 0.6);

  div {
    border: 8px solid white;
    border-radius: 50%;
    border-top: ${({ isAdmin }) =>
      isAdmin ? '8px solid #FFC44C' : '8px solid red'};
    width: 64px;
    height: 64px;
    filter: drop-shadow(0 0 4px #00000030);
    /* animação (troque a velocidade de acordo com os segundos) */
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;

    /* alinhamento centralizado */
    position: ${({ isComponent }) => (isComponent ? '' : 'absolute')};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
