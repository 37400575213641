import React from 'react';

const Chat = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1667 6.66659H15.8333C16.2754 6.66659 16.6993 6.84218 17.0118 7.15474C17.3244 7.4673 17.5 7.89122 17.5 8.33325V13.3333C17.5 13.7753 17.3244 14.1992 17.0118 14.5118C16.6993 14.8243 16.2754 14.9999 15.8333 14.9999H14.1667V18.3333L10.8333 14.9999H7.5C7.28111 15.0001 7.06433 14.9571 6.86211 14.8733C6.6599 14.7895 6.47623 14.6666 6.32167 14.5116L14.1667 6.66659ZM6.32167 14.5116L9.16667 11.6666H12.5C12.942 11.6666 13.3659 11.491 13.6785 11.1784C13.9911 10.8659 14.1667 10.4419 14.1667 9.99992V4.99992C14.1667 4.55789 13.9911 4.13397 13.6785 3.82141C13.3659 3.50885 12.942 3.33325 12.5 3.33325H4.16667C3.72464 3.33325 3.30072 3.50885 2.98816 3.82141C2.67559 4.13397 2.5 4.55789 2.5 4.99992V9.99992C2.5 10.4419 2.67559 10.8659 2.98816 11.1784C3.30072 11.491 3.72464 11.6666 4.16667 11.6666H5.83333V14.9999L6.32167 14.5116Z"
        fill={color}
        stroke="#071831"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default Chat;
