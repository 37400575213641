import React, { useState } from 'react';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';
import Loading from '~/components/Loading';
import UserList from './Components/UserList';
import UserData from './Components/UserData';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import UserRegistrationDescription from '~/components/NexAdmin/Modal/Descriptions/userRegistrationDescription';

const UserRegistration = () => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState('list');
  const [row, setRow] = useState({});
  const { openModal } = useModal();

  const openThermsModal = () => {
    openModal({
      title: 'Cadastros',
      close: true,
      content: <UserRegistrationDescription />,
    });
  };

  const switchStep = (current) => {
    switch (current) {
      case 'list':
        return (
          <UserList
            loading={loading}
            setLoading={setLoading}
            setStep={setStep}
            setRow={setRow}
          />
        );
      case 'data':
        return <UserData row={row} setStep={setStep} />;
    }
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="filedUser"
          title="Pesquisar Usuários"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>{switchStep(step)}</ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default UserRegistration;
