import * as S from './styles';
import { useState } from 'react';
import Notification from './Components/Notification';
import CommonRoutes from '~/routes/Components/Common/routes';
import moment from 'moment';
import UseNotification from '~/hooks/NexAdmin/NotificationHook';

const Notifications = ({
  showNotification,
  setShowNotification,
  fullPage,
  notifications,
}) => {
  const { readNotification, nextPage, loading } = UseNotification();
  const [read, setRead] = useState(true);
  const filtered = read
    ? notifications
    : notifications.filter((item) => item.was_read === 0);
  const goTo = ({ name, id, identifier }) => {
    if (id) {
      readNotification({ notification_id: id, identifier });
    }
    setShowNotification(!showNotification);
    return (window.location.href =
      CommonRoutes[name].path ?? CommonRoutes['Dashboard'].path);
  };
  return (
    <S.Container showNotification={showNotification} fullPage={fullPage}>
      <S.Wrapper fullPage={fullPage}>
        <S.Header>
          <S.Title>Notificações</S.Title>
          {!fullPage && (
            <S.Button
              content="Fechar"
              onClick={() => setShowNotification(false)}
            >
              Fechar
            </S.Button>
          )}
        </S.Header>
        <S.ButtonWrapper>
          <S.StateButton
            read={read}
            onClick={() => {
              setRead(!read);
            }}
          >
            Todos
          </S.StateButton>
          <S.StateButton
            read={!read}
            onClick={() => {
              notifications.filter((item) => item.was_read === false);
              setRead(!read);
            }}
          >
            Não Lidas
          </S.StateButton>
        </S.ButtonWrapper>
        <S.ContentWrapper>
          <S.ContentButtons>
            <S.Button onClick={() => nextPage()}>
              {loading ? <S.Loading /> : 'Anteriores'}
            </S.Button>
            {!fullPage && (
              <S.Button onClick={() => goTo({ name: 'Notifications' })}>
                ver tudo
              </S.Button>
            )}
          </S.ContentButtons>
          <S.NotificationsWrapper>
            {filtered &&
              filtered.map((item, index) => (
                <Notification
                  key={index}
                  date={moment(item.data_envio).fromNow()}
                  was_read={item.was_read}
                  goTo={() =>
                    goTo({
                      name: item.type,
                      id: item.id,
                      identifier: item.identifier,
                    })
                  }
                  content={item.corpo}
                />
              ))}
          </S.NotificationsWrapper>
        </S.ContentWrapper>
      </S.Wrapper>
    </S.Container>
  );
};

export default Notifications;
