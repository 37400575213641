import { toDateTime } from '~/Helpers/Date';
const na = 'N/A';

const column = [
  {
    name: 'Título',
    selector: (row) => row.title ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => <div data-tag="allowRowEvents">{row.title ?? na}</div>,
  },
  {
    name: 'Conteúdo',
    selector: (row) => row.content ?? na,
    sortable: true,
    cell: (row) => <div data-tag="allowRowEvents">{row.content ?? na}</div>,
  },
  {
    name: 'Status',
    selector: (row) => row.status ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => <div data-tag="allowRowEvents">{row.status ?? na}</div>,
  },
  {
    name: 'Criação',
    selector: (row) => (row.created_at ? toDateTime(row.created_at) : na),
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{toDateTime(row.created_at) ?? na}</div>
    ),
  },
  {
    name: 'Atualização',
    selector: (row) => (row.updated_at ? toDateTime(row.updated_at) : na),
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{toDateTime(row.updated_at) ?? na}</div>
    ),
  },
  {
    name: 'Canal',
    selector: (row) => row.all_channel ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => <div data-tag="allowRowEvents">{row.all_channel ?? na}</div>,
  },
];

export default column;
