import React from 'react';
import useGlobalModalStructure from '../../hooks/UseGlobalModalStructure';
import * as S from './styles';

const GlobalModalStructure = () => {
  const { modalStructureProps, removeGlobalModalStructure } =
    useGlobalModalStructure();

  return (
    <S.Wrapper open={!!modalStructureProps}>
      <S.Background
        onClick={() => {
          modalStructureProps?.closeOnClickBackground &&
            modalStructureProps?.closable &&
            removeGlobalModalStructure();

          modalStructureProps.onClose && modalStructureProps.onClose();
        }}
      />
      <S.Inner>
        <S.Body>{modalStructureProps?.children}</S.Body>
      </S.Inner>
    </S.Wrapper>
  );
};

export default GlobalModalStructure;
