import moment from 'moment';
import { PDVSCard } from '../PDVSCard/index';
import { UserInfoCard } from '../UserInfoCard/index';
import * as S from './styles';

const formatToDateTime = (date) => {
  return moment(new Date(date)).format('MM-DD-YYYY HH:mm:ss');
};

export const UserInfo = (channel, data) => {
  switch (channel) {
    case 'SIV_user':
      return GetSIVInfosComponent(data);
    case 'aparelhos_user':
      return GetAparelhosInfosComponent(data);
    case 'digital_user':
      return GetDigitalInfosComponent(data);
    case 'INOVA_user':
      return GetINOVAComponent(data);
    case 'gc_user':
      return GetGCUserComponent(data);
    case 'pap_user':
      return GetPapInfosComponent(data);
    case 'pap_pc_user':
      return GetPapPcInfosComponent(data);
    default:
      return null;
  }
};

const GetSIVInfosComponent = ({ SIV_user: user }) => {
  const { data } = user.infos;

  return (
    <S.Wrapper>
      <ol>
        <li>
          <b>Nome:</b> {data.nome ?? 'N/A'}
        </li>
        <li>
          <b>CPF:</b> {data.cpf ?? 'N/A'}
        </li>
        <li>
          <b>Canal:</b> {data.canal ?? 'N/A'}
        </li>
        <li>
          <b>Cargo:</b> {data.cargo ?? 'N/A'}
        </li>
        <li>
          <b>Ativo:</b>{' '}
          {data.ativo === '0' || data.ativo === 0 ? 'Não' : 'Sim' ?? 'N/A'}
        </li>
        <li>
          <b>PDVS:</b> {data.pdvs ? <PDVSCard PDVS={data.pdvs} /> : 'N/A'}
        </li>
      </ol>
    </S.Wrapper>
  );
};

const GetAparelhosInfosComponent = ({ aparelhos_user: user }) => {
  const { infos } = user;

  return (
    <S.Wrapper>
      <ol>
        <li>
          <b>Ativo:</b> {infos.ATIVO ?? 'N/A'}
        </li>
        <li>
          <b>Canal:</b> {infos.CANAL ?? 'N/A'}
        </li>
        <li>
          <b>Cargo:</b> {infos.CARGO || infos.Cargo || infos.cargo || 'N/A'}
        </li>
        <li>
          <b>Código agente venda:</b> {infos.COD_AGENTE_VENDA ?? 'N/A'}
        </li>
        <li>
          <b>E-mail:</b> {infos.E_MAIL ?? 'N/A'}
        </li>
        <li>
          <b>Nome Loja:</b> {infos.NOME_LOJA ?? 'N/A'}
        </li>
        <li>
          <b>Nome Vendedor:</b> {infos.NOME_VENDEDOR ?? 'N/A'}
        </li>
        <li>
          <b>Rede:</b> {infos.REDE ?? 'N/A'}
        </li>
        <li>
          <b>Regional:</b> {infos.REGIONAL ?? 'N/A'}
        </li>
        <li>
          <b>Status:</b> {infos.STATUS ?? 'N/A'}
        </li>
      </ol>
    </S.Wrapper>
  );
};

const GetDigitalInfosComponent = ({ digital_user: user }) => {
  const { infos } = user;

  return (
    <S.Wrapper>
      <ol>
        <li>
          <b>Ativo:</b> {infos.ATIVO ?? 'N/A'}
        </li>
        <li>
          <b>Canal:</b> {infos.CANAL ?? 'N/A'}
        </li>
        <li>
          <b>Cargo:</b> {infos.CARGO ?? 'N/A'}
        </li>
        <li>
          <b>E-Mail:</b> {infos.E_MAIL ?? 'N/A'}
        </li>
        <li>
          <b>Gestor:</b> {infos.GESTOR ?? 'N/A'}
        </li>
        <li>
          <b>Nome Fantasia:</b> {infos.NOME_NOME_FANTASIA ?? 'N/A'}
        </li>
        <li>
          <b>Razão Social:</b> {infos.RAZAO_SOCIAL ?? 'N/A'}
        </li>
      </ol>
    </S.Wrapper>
  );
};

const GetINOVAComponent = ({ INOVA_user: user }) => {
  const { infos } = user;

  return (
    <S.Wrapper>
      <ol>
        {Object.keys(infos || {}).map(
          (key) =>
            infos[key] &&
            key !== 'success' && (
              <li>
                <b>{key}:</b> {infos[key] ?? 'N/A'}
              </li>
            ),
        )}
      </ol>
    </S.Wrapper>
  );
};

const GetGCUserComponent = ({ gc_user: user }) => {
  const { infos } = user;

  const getUsuStatus = (usuStatus) => {
    switch (usuStatus) {
      case 3:
        return 'Cadastro em análise';
      case 2:
        return 'Edição do cadastro liberado';
      case 1:
        return 'Cadastro finalizado';
      case 0:
        return 'Sem cadastro';

      default:
        return 'N/A';
    }
  };

  return (
    <S.Wrapper>
      <ol>
        <li>
          <b>ID:</b> {infos.usu_id ?? 'N/A'}
        </li>
        <li>
          <b>Nome:</b> {infos.usu_nome ?? 'N/A'}
        </li>
        <li>
          <b>E-mail:</b> {infos.usu_email ?? 'N/A'}
        </li>
        <li>
          <b>Canal:</b> {infos.canal_nome ?? 'N/A'}
        </li>
        <li>
          <b>Cargo:</b> {infos.cargo_nome ?? 'N/A'}
        </li>
        <li>
          <b>PDV:</b> {infos.usu_pdv ?? 'N/A'}
        </li>
        <li>
          <b>Regional:</b> {infos.usu_regional ?? 'N/A'}
        </li>
        <li>
          <b>Rede:</b> {infos.usu_rede ?? 'N/A'}
        </li>
        <li>
          <b>Data aceite:</b>{' '}
          {infos.usu_dt_aceite ? formatToDateTime(infos.usu_dt_aceite) : 'N/A'}
        </li>
        <li>
          <b>Ultima tentativa de login:</b>{' '}
          {infos.usu_ultima_tentativa_login
            ? formatToDateTime(infos.usu_ultima_tentativa_login)
            : 'N/A'}
        </li>
        <li>
          <b>Status:</b> {getUsuStatus(infos.usu_status)}
        </li>
        <li>
          <b>Respostas:</b> <UserInfoCard answers={infos.usu_respostas} />
        </li>
      </ol>
    </S.Wrapper>
  );
};

const GetPapInfosComponent = ({ pap_user: user }) => {
  const { infos } = user;

  return (
    <S.Wrapper>
      <ol>
        <li>
          <b>Nome:</b> {infos.nome ?? 'N/A'}
        </li>
        <li>
          <b>Cargo:</b> {infos.cargo ?? 'N/A'}
        </li>
        <li>
          <b>CNPJ Loja:</b> {infos.cnpj_loja ?? 'N/A'}
        </li>
        <li>
          <b>Razão Social:</b> {infos.razao_social ?? 'N/A'}
        </li>
        <li>
          <b>Regional:</b> {infos.regional ?? 'N/A'}
        </li>
        <li>
          <b>Segmento:</b> {infos.segmento ?? 'N/A'}
        </li>
        <li>
          <b>Situação:</b> {infos.status ?? 'N/A'}
        </li>
        <li>
          <b>G20:</b> {infos.g20 ?? 'N/A'}
        </li>
        <li>
          <b>Equipe de Venda:</b> {infos.equipe_venda ?? 'N/A'}
        </li>
      </ol>
    </S.Wrapper>
  );
};

const GetPapPcInfosComponent = ({ pap_pc_user: user }) => {
  const { infos } = user;

  return (
    <S.Wrapper>
      <ol>
        <li>
          <b>Nome:</b> {infos.nome ?? 'N/A'}
        </li>
        <li>
          <b>Cargo:</b> {infos.cargo ?? 'N/A'}
        </li>
        <li>
          <b>CNPJ Loja:</b> {infos.cnpj_loja ?? 'N/A'}
        </li>
        <li>
          <b>Razão Social:</b> {infos.razao_social ?? 'N/A'}
        </li>
        <li>
          <b>Regional:</b> {infos.regional ?? 'N/A'}
        </li>
        <li>
          <b>Segmento:</b> {infos.segmento ?? 'N/A'}
        </li>
        <li>
          <b>Situação:</b> {infos.status ?? 'N/A'}
        </li>
        <li>
          <b>G20:</b> {infos.g20 ?? 'N/A'}
        </li>
        <li>
          <b>Equipe de Venda:</b> {infos.equipe_venda ?? 'N/A'}
        </li>
      </ol>
    </S.Wrapper>
  );
};
