import * as S from '../styles';
const ChallengeDescription = () => {
  return (
    <S.ThermWrapper>
      <br />
      Caso deseje editar um registro, clique no ícone de documento, presente na
      coluna <strong>Ações</strong>. Se deseja excluir, clique no ícone de
      lixeira, também em <strong>Ações</strong>.
      <br />
      <br />
      Para pesquisar um desafio específico preencha o campo{' '}
      <strong>Pesquisar</strong>.
      <br />
      <br />
      Para emitir o relatório clique em <strong>Download</strong>.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default ChallengeDescription;
