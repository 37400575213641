import * as S from './styles';
import getIcon from '~/Helpers/getIcon';
import CommonRoutes from '~/routes/Components/Common/routes';
import { Link } from 'react-router-dom';

const Card = ({ quiz }) => {
  const switchStatus = () => {
    switch (quiz.progress) {
      case 0:
      case 100:
        return (
          <S.Status progress={quiz.progress}>
            <S.Ball progress={quiz.progress} />
            {quiz.progress == 0 ? 'Não iniciado' : 'Concluído'}
          </S.Status>
        );
      default:
        return (
          <S.ProgressWrapper>
            <S.ProgressBar>
              <S.ProgressBarInner progress={quiz.progress} />
            </S.ProgressBar>
            <S.ProgressText>{`${quiz.progress}% concluido`}</S.ProgressText>
          </S.ProgressWrapper>
        );
    }
  };

  const switchText = () => {
    switch (quiz.progress) {
      case 0:
        return 'Começar';
      case 100:
        return 'Ver resultado';
      default:
        return 'Continuar';
    }
  };

  return (
    <>
      <S.Wrapper>
        <S.Header>
          {getIcon('trainningBook', '#484848')}
          <S.Tittle>{quiz.title}</S.Tittle>
          {switchStatus()}
        </S.Header>
        <S.Data>
          <Link
            to={{
              pathname: CommonRoutes['QuizzesTraining'].path,
              state: { quiz_id: quiz.id },
            }}
          >
            <S.Button>
              {switchText()}
              {getIcon('rightArrow', '#FFFF')}
            </S.Button>
          </Link>
        </S.Data>
      </S.Wrapper>
    </>
  );
};

export default Card;
