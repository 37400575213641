import { Form } from '@unform/web';
import { Input } from '~/components/NexAdmin/Inputs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import * as S from '../../styles';
import { useEffect, useState } from 'react';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';

import moment from 'moment';

import 'draft-js/dist/Draft.css';
import { Button } from '~/components/NexAdmin/Buttons';

const UpdateForm = ({ challenge, updateChallenge, fileRef }) => {
  const [editorState, setEditorState] = useState('');
  const [updatedChallenge, setUpdatedChallenge] = useState(challenge);

  useEffect(() => {
    const value = challenge.desafio
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(challenge.desafio)),
        )
      : EditorState.createEmpty();
    setEditorState(value);
  }, [challenge]);

  const submit = (data) => {
    const challenge = {
      ...data,
      desafio: updatedChallenge,
    };

    updateChallenge(challenge);
  };

  return (
    <>
      <Form onSubmit={submit}>
        <Input
          name="titulo"
          required
          type="text"
          label="Titulo"
          defaultValue={challenge.titulo}
        />
        <Input
          name="produtos_participantes"
          required
          type="text"
          label="Produtos participantes"
          defaultValue={challenge.produtos_participantes}
        />
        <Input
          name="digital_dt_ini"
          required
          type="date"
          label="Data Início"
          defaultValue={moment(challenge.digital_dt_ini).format('YYYY-MM-DD')}
        />
        <Input
          name="digital_dt_fim"
          required
          type="date"
          label="Data Fim"
          defaultValue={moment(challenge.digital_dt_fim).format('YYYY-MM-DD')}
        />

        <p>Desafio</p>
        <S.Editor>
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editor"
            onEditorStateChange={(e) => setEditorState(e)}
            onContentStateChange={(e) => setUpdatedChallenge(draftToHtml(e))}
          />
        </S.Editor>

        <S.Buttons>
          <input type="file" required accept="application/pdf" ref={fileRef} />
          <Button width="200px" type="submit">
            Atualizar
          </Button>
        </S.Buttons>
      </Form>
    </>
  );
};

export default UpdateForm;
