import styled from 'styled-components';
import media from 'styled-media-query';

export const FormFilters = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
  max-width: 80%;
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  button {
    margin-top: auto;
  }
`;

export const ButtonsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  grid-gap: 20px;
  max-width: 80%;
`;

export const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 20px;
  padding: 50px 10px;
`;

export const DownloadWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding-top: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  grid-gap: 20px;

  margin-bottom: 50px;

  h2 {
    font-size: ${({ theme }) => theme.adminfontsize.containertitle};
    color: ${({ theme }) => theme.admincolors.contrast.black};
  }
`;

export const InputWrapper = styled.div`
  display: grid;
  width: 100%;
  padding-bottom: 50px;
  border-bottom: 1px solid;
  place-items: center;
  ${media.lessThan('1000px')`
    width: 100%;
  `}

  .itens {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
  }

  button {
    margin: 0 auto;
  }

  label {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 10px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 40px;

  button {
    width: 80%;
  }
`;

export const ToggleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  gap: 10px;

  p {
    font-size: ${({ theme }) => theme.adminfontsize.containertitle};
    color: ${({ theme }) => theme.admincolors.contrast.black};
  }
`;
