import moment from 'moment';
const na = 'N/A';

const columns = [
  {
    name: 'Nome',
    selector: (row) => row.nome ?? na,
    sortable: true,
    grow: 1,
    minWidth: '300px',
    cell: (row) => row.nome ?? na,
  },
  {
    name: 'CPF',
    selector: (row) => row.cpf ?? na,
    sortable: true,
    grow: 1,
    minWidth: '160px',
    cell: (row) => row.cpf ?? na,
  },
  {
    name: 'Rede',
    selector: (row) => row.rede ?? na,
    sortable: true,
    grow: 1,
    cell: (row) => row.rede ?? na,
  },
  {
    name: 'Regional',
    selector: (row) => row.regional ?? na,
    sortable: true,
    grow: 1,
    cell: (row) => row.regional ?? na,
  },
  {
    name: 'Cargo',
    selector: (row) => row.cargo ?? na,
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => row.cargo ?? na,
  },
  {
    name: 'Título',
    selector: (row) => row.titulo ?? na,
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => row.titulo ?? na,
  },
  {
    name: 'Descrição',
    selector: (row) => row.descricao ?? na,
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => row.descricao ?? na,
  },
  {
    name: 'Data de envio',
    selector: (row) => new Date(row.data_envio) ?? na,
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) =>
      row.data_envio
        ? moment(new Date(row.data_envio)).format('DD/MM/YYYY hh:mm:ss')
        : 'N/A',
  },
  {
    name: 'Plataforma',
    selector: (row) => row.plataforma ?? na,
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => row.plataforma ?? na,
  },
  {
    name: 'Tipo',
    selector: (row) => row.tipo ?? na,
    sortable: true,
    grow: 1,
    minWidth: '200px',
    cell: (row) => row.tipo ?? na,
  },
];

export default columns;
