import styled from 'styled-components';

export const UserInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;

  p {
    margin-top: 0.8rem;
    margin-left: 1.5rem;
    font-family: ${({ theme }) => theme.adminfontfamily};
    font-size: ${({ theme }) => theme.adminfontsize.text};
    color: ${({ theme }) => theme.admincolors.contrast.black};
  }
`;
