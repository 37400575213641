import * as S from './styles';

const UserRegistrationDescription = () => {
  return (
    <S.ThermWrapper>
      <br />
      Esta tela exibe todos os participantes que estão cadastrados no canal,
      tendo eles realizado optin ou não.
      <br />
      <br />A funcionalidade <strong>Acesso Espelho</strong> está disponível
      apenas para usuários que possuem data de aceite(optin).
      <br />
      <br />
      Os campos de pesquisa <strong>De</strong> e <strong>Até</strong> são
      referentes a data em que os participantes foram cadastrados no sistema.
      <br />
      <br />
      Segue descritivo de alguns campos:
      <br />
      <br />
      <strong>1. Pontos Bônus:</strong> Contabilização de pontos bônus
      disponibilizados pela ferramenta admin, por vendas de mecânicas extras
      automáticas ou por bônus de aniversário.
      <br />
      <strong>2. Pontos Aprovados:</strong> Contabilização de pontos de vendas
      aprovadas.
      <br />
      <strong>3. Pontos Pendentes:</strong> Contabilização de pontos de vendas
      que estão em período de aprovação.
      <br />
      <strong>4. Pontos Resgatados:</strong> Contabilização de pontos que foram
      convertidos em pedidos na Premmiar.
      <br />
      <strong>5. Pontos Reprovados:</strong> Contabilização de pontos de vendas
      que após o período de aprovação, foram reprovadas.
      <br />
      <strong>6. Pontos Estornados:</strong> Contabilização de pontos que foram
      convertidos em pedidos na Premmiar, mas que foram estornados ao
      participante, após reprovação na solicitação de resgate.
      <br />
      <strong>7. Pontos Aniversário:</strong> Contabilização de pontos bônus que
      foram disponibilizados ao participante nas suas respectivas datas de
      aniversário, caso este tenha realizado o optin.
      <br />
      <strong>8. Saldo:</strong> Contabilização de pontos disponíveis na
      carteira do participante para realização de resgates. Estes pontos
      contemplam pontos de vendas aprovados e pontos bônus (independentemente de
      sua origem).
      <br />
      <strong>9. Pontos Bloqueados Premmiar:</strong> Contabilização de pontos
      que foram convertidos em pedidos na Premmiar, mas que ainda estão em
      processo de aprovação.
      <br />
      <br />
      Após preencher os campos e pesquisar, você pode fazer uma filtragem dos
      dados por meio do campo Pesquisar, presente abaixo dos campos da pesquisa.
      Nesse campo você pode pesquisar pelo CPF, Nome, Descrição, Pontos e
      Origem.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default UserRegistrationDescription;
