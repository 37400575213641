import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import { useState } from 'react';
import BannerForm from '../Components/BannerForm';
import Loading from '~/components/Loading';
import api from '~/services/api';
import { Button } from '~/components/NexAdmin/Buttons/index';
import getIcon from '~/Helpers/getIcon';

const UpdateBanner = ({ row, setStep }) => {
  const [loading, setLoading] = useState(false);
  const externalCall = (data) => {
    return api.put(`/banners/update/${row.dest_id}`, data);
  };
  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag icon="upload" title="Atualizar Banner" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <Button onClick={() => setStep('list')}>{getIcon('back')}</Button>
            <br />
            <br />
            <BannerForm
              row={row}
              setLoading={setLoading}
              externalCall={externalCall}
              step="update"
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default UpdateBanner;
