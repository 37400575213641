import { useState } from 'react';

import * as S from './styles';
import Input from '~/components/Input';

export const DigitSeparated = ({ digits, gridArea, margin, ...rest }) => {
  const [value, setValue] = useState(Array(digits).fill(0));

  const handleChange = (e) => {
    setValue((prev) => {
      const { name, value } = e.target;
      const newValue = [...prev];
      newValue[name] = value ? parseInt(value) : 0;
      const nextInput = document.querySelector(
        `input[name="${parseInt(name) + 1}"]`,
      );
      if (nextInput && value !== '') {
        nextInput.focus();
      }
      return newValue.join('');
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && e.target.value === '') {
      const previousInput = document.querySelector(
        `input[name="${parseInt(e.target.name) - 1}"]`,
      );
      if (previousInput) {
        previousInput.focus();
      }
    }
  };

  const handlePaste = (e) => {
    var clipboardData, pastedData;

    e.stopPropagation();
    e.preventDefault();

    clipboardData = e.clipboardData || window.clipboardData;
    pastedData = clipboardData.getData('Text');

    const numbersPasted = pastedData.replace(/\D/g, '').split('');

    const getInput = (name) => {
      return document.querySelector(`input[name="${name}"]`);
    };

    if (!numbersPasted.length) return;

    getInput(0) ? (getInput(0).value = numbersPasted[0]) : null;
    getInput(1) ? (getInput(1).value = numbersPasted[1]) : null;
    getInput(2) ? (getInput(2).value = numbersPasted[2]) : null;
    getInput(3) ? (getInput(3).value = numbersPasted[3]) : null;

    setValue(
      `${numbersPasted[0]}${numbersPasted[1]}${numbersPasted[2]}${numbersPasted[3]}`,
    );

    getInput(3) ? getInput(3).focus() : null;
  };

  return (
    <>
      <S.InputWrapper digits={digits} gridArea={gridArea} margin={margin}>
        {Array(digits)
          .fill(0)
          .map((_, index) => {
            return (
              <S.Input
                pattern="[0-9]"
                required
                placeholder="0"
                {...rest}
                maxLength="1"
                key={index}
                name={index}
                type="number"
                inputmode="numeric"
                onChange={(e) => {
                  if (e.target.value.length > 1) {
                    e.target.value = e.target.value.slice(0, 1);
                  }

                  handleChange(e);
                  rest?.onChange && rest.onChange(e);
                }}
                onKeyDown={(e) => {
                  handleKeyDown(e);
                  rest?.onKeyDown && rest.onKeyDown(encodeURI);
                }}
                onPaste={handlePaste}
              />
            );
          })}
      </S.InputWrapper>
      <S.Wrapper>
        <Input name="digits" value={value} />
      </S.Wrapper>
    </>
  );
};
