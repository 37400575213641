import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.section`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(133, 133, 133, 0.3);
  margin-top: 30px;
  padding: 70px 50px;
  overflow-x: hidden;
  width: 100vw;
  ${media.greaterThan('medium')`
  width: 100%;
`};
`;

export const DataWrapper = styled.section`
  display: flex;
  gap: 30px;
`;

export const Data = styled.div`
  margin-top: 100px;
`;

export const DataHeader = styled.div`
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;

  align-items: center;
  /* flex-grow: 3; */
  form {
    display: flex;
    align-items: center;
    // z-index: 100;
    width: 340px;

    svg {
      transform: rotate(-90deg);
      fill: ${({ theme }) => theme.colors.grayDarker};
    }

    span {
      margin-right: 10px;
      font-weight: 700;
      font-size: 1.6rem;
      color: ${({ theme }) => theme.colors.grayDarker};
    }
  }
  p {
    font-size: 1.4rem;
  }
`;

export const Message = styled.h1``;

export const Title = styled.p`
  top: 20px;
  left: 25px;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  color: ${({ theme }) => theme.colors.red};
  font-weight: ${({ theme }) => theme.font.medium};
  position: absolute;
  font-weight: 700;
`;
