import { toDateBR, InBetweenDates, toDateTimeNoSeconds } from '~/Helpers/Date';
const na = 'N/A';

const column = [
  {
    name: 'Título',
    selector: (row) => row.title ?? na,
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => <div data-tag="allowRowEvents">{row.title ?? na}</div>,
  },
  {
    name: 'Descrição',
    selector: (row) => row.description ?? na,
    sortable: true,
    minWidth: '250px',
    cell: (row) => <div data-tag="allowRowEvents">{row.description ?? na}</div>,
  },
  {
    name: 'Início',
    selector: (row) => (row.begin_date ? toDateBR(row.begin_date) : na),
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{toDateBR(row.begin_date) ?? na}</div>
    ),
  },
  {
    name: 'Fim',
    selector: (row) => (row.end_date ? toDateBR(row.end_date) : na),
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{toDateBR(row.end_date) ?? na}</div>
    ),
  },
  {
    name: 'Criado em',
    selector: (row) => toDateTimeNoSeconds(row.created_at),
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{toDateTimeNoSeconds(row.created_at)}</div>
    ),
  },
  {
    name: 'Última atualização em',
    selector: (row) => toDateTimeNoSeconds(row.updated_at),
    sortable: true,
    grow: 1,
    minWidth: '150px',
    cell: (row) => (
      <div data-tag="allowRowEvents">{toDateTimeNoSeconds(row.updated_at)}</div>
    ),
  },
];

export default column;
