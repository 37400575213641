import * as S from './styles';

const AwardsCatalog = () => {
  return (
    <>
      <S.Container>
        <S.Message>AwardsCatalog</S.Message>
      </S.Container>
    </>
  );
};

export default AwardsCatalog;
