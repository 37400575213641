import moment from 'moment';

const columns = [
  {
    name: 'Id',
    selector: (row) => row.id,
    cell: (row) => <div title={row.id}>{row.id}</div>,
    sortable: true,
  },
  {
    name: 'URL',
    selector: (row) => row.location,
    cell: (row) => (
      <a
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        href={row.location}
      >
        {row.location || 'N/A'}
      </a>
    ),
    sortable: true,
  },
  {
    name: 'Processado',
    selector: (row) => (row.processed ? 'Sim' : 'Não'),
    cell: (row) => (
      <div title={row.processed}>{row.processed ? 'Sim' : 'Não'}</div>
    ),
    sortable: true,
  },
  {
    name: 'Data de Upload',
    selector: (row) => row.created_at,
    cell: (row) => (
      <div title={row.created_at}>
        {moment(row.created_at).format('DD/MM/YYYY HH:mm')}
      </div>
    ),
    sortable: true,
  },
];

export default columns;
