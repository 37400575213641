import { useEffect, useState } from 'react';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';
import Loading from '~/components/Loading';
import Table from '~/components/NexAdmin/Table';
import api from '~/services/api';
import { columns, columnsDigital } from './columns';
import { parseJwt } from '~/services/auth';
import { HTTPErrorCallback } from '~/Helpers/Error';
import Actions from './Components/Actions';
import UpdateBanner from './Update';
import BannersDescription from '~/components/NexAdmin/Modal/Descriptions/Banners/bannersDescription';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';

const Banners = () => {
  const [loading, setLoading] = useState(false);
  const [banners, setBanners] = useState([]);
  const [step, setStep] = useState('list');
  const [row, setRow] = useState({});
  const { openModal } = useModal();
  useEffect(() => {
    getBanners();
  }, [step]);

  const getBanners = () => {
    api
      .get(`/banners/${parseJwt().canal_id}`)
      .then((response) => {
        setBanners(response.data);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };
  const switchColumn = () => {
    switch (Number(parseJwt().canal_id)) {
      case 7:
        return columnsDigital;
      default:
        return columns;
    }
  };
  const openThermsModal = () => {
    openModal({
      title: 'Banners',
      close: true,
      content: <BannersDescription />,
    });
  };
  const switchStep = (current) => {
    switch (current) {
      case 'list':
        return (
          <>
            {loading && <Loading />}
            <HeaderWrapper>
              <StatusTag
                icon="upload"
                title="Banners"
                modalFunction={openThermsModal}
              />
            </HeaderWrapper>
            <ContentWrapper>
              <ContentInner withDetails>
                <ContainerWithTitle title="Tabela de Banners">
                  <Table
                    data={banners}
                    columns={[
                      {
                        name: 'Ações',
                        cell: (row) => (
                          <Actions
                            row={row}
                            refresh={getBanners}
                            setLoading={setLoading}
                            setRow={setRow}
                            setStep={setStep}
                          />
                        ),
                      },
                      ...switchColumn(),
                    ]}
                    pagination={true}
                    header
                    dropdownItems={false}
                    hasDownload={false}
                  />
                </ContainerWithTitle>
              </ContentInner>
            </ContentWrapper>
          </>
        );
      case 'update':
        return <UpdateBanner row={row} setStep={setStep} />;
    }
  };

  return <>{switchStep(step)}</>;
};

export default Banners;
