import React, { useEffect, useState, useRef } from 'react';
import { toDateTime } from '~/Helpers/Date';
import { Select, Input } from '~/components/NexAdmin/Inputs/index';
import { Button } from '~/components/NexAdmin/Buttons/index';
import Table from '~/components/NexAdmin/Table';
import * as S from './styles';
import { Form } from '@unform/web';
import { parseJwt } from '~/services/auth';
import api from '~/services/api';
import Counter from '~/components/NexAdmin/Counter';
import { HTTPErrorCallback } from '~/Helpers/Error';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import StatusTag from '~/components/NexAdmin/StatusTag';
import Loading from '~/components/Loading';

const MigrationReport = () => {
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [channels, setChannels] = useState(null);
  const current_user = parseJwt();
  const formRef = useRef();

  useEffect(() => {
    handleSubmit();
    setLoading(true);
    api
      .get(`/channel/admin/overall-channel-data`)
      .then((response) => {
        setChannels(Array.isArray(response.data) ? response.data : []);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  }, []);

  const handleSubmit = (data) => {
    setLoading(true);
    console.log(data);
    api
      .get(`/channel/admin/migration?${new URLSearchParams(data)}`)
      .then((response) => {
        setUsers(response.data);
        setCount(Array.isArray(response.data) ? response.data.length : 0);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag icon="database" title="Relatório de Migrações" />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <S.FormWrapper>
                <S.FormFilters>
                  <Input label="De:" name="initial_date" type="date" />
                  <Input label="Até:" name="final_date" type="date" />
                  <Input label="CPF" name="cpf" type="text" />
                  <Input label="Nome" name="name" type="text" />
                  <Select
                    name="new_channel"
                    options={channels}
                    label="Canal Novo"
                  />
                  <Select
                    name="old_channel"
                    options={channels}
                    label="Canal Antigo"
                  />
                </S.FormFilters>
                <Button type="submit">Consultar</Button>
              </S.FormWrapper>
            </Form>
            <Counter total={count} />
            <Table
              data={users}
              columns={[
                {
                  name: 'Nome',
                  sortable: true,
                  grow: 1,
                  minWidth: '150px',
                  selector: (row) => (row.usu_nome ? row.usu_nome : 'N/A'),
                },
                {
                  name: 'CPF',
                  sortable: true,
                  grow: 1,
                  minWidth: '150px',
                  selector: (row) => (row.usu_cpf ? row.usu_cpf : 'N/A'),
                },
                {
                  name: 'Canal Novo',
                  sortable: true,
                  grow: 1,
                  minWidth: '150px',
                  selector: (row) => (row.canal_novo ? row.canal_novo : 'N/A'),
                },
                {
                  name: 'Canal Antigo',
                  sortable: true,
                  grow: 1,
                  minWidth: '150px',
                  selector: (row) =>
                    row.canal_antigo ? row.canal_antigo : 'N/A',
                },
                {
                  name: 'Data Migração',
                  sortable: true,
                  grow: 1,
                  minWidth: '150px',
                  selector: (row) =>
                    row.data_migracao ? row.data_migracao : 'N/A',
                },
              ]}
              loading={loading}
              dataTableProps={{
                pagination: true,
              }}
              header
              dropdownItems={false}
              hasDownload={true}
              fileName={`Relatório de Migrações - ${toDateTime(new Date())}`}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default MigrationReport;
