import * as S from './styles';

const Cards = ({ title, dataList }) => {
  return (
    <>
      <S.Card>
        <S.Title>
          <p>{title}</p>
        </S.Title>
        {dataList.map((item, i) => {
          return (
            <>
              <S.InfoTitle key={i}>
                {item[0]}:&nbsp;
                <S.InfoData key={i}>{item[1] ? item[1] : 'N/A'}</S.InfoData>
              </S.InfoTitle>
            </>
          );
        })}
        <S.Spaced />
      </S.Card>
    </>
  );
};

export default Cards;
