import * as S from './styles';

const PushDescription = () => {
  return (
    <S.ThermWrapper>
      <br />
      Essa tela permite o envio de notificações aos participantes. Para realizar
      um envio, preencha os campos <strong>Título</strong>, o texto desejado no
      campo <strong>Corpo</strong> e em seguida clique em{' '}
      <strong>Enviar notificações</strong>.
      <br />
      <br />
      O envio será feito para o dispositivo que o usuário estiver utilizando no
      momento do disparo, podendo ser um computador ou o aplicativo para
      smartphones.
      <br />
      <br />
      Todos que receberam a notificação são listados nessa tela, onde pode-se
      pesquisar pelos campos <strong>Rede</strong>, <strong>Regional</strong> e{' '}
      <strong>Cargo</strong> e depois clicar em <strong>Filtrar</strong>.
      <br />
      <br />
    </S.ThermWrapper>
  );
};

export default PushDescription;
